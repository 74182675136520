unit UEditStrTouchPW;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, 
DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  XData.Web.Client,
  XData.Web.Connection,

  WEBLib.DB,

  WEBLib.ExtCtrls,
  WEBLib.DBCtrls,
  Types,
  WEBLib.Imaging.pngimage,
  UGlobvar;



type



  TEditStrTouchPW = class(TForm)
    WebLabel1: TLabel;
    WebEdit1: TEdit;
    WebButton1: TButton;
    Login_WebLabel1: TLabel;
    WebEdit2: TEdit;
    WebTimer1: TTimer;
    WebCheckBox2: TCheckBox;
    procedure WebFormShow(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure WebTimer1Timer(Sender: TObject);
    procedure WebEdit1Click(Sender: TObject);
    procedure WebEdit2Click(Sender: TObject);
    procedure WebCheckBox2Click(Sender: TObject);
  private
    { Private-Deklarationen }

  public
    { Public-Deklarationen }
    Akt_header,
    Akt_Str : String;

    Zeilen_Liste : TStringList;
    Zeilen_Count : integer;
    Form_Aktiv : boolean;

  protected procedure LoadDFMValues; override; end;

var
  EditStrTouchPW: TEditStrTouchPW;
  UES_Username,
  UES_HeaderPW,
  UES_StringPW : String;

procedure Edit_Str_Touch_PW(Header,st,st2 : String;c,c2 : TControl);

implementation


uses Udatamodule;

{$R *.dfm}


procedure Edit_Str_Touch_PW(Header,st,st2 : String;c,c2 : TControl);
var newform : TEditStrTouchPW;
begin
  UES_HeaderPW := Header;
  UES_StringPW := st;
  UES_Username := st2;
  newform := TEditStrTouchPW.CreateNew(procedure(AForm: TObject)
    begin

    end
  );
  newform.ShowModal(procedure(AValue: TModalResult)
  begin
    TEdit(c).Text := newform.WebEdit1.Text;
    TEdit(c2).Text := newform.WebEdit2.Text;
  end
  );
end;


procedure TEditStrTouchPW.WebButton1Click(Sender: TObject);
begin
  Close;
end;

procedure TEditStrTouchPW.WebCheckBox2Click(Sender: TObject);
begin
  if WebCheckBox2.Checked then begin
    WebEdit1.PasswordChar := #0;
    Password_Anzeigen := true;
  end
  else begin
    WebEdit1.PasswordChar := '*';
    Password_Anzeigen := false;
  end;

end;

procedure TEditStrTouchPW.WebEdit1Click(Sender: TObject);
begin
//
end;

procedure TEditStrTouchPW.WebEdit2Click(Sender: TObject);
begin
//
end;

procedure TEditStrTouchPW.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  Zeilen_Liste.Clear;
  Zeilen_Liste.Free;

end;

procedure TEditStrTouchPW.WebFormCreate(Sender: TObject);
begin
  Form_Aktiv := false;

end;

procedure TEditStrTouchPW.WebFormResize(Sender: TObject);
begin
  if not Form_Aktiv then exit;
  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,nil,nil);

end;

procedure TEditStrTouchPW.WebFormShow(Sender: TObject);
begin
  if Password_Anzeigen then begin
    WebEdit1.PasswordChar := #0;
    WebCheckBox2.Checked := true;
  end
  else begin
    WebEdit1.PasswordChar := '*';
    WebCheckBox2.Checked := false;
  end;
  Weblabel1.Caption := UES_HeaderPW;
  WebEdit1.Text := UES_StringPW;
  WebEdit2.Text := UES_Username;
  Zeilen_Liste := TStringList.Create;
  Zeilen_Count := 0;
  Mit_On_Click := false;
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,Login_WebLabel1,WebEdit2,nil);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel1,WebEdit1,nil);
  Zeilen_Liste_Set(Zeilen_Liste,WebEdit1,WebButton1);
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,nil,WebCheckBox2,nil);

  Mit_On_Click := true;
  Form_Aktiv := true;
  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,nil,nil);
  if Akt_OS = OS_IOS then begin
    WebEdit2.SetFocus;
  end
  else begin
    if UES_Username = '' then begin
      WebEdit2.SetFocus;
    end
    else begin
      WebEdit1.SetFocus;
    end;
  end;
  WebTimer1.Enabled := true;

end;

procedure TEditStrTouchPW.WebTimer1Timer(Sender: TObject);
begin
  WebTimer1.Enabled := false;
  if Akt_OS = OS_IOS then begin
    WebEdit2Click(Self);
  end
  else begin
    WebEdit1Click(Self);
  end;
end;

procedure TEditStrTouchPW.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);
  Login_WebLabel1 := TLabel.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebEdit2 := TEdit.Create(Self);
  WebCheckBox2 := TCheckBox.Create(Self);
  WebTimer1 := TTimer.Create(Self);

  WebLabel1.BeforeLoadDFMValues;
  Login_WebLabel1.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebEdit2.BeforeLoadDFMValues;
  WebCheckBox2.BeforeLoadDFMValues;
  WebTimer1.BeforeLoadDFMValues;
  try
    Name := 'EditStrTouchPW';
    Width := 331;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Arial';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 24;
    WebLabel1.Top := 104;
    WebLabel1.Width := 65;
    WebLabel1.Height := 16;
    WebLabel1.Caption := 'WebLabel1';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -13;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    Login_WebLabel1.SetParentComponent(Self);
    Login_WebLabel1.Name := 'Login_WebLabel1';
    Login_WebLabel1.Left := 24;
    Login_WebLabel1.Top := 31;
    Login_WebLabel1.Width := 83;
    Login_WebLabel1.Height := 16;
    Login_WebLabel1.Caption := 'eMail-Adresse';
    Login_WebLabel1.Font.Charset := DEFAULT_CHARSET;
    Login_WebLabel1.Font.Color := clWindowText;
    Login_WebLabel1.Font.Height := -13;
    Login_WebLabel1.Font.Name := 'Arial';
    Login_WebLabel1.Font.Style := [];
    Login_WebLabel1.HeightPercent := 100.000000000000000000;
    Login_WebLabel1.ParentFont := False;
    Login_WebLabel1.WidthPercent := 100.000000000000000000;
    WebEdit1.SetParentComponent(Self);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 24;
    WebEdit1.Top := 136;
    WebEdit1.Width := 113;
    WebEdit1.Height := 22;
    WebEdit1.ChildOrder := 1;
    WebEdit1.ElementClassName := 'form-control';
    WebEdit1.HeightPercent := 100.000000000000000000;
    WebEdit1.PasswordChar := '*';
    WebEdit1.Text := 'WebEdit1';
    WebEdit1.WidthPercent := 100.000000000000000000;
    SetEvent(WebEdit1, Self, 'OnClick', 'WebEdit1Click');
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 160;
    WebButton1.Top := 130;
    WebButton1.Width := 105;
    WebButton1.Height := 30;
    WebButton1.Caption := 'Fertig';
    WebButton1.ChildOrder := 2;
    WebButton1.ElementClassName := 'btn btn-primary';
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebEdit2.SetParentComponent(Self);
    WebEdit2.Name := 'WebEdit2';
    WebEdit2.Left := 24;
    WebEdit2.Top := 51;
    WebEdit2.Width := 289;
    WebEdit2.Height := 22;
    WebEdit2.AutoCompletion := acUserName;
    WebEdit2.ChildOrder := 1;
    WebEdit2.Font.Charset := DEFAULT_CHARSET;
    WebEdit2.Font.Color := clWindowText;
    WebEdit2.Font.Height := -16;
    WebEdit2.Font.Name := 'Arial';
    WebEdit2.Font.Style := [];
    WebEdit2.HeightPercent := 100.000000000000000000;
    WebEdit2.ParentFont := False;
    WebEdit2.WidthPercent := 100.000000000000000000;
    SetEvent(WebEdit2, Self, 'OnClick', 'WebEdit2Click');
    WebCheckBox2.SetParentComponent(Self);
    WebCheckBox2.Name := 'WebCheckBox2';
    WebCheckBox2.Left := 24;
    WebCheckBox2.Top := 192;
    WebCheckBox2.Width := 249;
    WebCheckBox2.Height := 22;
    WebCheckBox2.Caption := 'Kennwort anzeigen';
    WebCheckBox2.ChildOrder := 8;
    WebCheckBox2.Color := clNone;
    WebCheckBox2.Font.Charset := DEFAULT_CHARSET;
    WebCheckBox2.Font.Color := clWindowText;
    WebCheckBox2.Font.Height := -16;
    WebCheckBox2.Font.Name := 'Arial';
    WebCheckBox2.Font.Style := [];
    WebCheckBox2.HeightPercent := 100.000000000000000000;
    WebCheckBox2.ParentFont := False;
    WebCheckBox2.State := cbUnchecked;
    WebCheckBox2.WidthPercent := 100.000000000000000000;
    SetEvent(WebCheckBox2, Self, 'OnClick', 'WebCheckBox2Click');
    WebTimer1.SetParentComponent(Self);
    WebTimer1.Name := 'WebTimer1';
    WebTimer1.Enabled := False;
    WebTimer1.Interval := 100;
    SetEvent(WebTimer1, Self, 'OnTimer', 'WebTimer1Timer');
    WebTimer1.Left := 216;
    WebTimer1.Top := 16;
  finally
    WebLabel1.AfterLoadDFMValues;
    Login_WebLabel1.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebEdit2.AfterLoadDFMValues;
    WebCheckBox2.AfterLoadDFMValues;
    WebTimer1.AfterLoadDFMValues;
  end;
end;

end.
