unit UEditStrTouch;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, 
DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  XData.Web.Client,
  XData.Web.Connection,

  WEBLib.DB,

  WEBLib.ExtCtrls,
  WEBLib.DBCtrls,
  Types,
  WEBLib.Imaging.pngimage,
  UGlobvar;



type



  TEditStrTouch = class(TForm)
    WebLabel1: TLabel;
    WebEdit1: TEdit;
    WebButton1: TButton;
    WebDBEdit1: TDBEdit;
    WebTimer1: TTimer;
    procedure WebFormShow(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure WebTimer1Timer(Sender: TObject);
  private
    { Private-Deklarationen }

  public
    { Public-Deklarationen }
    Akt_header,
    Akt_Str : String;

    Zeilen_Liste : TStringList;
    Zeilen_Count : integer;
    Form_Aktiv : boolean;
    Akt_Control : TControl;
  protected procedure LoadDFMValues; override; end;

var
  EditStrTouch: TEditStrTouch;
  UES_Header,
  UES_String : String;
  UES_PW : boolean;

procedure Edit_Str_Touch(Header,st : String;pw : boolean;c : TControl);

implementation


uses Udatamodule;

{$R *.dfm}


procedure Edit_Str_Touch(Header,st : String;pw : boolean;c : TControl);
var newform : TEditStrTouch;
begin
  UES_Header := Header;
  UES_String := st;
  UES_PW := PW;

  newform := TEditStrTouch.CreateNew(procedure(AForm: TObject)
    begin

    end
  );
  newform.Akt_Control := c;
  newform.ShowModal(procedure(AValue: TModalResult)
  begin
    if c is TDBEdit then begin
      TDBEdit(c).Text := newform.WebDBEdit1.Text;
//      TWebDBEdit(c).Datasource.Dataset.Fieldbyname(TWebDBEdit(c).Datafield).AsString := newform.WebDBEdit1.Text;
    end
    else if c is TEdit then begin
      TEdit(c).Text := newform.WebEdit1.Text;
    end

  end
  );
end;


procedure TEditStrTouch.WebButton1Click(Sender: TObject);
begin

  Close;
end;

procedure TEditStrTouch.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  Zeilen_Liste.Clear;
  Zeilen_Liste.Free;

end;

procedure TEditStrTouch.WebFormCreate(Sender: TObject);
begin
  Form_Aktiv := false;

end;

procedure TEditStrTouch.WebFormResize(Sender: TObject);
begin
  if not Form_Aktiv then exit;
  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,nil,nil);

end;

procedure TEditStrTouch.WebFormShow(Sender: TObject);
begin
  Weblabel1.Caption := UES_Header;

  WebEdit1.Text := UES_String;
  if UES_pw then begin
    WebEdit1.PasswordChar := '*';
    WebEdit1.AutoCompletion := acCurrentPassword;
    WebEdit1.ElementClassname := 'form-control';
    WebEdit1.AutoSelect := true;
  end
  else begin
    WebEdit1.PasswordChar := #0;
  end;

  Zeilen_Liste := TStringList.Create;
  Zeilen_Count := 0;
  Mit_On_Click := false;
  Zeilen_Liste_Add(Zeilen_Liste,Zeilen_Count,WebLabel1,WebEdit1,nil);
  Mit_On_Click := true;

  Form_Aktiv := true;
  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,nil,nil);

  if Akt_Control is TDBEdit then begin
    WebDBEdit1.Datasource := TDBEdit(Akt_Control).Datasource;
    WebDBEdit1.Datafield := TDBEdit(Akt_Control).Datafield;
    WebDBEdit1.Top := WebEdit1.Top;
    WebDBEdit1.Left := WebEdit1.Left;
    WebDBEdit1.Height := WebEdit1.Height;
    WebDBEdit1.Font.Size := WebEdit1.Font.Size;
    WebEdit1.Visible := false;
    WebDBEdit1.SetFocus;
  end
  else if Akt_Control is TEdit then begin
    WebDBEdit1.Visible := false;
    WebEdit1.SetFocus;
  end;
end;

procedure TEditStrTouch.WebTimer1Timer(Sender: TObject);
begin
  if Akt_Control is TDBEdit then begin
    WebDBEdit1.SetFocus;
  end
  else begin
    WebEdit1.SetFocus;
  end;
end;

procedure TEditStrTouch.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebDBEdit1 := TDBEdit.Create(Self);
  WebTimer1 := TTimer.Create(Self);

  WebLabel1.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebDBEdit1.BeforeLoadDFMValues;
  WebTimer1.BeforeLoadDFMValues;
  try
    Name := 'EditStrTouch';
    Left := 0;
    Top := 0;
    Width := 331;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Arial';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 16;
    WebLabel1.Top := 16;
    WebLabel1.Width := 65;
    WebLabel1.Height := 16;
    WebLabel1.Caption := 'WebLabel1';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -13;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Style := [];
    WebLabel1.ParentFont := False;
    WebEdit1.SetParentComponent(Self);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 16;
    WebEdit1.Top := 48;
    WebEdit1.Width := 307;
    WebEdit1.Height := 22;
    WebEdit1.ChildOrder := 1;
    WebEdit1.Text := 'WebEdit1';
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 72;
    WebButton1.Top := 76;
    WebButton1.Width := 105;
    WebButton1.Height := 41;
    WebButton1.Caption := 'Fertig';
    WebButton1.ChildOrder := 2;
    WebButton1.ElementClassName := 'btn-primary';
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebDBEdit1.SetParentComponent(Self);
    WebDBEdit1.Name := 'WebDBEdit1';
    WebDBEdit1.Left := 16;
    WebDBEdit1.Top := 136;
    WebDBEdit1.Width := 307;
    WebDBEdit1.Height := 22;
    WebDBEdit1.ChildOrder := 3;
    WebDBEdit1.Text := 'WebDBEdit1';
    WebTimer1.SetParentComponent(Self);
    WebTimer1.Name := 'WebTimer1';
    WebTimer1.Enabled := True;
    WebTimer1.Interval := 100;
    SetEvent(WebTimer1, Self, 'OnTimer', 'WebTimer1Timer');
    WebTimer1.Left := 200;
    WebTimer1.Top := 216;
  finally
    WebLabel1.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebDBEdit1.AfterLoadDFMValues;
    WebTimer1.AfterLoadDFMValues;
  end;
end;

end.
