function Email_Plausibel(st : String) : boolean;
var i,j : integer;

begin
  Email_Plausibel := false;
  if Trim(st) = '' then exit;
  j := 0;
  for i := 1 to length(st) do begin
    if st[i] = '@' then begin
      j := j + 1;
    end
    else begin

    end;
  end;
  if j <> 1 then exit;


  Email_Plausibel := true;
end;

function Kennwort_Plausibel(st : String) : boolean;
begin
  Kennwort_Plausibel := false;
  if Trim(st) = '' then exit;
  if length(st) < 6 then exit;

  Kennwort_Plausibel := true;
end;

