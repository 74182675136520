program PWebshop2;

uses
  WEBLib.Forms,
  
UWSMain in 'UWSMain.pas'{*.html},
  UDataModule in 'UDataModule.pas',
  ueditStrTouch in 'ueditStrTouch.pas'{*.html},
  ueditstrtouchpw in 'ueditstrtouchpw.pas'{*.html},
  UanzPDF in 'UanzPDF.pas'{*.html},
  UGlobvar in 'UGlobvar.pas'{*.html},
  UEditRTF in 'UEditRTF.pas'{*.html},
  App.Config in 'App.Config.pas';

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Start_Timer_Aktiv := false;
  Init_Globvar;
  Init_Prog;

  Application.CreateForm(TForm2, Form2);
  Application.CreateForm(TDataModule1, DataModule1);
//  Application.CreateForm(TEditStrTouch, EditStrTouch);
//  Application.CreateForm(TEditStrTouchPW, EditStrTouchPW);
//  Application.CreateForm(TAnzPDF, AnzPDF);
  Application.CreateForm(TGlobvar, Globvar);
//  Application.CreateForm(TEditRTF, EditRTF);
  Application.Run;
end.
