unit UEditRTF;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Buttons;

type
  TEditRTF = class(TForm)
    WebRichEditToolBar1: TRichEditToolBar;
  private
    { Private-Deklarationen }
  public
    { Public-Deklarationen }
  protected procedure LoadDFMValues; override; end;

var
  EditRTF: TEditRTF;

implementation

{$R *.dfm}

procedure TEditRTF.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebRichEditToolBar1 := TRichEditToolBar.Create('');

  WebRichEditToolBar1.BeforeLoadDFMValues;
  try
    Name := 'EditRTF';
    Width := 640;
    Height := 480;
    WebRichEditToolBar1.SetParentComponent(Self);
    WebRichEditToolBar1.Name := 'WebRichEditToolBar1';
    WebRichEditToolBar1.Left := 0;
    WebRichEditToolBar1.Top := 0;
    WebRichEditToolBar1.Width := 640;
    WebRichEditToolBar1.Height := 32;
    WebRichEditToolBar1.ElementClassName := '';
    WebRichEditToolBar1.HeightPercent := 100.000000000000000000;
    WebRichEditToolBar1.WidthPercent := 100.000000000000000000;
    WebRichEditToolBar1.Align := alTop;
    WebRichEditToolBar1.Hints.BeginUpdate;
    try
      WebRichEditToolBar1.Hints.Clear;
      WebRichEditToolBar1.Hints.Add('Bold');
      WebRichEditToolBar1.Hints.Add('Italic');
      WebRichEditToolBar1.Hints.Add('Underline');
      WebRichEditToolBar1.Hints.Add('Strike Throught');
      WebRichEditToolBar1.Hints.Add('Text Color');
      WebRichEditToolBar1.Hints.Add('Background Color');
      WebRichEditToolBar1.Hints.Add('Align lef');
      WebRichEditToolBar1.Hints.Add('Align center');
      WebRichEditToolBar1.Hints.Add('Align right');
      WebRichEditToolBar1.Hints.Add('Numbered list');
      WebRichEditToolBar1.Hints.Add('List');
    finally
      WebRichEditToolBar1.Hints.EndUpdate;
    end;
  finally
    WebRichEditToolBar1.AfterLoadDFMValues;
  end;
end;

end.