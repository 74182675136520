unit UWSMain;

// https://appadvice.com/game/app/metzgerei-schreder/1482256245
interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,

  WEBLib.Forms, WEBLib.Dialogs, WEBLib.ExtCtrls, WEBLib.Menus,
  
WEBLib.JSON,
  WebLib.Storage,
  DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  XData.Web.Client,
  XData.Web.Connection,

  UDatamodule, WEBLib.Grids, WEBLib.DBCtrls,
  WEBLib.Lists,
  WEBLib.TreeNodes,
  WEBLib.PayPal,
  WEBLib.ComCtrls, 
WEBLib.Slider, WEBLib.StdCtrls, WEBLib.Imaging.pngimage,
  WEBLib.REST, WEBLib.CDS,
  UGlobvar, WEBLib.WebCtrls, WEBLib.EditAutocomplete, WEBLib.WebSocketClient,
  WEBLib.Devices;


const
  Add_Modus = 1;
  Edit_Modus = 2;
  Loesch_Modus = 3;
  Add_Modus_Theke = 4;
  Max_VVA = 100;
  Max_Start_Phasen = 20;

type


  PReg_Rec = ^TReg_Rec;
  TReg_Rec = record
               v,
               b  : integer;
               ID : integer;
             end;



  ME_Rec = record
             Nummer : integer;
             KZ_Integer : boolean;
             RefGewicht : real;
           end;

  PML_Rec = ^TML_Rec;
  TML_Rec = record
             L : TLabel;
           end;



  ZM_Rec = record
             Nummer : integer;
             txt : String;
             Gebuehr : real;
             Abholung : boolean;
           end;



  TForm2 = class(TForm)
    WebTimer1: TTimer;
    WebTimer2: TTimer;
    WebHttpRequest1: THttpRequest;
    WebClientDataSet1: TClientDataSet;
    WebPanel6: TPanel;
    WebImageControl12: TImageControl;
    WebLabel3: TLabel;
    WebLabel4: TLabel;
    WebLabel5: TLabel;
    WebLabel6: TLabel;
    WebLabel7: TLabel;
    WebTimer3: TTimer;
    WebLabel24: TLabel;
    WebLabel25: TLabel;
    WebTimer4: TTimer;
    WebPageControl8: TPageControl;
    WebPageControl8Sheet1: TTabSheet;
    WebPageControl8Sheet2: TTabSheet;
    WebPageControl1: TPageControl;
    WebPageControl1Sheet1: TTabSheet;
    WebPanel3: TPanel;
    WebPanel2: TPanel;
    WebDBResponsiveGrid3: TDBResponsiveGrid;
    WebPageControl1Sheet2: TTabSheet;
    WebPanel4: TPanel;
    WebPanel10: TPanel;
    WebDBResponsiveGrid2: TDBResponsiveGrid;
    WebPanel13: TPanel;
    WebButton8: TButton;
    WebPanel14: TPanel;
    WebPanel15: TPanel;
    WebButton1: TButton;
    WebButton7: TButton;
    WebPageControl2: TPageControl;
    WebPageControl2Sheet1: TTabSheet;
    WebImageControl11: TImageControl;
    WebLabel29: TLabel;
    WebLabel30: TLabel;
    WebLabel31: TLabel;
    WebLabel32: TLabel;
    WebLabel72: TLabel;
    WebEdit5: TEdit;
    WebComboBox4: TComboBox;
    WebComboBox5: TComboBox;
    WebComboBox6: TComboBox;
    WebPageControl2Sheet2: TTabSheet;
    WebRichEdit8: TRichEdit;
    WebPageControl2Sheet3: TTabSheet;
    WebPageControl2Sheet4: TTabSheet;
    WebPageControl2Sheet5: TTabSheet;
    WebPanel31: TPanel;
    WebLabel83: TLabel;
    WebComboBox11: TComboBox;
    WebPanel32: TPanel;
    WebDBMemo1: TDBMemo;
    WebDBGrid2: TDBGrid;
    WebPanel33: TPanel;
    WebLabel84: TLabel;
    WebPageControl1Sheet3: TTabSheet;
    WebPageControl6: TPageControl;
    WebPageControl6Sheet1: TTabSheet;
    WebButton25: TButton;
    WebButton53: TButton;
    WebButton54: TButton;
    WebPageControl6Sheet2: TTabSheet;
    WebPageControl6Sheet3: TTabSheet;
    WebPageControl1Sheet4: TTabSheet;
    WebPanel7: TPanel;
    WebPanel8: TPanel;
    WebLabel1: TLabel;
    WebLabel2: TLabel;
    WebComboBox3: TComboBox;
    WebEdit2: TEdit;
    WebListBox1: TListBox;
    WebPanel30: TPanel;
    WebLabel78: TLabel;
    WebCheckBox3: TCheckBox;
    WebCheckBox2: TCheckBox;
    WebCheckBox1: TCheckBox;
    WebPanel11: TPanel;
    WebPageControl7: TPageControl;
    WebPageControl7Sheet1: TTabSheet;
    WebPanel5: TPanel;
    WebLabel77: TLabel;
    WebComboBox10: TComboBox;
    WebDBResponsiveGrid1: TDBResponsiveGrid;
    WebPageControl7Sheet2: TTabSheet;
    WebLabel80: TLabel;
    WebLabel81: TLabel;
    WebLabel82: TLabel;
    WebButton69: TButton;
    WebButton74: TButton;
    WebButton75: TButton;
    WebButton70: TButton;
    WebButton76: TButton;
    WebButton77: TButton;
    WebPageControl7Sheet3: TTabSheet;
    WebLabel79: TLabel;
    WebProgressBar1: TProgressBar;
    WebPageControl1Sheet5: TTabSheet;
    WebPanel12: TPanel;
    WebRichEdit1: TRichEdit;
    WebPageControl1Sheet6: TTabSheet;
    WebPageControl4: TPageControl;
    WebPageControl4Sheet1: TTabSheet;
    lbName: TLabel;
    lbAddress1: TLabel;
    lbAddress2: TLabel;
    lbCity: TLabel;
    lbPostalCode: TLabel;
    lbState: TLabel;
    WebLabel34: TLabel;
    WebLabel36: TLabel;
    WebLabel49: TLabel;
    edName: TEdit;
    edAddress1: TEdit;
    edAddress2: TEdit;
    edCity: TEdit;
    edPostalCode: TEdit;
    WebComboBox1: TComboBox;
    WebButton37: TButton;
    WebButton38: TButton;
    WebComboBox7: TComboBox;
    WebPageControl4Sheet2: TTabSheet;
    WebLabel33: TLabel;
    WebLabel37: TLabel;
    WebLabel38: TLabel;
    WebLabel39: TLabel;
    WebLabel40: TLabel;
    WebLabel41: TLabel;
    WebLabel42: TLabel;
    WebLabel43: TLabel;
    WebEdit1: TEdit;
    WebEdit6: TEdit;
    WebEdit7: TEdit;
    WebEdit8: TEdit;
    WebEdit9: TEdit;
    WebComboBox2: TComboBox;
    WebButton35: TButton;
    WebButton36: TButton;
    WebPageControl4Sheet3: TTabSheet;
    WebLabel12: TLabel;
    WebLabel51: TLabel;
    mePayment: TMemo;
    WebButton27: TButton;
    WebRadioGroup1: TRadioGroup;
    WebCheckBox7: TCheckBox;
    WebButton40: TButton;
    WebButton41: TButton;
    WebButton42: TButton;
    WebEdit12: TEdit;
    WebEdit13: TEdit;
    WebEdit14: TEdit;
    WebPageControl4Sheet4: TTabSheet;
    WebButton28: TButton;
    WebButton29: TButton;
    WebButton30: TButton;
    WebButton31: TButton;
    WebButton22: TButton;
    WebButton23: TButton;
    WebPageControl4Sheet5: TTabSheet;
    WebLabel44: TLabel;
    WebLabel45: TLabel;
    WebLabel46: TLabel;
    WebEdit10: TEdit;
    WebEdit11: TEdit;
    WebButton32: TButton;
    WebButton33: TButton;
    WebButton34: TButton;
    WebButton39: TButton;
    WebCheckBox5: TCheckBox;
    WebPageControl4Sheet6: TTabSheet;
    WebPageControl5: TPageControl;
    WebPageControl5Sheet1: TTabSheet;
    WebLabel52: TLabel;
    WebLabel53: TLabel;
    WebLabel54: TLabel;
    WebLabel55: TLabel;
    WebLabel56: TLabel;
    WebLabel57: TLabel;
    WebLabel58: TLabel;
    WebLabel59: TLabel;
    WebEdit15: TEdit;
    WebEdit16: TEdit;
    WebEdit17: TEdit;
    WebEdit18: TEdit;
    WebEdit19: TEdit;
    WebComboBox8: TComboBox;
    WebButton45: TButton;
    WebButton46: TButton;
    WebPageControl5Sheet2: TTabSheet;
    WebLabel60: TLabel;
    WebLabel61: TLabel;
    WebLabel62: TLabel;
    WebLabel63: TLabel;
    WebLabel64: TLabel;
    WebLabel65: TLabel;
    WebEdit20: TEdit;
    WebEdit21: TEdit;
    WebEdit22: TEdit;
    WebEdit23: TEdit;
    WebButton47: TButton;
    WebButton48: TButton;
    WebButton49: TButton;
    WebPageControl5Sheet3: TTabSheet;
    WebLabel66: TLabel;
    WebLabel67: TLabel;
    WebLabel68: TLabel;
    WebButton50: TButton;
    WebButton51: TButton;
    WebEdit24: TEdit;
    WebButton52: TButton;
    WebPageControl4Sheet7: TTabSheet;
    WebPanel21: TPanel;
    WebLabel47: TLabel;
    WebLabel48: TLabel;
    WebPayPal1: TPayPal;
    WebButton26: TButton;
    WebButton24: TButton;
    WebPanel22: TPanel;
    WebLabel50: TLabel;
    WebDBGrid1: TDBGrid;
    WebPageControl1Sheet7: TTabSheet;
    WebImageSlider1: TImageSlider;
    WebButton9: TButton;
    WebPageControl1Sheet8: TTabSheet;
    WebLabel14: TLabel;
    WebLabel15: TLabel;
    WebLabel16: TLabel;
    WebLabel17: TLabel;
    WebButton13: TButton;
    WebButton14: TButton;
    WebButton15: TButton;
    WebPageControl1Sheet9: TTabSheet;
    WebRichEdit2: TRichEdit;
    WebPageControl1Sheet10: TTabSheet;
    WebRichEdit3: TRichEdit;
    WebPageControl1Sheet11: TTabSheet;
    WebRichEdit4: TRichEdit;
    WebPageControl1Sheet12: TTabSheet;
    WebRichEdit5: TRichEdit;
    WebPageControl1Sheet13: TTabSheet;
    WebLabel9: TLabel;
    WebLabel13: TLabel;
    WebEdit3: TEdit;
    WebEdit4: TEdit;
    WebButton12: TButton;
    WebButton16: TButton;
    WebCheckBox4: TCheckBox;
    WebPageControl1Sheet14: TTabSheet;
    WebPageControl3: TPageControl;
    WebPageControl3Sheet1: TTabSheet;
    WebScrollBox4: TScrollBox;
    WebImageControl1: TImageControl;
    WebLabel28: TLabel;
    WebPageControl3Sheet2: TTabSheet;
    WebScrollBox3: TScrollBox;
    WebImageControl2: TImageControl;
    WebLabel27: TLabel;
    WebPageControl3Sheet3: TTabSheet;
    WebScrollBox2: TScrollBox;
    WebImageControl5: TImageControl;
    WebLabel26: TLabel;
    WebPageControl3Sheet4: TTabSheet;
    WebScrollBox1: TScrollBox;
    WebImageControl4: TImageControl;
    WebLabel23: TLabel;
    WebPageControl1Sheet15: TTabSheet;
    WebButton43: TButton;
    WebButton44: TButton;
    WebRichEdit6: TRichEdit;
    WebPageControl1Sheet16: TTabSheet;
    WebRichEdit7: TRichEdit;
    WebPageControl1Sheet17: TTabSheet;
    WebPageControl1Sheet18: TTabSheet;
    WebImageZoomControl1: TImageZoomControl;
    WebImageSlider2: TImageSlider;
    WebPanel20: TPanel;
    WebLabel35: TLabel;
    WebLabel71: TLabel;
    WebComboBox9: TComboBox;
    WebPageControl1Sheet19: TTabSheet;
    WebPanel23: TPanel;
    WebButton57: TButton;
    WebButton58: TButton;
    WebButton59: TButton;
    WebPanel24: TPanel;
    WebLabel73: TLabel;
    WebButton60: TButton;
    WebStringGrid1: TStringGrid;
    WebPageControl1Sheet20: TTabSheet;
    WebPanel25: TPanel;
    WebLabel74: TLabel;
    WebButton61: TButton;
    WebPanel26: TPanel;
    WebButton62: TButton;
    WebButton63: TButton;
    WebButton64: TButton;
    WebStringGrid2: TStringGrid;
    WebPageControl1Sheet21: TTabSheet;
    WebPanel27: TPanel;
    WebLabel75: TLabel;
    WebButton65: TButton;
    WebPanel28: TPanel;
    WebButton66: TButton;
    WebButton67: TButton;
    WebButton68: TButton;
    WebStringGrid3: TStringGrid;
    WebPageControl1Sheet22: TTabSheet;
    WebLabel76: TLabel;
    WebEditAutoComplete1: TEditAutoComplete;
    WebPanel29: TPanel;
    WebButton71: TButton;
    WebButton72: TButton;
    WebButton73: TButton;
    WebPanel1: TPanel;
    WebImageControl13: TImageControl;
    WebMessageDlg1: TMessageDlg;
    WebPanel9: TPanel;
    WebButton4: TButton;
    WebButton56: TButton;
    WebButton2: TButton;
    WebButton6: TButton;
    WebButton3: TButton;
    WebButton11: TButton;
    WebButton10: TButton;
    WebButton5: TButton;
    WebButton17: TButton;
    WebPanel36: TPanel;
    WebPageControl1Sheet23: TTabSheet;
    WebLabel87: TLabel;
    WebEdit26: TEdit;
    WebButton79: TButton;
    WebButton80: TButton;
    WebPageControl9: TPageControl;
    WebPageControl9Sheet1: TTabSheet;
    WebPageControl9Sheet2: TTabSheet;
    WebPageControl9Sheet3: TTabSheet;
    WebResponsiveGridPanel1: TResponsiveGridPanel;
    WebPanel34: TPanel;
    WebPanel35: TPanel;
    WebLabel85: TLabel;
    WebLabel86: TLabel;
    WebRadioGroup3: TRadioGroup;
    WebEdit25: TEdit;
    WebButton55: TButton;
    WebButton78: TButton;
    WebCheckBox6: TCheckBox;
    WebPanel37: TPanel;
    WebButton81: TButton;
    WebButton82: TButton;
    WebRadioGroup2: TRadioGroup;
    WebLabel69: TLabel;
    WebEdit27: TEdit;
    WebLabel70: TLabel;
    WebTimer5: TTimer;
    WebClientConnection1: TClientConnection;
    WebPanel38: TPanel;
    WebLabel88: TLabel;
    WebListBox3: TListBox;
    WebColorPicker1: TColorPicker;
    WebLabel11: TLabel;
    WebEdit28: TEdit;
    WebLabel18: TLabel;
    WebEdit29: TEdit;
    WebLabel89: TLabel;
    WebPanel39: TPanel;
    WebListBox2: TListBox;
    WebLabel90: TLabel;
    WebPageControl9Sheet4: TTabSheet;
    WebPanel40: TPanel;
    WebPanel41: TPanel;
    WebTreeView1: TTreeView;
    WebEdit30: TEdit;
    WebLabel91: TLabel;
    WebEdit31: TEdit;
    WebLabel92: TLabel;
    WebButton83: TButton;
    WebButton84: TButton;
    WebButton85: TButton;
    WebButton86: TButton;
    MB_Panel: TPanel;
    WebTimer6: TTimer;
    WebTimer7: TTimer;
    WebPanel42: TPanel;
    WebRichEdit9: TRichEdit;
    WebButton87: TButton;
    WebPanel43: TPanel;
    WebLabel93: TLabel;
    WebPanel44: TPanel;
    WebSocketClient1: TSocketClient;
    WebPanel45: TPanel;
    WebLabel94: TLabel;
    WebButton88: TButton;
    WebPanel46: TPanel;
    WebEdit32: TEdit;
    WebButton89: TButton;
    WebLabel95: TLabel;
    WebLabel96: TLabel;
    WebTimer8: TTimer;
    WebButton90: TButton;
    WebButton91: TButton;
    WebButton92: TButton;
    WebStringGrid4: TStringGrid;
    WebPanel47: TPanel;
    WebPageControl2Sheet6: TTabSheet;
    WebImageControl10: TImageControl;
    WebPanel48: TPanel;
    WebButton93: TButton;
    WebButton94: TButton;
    WebButton95: TButton;
    WebButton96: TButton;
    WebButton97: TButton;
    WebButton98: TButton;
    WebButton99: TButton;
    WebButton100: TButton;
    WebButton101: TButton;
    WebButton102: TButton;
    WebButton103: TButton;
    WebButton104: TButton;
    WebButton105: TButton;
    StartTimer: TTimer;
    WebLabel8: TLabel;
    WebButton106: TButton;
    WebPageControl1Sheet24: TTabSheet;
    WebPanel49: TPanel;
    WebLabel10: TLabel;
    WebComboBox12: TComboBox;
    WebPanel50: TPanel;
    WebButton107: TButton;
    WebStringGrid5: TStringGrid;
    WebComboBox13: TComboBox;
    WebLabel97: TLabel;
    WebButton108: TButton;
    WebTimer9: TTimer;
    WebButton110: TButton;
    WebLabel98: TLabel;
    WebButton115: TButton;
    WebLabel99: TLabel;
    WebButton109: TButton;
    WebPanel51: TPanel;
    WebPageControl1Sheet25: TTabSheet;
    WebLabel100: TLabel;
    WebLabel101: TLabel;
    WebEdit33: TEdit;
    WebEdit34: TEdit;
    WebLabel102: TLabel;
    WebEdit35: TEdit;
    WebLabel103: TLabel;
    WebButton116: TButton;
    WebButton117: TButton;
    WebPageControl1Sheet26: TTabSheet;
    WebButton118: TButton;
    WebButton119: TButton;
    WebEdit36: TEdit;
    WebLabel104: TLabel;
    WebEdit37: TEdit;
    WebLabel105: TLabel;
    WebLabel106: TLabel;
    WebPageControl1Sheet27: TTabSheet;
    WebButton120: TButton;
    WebButton121: TButton;
    WebEdit38: TEdit;
    WebLabel107: TLabel;
    WebEdit39: TEdit;
    WebLabel108: TLabel;
    WebEdit40: TEdit;
    WebLabel109: TLabel;
    WebLabel110: TLabel;
    WebButton122: TButton;
    WebButton124: TButton;
    WebButton125: TButton;
    WebLabel111: TLabel;
    WebEdit41: TEdit;
    WebEdit42: TEdit;
    WebLabel112: TLabel;
    WebLabel113: TLabel;
    WebLabel114: TLabel;
    WebEdit43: TEdit;
    WebEdit44: TEdit;
    WebLabel115: TLabel;
    WebLabel116: TLabel;
    WebEdit45: TEdit;
    WebEdit46: TEdit;
    WebLabel117: TLabel;
    WebLabel118: TLabel;
    WebEdit47: TEdit;
    WebEdit48: TEdit;
    WebLabel119: TLabel;
    WebLabel120: TLabel;
    WebLabel121: TLabel;
    WebPanel52: TPanel;
    WebLabel122: TLabel;
    WebMediaCapture1: TMediaCapture;
    WebMultimediaPlayer1: TMultimediaPlayer;
    WebButton123: TButton;
    WebHttpRequest2: THttpRequest;
    WebLabel124: TLabel;
    WebLabel123: TLabel;
    WebHttpRequest3: THttpRequest;
    WebTimer10: TTimer;
    WebPanel53: TPanel;
    WebLabel125: TLabel;
    WebPageControl1Sheet28: TTabSheet;
    WebComboBox14: TComboBox;
    WebLabel126: TLabel;
    WebPanel54: TPanel;
    WebPanel55: TPanel;
    WebPanel56: TPanel;
    WebDBResponsiveGrid4: TDBResponsiveGrid;
    WebLabel127: TLabel;
    WebPanel57: TPanel;
    WebButton126: TButton;
    WebLabel128: TLabel;
    WebButton127: TButton;
    WebButton128: TButton;
    WebPageControl1Sheet29: TTabSheet;
    WebLabel129: TLabel;
    WebButton129: TButton;
    WebButton130: TButton;
    WebLabel130: TLabel;
    WebButton131: TButton;
    WebTimer11: TTimer;
    WebButton132: TButton;
    WebButton133: TButton;
    WebPageControl1Sheet30: TTabSheet;
    WebLabel131: TLabel;
    WebButton134: TButton;
    WebButton135: TButton;
    WebLabel132: TLabel;
    WebLabel133: TLabel;
    WebButton136: TButton;
    WebButton137: TButton;
    WebPopupMenu1: TPopupMenu;
    Prospekte1: TMenuItem;
    heken1: TMenuItem;
    N1: TMenuItem;
    MeinKonto1: TMenuItem;
    Anmelden1: TMenuItem;
    N2: TMenuItem;
    Chat1: TMenuItem;
    N3: TMenuItem;
    AllergieFilter1: TMenuItem;
    ErnhrungsFilter1: TMenuItem;
    LieferartenFilter1: TMenuItem;
    WebComboBox15: TComboBox;
    WebComboBox16: TComboBox;
    WebPanel58: TPanel;
    WebComboBox17: TComboBox;
    WebButton111: TButton;
    WebButton21: TButton;
    WebLabel22: TLabel;
    WebPageControl8Sheet3: TTabSheet;
    WebProgressBar2: TProgressBar;
    WebLabel19: TLabel;
    WebTimer12: TTimer;
    procedure WebFormCreate(Sender: TObject);
    procedure Zeige_Artikel(DataSet: TDataSet);
    procedure Zeige_Warenkorb(DataSet: TDataSet);
    procedure Zeige_WK_Artikel(DataSet: TDataSet);
    procedure Warenkorb_Fertig(DataSet: TDataSet);
    procedure VV_Fertig(DataSet: TDataSet);
    procedure Zeige_Theken_Artikel(DataSet: TDataSet);
    procedure Zeige_Artikel_Details(DataSet: TDataSet);
    procedure WebTimer1Timer(Sender: TObject);
    procedure WebPageControl1Change(Sender: TObject);
    procedure Lade_Artikel_Bilder;
    procedure Set_Artikel_Bilder(DataSet: TDataSet);
    procedure Zeige_Langtext(DataSet: TDataSet);
    procedure Zeige_Theken_Hint(DataSet: TDataSet);
    procedure WebTimer2Timer(Sender: TObject);
    procedure WebImageControl4Click(Sender: TObject);
    procedure WebImageControl5Click(Sender: TObject);
    procedure WebImageControl2Click(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure Lade_Kategorie_WG;
    procedure Zeige_Kat_WG(DataSet: TDataSet);
    procedure Zeige_Bewertungen(DataSet: TDataSet);

    procedure WebHttpRequest1Response(Sender: TObject; AResponse: string);
    procedure WebDBResponsiveGrid1ItemGetFieldValue(Sender: TObject;
      Index: Integer; AFieldName: string; var AValue: string);
    procedure WebComboBox3Change(Sender: TObject);
    procedure WebListBox1Change(Sender: TObject);
    procedure WebDBResponsiveGrid1ItemClick(Sender: TObject; Index: Integer);
    procedure WebButton2Click(Sender: TObject);
    procedure WebButton4Click(Sender: TObject);
    procedure WebButton3Click(Sender: TObject);
    procedure WebButton6Click(Sender: TObject);
    procedure WebButton5Click(Sender: TObject);
    procedure WebDBResponsiveGrid2ItemGetFieldValue(Sender: TObject;
      Index: Integer; AFieldName: string; var AValue: string);
    procedure WebButton7Click(Sender: TObject);
    procedure WebButton8Click(Sender: TObject);
    procedure WebButton10Click(Sender: TObject);
    procedure WebButton12Click(Sender: TObject);
    procedure WebButton15Click(Sender: TObject);
    procedure WebButton14Click(Sender: TObject);
    procedure WebButton13Click(Sender: TObject);
    procedure WebLabel4Click(Sender: TObject);
    procedure WebLabel5Click(Sender: TObject);
    procedure WebLabel7Click(Sender: TObject);
    procedure WebDBResponsiveGrid2ItemClick(Sender: TObject; Index: Integer);
    procedure WebLabel6Click(Sender: TObject);
    procedure WebButton11Click(Sender: TObject);
    procedure Check_User_Password(In_Kasse : boolean);
    procedure WebButton1Click(Sender: TObject);
    procedure Update_Buttons;
    procedure WebDBResponsiveGrid3ItemGetFieldValue(Sender: TObject;
      Index: Integer; AFieldName: string; var AValue: string);
    procedure Del_ButtonClick(Sender : TObject);
    procedure WebDBResponsiveGrid3ItemClick(Sender: TObject; Index: Integer);
    procedure WebDBResponsiveGrid3ItemCreated(Sender: TObject; Index: Integer);
    procedure Edit_ButtonClick(Sender : TObject);
    procedure WebButton17Click(Sender: TObject);
    procedure WebButton19Click(Sender: TObject);
    procedure WebButton18Click(Sender: TObject);
    procedure WebButton20Click(Sender: TObject);
    procedure WebButton21Click(Sender: TObject);
    procedure Zeige_Live_Bild;
    procedure WebTimer3Timer(Sender: TObject);
    procedure WebImageControl1MouseDown(Sender: TObject; Button: TMouseButton;
      Shift: TShiftState; X, Y: Integer);
    procedure WebImageControl2MouseDown(Sender: TObject; Button: TMouseButton;
      Shift: TShiftState; X, Y: Integer);
    procedure WebImageControl5MouseDown(Sender: TObject; Button: TMouseButton;
      Shift: TShiftState; X, Y: Integer);
    procedure WebImageControl4MouseDown(Sender: TObject; Button: TMouseButton;
      Shift: TShiftState; X, Y: Integer);
    procedure Theken_Bild_Click(x,y : integer);
    procedure WebImageControl4MouseMove(Sender: TObject; Shift: TShiftState; X,
      Y: Integer);
    procedure Mouse_Move_Thekenbild(x,y : integer);
    procedure WebPageControl3Change(Sender: TObject);
    procedure Loesche_Theken_Regionen;
    procedure WebImageControl1MouseMove(Sender: TObject; Shift: TShiftState; X,
      Y: Integer);
    procedure WebImageControl2MouseMove(Sender: TObject; Shift: TShiftState; X,
      Y: Integer);
    procedure WebImageControl5MouseMove(Sender: TObject; Shift: TShiftState; X,
      Y: Integer);
    procedure WebPayPal1PaymentCancelled(Sender: TObject);
    procedure WebPayPal1PaymentDone(Sender: TObject;
      Args: TPayPalPaymentEventArgs);
    procedure WebPayPal1PaymentError(Sender: TObject;
      Args: TPayPalErrorEventArgs);
    procedure ShowError(ErrorTitle, ErrorMessage: string);
    procedure WebButton26Click(Sender: TObject);
    procedure WebButton27Click(Sender: TObject);
    procedure InitHeader;
    procedure WebButton34Click(Sender: TObject);
    procedure WebButton31Click(Sender: TObject);
    procedure WebButton29Click(Sender: TObject);
    procedure WebButton30Click(Sender: TObject);
    procedure WebButton28Click(Sender: TObject);
    procedure WebRadioGroup1Change(Sender: TObject);
    procedure WebButton37Click(Sender: TObject);
    procedure WebButton32Click(Sender: TObject);
    procedure WebButton33Click(Sender: TObject);
    procedure WebButton39Click(Sender: TObject);
    procedure WebButton40Click(Sender: TObject);
    procedure WebButton42Click(Sender: TObject);
    procedure WebButton44Click(Sender: TObject);
    procedure WebButton43Click(Sender: TObject);
    procedure WebButton38Click(Sender: TObject);
    procedure WebButton36Click(Sender: TObject);
    procedure WebButton35Click(Sender: TObject);
    procedure WebButton41Click(Sender: TObject);
    procedure WebButton46Click(Sender: TObject);
    procedure WebButton45Click(Sender: TObject);
    procedure WebButton48Click(Sender: TObject);
    procedure WebButton47Click(Sender: TObject);
    procedure WebButton49Click(Sender: TObject);
    procedure WebButton50Click(Sender: TObject);
    procedure WebButton52Click(Sender: TObject);
    procedure Check_Angemeldet;
    procedure WebPageControl4Change(Sender: TObject);
    procedure WebButton24Click(Sender: TObject);
    procedure WebButton22Click(Sender: TObject);
    procedure WebButton23Click(Sender: TObject);
    procedure Check_Get_WK_ID;
    procedure Lese_Warenkorb;
    procedure WebFormShow(Sender: TObject);
    function GetBeiwert : String;
    procedure WebPageControl2Change(Sender: TObject);
    procedure WebButton56Click(Sender: TObject);
    procedure WebImageSlider2ImageClick(Sender: TObject; ImageIndex: Integer);
    procedure WebImageZoomControl2Click(Sender: TObject);
    procedure Check_VV_Verfuegbar;
    procedure Check_Bestand;
    procedure Refresh_ShopArtikel(ID : integer);
    procedure WebButton59Click(Sender: TObject);
    procedure WebButton58Click(Sender: TObject);
    procedure WebButton57Click(Sender: TObject);
    procedure WebImageControl7Click(Sender: TObject);
    procedure WebButton60Click(Sender: TObject);
    procedure WebStringGrid1DblClick(Sender: TObject);
    procedure WebCheckBox1Click(Sender: TObject);
    procedure WebButton61Click(Sender: TObject);
    procedure WebStringGrid2DblClick(Sender: TObject);
    procedure WebButton62Click(Sender: TObject);
    procedure WebButton63Click(Sender: TObject);
    procedure WebButton64Click(Sender: TObject);
    procedure WebImageControl8Click(Sender: TObject);
    procedure WebButton65Click(Sender: TObject);
    procedure WebStringGrid3DblClick(Sender: TObject);
    procedure WebButton66Click(Sender: TObject);
    procedure WebButton67Click(Sender: TObject);
    procedure WebButton68Click(Sender: TObject);
    procedure WebImageControl9Click(Sender: TObject);
    procedure WebButton71Click(Sender: TObject);
    procedure WebButton72Click(Sender: TObject);
    procedure WebButton73Click(Sender: TObject);
    procedure Lese_Wort_Liste;
    procedure WebComboBox10Change(Sender: TObject);
    procedure WebTimer4Timer(Sender: TObject);
    procedure WebPageControl7Change(Sender: TObject);
    procedure WebButton74Click(Sender: TObject);
    procedure WebButton70Click(Sender: TObject);
    procedure WebButton75Click(Sender: TObject);
    procedure WebButton69Click(Sender: TObject);
    procedure WebButton76Click(Sender: TObject);
    procedure WebButton77Click(Sender: TObject);
    procedure WebComboBox11Change(Sender: TObject);
    procedure WebImageControl13DblClick(Sender: TObject);
    procedure WebButton79Click(Sender: TObject);
    procedure WebButton80Click(Sender: TObject);
    procedure WebButton81Click(Sender: TObject);
    procedure WebPageControl8Change(Sender: TObject);
    procedure WebListBox2Click(Sender: TObject);
    procedure WebButton82Click(Sender: TObject);
    procedure WebRadioGroup2Change(Sender: TObject);
    procedure Set_Display_Options;
    procedure Do_Display_Options(DataSet: TDataSet);
    procedure WebTimer5Timer(Sender: TObject);
//    procedure Konfig_Open(DataSet: TDataSet);
    procedure WebClientConnection1ConnectError(Sender: TObject;
      ErrorCode: Integer);
    procedure WebClientConnection1AfterConnect(Sender: TObject);
    procedure Set_Grid_Templates;
    procedure WebListBox3Change(Sender: TObject);
    procedure WebColorPicker1Select(Sender: TObject);
    procedure WebButton83Click(Sender: TObject);
    function Add_Menu(Parent : integer;Bez : String) : integer;
    procedure WebPageControl9Change(Sender: TObject);
    procedure Lese_Menus;
    procedure Mache_Menu_Baum(DataSet: TDataSet);
    procedure WebTreeView1Click(Sender: TObject);
    procedure MB_MouseEnter(Sender: TObject);
    procedure MB_MouseLeave(Sender: TObject);
    procedure MB_PanelMouseLeave(Sender: TObject);
    procedure WebTimer6Timer(Sender: TObject);
    procedure MB_PanelMouseEnter(Sender: TObject);
    procedure WebTimer7Timer(Sender: TObject);
    procedure Loesche_Menu_Labels;
    procedure MB_Click(Sender: TObject);
    procedure WebButton87Click(Sender: TObject);
    procedure WebButton88Click(Sender: TObject);
    procedure WebSocketClient1Connect(Sender: TObject);
    procedure WebSocketClient1DataReceived(Sender: TObject; Origin: string;
      SocketData: TJSObjectRecord);
    procedure WebSocketClient1Disconnect(Sender: TObject);
    procedure WebButton89Click(Sender: TObject);
    procedure WebTimer8Timer(Sender: TObject);
    procedure WebButton90Click(Sender: TObject);
    procedure WebButton91Click(Sender: TObject);
    procedure WebButton93Click(Sender: TObject);
    procedure WebButton94Click(Sender: TObject);
    procedure WebButton95Click(Sender: TObject);
    procedure WebButton96Click(Sender: TObject);
    procedure WebButton97Click(Sender: TObject);
    procedure WebButton98Click(Sender: TObject);
    procedure WebButton99Click(Sender: TObject);
    procedure WebButton100Click(Sender: TObject);
    procedure WebButton102Click(Sender: TObject);
    procedure WebButton101Click(Sender: TObject);
    procedure WebButton103Click(Sender: TObject);
    procedure WebButton105Click(Sender: TObject);
    procedure WebButton104Click(Sender: TObject);
    procedure StartTimerTimer(Sender: TObject);
    procedure WebButton106Click(Sender: TObject);
    procedure Check_Best_Listen;
    procedure Fuelle_Best_List_Combo(DataSet: TDataSet);
    procedure WebComboBox12Change(Sender: TObject);
    procedure Refresh_Bestell_Liste;
    procedure Fuelle_Best_Liste(DataSet: TDataSet);
    procedure WebButton107Click(Sender: TObject);
    procedure WebButton108Click(Sender: TObject);
    procedure WebTimer9Timer(Sender: TObject);
    procedure WebStringGrid5Click(Sender: TObject);
    procedure WebButton109Click(Sender: TObject);
    procedure WebButton110Click(Sender: TObject);
    procedure WebAccordion1RenderSection(Sender: TObject;
      ASection: TAccordionSection; ACaption, APanel: TJSHTMLElementRecord);
    procedure WebButton25Click(Sender: TObject);
    procedure Close_Konto_Menu;
    procedure Mache_Konto_Menu;
    procedure KMB_Click(Sender : TObject);
    procedure WebButton53Click(Sender: TObject);
    procedure WebButton54Click(Sender: TObject);
    procedure Zeige_Rechnungs_Adresse(DataSet: TDataSet);
    procedure Speichern_Rechnungs_Adresse(DataSet: TDataSet);
    procedure Speichern_Liefer_Adresse(DataSet: TDataSet);
    procedure Zeige_Liefer_Adresse(DataSet: TDataSet);
    procedure WebButton117Click(Sender: TObject);
    procedure WebButton116Click(Sender: TObject);
    procedure Speichern_eMail_Adresse(DataSet: TDataSet);
    procedure Speichern_Telefon(DataSet: TDataSet);
    procedure WebButton118Click(Sender: TObject);
    procedure Zeige_Telefon(DataSet: TDataSet);
    procedure WebButton122Click(Sender: TObject);
    procedure WebButton125Click(Sender: TObject);
    procedure WebButton124Click(Sender: TObject);
    procedure WebButton120Click(Sender: TObject);
    procedure Speichern_Password(DataSet: TDataSet);
    procedure Zeige_Naehrwerte(DataSet: TDataSet);
    procedure Zeige_Zutaten(DataSet: TDataSet);
    procedure WebButton123Click(Sender: TObject);
    procedure WebMediaCapture1StopCapture(Sender: TObject;
      ABinary: TJSUint8Array; ABase: string);
    procedure PlayRecording(ABase: string);
    procedure WebHttpRequest2Response(Sender: TObject; AResponse: string);
    procedure WebHttpRequest2Error(Sender: TObject;
      ARequest: TJSXMLHttpRequestRecord; Event: TJSEventRecord;
      var Handled: Boolean);
    procedure WebButton126Click(Sender: TObject);
    procedure WebHttpRequest2RequestResponse(Sender: TObject;
      ARequest: TJSXMLHttpRequestRecord; AResponse: string);
    procedure WebButton123MouseDown(Sender: TObject; Button: TMouseButton;
      Shift: TShiftState; X, Y: Integer);
    procedure WebButton123MouseUp(Sender: TObject; Button: TMouseButton;
      Shift: TShiftState; X, Y: Integer);
    procedure Lies_Vor(hst : String);
    procedure WebHttpRequest3Response(Sender: TObject; AResponse: string);
    procedure WebTimer10Timer(Sender: TObject);
    procedure WebButton92Click(Sender: TObject);
    procedure WebComboBox14Change(Sender: TObject);
    procedure WebDBResponsiveGrid4ItemGetFieldValue(Sender: TObject;
      Index: Integer; AFieldName: string; var AValue: string);
    procedure WebDBResponsiveGrid4ItemClick(Sender: TObject; Index: Integer);
    procedure Meldungs_Fenster(st,st2 : String;Art : integer;Y_Proc,N_Proc,C_Proc : TNotifyEvent);
    procedure WebButton129Click(Sender: TObject);
    procedure WebButton130Click(Sender: TObject);
    procedure WebButton131Click(Sender: TObject);
    procedure WebTimer11Timer(Sender: TObject);
    procedure WebButton132Click(Sender: TObject);
    procedure WebButton134Click(Sender: TObject);
    procedure WebButton135Click(Sender: TObject);
    procedure Resize_Panel_9;
    procedure WebButton137Click(Sender: TObject);
    procedure Prospekte1Click(Sender: TObject);
    procedure heken1Click(Sender: TObject);
    procedure MeinKonto1Click(Sender: TObject);
    procedure Anmelden1Click(Sender: TObject);
    procedure Chat1Click(Sender: TObject);
    procedure AllergieFilter1Click(Sender: TObject);
    procedure ErnhrungsFilter1Click(Sender: TObject);
    procedure LieferartenFilter1Click(Sender: TObject);
    procedure WebComboBox15Change(Sender: TObject);
    procedure WebComboBox16Change(Sender: TObject);
    procedure WebComboBox17Change(Sender: TObject);
    procedure WebButton111Click(Sender: TObject);
    procedure WebButton115Click(Sender: TObject);
    procedure WebTimer12Timer(Sender: TObject);
    procedure WebDBGrid1GetCellData(Sender: TObject; ACol, ARow: Integer;
      AField: TField; var AValue: string);
  private
    { Private-Deklarationen }
  public
    { Public-Deklarationen }
//    arrowbt1, arrowbt2, arrowbt3: TJSHTMLElement;
    Akt_Kat_Proc : String;
    WGA,
    SKA,
    SKAF : array[0 .. 99] of integer;
    Akt_Section: TAccordionSection;
    Akt_ScrollPos,
    Akt_Index,
    Akt_Kat,
    Akt_WG : integer;
    Kiosk_Modus,
    Ohne_Check_Kategorie : boolean;
    ME_Array : array[0 .. 8] of ME_Rec;
    Schnitt_Array : array[0 .. 16] of integer;
    Akt_Modus : integer;
    Akt_Kiosk_Index,
    Theken_Wahl,
    Akt_Theken_ID : integer;
    Regionen : array[0 .. 500] of TList;
    Zoom_Faktor_x,
    Zoom_Faktor_y : real;
    Theken_Hint : String;
    Auswahl_Checkout,
    Save_Tab_Index,
    Save_Tab_Index2 : integer;
    ZMA : array[0 .. 10] of ZM_Rec;
    Login_HM : boolean;
    WebT1Cnt : integer;
    Warenkorb_Gelesen : boolean;
    VV_ID : integer;
    VVA : array[1 .. Max_VVA] of integer;
    VVA_Cnt : integer;
    VV_Aktiv : boolean;
    LieferListe,
    VeganListe,
    AllergieListe : TStringList;
    Save_LieferFilter,
    LieferFilter,
    Save_VeganFilter,
    VeganFilter,
    Save_AllergieFilter,
    AllergieFilter : Array[0 .. 20] of boolean;
    AllergenNo,
    VeganNo,
    LieferArtNo  : array[0 .. 20] of integer;
    Save_Text_Filter : String;
    Save_Farben,
    Karten_Farben  : array[1 .. 6] of integer;
    Save_KG,
    Karten_Groesse : array[1 .. 6] of integer;
    Mit_Color_Change : boolean;
    Akt_Tree_Item,
    Akt_Menu,
    Menu_Root_ID : integer;
    Menu_Root : TTreeNode;
    Bin_im_Menu : boolean;
    Menu_Labels : TList;
    Akt_Auswahl_Menu : integer;
    Chat_Verbunden : boolean;
    VB_Vorschlag : integer;
    VB_Bem : String;
    Chat_Live_Bild : String;
    Chat_Live_ID : integer;
    Chat_Anmeldung : boolean;
    Chat_Abmeldung : boolean;
    Start_Phasen_Array : array[0 .. Max_Start_Phasen] of integer;
    Start_Phase_Aktiv : array[0 .. Max_Start_Phasen] of boolean;
    BestLA : array[0 .. 20] of integer;
    Bestell_Liste : TStringList;
    LoeschModus_WK : boolean;
    Akt_Konto_Menu : integer;
    Konto_Buttons : array[1 .. 10] of TButton;
    In_Konto_Menu : boolean;
    FRecording : boolean;
    Vorles_Liste : TStringList;
    LautSprecher_ein : boolean;
    Kiosk_Modus_Aktiv : boolean;
    Frage_Ergebnis : integer;
    Yes_Procedure,
    No_Procedure,
    Cancel_Procedure : TNotifyEvent;
    WebTimer12Cnt,
    GT : integer;
    procedure SendOrderConfirmation(Email, OrderID: string; Items: TPayPalItems);

  protected procedure LoadDFMValues; override; end;


  TCartItem = class(TPersistent)
  private
    FName: string;
    FImageUrl: string;
    FPrice: Double;
    FCategory: Integer;
    FDescription: string;
    FSize: string;
  public
    property Name: string read FName write FName;
    property Description: string read FDescription write FDescription;
    property Price: Double read FPrice write FPrice;
    property Category: Integer read FCategory write FCategory;
    property Size: string read FSize write FSize;
    property ImageUrl: string read FImageUrl write FImageUrl;
  end;



var
  Form2: TForm2;

implementation

{$R *.dfm}


{$I APPIDS.INC}
{$I BACKEND.INC}
{$I HEADER.INC}


procedure Set_Button_Class(Art : Integer; B : TButton);
begin
  if Art = 1 then begin
//  B.ElementClassName := 'btn btn';//'btn btn-outline-primary';// 'btn btn-primary';
//  B.ElementClassName := 'btn btn-dark';//'btn btn-outline-primary';// 'btn btn-primary';
//  B.ElementClassName := 'btn btn-light';//'btn btn-outline-primary';// 'btn btn-primary';
    B.ElementClassName := 'btn btn-secondary';// 'btn btn-primary';
//  B.ElementClassName := 'btn btn-info';// 'btn btn-primary';


    B.ElementFont := efCSS;
  end
  else if Art = 3 then begin
    B.ElementClassName := 'btn btn-danger';// 'btn btn-primary';
    B.ElementFont := efCSS;
  end


end;


procedure Set_Panel_Class(Art : Integer; P : TPanel);
begin
  P.ElementClassName := 'bg-primary mainform';
  P.ElementFont := efCSS;

end;


procedure Set_Label_Class(Art : Integer; L : TLabel);
begin
  case Art of
    1 : begin
          L.ElementClassName := 'badge badge-primary';
          L.ElementFont := efCSS;
        end;
    2 : begin
          L.ElementClassName := 'h5';
//          L.ElementClassName := 'h2';
          L.ElementFont := efCSS;
          L.ElementLabelClassName := 'badge badge-primary';
        end;

    3 : begin
          if Device_ist_Handy then begin
            L.ElementClassName := 'h5';
            L.ElementFont := efCSS;
            L.ElementLabelClassName := 'badge badge-primary';
          end
          else begin
            L.ElementClassName := 'h2';
            L.ElementFont := efCSS;
            L.ElementLabelClassName := 'badge badge-primary';
          end;
        end;

    4 : begin
          L.ElementClassName := 'badge badge-danger';
          L.ElementFont := efCSS;

//          L.ElementClassName := 'h3';
//          L.ElementFont := efCSS;
//          L.ElementLabelClassName := 'badge badge-danger';
        end;

  end;
end;

procedure Set_Checkbox_Class(Art : Integer; L : TCheckbox);
begin
  case Art of
    1 : begin
          L.ElementClassName := 'h5';
          L.ElementFont := efCSS;
          L.ElementLabelClassName := 'badge badge-secondary';
        end;
  end;
end;




procedure Set_TabSheet_Class(Art : Integer; T : TTabSheet);
begin
  T.ElementClassName := 'bg-primary mainform';
//  T.ElementClassName := 'btn btn-secondary';
  T.ElementFont := efCSS;

end;


procedure Set_Richedit_Class(Art : Integer;R : TRichEdit);
begin
  R.ElementClassName := 'bg-secondary';
  R.ElementFont := efCSS;

end;

procedure Set_Listbox_Class(Art : Integer;L : TListBox);
begin
//  L.ElementClassName := 'bg-secondary';
//  L.ElementFont := efCSS;

end;




procedure TForm2.WebAccordion1RenderSection(Sender: TObject;
  ASection: TAccordionSection; ACaption, APanel: TJSHTMLElementRecord);
var
  lb: TListControl;

begin
  ACaption.element.classList.add('accordion_extra');

  case ASection.Index of
  0:
    begin
//      ACaption.element.style.setProperty('background-color','#E5413C');
      lb := TListControl.Create(Self);
      lb.ParentElement := APanel.element;
      lb.HeightStyle := ssAuto;
      lb.WidthStyle := ssAuto;
      lb.Style := lsListGroup;
      lb.Items.Add.Text := 'Rechnungs-Adresse';
      lb.Items.Add.Text := 'Liefer-Adresse';
      lb.Items.Add.Text := 'email-Adresse';
      lb.Items.Add.Text := 'Telefon';
      lb.Items.Add.Text := 'Kennwort ändern';
      lb.ElementClassName := 'table-secondary';
      lb.ElementFont := efCSS;


{      lb.Items[0].AddLink('https://www.tmssoftware.com/site/tmspack.asp','_blank');
      lb.Items[0].Hint := 'Productivity feature-packed Windows user interface controls';
      lb.Items[1].AddLink('https://www.tmssoftware.com/site/vdsub.asp','_blank');
      lb.Items[1].Hint := 'Our strongest VCL components for Windows bundled';
      lb.Items[2].AddLink('https://www.tmssoftware.com/site/webgmaps.asp','_blank');
      lb.Items[2].Hint := 'Google mapping made easy for VCL Windows applications';
      lb.Items[3].AddLink('https://www.tmssoftware.com/site/cloudpack.asp','_blank');
      lb.Items[3].Hint := 'Connect with ease to popular cloud services';
      lb.Items[4].AddLink('https://www.tmssoftware.com/site/advchart.asp','_blank');
      lb.Items[4].Hint := 'Advanced charting for VCL Windows applications';
}
    end;
  1:
    begin
//      ACaption.element.style.setProperty('background-color','#E5413C');
      lb := TListControl.Create(Self);
      lb.ParentElement := APanel.element;
      lb.HeightStyle := ssAuto;
      lb.WidthStyle := ssAuto;
      lb.Style := lsListGroup;
      lb.Items.Add.Text := 'Offene Bestellungen';
      lb.Items.Add.Text := 'Erledigte Bestellungen';
      lb.ElementClassName := 'table-secondary';
      lb.ElementFont := efCSS;

{      lb.Items[0].AddLink('https://www.tmssoftware.com/site/tmspack.asp','_blank');
      lb.Items[0].Hint := 'Productivity feature-packed Windows user interface controls';
      lb.Items[1].AddLink('https://www.tmssoftware.com/site/vdsub.asp','_blank');
      lb.Items[1].Hint := 'Our strongest VCL components for Windows bundled';
      lb.Items[2].AddLink('https://www.tmssoftware.com/site/webgmaps.asp','_blank');
      lb.Items[2].Hint := 'Google mapping made easy for VCL Windows applications';
      lb.Items[3].AddLink('https://www.tmssoftware.com/site/cloudpack.asp','_blank');
      lb.Items[3].Hint := 'Connect with ease to popular cloud services';
      lb.Items[4].AddLink('https://www.tmssoftware.com/site/advchart.asp','_blank');
      lb.Items[4].Hint := 'Advanced charting for VCL Windows applications';
}
    end;
  2:
    begin
//      ACaption.element.style.setProperty('background-color','#E5413C');
      lb := TListControl.Create(Self);
      lb.ParentElement := APanel.element;
      lb.HeightStyle := ssAuto;
      lb.WidthStyle := ssAuto;
      lb.Style := lsListGroup;
      lb.Items.Add.Text := 'Offene Rechnungen';
      lb.Items.Add.Text := 'Bezahlte Rechnungen';
      lb.ElementClassName := 'table-secondary';
      lb.ElementFont := efCSS;

{      lb.Items[0].AddLink('https://www.tmssoftware.com/site/tmspack.asp','_blank');
      lb.Items[0].Hint := 'Productivity feature-packed Windows user interface controls';
      lb.Items[1].AddLink('https://www.tmssoftware.com/site/vdsub.asp','_blank');
      lb.Items[1].Hint := 'Our strongest VCL components for Windows bundled';
      lb.Items[2].AddLink('https://www.tmssoftware.com/site/webgmaps.asp','_blank');
      lb.Items[2].Hint := 'Google mapping made easy for VCL Windows applications';
      lb.Items[3].AddLink('https://www.tmssoftware.com/site/cloudpack.asp','_blank');
      lb.Items[3].Hint := 'Connect with ease to popular cloud services';
      lb.Items[4].AddLink('https://www.tmssoftware.com/site/advchart.asp','_blank');
      lb.Items[4].Hint := 'Advanced charting for VCL Windows applications';
}
    end;




  end;
end;

procedure TForm2.WebButton100Click(Sender: TObject);
begin
  if Chat_Verbunden then begin
    WebSocketClient1.Send('@Bild:'+IntToStr(Akt_Artikel_ID));
  end;
end;

procedure TForm2.WebButton101Click(Sender: TObject);
var i : integer;
begin
  Lese_Wort_Liste;
  exit;

end;

procedure TForm2.WebButton102Click(Sender: TObject);
begin
  WebEdit2.Text := '';
  WebEditAutoComplete1.Text := '';
  Refresh_ShopArtikel(0);

end;

procedure TForm2.WebButton103Click(Sender: TObject);
var i : integer;
begin
//  WebListBox2.ItemIndex := -1;
  for i := 0 to AllergieListe.Count - 1 do begin
    Save_AllergieFilter[i] := AllergieFilter[i];
  end;
  Save_Tab_Index := WebPageControl1.TabIndex;
  WebPageControl1.TabIndex := 18;
  WebPageControl1Change(Self);
  WebPanel9.Visible := false;

end;

procedure TForm2.WebButton104Click(Sender: TObject);
var i : integer;
begin
  for i := 0 to VeganListe.Count - 1 do begin
    Save_VeganFilter[i] := VeganFilter[i];
  end;
  Save_Tab_Index := WebPageControl1.TabIndex;
  WebPageControl1.TabIndex := 19;
  WebPageControl1Change(Self);
  WebPanel9.Visible := false;
end;

procedure TForm2.WebButton105Click(Sender: TObject);
var i : integer;
begin
  for i := 0 to LieferListe.Count - 1 do begin
    Save_LieferFilter[i] := LieferFilter[i];
  end;
  Save_Tab_Index := WebPageControl1.TabIndex;
  WebPageControl1.TabIndex := 20;
  WebPageControl1Change(Self);
  WebPanel9.Visible := false;
end;

procedure TForm2.WebButton106Click(Sender: TObject);
begin
  Save_Tab_Index := WebPageControl1.TabIndex;
  WebPanel36.Visible := false;
  WebPanel9.Visible := false;

  WebPageControl1.TabIndex := 23;
  WebPageControl1Change(Self);
end;

procedure TForm2.WebButton107Click(Sender: TObject);
var i,j,k,i2,j2,i3,CBA,Anzahl : integer;
    r : real;
    hst,hst2,hst3,MStr,EPStr : String;
    Feh_Gef,
    gef : boolean;
    BatchStr : String;
begin
  if not DataModule1.XDataWebConnection2.Connected then begin
    DataModule1.XDataWebConnection2.Connected := true;
    MessageDlg('Keine Verbindung zum Server, Versuchen Sie es noch einmal', mtError, [], nil);
    exit;
  end;
  k := 0;
  for i := 1 to WebStringGrid5.RowCount-1 do begin
    hst := Trim(WebStringGrid5.Cells[4,i]);
    hst2 := Trim(WebStringGrid5.Cells[5,i]);
    if (hst <> '') and (hst2 <> '') then begin
      MessageDlg('Gleichzeite Eingabe in Spalte "Bestell-Menge (kg)" und "Bestell-Menge (Stück)" nicht erlaubt', mtError, [], nil);
      WebStringGrid5.Row := i;
      WebStringGrid5.Col := 4;
      exit;
    end;

    r := 0.0;
    j := 0;
    gef := false;
    if (hst2 <> '') then begin
      i2 := 0;
      j := 0;
      val(hst2,j,i2);
      if (j <= 0) or (i2 <> 0) then begin
        MessageDlg('Eingabe in Spalte "Bestell-Menge (Stück)" ist ungültig', mtError, [], nil);
        WebStringGrid5.Row := i;
        WebStringGrid5.Col := 5;
        exit;
      end;
      gef := true;
    end;
    if (hst <> '') then begin
      if not Str_to_real(hst,r,0.0,0.0) then begin
        MessageDlg('Eingabe in Spalte "Bestell-Menge (kg)" ist ungültig', mtError, [], nil);
        WebStringGrid5.Row := i;
        WebStringGrid5.Col := 4;
        exit;
      end;
      if r <= 0.0 then begin
        MessageDlg('Eingabe in Spalte "Bestell-Menge (kg)" ist ungültig', mtError, [], nil);
        WebStringGrid5.Row := i;
        WebStringGrid5.Col := 4;
        exit;
      end;
      gef := true;
    end;
    if gef then begin
      k := k + 1;
    end;
  end;
  if k = 0 then begin
    MessageDlg('Sie haben keine Einträge in den Bestell-Mengen erfasst', mtError, [], nil);
    exit;
  end;
  BatchStr := '';
  Anzahl := k;
  k := 0;
  Feh_Gef := false;
  for i3 := 1 to WebStringGrid5.RowCount-1 do begin
    hst := Trim(WebStringGrid5.Cells[4,i3]);
    hst2 := Trim(WebStringGrid5.Cells[5,i3]);
    r := 0.0;
    j := 0;
    gef := false;
    if (hst2 <> '') then begin
      i2 := 0;
      j := 0;
      val(hst2,j,i2);

      Str(j:10,MStr);
      MStr := Trim(MStr);
      CBA := 2;
      gef := true;
    end
    else if (hst <> '') then begin
      if not Str_to_real(hst,r,0.0,0.0) then begin

      end;
      Str(r*1000:16:0,MStr);
      MStr := Trim(MStr);
      CBA := 1;
      gef := true;
    end;
    if gef then begin
      hst3 := Bestell_Liste[i3-1];
      EPStr := '0';
      BatchStr := BatchStr + hst3 + ';';
      BatchStr := BatchStr + Mstr + ';';
      BatchStr := BatchStr + EPstr + ';';
      BatchStr := BatchStr + IntToStr(CBA) + ';';
      BatchStr := BatchStr + IntToStr(0) + ';';
      BatchStr := BatchStr + IntToStr(0) + ';';
      BatchStr := BatchStr + IntToStr(0) + ';';
      BatchStr := BatchStr + ';';
    end;
  end;
  if DataModule1.XDataWebConnection2.Connected then begin
    DataModule1.XDataWebClientAuth.RawInvoke('ILoginService.Add_to_WK_2', [IntToStr(AktFirmaNo),My_Cookie,hst3,
       MStr,EPStr,IntToStr(CBA),
       IntToStr(0),IntToStr(0),
       '','1',GetBeiwert,BatchStr],
    procedure(Response: TXDataClientResponse)
    var
      i: integer;
      hst : String;
    begin
      hst := JS.ToString(JS.ToObject(Response.Result)['value']);
      if hst <> '' then begin
        i := StrToInt(hst);
        if i <= 0 then begin
          MessageDlg('Artikel nicht gespeichert (1)', mtError, [], nil);
          Feh_Gef := true;
        end
        else begin
          for i := 1 to WebStringGrid5.RowCount-1 do begin
            WebStringGrid5.Cells[4,i] := '';
            WebStringGrid5.Cells[5,i] := '';
          end;
          MessageDlg('Artikel wurden erfolgreich in den Warenkorb gelegt', mtInformation, [], nil);
          Lese_Warenkorb;
        end;
      end
      else begin
        MessageDlg('Speichern nicht möglich  (2)', mtError, [], nil);
        Feh_Gef := true;
      end;
    end,
    procedure(Error: TXDataClientError)
    begin
      MessageDlg('Speichern nicht möglich  (3)', mtError, [], nil);
      Feh_Gef := true;
    end

    );
  end;
end;

procedure TForm2.WebButton108Click(Sender: TObject);
var hst3 : String;
begin
  if LoeschModus_WK then begin
    LoeschModus_WK := false;
    WebTimer9.Enabled := false;
    hst3 := IntToStr(Akt_WK_ID);
    DataModule1.XDataWebClientAuth.RawInvoke('ILoginService.Delete_All_WK', [IntToStr(AktFirmaNo),My_Cookie,hst3,GetBeiwert],
    procedure(Response: TXDataClientResponse)
    var
      i: integer;
      hst : String;
    begin
      hst := JS.ToString(JS.ToObject(Response.Result)['value']);
      if hst <> '' then begin
        i := StrToInt(hst);
        if i <= 0 then begin
          MessageDlg('Artikel nicht gelöscht', mtError, [], nil);
        end
        else begin
          WK_Artikel := 0;
          Update_Buttons;
          WebButton6Click(Self);
          Lese_Warenkorb;
        end;
      end
      else begin
        MessageDlg('Artikel nicht gelöscht', mtError, [], nil);
      end;
    end,
    procedure(Error: TXDataClientError)
    begin
      MessageDlg('Artikel nicht gelöscht', mtError, [], nil);
    end
    );
  end
  else begin
    MessageDlg('Klicken Sie binnen 15 Sekunden auf den Bestätigungs-Button um alle Artikel zu löschen', mtInformation, [], nil);
    WebTimer9.Enabled := true;
    LoeschModus_WK := true;
  end;
  Update_Buttons;
end;

procedure TForm2.WebButton109Click(Sender: TObject);
begin
  WebPanel9.Visible := true;
  WebPageControl1.TabIndex := Save_Tab_Index;
  WebPageControl1Change(Self);
end;

procedure TForm2.WebButton10Click(Sender: TObject);
begin
  if Akt_Geraete_Art = GA_Handy then begin
    WebPanel9.Height := 100; // WebPanel9.Height * 2;
    WebPanel1.Height := 105;
    WebButton10.Visible := false;
    WebButton2.Visible := true;
    WebButton6.Visible := true;
    WebButton3.Visible := true;
    WebButton5.Visible := true;
    WebButton137.Visible := true;

  end
  else begin
    WebPanel9.Height := 50;
    WebPanel1.Height := 105;
    WebButton10.Visible := false;
    WebButton2.Visible := true;
    WebButton6.Visible := true;
    WebButton3.Visible := true;
    WebButton5.Visible := true;
    WebButton4.Visible := true;
    WebButton11.Visible := true;
    WebButton17.Visible := true;
    WebButton56.Visible := true;
    WebButton90.Visible := true;

  end;
  if Akt_User_ID <> 0 then begin
    if WebComboBox12.Items.Count > 0 then begin
      if Akt_Geraete_Art <> GA_Handy then begin
        WebButton106.Visible := true;
      end;
    end;
  end;
  WebPageControl1.TabIndex := Save_Tab_Index;
  WebPageControl1Change(Self);

  if Save_Tab_Index = 1 then begin
    WebPanel9.Visible := false;
  end
  else begin
    WebPanel9.Visible := true;
  end;
  if Akt_Geraete_Art = GA_Handy then begin
    WebPanel1.Visible := false;
    WebPanel6.Visible := false;
  end;

{
  WebPageControl1Sheet1.TabVisible := false;
  WebPageControl1Sheet2.TabVisible := false;
  WebPageControl1Sheet3.TabVisible := false;
//  WebPageControl1Sheet4.TabVisible := false;
  WebPageControl1Sheet5.TabVisible := false;
  WebPageControl1Sheet6.TabVisible := false;
  WebPageControl1Sheet7.TabVisible := false;
}
end;

procedure TForm2.WebButton110Click(Sender: TObject);
begin

  WebPageControl1.TabIndex := 3;
  WebPageControl1Change(Self);
  WebComboBox3Change(Self);
  WebPanel42.Visible := false;
//  Refresh_ShopArtikel(0);
  if not Kiosk_Modus then begin
    WebPanel9.Visible := true;
  end
  else begin
    WebPanel9.Visible := false;

  end;

end;

procedure TForm2.WebButton111Click(Sender: TObject);
begin
  WebPageControl1.TabIndex := Save_Tab_Index;
  WebPageControl1Change(Self);
  if not Kiosk_Modus then begin
    WebPanel9.Visible := true;
  end
  else begin
    WebPanel9.Visible := false;

  end;

end;

procedure TForm2.WebButton115Click(Sender: TObject);
begin
  WebPageControl1.TabIndex := Save_Tab_Index;
  WebPageControl1Change(Self);
  if not Kiosk_Modus then begin
    WebPanel9.Visible := true;
  end
  else begin
    WebPanel9.Visible := false;

  end;

end;

procedure TForm2.WebButton116Click(Sender: TObject);
var hst,
    hst2 : String;
begin
  hst := Trim(WebEdit34.Text);
  hst2 := Trim(WebEdit35.Text);
  if hst = '' then begin
    MessageDlg('Neue eMail-Adresse muss erfasst werden', mtError, [], nil);
    exit;
  end;
  if hst2 = '' then begin
    MessageDlg('Wiederholung neue eMail-Adresse muss erfasst werden', mtError, [], nil);
    exit;
  end;
  if hst <> hst2 then begin
    MessageDlg('Neue eMail-Adresse und Wiederholung nicht identisch', mtError, [], nil);
    exit;
  end;
  if not Email_Plausibel(hst2) then begin
    MessageDlg('Neue eMail-Adresse kann keine gültige eMail-Adresse sein', mtError, [], nil);
    exit;
  end;


  hst := 'ID;' + IntToStr(Akt_User_ID)+ ';';
  hst := hst + 'emailadr;'+hst2+';';
  Open_Standard_Query(1,'Put_EmailAdr',hst,@Speichern_Email_Adresse,GetBeiwert);

end;

procedure TForm2.WebButton117Click(Sender: TObject);
begin
  WebPageControl1.TabIndex := 2;
  WebPageControl1Change(Self);

end;




procedure TForm2.WebButton118Click(Sender: TObject);
var hst,
    hst2 : String;
begin
  hst := 'ID;' + IntToStr(Akt_User_ID)+ ';';
  hst := hst + 'Telefon;'+WebEdit37.Text+';';
  hst := hst + 'Handy;'+WebEdit36.Text+';';
  Open_Standard_Query(1,'Put_Telefon',hst,@Speichern_Telefon,GetBeiwert);

end;

procedure TForm2.WebButton11Click(Sender: TObject);
begin
  WebPanel36.Visible := false;
  WebPanel9.Visible := false;
  if Akt_User_ID = 0 then begin
    AktKDName := '';
    Save_Tab_Index := WebPageControl1.TabIndex;
    Check_Angemeldet;
    WebPageControl4.TabIndex := 4;
    WebPageControl1.TabIndex := 5;
    WebPageControl1Change(Self);
    Login_HM := true;
  end
  else begin
    Akt_User_ID := 0;
    AktKDName := '';
    Check_Angemeldet;
    WebPanel9.Visible := true;
  end;

end;

procedure TForm2.WebButton120Click(Sender: TObject);
var hst,
    hst2,
    hst3 : String;
begin
  hst := Trim(WebEdit40.Text);
  hst2 := Trim(WebEdit39.Text);
  hst3 := Trim(WebEdit38.Text);

  if hst = '' then begin
    MessageDlg('Altes Kennwort muss erfasst werden', mtError, [], nil);
    exit;
  end;
  if hst2 = '' then begin
    MessageDlg('Neues Kennwort muss erfasst werden', mtError, [], nil);
    exit;
  end;
  if hst3 = '' then begin
    MessageDlg('Wiederholung neues Kennwort muss erfasst werden', mtError, [], nil);
    exit;
  end;
  if hst2 <> hst3 then begin
    MessageDlg('Neues Kennwort und Wiederholung nicht identisch', mtError, [], nil);
    exit;
  end;
  if not Kennwort_Plausibel(hst2) then begin
    MessageDlg('Kennwort ist nicht sicher genug', mtError, [], nil);
    exit;
  end;




  hst := 'ID;' + IntToStr(Akt_User_ID)+ ';';
  hst := hst + 'APW;'+Trim(WebEdit40.Text)+';';
  hst := hst + 'NPW;'+hst2+';';
  Open_Standard_Query(1,'Put_Password',hst,@Speichern_Password,GetBeiwert);

end;

procedure TForm2.WebButton122Click(Sender: TObject);
begin
  if WebEdit40.PasswordChar = '*' then begin
    WebEdit40.PasswordChar := #0;
    WebButton122.Caption := '<i class="material-icons">visibility_off</i> <span class="badge badge-danger badge-xs">'+ ''+'</span>';

  end
  else begin
    WebEdit40.PasswordChar := '*';
    WebButton122.Caption := '<i class="material-icons">visibility</i> <span class="badge badge-danger badge-xs">'+ ''+'</span>';
  end;
end;

procedure TForm2.WebButton123Click(Sender: TObject);
begin
  exit;
  if not FRecording then
  begin
    WebMediaCapture1.Start;
    FRecording := True;
    WebButton123.Caption := '<i class="material-icons">mic_off</i> <span class="badge badge-danger badge-xs">'+ 'Stop Aufnahme'+'</span>';
(*
    asm
      var recognition = new webkitSpeechRecognition();
      recognition.continuous = true;
      recognition.interimResults = true;
      recognition.lang = "en-GB";

      recognition.onresult = function(event) {
          var interim_transcript = '';

          for (var i = event.resultIndex; i < event.results.length; ++i) {
            if (event.results[i].isFinal) {
              final_transcript += event.results[i][0].transcript;
            } else {
              interim_transcript += event.results[i][0].transcript;
            }
          }

          console.log(interim_transcript,final_transcript);
        };
      }
    end;
*)
//    WebImageControl1.URL := GetStopImage;
  end
  else
  begin
    WebMediaCapture1.Stop;
    FRecording := False;
    WebButton123.Caption := '<i class="material-icons">mic</i> <span class="badge badge-danger badge-xs">'+ 'Aufnahme'+'</span>';

//    WebImageControl1.URL := GetRecordImage;
  end;

end;

procedure TForm2.WebButton123MouseDown(Sender: TObject; Button: TMouseButton;
  Shift: TShiftState; X, Y: Integer);
begin
  if not FRecording then begin
    WebMediaCapture1.Start;
    FRecording := True;
    WebButton123.Caption := '<i class="material-icons">mic_off</i> <span class="badge badge-danger badge-xs">'+ 'Stop Aufnahme'+'</span>';
  end;
end;

procedure TForm2.WebButton123MouseUp(Sender: TObject; Button: TMouseButton;
  Shift: TShiftState; X, Y: Integer);
begin
  WebMediaCapture1.Stop;
  FRecording := False;
  WebButton123.Caption := '<i class="material-icons">mic</i> <span class="badge badge-danger badge-xs">'+ 'Aufnahme'+'</span>';

end;

procedure TForm2.WebButton124Click(Sender: TObject);
begin
  if WebEdit38.PasswordChar = '*' then begin
    WebEdit38.PasswordChar := #0;
    WebButton124.Caption := '<i class="material-icons">visibility_off</i> <span class="badge badge-danger badge-xs">'+ ''+'</span>';

  end
  else begin
    WebEdit38.PasswordChar := '*';
    WebButton124.Caption := '<i class="material-icons">visibility</i> <span class="badge badge-danger badge-xs">'+ ''+'</span>';
  end;

end;

procedure TForm2.WebButton125Click(Sender: TObject);
begin
  if WebEdit39.PasswordChar = '*' then begin
    WebEdit39.PasswordChar := #0;
    WebButton125.Caption := '<i class="material-icons">visibility_off</i> <span class="badge badge-danger badge-xs">'+ ''+'</span>';

  end
  else begin
    WebEdit39.PasswordChar := '*';
    WebButton125.Caption := '<i class="material-icons">visibility</i> <span class="badge badge-danger badge-xs">'+ ''+'</span>';
  end;

end;

procedure TForm2.WebButton126Click(Sender: TObject);
begin
  if Kiosk_Modus then begin
    WebPageControl1.TabIndex := 27;
    WebPageControl1Change(Self);
    WebPanel9.Visible := false;
    WebPanel6.Visible := false;
    WebPanel57.Visible := false;
    WebLabel128.Visible := false;

    exit;

  end

end;

procedure TForm2.WebButton129Click(Sender: TObject);
begin
  WebPageControl1.TabIndex := 27; //Save_Tab_Index2;
  WebPageControl1Change(Self);
  WebPanel57.Visible := false;

  Frage_Ergebnis := 0;
  if Yes_Procedure <> nil then begin
    Yes_Procedure(self);
  end;

end;

procedure TForm2.WebButton12Click(Sender: TObject);
var hst,hst2 : String;
begin
  hst := Trim(WebEdit3.Text);
  if hst = '' then begin
    MessageDlg('eMail-Adresse muss eingegeben werden', mtError, [], nil);
    exit;
  end;
  hst2 := Trim(WebEdit4.Text);
  if hst2 = '' then begin
    MessageDlg('Kennwort muss eingegeben werden', mtError, [], nil);
    exit;
  end;
  Check_User_Password(false);


end;

procedure TForm2.WebButton130Click(Sender: TObject);
begin
  WebPageControl1.TabIndex := 27; //Save_Tab_Index2;
  WebPageControl1Change(Self);
  WebPanel57.Visible := false;

  Frage_Ergebnis := 1;
  if No_Procedure <> nil then begin
    No_Procedure(self);
  end;
end;

procedure TForm2.WebButton131Click(Sender: TObject);
begin
  WebPageControl1.TabIndex := 27; //Save_Tab_Index2;
  WebPageControl1Change(Self);
  WebPanel57.Visible := false;

  Frage_Ergebnis := 2;
  if Cancel_Procedure <> nil then begin
    Cancel_Procedure(self);
  end;
end;

procedure TForm2.WebButton132Click(Sender: TObject);
begin
  Meldungs_Fenster('Bestellung abbrechen?','Bestätigung',1,nil,nil,nil);
end;

procedure TForm2.WebButton134Click(Sender: TObject);
begin
  WebPageControl1.TabIndex := 27;
  WebPageControl1Change(Self);

end;

procedure TForm2.WebButton135Click(Sender: TObject);
begin
  WebPageControl1.TabIndex := 27;
  WebPageControl1Change(Self);

end;

procedure TForm2.WebButton137Click(Sender: TObject);
begin
  WebPopupMenu1.popup(WebButton137.Left - 70,WebPanel9.Top + WebButton137.Top + WebButton137.Height);
end;

procedure TForm2.WebButton13Click(Sender: TObject);
begin
  WebComboBox3.ItemIndex := 1;

//  WebListBox1Change(Self);
end;

procedure TForm2.WebButton14Click(Sender: TObject);
begin
  WebPageControl1.TabIndex := 3;
  WebPageControl1Change(Self);
  WebListBox1.ItemIndex := 0;
  WebComboBox15.ItemIndex := 0;
  WebListBox1Change(Self);
end;

procedure TForm2.WebButton15Click(Sender: TObject);
begin
  WebPageControl1.TabIndex := 3;
  WebPageControl1Change(Self);
  WebEdit2.SetFocus;
end;

procedure TForm2.WebButton17Click(Sender: TObject);
begin
  WebPanel36.Visible := false;
  Save_Tab_Index := WebPageControl1.TabIndex;
  WebPanel9.Visible := false;

  Loesche_Theken_Regionen;
  Akt_Modus := Add_Modus;
  WebPageControl1.TabIndex := 13;
  WebPageControl1Change(Self);
  WebPageControl3Change(Self);

end;

procedure TForm2.WebButton18Click(Sender: TObject);
begin
  Zeige_Live_Bild;
end;

procedure TForm2.WebButton19Click(Sender: TObject);
begin
  Zeige_Live_Bild;
end;

procedure TForm2.WebButton1Click(Sender: TObject);
var hst,
    hst2,
    MStr,
    EPStr : String;
    r,r2 : real;
    CBA : integer;

begin
  WebButton94Click(Self);
//  WebPageControl2.TabIndex := 0;
//  WebPageControl2Change(Self);
  if Akt_Modus = Add_Modus then begin
    if VV_Aktiv then begin
      WebEdit5.Text := '1';
      if WebComboBox4.Visible and (WebComboBox4.ItemIndex = 0) then begin
        MessageDlg('Ungültige oder keine Auswahl für "'+WebLabel32.Caption + '"', mtError, [], nil);
        WebComboBox4.SetFocus;
        exit;
      end;
    end;
    hst := Trim(WebEdit5.Text);
    if not Str_to_Real(hst,r,0.0,0.0) then begin
      MessageDlg('Eingabe Menge/Stückzahl ist ungültig', mtError, [], nil);
      WebEdit5.SetFocus;
      exit;
    end;
    if r <= 0.0 then begin
      MessageDlg('Eingabe Menge/Stückzahl ist ungültig', mtError, [], nil);
      WebEdit5.SetFocus;
      exit;
    end;

    if WebComboBox4.Visible and (WebComboBox4.ItemIndex = 0) then begin
      MessageDlg('Ungültige oder keine Auswahl für "'+WebLabel32.Caption + '"', mtError, [], nil);
      WebComboBox4.SetFocus;
      exit;
    end;

    if WebComboBox5.Visible and (WebComboBox5.ItemIndex = 0) then begin
      MessageDlg('Ungültige oder keine Auswahl für "'+WebLabel30.Caption + '"', mtError, [], nil);
      WebComboBox5.SetFocus;
      exit;
    end;

    if WebComboBox6.Visible and (WebComboBox6.ItemIndex = 0) then begin
      MessageDlg('Ungültige oder keine Auswahl für "'+WebLabel29.Caption + '"', mtError, [], nil);
      WebComboBox6.SetFocus;
      exit;
    end;


    r2 := 2.22; // Get_EP(Akt_Artikel_ID,r);

    Str(r*1000:16:0,MStr);
    MStr := Trim(MStr);
    Str(r2*100:16:0,EPStr);
    EPStr := Trim(EPStr);
    if VV_Aktiv then begin
      CBA := VVA[WebComboBox4.ItemIndex];
    end
    else begin
      CBA := WebComboBox4.ItemIndex;
    end;


    DataModule1.XDataWebClientAuth.RawInvoke('ILoginService.Add_to_WK', [IntToStr(AktFirmaNo),My_Cookie,IntToStr(Akt_Artikel_ID),
       MStr,EPStr,IntToStr(CBA),
       IntToStr(WebComboBox5.ItemIndex),IntToStr(WebComboBox6.ItemIndex),
       '','1',GetBeiwert],
    procedure(Response: TXDataClientResponse)
    var
      i: integer;
      hst : String;
    begin
      hst := JS.ToString(JS.ToObject(Response.Result)['value']);
      if hst <> '' then begin
        i := StrToInt(hst);
        if i <= 0 then begin
          MessageDlg('Artikel nicht gespeichert', mtError, [], nil);
        end
        else begin
          WK_Artikel := WK_Artikel + 1;
          Update_Buttons;
          WebButton7Click(Self);
          Lese_Warenkorb;
        end;
      end
      else begin
        MessageDlg('Speichern nicht möglich', mtError, [], nil);
      end;
    end,
    procedure(Error: TXDataClientError)
    begin
      MessageDlg('Speichern nicht möglich', mtError, [], nil);
    end

    );
  end
  else if Akt_Modus = Add_Modus_Theke then begin
    if VV_Aktiv then begin
      WebEdit5.Text := '1';
      if WebComboBox4.Visible and (WebComboBox4.ItemIndex = 0) then begin
        MessageDlg('Ungültige oder keine Auswahl für "'+WebLabel32.Caption + '"', mtError, [], nil);
        WebComboBox4.SetFocus;
        exit;
      end;
    end;
    hst := Trim(WebEdit5.Text);
    if not Str_to_Real(hst,r,0.0,0.0) then begin
      MessageDlg('Eingabe Menge/Stückzahl ist ungültig', mtError, [], nil);
      WebEdit5.SetFocus;
      exit;
    end;
    if r <= 0.0 then begin
      MessageDlg('Eingabe Menge/Stückzahl ist ungültig', mtError, [], nil);
      WebEdit5.SetFocus;
      exit;
    end;

    if WebComboBox4.Visible and (WebComboBox4.ItemIndex = 0) then begin
      MessageDlg('Ungültige oder keine Auswahl für "'+WebLabel32.Caption + '"', mtError, [], nil);
      WebComboBox4.SetFocus;
      exit;
    end;

    if WebComboBox5.Visible and (WebComboBox5.ItemIndex = 0) then begin
      MessageDlg('Ungültige oder keine Auswahl für "'+WebLabel30.Caption + '"', mtError, [], nil);
      WebComboBox5.SetFocus;
      exit;
    end;

    if WebComboBox6.Visible and (WebComboBox6.ItemIndex = 0) then begin
      MessageDlg('Ungültige oder keine Auswahl für "'+WebLabel29.Caption + '"', mtError, [], nil);
      WebComboBox6.SetFocus;
      exit;
    end;

    r := 1.0;
    r2 := 2.22;
    Str(r*1000:16:0,MStr);
    MStr := Trim(MStr);
    Str(r2*100:16:0,EPStr);
    EPStr := Trim(EPStr);
    if VV_Aktiv then begin
      CBA := VVA[WebComboBox4.ItemIndex];
    end
    else begin
      CBA := WebComboBox4.ItemIndex;
    end;

    DataModule1.XDataWebClientAuth.RawInvoke('ILoginService.Add_to_WK', [IntToStr(AktFirmaNo),My_Cookie,IntToStr(Akt_Artikel_ID),
       MStr,EPStr,IntToStr(CBA),
       IntToStr(WebComboBox5.ItemIndex),IntToStr(WebComboBox6.ItemIndex),
       '','1',GetBeiwert],

    procedure(Response: TXDataClientResponse)
    var
      i: integer;
      hst : String;
    begin
      hst := JS.ToString(JS.ToObject(Response.Result)['value']);
      if hst <> '' then begin
        i := StrToInt(hst);
        if i <= 0 then begin
          MessageDlg('Artikel nicht gespeichert', mtError, [], nil);
        end
        else begin
          WK_Artikel := WK_Artikel + 1;
          Update_Buttons;
          Lese_Warenkorb;
          WebPageControl1.TabIndex := 13;
          WebPageControl1Change(Self);
          WebButton2.Visible := true;
          WebButton6.Visible := true;
          WebButton3.Visible := true;
          WebButton5.Visible := true;
          WebButton4.Visible := true;
          WebButton11.Visible := true;
          WebButton17.Visible := true;
          WebButton10.Visible := false;
          if Akt_Geraete_Art = GA_Handy then begin
            WebPanel1.Height := 155;
          end
          else begin
            WebPanel1.Height := 105;

          end;

          WebPanel9.Visible := true;

        end;
      end
      else begin
        MessageDlg('Speichern nicht möglich', mtError, [], nil);
      end;
    end,
    procedure(Error: TXDataClientError)
    begin
      MessageDlg('Speichern nicht möglich', mtError, [], nil);
    end

    );
  end


  else if Akt_Modus = Loesch_Modus then begin
    hst2 := WebDBResponsiveGrid3.DataSource.DataSet.Fieldbyname('lfdno').AsString;
    DataModule1.XDataWebClientAuth.RawInvoke('ILoginService.Delete_WK', [IntToStr(AktFirmaNo),My_Cookie,hst2,GetBeiwert],

    procedure(Response: TXDataClientResponse)
    var
      i: integer;
      hst : String;
    begin
      hst := JS.ToString(JS.ToObject(Response.Result)['value']);
      if hst <> '' then begin
        i := StrToInt(hst);
        if i <= 0 then begin
          MessageDlg('Artikel nicht gelöscht', mtError, [], nil);
        end
        else begin
          WK_Artikel := WK_Artikel - 1;
          WebEdit5.Readonly := false;
          WebComboBox4.Enabled := true;
          WebComboBox5.Enabled := true;
          WebComboBox6.Enabled := true;

          Update_Buttons;
          WebButton7Click(Self);

          if WK_Artikel > 0 then begin
            WebButton3Click(Self);
          end
          else begin
            WebButton6Click(Self);
          end;
          Lese_Warenkorb;

        end;
      end
      else begin
        MessageDlg('Löschen nicht möglich', mtError, [], nil);
      end;
    end,
    procedure(Error: TXDataClientError)
    begin
      MessageDlg('Löschen nicht möglich', mtError, [], nil);
    end

    );
  end
  else begin
    if VV_Aktiv then begin
      WebEdit5.Text := '1';
      if WebComboBox4.Visible and (WebComboBox4.ItemIndex = 0) then begin
        MessageDlg('Ungültige oder keine Auswahl für "'+WebLabel32.Caption + '"', mtError, [], nil);
        WebComboBox4.SetFocus;
        exit;
      end;
    end;
    hst := Trim(WebEdit5.Text);
    if not Str_to_Real(hst,r,0.0,0.0) then begin
      MessageDlg('Eingabe Menge/Stückzahl ist ungültig', mtError, [], nil);
      WebEdit5.SetFocus;
      exit;
    end;
    if r <= 0.0 then begin
      MessageDlg('Eingabe Menge/Stückzahl ist ungültig', mtError, [], nil);
      WebEdit5.SetFocus;
      exit;
    end;

    if WebComboBox4.Visible and (WebComboBox4.ItemIndex = 0) then begin
      MessageDlg('Ungültige oder keine Auswahl für "'+WebLabel32.Caption + '"', mtError, [], nil);
      WebComboBox4.SetFocus;
      exit;
    end;

    if WebComboBox5.Visible and (WebComboBox5.ItemIndex = 0) then begin
      MessageDlg('Ungültige oder keine Auswahl für "'+WebLabel30.Caption + '"', mtError, [], nil);
      WebComboBox5.SetFocus;
      exit;
    end;

    if WebComboBox6.Visible and (WebComboBox6.ItemIndex = 0) then begin
      MessageDlg('Ungültige oder keine Auswahl für "'+WebLabel29.Caption + '"', mtError, [], nil);
      WebComboBox6.SetFocus;
      exit;
    end;

    r := 1.0;
    r2 := 2.22;

    Str(r*1000:16:0,MStr);
    MStr := Trim(MStr);
    Str(r2*100:16:0,EPStr);
    EPStr := Trim(EPStr);

    if VV_Aktiv then begin
      CBA := VVA[WebComboBox4.ItemIndex];
    end
    else begin
      CBA := WebComboBox4.ItemIndex;
    end;

    DataModule1.XDataWebClientAuth.RawInvoke('ILoginService.Edit_WK', [IntToStr(AktFirmaNo),My_Cookie,
       WebDBResponsiveGrid3.DataSource.DataSet.Fieldbyname('lfdno').AsString,

       IntToStr(Akt_Artikel_ID),
       MStr,EPStr,IntToStr(CBA),
       IntToStr(WebComboBox5.ItemIndex),IntToStr(WebComboBox6.ItemIndex),
       '','1',GetBeiwert],

    procedure(Response: TXDataClientResponse)
    var
      i: integer;
      hst : String;
    begin
      hst := JS.ToString(JS.ToObject(Response.Result)['value']);
      if hst <> '' then begin
        i := StrToInt(hst);
        if i <= 0 then begin
          MessageDlg('Artikel nicht gespeichert', mtError, [], nil);
        end
        else begin
          Update_Buttons;
          WebButton7Click(Self);
          WebButton3Click(Self);
          Lese_Warenkorb;
        end;
      end
      else begin
        MessageDlg('Speichern nicht möglich', mtError, [], nil);
      end;
    end,
    procedure(Error: TXDataClientError)
    begin
      MessageDlg('Speichern nicht möglich', mtError, [], nil);
    end

    );
  end;
end;

procedure TForm2.WebButton20Click(Sender: TObject);
begin
  Zeige_Live_Bild;
end;

procedure TForm2.WebButton21Click(Sender: TObject);
begin
  Zeige_Live_Bild;
end;

procedure TForm2.WebButton22Click(Sender: TObject);
begin
  Akt_User_ID := 0;
  AktKDName := '';
  Check_Angemeldet;
end;

procedure TForm2.WebButton23Click(Sender: TObject);
begin
  WebPageControl4.TabIndex := 2;

end;

procedure TForm2.WebButton24Click(Sender: TObject);
begin
  Check_Angemeldet;
  WebPageControl4.TabIndex := 2;
end;

procedure TForm2.WebButton25Click(Sender: TObject);
begin
  if Akt_Konto_Menu = 1 then begin
    Close_Konto_Menu;
  end
  else begin
    Close_Konto_Menu;
    Akt_Konto_Menu := 1;
    Mache_Konto_Menu;
  end;
end;

procedure TForm2.WebButton26Click(Sender: TObject);
var pi: TPayPalItem;
    cartitem : TCartItem;
begin
  WebPayPal1.BeginUpdate;
  if PayPalAPIKey = '' then
    raise Exception.Create('Missing PayPal API Key. Please add the Paypal API key to APPIDS.INC and recompile.');
  WebPayPal1.APIKey := PayPalAPIKey;
  WebPayPal1.Payment.RecipientName := 'Klaus Weinig';
  WebPayPal1.Payment.Address1 := 'Rothermelstr. 10';
  WebPayPal1.Payment.Address2 := '';
  WebPayPal1.Payment.PostalCode := '97953';
  WebPayPal1.Payment.City := 'Königheim';
  WebPayPal1.Payment.State := '';
  WebPayPal1.Payment.CountryCode := 'Germany';



  WebPayPal1.Payment.Tax := 0;
  WebPayPal1.Payment.Shipping := 0;
  WebPayPal1.Payment.Currency := pcEUR;
  WebPayPal1.Payment.Description := 'TMS Web Shop Purchase';
  WebPayPal1.Payment.CustomText := 'TMS Web Shop Purchase';
  WebPayPal1.Payment.Items.Clear;
  WebPayPal1.EndUpdate;






end;

procedure TForm2.WebButton27Click(Sender: TObject);
var pi : TPayPalItem;
    cartitem : TCartItem;
begin
  WebPayPal1.BeginUpdate;
  if PayPalAPIKey = '' then
    raise Exception.Create('Missing PayPal API Key. Please add the Paypal API key to APPIDS.INC and recompile.');
  WebPayPal1.APIKey := PayPalAPIKey;
  WebPayPal1.Payment.RecipientName := 'Klaus Weinig';
  WebPayPal1.Payment.Address1 := 'Rothermelstr. 10';
  WebPayPal1.Payment.Address2 := '';
  WebPayPal1.Payment.PostalCode := '97953';
  WebPayPal1.Payment.City := 'Koenigheim';
  WebPayPal1.Payment.State := '';
  WebPayPal1.Payment.CountryCode := 'DE'; // 'Germany';



  WebPayPal1.Payment.Tax := 7.0;
  WebPayPal1.Payment.Shipping := 5.5;
  WebPayPal1.Payment.Currency := pcEUR;
  WebPayPal1.Payment.Description := 'TMS Web Shop Purchase';
  WebPayPal1.Payment.CustomText := 'TMS Web Shop Purchase';

  Datamodule1.VWarenkorbDataSet.First;
  WebPayPal1.Payment.Items.Clear;
  while not Datamodule1.VWarenkorbDataSet.EOF do begin
    pi := WebPayPal1.Payment.Items.Add;
    pi.Name := Datamodule1.VWarenkorbDataSet.Fieldbyname('Bezeichnung').AsString;
    pi.Price := Datamodule1.VWarenkorbDataSet.Fieldbyname('GPreis').AsFloat;
    pi.Tax := 7.0;
    pi.Quantity := 1;
    pi.SKU := Datamodule1.VWarenkorbDataSet.Fieldbyname('ArtikelID').AsString;

    cartitem := TCartItem.Create;
    cartitem.Name := 'CI_'+Datamodule1.VWarenkorbDataSet.Fieldbyname('lfdno').AsString;
    cartitem.Description := pi.Name;
    cartitem.Price := pi.Price;
    cartitem.Category := 1;
    cartitem.ImageUrl := '';

    pi.TagObject := cartitem;



    Datamodule1.VWarenkorbDataSet.Next;
  end;



  WebPayPal1.EndUpdate;

end;

procedure TForm2.WebButton28Click(Sender: TObject);
begin
  Akt_User_ID := 0;
  AktKDName := '';
  Check_Angemeldet;

  Auswahl_Checkout := 2;
  WebCheckBox7.Visible := false;
  WebPageControl4.TabIndex := 0;
end;

procedure TForm2.WebButton29Click(Sender: TObject);
begin
  Akt_User_ID := 0;
  AktKDName := '';
  Check_Angemeldet;

  Auswahl_Checkout := 1;
  WebCheckBox7.Visible := true;
  WebPageControl4.TabIndex := 4;
end;

procedure TForm2.WebButton2Click(Sender: TObject);
begin
  WebPanel36.Visible := false;

{
  WebPageControl1Sheet1.TabVisible := false;
  WebPageControl1Sheet2.TabVisible := false;
  WebPageControl1Sheet3.TabVisible := false;
//  WebPageControl1Sheet4.TabVisible := false;
//  WebPageControl1Sheet5.TabVisible := true;
  WebPageControl1Sheet5.TabVisible := false;
  WebPageControl1Sheet6.TabVisible := false;
  WebPageControl1Sheet7.TabVisible := false;
}
  WebPanel9.Visible := true;
  WebPageControl1.TabIndex := 4;
  WebPageControl1Change(Self);
end;

procedure TForm2.WebButton30Click(Sender: TObject);
begin
  Akt_User_ID := 0;
  AktKDName := '';
  Check_Angemeldet;

  WebPageControl4.TabIndex := 5;
end;

procedure TForm2.WebButton31Click(Sender: TObject);
begin
  WebPanel9.Visible := true;
  WebPageControl1.TabIndex := Save_Tab_Index;
  WebPageControl1Change(Self);
end;

procedure TForm2.WebButton32Click(Sender: TObject);
begin
  if Trim(WebEdit10.Text) = '' then begin
    MessageDlg('eMail-Adresse muss eingegeben werden', mtError, [], nil);
    WebEdit10.SetFocus;
    exit;
  end;
  if Trim(WebEdit11.Text) = '' then begin
    MessageDlg('Kennwort muss eingegeben werden', mtError, [], nil);
    WebEdit11.SetFocus;
    exit;
  end;
  Akt_User_ID := 0;
  AktKDName := '';
  Check_Angemeldet;

  Check_User_Password(true);

end;

procedure TForm2.WebButton33Click(Sender: TObject);

var User_Name,
    Kennwort,hst : String;
    NLK : String;
    i,j,UKA: integer;



  procedure OnConnect;
  begin

    NLK := IntToStr(AktFirmaNo);
    DataModule1.XDataWebClientAuth.RawInvoke('ILoginService.RequestEmailPW', [NLK,User_Name],
        procedure(Response: TXDataClientResponse)
        var
          F: string;
        begin
          F := JS.ToString(JS.ToObject(Response.Result)['value']);
          MessageDlg('Prüfen Sie zeitnah ihr Email-Postfach (ggf. auch den Spam-Ordner). Der Link in der Mail ist 10 Minuten gültig', mtInformation, [], nil);
        end,

        procedure(Error: TXDataClientError)
        begin
          MessageDlg('Keine Verbindung zum Server, RequestEmailPW', mtError, [], nil);
        end
       );

  end;

  procedure OnError(Error: TXDataWebConnectionError);
  var hst : String;
  begin
    hst := 'Auth-Server Fehler (RequestEmailPW): '+Error.ErrorMessage + '. Bitte prüfen Sie Ihre Internet-Verbindung.';
    MessageDlg(hst, mtError, [], nil);
  end;




begin
  Akt_User_ID := 0;
  AktKDName := '';
  Check_Angemeldet;
  Datamodule1.XDataWebConnection2.Close;

  if Trim(WebEdit10.Text) = '' then begin
    MessageDlg('eMail-Adresse muss eingegeben werden', mtError, [], nil);
    WebEdit10.SetFocus;
    exit;
  end;
  User_Name := Trim(WebEdit10.Text);
  PW_Request_Cnt := PW_Request_Cnt + 1;
  if PW_Request_Cnt > 3 then begin
    MessageDlg('Sie haben bereits 3 Mal eine eMail angefordert, starten Sie die App erneut für diese Aktion', mtError, [], nil);
    WebEdit10.SetFocus;
    exit;

  end;


  Datamodule1.XDataWebConnection2.Open(@OnConnect, @OnError);



end;

procedure TForm2.WebButton34Click(Sender: TObject);
begin
  WebEdit11.Text := '';
  Check_Angemeldet;
  if Login_HM then begin
    Login_HM := false;
    WebPageControl1.TabIndex := Save_Tab_Index;
    WebPanel9.Visible := true;
    WebPageControl1Change(Self);
  end
  else begin
    WebPageControl4.TabIndex := 3;
  end;
end;

procedure TForm2.WebButton35Click(Sender: TObject);
var hst : String;
begin
  if Trim(WebEdit1.Text) = '' then begin
    MessageDlg('Name muss eingegeben werden', mtError, [], nil);
    WebEdit1.SetFocus;
    exit;
  end;
  if Trim(WebEdit6.Text) = '' then begin
    MessageDlg('Adresse Zeile 1 muss eingegeben werden', mtError, [], nil);
    WebEdit6.SetFocus;
    exit;
  end;
  if Trim(WebEdit8.Text) = '' then begin
    MessageDlg('Postleitzahl muss eingegeben werden', mtError, [], nil);
    WebEdit8.SetFocus;
    exit;
  end;
  if Trim(WebEdit9.Text) = '' then begin
    MessageDlg('Ort muss eingegeben werden', mtError, [], nil);
    WebEdit9.SetFocus;
    exit;
  end;
  if WebComboBox2.ItemIndex <= 0 then begin
    MessageDlg('Land muss ausgewählt werden', mtError, [], nil);
    WebComboBox2.SetFocus;
    exit;
  end;
  if In_Konto_Menu then begin
    hst := 'KDID;' + IntToStr(Akt_User_ID)+ ';';
    hst := hst + 'lfdno;1;';
    hst := hst + 'Name;'+WebEdit1.Text+';';
    hst := hst + 'Name2;'+WebEdit6.Text+';';
    hst := hst + 'Strasse;'+WebEdit7.Text+';';
    hst := hst + 'PLZ;'+WebEdit8.Text+';';
    hst := hst + 'Ort;'+WebEdit9.Text+';';
    hst := hst + 'Land;'+IntToStr(WebComboBox2.ItemIndex)+';';
    Open_Standard_Query(1,'Put_LiefAdr',hst,@Speichern_Liefer_Adresse,GetBeiwert);

//    WebPageControl1.TabIndex := 2;
//    WebPageControl1Change(Self);
  end
  else begin
    WebPageControl4.TabIndex := 2;
  end;
end;

procedure TForm2.WebButton36Click(Sender: TObject);
begin
  if In_Konto_Menu then begin
    WebPageControl1.TabIndex := 2;
    WebPageControl1Change(Self);
  end
  else begin
    Check_Angemeldet;
    WebPageControl4.TabIndex := 0;
  end;
end;

procedure TForm2.WebButton37Click(Sender: TObject);
var hst : String;
begin
  if Trim(edName.Text) = '' then begin
    MessageDlg('Name muss eingegeben werden', mtError, [], nil);
    edName.SetFocus;
    exit;
  end;
  if Trim(edAddress1.Text) = '' then begin
    MessageDlg('Adresse Zeile 1 muss eingegeben werden', mtError, [], nil);
    edAddress1.SetFocus;
    exit;
  end;
  if Trim(edPostalCode.Text) = '' then begin
    MessageDlg('Postleitzahl muss eingegeben werden', mtError, [], nil);
    edPostalCode.SetFocus;
    exit;
  end;
  if Trim(edCity.Text) = '' then begin
    MessageDlg('Ort muss eingegeben werden', mtError, [], nil);
    edCity.SetFocus;
    exit;
  end;
  if WebComboBox1.ItemIndex <= 0 then begin
    MessageDlg('Land muss ausgewählt werden', mtError, [], nil);
    WebComboBox1.SetFocus;
    exit;
  end;
  if not In_Konto_Menu then begin
    if WebComboBox7.ItemIndex <= 0 then begin
      MessageDlg('Lieferung muss ausgewählt werden', mtError, [], nil);
      WebComboBox7.SetFocus;
      exit;
    end;
  end;
  if In_Konto_Menu then begin
    hst := 'ID;' + IntToStr(Akt_User_ID)+ ';';
    hst := hst + 'Name;'+edName.Text+';';
    hst := hst + 'Name2;'+edAddress1.Text+';';
    hst := hst + 'Strasse;'+edAddress2.Text+';';
    hst := hst + 'PLZ;'+edPostalCode.Text+';';
    hst := hst + 'Ort;'+edCity.Text+';';
    hst := hst + 'Land;'+IntToStr(WebComboBox1.ItemIndex)+';';
    Open_Standard_Query(1,'Put_RechAdr',hst,@Speichern_Rechnungs_Adresse,GetBeiwert);

//    WebPageControl1.TabIndex := 2;
//    WebPageControl1Change(Self);
  end
  else begin
    if WebComboBox7.ItemIndex = 1 then begin
      WebPageControl4.TabIndex := 2;
    end
    else if WebComboBox7.ItemIndex = 2 then begin
      WebPageControl4.TabIndex := 1;
    end
    else begin
      WebPageControl4.TabIndex := 2;
    end;
  end;



end;

procedure TForm2.WebButton38Click(Sender: TObject);
begin
  if In_Konto_Menu then begin
    WebPageControl1.TabIndex := 2;
    WebPageControl1Change(Self);
  end
  else begin
    Check_Angemeldet;
    WebPageControl4.TabIndex := 3;
  end;
end;

procedure TForm2.WebButton39Click(Sender: TObject);
begin
  if WebEdit11.PasswordChar = '*' then begin
    WebEdit11.PasswordChar := #0;
    WebButton39.Caption := '<i class="material-icons">visibility_off</i> <span class="badge badge-danger badge-xs">'+ ''+'</span>';

  end
  else begin
    WebEdit11.PasswordChar := '*';
    WebButton39.Caption := '<i class="material-icons">visibility</i> <span class="badge badge-danger badge-xs">'+ ''+'</span>';

  end;
end;

procedure TForm2.WebButton3Click(Sender: TObject);
begin

{  WebPageControl1Sheet1.TabVisible := false;
  WebPageControl1Sheet2.TabVisible := false;
  WebPageControl1Sheet3.TabVisible := false;
//  WebPageControl1Sheet4.TabVisible := false;
  WebPageControl1Sheet5.TabVisible := false;
  WebPageControl1Sheet6.TabVisible := false;
  WebPageControl1Sheet7.TabVisible := false;
}
  if WK_Artikel = 0 then begin
    MessageDlg('Sie haben (noch) keine Artikel im Warenkorb', mtError, [], nil);
    exit;
  end;
  Save_Tab_Index := WebPageControl1.TabIndex;
  WebPanel36.Visible := false;
  WebPanel9.Visible := false;

  Zeige_Warenkorb(nil);
//  WebDBResponsiveGrid3.Datasource := nil;



end;

procedure TForm2.WebButton40Click(Sender: TObject);
begin

  WebRadioGroup1Change(Self);

  WebPageControl4.TabIndex := 6;

end;

procedure TForm2.WebButton41Click(Sender: TObject);
begin
  Check_Angemeldet;
  if Auswahl_Checkout = 1 then begin
    WebPageControl4.TabIndex := 3;
  end
  else begin
    if WebComboBox7.ItemIndex = 1 then begin
      WebPageControl4.TabIndex := 0;
    end
    else if WebComboBox7.ItemIndex = 2 then begin
      WebPageControl4.TabIndex := 1;
    end
  end;
end;

procedure TForm2.WebButton42Click(Sender: TObject);
var hst2 : String;
begin
    hst2 := 'MYKDDATA';

    DataModule1.XDataWebClientApiService.RawInvoke('IApiService.Do_Transaction', [0, hst2],
    procedure(Response: TXDataClientResponse)
    var
      i: integer;
      hst : String;
      ts : TStringList;
      TSD : TSL_Dataset;
    begin
//      hst := Response.Result;
      hst := JS.ToString(JS.ToObject(Response.Result)['value']);

      if hst <> '' then begin
        WebLabel51.Caption := hst;

        ts := TStringList.Create;
        ts.Text := hst;
        Init_TSL_Dataset(TSD,ts);
        ts.Free;
        WebEdit12.Text := TSL_Dataset_FBN(TSD,'ID');
        WebEdit13.Text := TSL_Dataset_FBN(TSD,'Name');
        WebEdit14.Text := TSL_Dataset_FBN(TSD,'Name2');

//        hst := '{"ROW": [{"ID":"525","NAME":"Weinig","NAME2":"Klaus"}]}';
//        Datamodule1.MyKDDataset.Rows := JS.ToArray (TJSJson.parse (hst));


//        ja := TJSJSON.parse(hst);
//        Datamodule1.MyKDDataset.Rows := ja;
//        Datamodule1.MyKDDataset.Rows := ja;// TJSArray(TJSJSON.parseObject('{"ROW": [{"ID":"525","NAME":"Weinig","NAME2":"Klaus"}]}'));
//        Datamodule1.MyKDDataset.Open;

//                          ja = {ID: "525", NAME: "Weinig", NAME2: "Klaus"}

{
        i := StrToInt(hst);
        if i = -1 then begin
          MessageDlg('Artikel nicht gespeichert', mtError, [], nil);
        end
        else begin
          WK_Artikel := WK_Artikel + 1;
          Update_Buttons;
          WebButton7Click(Self);
        end;
}
      end
      else begin
        MessageDlg('Laden nicht möglich', mtError, [], nil);
      end;
    end,
    procedure(Error: TXDataClientError)
    begin
      MessageDlg('Laden nicht möglich', mtError, [], nil);
    end

    );

end;

procedure TForm2.WebButton43Click(Sender: TObject);

var
    NLK : String;
    i,j,UKA: integer;



  procedure OnConnect;
  begin

    NLK := IntToStr(AktFirmaNo);
    DataModule1.XDataWebClientAuth.RawInvoke('ILoginService.GetWKToken', [NLK],
        procedure(Response: TXDataClientResponse)
        var
          F: string;
        begin
          F := JS.ToString(JS.ToObject(Response.Result)['value']);
          My_Cookie := F;
          LS_SetMyToken(Shop_ID_Token,My_Cookie);
          WebPanel9.Visible := true;
          WebPageControl1.TabIndex := 4;
          WebPageControl1Change(Self);
        end,

        procedure(Error: TXDataClientError)
        begin
          MessageDlg('Keine Verbindung zum Server (GetWKToken)', mtError, [], nil);
        end
       );

  end;

  procedure OnError(Error: TXDataWebConnectionError);
  var hst : String;
  begin
    hst := 'Auth-Server Fehler (GetWKToken): '+Error.ErrorMessage + '. Bitte prüfen Sie Ihre Internet-Verbindung.';
    MessageDlg(hst, mtError, [], nil);
  end;


begin
  Datamodule1.XDataWebConnection2.Close;
  Datamodule1.XDataWebConnection2.Open(@OnConnect, @OnError);

end;

procedure TForm2.WebButton44Click(Sender: TObject);
begin
  WebPageControl1.TabIndex := 15;
  WebPageControl1Change(Self);
end;

procedure TForm2.WebButton45Click(Sender: TObject);
begin
  if Trim(WebEdit15.Text) = '' then begin
    MessageDlg('Name muss eingegeben werden', mtError, [], nil);
    WebEdit15.SetFocus;
    exit;
  end;
  if Trim(WebEdit16.Text) = '' then begin
    MessageDlg('Adresse Zeile 1 muss eingegeben werden', mtError, [], nil);
    WebEdit16.SetFocus;
    exit;
  end;
  if Trim(WebEdit18.Text) = '' then begin
    MessageDlg('Postleitzahl muss eingegeben werden', mtError, [], nil);
    WebEdit18.SetFocus;
    exit;
  end;
  if Trim(WebEdit19.Text) = '' then begin
    MessageDlg('Ort muss eingegeben werden', mtError, [], nil);
    WebEdit19.SetFocus;
    exit;
  end;
  if WebComboBox8.ItemIndex <= 0 then begin
    MessageDlg('Land muss ausgewählt werden', mtError, [], nil);
    WebComboBox8.SetFocus;
    exit;
  end;
  WebPageControl5.TabIndex := 1;

end;

procedure TForm2.WebButton46Click(Sender: TObject);
begin
  Check_Angemeldet;
  WebPageControl4.TabIndex := 3;

end;

procedure TForm2.WebButton47Click(Sender: TObject);
begin
  if Trim(WebEdit21.Text) = '' then begin
    MessageDlg('eMail-Adresse muss eingegeben werden', mtError, [], nil);
    WebEdit21.SetFocus;
    exit;
  end;
  if Trim(WebEdit20.Text) = '' then begin
    MessageDlg('Wiederholung eMail-Adresse muss eingegeben werden', mtError, [], nil);
    WebEdit20.SetFocus;
    exit;
  end;
  if Trim(WebEdit22.Text) = '' then begin
    MessageDlg('Kennwort muss eingegeben werden', mtError, [], nil);
    WebEdit22.SetFocus;
    exit;
  end;
  if Trim(WebEdit23.Text) = '' then begin
    MessageDlg('Wiederholung Kennwort muss eingegeben werden', mtError, [], nil);
    WebEdit23.SetFocus;
    exit;
  end;
  if Trim(WebEdit21.Text) <> Trim(WebEdit20.Text) then begin
    MessageDlg('eMail-Adresse und Wiederholung eMail-Adresse nicht identisch', mtError, [], nil);
    WebEdit20.SetFocus;
    exit;
  end;
  if Trim(WebEdit22.Text) <> Trim(WebEdit23.Text) then begin
    MessageDlg('Kennwort und Wiederholung Kennwort nicht identisch', mtError, [], nil);
    WebEdit23.SetFocus;
    exit;
  end;
  if not Email_Plausibel(WebEdit21.Text) then begin
    MessageDlg('eMail-Adresse ungültig', mtError, [], nil);
    WebEdit21.SetFocus;
    exit;
  end;

  if not Kennwort_Plausibel(WebEdit21.Text) then begin
    MessageDlg('Kennwort ungültig (Mindestlänge 6 Zeichen,Leerzeichnen nicht erlaubt)', mtError, [], nil);
    WebEdit22.SetFocus;
    exit;
  end;





  WebPageControl5.TabIndex := 2;


end;

procedure TForm2.WebButton48Click(Sender: TObject);
begin
  Check_Angemeldet;
  WebPageControl5.TabIndex := 0;

end;

procedure TForm2.WebButton49Click(Sender: TObject);
begin
  if WebEdit22.PasswordChar = '*' then begin
    WebEdit22.PasswordChar := #0;
    WebEdit23.PasswordChar := #0;
    WebButton49.Caption := '<i class="material-icons">visibility_off</i> <span class="badge badge-danger badge-xs">'+ ''+'</span>';
  end
  else begin
    WebEdit22.PasswordChar := '*';
    WebEdit23.PasswordChar := '*';
    WebButton49.Caption := '<i class="material-icons">visibility</i> <span class="badge badge-danger badge-xs">'+ ''+'</span>';

  end;

end;

procedure TForm2.WebButton4Click(Sender: TObject);
begin
  WebPanel36.Visible := false;
  WebButton137.Visible := false;

{  WebPageControl1Sheet1.TabVisible := false;
  WebPageControl1Sheet2.TabVisible := false;
  WebPageControl1Sheet3.TabVisible := false;
//  WebPageControl1Sheet4.TabVisible := false;
  WebPageControl1Sheet5.TabVisible := false;
  WebPageControl1Sheet6.TabVisible := false;
  WebPageControl1Sheet7.TabVisible := false;
}

  if Akt_User_ID = 0 then begin
    MessageDlg('Sie müssen sich erst anmelden um ihr Konto bearbeiten zu können', mtError, [], nil);
    exit;
  end;
  WebPageControl6.TabIndex := 0;
  Save_Tab_Index := WebPageControl1.TabIndex;
  WebPageControl1.TabIndex := 2;
  WebPageControl1Change(Self);

end;

procedure TForm2.WebButton50Click(Sender: TObject);
begin
  if Trim(WebEdit24.Text) = '' then begin
    MessageDlg('Bestätigungscode muss eingegeben werden', mtError, [], nil);
    WebEdit24.SetFocus;
    exit;
  end;
  WebPageControl4.TabIndex := 3;
end;

procedure TForm2.WebButton52Click(Sender: TObject);
begin
  Check_Angemeldet;
  WebPageControl4.TabIndex := 3;

end;

procedure TForm2.WebButton53Click(Sender: TObject);
begin
  if Akt_Konto_Menu = 2 then begin
    Close_Konto_Menu;
  end
  else begin
    Close_Konto_Menu;
    Akt_Konto_Menu := 2;
    Mache_Konto_Menu;
  end;

end;

procedure TForm2.WebButton54Click(Sender: TObject);
begin
  if Akt_Konto_Menu = 3 then begin
    Close_Konto_Menu;
  end
  else begin
    Close_Konto_Menu;
    Akt_Konto_Menu := 3;
    Mache_Konto_Menu;
  end;
end;

procedure TForm2.WebButton56Click(Sender: TObject);
begin
  WebPanel36.Visible := false;
  WebPanel9.Visible := false;
  Save_Tab_Index := WebPageControl1.TabIndex;

  Loesche_Theken_Regionen;
  Akt_Modus := Add_Modus;
  WebPageControl1.TabIndex := 17;
  WebPageControl1Change(Self);
  WebPageControl3Change(Self);

end;

procedure TForm2.WebButton57Click(Sender: TObject);
var i,j : integer;
begin
  j := 0;
  for i := 0 to AllergieListe.Count - 1 do begin
    if AllergieFilter[i] then j := j + 1;
  end;
  if j = 0 then begin
    MessageDlg('Sie haben (noch) nichts ausgewählt', mtError, [], nil);
    exit;
  end;
  WebCheckBox1.Checked := true;
  AllergieFilter1.Checked := true;
  WebPageControl1.TabIndex := Save_Tab_Index;
  WebPageControl1Change(Self);
  Refresh_ShopArtikel(0);
end;

procedure TForm2.WebButton58Click(Sender: TObject);
var i : integer;
begin
  for i := 0 to AllergieListe.Count - 1 do begin
    AllergieFilter[i] := false;
    WebStringGrid1.Cells[1,i+1] := '';
  end;
  WebCheckBox1.Checked := false;
  AllergieFilter1.Checked := false;
  WebPageControl1.TabIndex := Save_Tab_Index;
  WebPageControl1Change(Self);
  Refresh_ShopArtikel(0);
end;

procedure TForm2.WebButton59Click(Sender: TObject);
var i,j : integer;
begin
  j := 0;
  for i := 0 to AllergieListe.Count - 1 do begin
    AllergieFilter[i] := Save_AllergieFilter[i];
    if AllergieFilter[i] then begin
      WebStringGrid1.Cells[1,i] := 'X';
      j := j + 1;
    end
    else begin
      WebStringGrid1.Cells[1,i] := '';
    end;
  end;
  if j > 0 then WebCheckBox1.Checked := true
  else WebCheckBox1.Checked := false;
  AllergieFilter1.Checked := WebCheckBox1.Checked;
  WebPageControl1.TabIndex := Save_Tab_Index;
  WebPageControl1Change(Self);
  WebPanel9.Visible := true;
end;

procedure TForm2.WebButton5Click(Sender: TObject);
var ts : TStringList;
    i : integer;
begin
  if WK_Artikel = 0 then begin
    MessageDlg('Sie haben (noch) keine Artikel im Warenkorb', mtError, [], nil);
    exit;
  end;
  Save_Tab_Index := WebPageControl1.TabIndex;
  WebPanel36.Visible := false;


{  if Akt_User_ID = 0 then begin
    MessageDlg('Sie müssen sich erst anmelden um die Bestellung abschliessen zu können', mtError, [], nil);
    exit;
  end;
}
//  arrowbt1.setAttribute('class', 'btn btn-webcore btn-arrow-right');
//  arrowbt2.setAttribute('class', 'btn btn-webcore btn-arrow-right btn-webcore-disabled');
//  arrowbt3.setAttribute('class', 'btn btn-webcore btn-arrow-right btn-webcore-disabled');

  Check_Angemeldet;
  WebPageControl1.TabIndex := 5;
  WebPageControl1Change(Self);
  if Akt_User_ID = 0 then begin
    WebPageControl4.TabIndex := 3;
  end
  else begin
    WebPageControl4.TabIndex := 3;
  end;

  ts := TStringList.Create;
  ts.LoadFromFile('VKS.txt');
  WebComboBox7.Items.Clear;
  WebComboBox7.Items.Add('Ihre Auswahl ...');
  WebComboBox7.Items.Add('Lieferung an Rechnungsanschrift');
  WebComboBox7.Items.Add('Lieferung an andere Adresse');
  for i := 0 to ts.Count - 1 do begin
    WebComboBox7.Items.Add('Abholung in: '+ ts[i]);
  end;
  ts.Free;
  WebComboBox7.ItemIndex := 0;
  if Akt_Geraete_Art = GA_Handy then begin
    WebPanel1.Height := 155;
  end
  else begin
    WebPanel1.Height := 105;

  end;

  WebPanel9.Visible := false;

end;

procedure TForm2.WebButton60Click(Sender: TObject);
var i : integer;
begin
  i := WebStringGrid1.Row;
  if i = -1 then begin
    MessageDlg('Sie müssen erst einen Eintrag auswählen', mtError, [], nil);
    exit;
  end;
  AllergieFilter[i] := not AllergieFilter[i];
  if AllergieFilter[i] then begin
    WebStringGrid1.Cells[1,i] := 'X';
  end
  else begin
    WebStringGrid1.Cells[1,i] := '';
  end;
//  WebListBox2.ItemIndex := i;
end;

procedure TForm2.WebButton61Click(Sender: TObject);
var i : integer;
begin
  i := WebStringGrid2.Row;
  if i = -1 then begin
    MessageDlg('Sie müssen erst einen Eintrag auswählen', mtError, [], nil);
    exit;
  end;
  VeganFilter[i] := not VeganFilter[i];
  if VeganFilter[i] then begin
    WebStringGrid2.Cells[1,i] := 'X';
  end
  else begin
    WebStringGrid2.Cells[1,i] := '';
  end;
//  WebListBox2.ItemIndex := i;

end;

procedure TForm2.WebButton62Click(Sender: TObject);
var i,j : integer;
begin
  j := 0;
  for i := 0 to VeganListe.Count - 1 do begin
    if VeganFilter[i] then j := j + 1;
  end;
  if j = 0 then begin
    MessageDlg('Sie haben (noch) nichts ausgewählt', mtError, [], nil);
    exit;
  end;
  WebCheckBox2.Checked := true;
  ErnhrungsFilter1.Checked := true;
  WebPageControl1.TabIndex := Save_Tab_Index;
  WebPageControl1Change(Self);
  Refresh_ShopArtikel(0);

end;

procedure TForm2.WebButton63Click(Sender: TObject);
var i : integer;
begin
  for i := 0 to VeganListe.Count - 1 do begin
    VeganFilter[i] := false;
    WebStringGrid2.Cells[1,i+1] := '';
  end;
  WebCheckBox2.Checked := false;
  ErnhrungsFilter1.Checked := false;
  WebPageControl1.TabIndex := Save_Tab_Index;
  WebPageControl1Change(Self);
  Refresh_ShopArtikel(0);

end;

procedure TForm2.WebButton64Click(Sender: TObject);
var i,j : integer;
begin
  j := 0;
  for i := 0 to VeganListe.Count - 1 do begin
    VeganFilter[i] := Save_VeganFilter[i];
    if VeganFilter[i] then begin
      WebStringGrid2.Cells[1,i] := 'X';
      j := j + 1;
    end
    else begin
      WebStringGrid2.Cells[1,i] := '';
    end;
  end;
  if j > 0 then WebCheckBox2.Checked := true
  else WebCheckBox2.Checked := false;
  ErnhrungsFilter1.Checked := WebCheckBox2.Checked;
  WebPageControl1.TabIndex := Save_Tab_Index;
  WebPageControl1Change(Self);
  WebPanel9.Visible := true;
end;

procedure TForm2.WebButton65Click(Sender: TObject);
var i : integer;
begin
  i := WebStringGrid3.Row;
  if i = -1 then begin
    MessageDlg('Sie müssen erst einen Eintrag auswählen', mtError, [], nil);
    exit;
  end;
  LieferFilter[i] := not LieferFilter[i];
  if LieferFilter[i] then begin
    WebStringGrid3.Cells[1,i] := 'X';
  end
  else begin
    WebStringGrid3.Cells[1,i] := '';
  end;
//  WebListBox2.ItemIndex := i;

end;

procedure TForm2.WebButton66Click(Sender: TObject);
var i,j : integer;
begin
  j := 0;
  for i := 0 to LieferListe.Count - 1 do begin
    if LieferFilter[i] then j := j + 1;
  end;
  if j = 0 then begin
    MessageDlg('Sie haben (noch) nichts ausgewählt', mtError, [], nil);
    exit;
  end;
  WebCheckBox3.Checked := true;
  LieferartenFilter1.Checked := true;
  WebPageControl1.TabIndex := Save_Tab_Index;
  WebPageControl1Change(Self);
  Refresh_ShopArtikel(0);

end;

procedure TForm2.WebButton67Click(Sender: TObject);
var i : integer;
begin
  for i := 0 to LieferListe.Count - 1 do begin
    LieferFilter[i] := false;
    WebStringGrid3.Cells[1,i+1] := '';
  end;
  WebCheckBox3.Checked := false;
  LieferartenFilter1.Checked := false;
  WebPageControl1.TabIndex := Save_Tab_Index;
  WebPageControl1Change(Self);
  Refresh_ShopArtikel(0);

end;

procedure TForm2.WebButton68Click(Sender: TObject);
var i,j : integer;
begin
  j := 0;
  for i := 0 to LieferListe.Count - 1 do begin
    LieferFilter[i] := Save_LieferFilter[i];
    if LieferFilter[i] then begin
      WebStringGrid3.Cells[1,i] := 'X';
      j := j + 1;
    end
    else begin
      WebStringGrid3.Cells[1,i] := '';
    end;
  end;
  if j > 0 then WebCheckBox3.Checked := true
  else WebCheckBox3.Checked := false;
  LieferartenFilter1.Checked := WebCheckBox3.Checked;
  WebPageControl1.TabIndex := Save_Tab_Index;
  WebPageControl1Change(Self);
  WebPanel9.Visible := true;

end;

procedure TForm2.WebButton69Click(Sender: TObject);
var i,j : integer;
begin
  j := -1;
  for i := 0 to WebComboBox3.Items.Count - 1 do begin
    if SKA[i] = 2 then begin
      j := i;
      break;
    end;
  end;
  if j <> -1 then begin
    WebComboBox3.ItemIndex := j;
    WebComboBox3Change(Self);
  end;
end;

procedure TForm2.WebButton6Click(Sender: TObject);
begin
  Akt_Modus := Add_Modus;
  VB_Vorschlag := 0;
  WebPanel42.Visible := false;
  WebPanel43.Visible := false;
  WebPanel36.Visible := true;
  WebPanel9.Visible := true;
  WebPanel7.Visible := true;
  WebPanel42.Visible := false;
  WebPanel43.Visible := false;
  WebButton87.Visible := false;
  WebButton91.Visible := false;

  Akt_Auswahl_Menu := 0;

  WebPageControl1.TabIndex := 3;
  WebPageControl1Change(Self);
  WebComboBox3Change(Self);
//  Refresh_ShopArtikel(0);
end;

procedure TForm2.WebButton70Click(Sender: TObject);
begin
  WebEdit2.Text := '';
  WebEditAutoComplete1.Text := '';
  Refresh_ShopArtikel(0);

end;

procedure TForm2.WebButton71Click(Sender: TObject);
var i,j : integer;
begin
{  j := 0;
  for i := 0 to LieferListe.Count - 1 do begin
    if LieferFilter[i] then j := j + 1;
  end;
  if j = 0 then begin
    MessageDlg('Sie haben (noch) nichts ausgewählt', mtError, [], nil);
    exit;
  end;
}
  WebEdit2.Text := WebEditAutoComplete1.Text;
  WebPageControl1.TabIndex := 3; //Save_Tab_Index;
  WebPageControl1Change(Self);
  Refresh_ShopArtikel(0);


end;

procedure TForm2.WebButton72Click(Sender: TObject);
begin
  WebEdit2.Text := '';
  WebEditAutoComplete1.Text := '';
  WebPageControl1.TabIndex := 3; // Save_Tab_Index;
  WebPageControl1Change(Self);
  Refresh_ShopArtikel(0);

end;

procedure TForm2.WebButton73Click(Sender: TObject);
begin
  WebEditAutoComplete1.Text := WebEdit2.Text;
  WebPageControl1.TabIndex := 3; // Save_Tab_Index;
  WebPageControl1Change(Self);
end;

procedure TForm2.WebButton74Click(Sender: TObject);
var i : integer;
begin
  for i := 0 to AllergieListe.Count - 1 do begin
    Allergiefilter[i] := false;
    WebStringGrid1.Cells[1,i+1] := '';
  end;
  WebCheckBox1.Checked := false;
  AllergieFilter1.Checked := false;
  Refresh_ShopArtikel(0);

end;

procedure TForm2.WebButton75Click(Sender: TObject);
begin
  WebButton101Click(Self);
end;

procedure TForm2.WebButton76Click(Sender: TObject);
var i : integer;
begin
  for i := 0 to VeganListe.Count - 1 do begin
    Veganfilter[i] := false;
    WebStringGrid2.Cells[1,i+1] := '';

  end;
  WebCheckBox2.Checked := false;
  ErnhrungsFilter1.Checked := false;
  Refresh_ShopArtikel(0);


end;

procedure TForm2.WebButton77Click(Sender: TObject);
var i : integer;
begin
  for i := 0 to LieferListe.Count - 1 do begin
    Lieferfilter[i] := false;
    WebStringGrid3.Cells[1,i+1] := '';

  end;
  WebCheckBox3.Checked := false;
  WebCheckBox3.Checked := false;
  Refresh_ShopArtikel(0);


end;

procedure TForm2.WebButton79Click(Sender: TObject);
begin
  if WebEdit26.Text <> Admin_Kennwort then begin
    MessageDlg('Kennwort ist ungültig', mtError, [], nil);
    exit;
  end;
  Admin_Angemeldet := true;
  WebPageControl8.TabIndex := 1;
  WebPageControl8Change(Self);

end;

procedure TForm2.WebButton7Click(Sender: TObject);
begin
  WebEdit5.Readonly := false;
  WebComboBox4.Enabled := true;
  WebComboBox5.Enabled := true;
  WebComboBox6.Enabled := true;
  WebPanel6.Visible := true;

  WebButton94Click(Self);

  if Akt_Modus = Add_Modus then begin
    WebPageControl1.TabIndex := 3;
    WebPageControl1Change(Self);
    if Akt_Geraete_Art = GA_Handy then begin
      WebPanel9.Height := 50; // WebPanel9.Height * 2;
      WebButton10.Visible := false;
      WebButton2.Visible := true;
      WebButton6.Visible := true;
      WebButton3.Visible := true;
      WebButton5.Visible := true;
      WebButton137.Visible := true;

    end
    else begin
      WebPanel9.Height := 50; // WebPanel9.Height * 2;
      WebButton2.Visible := true;
      WebButton6.Visible := true;
      WebButton3.Visible := true;
      WebButton5.Visible := true;
      WebButton4.Visible := true;
      WebButton11.Visible := true;
      WebButton17.Visible := true;

    end;
    if Akt_Geraete_Art = GA_Handy then begin
      WebPanel1.Height := 105;
      WebPanel9.Visible := true;
      WebPanel36.Visible := false;

    end
    else begin
      WebPanel1.Height := 105;
      WebPanel9.Visible := true;
      WebPanel36.Visible := true;

    end;

    WebDBResponsiveGrid1.ElementHandle.scrollTop := Akt_ScrollPos;
  end
  else if Akt_Modus = Add_Modus_Theke then begin
    if Akt_Geraete_Art = GA_Handy then begin
      WebPanel1.Height := 105;
      WebButton10.Visible := false;
      WebButton2.Visible := true;
      WebButton6.Visible := true;
      WebButton3.Visible := true;
      WebButton5.Visible := true;
      WebButton137.Visible := true;

    end
    else begin
      WebPanel1.Height := 105;
      WebButton2.Visible := true;
      WebButton6.Visible := true;
      WebButton3.Visible := true;
      WebButton5.Visible := true;
      WebButton4.Visible := true;
      WebButton11.Visible := true;
      WebButton17.Visible := true;

    end;

    WebPanel9.Visible := true;
    WebPageControl1.TabIndex := 13;
    WebPageControl1Change(Self);
  end
  else begin
    Akt_Modus := Add_Modus;
    if Akt_Geraete_Art = GA_Handy then begin
      WebPanel9.Height := 50; // WebPanel9.Height * 2;
      WebButton10.Visible := false;
      WebButton2.Visible := true;
      WebButton6.Visible := true;
      WebButton3.Visible := true;
      WebButton5.Visible := true;
      WebButton137.Visible := true;

    end
    else begin
      WebPanel9.Height := 50; // WebPanel9.Height * 2;
      WebButton2.Visible := true;
      WebButton6.Visible := true;
      WebButton3.Visible := true;
      WebButton5.Visible := true;
      WebButton4.Visible := true;
      WebButton11.Visible := true;
      WebButton17.Visible := true;

    end;
    if Akt_Geraete_Art = GA_Handy then begin
      WebPanel1.Height := 105;
    end
    else begin
      WebPanel1.Height := 105;

    end;

    WebPanel9.Visible := true;

    if WK_Artikel > 0 then begin
      WebButton3Click(Self);
    end
    else begin
      WebPageControl1.TabIndex := 3;
      WebPageControl1Change(Self);
    end;
  end;
  if VB_Vorschlag <> 0 then begin
    WebPanel9.Visible := false;
    WebPanel36.Visible := false;
  end;


end;

procedure TForm2.WebButton80Click(Sender: TObject);
begin
  WebPageControl1.TabIndex := Save_Tab_Index;
  WebPageControl1Change(Self);
end;

procedure TForm2.WebButton81Click(Sender: TObject);
var B,
    H,
    A : integer;
begin
  B := StrToInt(WebEdit27.Text);
  H := StrToInt(WebEdit28.Text);
  A := StrToInt(WebEdit29.Text);

    DataModule1.XDataWebClientApiService.RawInvoke('IApiService.SaveCFG', [IntToStr(AktFirmaNo), AktKDName,WebListBox2.Items[WebListBox2.ItemIndex],
         IntToStr(WebRadioGroup2.ItemIndex),
         IntToStr(Save_Farben[1]),
         IntToStr(Save_Farben[2]),
         IntToStr(Save_Farben[3]),
         IntToStr(Save_Farben[4]),
         IntToStr(Save_Farben[5]),
         IntToStr(Save_Farben[6]),
         IntToStr(B),
         IntToStr(H),
         IntToStr(A),

         '','','','','','','',''
    ],

    procedure(Response: TXDataClientResponse)
    var
      i: integer;
      hst : String;
      ts : TStringList;
      TSD : TSL_Dataset;
    begin
      hst := JS.ToString(JS.ToObject(Response.Result)['value']);
      if hst = '1' then begin
        WebPageControl8.TabIndex := 0;
        WebPageControl8Change(Self);
        Akt_Theme := WebListBox2.ItemIndex;
        Akt_ArtikelWahl := WebRadioGroup2.ItemIndex;
        Set_Display_Options;
      end
      else begin
        MessageDlg('Speichern nicht möglich', mtError, [], nil);
      end;
    end,

    procedure(Error: TXDataClientError)
    begin
      MessageDlg('Speichern nicht möglich', mtError, [], nil);
    end
    );

end;



function TForm2.Add_Menu(Parent : integer;Bez : String) : integer;
var hst : String;
begin

  DataModule1.XDataWebClientApiService.RawInvoke('IApiService.AddTree', [IntToStr(AktFirmaNo), AktKDName,IntToStr(Parent),'5','0','0','0','0',
                                       '0','0','0','',Bez
    ],

    procedure(Response: TXDataClientResponse)
    var
      i: integer;
      hst : String;
      ts : TStringList;
      TSD : TSL_Dataset;
    begin
      hst := JS.ToString(JS.ToObject(Response.Result)['value']);
      Add_Menu := StrtoInt(hst);
    end,

    procedure(Error: TXDataClientError)
    begin
      MessageDlg('Speichern nicht möglich', mtError, [], nil);
    end
    );

end;




procedure TForm2.WebButton82Click(Sender: TObject);
begin

  WebPageControl8.TabIndex := 0;
  WebPageControl8Change(Self);
  Set_Display_Options;
end;

procedure TForm2.WebButton83Click(Sender: TObject);
var i,j : integer;
    tn,
    S : TTreeNode;
    hst : String;
begin
  hst := Trim(WebEdit30.Text);
  if hst = '' then begin
    MessageDlg('Bezeichnung muss eingegeben werden', mtError, [], nil);
    exit;
  end;
  if webtreeview1.Selected = nil then begin
    MessageDlg('Zuerst einen Eintrag wählen', mtError, [], nil);
    exit;
  end;
  S := webtreeview1.Selected;
  j := PMenu_Rec(HMenu_Liste[Akt_Tree_Item])^.ID;
  i := Add_Menu(j,hst);
  webtreeview1.BeginUpdate;
  tn := webtreeview1.Items.AddChild(webtreeview1.Selected,'<font color="#E5413C" style="font-size:10pt">&#x25FC;</font>'+HTML_Decode(hst));
  tn.Tag := i;
  webtreeview1.EndUpdate;
  webtreeview1.Selected := S;
  webtreeview1.Selected.Expand(false);
end;

procedure TForm2.WebButton87Click(Sender: TObject);
begin
  if Kiosk_Modus then begin
    WebPageControl1.TabIndex := 27;
    WebPageControl1Change(Self);
    WebPanel9.Visible := false;
    WebPanel6.Visible := false;
    exit;

  end
  else begin
    WebPanel36.Visible := true;
    WebPanel9.Visible := true;
    WebPanel7.Visible := true;
    WebPanel42.Visible := false;
    WebPanel43.Visible := false;
    WebButton87.Visible := false;
    WebButton91.Visible := false;
    VB_Vorschlag := 0;
    Akt_Auswahl_Menu := 0;
    Refresh_ShopArtikel(0);
  //  WebComboBox3Change(Self);
  end;
end;

procedure TForm2.WebButton88Click(Sender: TObject);
begin
  if not Chat_Verbunden then begin
    WebSocketClient1.HostName := 'localhost'; // '167.86.109.231'; // Chat_Url; // 'localhost';
    WebSocketClient1.Port := 20010;
    Chat_Anmeldung := true;
    WebSocketClient1.Connect;
  end
  else begin
    Chat_Abmeldung := true;
    WebSocketClient1.Send('ich_geh_dann_mal');
    WebTimer8.Enabled := true;

  end;

end;

procedure TForm2.WebButton89Click(Sender: TObject);
var hst : String;
begin
  hst := Trim(WebEdit32.Text);
  if hst <> '' then begin
    hst := '<font color="#000000">'+ hst+'</font>';

    WebStringGrid4.RowCount := WebStringGrid4.RowCount + 1;
    WebStringGrid4.Cells[0,WebStringGrid4.RowCount-1] := hst;
    WebStringGrid4.TopRow := WebStringGrid4.RowCount-1;

//    WebListBox4.Items.Add('> '+hst);
    WebSocketClient1.Send(hst);
    WebEdit32.Text := '';
  end
  else begin
    MessageDlg('Bitte erst eine Nachricht erfassen', mtError, [], nil);
  end;
end;

procedure TForm2.WebButton8Click(Sender: TObject);
var hst : String;
begin
  Save_Tab_Index := WebPageControl1.TabIndex;


  WebButton2.Visible := false;
  WebButton6.Visible := false;
  WebButton3.Visible := false;
  WebButton5.Visible := false;
  WebButton4.Visible := false;
  WebButton11.Visible := false;
  WebButton17.Visible := false;
  WebButton56.Visible := false;
  WebButton90.Visible := false;
  WebButton137.Visible := false;

  WebPanel9.Height := 50;
  if Akt_Geraete_Art = GA_Handy then begin
    WebPanel1.Height := 105;
  end
  else begin
    WebPanel1.Height := 105;

  end;

  WebPanel9.Visible := true;
  WebButton10.Visible := true;
  WebButton10.Left := WebButton2.Left;
  WebButton10.Top := WebButton2.Top;
  WebButton10.BeginUpdate;
  WebButton10.Caption :='<i class="material-icons">arrow_back</i> <span class="badge badge-danger badge-xs">' + ' Zurück zu den <br>Artikel-Details' + '</span>';
  Set_Button_Class(1,WebButton10);
  WebButton10.EndUpdate;



{  WebPageControl1Sheet1.TabVisible := false;
  WebPageControl1Sheet2.TabVisible := false;
  WebPageControl1Sheet3.TabVisible := false;
//  WebPageControl1Sheet4.TabVisible := false;
  WebPageControl1Sheet5.TabVisible := false;
  WebPageControl1Sheet6.TabVisible := false;
  WebPageControl1Sheet7.TabVisible := false;
}
  WebImageSlider1.ImageUrls.Clear;

//  WebImageSlider1.ImageUrls.Add(Bilder_Url + 'NB_996_1.png');
//  WebImageSlider1.ImageUrls.Add(Bilder_Url + 'NB_996_2.png');

  Datamodule1.ShopArtikelThumbsDataSet.First;
  while not Datamodule1.ShopArtikelThumbsDataSet.EOF do begin
    hst := Bilder_Url + 'NB_'+Datamodule1.ShopArtikelThumbsDataSet.FieldByname('ID2').AsString + '_'
      + Datamodule1.ShopArtikelThumbsDataSet.FieldByname('lfdno').AsString + '.png';
    WebImageSlider1.ImageUrls.Add(hst);
    Datamodule1.ShopArtikelThumbsDataSet.Next;
  end;
  WebPageControl1.TabIndex := 6;
  WebPageControl1Change(Self);
//  WebImageSlider1.Appearance.NavigationStyle := TNavigationStyle(2);

  WebImageSlider1.RefreshImages;
  WebImageSlider1.ActiveImageIndex := 0;


end;

procedure TForm2.WebButton90Click(Sender: TObject);
begin
  if not LautSprecher_ein then begin
    WebButton92.Caption := '<i class="material-icons">volume_up</i> <span class="badge badge-danger badge-xs">' + 'Lautsprecher ein' + '</span>';
  end
  else begin
    WebButton92.Caption := '<i class="material-icons">volume_off</i> <span class="badge badge-danger badge-xs">' + 'Lautsprecher aus' + '</span>';
  end;

  if not Chat_Verbunden then begin

    WebPanel44.Visible := true;
    WebButton88Click(Self);

  end;
end;

procedure TForm2.WebButton91Click(Sender: TObject);
begin
  WebButton90Click(Self);
end;

procedure TForm2.WebButton92Click(Sender: TObject);
begin
  LautSprecher_ein := not LautSprecher_ein;
  if not LautSprecher_ein then begin
    WebButton92.Caption := '<i class="material-icons">volume_up</i> <span class="badge badge-danger badge-xs">' + 'Lautsprecher ein' + '</span>';
  end
  else begin
    WebButton92.Caption := '<i class="material-icons">volume_off</i> <span class="badge badge-danger badge-xs">' + 'Lautsprecher aus' + '</span>';
  end;
end;

procedure TForm2.WebButton93Click(Sender: TObject);
begin
  WebButton90Click(Self);
end;

procedure TForm2.WebButton94Click(Sender: TObject);
begin
  WebPageControl2.TabIndex := 0;
  WebComboBox16.ItemIndex := 0;
  WebPageControl2Change(Self);
end;

procedure TForm2.WebButton95Click(Sender: TObject);
begin
  Open_Standard_Query(1,'Get_Zutaten','ID;'+IntToStr(Akt_Artikel_ID)+';',@Zeige_Zutaten,GetBeiwert);

end;

procedure TForm2.WebButton96Click(Sender: TObject);
begin
  Open_Standard_Query(1,'Get_NW','ID;'+IntToStr(Akt_Artikel_ID)+';',@Zeige_Naehrwerte,GetBeiwert);

//  WebPageControl2.TabIndex := 2;
//  WebPageControl2Change(Self);
end;

procedure TForm2.WebButton97Click(Sender: TObject);
begin
  WebPageControl2.TabIndex := 3;
  WebPageControl2Change(Self);
end;

procedure TForm2.WebButton98Click(Sender: TObject);
begin
  WebPageControl2.TabIndex := 4;
  WebPageControl2Change(Self);
end;

procedure TForm2.WebButton99Click(Sender: TObject);
begin
  if not Chat_Verbunden then begin
    MessageDlg('Sie müssen den Chat öffnen, um die Möglichkeit eines Live-Bildes zu haben', mtError, [], nil);
    exit;
  end;
  if Chat_Live_ID <> Akt_Artikel_ID then begin
    MessageDlg('Für diesen Artikel liegt kein Live-Bild, ggf. anfordern', mtError, [], nil);
    exit;
  end;

  WebPageControl2.TabIndex := 5;
  WebPageControl2Change(Self);
end;

procedure TForm2.WebCheckBox1Click(Sender: TObject);
begin
  WebImageControl7Click(Self);
end;

procedure TForm2.WebClientConnection1AfterConnect(Sender: TObject);
begin
  MessageDlg('AfterConnect Konfiguration', mtError, [], nil);
  WebClientDataset1.Open;
end;

procedure TForm2.WebClientConnection1ConnectError(Sender: TObject;
  ErrorCode: Integer);
begin
  MessageDlg('Konfiguration nicht gelesen', mtError, [], nil);

end;



procedure TForm2.WebColorPicker1Select(Sender: TObject);
begin
  Save_Farben[WebListBox3.ItemIndex + 1] := WebColorPicker1.Color;
end;

procedure TForm2.WebComboBox10Change(Sender: TObject);
begin
  Refresh_ShopArtikel(0);
end;

procedure TForm2.WebComboBox11Change(Sender: TObject);
var hst : String;
begin
  case WebComboBox11.ItemIndex of
    0 : hst := 'lfdno desc';
    1 : hst := 'Punkte desc,lfdno desc';
    2 : hst := 'Punkte,lfdno desc';
  end;
  WebDBGrid2.Datasource := nil;
  Datamodule1.BewertungDataSet.Close;
  Datamodule1.BewertungDataSet.AfterOpen := Zeige_Bewertungen;
  Datamodule1.BewertungDataSet.QueryString :='$filter=ID eq '+IntToStr(Akt_Artikel_ID)+'&$orderby='+hst;
  Datamodule1.BewertungDataSet.Load;

end;

procedure TForm2.WebComboBox12Change(Sender: TObject);
begin
  Refresh_Bestell_Liste;
end;

procedure TForm2.WebComboBox14Change(Sender: TObject);

begin
  case WebComboBox14.ItemIndex of
    0 : begin
          WebPanel9.Visible := true;
          Akt_Geraete_Art := GA_DeskTop;

          WebButton6Click(Self);
          Kiosk_Modus_Aktiv := false;
          Width := 1360;
        end;
    1 : begin
          WebPanel9.Visible := true;
          Akt_Geraete_Art := GA_Handy;
          Device_ist_Handy := true;
//          WebButton6Click(Self);
          Kiosk_Modus_Aktiv := false;
          Width := 360;
        end;
    2 : begin
          WebPanel9.Visible := true;
          WebPanel6.Visible := true;
          Akt_Geraete_Art := GA_Tablet;

          WebButton6Click(Self);
          Kiosk_Modus_Aktiv := false;

        end;
    3 : begin
          WebComboBox3.ItemIndex := WebComboBox3.Items.IndexOf('Kiosk-Artikel');
          WebComboBox3Change(Self);
          Kiosk_Modus_Aktiv := true;
          Akt_Geraete_Art := GA_Desktop;

          WebPageControl1.TabIndex := 29;
          WebPageControl1Change(Self);
          WebPanel9.Visible := false;
          WebPanel6.Visible := false;
        end;

  end;
  Set_Grid_Templates;
  Resize_Panel_9;

end;

procedure TForm2.WebComboBox15Change(Sender: TObject);
begin
  WebListBox1.ItemIndex := WebComboBox15.ItemIndex;
  Refresh_ShopArtikel(0);
end;

procedure TForm2.WebComboBox16Change(Sender: TObject);
begin
  case WebComboBox16.ItemIndex of
    0 : WebButton94Click(Self);
    1 : WebButton95Click(Self);
    2 : WebButton96Click(Self);
    3 : WebButton97Click(Self);
    4 : WebButton98Click(Self);
    5 : WebButton99Click(Self);
  end;
end;

procedure TForm2.WebComboBox17Change(Sender: TObject);
begin
  WebLabel22.BeginUpdate;
  case WebComboBox17.ItemIndex of
    0 : WebLabel22.Caption := 'Klicken Sie auf die<br>Wurst Ihrer Wahl';
    1 : WebLabel22.Caption := 'Klicken Sie auf das<br>Fleisch Ihrer Wahl';
    2 : WebLabel22.Caption := 'Klicken Sie auf den<br>Käse Ihrer Wahl';
    3 : WebLabel22.Caption := 'Klicken Sie auf das<br>Essen Ihrer Wahl';
  end;
  WebLabel22.EndUpdate;
  WebPageControl3.TabIndex := WebComboBox17.ItemIndex;
  WebPageControl3Change(Self);
end;

procedure TForm2.WebComboBox3Change(Sender: TObject);
var i,j : integer;
begin
  i := WebComboBox3.ItemIndex;
  j := SKA[i];
  if j = 11 then begin
    if Akt_User_ID = 0 then begin
      MessageDlg('Sie müssen sich erst anmelden um in der Kategorie "Bereits gekaufte Artikel" suchen zu können', mtError, [], nil);
      WebTimer2.Enabled := true;
      exit;
    end;
  end;
  Ohne_Check_Kategorie := false;

  Akt_Kat := j;
  case j of
    1 : Akt_Kat_Proc := 'BERECHNE_WG_SHOP_SA';
    2 : Akt_Kat_Proc := 'BERECHNE_WG_SHOP_ALLE';
    3 : Akt_Kat_Proc := 'BERECHNE_WG_SHOP_REGIONAL';
    4 : Akt_Kat_Proc := 'BERECHNE_WG_SHOP_BIO';
    5 : Akt_Kat_Proc := 'BERECHNE_WG_SHOP_NEU';
    6 : Akt_Kat_Proc := 'BERECHNE_WG_SHOP_REST';
    7 : Akt_Kat_Proc := 'BERECHNE_WG_SHOP_HALTBAR';
    8 : Akt_Kat_Proc := 'BERECHNE_WG_SHOP_VORBESTELL';
    9 : Akt_Kat_Proc := 'BERECHNE_WG_SHOP_BESTSELLER';
    12 : Akt_Kat_Proc := 'BERECHNE_WG_SHOP_KIOSK';

  end;
  Lade_Kategorie_WG;

//  Datamodule1.ShopKatWGDataset.Active := false;
//  Datamodule1.ShopKatWGDataset.EntitySetname := Akt_Kat_Proc;
//  Datamodule1.ShopKatWGDataset.Load;

  exit;

  WebHttpRequest1.URL := API_Url + Akt_Kat_Proc;
  WebHttpRequest1.Execute;

end;

procedure TForm2.WebDBGrid1GetCellData(Sender: TObject; ACol, ARow: Integer;
  AField: TField; var AValue: string);
var hst : String;
begin
  hst := Uppercase(AField.Fieldname);
  if hst = 'PREIS' then begin
    AValue := '<p style="text-align:right;">'+Trim(RealStr(AField.AsInteger / 100,10,2,true)+'</p>');
  end
  else if hst = 'GPREIS' then begin
    AValue := '<p style="text-align:right;">'+Trim(RealStr(AField.AsInteger / 100000,10,2,true)+'</p>');
  end
  else if hst = 'MENGE' then begin
    AValue := '<p style="text-align:right;">' + Trim(RealStr(AField.AsInteger / 1000,10,3,true))+'</p>';
  end
  else begin
    AValue := '<p style="text-align:left;">'+AField.AsString + '</p>';
  end;

end;

procedure TForm2.WebDBResponsiveGrid1ItemClick(Sender: TObject; Index: Integer);
var i : integer;
    hst : String;
begin
  Akt_ScrollPos := WebDBResponsiveGrid1.ElementHandle.scrollTop;

  Akt_Index := WebDBResponsiveGrid1.ItemIndex;
  WebDBResponsiveGrid1.DataSource.DataSet.First;
  WebDBResponsiveGrid1.DataSource.DataSet.MoveBy(Index);
//  WebDBResponsiveGrid1.DataSource.DataSet.last;
  Akt_Artikel_ID := WebDBResponsiveGrid1.DataSource.DataSet.FieldByName('ID').AsInteger;
  if Akt_Artikel_ID = 0 then exit;
//  WebLabel10.Caption := WebDBResponsiveGrid1.DataSource.DataSet.Fieldbyname('Bezeichnung').AsString;
//  WebLabel8.Caption := WebDBResponsiveGrid1.DataSource.DataSet.Fieldbyname('Bezeichnung2').AsString;
  WebLabel84.Caption := WebDBResponsiveGrid1.DataSource.DataSet.Fieldbyname('Bezeichnung').AsString;
//  p := WebDBResponsiveGrid1.VScrollbar..ItemIndex := 3;
//  Lade_Artikel_Bilder;
//  exit;
  WebLabel72.Visible := false;
  WebPanel36.Visible := false;

  if Chat_Verbunden then begin
    hst := '<font color="#000000">'+ WebLabel84.Caption+'</font>';
    WebSocketClient1.Send('@Artikel:'+InttoStr(Akt_Artikel_ID)+';'+hst);
  end;

  if WebDBResponsiveGrid1.DataSource.DataSet.Fieldbyname('KZ_VV').AsInteger = 1 then begin
    Check_VV_Verfuegbar;

  end
  else begin
    Check_Bestand;
    DataModule1.ArtikelDataSet.Close;
    DataModule1.ArtikelDataSet.AfterOpen := Zeige_Artikel_Details;
    DataModule1.WebClientConnection8.Active := false;
    DataModule1.WebClientConnection8.URI := Auth_URL + 'LoginService/MyGetShopArtikel?P1='+IntToStr(Akt_Artikel_ID)+'&P2='+IntToStr(AktFirmaNo)+'&P3='+My_Cookie+'&P4='+GetBeiwert
      + '&P5='+IntToStr(AktKDID)+'&P6=0&P7=0&P8=0&P9=0'
      + '&P10=0&P11='+IntToStr(0)
      + '&P12='+IntToStr(0)+'&P13='+IntToStr(0)
      + '&P14='+' '+'&P15='+IntToStr(0)
      + '&P16='+IntToStr(0);
    DataModule1.WebClientConnection8.Active := true;
    DataModule1.ArtikelDataSet.Open;
{Artikelstamm
    Datamodule1.ArtikelDataSet.Close;
    Datamodule1.ArtikelDataSet.AfterOpen := Zeige_Artikel_Details;
    Datamodule1.ArtikelDataSet.QueryString :='$filter=ID eq '+IntToStr(Akt_Artikel_ID);
    Datamodule1.ArtikelDataSet.Load;
}
  end;
  if Device_ist_Handy then begin
    WebPanel6.Visible := false;
  end;
end;

procedure TForm2.WebDBResponsiveGrid1ItemGetFieldValue(Sender: TObject;
  Index: Integer; AFieldName: string; var AValue: string);
var
  d: double;
  i : integer;
  hst,hst2,hst3,M : String;
begin
  hst := Uppercase(AFieldName);
  if hst = 'PREIS' then begin
    i := WebDBResponsiveGrid1.DataSource.DataSet.FieldByName('Preis').AsInteger;
    M := Uppercase(WebDBResponsiveGrid1.DataSource.DataSet.FieldByName('MEBEZ').AsString);
    d := Gerundete_Realzahl(i/100,2);
    if M = 'KG' then begin
      if WebDBResponsiveGrid1.DataSource.DataSet.FieldByName('KZ_100g').AsInteger = 1 then begin
        AValue := RealStr(d/10,12,2,true) + ' € pro 100g';
      end
      else begin
        AValue := RealStr(d,12,2,true) + ' € pro kg';
      end;
      if WebDBResponsiveGrid1.DataSource.DataSet.FieldByName('KZ_Gramm').AsInteger = 1 then begin
        AValue := AValue + '<br>(grammgenaue Abrechnung)';
      end;
    end
    else begin
      AValue := RealStr(d,12,2,true) + ' € pro ' + WebDBResponsiveGrid1.DataSource.DataSet.FieldByName('MEBEZ').AsString;
    end;
//    AValue := 'xx';
  end
  else if hst = 'BILD' then begin
    hst2 := WebDBResponsiveGrid1.DataSource.DataSet.FieldByName('ID').AsString;
    if Device_ist_Handy then begin
      AValue := Bilder_Url + 'TH_'+hst2 + '_1.png';
    end
    else begin
      AValue := Bilder_Url + 'KB2_'+hst2 + '.png';

    end;
  end
  else if hst = 'BEWERTUNG' then begin
    case WebDBResponsiveGrid1.DataSource.DataSet.FieldByName('Bewertung').AsInteger of
      0 .. 49 : hst3 := '0_Sterne_weissk.png';
      50 .. 99 : hst3 := '0_5_Sterne_weissk.png';
      100 .. 149 : hst3 := '1_Sterne_weissk.png';
      150 .. 199 : hst3 := '1_5_Sterne_weissk.png';
      200 .. 249 : hst3 := '2_Sterne_weissk.png';
      250 .. 299 : hst3 := '2_5_Sterne_weissk.png';
      300 .. 349 : hst3 := '3_Sterne_weissk.png';
      350 .. 399 : hst3 := '3_5_Sterne_weissk.png';
      400 .. 449 : hst3 := '4_Sterne_weissk.png';
      450 .. 500 : hst3 := '5_Sterne_weissk.png';

    end;
    hst2 := '<img class="imgitem2" src="'
          + Bilder_Url + hst3
          + '">';
    i := WebDBResponsiveGrid1.DataSource.DataSet.FieldByName('BewertungZahl').AsInteger;
    hst2 := hst2 + '('+IntToStr(i)+') '
            + Trim(RealStr(WebDBResponsiveGrid1.DataSource.DataSet.FieldByName('Bewertung').AsInteger/100,10,2,false))+'/5,00';
    AValue := hst2;
  end;
end;

procedure TForm2.WebDBResponsiveGrid2ItemClick(Sender: TObject; Index: Integer);
var hst2 : String;
begin
  hst2 := WebDBResponsiveGrid2.DataSource.DataSet.FieldByName('ID2').AsString + '_'
          + WebDBResponsiveGrid2.DataSource.DataSet.FieldByName('lfdno').AsString;
  WebImageControl11.Url := Bilder_Url + 'PV_'+hst2 + '.png';

end;

procedure TForm2.WebDBResponsiveGrid2ItemGetFieldValue(Sender: TObject;
  Index: Integer; AFieldName: string; var AValue: string);
var hst,
    hst2,
    hst3 : String;
begin
  hst := Uppercase(AFieldname);
  if hst = 'BILD' then begin
    hst2 := WebDBResponsiveGrid2.DataSource.DataSet.FieldByName('ID2').AsString;
    hst3 := WebDBResponsiveGrid2.DataSource.DataSet.FieldByName('lfdno').AsString;
    AValue :=

//    'https://download.tmssoftware.com/tmsweb/demos/tmsweb_responsivegrid/img/bmw3_96.jpg';

//    'http://localhost/c/kw/Icons/Email.bmp';
//    'file://c:\kw\Icons\Email.bmp';
     Bilder_Url + 'TH_'+hst2 + '_'+ hst3 + '.png';
//     'https://essenszeit.fleischerei-haustein.net/images/Cancel.png';
  end;

end;

procedure TForm2.WebDBResponsiveGrid3ItemClick(Sender: TObject; Index: Integer);
var hst : String;
begin

  exit;
  WebDBResponsiveGrid3.DataSource.DataSet.First;
  WebDBResponsiveGrid3.DataSource.DataSet.MoveBy(Index);
  hst := IntToStr(Index) + '/' + WebDBResponsiveGrid3.DataSource.DataSet.FieldByName('lfdno').AsString;
  MessageDlg(hst, mtError, [], nil);
end;

procedure TForm2.WebDBResponsiveGrid3ItemCreated(Sender: TObject;
  Index: Integer);
var btn : TButton;
    i : integer;
begin
  WebDBResponsiveGrid3.DataSource.DataSet.First;
  WebDBResponsiveGrid3.DataSource.DataSet.MoveBy(Index);
  i := WebDBResponsiveGrid3.DataSource.DataSet.Fieldbyname('lfdno').AsInteger;
  btn := TButton.Create('Del_btn'+i.ToString);
//  Set_Button_Class(1,btn);
  btn.Tag := i;
  btn.OnClick := Del_ButtonClick;
  btn.Caption := '<i class="material-icons">delete</i> <span class="badge badge-danger badge-xs">'+ 'Löschen'+'</span>';
  btn.Height := 18;
  btn := TButton.Create('Edit_btn'+i.ToString);
//  Set_Button_Class(1,btn);
  btn.Tag := i;
  btn.OnClick := Edit_ButtonClick;
  btn.Caption := '<i class="material-icons">edit</i> <span class="badge badge-danger badge-xs">'+ 'Ändern'+'</span>';
  btn.Height := 18;

end;

procedure TForm2.WebDBResponsiveGrid3ItemGetFieldValue(Sender: TObject;
  Index: Integer; AFieldName: string; var AValue: string);
var
  d: double;
  hst,hst2 : String;
begin
  hst := Uppercase(AFieldName);
  if hst = 'GPREIS' then begin
    d := WebDBResponsiveGrid3.DataSource.DataSet.FieldByName('GPreis').AsFloat/100;
    AValue := ' Gesamtpreis:' + RealStr(d,12,2,true) + ' €';
  end
  else if hst = 'MENGE' then begin
    d := WebDBResponsiveGrid3.DataSource.DataSet.FieldByName('Menge').AsInteger / 1000;
    AValue := RealStr(d,12,3,true) + ' ' + WebDBResponsiveGrid3.DataSource.DataSet.FieldByName('ZStr1').AsString;
  end

  else if hst = 'BILD' then begin
    hst2 := WebDBResponsiveGrid3.DataSource.DataSet.FieldByName('ARTIKELID').AsString;
    AValue :=
    Bilder_Url + 'KB2_'+hst2 + '.png';
  end
  else if hst = 'WKZ1' then begin
    if WebDBResponsiveGrid3.DataSource.DataSet.FieldByName('KZ_VV').AsInteger = 1 then begin
      hst2 := WebDBResponsiveGrid3.DataSource.DataSet.FieldByName('VVBez').AsString;
      hst2 := HTML_Decode(hst2);
      AValue := hst2;
    end
    else begin
      d := WebDBResponsiveGrid3.DataSource.DataSet.FieldByName('Menge').AsInteger / 1000;
      AValue := RealStr(d,12,3,true) + ' ' + HTML_Decode(WebDBResponsiveGrid3.DataSource.DataSet.FieldByName('ZStr1').AsString);
    end;
  end
  else if hst = 'WKZ2' then begin
    AValue := HTML_Decode(WebDBResponsiveGrid3.DataSource.DataSet.FieldByName('ZStr2').AsString);
  end
  else if hst = 'WKZ3' then begin
    AValue := HTML_Decode(WebDBResponsiveGrid3.DataSource.DataSet.FieldByName('ZStr3').AsString);
  end



  else begin
    if (WebDBResponsiveGrid3.DataSource.DataSet.FieldByName(hst).DataType = ftString)
//     or (WebDBResponsiveGrid3.DataSource.DataSet.FieldByName(hst).DataType = ftWideString)
     then begin
      hst2 := WebDBResponsiveGrid3.DataSource.DataSet.FieldByName(hst).AsString;
      hst2 := HTML_Decode(hst2);
//      hst2 := UTF8ToStr(hst2);
      AValue := hst2;
    end;
  end;

end;

procedure TForm2.WebDBResponsiveGrid4ItemClick(Sender: TObject; Index: Integer);
begin
  WebPageControl1.TabIndex := 3;
  WebPageControl1Change(Self);
  Kiosk_Modus := true;
  Akt_Kiosk_Index := Index;
  WebLabel128.Visible := true;
  WebLabel128.Caption := WebDBResponsiveGrid4.Datasource.Dataset.Fieldbyname('Bezeichnung').AsString;
  Refresh_ShopArtikel(0);

end;

procedure TForm2.WebDBResponsiveGrid4ItemGetFieldValue(Sender: TObject;
  Index: Integer; AFieldName: string; var AValue: string);
var hst,
    hst2 : String;
begin
  hst := Uppercase(AFieldName);
  if hst = 'KIOSKBILD' then begin
    hst2 := WebDBResponsiveGrid4.DataSource.DataSet.FieldByName('KIOSKBILD').AsString;
    AValue :=
    Bilder_Url + 'KB2_'+hst2 + '.png';
  end

end;

procedure TForm2.WebFormClose(Sender: TObject; var Action: TCloseAction);
var i : integer;
begin
  Vorles_Liste.Clear;
  Vorles_Liste.Free;
  Bestell_Liste.Free;
  AllergieListe.Free;
  VeganListe.Free;
  LieferListe.Free;
  Shop_Kategorien.Clear;
  Shop_Kategorien.Free;
  Loesche_Theken_Regionen;
  if My_Cookie <> '' then begin
    LS_SetMyToken(Shop_ID_Token,My_Cookie);
  end;

  for i := 0 to Merkmal_Liste.Count - 1 do begin
    dispose(PMerkmal_Rec(Merkmal_Liste[i]));
  end;
  Merkmal_Liste.Clear;
  Merkmal_Liste.Free;
  Merkmal_Liste := nil;

  for i := 0 to HMenu_Liste.Count - 1 do begin
    dispose(PMenu_Rec(HMenu_Liste[i]));
  end;
  HMenu_Liste.Clear;
  HMenu_Liste.Free;
  HMenu_Liste := nil;




  for i := 0 to Laender_Liste.Count - 1 do begin
    dispose(PLaender_Rec(Laender_Liste[i]));
  end;

  Laender_Liste.Clear;
  Laender_Liste.Free;
  Laender_Liste := nil;


  Loesche_Menu_Labels;
  Menu_Labels.Free;
  Menu_Labels := nil;

  for i := 0 to Query_List.Count - 1 do begin
    PQuery_Rec(Query_List[i])^.Q.Close;
    PQuery_Rec(Query_List[i])^.Q.Free;
    PQuery_Rec(Query_List[i])^.Q := nil;
    PQuery_Rec(Query_List[i])^.DS.Active := false;
    PQuery_Rec(Query_List[i])^.DS.Free;
    PQuery_Rec(Query_List[i])^.DS := nil;
    dispose(PQuery_Rec(Query_List[i]));
  end;
  Query_List.Clear;
  Query_List.Free;
  Query_List := nil;



end;


procedure TForm2.WebFormCreate(Sender: TObject);
var
  tn,sn,wn: TTreeNode;
  i,j : integer;
  hst,hst2,bt, ed, lb, co: string;
  ts : TStringList;

begin
  WebTimer12Cnt := 0;
  WebPageControl8.TabIndex := 2;
  WebPanel9.Visible := false;


  GT := Get_Device_Typ;

  Mit_Color_Change := false;
  LautSprecher_ein := true;
  Shop_Start_OK := false;
  Chat_Anmeldung := false;
  Chat_Abmeldung := false;
  In_Konto_Menu := false;
  FRecording := false;
  StartPhase := 0;
  LoeschModus_WK := false;
  WebLabel99.Visible := false;
  Bestell_Liste := TStringList.Create;
  Akt_Konto_Menu := 0;
  Vorles_Liste := TStringList.Create;
  Kiosk_Modus_Aktiv := false;
  WebPageControl3.TabIndex := 0;
  WebComboBox17.ItemIndex := 0;

  for i := 1 to 10 do begin
    Konto_Buttons[i] := nil;
  end;

  for i := 0 to Max_Start_Phasen do begin
    Start_Phasen_array[i] := 0;
    Start_Phase_Aktiv[i] := false;
  end;

  Warenkorb_Gelesen := false;
  VV_Aktiv := false;
  VB_Vorschlag := 0;
  WebImageSlider1.ImageUrls.Clear;
  WebEditAutoComplete1.Text := '';
  Bin_im_Menu := false;
  Menu_Labels := TList.Create;
  Set_Button_Class(1,WebButton8);
  Set_Button_Class(1,WebButton1);
  Set_Button_Class(1,WebButton7);
  Set_Button_Class(1,WebButton25);
  Set_Button_Class(1,WebButton53);
  Set_Button_Class(1,WebButton54);
  Set_Button_Class(1,WebButton69);
  Set_Button_Class(1,WebButton74);
  Set_Button_Class(1,WebButton75);
  Set_Button_Class(1,WebButton70);
  Set_Button_Class(1,WebButton76);
  Set_Button_Class(1,WebButton77);
  Set_Button_Class(1,WebButton37);
  Set_Button_Class(1,WebButton38);
  Set_Button_Class(1,WebButton35);
  Set_Button_Class(1,WebButton36);
  Set_Button_Class(1,WebButton27);
  Set_Button_Class(1,WebButton40);
  Set_Button_Class(1,WebButton41);
  Set_Button_Class(1,WebButton42);
  Set_Button_Class(1,WebButton28);
  Set_Button_Class(1,WebButton29);
  Set_Button_Class(1,WebButton30);
  Set_Button_Class(1,WebButton31);
  Set_Button_Class(1,WebButton22);
  Set_Button_Class(1,WebButton23);
  Set_Button_Class(1,WebButton32);
  Set_Button_Class(1,WebButton33);
  Set_Button_Class(1,WebButton34);
  Set_Button_Class(1,WebButton39);
  Set_Button_Class(1,WebButton45);
  Set_Button_Class(1,WebButton46);
  Set_Button_Class(1,WebButton47);
  Set_Button_Class(1,WebButton48);
  Set_Button_Class(1,WebButton49);
  Set_Button_Class(1,WebButton50);
  Set_Button_Class(1,WebButton51);
  Set_Button_Class(1,WebButton52);
  Set_Button_Class(1,WebButton26);
  Set_Button_Class(1,WebButton24);
  Set_Button_Class(1,WebButton9);
  Set_Button_Class(1,WebButton13);
  Set_Button_Class(1,WebButton14);
  Set_Button_Class(1,WebButton15);
  Set_Button_Class(1,WebButton12);
  Set_Button_Class(1,WebButton16);
  Set_Button_Class(1,WebButton21);
  Set_Button_Class(1,WebButton43);
  Set_Button_Class(1,WebButton44);
  Set_Button_Class(1,WebButton57);
  Set_Button_Class(1,WebButton58);
  Set_Button_Class(1,WebButton59);
  Set_Button_Class(1,WebButton60);
  Set_Button_Class(1,WebButton61);
  Set_Button_Class(1,WebButton62);
  Set_Button_Class(1,WebButton63);
  Set_Button_Class(1,WebButton64);
  Set_Button_Class(1,WebButton65);
  Set_Button_Class(1,WebButton66);
  Set_Button_Class(1,WebButton67);
  Set_Button_Class(1,WebButton68);
  Set_Button_Class(1,WebButton71);
  Set_Button_Class(1,WebButton72);
  Set_Button_Class(1,WebButton73);
  Set_Button_Class(1,WebButton4);
  Set_Button_Class(1,WebButton56);
  Set_Button_Class(1,WebButton2);
  Set_Button_Class(1,WebButton6);
  Set_Button_Class(1,WebButton3);
  Set_Button_Class(1,WebButton11);
  Set_Button_Class(1,WebButton10);
  Set_Button_Class(1,WebButton5);
  Set_Button_Class(1,WebButton17);
  Set_Button_Class(1,WebButton79);
  Set_Button_Class(1,WebButton80);
  Set_Button_Class(1,WebButton55);
  Set_Button_Class(1,WebButton78);
  Set_Button_Class(1,WebButton81);
  Set_Button_Class(1,WebButton82);
  Set_Button_Class(1,WebButton87);
  Set_Button_Class(1,WebButton88);
  Set_Button_Class(1,WebButton89);
  Set_Button_Class(1,WebButton90);
  Set_Button_Class(1,WebButton91);
  Set_Button_Class(1,WebButton92);
  Set_Button_Class(1,WebButton93);

  Set_Button_Class(1,WebButton94);
  Set_Button_Class(1,WebButton95);
  Set_Button_Class(1,WebButton96);
  Set_Button_Class(1,WebButton97);
  Set_Button_Class(1,WebButton98);
  Set_Button_Class(1,WebButton99);
  Set_Button_Class(1,WebButton100);
  Set_Button_Class(1,WebButton101);
  Set_Button_Class(1,WebButton102);
  Set_Button_Class(1,WebButton106);
  Set_Button_Class(1,WebButton107);
  Set_Button_Class(1,WebButton108);
  Set_Button_Class(1,WebButton109);
  Set_Button_Class(1,WebButton110);
  Set_Button_Class(1,WebButton111);
  Set_Button_Class(1,WebButton115);
  Set_Button_Class(1,WebButton116);
  Set_Button_Class(1,WebButton117);
  Set_Button_Class(1,WebButton118);
  Set_Button_Class(1,WebButton119);
  Set_Button_Class(1,WebButton120);
  Set_Button_Class(1,WebButton121);
  Set_Button_Class(1,WebButton123);
  Set_Button_Class(1,WebButton126);
  Set_Button_Class(1,WebButton127);
  Set_Button_Class(1,WebButton128);
  Set_Button_Class(1,WebButton129);
  Set_Button_Class(1,WebButton130);
  Set_Button_Class(1,WebButton131);
  Set_Button_Class(1,WebButton132);
  Set_Button_Class(1,WebButton133);
  Set_Button_Class(1,WebButton134);
  Set_Button_Class(1,WebButton135);
  Set_Button_Class(1,WebButton136);
  Set_Button_Class(1,WebButton137);




  Set_Panel_Class(1,WebPanel6);
  Set_Panel_Class(1,WebPanel3);
  Set_Panel_Class(1,WebPanel2);
  Set_Panel_Class(1,WebPanel4);
  Set_Panel_Class(1,WebPanel10);
  Set_Panel_Class(1,WebPanel13);
  Set_Panel_Class(1,WebPanel14);
  Set_Panel_Class(1,WebPanel15);
  Set_Panel_Class(1,WebPanel31);
  Set_Panel_Class(1,WebPanel32);
  Set_Panel_Class(1,WebPanel33);
  Set_Panel_Class(1,WebPanel7);
  Set_Panel_Class(1,WebPanel8);
  Set_Panel_Class(1,WebPanel30);
  Set_Panel_Class(1,WebPanel11);
  Set_Panel_Class(1,WebPanel5);
  Set_Panel_Class(1,WebPanel12);
  Set_Panel_Class(1,WebPanel21);
  Set_Panel_Class(1,WebPanel22);
  Set_Panel_Class(1,WebPanel20);
  Set_Panel_Class(1,WebPanel23);
  Set_Panel_Class(1,WebPanel24);
  Set_Panel_Class(1,WebPanel25);
  Set_Panel_Class(1,WebPanel26);
  Set_Panel_Class(1,WebPanel27);
  Set_Panel_Class(1,WebPanel28);
  Set_Panel_Class(1,WebPanel29);
  Set_Panel_Class(1,WebPanel1);
  Set_Panel_Class(1,WebPanel9);
  Set_Panel_Class(1,WebPanel36);
  Set_Panel_Class(1,WebPanel34);
  Set_Panel_Class(1,WebPanel35);
  Set_Panel_Class(1,WebPanel37);
  Set_Panel_Class(1,WebPanel38);
  Set_Panel_Class(1,WebPanel39);
  Set_Panel_Class(1,WebPanel42);
  Set_Panel_Class(1,WebPanel43);
  Set_Panel_Class(1,WebPanel45);
  Set_Panel_Class(1,WebPanel44);
  Set_Panel_Class(1,WebPanel46);
  Set_Panel_Class(1,WebPanel47);
  Set_Panel_Class(1,WebPanel48);
  Set_Panel_Class(1,WebPanel49);
  Set_Panel_Class(1,WebPanel50);
  Set_Panel_Class(1,WebPanel52);
  Set_Panel_Class(1,WebPanel53);
  Set_Panel_Class(1,WebPanel54);
  Set_Panel_Class(1,WebPanel55);
  Set_Panel_Class(1,WebPanel56);
  Set_Panel_Class(1,WebPanel57);
  Set_Panel_Class(1,WebPanel58);




  Set_Label_Class(1,WebLabel3);
  Set_Label_Class(1,WebLabel4);
  Set_Label_Class(1,WebLabel5);
  Set_Label_Class(1,WebLabel6);
  Set_Label_Class(1,WebLabel7);
  Set_Label_Class(1,WebLabel24);
  Set_Label_Class(1,WebLabel25);
//  Set_Label_Class(1,WebLabel8);
//  Set_Label_Class(1,WebLabel10);
  Set_Label_Class(1,WebLabel29);
  Set_Label_Class(1,WebLabel30);
  Set_Label_Class(1,WebLabel31);
  Set_Label_Class(1,WebLabel32);
  Set_Label_Class(4,WebLabel72);
  Set_Label_Class(1,WebLabel83);
  Set_Label_Class(2,WebLabel84);
  Set_Label_Class(1,WebLabel1);
  Set_Label_Class(1,WebLabel2);
  Set_Label_Class(1,WebLabel78);
  Set_Label_Class(1,WebLabel77);
  Set_Label_Class(1,WebLabel80);
  Set_Label_Class(1,WebLabel81);
  Set_Label_Class(1,WebLabel82);
  Set_Label_Class(1,WebLabel79);
  Set_Label_Class(1,lbName);
  Set_Label_Class(1,lbAddress1);
  Set_Label_Class(1,lbAddress2);
  Set_Label_Class(1,lbCity);
  Set_Label_Class(1,lbPostalCode);
  Set_Label_Class(1,lbState);
  Set_Label_Class(1,WebLabel34);
  Set_Label_Class(3,WebLabel36);
  Set_Label_Class(1,WebLabel49);
  Set_Label_Class(1,WebLabel33);
  Set_Label_Class(1,WebLabel37);
  Set_Label_Class(1,WebLabel38);
  Set_Label_Class(1,WebLabel39);
  Set_Label_Class(1,WebLabel40);
  Set_Label_Class(1,WebLabel41);
  Set_Label_Class(1,WebLabel42);
  Set_Label_Class(3,WebLabel43);
  Set_Label_Class(3,WebLabel12);
  Set_Label_Class(1,WebLabel51);
  Set_Label_Class(1,WebLabel44);
  Set_Label_Class(1,WebLabel45);
  Set_Label_Class(3,WebLabel46);
  Set_Label_Class(3,WebLabel52);
  Set_Label_Class(1,WebLabel53);
  Set_Label_Class(1,WebLabel54);
  Set_Label_Class(1,WebLabel55);
  Set_Label_Class(1,WebLabel56);
  Set_Label_Class(1,WebLabel57);
  Set_Label_Class(1,WebLabel58);
  Set_Label_Class(1,WebLabel59);
  Set_Label_Class(3,WebLabel60);
  Set_Label_Class(1,WebLabel61);
  Set_Label_Class(1,WebLabel62);
  Set_Label_Class(1,WebLabel63);
  Set_Label_Class(1,WebLabel64);
  Set_Label_Class(1,WebLabel65);
  Set_Label_Class(2,WebLabel66);
  Set_Label_Class(3,WebLabel67);
  Set_Label_Class(1,WebLabel68);
  Set_Label_Class(2,WebLabel47);
  Set_Label_Class(2,WebLabel48);
  Set_Label_Class(3,WebLabel50);
  Set_Label_Class(1,WebLabel14);
  Set_Label_Class(1,WebLabel15);
  Set_Label_Class(1,WebLabel16);
  Set_Label_Class(1,WebLabel17);
  Set_Label_Class(1,WebLabel9);
  Set_Label_Class(1,WebLabel13);
//  Set_Label_Class(1,WebLabel28);
//  Set_Label_Class(1,WebLabel27);
//  Set_Label_Class(1,WebLabel26);
  Set_Label_Class(2,WebLabel22);
//  Set_Label_Class(1,WebLabel23);
  Set_Label_Class(1,WebLabel35);
  Set_Label_Class(2,WebLabel71);
  Set_Label_Class(3,WebLabel73);
  Set_Label_Class(3,WebLabel74);
  Set_Label_Class(3,WebLabel75);
  Set_Label_Class(3,WebLabel76);
  Set_Label_Class(1,WebLabel87);
  Set_Label_Class(1,WebLabel85);
  Set_Label_Class(1,WebLabel86);
  Set_Label_Class(1,WebLabel11);
  Set_Label_Class(1,WebLabel18);
  Set_Label_Class(1,WebLabel69);
  Set_Label_Class(1,WebLabel70);
  Set_Label_Class(1,WebLabel88);
  Set_Label_Class(1,WebLabel89);
  Set_Label_Class(1,WebLabel90);
  Set_Label_Class(3,WebLabel93);
  Set_Label_Class(1,WebLabel94);
  Set_Label_Class(1,WebLabel95);
  Set_Label_Class(2,WebLabel96);
  Set_Label_Class(2,WebLabel10);
  Set_Label_Class(2,WebLabel97);
  Set_Label_Class(2,WebLabel98);
  Set_Label_Class(2,WebLabel99);
  Set_Label_Class(3,WebLabel100);
  Set_Label_Class(1,WebLabel101);
  Set_Label_Class(1,WebLabel102);
  Set_Label_Class(1,WebLabel103);
  Set_Label_Class(1,WebLabel104);
  Set_Label_Class(1,WebLabel105);
  Set_Label_Class(3,WebLabel106);
  Set_Label_Class(3,WebLabel110);
  Set_Label_Class(1,WebLabel107);
  Set_Label_Class(1,WebLabel108);
  Set_Label_Class(1,WebLabel109);

  Set_Label_Class(1,WebLabel111);
  Set_Label_Class(1,WebLabel112);
  Set_Label_Class(1,WebLabel113);
  Set_Label_Class(1,WebLabel114);
  Set_Label_Class(1,WebLabel115);
  Set_Label_Class(1,WebLabel116);
  Set_Label_Class(1,WebLabel117);
  Set_Label_Class(1,WebLabel118);
  Set_Label_Class(3,WebLabel119);
  Set_Label_Class(3,WebLabel120);
  Set_Label_Class(3,WebLabel121);
  Set_Label_Class(3,WebLabel122);
  Set_Label_Class(2,WebLabel125);
  Set_Label_Class(2,WebLabel126);
  Set_Label_Class(3,WebLabel127);
  Set_Label_Class(3,WebLabel128);
  Set_Label_Class(2,WebLabel129);
  Set_Label_Class(3,WebLabel130);
  Set_Label_Class(3,WebLabel131);
  Set_Label_Class(2,WebLabel132);
  Set_Label_Class(2,WebLabel133);
  Set_Label_Class(2,WebLabel19);


  Set_Tabsheet_Class(1,WebPageControl8Sheet1);
  Set_Tabsheet_Class(1,WebPageControl8Sheet2);
  Set_Tabsheet_Class(1,WebPageControl1Sheet1);
  Set_Tabsheet_Class(1,WebPageControl1Sheet2);
  Set_Tabsheet_Class(1,WebPageControl2Sheet1);
  Set_Tabsheet_Class(1,WebPageControl2Sheet2);
  Set_Tabsheet_Class(1,WebPageControl2Sheet3);
  Set_Tabsheet_Class(1,WebPageControl2Sheet4);
  Set_Tabsheet_Class(1,WebPageControl2Sheet5);
  Set_Tabsheet_Class(1,WebPageControl1Sheet3);
  Set_Tabsheet_Class(1,WebPageControl6Sheet1);
  Set_Tabsheet_Class(1,WebPageControl6Sheet2);
  Set_Tabsheet_Class(1,WebPageControl6Sheet3);
  Set_Tabsheet_Class(1,WebPageControl1Sheet4);
  Set_Tabsheet_Class(1,WebPageControl7Sheet1);
  Set_Tabsheet_Class(1,WebPageControl7Sheet2);
  Set_Tabsheet_Class(1,WebPageControl7Sheet3);
  Set_Tabsheet_Class(1,WebPageControl1Sheet5);
  Set_Tabsheet_Class(1,WebPageControl1Sheet6);
  Set_Tabsheet_Class(1,WebPageControl4Sheet1);
  Set_Tabsheet_Class(1,WebPageControl4Sheet2);
  Set_Tabsheet_Class(1,WebPageControl4Sheet3);
  Set_Tabsheet_Class(1,WebPageControl4Sheet4);
  Set_Tabsheet_Class(1,WebPageControl4Sheet5);
  Set_Tabsheet_Class(1,WebPageControl4Sheet6);
  Set_Tabsheet_Class(1,WebPageControl5Sheet1);
  Set_Tabsheet_Class(1,WebPageControl5Sheet2);
  Set_Tabsheet_Class(1,WebPageControl5Sheet3);
  Set_Tabsheet_Class(1,WebPageControl4Sheet7);
  Set_Tabsheet_Class(1,WebPageControl1Sheet7);
  Set_Tabsheet_Class(1,WebPageControl1Sheet8);
  Set_Tabsheet_Class(1,WebPageControl1Sheet9);
  Set_Tabsheet_Class(1,WebPageControl1Sheet10);
  Set_Tabsheet_Class(1,WebPageControl1Sheet11);
  Set_Tabsheet_Class(1,WebPageControl1Sheet12);
  Set_Tabsheet_Class(1,WebPageControl1Sheet13);
  Set_Tabsheet_Class(1,WebPageControl1Sheet14);
  Set_Tabsheet_Class(1,WebPageControl3Sheet1);
  Set_Tabsheet_Class(1,WebPageControl3Sheet2);
  Set_Tabsheet_Class(1,WebPageControl3Sheet3);
  Set_Tabsheet_Class(1,WebPageControl3Sheet4);
  Set_Tabsheet_Class(1,WebPageControl1Sheet15);
  Set_Tabsheet_Class(1,WebPageControl1Sheet16);
  Set_Tabsheet_Class(1,WebPageControl1Sheet17);
  Set_Tabsheet_Class(1,WebPageControl1Sheet18);
  Set_Tabsheet_Class(1,WebPageControl1Sheet19);
  Set_Tabsheet_Class(1,WebPageControl1Sheet20);
  Set_Tabsheet_Class(1,WebPageControl1Sheet21);
  Set_Tabsheet_Class(1,WebPageControl1Sheet22);
  Set_Tabsheet_Class(1,WebPageControl1Sheet23);
  Set_Tabsheet_Class(1,WebPageControl9Sheet1);
  Set_Tabsheet_Class(1,WebPageControl9Sheet2);
  Set_Tabsheet_Class(1,WebPageControl9Sheet3);
  Set_Tabsheet_Class(1,WebPageControl2Sheet6);
  Set_Tabsheet_Class(1,WebPageControl1Sheet24);
  Set_Tabsheet_Class(1,WebPageControl1Sheet25);
  Set_Tabsheet_Class(1,WebPageControl1Sheet26);
  Set_Tabsheet_Class(1,WebPageControl1Sheet27);
  Set_Tabsheet_Class(1,WebPageControl1Sheet28);
  Set_Tabsheet_Class(1,WebPageControl1Sheet29);
  Set_Tabsheet_Class(1,WebPageControl1Sheet30);
  Set_Tabsheet_Class(1,WebPageControl8Sheet3);


  Set_Richedit_Class(1,WebRichEdit1);
  Set_Richedit_Class(1,WebRichEdit2);
  Set_Richedit_Class(1,WebRichEdit3);
  Set_Richedit_Class(1,WebRichEdit4);
  Set_Richedit_Class(1,WebRichEdit5);
  Set_Richedit_Class(1,WebRichEdit6);
  Set_Richedit_Class(1,WebRichEdit7);
  Set_Richedit_Class(1,WebRichEdit8);
  Set_Richedit_Class(1,WebRichEdit9);


  Set_Checkbox_Class(1,WebCheckbox1);
  Set_Checkbox_Class(1,WebCheckbox2);
  Set_Checkbox_Class(1,WebCheckbox3);


//  Set_Listbox_Class(1,WebListBox1);


//  Auth_URL := 'http://127.0.0.1:2003/kwshop/auth';


{  for I := 0 to ComponentCount do begin
    if Components[i] is TWebButton then begin
      TWebButton(Components[i]).ElementClassName := 'btn btn-primary';
      TWebButton(Components[i]).ElementFont := efCSS;

    end;
  end;
}
  WebButton106.Visible := false;

  Akt_Auswahl_Menu := 0;
  WebImageControl10.Visible := false;
  Chat_Live_Bild := '';
  Chat_Live_ID := 0;

  WebT1Cnt := 0;
  Init_Stufe := 0;
  WebTimer1.Enabled := false;
  Akt_Theken_ID := -1;
  Theken_Wahl := -1;
  Login_HM := false;
  Token_Gelesen := false;
  Chat_Verbunden := false;

  Token_Ausgewertet := false;
  WebPanel9.Visible := true;
  WebPageControl1.TabIndex := 4;
  WebPageControl1Change(Self);
//  My_Cookie := LS_GetMyToken(Shop_ID_Token);
  for i := 0 to 500 do Regionen[i] := nil;
  Zoom_Faktor_x := 1.0;
  Zoom_Faktor_y := 1.0;
  Akt_ScrollPos := 0;
  Ohne_Check_Kategorie := false;
  Akt_User_ID := 0;
  bt := 'btn btn-webcore';
  ed := 'form-control';
  lb := 'form-label';
  co := ed;
  Theken_Hint := '';

//  WebButton22.ElementClassName := bt;


  WebEdit3.Text := '';
  WebEdit4.Text := '';
  Akt_Modus := Add_Modus;


  WebComboBox10.Items.Clear;
  WebComboBox10.Items.Add('Wir empfehlen');
  WebComboBox10.Items.Add('Preis aufsteigend');
  WebComboBox10.Items.Add('Preis absteigend');
  WebComboBox10.Items.Add('Bewertung aufsteigend');
  WebComboBox10.Items.Add('Bewertung absteigend');

  WebComboBox10.ItemIndex := 0;

  AllergieListe := TStringList.Create;
  AllergieListe.LoadFromFile('Allergene.txt');
//  WebStringGrid1.RowCount := 0;
//  WebStringGrid1.ColCount := 2;

  for i := 0 to AllergieListe.Count - 1 do begin
    AllergieFilter[i] := false;
    hst :=AllergieListe[i];
    j := pos(';',hst);
    hst2 := Copy(hst,1,j-1);
    AllergenNo[i] := StrToInt(hst2);
    system.delete(hst,1,j);
//    WebStringGrid1.RowCount := WebStringGrid1.RowCount + 1;
    WebStringGrid1.Cells[1,i+1] := '';
    WebStringGrid1.Cells[2,i+1] := hst;
  end;
  WebStringGrid1.RowCount := AllergieListe.Count + 1;

  VeganListe := TStringList.Create;
  VeganListe.LoadFromFile('Vegan_etc.txt');
  for i := 0 to VeganListe.Count - 1 do begin
    VeganFilter[i] := false;
    hst :=VeganListe[i];
    j := pos(';',hst);
    hst2 := Copy(hst,1,j-1);
    VeganNo[i] := StrToInt(hst2);
    system.delete(hst,1,j);
    WebStringGrid2.Cells[1,i+1] := '';
    WebStringGrid2.Cells[2,i+1] := hst;
  end;
  WebStringGrid2.RowCount := VeganListe.Count + 1;

  LieferListe := TStringList.Create;
  LieferListe.LoadFromFile('Lieferfilter.txt');
  for i := 0 to LieferListe.Count - 1 do begin
    LieferFilter[i] := false;
    hst :=LieferListe[i];
    j := pos(';',hst);
    hst2 := Copy(hst,1,j-1);
    LieferArtNo[i] := StrToInt(hst2);
    system.delete(hst,1,j);
    WebStringGrid3.Cells[1,i+1] := '';
    WebStringGrid3.Cells[2,i+1] := hst;
  end;
  WebStringGrid3.RowCount := LieferListe.Count + 1;


  WebRichEdit1.Lines.Loadfromfile('Startseite3.html');
  WebRichEdit2.Lines.Loadfromfile('AGB.html');
  WebRichEdit3.Lines.Loadfromfile('Datenschutz.html');
  WebRichEdit4.Lines.Loadfromfile('impressum.html');
  WebRichEdit5.Lines.Loadfromfile('Kontakt.html');
  WebRichEdit6.Lines.Loadfromfile('Cookies.html');
  WebRichEdit7.Lines.Loadfromfile('Good_bye.html');

  WebImageControl13.Picture.LoadFromFile('Logo_Shop.png');

  WebImageControl1.Picture.LoadFromFile('Wursttheke.png');
  WebImageControl2.Picture.LoadFromFile('Fleischtheke.png');
  WebImageControl5.Picture.LoadFromFile('Kaesetheke.png');
  WebImageControl4.Picture.LoadFromFile('Imbisstheke.png');

  WK_Artikel := 0;
//  WebButton3.ElementClassName := bt;

  WebButton3.Caption := '<i class="material-icons">shopping_cart</i> <span class="badge badge-danger badge-xs">' + IntToStr(WK_Artikel) + ' Warenkorb '+'</span>';
  WebButton2.Caption := '<i class="material-icons">home</i> <span class="badge badge-danger badge-xs">' + 'Startseite' + '</span>';
  WebButton5.Caption := '<i class="material-icons">euro_symbol</i> <span class="badge badge-danger badge-xs">' + IntToStr(WK_Artikel) + ' Kasse</span>';
  WebButton6.Caption := '<i class="material-icons">apps</i> <span class="badge badge-danger badge-xs">' + 'Artikel' + '</span>';
  WebButton4.Caption := '<i class="material-icons">account_box</i> <span class="badge badge-danger badge-xs">' + 'Konto' + '</span>';
  WebButton11.Caption := '<i class="material-icons">logout</i> <span class="badge badge-danger badge-xs">' + 'Abmelden' + '</span>';
  WebButton10.Caption := '<i class="material-icons">arrow_back</i> <span class="badge badge-danger badge-xs">' + ' Zurück zu den <br>Artikel-Details' + '</span>';
  WebButton8.Caption := '<i class="material-icons">zoom_in</i> <span class="badge badge-danger badge-xs">' + '' + '</span>';
  WebButton1.Caption := '<i class="material-icons">shopping_cart</i> <span class="badge badge-danger badge-xs">'+ 'In den<br>Warenkorb'+'</span>';
  WebButton7.Caption := '<i class="material-icons">arrow_back</i> <span class="badge badge-danger badge-xs">'+ ' Zurück'+'</span>';
  WebButton17.Caption := '<i class="material-icons">panorama_photosphere</i> <span class="badge badge-danger badge-xs">'+ ' Theken'+'</span>';
//  WebButton22.Caption := '<i class="material-icons">receipt</i> <span class="badge badge-danger badge-xs">'+ 'Adresse'+'</span>';
//  WebButton23.Caption := '<i class="material-icons">local_shipping</i> <span class="badge badge-danger badge-xs">'+ 'Lieferadresse'+'</span>';
  WebButton24.Caption := '<i class="material-icons">arrow_back</i> <span class="badge badge-danger badge-xs">'+ 'Zurück'+'</span>';
  WebButton26.Caption := '<i class="material-icons">euro</i> <span class="badge badge-danger badge-xs">'+ 'Kostenpflichtig bestellen'+'</span>';
  WebButton39.Caption := '<i class="material-icons">visibility</i> <span class="badge badge-danger badge-xs">'+ ''+'</span>';
  WebButton34.Caption := '<i class="material-icons">arrow_back</i> <span class="badge badge-danger badge-xs">'+ 'Zurück'+'</span>';
  WebButton33.Caption := '<i class="material-icons">local_post_office</i> <span class="badge badge-danger badge-xs">'+ 'Kennwort<br>vergessen?'+'</span>';
  WebButton32.Caption := '<i class="material-icons">login</i> <span class="badge badge-danger badge-xs">'+ 'Anmelden'+'</span>';
  WebButton29.Caption := '<i class="material-icons">login</i> <span class="badge badge-danger badge-xs">'+ 'Bereits Kunde? --> Anmelden'+'</span>';
  WebButton30.Caption := '<i class="material-icons">add_to_queue</i> <span class="badge badge-danger badge-xs">'+ 'Als neuer Kunde registrieren'+'</span>';
  WebButton31.Caption := '<i class="material-icons">arrow_back</i> <span class="badge badge-danger badge-xs">'+ 'Zurück zum Shop'+'</span>';
  WebButton28.Caption := '<i class="material-icons">unsubscribe</i> <span class="badge badge-danger badge-xs">'+ 'Weiter ohne Anmeldung (Gast-Zugang)'+'</span>';

  WebButton40.Caption := '<i class="material-icons">arrow_forward</i> <span class="badge badge-danger badge-xs">'+ 'Weiter'+'</span>';
  WebButton41.Caption := '<i class="material-icons">arrow_back</i> <span class="badge badge-danger badge-xs">'+ 'Zurück'+'</span>';

  WebButton37.Caption := '<i class="material-icons">arrow_forward</i> <span class="badge badge-danger badge-xs">'+ 'Weiter'+'</span>';
  WebButton38.Caption := '<i class="material-icons">arrow_back</i> <span class="badge badge-danger badge-xs">'+ 'Zurück'+'</span>';

  WebButton35.Caption := '<i class="material-icons">arrow_forward</i> <span class="badge badge-danger badge-xs">'+ 'Weiter'+'</span>';
  WebButton36.Caption := '<i class="material-icons">arrow_back</i> <span class="badge badge-danger badge-xs">'+ 'Zurück'+'</span>';
  WebButton49.Caption := '<i class="material-icons">visibility_off</i> <span class="badge badge-danger badge-xs">'+ ''+'</span>';
  WebButton56.Caption := '<i class="material-icons">import_contacts</i> <span class="badge badge-danger badge-xs">'+ 'Prospekte'+'</span>';

  WebButton74.Caption := '<i class="material-icons">layers_clear</i> <span class="badge badge-danger badge-xs">'+ 'Allergie-Filter löschen'+'</span>';
  WebButton76.Caption := '<i class="material-icons">layers_clear</i> <span class="badge badge-danger badge-xs">'+ 'Ernährungs-Filter löschen'+'</span>';
  WebButton77.Caption := '<i class="material-icons">layers_clear</i> <span class="badge badge-danger badge-xs">'+ 'Liefer-Filter löschen'+'</span>';
  WebButton70.Caption := '<i class="material-icons">undo</i> <span class="badge badge-danger badge-xs">'+ 'Suchbegriff löschen'+'</span>';
  WebButton75.Caption := '<i class="material-icons">filter_alt</i> <span class="badge badge-danger badge-xs">'+ 'Suchbegriff ändern'+'</span>';
  WebButton69.Caption := '<i class="material-icons">select_all</i> <span class="badge badge-danger badge-xs">'+ 'Suche allen Artikeln'+'</span>';
  WebButton87.Caption := '<i class="material-icons">arrow_back</i> <span class="badge badge-danger badge-xs">'+ 'Zurück'+'</span>';
  WebButton90.Caption := '<i class="material-icons">chat</i> <span class="badge badge-danger badge-xs">'+ 'Chat'+'</span>';
  WebButton91.Caption := '<i class="material-icons">chat</i> <span class="badge badge-danger badge-xs">'+ 'Chat'+'</span>';
  WebButton93.Caption := '<i class="material-icons">chat</i> <span class="badge badge-danger badge-xs">'+ 'Chat'+'</span>';

  WebButton94.Caption := '<i class="material-icons">foundation</i> <span class="badge badge-danger badge-xs">'+ 'Grunddaten'+'</span>';
  WebButton95.Caption := '<i class="material-icons">description</i> <span class="badge badge-danger badge-xs">'+ 'Beschreibung<br>Zutatentext'+'</span>';
  WebButton96.Caption := '<i class="material-icons">fact_check</i> <span class="badge badge-danger badge-xs">'+ 'Nährwerte<br>Allergene'+'</span>';
  WebButton97.Caption := '<i class="material-icons">crop_original</i> <span class="badge badge-danger badge-xs">'+ 'Herkunft'+'</span>';
  WebButton98.Caption := '<i class="material-icons">score</i> <span class="badge badge-danger badge-xs">'+ 'Bewertungen'+'</span>';
  WebButton99.Caption := '<i class="material-icons">live_tv</i> <span class="badge badge-danger badge-xs">'+ 'Live-Bild'+'</span>';
  WebButton100.Caption := '<i class="material-icons">live_tv</i> <span class="badge badge-danger badge-xs">'+ 'Live-Bild anfordern'+'</span>';
  WebButton21.Caption := '<i class="material-icons">live_tv</i> <span class="badge badge-danger badge-xs">'+ 'Live-Bild'+'</span>';
  WebButton101.Caption := '<i class="material-icons">edit</i> <span class="badge badge-danger badge-xs">'+ ''+'</span>';
  WebButton102.Caption := '<i class="material-icons">delete</i> <span class="badge badge-danger badge-xs">'+ ''+'</span>';
  WebButton71.Caption := '<i class="material-icons">search</i> <span class="badge badge-danger badge-xs">'+ 'Filter<br>setzen'+'</span>';
  WebButton72.Caption := '<i class="material-icons">clear</i> <span class="badge badge-danger badge-xs">'+ 'Filter<br>löschen'+'</span>';
  WebButton73.Caption := '<i class="material-icons">arrow_back</i> <span class="badge badge-danger badge-xs">'+ 'Zurück'+'</span>';

  WebButton57.Caption := '<i class="material-icons">search</i> <span class="badge badge-danger badge-xs">'+ 'Filter<br> setzen'+'</span>';
  WebButton58.Caption := '<i class="material-icons">clear</i> <span class="badge badge-danger badge-xs">'+ 'Filter<br> löschen'+'</span>';
  WebButton59.Caption := '<i class="material-icons">arrow_back</i> <span class="badge badge-danger badge-xs">'+ 'Zurück'+'</span>';
  WebButton60.Caption := '<i class="material-icons">check_box</i> <span class="badge badge-danger badge-xs">'+ 'Aktuellen Eintrag aus- oder abwählen'+'</span>';

  WebButton62.Caption := '<i class="material-icons">search</i> <span class="badge badge-danger badge-xs">'+ 'Filter<br>setzen'+'</span>';
  WebButton63.Caption := '<i class="material-icons">clear</i> <span class="badge badge-danger badge-xs">'+ 'Filter<br>löschen'+'</span>';
  WebButton64.Caption := '<i class="material-icons">arrow_back</i> <span class="badge badge-danger badge-xs">'+ 'Zurück'+'</span>';
  WebButton61.Caption := '<i class="material-icons">check_box</i> <span class="badge badge-danger badge-xs">'+ 'Aktuellen Eintrag aus- oder abwählen'+'</span>';

  WebButton66.Caption := '<i class="material-icons">search</i> <span class="badge badge-danger badge-xs">'+ 'Filter<br>setzen'+'</span>';
  WebButton67.Caption := '<i class="material-icons">clear</i> <span class="badge badge-danger badge-xs">'+ 'Filter<br>löschen'+'</span>';
  WebButton68.Caption := '<i class="material-icons">arrow_back</i> <span class="badge badge-danger badge-xs">'+ 'Zurück'+'</span>';
  WebButton65.Caption := '<i class="material-icons">check_box</i> <span class="badge badge-danger badge-xs">'+ 'Aktuellen Eintrag aus- oder abwählen'+'</span>';

  WebButton103.Caption := '<i class="material-icons">check_box</i> <span class="badge badge-danger badge-xs">'+ ''+'</span>';
  WebButton104.Caption := '<i class="material-icons">check_box</i> <span class="badge badge-danger badge-xs">'+ ''+'</span>';
  WebButton105.Caption := '<i class="material-icons">check_box</i> <span class="badge badge-danger badge-xs">'+ ''+'</span>';
  WebButton106.Caption := '<i class="material-icons">table_rows</i> <span class="badge badge-danger badge-xs">'+ 'Best.-Listen'+'</span>';
  WebButton107.Caption := '<i class="material-icons">add_shopping_cart</i> <span class="badge badge-danger badge-xs">'+ 'Artikel in den <br>Warenkorb hinzufügen'+'</span>';
  WebButton108.Caption := '<i class="material-icons">delete_sweep</i> <span class="badge badge-danger badge-xs">'+ 'Alle Artikel aus<br>Warenkorb löschen'+'</span>';
  WebButton109.Caption := '<i class="material-icons">arrow_back</i> <span class="badge badge-danger badge-xs">'+ 'Zurück'+'</span>';
  WebButton110.Caption := '<i class="material-icons">arrow_back</i> <span class="badge badge-danger badge-xs">'+ 'Zurück'+'</span>';
  WebButton111.Caption := '<i class="material-icons">arrow_back</i> <span class="badge badge-danger badge-xs">'+ 'Zurück'+'</span>';
  WebButton115.Caption := '<i class="material-icons">arrow_back</i> <span class="badge badge-danger badge-xs">'+ 'Zurück'+'</span>';
  WebButton25.Caption := '<i class="material-icons">account_box</i> <span class="badge badge-danger badge-xs">'+ 'Meine Daten'+'</span>';
  WebButton53.Caption := '<i class="material-icons">all_inbox</i> <span class="badge badge-danger badge-xs">'+ 'Meine Bestellungen'+'</span>';
  WebButton54.Caption := '<i class="material-icons">receipt</i> <span class="badge badge-danger badge-xs">'+ 'Meine Rechnungen'+'</span>';
  WebButton116.Caption := '<i class="material-icons">save</i> <span class="badge badge-danger badge-xs">'+ 'Speichern'+'</span>';
  WebButton117.Caption := '<i class="material-icons">arrow_back</i> <span class="badge badge-danger badge-xs">'+ 'Zurück'+'</span>';
  WebButton118.Caption := '<i class="material-icons">save</i> <span class="badge badge-danger badge-xs">'+ 'Speichern'+'</span>';
  WebButton119.Caption := '<i class="material-icons">arrow_back</i> <span class="badge badge-danger badge-xs">'+ 'Zurück'+'</span>';
  WebButton122.Caption := '<i class="material-icons">visibility</i> <span class="badge badge-danger badge-xs">'+ ''+'</span>';
  WebButton124.Caption := '<i class="material-icons">visibility</i> <span class="badge badge-danger badge-xs">'+ ''+'</span>';
  WebButton125.Caption := '<i class="material-icons">visibility</i> <span class="badge badge-danger badge-xs">'+ ''+'</span>';
  WebButton120.Caption := '<i class="material-icons">save</i> <span class="badge badge-danger badge-xs">'+ 'Speichern'+'</span>';
  WebButton121.Caption := '<i class="material-icons">arrow_back</i> <span class="badge badge-danger badge-xs">'+ 'Zurück'+'</span>';
  WebButton123.Caption := '<i class="material-icons">mic</i> <span class="badge badge-danger badge-xs">'+ 'Aufnahme'+'</span>';

  WebButton126.Caption := '<i class="material-icons">arrow_back</i> <span class="badge badge-danger badge-xs">'+ 'Zurück'+'</span>';

  WebButton132.Caption := '<i class="material-icons">cancel</i> <span class="badge badge-danger badge-xs">'+ 'Bestellung abbrechen'+'</span>';
  WebButton133.Caption := '<i class="material-icons">cancel</i> <span class="badge badge-danger badge-xs">'+ 'Bestellung abbrechen'+'</span>';

  WebButton134.Caption := '<i class="material-icons">restaurant</i> <span class="badge badge-danger badge-xs">'+ 'Bestellung für Im-Haus-Verzehr'+'</span>';
  WebButton135.Caption := '<i class="material-icons">takeout_dining</i> <span class="badge badge-danger badge-xs">'+ 'Bestellung für Außer-Haus-Verzehr'+'</span>';
  WebButton136.Caption := '<i class="material-icons">receipt</i> <span class="badge badge-danger badge-xs">'+ 'Warte-Marke drucken'+'</span>';

{
  arrowbt1 := TJSHTMLElement(document.createElement('BUTTON'));
  arrowbt1.innerHTML := 'Rechnungsanschrift';
  arrowbt1.setAttribute('class', 'btn btn-webcore btn-arrow-right');
  arrowbt1.setAttribute('tabindex', '-1');
  WebPanel20.ElementHandle.appendChild(arrowbt1);

  arrowbt2 := TJSHTMLElement(document.createElement('BUTTON'));
  arrowbt2.innerHTML := 'Lieferanschrift';
  arrowbt2.setAttribute('class', 'btn btn-webcore btn-arrow-right btn-webcore-disabled');
  arrowbt2.setAttribute('tabindex', '-1');
  WebPanel20.ElementHandle.appendChild(arrowbt2);

  arrowbt3 := TJSHTMLElement(document.createElement('BUTTON'));
  arrowbt3.innerHTML := 'Zahlung';
  arrowbt3.setAttribute('class', 'btn btn-webcore btn-arrow-right btn-webcore-disabled');
  arrowbt3.setAttribute('tabindex', '-1');
  WebPanel20.ElementHandle.appendChild(arrowbt3);
}


  WebRadioGroup1.Items.Clear;
  ts := TStringList.Create;
  ts.LoadFromFile('Zahlmethoden.txt');
  for i := 0 to 10 do begin
    ZMA[i].Nummer := i + 1;
    ZMA[i].txt := '';
    ZMA[i].Gebuehr := 0.0;
    ZMA[i].Abholung := false;
  end;
  for i := 0 to ts.Count - 1 do begin
    hst := ts[i];
    j := pos(';',hst);
    ZMA[i].txt := Copy(hst,1,j-1);
    system.delete(hst,1,j);
    j := pos(';',hst);
    hst2 := Copy(hst,1,j-1);
    system.delete(hst,1,j);
    ZMA[i].Gebuehr := StrToFloat(hst2);
    ZMA[i].Abholung := hst = '1';
    if not equal(ZMA[i].Gebuehr,0.0) then begin
      hst2 := ZMA[i].txt + ' (+ '+Trim(RealStr(ZMA[i].Gebuehr,7,2,false)) + ' € Gebühr)';
    end
    else begin
      hst2 := ZMA[i].txt;
    end;

    WebRadioGroup1.Items.Add(hst2);
  end;
  ts.Free;
  WebRadioGroup1.ItemIndex := 0;

//  Init_Globvar;
  if Akt_Geraete_Art = GA_Handy then begin
    WebPanel9.Height := 100; // WebPanel9.Height * 2;
    WebButton3.Left := WebButton2.Left;
    WebButton3.Top := WebButton2.Top + WebButton2.Height + 5;

    WebButton5.Left := WebButton6.Left;
    WebButton5.Top := WebButton6.Top + WebButton6.Height + 5;
    WebButton4.Left := WebButton17.Left;
    WebButton4.Top := WebButton17.Top + WebButton17.Height + 5;
    WebButton11.Left := WebButton56.Left;
    WebButton11.Top := WebButton56.Top + WebButton56.Height + 5;


  end;

  AktFirmaNo := 10;
  for i := 0 to 99 do begin
    SKA[i] := 0;
    SKAF[i] := 0;
  end;

  Akt_Artikel_ID := 0;
  Akt_Artikel_RecsPage := 25;
  Akt_Artikel_Count := 0;
  Akt_Artikel_Seite := 1;

  Set_Grid_Templates;
{
  WebDBResponsiveGrid1.Options.ItemBorderColor := clLime;// Silver;
  WebDBResponsiveGrid1.Options.ItemColor := clWhite;// Silver;
  WebDBResponsiveGrid1.Options.ItemHoverColor := clYellow;// Silver;

  case Akt_Item_Art of
    0 : begin
          WebDBResponsiveGrid1.Options.ItemTemplate := '<font size="6" style="font-size: 14pt"><span><b>(%Bezeichnung%)</b></span></font></p>'
          + '<br><img class="imgitem" src="(%Bild%)">'
          +'<p><font size="3" style="font-size: 10pt"><span>(%Preis%)<span></span></font></p>';

          WebDBResponsiveGrid1.Options.ItemWidthMin := 250;

          WebDBResponsiveGrid1.Options.ItemHeight := 280;
        end;
    1 : begin
          WebDBResponsiveGrid1.Options.ItemTemplate := '<font size="6" style="font-size: 14pt"><span><b>(%Bezeichnung%)</b></span></font>'
          + '<br><img class="imgitem" src="(%Bild%)">'

          + '<br>(%Bewertung%)'
          +'<br><font size="3" style="font-size: 10pt"><span>(%Preis%)<span></span></font>';

         WebDBResponsiveGrid1.Options.ItemWidthMin := 250;

          WebDBResponsiveGrid1.Options.ItemHeight := 280;
        end;

  end;

}



  WebDBResponsiveGrid2.Options.ItemTemplate := '<img class="imgitem" src="(%Bild%)">';
  WebDBResponsiveGrid2.Options.ItemWidthMin := 66;
  WebDBResponsiveGrid2.Options.ItemHeight := 66;

  WebDBResponsiveGrid3.Options.ItemBorderColor := clSilver;

  WebDBResponsiveGrid3.Options.ItemTemplate :=
  '<br><button id="Del_btn(%lfdno%)">Löschen</button><span>             </span>'
  +'<button id="Edit_btn(%lfdno%)">Ändern</button>'

  + '<br><font size="6" style="font-size: 12pt"><span><b>(%Bezeichnung%)</b></span></font></p>'
  + '<br><img class="imgitem" src="(%Bild%)">'

  +'<p><font size="3" style="font-size: 10pt"><span>(%WKZ1%)</span></font>'
  +'<br><font size="3" style="font-size: 10pt"><span>(%WKZ2%)<span></span></font>'
  +'<br><font size="3" style="font-size: 10pt"><span>(%WKZ3%)<span></span></font></p>';

  WebDBResponsiveGrid3.Options.ItemWidthMin := 200;
  WebDBResponsiveGrid3.Options.ItemHeight := 380;

  InitHeader;


  Init_Shop;



  Start_Timer_Aktiv := true;

  StartTimer.Enabled := true;
//  WebTimer1.Enabled := true;

end;

procedure TForm2.WebFormShow(Sender: TObject);
begin
  Caption := AktPrgName;

end;

procedure TForm2.WebHttpRequest1Response(Sender: TObject; AResponse: string);
var
  js:TJSON;
  jo,
  jo2: TJSONObject;
  jv: TJSONValue;
  hst : String;
  ja : TJSONArray;
  i : integer;
  lb: TListControl;

begin
  hst := AResponse;
  WebRichEdit1.Lines.Text := hst;
  exit;
  js := TJSON.Create;
  jo := TJSONObject(JS.Parse(AResponse));
  ja := TJSONArray(jo.GetValue('value'));
//  if ja.Count > 0 then begin
    jo2 := TJSONArray(ja.Items[0]);
    hst := JO2.GetValue('SHOPSTARTMOBIL').ToString;
//  end;

  exit;


//  Akt_Section.Captionelement.classList.add('accordion_extra');
//  Akt_Section.Captionelement.style.setProperty('background-color','#E5413C');
//  Akt_Section.Panelelement.InnerHtml := '';
{  lb := TWebListControl.Create(Self);
  lb.Font.Size := 14;
  lb.ParentElement := Akt_Section.Panelelement;
  lb.HeightStyle := ssAuto;
  lb.WidthStyle := ssAuto;
  lb.Style := lsListGroup;
}
  if ja.Count = 0 then begin
//    lb.Items.Add.Text := 'zur Zeit keine Artikel';
    WebListBox1.Items.Add('zur Zeit keine Artikel');
    WebComboBox15.Items.Add('zur Zeit keine Artikel');

  end
  else begin
    for i := 0 to ja.Count - 1 do begin
      jo2 := TJSONArray(ja.Items[i]);
      hst := JO2.GetValue('BEZEICHNUNG').ToString;
      if hst <> '' then begin
        system.delete(hst,1,1);
        system.delete(hst,length(hst),1);
        WebListBox1.Items.Add(hst);
        WebComboBox15.Items.Add(hst);

//        lb.Items.Add.Text := hst;
//        WebMemo1.Lines.Add('Server->'+hst);
      end;
    end;
  end;

  exit;
//  jv := jo.GetValue('value');
//  Akt_Section.PanelElement.innerHTML := jv.Value;
end;

procedure TForm2.WebHttpRequest2Error(Sender: TObject;
  ARequest: TJSXMLHttpRequestRecord; Event: TJSEventRecord;
  var Handled: Boolean);
begin
  WebLabel123.Caption := 'Error';
end;

procedure TForm2.WebHttpRequest2RequestResponse(Sender: TObject;
  ARequest: TJSXMLHttpRequestRecord; AResponse: string);
begin
  WebLabel124.Caption := AResponse;

end;

procedure TForm2.WebHttpRequest2Response(Sender: TObject; AResponse: string);
var hst : String;
    i,j : integer;
begin
  WebLabel123.Caption := AResponse;
  hst := AResponse;
  i := pos('transcript',hst);
  if i > 0 then begin
    system.delete(hst,1,i-1);
    i := pos(':',hst);
    system.delete(hst,1,i);
    i := pos('"',hst);
    system.delete(hst,1,i);
    i := pos('"',hst);
    system.delete(hst,i,4096);

    WebEdit32.Text := hst;
    WebButton89Click(Self);

{    if Copy(hst,1,1) = '"' then begin
      system.delete(hst,1,1);
      hst := Trim(hst);
      if hst <> '' then begin
        WebEdit32.Text := hst;
        WebButton89Click(Self);
      end;
    end;
}
  end;
end;

procedure TForm2.WebHttpRequest3Response(Sender: TObject; AResponse: string);
var hst : String;
    i,j : integer;
begin
  hst := Trim(AResponse);
//  MessageDlg(hst, mtInformation, [], nil);
  i := pos('"audioContent":',hst);
  if i > 0 then begin
    system.delete(hst,1,i);
    i := pos(':',hst);
    system.delete(hst,1,i);
    hst := trim(hst);
    if Copy(hst,1,1) = '"' then system.delete(hst,1,1);
    i := pos('"',hst);
    system.delete(hst,i,4096);
//    MessageDlg(hst, mtInformation, [], nil);


    Vorles_Liste.Add(hst);
    WebTimer10.Enabled := true;
//    WebMultimediaPlayer1.URL := 'data:audio/MP3;base64,' + hst + '';
//    WebMultimediaPlayer1.Play;

  end;

end;

procedure TForm2.WebImageControl13DblClick(Sender: TObject);
begin
  WebLabel126.Visible := not WebLabel126.Visible;
  WebComboBox14.Visible := not WebComboBox14.Visible;
  exit;

  if WebPageControl8.TabIndex = 1 then begin
    MessageDlg('Sie sind bereits im Admin-Bereich', mtError, [], nil);
    exit;
  end;
  if Admin_Angemeldet then begin
    Save_Tab_Index := WebPageControl1.TabIndex;
    WebPageControl8.TabIndex := 1;
    WebPageControl8Change(Self);

  end
  else begin
    exit;
    Save_Tab_Index := WebPageControl1.TabIndex;
    WebPageControl1.TabIndex := 22;
    WebPageControl1Change(Self);
  end;

end;

procedure TForm2.WebImageControl1MouseDown(Sender: TObject;
  Button: TMouseButton; Shift: TShiftState; X, Y: Integer);
var hst : String;
begin
  Theken_Bild_Click(x,y);
  if Theken_Wahl <> -1 then begin
    Akt_Modus := Add_Modus_Theke;
    Refresh_ShopArtikel(Theken_Wahl);
  end;
{
      Datamodule1.VSHOPArtikelDataSet.Close;
      Datamodule1.VSHOPArtikelDataSet.AfterOpen := Zeige_Theken_Artikel;
      hst := '$filter=(ID eq '+IntToStr(Theken_Wahl)+ ')';
      Datamodule1.VSHOPArtikelDataSet.QueryString :=hst;
      Datamodule1.VSHOPArtikelDataSet.Load;
}


end;

procedure TForm2.WebImageControl1MouseMove(Sender: TObject; Shift: TShiftState;
  X, Y: Integer);
begin
  Mouse_Move_Thekenbild(x,y);
end;

procedure TForm2.WebImageControl2Click(Sender: TObject);
begin
  Akt_Artikel_Seite := Akt_Artikel_Seite + 1;
  WebTimer2.Enabled := true;

end;

procedure TForm2.WebImageControl2MouseDown(Sender: TObject;
  Button: TMouseButton; Shift: TShiftState; X, Y: Integer);
var hst : String;
begin
  Theken_Bild_Click(x,y);
  if Theken_Wahl <> -1 then begin
      Akt_Modus := Add_Modus_Theke;
      Refresh_ShopArtikel(Theken_Wahl);
  end;

  {
      Datamodule1.VSHOPArtikelDataSet.Close;
      Datamodule1.VSHOPArtikelDataSet.AfterOpen := Zeige_Theken_Artikel;
      hst := '$filter=(ID eq '+IntToStr(Theken_Wahl)+ ')';
      Datamodule1.VSHOPArtikelDataSet.QueryString :=hst;
      Datamodule1.VSHOPArtikelDataSet.Load;
}


end;

procedure TForm2.WebImageControl2MouseMove(Sender: TObject; Shift: TShiftState;
  X, Y: Integer);
begin
  Mouse_Move_Thekenbild(x,y);
end;

procedure TForm2.WebImageControl4Click(Sender: TObject);
begin
  Akt_Artikel_Seite := 1;
  WebTimer2.Enabled := true;

end;

procedure TForm2.WebImageControl4MouseDown(Sender: TObject;
  Button: TMouseButton; Shift: TShiftState; X, Y: Integer);
var hst : String;
begin
  Theken_Bild_Click(x,y);
  if Theken_Wahl <> -1 then begin
      Akt_Modus := Add_Modus_Theke;
      Refresh_ShopArtikel(Theken_Wahl);
  end;
{
      Datamodule1.VSHOPArtikelDataSet.Close;
      Datamodule1.VSHOPArtikelDataSet.AfterOpen := Zeige_Theken_Artikel;
      hst := '$filter=(ID eq '+IntToStr(Theken_Wahl)+ ')';
      Datamodule1.VSHOPArtikelDataSet.QueryString :=hst;
      Datamodule1.VSHOPArtikelDataSet.Load;
}


end;

procedure TForm2.WebImageControl4MouseMove(Sender: TObject; Shift: TShiftState;
  X, Y: Integer);
begin
  Mouse_Move_Thekenbild(x,y);
end;

procedure TForm2.WebImageControl5Click(Sender: TObject);
begin
  if Akt_Artikel_Seite > 1 then begin
    Akt_Artikel_Seite := Akt_Artikel_Seite - 1;
    WebTimer2.Enabled := true;
  end;
end;

procedure TForm2.WebImageControl5MouseDown(Sender: TObject;
  Button: TMouseButton; Shift: TShiftState; X, Y: Integer);
var hst : String;
begin
  Theken_Bild_Click(x,y);
  if Theken_Wahl <> -1 then begin
      Akt_Modus := Add_Modus_Theke;
      Refresh_ShopArtikel(Theken_Wahl);
  end;
{
      Datamodule1.VSHOPArtikelDataSet.Close;
      Datamodule1.VSHOPArtikelDataSet.AfterOpen := Zeige_Theken_Artikel;
      hst := '$filter=(ID eq '+IntToStr(Theken_Wahl)+ ')';
      Datamodule1.VSHOPArtikelDataSet.QueryString :=hst;
      Datamodule1.VSHOPArtikelDataSet.Load;

}

end;

procedure TForm2.WebImageControl5MouseMove(Sender: TObject; Shift: TShiftState;
  X, Y: Integer);
begin
  Mouse_Move_Thekenbild(x,y);
end;

procedure TForm2.WebImageControl7Click(Sender: TObject);
var i : integer;
begin
//  WebListBox2.ItemIndex := -1;
  for i := 0 to AllergieListe.Count - 1 do begin
    Save_AllergieFilter[i] := AllergieFilter[i];
  end;
  Save_Tab_Index := WebPageControl1.TabIndex;
  WebPageControl1.TabIndex := 18;
  WebPageControl1Change(Self);
end;

procedure TForm2.WebImageControl8Click(Sender: TObject);
var i : integer;
begin
  for i := 0 to VeganListe.Count - 1 do begin
    Save_VeganFilter[i] := VeganFilter[i];
  end;
  Save_Tab_Index := WebPageControl1.TabIndex;
  WebPageControl1.TabIndex := 19;
  WebPageControl1Change(Self);
end;

procedure TForm2.WebImageControl9Click(Sender: TObject);
var i : integer;
begin
  for i := 0 to LieferListe.Count - 1 do begin
    Save_LieferFilter[i] := LieferFilter[i];
  end;
  Save_Tab_Index := WebPageControl1.TabIndex;
  WebPageControl1.TabIndex := 20;
  WebPageControl1Change(Self);
end;

procedure TForm2.WebImageSlider2ImageClick(Sender: TObject;
  ImageIndex: Integer);
var
 iz: TImageZoomControl;
begin
{
  iz := TWebImageZoomControl.Create(Self);
  iz.Parent := Self; //WebResponsiveGridPanel1;
  iz.URL := WebImageSlider2.ImageUrls[ImageIndex];
  iz.URLZoom := WebImageSlider2.ImageUrls[ImageIndex]; // 'img/' + IntToStr(I) + '_1024.jpg';
  iz.Width := 128;
  iz.Height := 85;
  iz.Visible := true;
  iz.OnClick := WebImageZoomControl2Click;
  WebImageZoomControl2Click(iz);
}

end;

procedure TForm2.WebImageZoomControl2Click(Sender: TObject);
begin
  //
end;

procedure TForm2.WebLabel4Click(Sender: TObject);
begin
  Save_Tab_Index := WebPageControl1.TabIndex;
  WebButton2.Visible := false;
  WebButton6.Visible := false;
  WebButton3.Visible := false;
  WebButton5.Visible := false;
  WebButton4.Visible := false;
  WebButton56.Visible := false;
  WebButton90.Visible := false;
  WebButton106.Visible := false;

  WebButton11.Visible := false;
  WebButton17.Visible := false;
  WebButton10.Visible := true;
  WebButton10.Caption := '<i class="material-icons">arrow_back</i> <span class="badge badge-danger badge-xs">' + ' Zurück' + '</span>';
  Set_Button_Class(1,WebButton10);
  WebLabel99.Left := WebButton2.Left;
  WebLabel99.Caption := 'Allgemeine Geschäftsbedingungen';
  WebLabel99.Visible := true;
  WebButton10.Top := WebButton2.Top;
  WebButton10.Left := WebButton2.Left;

  WebPanel9.Visible := true;
  WebPageControl1.TabIndex := 8;
  WebPageControl1Change(Self);
end;

procedure TForm2.WebLabel5Click(Sender: TObject);
begin
  Save_Tab_Index := WebPageControl1.TabIndex;
  WebButton2.Visible := false;
  WebButton6.Visible := false;
  WebButton3.Visible := false;
  WebButton5.Visible := false;
  WebButton4.Visible := false;
  WebButton56.Visible := false;
  WebButton90.Visible := false;
  WebButton106.Visible := false;

  WebButton11.Visible := false;
  WebButton17.Visible := false;
  WebButton10.Visible := true;
  WebButton10.Caption := '<i class="material-icons">arrow_back</i> <span class="badge badge-danger badge-xs">' + ' Zurück' + '</span>';
  Set_Button_Class(1,WebButton10);

  WebButton10.Top := WebButton2.Top;
  WebButton10.Left := WebButton2.Left;

  WebPanel9.Visible := true;

  WebPageControl1.TabIndex := 9;
  WebPageControl1Change(Self);
end;

procedure TForm2.WebLabel6Click(Sender: TObject);
begin
  Save_Tab_Index := WebPageControl1.TabIndex;
  WebButton2.Visible := false;
  WebButton6.Visible := false;
  WebButton3.Visible := false;
  WebButton5.Visible := false;
  WebButton4.Visible := false;
  WebButton56.Visible := false;
  WebButton90.Visible := false;
  WebButton106.Visible := false;

  WebButton11.Visible := false;
  WebButton17.Visible := false;
  WebButton10.Visible := true;
  WebButton10.Caption := '<i class="material-icons">arrow_back</i> <span class="badge badge-danger badge-xs">' + ' Zurück' + '</span>';
  Set_Button_Class(1,WebButton10);

  WebButton10.Top := WebButton2.Top;
  WebButton10.Left := WebButton2.Left;

  WebPanel9.Visible := true;

  WebPageControl1.TabIndex := 11;
  WebPageControl1Change(Self);
end;

procedure TForm2.WebLabel7Click(Sender: TObject);
begin
  Save_Tab_Index := WebPageControl1.TabIndex;
  WebButton2.Visible := false;
  WebButton6.Visible := false;
  WebButton3.Visible := false;
  WebButton5.Visible := false;
  WebButton4.Visible := false;
  WebButton56.Visible := false;
  WebButton90.Visible := false;
  WebButton106.Visible := false;

  WebButton11.Visible := false;
  WebButton17.Visible := false;
  WebButton10.Visible := true;
  WebButton10.BeginUpdate;
  WebButton10.Caption := '<i class="material-icons">arrow_back</i> <span class="badge badge-danger badge-xs">' + ' Zurück' + '</span>';
  Set_Button_Class(1,WebButton10);
  WebButton10.EndUpdate;

  WebButton10.Top := WebButton2.Top;
  WebButton10.Left := WebButton2.Left;
  WebButton10.Top := WebButton2.Top;
  WebButton10.Left := WebButton2.Left;

  WebPanel9.Visible := true;

  WebPageControl1.TabIndex := 10;
  WebPageControl1Change(Self);
end;

procedure TForm2.WebListBox1Change(Sender: TObject);
begin

  Refresh_ShopArtikel(0);
end;

procedure TForm2.WebListBox2Click(Sender: TObject);
var i : integer;
    hst : String;
begin
  i := WebListBox2.ItemIndex;
  if i = 0 then begin
    Application.RemoveCSS('themecss')
  end
  else begin
    hst := WebListBox2.Items[i];
    Application.InsertCSS('themecss',hst+'/theme.css');
  end;
end;

procedure TForm2.WebListBox3Change(Sender: TObject);
begin
  if not Mit_Color_Change then exit;
  WebColorPicker1.Color := Save_Farben[WebListBox3.ItemIndex + 1];

end;

procedure TForm2.WebMediaCapture1StopCapture(Sender: TObject;
  ABinary: TJSUint8Array; ABase: string);
var hst : String;
begin
  hst := ABase;
  PlayRecording(hst);
end;

procedure TForm2.Refresh_ShopArtikel(ID : integer);
var hst,
    hst2,
    hst3,
    hst4 : String;
    AllergieKZ,
    VeganKZ,
    LieferKZ,
    Sortierung : integer;
    i,j: Integer;
    WGIndex : integer;
begin
  if not Kiosk_Modus then begin
    if WebListBox1.Items.Count > 0 then begin
      if WebListBox1.ItemIndex <> 0 then begin
        WGIndex := WGA[WebListBox1.ItemIndex];
      end
      else begin
        WGIndex := 0;
      end;
    end
    else begin
      WGIndex := 0;
    end;
  end
  else begin
    WGIndex := WGA[Akt_Kiosk_Index+1];
  end;
  i := WebComboBox3.ItemIndex;

  j := SKA[i];
  Akt_Kat := j;

  WebProgressbar1.Position := 0;
  WebPageControl7.TabIndex := 2;
  WebTimer4.Enabled := true;

  DataModule1.WebClientConnection5.Active := false;

  Datamodule1.VSHOPArtikelDataSet.Close;
  case Akt_Modus of
    Edit_Modus,
    Loesch_Modus : begin
                      AllergieKZ := 0;
                      VeganKZ := 0;
                      LieferKZ := 0;
                      hst := '0';
                      hst2 := '0';
                      Sortierung := 0;
                      if hst3 = '' then hst3 := ' ';
                      DataModule1.VSHOPArtikelDataSet.Close;
                      DataModule1.VSHOPArtikelDataSet.AfterOpen := Zeige_WK_Artikel;
                      DataModule1.WebClientConnection5.Active := false;
                      DataModule1.WebClientConnection5.URI := Auth_URL + 'LoginService/MyGetShopArtikel?P1='+IntToStr(ID)+'&P2='+IntToStr(AktFirmaNo)+'&P3='+My_Cookie+'&P4='+GetBeiwert
                        + '&P5='+IntToStr(AktKDID)+'&P6=0&P7=0&P8='+hst2+'&P9=0'
                        + '&P10='+hst+'&P11='+IntToStr(AllergieKZ)
                        + '&P12='+IntToStr(VeganKZ)+'&P13='+IntToStr(LieferKZ)
                        + '&P14='+hst3+'&P15='+IntToStr(Sortierung)
                        + '&P16='+IntToStr(Akt_Auswahl_Menu);

{                      DataModule1.WebClientConnection5.URI := Auth_URL + '/LoginService/MyGetShopArtikel?ID='+IntToStr(ID)+'&NLK='+IntToStr(AktFirmaNo)+'&Token='+My_Cookie+'&Beiwert='+GetBeiwert
                        + '&KUNDENID='+IntToStr(AktKDID)+'&KZ_PREIS=0&KZ_BRUTTO=0&KZWG='+hst2+'&VKS=0'
                        + '&FILTERART='+hst+'&ALLERGENFILTER='+IntToStr(AllergieKZ)
                        + '&ERNAEHRUNGFILTER='+IntToStr(VeganKZ)+'&LIEFERFILTER='+IntToStr(LieferKZ)
                        + '&MATCHCODE='+hst3+'&KZ_Sortierung=0';
}
                      DataModule1.WebClientConnection5.Active := true;
                      DataModule1.VSHOPArtikelDataSet.Open;

//                     hst := '$filter=(ID eq '+IntToStr(ID)+ ')';
//                     Datamodule1.VSHOPArtikelDataSet.QueryString :=hst;
//                     Datamodule1.VSHOPArtikelDataSet.AfterOpen := Zeige_WK_Artikel;
                   end;
    Add_Modus_Theke : begin
                        AllergieKZ := 0;
                        VeganKZ := 0;
                        LieferKZ := 0;
                        hst := '0';
                        hst2 := '0';
                        Sortierung := 0;
                        if hst3 = '' then hst3 := ' ';


                        DataModule1.VSHOPArtikelDataSet.Close;
                        DataModule1.VSHOPArtikelDataSet.AfterOpen := Zeige_Theken_Artikel;
                        DataModule1.WebClientConnection5.Active := false;
                        DataModule1.WebClientConnection5.URI := Auth_URL + 'LoginService/MyGetShopArtikel?P1='+IntToStr(ID)+'&P2='+IntToStr(AktFirmaNo)+'&P3='+My_Cookie+'&P4='+GetBeiwert
                        + '&P5='+IntToStr(AktKDID)+'&P6=0&P7=0&P8='+hst2+'&P9=0'
                        + '&P10='+hst+'&P11='+IntToStr(AllergieKZ)
                        + '&P12='+IntToStr(VeganKZ)+'&P13='+IntToStr(LieferKZ)
                        + '&P14='+hst3+'&P15='+IntToStr(Sortierung)
                        + '&P16='+IntToStr(Akt_Auswahl_Menu);

{                      DataModule1.WebClientConnection5.URI := Auth_URL + '/LoginService/MyGetShopArtikel?ID='+IntToStr(ID)+'&NLK='+IntToStr(AktFirmaNo)+'&Token='+My_Cookie+'&Beiwert='+GetBeiwert
                        + '&KUNDENID='+IntToStr(AktKDID)+'&KZ_PREIS=0&KZ_BRUTTO=0&KZWG='+hst2+'&VKS=0'
                        + '&FILTERART='+hst+'&ALLERGENFILTER='+IntToStr(AllergieKZ)
                        + '&ERNAEHRUNGFILTER='+IntToStr(VeganKZ)+'&LIEFERFILTER='+IntToStr(LieferKZ)
                        + '&MATCHCODE='+hst3+'&KZ_Sortierung=0';
}

                        DataModule1.WebClientConnection5.Active := true;
                        DataModule1.VSHOPArtikelDataSet.Open;

//                        hst := '$filter=(ID eq '+IntToStr(ID)+ ')';
//                        Datamodule1.VSHOPArtikelDataSet.QueryString :=hst;
//                        Datamodule1.VSHOPArtikelDataSet.AfterOpen := Zeige_Theken_Artikel;
                      end;
    else begin
            AllergieKZ := 0;
            for i := 0 to AllergieListe.Count - 1 do begin
              if AllergieFilter[i] then begin
                j := AllergenNo[i];
                SetBit(AllergieKZ,j);
              end;
            end;
            VeganKZ := 0;
            for i := 0 to VeganListe.Count - 1 do begin
              if VeganFilter[i] then begin
                j := VeganNo[i];
                SetBit(VeganKZ,j);
              end;
            end;
            LieferKZ := 0;
            for i := 0 to LieferListe.Count - 1 do begin
              if LieferFilter[i] then begin
                j := LieferArtNo[i];
                SetBit(LieferKZ,j);
              end;
            end;
            hst := IntToStr(Akt_Kat);
            hst2 := IntToStr(WGIndex);
            hst3 := WebEdit2.Text;
            if hst3 = '' then hst3 := ' ';
            Sortierung := WebComboBox10.ItemIndex;
            DataModule1.VSHOPArtikelDataSet.Close;
            DataModule1.VSHOPArtikelDataSet.AfterOpen := Zeige_Artikel;
            DataModule1.WebClientConnection5.Active := false;
            DataModule1.WebClientConnection5.URI := Auth_URL + 'LoginService/MyGetShopArtikel?P1='+IntToStr(ID)+'&P2='+IntToStr(AktFirmaNo)+'&P3='+My_Cookie+'&P4='+GetBeiwert
              + '&P5='+IntToStr(AktKDID)+'&P6=0&P7=0&P8='+hst2+'&P9=0'
              + '&P10='+hst+'&P11='+IntToStr(AllergieKZ)
              + '&P12='+IntToStr(VeganKZ)+'&P13='+IntToStr(LieferKZ)
              + '&P14='+hst3+'&P15='+IntToStr(Sortierung)
              + '&P16='+IntToStr(Akt_Auswahl_Menu);

            DataModule1.WebClientConnection5.Active := true;
            DataModule1.VSHOPArtikelDataSet.Open;
    end;
  end;
//  Application.ProcessMesages;

end;

procedure TForm2.WebPageControl1Change(Sender: TObject);
begin
  WebPanel36.Visible := WebPageControl1.TabIndex = 3;
  WebLabel99.Visible := false;
  WebButton10.Visible := false;
  WebPanel9.Height := 48;
  if Kiosk_Modus then begin
    WebPanel6.Visible := false;
  end;
  if Akt_Geraete_Art = GA_Handy then begin
    WebButton137.Visible := true;
    case WebPageControl1.TabIndex of
      0 : begin //Warenkorb
            WebPanel1.Visible := false;
            WebPanel6.Visible := false;

          end;
      1 : begin //Artikeldetails
            WebPanel1.Visible := false;
            WebPanel6.Visible := false;

          end;

      2 : begin // Konto
            WebPanel1.Visible := true;
            WebPanel6.Visible := true;

            WebButton2.Visible := false;
            WebButton6.Visible := false;
            WebButton3.Visible := false;
            WebButton5.Visible := false;
            WebButton4.Visible := false;
            WebButton56.Visible := false;
            WebButton90.Visible := false;
            WebButton11.Visible := false;
            WebButton17.Visible := false;
            WebButton106.Visible := false;

            WebPanel9.Visible := true;
            WebButton10.Caption := '<i class="material-icons">arrow_back</i> <span class="badge badge-danger badge-xs">' + ' Zurück' + '</span>';
            Set_Button_Class(1,WebButton10);

            WebPanel6.Visible := false;

            WebLabel99.Left := WebButton2.Left;
            WebLabel99.Caption := 'Konto bearbeiten';
            WebLabel99.Visible := true;
            WebPanel9.Height := 90;

            WebButton10.Top := 45;
            WebButton10.Left := WebButton25.Left;
            WebButton10.Visible := true;
            WebButton25.Width := WebButton10.Width;
            WebButton53.Width := WebButton10.Width;
            WebButton54.Width := WebButton10.Width;
            WebButton25.Height := WebButton10.Height;
            WebButton53.Height := WebButton10.Height;
            WebButton54.Height := WebButton10.Height;


          end;
      3 : begin //Artikelsuche
            WebPanel7.Visible := true;
            WebPanel36.Visible := false;
            WebPanel1.Visible := false;
            WebPanel5.Visible := false;
            WebPanel6.Visible := false;
            WebEdit2.Width := 100;
            WebButton101.Left := 110;
            WebButton102.Left := 150;
            WebComboBox10.Parent := WebPanel8;
            WebLabel77.Parent := WebPanel8;
            WebLabel77.Left := 190;
            WebComboBox10.Left := 190;
            WebLabel77.Top := WebLabel2.Top;
            WebComboBox10.Top := WebEdit2.Top;
            WebComboBox10.Width := 130;
            WebComboBox10.Height := WebEdit2.Height;
            WebComboBox15.Width := 160;

          end;
      4 : begin // Startseite
            WebPanel9.Visible := true;
            WebPanel1.Visible := true;
            WebPanel6.Visible := true;

          end;
      5 : begin // Kasse

          end;
      6 : begin // Bilder
            WebButton2.Visible := false;
            WebButton6.Visible := false;
            WebButton3.Visible := false;
            WebButton5.Visible := false;
            WebButton4.Visible := false;
            WebButton11.Visible := false;
            WebButton17.Visible := false;
            WebButton56.Visible := false;
            WebButton90.Visible := false;
            WebButton137.Visible := false;

            WebPanel9.Height := 50;
            if Akt_Geraete_Art = GA_Handy then begin
              WebPanel1.Height := 105;
            end
            else begin
              WebPanel1.Height := 105;

            end;

            WebPanel9.Visible := true;
            WebButton10.Visible := true;
            WebButton10.Left := WebButton2.Left;
            WebButton10.Top := WebButton2.Top;
            WebButton10.BeginUpdate;
            WebButton10.Caption :='<i class="material-icons">arrow_back</i> <span class="badge badge-danger badge-xs">' + ' Zurück zu den <br>Artikel-Details' + '</span>';
            Set_Button_Class(1,WebButton10);
            WebButton10.EndUpdate;

          end;
      7 : begin // Nicht gefubden

          end;
      8 : begin //AGB
            WebPanel6.Visible := false;

            WebLabel99.Left := WebButton2.Left;
            WebLabel99.Caption := 'Allgemeine Geschäftsbedingungen';
            WebLabel99.Visible := true;
            WebPanel9.Height := 90;

            WebButton10.Top := 45;
            WebButton10.Left := WebButton2.Left;
            WebButton10.Visible := true;

          end;
      9 : begin //Datenschutz
            WebPanel6.Visible := false;

            WebLabel99.Left := WebButton2.Left;
            WebLabel99.Caption := 'Datenschutz-Erkärung';
            WebLabel99.Visible := true;
            WebPanel9.Height := 90;

            WebButton10.Top := 45;
            WebButton10.Left := WebButton2.Left;
            WebButton10.Visible := true;

          end;
      10 : begin //Imporessum
            WebPanel6.Visible := false;

            WebLabel99.Left := WebButton2.Left;
            WebLabel99.Caption := 'Impressum';
            WebLabel99.Visible := true;
            WebPanel9.Height := 90;

            WebButton10.Top := 45;
            WebButton10.Left := WebButton2.Left;
            WebButton10.Visible := true;

          end;
      11 : begin //Kontakt
            WebPanel6.Visible := false;

            WebLabel99.Left := WebButton2.Left;
            WebLabel99.Caption := 'Kontaktformular';
            WebLabel99.Visible := true;
            WebPanel9.Height := 90;

            WebButton10.Top := 45;
            WebButton10.Left := WebButton2.Left;
            WebButton10.Visible := true;

          end;
      12 : begin // Login

          end;
      13 : begin //Theke
             WebPageControl3Change(Self);
          end;
      14 : begin //Cookies
            WebPanel9.Visible := false;
          end;
      15 : begin //

          end;
      16 : begin //

          end;

      17 : begin // Prospekte
             WebImageSlider2.ImageUrls.Clear;
             WebImageSlider2.ImageUrls.Add(Bilder_Url + 'prospekt/Seite1_2.png');
             WebImageSlider2.ImageUrls.Add(Bilder_Url + 'prospekt/Seite3_4.png');
             WebImageSlider2.RefreshImages;
             WebImageSlider2.ActiveImageIndex := 0;

           end;
      18 : begin // Allergiefilter

          end;
      19 : begin // Ernährungsfilter

          end;
      20 : begin // Lieferfilter

          end;

      21 : begin // Textfilter
              WebEditAutocomplete1.BeginUpdate;

              WebEditAutocomplete1.Items.StrictDelimiter := true;
              WebEditAutocomplete1.Items.Delimiter := ';';
              WebEditAutocomplete1.Items.DelimitedText := Wort_Liste;
              WebEditAutocomplete1.EndUpdate;

           end;
      22 : begin // Login Admin-Bereich
             if not Admin_Angemeldet then begin
               WebEdit26.Text := '';
             end;
           end;
      23 : begin // Bestell-Listen

          end;
      24 : begin // Email-Adresse ändern

          end;
      25 : begin // Telefonnummer ändern

          end;
      26 : begin // Kennwort ändern

          end;

      27 : begin // Kiosk Warengruppen
             WebPanel57.Visible := false;
             WebPanel6.Visible := false;

           end;
      28 : begin  // Meldungsfenster
             WebPanel6.Visible := false;
             WebPanel57.Visible := false;
           end;
      29 : begin // Start-Bildschirm Kiosk

          end;

    end;

  end
  else begin
    WebPanel1.Visible := true;
    WebPanel5.Visible := true;
    WebButton137.Visible := false;
    WebCombobox16.Visible := false;
    case WebPageControl1.TabIndex of
      0 : begin //Warenkorb

          end;
      1 : begin //Artikeldetails

          end;

      2 : begin // Konto
            WebButton2.Visible := false;
            WebButton6.Visible := false;
            WebButton3.Visible := false;
            WebButton5.Visible := false;
            WebButton4.Visible := false;
            WebButton56.Visible := false;
            WebButton90.Visible := false;
            WebButton11.Visible := false;
            WebButton17.Visible := false;
            WebButton106.Visible := false;

            WebPanel9.Visible := true;
            WebButton10.Caption := '<i class="material-icons">arrow_back</i> <span class="badge badge-danger badge-xs">' + ' Zurück' + '</span>';
            Set_Button_Class(1,WebButton10);

            WebPanel6.Visible := false;

            WebLabel99.Left := WebButton2.Left;
            WebLabel99.Caption := 'Konto bearbeiten';
            WebLabel99.Visible := true;
            WebPanel9.Height := 90;

            WebButton10.Top := 45;
            WebButton10.Left := WebButton25.Left;
            WebButton10.Visible := true;
            WebButton25.Width := WebButton10.Width;
            WebButton53.Width := WebButton10.Width;
            WebButton54.Width := WebButton10.Width;
            WebButton25.Height := WebButton10.Height;
            WebButton53.Height := WebButton10.Height;
            WebButton54.Height := WebButton10.Height;


          end;
      3 : begin //Artikelsuche
            if Kiosk_Modus then begin
              WebPanel7.Visible := false;
              WebPanel8.Visible := false;
              WebPanel30.Visible := false;

              WebPanel42.Visible := false;
              WebPanel36.Visible := false;

            end
            else begin
              WebPanel7.Visible := true;

            end;
            if Akt_Geraete_Art = GA_Handy then begin
              WebPanel36.Visible := false;
              WebPanel1.Visible := false;
              WebPanel5.Visible := false;
              WebPanel6.Visible := false;
            end;
          end;
      4 : begin // Startseite
            WebPanel9.Visible := true;
          end;
      5 : begin // Kasse

          end;
      6 : begin // Bilder

          end;
      7 : begin // Nicht gefubden

          end;
      8 : begin //AGB
            WebPanel6.Visible := false;

            WebLabel99.Left := WebButton2.Left;
            WebLabel99.Caption := 'Allgemeine Geschäftsbedingungen';
            WebLabel99.Visible := true;
            WebPanel9.Height := 90;

            WebButton10.Top := 45;
            WebButton10.Left := WebButton2.Left;
            WebButton10.Visible := true;

          end;
      9 : begin //Datenschutz
            WebPanel6.Visible := false;

            WebLabel99.Left := WebButton2.Left;
            WebLabel99.Caption := 'Datenschutz-Erkärung';
            WebLabel99.Visible := true;
            WebPanel9.Height := 90;

            WebButton10.Top := 45;
            WebButton10.Left := WebButton2.Left;
            WebButton10.Visible := true;

          end;
      10 : begin //Imporessum
            WebPanel6.Visible := false;

            WebLabel99.Left := WebButton2.Left;
            WebLabel99.Caption := 'Impressum';
            WebLabel99.Visible := true;
            WebPanel9.Height := 90;

            WebButton10.Top := 45;
            WebButton10.Left := WebButton2.Left;
            WebButton10.Visible := true;

          end;
      11 : begin //Kontakt
            WebPanel6.Visible := false;

            WebLabel99.Left := WebButton2.Left;
            WebLabel99.Caption := 'Kontaktformular';
            WebLabel99.Visible := true;
            WebPanel9.Height := 90;

            WebButton10.Top := 45;
            WebButton10.Left := WebButton2.Left;
            WebButton10.Visible := true;

          end;
      12 : begin // Login

          end;
      13 : begin //Theke
             WebPageControl3Change(Self);
          end;
      14 : begin //Cookies
            WebPanel9.Visible := false;
          end;
      15 : begin //

          end;
      16 : begin //

          end;

      17 : begin // Prospekte
             WebImageSlider2.ImageUrls.Clear;
             WebImageSlider2.ImageUrls.Add(Bilder_Url + 'prospekt/Seite1_2.png');
             WebImageSlider2.ImageUrls.Add(Bilder_Url + 'prospekt/Seite3_4.png');
             WebImageSlider2.RefreshImages;
             WebImageSlider2.ActiveImageIndex := 0;

           end;
      18 : begin // Allergiefilter

          end;
      19 : begin // Ernährungsfilter

          end;
      20 : begin // Lieferfilter

          end;

      21 : begin // Textfilter
              WebEditAutocomplete1.BeginUpdate;

              WebEditAutocomplete1.Items.StrictDelimiter := true;
              WebEditAutocomplete1.Items.Delimiter := ';';
              WebEditAutocomplete1.Items.DelimitedText := Wort_Liste;
              WebEditAutocomplete1.EndUpdate;

           end;
      22 : begin // Login Admin-Bereich
             if not Admin_Angemeldet then begin
               WebEdit26.Text := '';
             end;
           end;
      23 : begin // Bestell-Listen

          end;
      24 : begin // Email-Adresse ändern

          end;
      25 : begin // Telefonnummer ändern

          end;
      26 : begin // Kennwort ändern

          end;

      27 : begin // Kiosk Warengruppen
             WebPanel57.Visible := false;
             WebPanel6.Visible := false;

           end;
      28 : begin  // Meldungsfenster
             WebPanel6.Visible := false;
             WebPanel57.Visible := false;
           end;
      29 : begin // Start-Bildschirm Kiosk

          end;

    end;
  end;

end;

procedure TForm2.WebPageControl2Change(Sender: TObject);
var i : integer;
begin
  if WebPageControl2.TabIndex = 0 then begin
    WebPanel4.Visible := true;
    WebPanel15.Visible := true;

  end
  else if WebPageControl2.TabIndex = 1 then begin
    WebPanel4.Visible := false;
//    WebPanel15.Visible := false;

//    Datamodule1.LangtextDataSet.Close;
//    Datamodule1.LangtextDataSet.AfterOpen := Zeige_Langtext;
//    Datamodule1.LangtextDataSet.QueryString :='$filter=ID eq '+IntToStr(Akt_Artikel_ID);
//    Datamodule1.LangtextDataSet.Load;
  end
  else if WebPageControl2.TabIndex = 4 then begin
    WebPanel4.Visible := false;
//    WebPanel15.Visible := false;
    WebComboBox11.ItemIndex := 0;
    WebDBGrid2.Datasource := nil;
    Datamodule1.BewertungDataSet.Close;
    Datamodule1.BewertungDataSet.AfterOpen := Zeige_Bewertungen;
    Datamodule1.BewertungDataSet.QueryString :='$filter=ID eq '+IntToStr(Akt_Artikel_ID)+'&$orderby=lfdno desc';
    Datamodule1.BewertungDataSet.Load;
  end
  else if WebPageControl2.TabIndex = 5 then begin

    WebImageControl10.Visible := false;
    if Chat_Verbunden then begin
      if Chat_Live_ID = Akt_Artikel_ID then begin
        WebImageControl10.Picture.LoadFromFile(Chat_Live_Bild);
        WebImageControl10.Visible := true;
      end
    end;
  end;
end;

procedure TForm2.Zeige_Langtext(DataSet: TDataSet);
var hst,hst2 : String;
    xhr : TJSXMLHttpRequest;

  procedure _Load;
  begin
    hst2 := xhr.responseText;
  end;


begin
  hst := // Datamodule1.LangtextDataSet.Fieldbyname('TXT').AsString;
    string(TJSObject(DataModule1.LangtextDataSet.CurrentData)['TXT@xdata.proxy']);

  xhr := TJSXMLHttpRequest.new;
  xhr.open('GET',
    DataModule1.XDataWebConnection1.URL + '/' + hst);
//    string(TJSObject(DataModule1.LangtextDataSet.CurrentData)['TXT@xdata.proxy'])
//  );
  xhr.overrideMimeType('text/plain; charset=utf-16');
  xhr.addEventListener('load', @_Load);
  xhr.send;

  WebRichedit8.Lines.Text := hst2;
end;



procedure TForm2.WebPageControl3Change(Sender: TObject);
var i,j,k,v,b,id : integer;
    ts : TStringList;
    hst,
    hst2 : String;
    l : TList;
    p : PReg_Rec;
begin
  Loesche_Theken_Regionen;
  WebLabel23.Visible := false;
  WebLabel28.Visible := false;
  WebLabel26.Visible := false;
  WebLabel27.Visible := false;

  WebImageControl4.Width := Round(1414*0.9);
  WebImageControl4.Height := Round(420*0.9);
  WebImageControl4.Left := 10;
  WebImageControl4.Top := 10;

  WebImageControl1.Width := Round(1414*0.9);
  WebImageControl1.Height := Round(420*0.9);
  WebImageControl1.Left := 10;
  WebImageControl1.Top := 10;

  WebImageControl2.Width := Round(1414*0.9);
  WebImageControl2.Height := Round(420*0.9);
  WebImageControl2.Left := 10;
  WebImageControl2.Top := 10;

  WebImageControl5.Width := Round(1414*0.9);
  WebImageControl5.Height := Round(420*0.9);
  WebImageControl5.Left := 10;
  WebImageControl5.Top := 10;


  ts := TStringList.Create;
  case WebPageControl3.TabIndex of
    0 : ts.LoadFromFile('TBK_Wurst.txt');
    1 : ts.LoadFromFile('TBK_Fleisch.txt');

  end;
//  ts.LoadFromFile('TBK_12.txt');
  for i := 0 to ts.Count - 1 do begin
    hst := Trim(ts[i]);
    if hst <> '' then begin
      j := pos(';',hst);
      hst2 := Copy(hst,1,j-1);
      k := StrToInt(hst2);
      system.Delete(hst,1,j);
      l := TList.Create;
      Regionen[k] := l;
      repeat
        j := pos(';',hst);
        hst2 := Copy(hst,1,j-1);
        system.Delete(hst,1,j);
        v := StrToInt(hst2);
        j := pos(';',hst);
        hst2 := Copy(hst,1,j-1);
        system.Delete(hst,1,j);
        b := StrToInt(hst2);
        j := pos(';',hst);
        hst2 := Copy(hst,1,j-1);
        system.Delete(hst,1,j);
        id := StrToInt(hst2);
        new(p);
        p^.v := v;
        p^.b := b;
        p^.ID := id;
        l.Add(p);
      until hst='';
    end;
  end;
  ts.Free;
end;

procedure TForm2.WebPageControl4Change(Sender: TObject);
begin
  if In_Konto_Menu then begin
    WebButton37.Caption := '<i class="material-icons">save</i> <span class="badge badge-danger badge-xs">'+ 'Speichern'+'</span>';
    WebButton35.Caption := '<i class="material-icons">save</i> <span class="badge badge-danger badge-xs">'+ 'Speichern'+'</span>';
    WebLabel49.Visible := false;
    WebComboBox7.Visible := false;

  end
  else begin
    Check_Angemeldet;
    WebButton37.Caption := '<i class="material-icons">arrow_forward</i> <span class="badge badge-danger badge-xs">'+ 'Weiter'+'</span>';
    WebButton35.Caption := '<i class="material-icons">arrow_forward</i> <span class="badge badge-danger badge-xs">'+ 'Weiter'+'</span>';
    WebLabel49.Visible := true;
    WebComboBox7.Visible := true;


  end;
  if WebPageControl4.TabIndex = 6 then begin
  end;

end;

procedure TForm2.WebPageControl7Change(Sender: TObject);
var i : integer;
begin
  if WebPageControl7.TabIndex = 1 then begin
    if Akt_Auswahl_Menu <> 0 then begin
      MessageDlg('Keine Artikel gefunden unter "'+WebLabel93.Caption+'"', mtError, [], nil);
      WebButton87Click(Self);
      exit;
    end
    else begin
      WebButton70.Enabled := Trim(WebEdit2.Text) <> '';
      WebButton75.Enabled := Trim(WebEdit2.Text) <> '';
      WebButton74.Enabled := WebCheckBox1.Checked;
      WebButton76.Enabled := WebCheckBox2.Checked;
      WebButton77.Enabled :=  WebCheckBox3.Checked;
      i := WebComboBox3.ItemIndex;
      WebButton69.Enabled := SKA[i] <> 2;
    end;
  end;

end;

procedure TForm2.WebPageControl8Change(Sender: TObject);
var i : integer;
begin
  if WebPageControl8.TabIndex = 0 then begin
    WebButton2.Enabled := true;
    WebButton6.Enabled := true;
    WebButton17.Enabled := true;
    WebButton56.Enabled := true;
    WebButton4.Enabled := true;
    WebButton3.Enabled := true;
    WebButton5.Enabled := true;
    WebButton11.Enabled := true;
  end
  else begin
    WebButton2.Enabled := false;
    WebButton6.Enabled := false;
    WebButton17.Enabled := false;
    WebButton56.Enabled := false;
    WebButton4.Enabled := false;
    WebButton3.Enabled := false;
    WebButton5.Enabled := false;
    WebButton11.Enabled := false;
    WebListBox2.ItemIndex := Akt_Theme;
    WebRadioGroup2.ItemIndex := Akt_ArtikelWahl;
    for i := 1 to 6 do Save_Farben[i] := Karten_Farben[i];

    for i := 1 to 6 do Save_KG[i] := Karten_Groesse[i];
    WebEdit27.Text := IntToStr(Save_KG[1]);
    WebEdit28.Text := IntToStr(Save_KG[2]);
    WebEdit29.Text := IntToStr(Save_KG[3]);
    Mit_Color_Change := true;
    WebListBox3Change(Self);

  end;
end;

procedure TForm2.WebPageControl9Change(Sender: TObject);
var tn : TTreeNode;
    m : PMenu_Rec;
begin
  WebButton81.Visible := true;
  if WebPageControl9.TabIndex = 3 then begin
    WebButton81.Visible := false;
{
    Menu_Root_ID := 13;
    webtreeview1.BeginUpdate;
    webtreeview1.Items.Clear;
    New(m);
    m^.ID := 0;
    m^.Level := 0;
    m^.PageID := 0;
    m^.Bez := 'Hauptmenü';
    HMenu_Liste.Add(m);
    Menu_Root := webtreeview1.Items.Add(nil,'<font color="#E5413C" style="font-size:10pt">&#x25FC;</font>'+'Hauptmenü');
    Menu_Root.Tag := Menu_Root_ID;
    webtreeview1.EndUpdate;
}

  end;
end;

procedure TForm2.Loesche_Theken_Regionen;
var i,j : integer;
begin
  for i := 0 to 500 do begin
    if Regionen[i] <> nil then begin
      for j := 0 to Regionen[i].Count - 1 do begin
        dispose(PReg_Rec(Regionen[i][j]));
      end;
      Regionen[i].Clear;
      Regionen[i].Free;
      Regionen[i] := nil;
    end;
  end;
end;

procedure TForm2.WebTimer10Timer(Sender: TObject);
var hst : String;
begin
  WebTimer10.Enabled := false;
  if Vorles_Liste.Count > 0 then begin
    hst := Vorles_liste[0];
    Vorles_Liste.Delete(0);
    WebMultimediaPlayer1.URL := 'data:audio/MP3;base64,' + hst + '';
    WebMultimediaPlayer1.Play;
  end;
  if Vorles_Liste.Count > 0 then begin
    WebTimer10.Enabled := true;
  end;

end;

procedure TForm2.WebTimer11Timer(Sender: TObject);
begin
  WebTimer11.Enabled := false;
  if Frage_Ergebnis <> -1 then begin
//    WebPanel57.Visible := true;
    exit;
  end;
  WebTimer11.Enabled := true;
end;

procedure TForm2.WebTimer12Timer(Sender: TObject);
begin
  WebTimer12.Enabled := false;
  WebPanel9.Visible := false;
  WebTimer12Cnt := WebTimer12Cnt + 10;
  if WebTimer12Cnt > 90 then begin
    WebTimer12Cnt := 0;
  end;
  WebProgressBar2.Position := WebTimer12Cnt;
  WebTimer12.Enabled := true;
end;

procedure TForm2.WebTimer1Timer(Sender: TObject);
var i,j,k,Farbe : integer;
    hst,hst2,hst3 : String;
    pl : PLaender_Rec;
begin

  WebTimer1.Enabled := false;
  WebT1Cnt := WebT1Cnt + 1;
//  WebLabel11.Caption := IntToStr(WebT1Cnt)+'/'+IntToStr(Init_Stufe);
  if not Token_Gelesen then begin
    WebTimer1.Enabled := true;
    exit;
  end;
{  if Auth_Url = '' then begin
    WebTimer1.Enabled := true;
    exit;
  end;
}
  if Shop_Start_OK then begin
    if not Token_Ausgewertet then begin
      WebPanel9.Visible := true;
//      WebLabel69.Caption := My_Cookie;
      if My_Cookie = '' then begin
//        WebLabel69.Caption := 'leer';
        WebPanel9.Visible := false;
        WebPageControl1.TabIndex := 14;
        WebPageControl1Change(Self);
      end
      else begin
//        WebLabel69.Caption := My_Cookie;
        Check_Get_WK_ID;
      end;
      Check_Angemeldet;
      Token_Ausgewertet := true;

    end;


    Update_Buttons;

    WebComboBox1.Items.Clear;
    WebComboBox2.Items.Clear;
    WebComboBox1.Items.Add('Bitte auswählen');
    WebComboBox2.Items.Add('Bitte auswählen');
    WebComboBox8.Items.Add('Bitte auswählen');

    if Laender_Liste.Count = 0 then begin
      new(PL);
      pl^.ID := 1;
      pl^.BEZEICHNUNG := 'Deutschland';
      pl^.Kuerzel := 'D';
      Laender_Liste.Add(pl);
    end;

    for i := 0 to Laender_Liste.Count - 1 do begin
      WebComboBox1.Items.Add(PLaender_Rec(Laender_Liste[i])^.Bezeichnung);
      WebComboBox2.Items.Add(PLaender_Rec(Laender_Liste[i])^.Bezeichnung);
      WebComboBox8.Items.Add(PLaender_Rec(Laender_Liste[i])^.Bezeichnung);

    end;
    WebComboBox1.ItemIndex := 0;
    WebComboBox2.ItemIndex := 0;
    WebComboBox8.ItemIndex := 0;

    if Laender_Liste.Count = 0 then begin
      WebComboBox1.ItemIndex := 1;
      WebComboBox2.ItemIndex := 1;

      WebComboBox1.Enabled := false;
      WebComboBox2.Enabled := false;
    end;


    WebComboBox3.Items.Clear;
    for i := 0 to Shop_Kategorien.Count - 1 do begin
      hst := Shop_Kategorien[i];
      j := pos(';',hst);
      hst2 := Copy(hst,1,j-1);
      SKA[i] := StrToInt(hst2);
      system.delete(hst,1,j);
      if Copy(hst,1,1) = '0' then begin
        j := pos(';',hst);
        system.delete(hst,1,j);
        j := pos(';',hst);
        hst2 := Copy(hst,1,j-1);
        system.delete(hst,1,j);
        SKAF[i] := StrToInt(hst);
  //      webaccordion1.Sections.Add.Caption := hst2;
        WebComboBox3.Items.Add(hst2);
      end;
    end;
    WebComboBox3.ItemIndex := 1;
//    WebComboBox3Change(Self);

    WebDBResponsiveGrid1.Datasource := Datamodule1.VSHOPArtikelDataSource;

    Set_Display_Options;


    exit;

  end;
  WebTimer1.Enabled := true;
  exit;





end;

procedure TForm2.WebTimer2Timer(Sender: TObject);
var i,j : integer;
begin
  WebTimer2.Enabled := false;
  j := -1;
  for i := 0 to Shop_Kategorien.Count - 1 do begin
    if SKA[i] = Akt_Kat then j := i;
  end;
  if j <> -1 then begin
    Ohne_Check_Kategorie := true;
    WebComboBox3.ItemIndex := j;
  end;

  exit;

end;

procedure TForm2.WebTimer3Timer(Sender: TObject);
begin
  WebTimer3.Enabled := false;
  exit;
  MessageDlg('Keine Verbindung zum Live-Server', mtError, [], nil);

end;

procedure TForm2.WebTimer4Timer(Sender: TObject);
var i : integer;
begin
  WebTimer4.Enabled := false;
  i := WebProgressbar1.Position;
  if i > 50 then i := 0
  else i := i + 1;
  WebProgressbar1.Position := i;
  WebTimer4.Enabled := true;

end;

procedure TForm2.WebTimer5Timer(Sender: TObject);
begin
  WebTimer5.Enabled := false;
  if Auth_Url = '' then begin
    WebTimer5.Enabled := true;
    exit;
  end;
  Set_Display_Options;

end;

procedure TForm2.WebTimer6Timer(Sender: TObject);
begin
  WebTimer6.Enabled := false;
  if not Bin_im_Menu then begin
    MB_Panel.Visible := false;
  end;
end;

procedure TForm2.WebTimer7Timer(Sender: TObject);
var i,i2,i3,j,k,k2,ID : integer;
    tn,tn2 : TTreeNode;
    ts,
    ts2 : TStringList;
    L : TLabel;
    ml : PML_Rec;
    TMA : array[0 .. 20] of integer;
    MW,MW2 : integer;
    SP_Dif,
    Spalten : integer;
    MB_ID : Array[0 .. 100] of integer;
begin
  WebTimer7.Enabled := false;
  MW := 200;
//  MB_Panel.Caption := IntToStr(Akt_Menu);
  Loesche_Menu_Labels;
  for i := 0 to 20 do begin
    TMA[i] := 0;
  end;
  Spalten := 0;
  j := -1;
  i2 := 10;
  ts := TStringList.Create;
  ts2 := TStringList.Create;

  for i := 0 to HMenu_Liste.Count - 1 do begin
    if PMenu_Rec(HMenu_Liste[i])^.Parent = Akt_Menu then begin
      ts.Add(PMenu_Rec(HMenu_Liste[i])^.Bez);
      TMA[ts.Count-1] := PMenu_Rec(HMenu_Liste[i])^.ID;
    end;
  end;
  MW2 := 10;
  for i := 0 to ts.Count - 1 do begin
    L := TLabel.Create(self);
    L.Name := 'MenuLabel_'+IntToStr(i);
    L.Parent := MB_Panel;
    L.Caption := ts[i];// + '('+IntToStr(TMA[i])+')';
    L.Top := 10;
    L.Left := MW2;
    L.Width := MW;
    L.ElementClassName := 'h5';
    L.ElementFont := efCSS;
    L.ElementLabelClassName := 'badge badge-primary';
//    MW2 := MW2 + L.Width + 10;
    new(ml);
    ml^.L := L;
    Menu_Labels.Add(ml);
    ts2.Clear;
    for j := 0 to HMenu_Liste.Count - 1 do begin
      k := TMA[i];
      k2 := PMenu_Rec(HMenu_Liste[j])^.Parent;
      if k2 = k then begin
        ts2.Add(PMenu_Rec(HMenu_Liste[j])^.Bez);
        MB_ID[ts2.Count-1] := PMenu_Rec(HMenu_Liste[j])^.ID;
      end;
    end;
    if ts2.Count <= 7 then begin
      i3 := 40;
      for j := 0 to ts2.Count - 1 do begin
        L := TLabel.Create(self);
        L.Name := 'MenuLabel_'+IntToStr(i)+'_'+IntToStr(j);
        L.Parent := MB_Panel;
        L.Caption := ts2[j];// + ' ('+IntToStr(MB_ID[j])+')';
        L.Top := i3;
        L.Left := MW2;
        L.Width := MW;
        L.ElementClassName := 'btn btn-secondary';
        L.ElementFont := efCSS;
        L.ElementLabelClassName := 'badge badge-secondary';
        L.OnClick := MB_Click;
        L.Tag := MB_ID[j];
  //      i2 := i2 + L.Width + 10;
        new(ml);
        ml^.L := L;
        Menu_Labels.Add(ml);
        i3 := i3 + L.Height + 40;
        Spalten := Spalten + 1;
      end;
      MW2 := MW2 + MW + 10;
    end
    else begin
      i3 := 40;
      for j := 0 to 6 do begin
        L := TLabel.Create(self);
        L.Name := 'MenuLabel_'+IntToStr(i)+'_'+IntToStr(j);
        L.Parent := MB_Panel;
        L.Caption := ts2[j];// + ' ('+IntToStr(MB_ID[j])+')';
        L.Top := i3;
        L.Left := MW2;
        L.Width := MW;
        L.ElementClassName := 'btn btn-secondary';
        L.ElementFont := efCSS;
        L.ElementLabelClassName := 'badge badge-secondary';
        L.OnClick := MB_Click;
        L.Tag := MB_ID[j];

  //      i2 := i2 + L.Width + 10;
        new(ml);
        ml^.L := L;
        Menu_Labels.Add(ml);
        i3 := i3 + L.Height + 40;
      end;
      MW2 := MW2 + MW + 10;
      i3 := 40;
      for j := 7 to ts2.Count - 1 do begin
        L := TLabel.Create(self);
        L.Name := 'MenuLabel_'+IntToStr(i)+'_'+IntToStr(j);
        L.Parent := MB_Panel;
        L.Caption := ts2[j];// + ' ('+IntToStr(MB_ID[j])+')';
        L.Top := i3;
        L.Left := MW2;
        L.Width := MW;
        L.ElementClassName := 'btn btn-secondary';
        L.ElementFont := efCSS;
        L.ElementLabelClassName := 'badge badge-secondary';
        L.OnClick := MB_Click;
        L.Tag := MB_ID[j];

  //      i2 := i2 + L.Width + 10;
        new(ml);
        ml^.L := L;
        Menu_Labels.Add(ml);
        i3 := i3 + L.Height + 40;
      end;
      MW2 := MW2 + MW + 10;
      Spalten := Spalten + 2;

    end;
  end;
  if Spalten > 0 then begin
    case Spalten of
      1 : SP_Dif := 3 * MW + 30;
      2 : SP_Dif := 2 * MW + 20;
      3 : SP_Dif := 2 * MW + 20;
      4 : SP_Dif := 1 * MW + 10;
      else SP_Dif := 0;
    end;
    if SP_Dif > 0 then begin
      for i := 0 to Menu_Labels.Count - 1 do begin
        PML_Rec(Menu_Labels[i])^.L.Left := PML_Rec(Menu_Labels[i])^.L.Left + SP_Dif;
      end;
    end;
  end;
  if ts.Count > 0 then begin
    MB_Panel.Visible := true;
  end;
  ts.Free;
  ts2.Free;

  exit;



  for i := 0 to HMenu_Liste.Count - 1 do begin
    if PMenu_Rec(HMenu_Liste[i])^.Parent = Akt_Menu then begin

      L := TLabel.Create(self);
      L.Name := 'MenuLabel_'+IntToStr(i);
      L.Parent := MB_Panel;
      L.Caption := PMenu_Rec(HMenu_Liste[i])^.Bez;
      L.Top := 10;
      L.Left := i2;
      L.Width := 150;
      i2 := i2 + L.Width + 10;
      new(ml);
      ml^.L := L;
      Menu_Labels.Add(ml);

    end;
  end;
  ts.Free;
  MB_Panel.Visible := true;
  exit;

  tn := nil;
  for i := 1 to WebTreeView1.Items.Count - 1 do begin
    j := WebTreeView1.Items[i].Tag;
    if j = Akt_Menu then begin
      tn := WebTreeView1.Items[i];
      break;
    end;
  end;
  if tn <> nil then begin
    if tn.HasChildren then begin
      ts := TStringList.Create;
      tn2 := tn.GetFirstChild;
      repeat
        ts.Add(tn2.Text);
        tn2 := tn2.GetNextSibling;
      until tn2 = nil;
      i2 := 10;
      for i := 0 to ts.Count - 1 do begin
        L := TLabel.Create(self);
        L.Name := 'MenuLabel_'+IntToStr(i);
        L.Parent := MB_Panel;
        L.Caption := ts[i];
        L.Top := 10;
        L.Left := i2;
        i2 := i2 + L.Width + 10;
        new(ml);
        ml^.L := L;
        Menu_Labels.Add(ml);
      end;
      ts.free;
    end;
  end;

  MB_Panel.Visible := true;
  exit;

  MB_Panel.Top := WebPanel36.Top + TButton(Sender).Top + TButton(Sender).Height;
  MB_Panel.Left := 0; // TWebButton(Sender).Left;
  MB_Panel.Width := Width;
  MB_Panel.Height := WebPageControl1.Height - WebPanel6.Height;

end;

procedure TForm2.WebTimer8Timer(Sender: TObject);
begin
  WebTimer8.Enabled := false;
  WebSocketClient1.DisConnect;
end;

procedure TForm2.WebTimer9Timer(Sender: TObject);
begin
  WebTimer9.Enabled := false;
  Loeschmodus_WK := false;
  Update_Buttons;
end;

procedure TForm2.WebTreeView1Click(Sender: TObject);
var Node : TTreeNode;
    i,j : integer;
    hst : String;
begin
  Node := WebTreeView1.Selected;
  if Node <> nil then begin
    j := -1;
    for i := 0 to HMenu_Liste.Count - 1 do begin
      if PMenu_Rec(HMenu_Liste[i])^.ID = Node.Tag then begin
        j := i;
        hst := HTML_Decode(PMenu_Rec(HMenu_Liste[i])^.Bez);
        break;
      end;
    end;
    WebEdit31.Text := hst;
    Akt_Tree_Item := j;
  end
  else begin
    WebEdit31.Text := '';
    Akt_Tree_Item := -1;
  end;

end;

procedure TForm2.Zeige_Artikel(DataSet: TDataSet);
var i : integer;
begin
//  WebPageControl1Sheet2.Visible := true;
//  WebPageControl1Sheet2.TabVisible := true;
//  WebPageControl1Sheet4.Visible := false;
//  WebPageControl1Sheet4.TabVisible := false;


{  WebPageControl1Sheet1.TabVisible := false;
  WebPageControl1Sheet2.TabVisible := false;
  WebPageControl1Sheet3.TabVisible := false;
//  WebPageControl1Sheet4.TabVisible := false;
  WebPageControl1Sheet5.TabVisible := false;
  WebPageControl1Sheet6.TabVisible := false;
}

  WebTimer4.Enabled := false;
  if Datamodule1.VSHOPArtikelDataSource.Dataset.Fieldbyname('ID').AsInteger = -1 then begin
    WebPageControl7.TabIndex := 1;
    WebPageControl7Change(Self);
    exit;
  end;
  if Datamodule1.VSHOPArtikelDataSource.Dataset.RecordCount = 0 then begin
    if Trim(WebEdit2.Text) <> '' then begin
      WebLabel14.Caption := 'Suchbegriff: '+WebEdit2.Text;
    end
    else begin
      WebLabel14.Caption := '';
    end;
    WebLabel16.Caption := 'Sie suchen in Kategorie: '+WebComboBox3.Text+' -> '+WebListBox1.Items[WebListBox1.ItemIndex];

    if Akt_WG <> 0 then begin
      WebButton14.Caption := 'Suchen in allen Warengruppen <BR> der Kategorie: '+WebComboBox3.Text;
      WebButton14.Visible := true;
    end
    else begin
      WebButton14.Visible := false;
    end;

    WebPageControl1.TabIndex := 7;
    WebPageControl1Change(Self);
    exit;
  end;
  WebDBResponsiveGrid1.Datasource := Datamodule1.VSHOPArtikelDataSource;
  WebPageControl7.TabIndex := 0;
  if Kiosk_Modus then begin
    WebPanel7.Visible := false;
    WebPanel8.Visible := false;
    WebPanel30.Visible := false;

    WebPanel42.Visible := false;
    WebPanel36.Visible := false;
    WebPanel6.Visible := false;
    WebPanel57.Visible := true;

  end
  else begin
    WebPanel7.Visible := true;
    WebPanel57.Visible := false;

  end;




//  WebPageControl1.TabIndex := 1;


end;


procedure TForm2.Lade_Artikel_Bilder;
begin
  Datamodule1.ShopArtikelThumbsDataSet.Close;
  Datamodule1.ShopArtikelThumbsDataSet.AfterOpen := Set_Artikel_Bilder;
  Datamodule1.ShopArtikelThumbsDataSet.QueryString :='$filter=ID2 eq '+IntToStr(Akt_Artikel_ID);
  Datamodule1.ShopArtikelThumbsDataSet.Load;

end;

procedure TForm2.Set_Artikel_Bilder(DataSet: TDataSet);
var hst2 : String;
    i,j,k,k2,ci : cardinal;



procedure Init_Artikel_Merkmal(Gruppe,Wert : cardinal;var CB : TComboBox;var Lab : TLabel);
var i1,j1,j2,j3 : integer;
begin
  CB.Items.Clear;
  CB.Items.Add('-----');
  if Gruppe = 0 then begin
    CB.Visible := false;
    Lab.Visible := false;
  end
  else begin
    CB.Visible := true;
    Lab.Visible := true;
    Lab.Top := k;
    k := k + Lab.Height + 3;
    CB.Top := k;
    k := CB.Top + CB.Height + 5;

    j1 := 0;
    for i1 := 0 to Merkmal_Liste.Count - 1 do begin
      if PMerkmal_Rec(Merkmal_Liste[i1])^.ID = Gruppe then begin
        if PMerkmal_Rec(Merkmal_Liste[i1])^.lfdno = 0 then begin
          Lab.Caption := PMerkmal_Rec(Merkmal_Liste[i1])^.Bezeichnung;
        end
        else begin
          if BitGesetzt(Wert,PMerkmal_Rec(Merkmal_Liste[i1])^.lfdno - 1) then begin
            CB.Items.Add(PMerkmal_Rec(Merkmal_Liste[i1])^.Bezeichnung);
          end;
        end;
      end;
    end;
    if CB.Items.Count = 2 then begin
      CB.ItemIndex := 1;
      CB.Enabled := false;
    end
    else begin
      CB.ItemIndex := 0;
      CB.Enabled := true;
    end;
  end;
end;


procedure Init_Artikel_Merkmal_VV(Gruppe,Wert : cardinal;var CB : TComboBox;var Lab : TLabel);
var i1,j1,j2,j3 : integer;
    VKS : integer;
begin
  VKS := 0;
  CB.Items.Clear;
  CB.Items.Add('-----');
//  Auth_URL := 'http://127.0.0.1:2003/kwshop/auth';
  DataModule1.ArtikelVVDataSet.Close;
  DataModule1.ArtikelVVDataSet.AfterOpen := VV_Fertig; // DataModule1.Shop_Init_2;
  DataModule1.WebClientConnection4.Active := false;
  DataModule1.WebClientConnection4.URI := Auth_URL + 'LoginService/MyGetArtikelVV?NLK='+IntToStr(AktFirmaNo)+'&Token='+My_Cookie+'&ArtikelID='+IntToStr(VV_ID)
  +'&Beiwert='+GetBeiwert+'&VKS='+IntToStr(VKS);
//  WebClientConnection4AfterConnect := VV_(Sender: TObject);
  DataModule1.WebClientConnection4.Active := true;
  DataModule1.ArtikelVVDataSet.Open;



end;



begin
  if Akt_Geraete_Art = GA_Handy then begin
    WebPanel1.Height := 105;
  end
  else begin
    WebPanel1.Height := 105;

  end;

  WebPanel9.Visible := false;
  WebPageControl1.TabIndex := 1;
  WebPageControl1Change(Self);
  hst2 := WebDBResponsiveGrid1.DataSource.DataSet.FieldByName('ID').AsString;
  WebImageControl11.Url := Bilder_Url + 'KB2_'+hst2 + '.png';

  WebEdit5.Text := '';

  WebComboBox5.Width := 200;
  asm debugger; end;  WebComboBox6.Width := 200;



  k2 := WebImageControl11.Top + WebImageControl11.Height + 10;
  k := k2;


  if WebDBResponsiveGrid1.DataSource.DataSet.FieldByName('KZ_VV').AsInteger = 1 then begin
    VV_Aktiv := true;
    VV_ID := WebDBResponsiveGrid1.DataSource.DataSet.FieldByName('ID').AsInteger;
    WebEdit5.Visible := false;
    WebLabel31.Visible := false;
    WebComboBox4.Left := WebEdit5.Left;
    WebLabel32.Left := WebLabel31.Left;
    WebLabel32.Caption := 'Auswahl';
    WebLabel32.Top := k2;
    k2 := k2 + WebLabel32.Height + 10;
    WebComboBox4.Width := WebComboBox5.Width;
    Init_Artikel_Merkmal_VV(WebDBResponsiveGrid1.DataSource.DataSet.FieldByName('MerkmalG1').AsInteger,
                         WebDBResponsiveGrid1.DataSource.DataSet.FieldByName('Merkmal1').AsInteger,
                         WebComboBox4,WebLabel32);
    WebComboBox4.Top := k2;
    k2 := k2 + WebComboBox4.Height + 15;
    WebComboBox5.Visible := false;
    WebLabel30.Visible := false;
    WebComboBox6.Visible := false;
    WebLabel29.Visible := false;
    WebLabel72.Top := k2;

  end
  else begin
    WebEdit5.Visible := true;
    WebEdit5.Left := 17;
    WebLabel31.Visible := true;
    WebLabel31.Left := 17;
    WebLabel31.Caption := 'Menge';
    WebLabel31.Top := k2;

    WebComboBox4.Left := 117;
    WebLabel32.Left := 117;
    WebLabel32.Caption := 'Einheit';
    WebLabel32.Top := k2;



    VV_Aktiv := false;
    WebEdit5.Visible := true;
    WebLabel31.Visible := true;

//    WebComboBox4.Left := 116;
//    WebLabel32.Left := 117;
    WebComboBox4.Width := 100;
    WebLabel32.Caption := 'Einheit';
    WebLabel31.Top := k2;
    WebLabel32.Top := k2;
    k2 := k2 + WebLabel32.Height + 10;
    WebComboBox4.Top := k2;
    WebEdit5.Top := k2-5;

    Init_Artikel_Merkmal(WebDBResponsiveGrid1.DataSource.DataSet.FieldByName('MerkmalG1').AsInteger,
                         WebDBResponsiveGrid1.DataSource.DataSet.FieldByName('Merkmal1').AsInteger,
                         WebComboBox4,WebLabel32);
    k2 := k2 + WebComboBox4.Height + 5;
    k := k2;
    Init_Artikel_Merkmal(WebDBResponsiveGrid1.DataSource.DataSet.FieldByName('MerkmalG2').AsInteger,
                           WebDBResponsiveGrid1.DataSource.DataSet.FieldByName('Merkmal2').AsInteger,
                           WebComboBox5,WebLabel30);
    Init_Artikel_Merkmal(WebDBResponsiveGrid1.DataSource.DataSet.FieldByName('MerkmalG3').AsInteger,
                           WebDBResponsiveGrid1.DataSource.DataSet.FieldByName('Merkmal3').AsInteger,
                           WebComboBox6,WebLabel29);
    WebLabel72.Top := k;

  end;


  if Akt_Modus = Add_Modus then begin
    if WebComboBox4.Visible then begin
      WebComboBox4.ItemIndex := 0;
    end;
    if WebComboBox5.Visible then begin
      WebComboBox5.ItemIndex := 0;
    end;
    if WebComboBox6.Visible then begin
      if WebLabel29.Caption = 'Verpackung' then begin
        WebComboBox6.ItemIndex := 1;
      end
      else begin
        WebComboBox6.ItemIndex := 0;
      end;
    end;
    WebButton1.Caption := '<i class="material-icons">shopping_cart</i> <span class="badge badge-danger badge-xs">'+ 'In den<br>Warenkorb'+'</span>';
    WebEdit5.Readonly := false;
    WebComboBox4.Enabled := true;
    WebComboBox5.Enabled := true;
    WebComboBox6.Enabled := true;

  end
  else if Akt_Modus = Add_Modus_Theke then begin
    if WebComboBox4.Visible then begin
      WebComboBox4.ItemIndex := 0;
    end;
    if WebComboBox5.Visible then begin
      WebComboBox5.ItemIndex := 0;
    end;
    if WebComboBox6.Visible then begin
      if WebLabel29.Caption = 'Verpackung' then begin
        WebComboBox6.ItemIndex := 1;
      end
      else begin
        WebComboBox6.ItemIndex := 0;
      end;
    end;

    WebButton1.Caption := '<i class="material-icons">shopping_cart</i> <span class="badge badge-danger badge-xs">'+ 'In den<br>Warenkorb'+'</span>';
    WebEdit5.Readonly := false;
    WebComboBox4.Enabled := true;
    WebComboBox5.Enabled := true;
    WebComboBox6.Enabled := true;

  end
  else if Akt_Modus = Loesch_Modus then begin
    WebEdit5.Text := Trim(RealStr(WebDBResponsiveGrid3.DataSource.DataSet.Fieldbyname('Menge').AsInteger/1000,11,3,false));
    if VV_Aktiv then begin
      WebComboBox4.Items.Clear;
      WebComboBox4.Items.Add(WebDBResponsiveGrid3.DataSource.DataSet.Fieldbyname('VVBez').AsString);
      WebComboBox4.ItemIndex := 0;
    end
    else begin
      if WebComboBox4.Visible then begin
        WebComboBox4.ItemIndex := WebDBResponsiveGrid3.DataSource.DataSet.Fieldbyname('Zahl1').AsInteger;
      end;
    end;
    if WebComboBox5.Visible then begin
      WebComboBox5.ItemIndex := WebDBResponsiveGrid3.DataSource.DataSet.Fieldbyname('Zahl2').AsInteger;
    end;
    if WebComboBox6.Visible then begin
      WebComboBox6.ItemIndex := WebDBResponsiveGrid3.DataSource.DataSet.Fieldbyname('Zahl3').AsInteger;
    end;

    WebButton1.Caption := '<i class="material-icons">delete</i> <span class="badge badge-danger badge-xs">'+ 'Aus Waren-<br>korb löschen'+'</span>';
    WebEdit5.Readonly := true;
    WebComboBox4.Enabled := false;
    WebComboBox5.Enabled := false;
    WebComboBox6.Enabled := false;

  end
  else begin
    WebEdit5.Text := Trim(RealStr(WebDBResponsiveGrid3.DataSource.DataSet.Fieldbyname('Menge').AsInteger/1000,11,3,false));
    if VV_Aktiv then begin
    end
    else begin
      if WebComboBox4.Visible then begin
        WebComboBox4.ItemIndex := WebDBResponsiveGrid3.DataSource.DataSet.Fieldbyname('Zahl1').AsInteger;
      end;
    end;
    if WebComboBox5.Visible then begin
      WebComboBox5.ItemIndex := WebDBResponsiveGrid3.DataSource.DataSet.Fieldbyname('Zahl2').AsInteger;
    end;
    if WebComboBox6.Visible then begin
      WebComboBox6.ItemIndex := WebDBResponsiveGrid3.DataSource.DataSet.Fieldbyname('Zahl3').AsInteger;
    end;
    WebButton1.Caption := '<i class="material-icons">save</i> <span class="badge badge-danger badge-xs">'+ 'Änderungen<br>speichern'+'</span>';
    WebEdit5.Readonly := false;
    WebComboBox4.Enabled := true;
    WebComboBox5.Enabled := true;
    WebComboBox6.Enabled := true;

  end;
  if Akt_Geraete_Art = GA_Handy then begin
    WebComboBox16.ItemIndex := 0;
    WebComboBox16Change(Self);
  end
  else begin
    WebPageControl2.TabIndex := 0;
    WebPageControl2Change(Self);
  end;
//
end;


procedure TForm2.Lade_Kategorie_WG;
begin
//  if VB_Vorschlag = 0 then exit;
  Datamodule1.ShopKatWGDataset.Close;
  Datamodule1.ShopKatWGDataset.AfterOpen := Zeige_Kat_WG;
  Datamodule1.ShopKatWGDataset.EntitySetName := Akt_Kat_Proc;
  if Akt_Kat_Proc = 'BERECHNE_WG_SHOP_KIOSK' then begin
    Datamodule1.ShopKatWGDataset.QueryString :='$orderby=Reihenfolge,WG';
  end
  else begin
    Datamodule1.ShopKatWGDataset.QueryString :='';
  end;

//  Datamodule1.ShopKatWGDataset.QueryString :='$filter=NL_Key eq '+IntToStr(AktFirmaNo) +' and KZ_Aktiv>0&$orderby=KZ_Aktiv';
  Datamodule1.ShopKatWGDataset.Load;

//
end;


procedure TForm2.Zeige_Kat_WG(DataSet: TDataSet);
var i,j : integer;
    hst : String;
    lb : TListControl;

begin
  WebDBResponsiveGrid1.Datasource := nil;
  Datamodule1.VSHOPArtikelDataSet.Close;
  WebListBox1.Items.Clear;
  WebComboBox15.Items.Clear;

  if DataModule1.ShopKatWGDataset.RecordCount = 0 then begin
    MessageDlg('Zur Zeit keine Artikel in dieser Kategorie', mtError, [], nil);
    exit;
  end;
  WGA[0] := 0;
  WebListBox1.Items.Add('Alle Warengruppen');
  WebCombobox15.Items.Add('Alle Warengruppen');

  i := 1;
  while not DataModule1.ShopKatWGDataset.EOF do begin
    WGA[i] := DataModule1.ShopKatWGDataset.Fieldbyname('WG').AsInteger;
    i := i + 1;
    WebListBox1.Items.Add(DataModule1.ShopKatWGDataset.Fieldbyname('Bezeichnung').AsString);
    WebComboBox15.Items.Add(DataModule1.ShopKatWGDataset.Fieldbyname('Bezeichnung').AsString);
    DataModule1.ShopKatWGDataset.Next;
  end;
  WebListBox1.ItemIndex := 0;
  WebComboBox15.ItemIndex := 0;
  WebListBox1Change(Self);

  exit;
end;


procedure TForm2.Zeige_Artikel_Details(DataSet: TDataSet);
begin
  WebButton1.Caption := '<i class="material-icons">shopping_cart</i> <span class="badge badge-danger badge-xs">'+ 'In den<br>Warenkorb'+'</span>';
  WebEdit5.Readonly := false;
  WebComboBox4.Enabled := true;
  WebComboBox5.Enabled := true;
  WebComboBox6.Enabled := true;
  WebEdit5.Readonly := false;
  WebComboBox4.Enabled := true;
  WebComboBox5.Enabled := true;
  WebComboBox6.Enabled := true;

  Lade_Artikel_Bilder;

end;

procedure TForm2.Zeige_WK_Artikel(DataSet: TDataSet);
begin
  case Akt_Modus of
    Loesch_Modus : begin
      WebButton1.Caption := '<i class="material-icons">delete</i> <span class="badge badge-danger badge-xs">'+ 'Aus Waren-<br>korb löschen'+'</span>';
      WebEdit5.Readonly := true;
      WebComboBox4.Enabled := false;
      WebComboBox5.Enabled := false;
      WebComboBox6.Enabled := false;
      WebComboBox16.Enabled := false;

    end;
    Add_Modus : begin
      WebButton1.Caption := '<i class="material-icons">shopping_cart</i> <span class="badge badge-danger badge-xs">'+ 'In den<br>Warenkorb'+'</span>';
      WebEdit5.Readonly := false;
      WebComboBox4.Enabled := true;
      WebComboBox5.Enabled := true;
      WebComboBox6.Enabled := true;
      WebComboBox16.Enabled := true;

    end;
    Add_Modus_Theke : begin
      WebButton1.Caption := '<i class="material-icons">shopping_cart</i> <span class="badge badge-danger badge-xs">'+ 'In den<br>Warenkorb'+'</span>';
      WebEdit5.Readonly := false;
      WebComboBox4.Enabled := true;
      WebComboBox5.Enabled := true;
      WebComboBox6.Enabled := true;
      WebComboBox16.Enabled := true;

    end;

    Edit_Modus : begin
      WebButton1.Caption := '<i class="material-icons">save</i> <span class="badge badge-danger badge-xs">'+ 'Änderungen<br>speichern'+'</span>';
      WebEdit5.Readonly := false;
      WebComboBox4.Enabled := true;
      WebComboBox5.Enabled := true;
      WebComboBox6.Enabled := true;
      WebComboBox16.Enabled := true;

    end;

  end;
  if not Device_ist_Handy then begin
    WebComboBox16.Visible := false;
  end;

  WebDBResponsiveGrid1ItemClick(self,0);
end;

procedure TForm2.Zeige_Theken_Artikel(DataSet: TDataSet);
begin
  case Akt_Modus of
    Add_Modus_Theke : begin
      WebButton1.Caption := '<i class="material-icons">shopping_cart</i> <span class="badge badge-danger badge-xs">'+ 'In den<br>Warenkorb'+'</span>';
      WebEdit5.Readonly := false;
      WebComboBox4.Enabled := true;
      WebComboBox5.Enabled := true;
      WebComboBox6.Enabled := true;
    end;
  end;
  WebDBResponsiveGrid1ItemClick(self,0);
end;

procedure TForm2.Check_User_Password(In_Kasse : boolean);
var User_Name,
    Kennwort : String;
    NLK : String;
    i,j,UKA,
    TNID : integer;


  procedure OnConnect;
  begin

    DataModule1.XDataWebClientAuth.RawInvoke('ILoginService.Login', [NLK,User_Name, Kennwort],
      procedure(Response: TXDataClientResponse)
      var
        Token,
        hst3: string;
      begin
        Token := JS.ToString(JS.ToObject(Response.Result)['value']);
        if Token <> '' then begin
          i := pos(';',Token);
          hst3 := Copy(Token,1,i-1);
          UKA := StrToInt(hst3);
          system.Delete(Token,1,i);

          i := pos(';',Token);
          hst3 := Copy(Token,1,i-1);
          Akt_User_ID := StrToInt(hst3);
          system.Delete(Token,1,i);

          i := pos(';',Token);
          hst3 := Copy(Token,1,i-1);
          TNID := StrToInt(hst3);
          system.Delete(Token,1,i);
          UnterKonto_Aktiv := false;
          if UKA >= 0 then begin
            Admin_Angemeldet := true;
          end;
          Akt_JWToken := Token;
          AktKDName := WebEdit10.Text;
          WebEdit11.Text := '';
          Datamodule1.XDataWebConnection2.Close;
          WebEdit11.Text := '';
          Check_Angemeldet;
          if Login_HM then begin
            Login_HM := false;
            WebPanel9.Visible := true;
            WebPageControl1.TabIndex := Save_Tab_Index;
            WebPageControl1Change(Self);
          end
          else begin
            WebPageControl4.TabIndex := 2;
          end;
          if Chat_Verbunden then begin
            WebSocketClient1.Send('@Login:'+IntToStr(Akt_User_ID));
          end;

        end;
      end,

      procedure(Error: TXDataClientError)
      begin
        MessageDlg('Email-Adresse oder Kennwort falsch oder keine Internet-Verbindung', mtError, [], nil);
      end
     );
  end;

  procedure OnError(Error: TXDataWebConnectionError);
  var hst : String;
  begin
    hst := 'Auth-Server Fehler: '+Error.ErrorMessage + '. Bitte prüfen Sie Ihre Internet-Verbindung.';
    MessageDlg(hst, mtError, [], nil);
  end;





begin
  Datamodule1.XDataWebConnection2.Close;
  if In_Kasse then begin
    User_Name := Uppercase(Trim(WebEdit10.Text));
    Kennwort := Trim(WebEdit11.Text);
  end
  else begin
    User_Name := Uppercase(Trim(WebEdit3.Text));
    Kennwort := Trim(WebEdit4.Text);
  end;
  repeat
    i := pos('ü',User_Name);
    if i > 0 then begin
      system.delete(User_name,i,1);
      system.Insert('UE',User_name,i);
    end;
  until i = 0;
  repeat
    i := pos('ö',User_Name);
    if i > 0 then begin
      system.delete(User_name,i,1);
      system.Insert('OE',User_name,i);
    end;
  until i = 0;
  repeat
    i := pos('ä',User_Name);
    if i > 0 then begin
      system.delete(User_name,i,1);
      system.Insert('AE',User_name,i);
    end;
  until i = 0;
  repeat
    i := pos('Ü',User_Name);
    if i > 0 then begin
      system.delete(User_name,i,1);
      system.Insert('UE',User_name,i);
    end;
  until i = 0;
  repeat
    i := pos('Ö',User_Name);
    if i > 0 then begin
      system.delete(User_name,i,1);
      system.Insert('OE',User_name,i);
    end;
  until i = 0;
  repeat
    i := pos('Ä',User_Name);
    if i > 0 then begin
      system.delete(User_name,i,1);
      system.Insert('AE',User_name,i);
    end;
  until i = 0;



  NLK := IntToStr(AktFirmaNo);
  Akt_JWToken := '';
  AktKDName := '';
  Akt_User_ID := 0;

  Datamodule1.XDataWebConnection2.Open(@OnConnect, @OnError);

end;

procedure TForm2.Update_Buttons;
begin
  if Akt_Geraete_Art <> GA_Handy then begin
    WebButton3.Caption := '<i class="material-icons">shopping_cart</i> <span class="badge badge-danger badge-xs">' + IntToStr(WK_Artikel) + ' Artikel'+'</span>';
    WebButton5.Caption := '<i class="material-icons">euro_symbol</i> <span class="badge badge-danger badge-xs">' + IntToStr(WK_Artikel) + ' Kasse</span>';
  end
  else begin
    WebButton3.Caption := '<i class="material-icons">shopping_cart</i> <span class="badge badge-danger badge-xs">' + IntToStr(WK_Artikel) + ''+'</span>';
    WebButton5.Caption := '<i class="material-icons">euro_symbol</i> <span class="badge badge-danger badge-xs">' + IntToStr(WK_Artikel) + '</span>';
  end;

  WebButton127.Caption := '<i class="material-icons">shopping_cart</i> <span class="badge badge-danger badge-xs">' + IntToStr(WK_Artikel) + ' Artikel'+'</span>';
  WebButton128.Caption := '<i class="material-icons">shopping_cart</i> <span class="badge badge-danger badge-xs">' + IntToStr(WK_Artikel) + ' Artikel'+'</span>';

  if Akt_User_ID = 0 then begin
    WebButton11.Caption := '<i class="material-icons">login</i> <span class="badge badge-danger badge-xs">' + 'Anmelden' + '</span>';
    Anmelden1.Caption := 'Anmelden';
  end
  else begin
    WebButton11.Caption := '<i class="material-icons">logout</i> <span class="badge badge-danger badge-xs">' + 'Abmelden' + '</span>';
    Anmelden1.Caption := 'Abmelden';
  end;
  if Chat_Verbunden then begin
    WebButton88.Caption := '<i class="material-icons">flash_off</i> <span class="badge badge-danger badge-xs">' + 'Trennen' + '</span>';
    WebButton100.Visible := true;
    WebButton90.Visible := false;
    WebButton93.Visible := false;
    WebButton89.Visible := true;

  end
  else begin
    WebButton88.Caption := '<i class="material-icons">flash_on</i> <span class="badge badge-danger badge-xs">' + 'Verbinden' + '</span>';
    WebButton100.Visible := false;
    WebButton90.Visible := true;
    WebButton93.Visible := true;
    WebButton89.Visible := false;

  end;
  WebButton89.Caption := '<i class="material-icons">send</i> <span class="badge badge-danger badge-xs">' + 'Nachricht senden' + '</span>';
  WebButton92.Caption := '<i class="material-icons">volume_off</i> <span class="badge badge-danger badge-xs">' + 'Lautsprecher aus' + '</span>';
//  WebButton92.Enabled := false;
  if LoeschModus_WK then begin
    WebButton108.Caption := '<i class="material-icons">delete_sweep</i> <span class="badge badge-danger badge-xs">'+ 'Bestätigung: Alle Artikel<br> aus Warenkorb löschen'+'</span>';
    Set_Button_Class(3,WebButton108);

  end
  else begin
    WebButton108.Caption := '<i class="material-icons">delete_sweep</i> <span class="badge badge-danger badge-xs">'+ 'Alle Artikel aus dem<br>Warenkorb löschen'+'</span>';
    Set_Button_Class(1,WebButton108);

  end;
end;


procedure TForm2.Zeige_Warenkorb(DataSet: TDataSet);
begin
  WebDBResponsiveGrid3.Datasource := Datamodule1.VWarenkorbDataSource;
  WebPageControl1.TabIndex := 0;
  WebPageControl1Change(Self);
end;

procedure TForm2.Del_ButtonClick(Sender : TObject);
var hst : String;
    i,j : integer;
begin
  if (Sender is TButton) then  begin
    i := (Sender as TButton).Tag;
    if WebDBResponsiveGrid3.DataSource.DataSet.Locate('lfdno',i,[]) then begin
      j := WebDBResponsiveGrid3.DataSource.DataSet.Fieldbyname('ArtikelID').AsInteger;
      Akt_Artikel_ID := j;
      Akt_Modus := Loesch_Modus;
      Refresh_ShopArtikel(j);
      {
      Datamodule1.VSHOPArtikelDataSet.Close;
      Datamodule1.VSHOPArtikelDataSet.AfterOpen := Zeige_WK_Artikel;
      hst := '$filter=(ID eq '+IntToStr(j)+ ')';
      Datamodule1.VSHOPArtikelDataSet.QueryString :=hst;
      Datamodule1.VSHOPArtikelDataSet.Load;
      }
    end;
  end;

end;



procedure TForm2.Edit_ButtonClick(Sender : TObject);
var hst : String;
    i,j : integer;
begin
  if (Sender is TButton) then  begin
    i := (Sender as TButton).Tag;
    if WebDBResponsiveGrid3.DataSource.DataSet.Locate('lfdno',i,[]) then begin
      if WebDBResponsiveGrid3.DataSource.DataSet.FieldByName('KZ_VV').AsInteger = 1 then begin
        MessageDlg('Dies ist ein VV-Artikel --> Löschen', mtError, [], nil);
        exit;
      end;
      j := WebDBResponsiveGrid3.DataSource.DataSet.Fieldbyname('ArtikelID').AsInteger;
      Akt_Artikel_ID := j;
      Refresh_ShopArtikel(j);
    end;
{
      Akt_Modus := Edit_Modus;
      Datamodule1.VSHOPArtikelDataSet.Close;
      Datamodule1.VSHOPArtikelDataSet.AfterOpen := Zeige_WK_Artikel;
      hst := '$filter=(ID eq '+IntToStr(j)+ ')';
      Datamodule1.VSHOPArtikelDataSet.QueryString :=hst;
      Datamodule1.VSHOPArtikelDataSet.Load;
    end;
}
  end;

end;

procedure TForm2.ErnhrungsFilter1Click(Sender: TObject);
begin
  WebButton104Click(Self);
end;

procedure TForm2.Zeige_Live_Bild;
begin
  if Chat_Verbunden then begin
    MessageDlg('Klicken Sie auf den Artikel Ihrer Wahl zu dem Sie ein Live-Bild sehen wollen, in der Detail-Maske können Sie dann das Bild anfordern', mtInformation, [], nil);
  end
  else begin
    MessageDlg('Sie müssen erst den Chat starten, anschließend klicken Sie auf den Artikel Ihrer Wahl zu dem Sie ein Live-Bild sehen wollen, in der Detail-Maske können Sie dann das Bild anfordern', mtInformation, [], nil);
  end;
//  WebTimer3.Enabled := true;

end;



procedure TForm2.Theken_Bild_Click(x,y : integer);
var x1,y1,i,j : integer;
    l : TList;
begin
  Theken_Wahl := -1;
  x1 := Round(x / Zoom_Faktor_x);
  y1 := Round(y / Zoom_Faktor_y);
  if Regionen[y1] <> nil then begin
    l := Regionen[y1];
    for i := 0 to l.Count - 1 do begin
      if (x1 >= PReg_Rec(l[i])^.v)
        and (x1 <= PReg_Rec(l[i])^.b) then begin
          Akt_Theken_ID := PReg_Rec(l[i])^.ID;
          Theken_Wahl := PReg_Rec(l[i])^.ID;
          break;
      end;
    end;
  end;
end;


procedure TForm2.Mouse_Move_Thekenbild(x,y : integer);
var x1,y1,i,j : integer;
    l : TList;
    Wl : TLabel;
    wic : TImageControl;

begin
  case WebPageControl3.TabIndex of
    0 : begin
          wic := WebImageControl1;
          wl := WebLabel28;
        end;
    1 : begin
          wic := WebImageControl2;
          wl := WebLabel27;
        end;
    2 : begin
          wic := WebImageControl5;
          wl := WebLabel26;
        end;
    3 : begin
          wic := WebImageControl4;
          wl := WebLabel23;
        end;
  end;


  Zoom_Faktor_x := wic.Width/1414;
  Zoom_Faktor_y := wic.Height/420;
  WebLabel24.Caption := InttoStr(x) +'/' +intToStr(y);
  x1 := Round(x / Zoom_Faktor_x);
  y1 := Round(y / Zoom_Faktor_y);
  WebLabel25.Caption := InttoStr(x1) +'/' +intToStr(y1);


  wl.Visible := false;
  if Regionen[y1] <> nil then begin
    l := Regionen[y1];
    for i := 0 to l.Count - 1 do begin
      if (x1 >= PReg_Rec(l[i])^.v)
        and (x1 <= PReg_Rec(l[i])^.b) then begin
        if Akt_Theken_ID <> PReg_Rec(l[i])^.ID then begin
          wl.Caption := '';
          Akt_Theken_ID := PReg_Rec(l[i])^.ID;

          Datamodule1.ARTIKELBEZDataSet.Close;
          Datamodule1.ARTIKELBEZDataSet.AfterOpen := Zeige_Theken_Hint;
          Datamodule1.ARTIKELBEZDataSet.QueryString :='$filter=ID eq '+IntToStr(Akt_Theken_ID);
          Datamodule1.ARTIKELBEZDataSet.Load;

        end;
        wl.Left := x+20;
        wl.Top := y;
        wl.Visible := true;
        break;
      end;
    end;
  end;

end;


procedure TForm2.Zeige_Theken_Hint(DataSet: TDataSet);
begin
  Theken_Hint := '';
  if Datamodule1.ARTIKELBEZDataSet.RecordCount > 0 then begin
    Theken_Hint := Datamodule1.ARTIKELBEZDataSet.Fieldbyname('Bezeichnung').AsString;
    WebLabel23.Caption := Theken_Hint;
    WebLabel28.Caption := Theken_Hint;
    WebLabel27.Caption := Theken_Hint;
    WebLabel26.Caption := Theken_Hint;
  end;
end;


procedure TForm2.WebPayPal1PaymentCancelled(Sender: TObject);
begin
  mePayment.Lines.Clear;
  mePayment.Lines.Add('payment cancelled');
end;

procedure TForm2.WebPayPal1PaymentDone(Sender: TObject;
  Args: TPayPalPaymentEventArgs);
begin
//  DisplayOrder;

  mePayment.Lines.Clear;
  mePayment.Lines.Add('Thank you for your order!');
  mePayment.Lines.Add(Args.PaymentID);
  mePayment.Lines.Add(Args.Email);
  mePayment.Lines.Add(Args.Total);
  mePayment.Lines.Add(Args.RecipientName);
  mePayment.Lines.Add(Args.Address1);
  mePayment.Lines.Add(Args.PostalCode);
  mePayment.Lines.Add(Args.City);
  mePayment.Lines.Add(Args.CountryCode);

  SendOrderConfirmation(Args.Email, Args.PaymentID, WebPayPal1.Payment.Items);

  WebPayPal1.BeginUpdate;
  WebPayPal1.Payment.Items.Clear;
  WebPayPal1.EndUpdate;

//  UpdateCart(False);
end;

procedure TForm2.WebPayPal1PaymentError(Sender: TObject;
  Args: TPayPalErrorEventArgs);
var
  I: Integer;
  errors: string;
begin
  mePayment.Lines.Clear;
  mePayment.Lines.Add('payment error');

  for I := 0 to Args.ErrorDetails.Count - 1 do
  begin
    if errors <> '' then
      errors := '<br>';
    errors := errors + Args.ErrorDetails[I];
  end;

  ShowError(Args.ErrorName, errors);
end;

procedure TForm2.WebRadioGroup1Change(Sender: TObject);
begin
  case WebRadioGroup1.ItemIndex of
    2 : begin
          WebLabel47.Caption := 'Wählen Sie die Verkaufsstelle';
          WebLabel48.Caption := 'in der Sie die Ware abholen wollen';
        end;
    0 : begin
          WebLabel47.Caption := 'Klicken Sie auf den Paypal-Button';
          WebLabel48.Caption := 'um die Zahlung abzuschließen';

        end;

  end;
end;

procedure TForm2.WebRadioGroup2Change(Sender: TObject);
begin
  case WebRadioGroup2.ItemIndex of
    0 : begin
          WebPanel36.Visible := false;

        end;
    1 : begin
          WebPanel36.Visible := true;

        end;
    2 : begin

        end;

  end;
end;

procedure TForm2.WebSocketClient1Connect(Sender: TObject);
var hst : String;
begin
  Chat_Verbunden := true;
//  WebListBox4.Items.Clear;
  WebStringGrid4.RowCount := 0;
  if not LautSprecher_ein then begin
    WebButton92.Caption := '<i class="material-icons">volume_up</i> <span class="badge badge-danger badge-xs">' + 'Lautsprecher ein' + '</span>';
  end
  else begin
    WebButton92.Caption := '<i class="material-icons">volume_off</i> <span class="badge badge-danger badge-xs">' + 'Lautsprecher aus' + '</span>';
  end;

  Update_Buttons;
  WebLabel94.Caption := 'Verbunden';
  WebSocketClient1.Send('Ich_bin_verbunden;'+IntToStr(Akt_WK_ID)+';'+IntToStr(Akt_User_ID));
  if WebPageControl1.TabIndex = 1 then begin
    hst := '<font color="#000000">'+ WebLabel84.Caption+'</font>';
    WebSocketClient1.Send('@Artikel:'+InttoStr(Akt_Artikel_ID)+';'+hst);

  end;

//  MessageDlg('Connected', mtInformation, [], nil);

  WebButton90.Visible := false;
  WebButton93.Visible := false;

end;

procedure TForm2.WebSocketClient1DataReceived(Sender: TObject; Origin: string;
  SocketData: TJSObjectRecord);
var hst,
    hst2 : String;
    i : integer;
begin
  hst := SocketData.jsobject.ToString;
  if Copy(hst,1,length('@Vorschlag:')) = '@Vorschlag:' then begin
    system.delete(hst,1,length('@Vorschlag:'));
    i := pos(';',hst);
    VB_Bem := Copy(hst,i+1,255);
    system.delete(hst,i,255);
    i := StrToInt(hst);
    hst := '<font color="#FF0000" size="large"> '+ hst+'</font>';
    WebStringGrid4.RowCount := WebStringGrid4.RowCount + 1;
    WebStringGrid4.Cells[0,WebStringGrid4.RowCount-1] := 'Da hätte ich ein paar interessante Vorschläge für Sie!';
    WebStringGrid4.TopRow := WebStringGrid4.RowCount-1;
    VB_Vorschlag := i;
    if WebPageControl1.TabIndex <> 3 then begin
      WebButton6Click(Self);
      VB_Vorschlag := i;

      MB_Click(Self);
    end
    else begin
      VB_Vorschlag := i;
      MB_Click(Self);

    end;
  end
  else if Copy(hst,1,length('@Suche:')) = '@Suche:' then begin
    VB_Vorschlag := 0;
    system.delete(hst,1,length('@Suche:'));
    WebEdit2.Text := hst;
    WebComboBox3.ItemIndex := 1;
    WebComboBox3Change(Self);

    if WebPageControl1.TabIndex <> 3 then begin
      WebButton6Click(Self);
//      MB_Click(Self);
    end
    else begin
//      MB_Click(Self);
    end;
  end

  else if Copy(hst,1,length('@Bestellung_Aktiv')) = '@Bestellung_Aktiv' then begin
    WebPanel53.Visible := true;
  end
  else if Copy(hst,1,length('@Bestellung_Fertig')) = '@Bestellung_Fertig' then begin
    WebPanel53.Visible := false;
  end

  else if Copy(hst,1,length('@Livebild:')) = '@Livebild:' then begin
//    WebLabel97.Caption := hst;
    system.delete(hst,1,length('@Livebild:'));
    i := pos(';',hst);
    hst2 := Copy(hst,1,i-1);
    Chat_Live_ID := StrToInt(hst2);
    system.delete(hst,1,i);
    Chat_Live_Bild := hst;
//    WebLabel97.Caption := WebLabel97.Caption  + ' --> '+IntToStr(Chat_Live_ID) + ';'+hst;
    WebPageControl2.TabIndex := 5;
    WebPageControl2Change(Self);
  end
  else if Copy(hst,1,length('@Speak:')) = '@Speak:' then begin
    system.delete(hst,1,length('@Speak:'));
    Lies_Vor(hst);
  end
  else begin

    hst := '<font color="#FF0000" size="large"> '+ hst+'</font>';
    WebStringGrid4.RowCount := WebStringGrid4.RowCount + 1;
    WebStringGrid4.Cells[0,WebStringGrid4.RowCount-1] := hst;
    WebStringGrid4.TopRow := WebStringGrid4.RowCount-1;
  //  i := WebStringGrid4.ElementHandle..scrollTop;


  //  WebListBox4.Items.Add('< '+ hst);
  //  WebListBox4.Items.Add('<font color="#FF0000">'+ hst+'</font>');
  //  WebListBox4.Items.Add('<p style=color:red>'+ hst+'</p>');
//    Lies_Vor('Hallo, ich bin Clara.'); //hst);

  end;
end;

procedure TForm2.WebSocketClient1Disconnect(Sender: TObject);
begin
  if not Chat_Abmeldung then begin
    if Chat_Anmeldung then begin
      MessageDlg('Anmeldung beim Chat-Server nicht möglich', mtError, [], nil);
    end;
  end;
  Chat_Anmeldung := false;
  Chat_Abmeldung := false;
  WebLabel94.Caption := 'Nicht Verbunden';
  Chat_Verbunden := false;
  WebPanel44.Visible := false;
  WebStringGrid4.RowCount := 0;
  WebImageControl10.Visible := false;
  Chat_Live_Bild := '';
  Chat_Live_ID := 0;


//  WebListBox4.Items.Clear;

  Update_Buttons;

end;

procedure TForm2.WebStringGrid1DblClick(Sender: TObject);
begin
  WebButton60Click(Self);
end;

procedure TForm2.WebStringGrid2DblClick(Sender: TObject);
begin
  WebButton61Click(Self);
end;

procedure TForm2.WebStringGrid3DblClick(Sender: TObject);
begin
  WebButton65Click(Self);
end;

procedure TForm2.WebStringGrid5Click(Sender: TObject);
var i,j : integer;
    hst : String;
begin
  if Chat_Verbunden then begin
    i := WebStringGrid5.Row;
    if i > 0 then begin
      j := StrToInt(Bestell_Liste[i-1]);
      hst := WebStringGrid5.Cells[1,i];
      WebSocketClient1.Send('@Artikel:'+InttoStr(j)+';'+hst);
    end;
  end;

end;

procedure TForm2.ShowError(ErrorTitle, ErrorMessage: string);
  procedure DialogProc(AValue: TModalResult);
//  var
//    s: string;
  begin
//    if AValue = mrOk then
//      s := 'OK clicked'
  end;
begin
  WebMessageDlg1.ShowDialog('<b>' + ErrorTitle + '</b><br>' + ErrorMessage, TMsgDlgType.mtWarning, [mbOK], @DialogProc);
end;


procedure TForm2.StartTimerTimer(Sender: TObject);
var i, j : integer;
    hst : String;
    p : PMerkmal_Rec;
    p2 : PLaender_Rec;
begin
  if not Start_Timer_Aktiv then begin
    StartTimer.Enabled := false;
    exit;
  end;
  WebLabel8.Caption := IntToStr(StartPhase) + '  ' + IntToStr(Start_Phasen_Array[StartPhase])
    + '  ' + DateTimeToStr(Now);
  StartTimer.Enabled := false;
  if not Start_Phase_Aktiv[StartPhase] then begin
    Start_Phase_Aktiv[StartPhase] := true;
    case StartPhase of
      0 : begin
            if not DataModule1.XDataWebConnection1.Connected then begin
              DataModule1.XDataWebConnection1.Url := Api_Url;
              try
                DataModule1.XDataWebConnection1.Connected := true;
              except
                Start_Phase_Aktiv[StartPhase] := false;
                Start_Phasen_Array[StartPhase] := Start_Phasen_Array[StartPhase] + 1;
                if Start_Phasen_Array[StartPhase] > 5 then begin
                  MessageDlg('Probleme beim Verbinden zum Server, Phase ' + IntToStr(StartPhase)+' Bitte neu starten',mtError, [], nil);
                  exit;
                end;
              end;
            end;
          end;
      1 : begin
            if not DataModule1.XDataWebConnection2.Connected then begin
              DataModule1.XDataWebConnection2.Url := Auth_URL;
              try
                DataModule1.XDataWebConnection2.Connected := true;
              except
                Start_Phase_Aktiv[StartPhase] := false;
                Start_Phasen_Array[StartPhase] := Start_Phasen_Array[StartPhase] + 1;
                if Start_Phasen_Array[StartPhase] > 5 then begin
                  MessageDlg('Probleme beim Verbinden zum Server, Phase ' + IntToStr(StartPhase)+' Bitte neu starten',mtError, [], nil);
                  exit;
                end;

              end;
            end;
          end;
      2 : begin
            DataModule1.SHOPKategorienDataSet.Close;
            DataModule1.SHOPKategorienDataSet.AfterOpen := DataModule1.Shop_Init_1;
            DataModule1.SHOPKategorienDataSet.QueryString :='$filter=NL_Key eq '+IntToStr(AktFirmaNo) + ' and KZ_Aktiv gt 0&$orderby=KZ_Aktiv';
            try
              DataModule1.SHOPKategorienDataSet.Load;
            except
              Start_Phase_Aktiv[StartPhase] := false;
              Start_Phasen_Array[StartPhase] := Start_Phasen_Array[StartPhase] + 1;
              if Start_Phasen_Array[StartPhase] > 5 then begin
                MessageDlg('Probleme beim Verbinden zum Server, Phase ' + IntToStr(StartPhase)+' Bitte neu starten',mtError, [], nil);
                exit;
              end;

            end;

          end;
      3 : begin
            Shop_Kategorien := TStringList.Create;
            while not DataModule1.SHOPKategorienDataSet.EOF do begin
              if DataModule1.SHOPKategorienDataSet.Fieldbyname('KZ_Aktiv').AsInteger > 0 then begin
                hst := DataModule1.SHOPKategorienDataSet.Fieldbyname('Nummer').AsString + ';0;'
                       + DataModule1.SHOPKategorienDataSet.Fieldbyname('Bezeichnung').AsString + ';';
                hst := hst + DataModule1.SHOPKategorienDataSet.Fieldbyname('Farbe').AsString;
                Shop_Kategorien.Add(hst);
              end;
              DataModule1.SHOPKategorienDataSet.Next;
            end;
  //          DataModule1.Shop_Init_2(nil);
            StartPhase := 4;
          end;
      4 : begin
            WK_Artikel := 0; // DataModule1.VWarenkorbDataSet.RecordCount;

            DataModule1.MerkmaleDataSet.Close;
            DataModule1.MerkmaleDataSet.AfterOpen := DataModule1.Shop_Init_3;
            DataModule1.MerkmaleDataSet.QueryString :='$orderby=ID,lfdno';
            try
              DataModule1.MerkmaleDataSet.Load;
            except
              Start_Phase_Aktiv[StartPhase] := false;
              Start_Phasen_Array[StartPhase] := Start_Phasen_Array[StartPhase] + 1;
              if Start_Phasen_Array[StartPhase] > 5 then begin
                MessageDlg('Probleme beim Verbinden zum Server, Phase ' + IntToStr(StartPhase)+' Bitte neu starten',mtError, [], nil);
                exit;
              end;
            end;
          end;
      5 : begin
            DataModule1.MerkmaleDataSet.First;
            while not DataModule1.MerkmaleDataSet.EOF do begin
              new(p);

              p^.ID := DataModule1.MerkmaleDataSet.FieldByname('ID').AsInteger;
              p^.lfdno := DataModule1.MerkmaleDataSet.FieldByname('lfdno').AsInteger;
              p^.Bezeichnung := HTML_Decode(DataModule1.MerkmaleDataSet.FieldByname('Bezeichnung').AsString);
              p^.RefGewicht := DataModule1.MerkmaleDataSet.FieldByname('RefGewicht').AsFloat;
              p^.KZ_Integer := DataModule1.MerkmaleDataSet.FieldByname('KZ_Integer').AsInteger;
              p^.KZ_Gewicht := DataModule1.MerkmaleDataSet.FieldByname('KZ_Gewicht').AsInteger;
              Merkmal_Liste.Add(p);
              DataModule1.MerkmaleDataSet.Next;
            end;
            DataModule1.LaenderDataSet.Close;
            DataModule1.LaenderDataSet.AfterOpen := DataModule1.Shop_Init_4;
            DataModule1.LaenderDataSet.QueryString :='$filter=NL_Key eq '+IntToStr(AktFirmaNo) + ' and KZ_Aktiv eq 1';
            try
              DataModule1.LaenderDataSet.Load;
            except
              Start_Phase_Aktiv[StartPhase] := false;
              Start_Phasen_Array[StartPhase] := Start_Phasen_Array[StartPhase] + 1;
              if Start_Phasen_Array[StartPhase] > 5 then begin
                MessageDlg('Probleme beim Verbinden zum Server, Phase ' + IntToStr(StartPhase)+' Bitte neu starten',mtError, [], nil);
                exit;
              end;
            end;


          end;
      6 : begin
            Laender_Liste := TList.Create;
            DataModule1.LaenderDataSet.First;
            while not DataModule1.LaenderDataSet.Eof do begin
              new(p2);
              p2^.ID := DataModule1.LaenderDataSet.FieldByName('ID').AsInteger;
              p2^.Bezeichnung := DataModule1.LaenderDataSet.FieldByName('Bezeichnung').AsString;
              p2^.Kuerzel := DataModule1.LaenderDataSet.FieldByName('Kuerzel').AsString;
              Laender_Liste.Add(p2);
              DataModule1.LaenderDataSet.Next;
            end;
            StartPhase := 7;
          end;
      7 : begin
            DataModule1.KonfigDataSet.Close;
  //          DataModule1.KonfigDataSet.AfterOpen := Do_Display_Options;
            DataModule1.WebClientConnection6.Active := false;
            DataModule1.WebClientConnection6.URI := Auth_URL + 'LoginService/MyGetKonfig?P1='+IntToStr(0)+'&P2='+IntToStr(AktFirmaNo)+'&P3='+My_Cookie+'&P4='+GetBeiwert;
            try
              DataModule1.WebClientConnection6.Active := true;
              if DataModule1.WebClientConnection6.Active then begin
                StartPhase := 8;
              end;
            except
              Start_Phase_Aktiv[StartPhase] := false;
              Start_Phasen_Array[StartPhase] := Start_Phasen_Array[StartPhase] + 1;
              if Start_Phasen_Array[StartPhase] > 5 then begin
                MessageDlg('Probleme beim Verbinden zum Server, Phase ' + IntToStr(StartPhase)+' Bitte neu starten',mtError, [], nil);
                exit;
              end;
            end;

  //          DataModule1.KonfigDataSet.Open;

          end;
      8 : begin
            try
//              DataModule1.KonfigDataSet.AfterOpen := Do_Display_Options;
              DataModule1.KonfigDataSet.Open;
              if DataModule1.KonfigDataSet.Active then begin
                StartPhase := 9;
              end;
            except
              Start_Phase_Aktiv[StartPhase] := false;
              Start_Phasen_Array[StartPhase] := Start_Phasen_Array[StartPhase] + 1;
              if Start_Phasen_Array[StartPhase] > 5 then begin
                MessageDlg('Probleme beim Verbinden zum Server, Phase ' + IntToStr(StartPhase)+' Bitte neu starten',mtError, [], nil);
                exit;
              end;

            end;
          end;
      9 : begin
            Datamodule1.MenuDataSet.Close;
  //          Datamodule1.MenuDataSet.AfterOpen := Mache_Menu_Baum;
            Datamodule1.WebClientConnection7.Active := false;
            Datamodule1.WebClientConnection7.URI := Auth_URL + 'LoginService/MyGetMenu?P1='+IntToStr(0)+'&P2='+IntToStr(AktFirmaNo)+'&P3='+My_Cookie+'&P4='+GetBeiwert;
            try
              Datamodule1.WebClientConnection7.Active := true;
            except
              Start_Phase_Aktiv[StartPhase] := false;
              Start_Phasen_Array[StartPhase] := Start_Phasen_Array[StartPhase] + 1;
              if Start_Phasen_Array[StartPhase] > 5 then begin
                MessageDlg('Probleme beim Verbinden zum Server, Phase ' + IntToStr(StartPhase)+' Bitte neu starten',mtError, [], nil);
                exit;
              end;

            end;


          end;
      10 : begin
             try
               Datamodule1.MenuDataSet.Open;
               StartPhase := 11;
            except
              Start_Phase_Aktiv[StartPhase] := false;
              Start_Phasen_Array[StartPhase] := Start_Phasen_Array[StartPhase] + 1;
              if Start_Phasen_Array[StartPhase] > 5 then begin
                MessageDlg('Probleme beim Verbinden zum Server, Phase ' + IntToStr(StartPhase)+' Bitte neu starten',mtError, [], nil);
                exit;
              end;
            end;
           end;

      11 : begin

             Mache_Menu_Baum(nil);
           end;
      12 : begin
             Do_Display_Options(nil);
           end;
      13 : begin
//              Device_Ist_Handy := true;
              if Device_Ist_Handy then begin
                WebComboBox14.ItemIndex := 1;
                WebComboBox14Change(Self);
                WebButton90.Visible := false;
              end;

             Shop_Start_OK := true;
             WebTimer1.Enabled := true;
             WebPageControl8.TabIndex := 0;
             WebPanel9.Visible := true;
             WebTimer12.Enabled := false;
             exit;
           end;

    end;
  end;
  StartTimer.Enabled := true;

end;

procedure TForm2.InitHeader;
begin
//
end;



procedure TForm2.AllergieFilter1Click(Sender: TObject);
begin
  WebButton103Click(Self);
end;

procedure TForm2.Anmelden1Click(Sender: TObject);
begin
  WebButton11Click(Self);
end;

procedure TForm2.Chat1Click(Sender: TObject);
begin
  WebButton90Click(Self);
end;

procedure TForm2.Check_Angemeldet;
var hst : String;
begin
  if Akt_User_ID = 0 then begin
    AktKDName := '';
    WebButton23.Enabled := false;
    WebButton22.Enabled := false;
    WebButton23.Caption := '<i class="material-icons">euro</i> <span class="badge badge-danger badge-xs">' + 'Angemeldet: "'+AktKDName+'"<br>Weiter zur Kaufabwicklung' + '</span>';
    WebButton22.Caption := '<i class="material-icons">logout</i> <span class="badge badge-danger badge-xs">' + 'Angemeldet: "'+AktKDName+'"<br>Abmelden' + '</span>';
    WebButton29.Caption := '<i class="material-icons">login</i> <span class="badge badge-danger badge-xs">'+ 'Bereits Kunde? --> Anmelden'+'</span>';
    WebButton11.Caption := '<i class="material-icons">login</i> <span class="badge badge-danger badge-xs">' + 'Anmelden' + '</span>';
    WebButton106.Visible := false;

  end
  else begin
    WebButton23.Caption := '<i class="material-icons">euro</i> <span class="badge badge-danger badge-xs">' + 'Angemeldet: "'+AktKDName+'"<br>Weiter zur Kaufabwicklung' + '</span>';
    WebButton22.Caption := '<i class="material-icons">logout</i> <span class="badge badge-danger badge-xs">' + 'Angemeldet: "'+AktKDName+'"<br>Abmelden' + '</span>';
    WebButton29.Caption := '<i class="material-icons">login</i> <span class="badge badge-danger badge-xs">'+ 'Nicht "'+AktKDName+'"?<br>Bitte anmelden'+'</span>';
    if length(AktKDName) > 10 then begin
      hst := Copy(AktKDName,1,9) + '...';
    end
    else begin
      hst := AktKDName;
    end;
    WebButton11.Caption := '<i class="material-icons">logout</i> <span class="badge badge-danger badge-xs">' + hst+'<br>abmelden' + '</span>';


    WebButton23.Enabled := true;
    WebButton22.Enabled := true;

    Check_Best_Listen;

  end;
end;

procedure TForm2.Check_Best_Listen;
var UID : String;
    i,j,UKA,
    TNID : integer;


begin
  WebComboBox12.Items.Clear;
  WebButton106.Visible := false;
  for i := 0 to 30 do BestLA[i] := 0;

  DataModule1.BestListDataSet.Close;
  DataModule1.BestListDataSet.AfterOpen := Fuelle_Best_List_Combo;
  DataModule1.WebClientConnection9.Active := false;
  DataModule1.WebClientConnection9.URI := API2_URL + 'ApiService/myGetBestlisten?P1='+IntToStr(Akt_User_ID);
  DataModule1.WebClientConnection9.Active := true;
  DataModule1.BestListDataSet.Open;

end;


procedure TForm2.Fuelle_Best_List_Combo(DataSet: TDataSet);
begin
  Datamodule1.BestListDataSet.First;
  while not Datamodule1.BestListDataSet.EOF do begin
    WebComboBox12.Items.Add(Datamodule1.BestListDataSet.FieldByname('LBez').AsString);
    BestLA[WebComboBox12.Items.Count - 1] := Datamodule1.BestListDataSet.FieldByname('BID').AsInteger;
    Datamodule1.BestListDataSet.Next;
  end;
  if WebComboBox12.Items.Count > 0 then begin
    if Akt_Geraete_Art <> GA_Handy then begin
      WebButton106.Visible := true;
    end;
    WebComboBox12.ItemIndex := 0;
    WebComboBox12Change(Self);
  end;

end;


procedure TForm2.Check_Get_WK_ID;

var hst : String;


  procedure OnConnect;
  begin
    hst := My_Cookie;
    DataModule1.XDataWebClientAuth.RawInvoke('ILoginService.Get_WK_ID', [IntToStr(AktFirmaNo),hst],

        procedure(Response: TXDataClientResponse)
        var
          F: string;
        begin
          F := JS.ToString(JS.ToObject(Response.Result)['value']);
          Akt_WK_ID := StrToInt(F);
          if Akt_WK_ID = 0 then begin
            MessageDlg('Warenkorb konnte nicht initialisiert werden, bitte neu starten', mtError, [], nil);
            WebPageControl1.TabIndex := 15;
            WebPageControl1Change(Self);
            exit;
          end;
//          WebLabel70.Caption := 'WKID='+IntToStr(Akt_WK_ID);
          WebPanel9.Visible := true;
          WebPageControl1.TabIndex := 4;
          WebPageControl1Change(Self);
          Lese_Warenkorb;
          Update_Buttons;
        end,

        procedure(Error: TXDataClientError)
        begin
          MessageDlg('Keine Verbindung zum Server (Get_WK_ID)', mtError, [], nil);
        end
       );

  end;

  procedure OnError(Error: TXDataWebConnectionError);
  var hst : String;
  begin
    hst := 'Auth-Server Fehler (Get_WK_ID): '+Error.ErrorMessage + '. Bitte prüfen Sie Ihre Internet-Verbindung.';
    MessageDlg(hst, mtError, [], nil);
  end;




begin
  hst := My_Cookie;
  Datamodule1.XDataWebConnection2.Close;
  Datamodule1.XDataWebConnection2.Open(@OnConnect, @OnError);
end;


procedure TForm2.Lese_Warenkorb;
begin

//  Auth_URL := 'http://127.0.0.1:2003/kwshop/auth';
  Warenkorb_Gelesen := false;

  WK_Artikel := 0;
  DataModule1.VWarenkorbDataSet.Close;
  DataModule1.VWarenkorbDataSet.AfterOpen := Warenkorb_Fertig; // DataModule1.Shop_Init_2;
  DataModule1.WebClientConnection3.Active := false;
  DataModule1.WebClientConnection3.URI := Auth_URL + 'LoginService/MyGetWarenkorbArtikelBatch?NLK='+IntToStr(AktFirmaNo)+'&Token='+My_Cookie+'&Beiwert='+GetBeiwert;
  DataModule1.WebClientConnection3.Active := true;
  DataModule1.VWarenkorbDataSet.Open;
end;

procedure TForm2.Warenkorb_Fertig(DataSet: TDataSet);
begin
  Warenkorb_Gelesen := true;
  WK_Artikel := DataModule1.VWarenkorbDataSet.RecordCount;
  Update_Buttons;
  WebDBResponsiveGrid3.Datasource := DataModule1.VWarenkorbDataSource;

end;


procedure TForm2.VV_Fertig(DataSet: TDataSet);
var hst : String;
    i,j,k : integer;
begin
  for i := 1 to Max_VVA do begin
    VVA[i] := 0;
  end;
  VVA_Cnt := 0;
  DataModule1.ArtikelVVDataSet.First;
  while (VVA_Cnt < Max_VVA) and (not DataModule1.ArtikelVVDataSet.EOF) do begin
    VVA_Cnt := VVA_Cnt + 1;
    VVA[VVA_Cnt] := DataModule1.ArtikelVVDataSet.FieldByname('lfdno').AsInteger;
    hst := DataModule1.ArtikelVVDataSet.FieldByname('Beschreibung').AsString;
    WebComboBox4.Items.Add(HTML_Decode(hst));
    DataModule1.ArtikelVVDataSet.Next;
  end;
  if VV_Aktiv then begin
    if (Akt_Modus = Add_Modus) or (Akt_Modus = Add_Modus_Theke) then begin
      WebComboBox4.ItemIndex := 0;
      if WebComboBox4.Items.Count > 1 then begin
        WebComboBox4.Items[0] := '-----';
        exit;
      end;
    end
    else begin
      i := WebDBResponsiveGrid3.DataSource.DataSet.Fieldbyname('Zahl1').AsInteger;
      j := -1;
      for k := 1 to VVA_Cnt do begin
        if VVA[k] = i then begin
          j := k;
          break;
        end;
      end;
      if j = -1 then begin
        WebComboBox4.ItemIndex := 0;
      end
      else begin
        WebComboBox4.ItemIndex := j;
      end;
    end;
  end;
end;



function TForm2.GetBeiwert : String;
begin
  GetBeiwert := ' ';
end;


procedure TForm2.heken1Click(Sender: TObject);
begin
  WebButton17Click(Self);
end;

procedure TForm2.Check_VV_Verfuegbar;


var User_Name,
    Kennwort,hst : String;
    NLK : String;
    i,j,UKA: integer;



  procedure OnConnect;
  var VKSID : integer;
  begin
    VKSID := 0;
    DataModule1.XDataWebClientAuth.RawInvoke('ILoginService.Check_VV_Verfuegbar',[IntToStr(AktFirmaNo),My_Cookie,GetBeiWert,IntToStr(Akt_Artikel_ID),IntToStr(VKSID)],

        procedure(Response: TXDataClientResponse)
        var
          F: string;
          i : integer;
        begin
          F := JS.ToString(JS.ToObject(Response.Result)['value']);
          i := StrToInt(F);
          if i <= 0 then begin
            MessageDlg('Dieser Artikel ist zur Zeit leider ausverkauft. Versuchen Sie es zu einem späteren Zeitpunkt noch einmal', mtError, [], nil);
            exit;
          end
          else begin
            WebLabel72.Caption := 'noch ' + IntToStr(i) + ' Stück verfügbar';
            WebLabel72.Visible := true;
            DataModule1.ArtikelDataSet.Close;
            DataModule1.ArtikelDataSet.AfterOpen := Zeige_Artikel_Details;
            DataModule1.WebClientConnection8.Active := false;
            DataModule1.WebClientConnection8.URI := Auth_URL + 'LoginService/MyGetShopArtikel?P1='+IntToStr(Akt_Artikel_ID)+'&P2='+IntToStr(AktFirmaNo)+'&P3='+My_Cookie+'&P4='+GetBeiwert
              + '&P5='+IntToStr(AktKDID)+'&P6=0&P7=0&P8=0&P9=0'
              + '&P10=0&P11='+IntToStr(0)
              + '&P12='+IntToStr(0)+'&P13='+IntToStr(0)
              + '&P14='+' '+'&P15='+IntToStr(0)
              + '&P16='+IntToStr(0);
            DataModule1.WebClientConnection8.Active := true;
            DataModule1.ArtikelDataSet.Open;

{Artikelstamm
            Datamodule1.ArtikelDataSet.Close;
            Datamodule1.ArtikelDataSet.AfterOpen := Zeige_Artikel_Details;
            Datamodule1.ArtikelDataSet.QueryString :='$filter=ID eq '+IntToStr(Akt_Artikel_ID);
            Datamodule1.ArtikelDataSet.Load;
}
          end;
        end,

        procedure(Error: TXDataClientError)
        begin
          MessageDlg('Keine Verbindung zum Server (Check_VV_Verfuegbar)', mtError, [], nil);
        end
       );

  end;

  procedure OnError(Error: TXDataWebConnectionError);
  var hst : String;
  begin
    hst := 'Auth-Server Fehler (Check_VV_Verfuegbar): '+Error.ErrorMessage + '. Bitte prüfen Sie Ihre Internet-Verbindung.';
    MessageDlg(hst, mtError, [], nil);
  end;




begin
  if Akt_Modus in [Add_Modus,Add_Modus_Theke] then begin
    Datamodule1.XDataWebConnection2.Close;
    Datamodule1.XDataWebConnection2.Open(@OnConnect, @OnError);
  end
  else begin
    WebLabel72.Visible := false;
    DataModule1.ArtikelDataSet.Close;
    DataModule1.ArtikelDataSet.AfterOpen := Zeige_Artikel_Details;
    DataModule1.WebClientConnection8.Active := false;
    DataModule1.WebClientConnection8.URI := Auth_URL + 'LoginService/MyGetShopArtikel?P1='+IntToStr(Akt_Artikel_ID)+'&P2='+IntToStr(AktFirmaNo)+'&P3='+My_Cookie+'&P4='+GetBeiwert
      + '&P5='+IntToStr(AktKDID)+'&P6=0&P7=0&P8=0&P9=0'
      + '&P10=0&P11='+IntToStr(0)
      + '&P12='+IntToStr(0)+'&P13='+IntToStr(0)
      + '&P14='+' '+'&P15='+IntToStr(0)
      + '&P16='+IntToStr(0);
    DataModule1.WebClientConnection8.Active := true;
    DataModule1.ArtikelDataSet.Open;

{Artikelstamm
    Datamodule1.ArtikelDataSet.Close;
    Datamodule1.ArtikelDataSet.AfterOpen := Zeige_Artikel_Details;
    Datamodule1.ArtikelDataSet.QueryString :='$filter=ID eq '+IntToStr(Akt_Artikel_ID);
    Datamodule1.ArtikelDataSet.Load;
}
  end;

end;


procedure TForm2.Check_Bestand;


var User_Name,
    Kennwort,hst : String;
    NLK : String;
    i,j,UKA: integer;



  procedure OnConnect;
  var VKSID : integer;
  begin
    VKSID := 0;
    DataModule1.XDataWebClientAuth.RawInvoke('ILoginService.Check_Artikel_Bestand',[IntToStr(AktFirmaNo),My_Cookie,GetBeiWert,IntToStr(Akt_Artikel_ID),IntToStr(VKSID)],

        procedure(Response: TXDataClientResponse)
        var
          F: string;
          i : integer;
          r : double;

        begin
          F := JS.ToString(JS.ToObject(Response.Result)['value']);
          r := 0.0;
          i := 0;
          val(F,r,i);
          if i = 0 then begin
            if r <= 0.0 then begin
              MessageDlg('Dieser Artikel ist zur Zeit leider ausverkauft. Versuchen Sie es zu einem späteren Zeitpunkt noch einmal', mtError, [], nil);
              exit;
            end
            else begin
              WebLabel72.Caption := 'noch ' + Trim(RealStr(r,11,3,true)) + ' Stück verfügbar';
              WebLabel72.Visible := true;
              DataModule1.ArtikelDataSet.Close;
              DataModule1.ArtikelDataSet.AfterOpen := Zeige_Artikel_Details;
              DataModule1.WebClientConnection8.Active := false;
              DataModule1.WebClientConnection8.URI := Auth_URL + 'LoginService/MyGetShopArtikel?P1='+IntToStr(Akt_Artikel_ID)+'&P2='+IntToStr(AktFirmaNo)+'&P3='+My_Cookie+'&P4='+GetBeiwert
                + '&P5='+IntToStr(AktKDID)+'&P6=0&P7=0&P8=0&P9=0'
                + '&P10=0&P11='+IntToStr(0)
                + '&P12='+IntToStr(0)+'&P13='+IntToStr(0)
                + '&P14='+' '+'&P15='+IntToStr(0)
                + '&P16='+IntToStr(0);
              DataModule1.WebClientConnection8.Active := true;
              DataModule1.ArtikelDataSet.Open;

{Artikelstamm
              Datamodule1.ArtikelDataSet.Close;
              Datamodule1.ArtikelDataSet.AfterOpen := Zeige_Artikel_Details;
              Datamodule1.ArtikelDataSet.QueryString :='$filter=ID eq '+IntToStr(Akt_Artikel_ID);
              Datamodule1.ArtikelDataSet.Load;
}
            end;
          end
          else begin
            MessageDlg('Fehler bei Bestandsberechnung', mtError, [], nil);
            exit;
          end;
        end,

        procedure(Error: TXDataClientError)
        begin
          MessageDlg('Keine Verbindung zum Server (Check_Artikel_Bestand)', mtError, [], nil);
        end
       );

  end;

  procedure OnError(Error: TXDataWebConnectionError);
  var hst : String;
  begin
    hst := 'Auth-Server Fehler (Check_Artikel_Bestand): '+Error.ErrorMessage + '. Bitte prüfen Sie Ihre Internet-Verbindung.';
    MessageDlg(hst, mtError, [], nil);
  end;




begin
  if Akt_Modus in [Add_Modus,Add_Modus_Theke] then begin
    if Datamodule1.VSHOPArtikelDataSet.Fieldbyname('KZ_ANZEIGEBESTAND').AsInteger = 1 then begin
      Datamodule1.XDataWebConnection2.Close;
      Datamodule1.XDataWebConnection2.Open(@OnConnect, @OnError);
    end
    else begin
      WebLabel72.Visible := false;
      DataModule1.ArtikelDataSet.Close;
      DataModule1.ArtikelDataSet.AfterOpen := Zeige_Artikel_Details;
      DataModule1.WebClientConnection8.Active := false;
      DataModule1.WebClientConnection8.URI := Auth_URL + 'LoginService/MyGetShopArtikel?P1='+IntToStr(Akt_Artikel_ID)+'&P2='+IntToStr(AktFirmaNo)+'&P3='+My_Cookie+'&P4='+GetBeiwert
        + '&P5='+IntToStr(AktKDID)+'&P6=0&P7=0&P8=0&P9=0'
        + '&P10=0&P11='+IntToStr(0)
        + '&P12='+IntToStr(0)+'&P13='+IntToStr(0)
        + '&P14='+' '+'&P15='+IntToStr(0)
        + '&P16='+IntToStr(0);
      DataModule1.WebClientConnection8.Active := true;
      DataModule1.ArtikelDataSet.Open;

{Artikelstamm
      Datamodule1.ArtikelDataSet.Close;
      Datamodule1.ArtikelDataSet.AfterOpen := Zeige_Artikel_Details;
      Datamodule1.ArtikelDataSet.QueryString :='$filter=ID eq '+IntToStr(Akt_Artikel_ID);
      Datamodule1.ArtikelDataSet.Load;
}
    end;
  end
  else begin
    WebLabel72.Visible := false;
    DataModule1.ArtikelDataSet.Close;
    DataModule1.ArtikelDataSet.AfterOpen := Zeige_Artikel_Details;
    DataModule1.WebClientConnection8.Active := false;
    DataModule1.WebClientConnection8.URI := Auth_URL + 'LoginService/MyGetShopArtikel?P1='+IntToStr(Akt_Artikel_ID)+'&P2='+IntToStr(AktFirmaNo)+'&P3='+My_Cookie+'&P4='+GetBeiwert
      + '&P5='+IntToStr(AktKDID)+'&P6=0&P7=0&P8=0&P9=0'
      + '&P10=0&P11='+IntToStr(0)
      + '&P12='+IntToStr(0)+'&P13='+IntToStr(0)
      + '&P14='+' '+'&P15='+IntToStr(0)
      + '&P16='+IntToStr(0);
    DataModule1.WebClientConnection8.Active := true;
    DataModule1.ArtikelDataSet.Open;

{Artikelstamm
    Datamodule1.ArtikelDataSet.Close;
    Datamodule1.ArtikelDataSet.AfterOpen := Zeige_Artikel_Details;
    Datamodule1.ArtikelDataSet.QueryString :='$filter=ID eq '+IntToStr(Akt_Artikel_ID);
    Datamodule1.ArtikelDataSet.Load;
}
  end;

end;



procedure TForm2.Lese_Wort_Liste;

begin
  if Wort_Liste <> '' then begin
    Save_Text_Filter := WebEdit2.Text;
    Save_Tab_Index := WebPageControl1.TabIndex;
    WebPageControl1.TabIndex := 21;
    WebPageControl1Change(Self);
  end;
  DataModule1.XDataWebClientAuth.RawInvoke('ILoginService.Get_Wort_Liste', [IntToStr(AktFirmaNo),My_Cookie,GetBeiwert],
    procedure(Response: TXDataClientResponse)
    var
      i: integer;
      hst : String;
    begin
      hst := JS.ToString(JS.ToObject(Response.Result)['value']);
      Wort_Liste := hst;
      Save_Text_Filter := WebEdit2.Text;
      Save_Tab_Index := WebPageControl1.TabIndex;
      WebPageControl1.TabIndex := 21;
      WebPageControl1Change(Self);

    end,
    procedure(Error: TXDataClientError)
    begin
      MessageDlg('Keine Verbindung zum Server (Wortliste)', mtError, [], nil);
    end

    );

end;


procedure TForm2.Zeige_Bewertungen(DataSet: TDataSet);
begin
  WebDBGrid2.Datasource := Datamodule1.BewertungDatasource;
end;

procedure TForm2.Set_Display_Options;
var i : integer;
begin
  Weblabel70.Caption := 'Set_Display_Options';
//  Auth_URL := 'http://127.0.0.1:2003/kwshop/auth';

  DataModule1.KonfigDataSet.Close;
  DataModule1.KonfigDataSet.AfterOpen := Do_Display_Options;
  DataModule1.WebClientConnection6.Active := false;
  DataModule1.WebClientConnection6.URI := Auth_URL + 'LoginService/MyGetKonfig?P1='+IntToStr(0)+'&P2='+IntToStr(AktFirmaNo)+'&P3='+My_Cookie+'&P4='+GetBeiwert;
  DataModule1.WebClientConnection6.Active := true;
  DataModule1.KonfigDataSet.Open;




//
end;


procedure TForm2.Do_Display_Options(DataSet: TDataSet);
var hst : String;
    c : TColor;
    i : integer;
begin
  Weblabel70.Caption := 'Do_Display_Options';

  for i := 1 to 6 do begin
    Karten_Farben[i] := DataModule1.KonfigDataSet.FieldByname('Farbe'+IntToStr(i)).AsInteger;
  end;
  Karten_Groesse[1] := DataModule1.KonfigDataSet.FieldByname('Breite').AsInteger;
  Karten_Groesse[2] := DataModule1.KonfigDataSet.FieldByname('Hoehe').AsInteger;
  Karten_Groesse[3] := DataModule1.KonfigDataSet.FieldByname('Abstand').AsInteger;
  Karten_Groesse[4] := 0;
  Karten_Groesse[5] := 0;
  Karten_Groesse[6] := 0;

  c := clBlack;
//  hst := Uppercase(WebListBox2.Items[Akt_Theme]);
  hst := DataModule1.KonfigDataSet.FieldByname('ATheme').AsString;
  Weblabel70.Caption := 'Theme:'+hst;
  if Uppercase(hst) = 'DEFAULT BOOTSTRAP' then begin
    Application.RemoveCSS('themecss');
  end
  else begin
    Application.InsertCSS('themecss',hst+'/theme.css');
  end;
  if hst = 'MONOTONE' then begin
    c :=  clWhite;
  end;
  WebLabel77.Font.Color := c;
  WebLabel2.Font.Color := c;
  WebLabel1.Font.Color := c;
  WebLabel78.Font.Color := c;

  WebCheckBox1.Font.Color := c;
  WebCheckBox2.Font.Color := c;
  WebCheckBox3.Font.Color := c;
//  WebListBox1.Font.Color := c;
//  WebListBox1.Font.Size := 12;
  Set_Grid_Templates;

  StartPhase := 13;

{  if HMenu_Liste.Count = 0 then begin
    Lese_Menus;
  end;
}

end;

procedure TForm2.Set_Grid_Templates;
begin
  WebDBResponsiveGrid1.Options.ItemBorderColor := Karten_Farben[1];
  WebDBResponsiveGrid1.Options.ItemColor := Karten_Farben[2];
  WebDBResponsiveGrid1.Options.ItemSelectedColor := Karten_Farben[3];
  WebDBResponsiveGrid1.Options.ItemSelectedBorderColor := Karten_Farben[4];
  WebDBResponsiveGrid1.Options.ItemHoverColor := Karten_Farben[5];
  WebDBResponsiveGrid1.Options.ItemHoverBorderColor := Karten_Farben[6];

  case Akt_Item_Art of
    0 : begin
          if Device_ist_Handy then begin
            WebDBResponsiveGrid1.Options.ItemTemplate := '<font size="3" style="font-size: 10pt"><span><b>(%Bezeichnung%)</b></span></font></p>'
            + '<br><img class="imgitem" src="(%Bild%)">'
            +'<p><font size="1" style="font-size: 8pt"><span>(%Preis%)<span></span></font></p>';


            WebDBResponsiveGrid1.Options.ItemWidthMin := Karten_Groesse[1] div 2;
            WebDBResponsiveGrid1.Options.ItemHeight := Karten_Groesse[2] div 2;
          end
          else begin
            WebDBResponsiveGrid1.Options.ItemTemplate := '<font size="6" style="font-size: 14pt"><span><b>(%Bezeichnung%)</b></span></font></p>'
            + '<br><img class="imgitem" src="(%Bild%)">'
            +'<p><font size="3" style="font-size: 10pt"><span>(%Preis%)<span></span></font></p>';

            WebDBResponsiveGrid1.Options.ItemWidthMin := Karten_Groesse[1];

            WebDBResponsiveGrid1.Options.ItemHeight := Karten_Groesse[2];

          end;

        end;
    1 : begin
          if Device_ist_Handy then begin
            WebDBResponsiveGrid1.Options.ItemTemplate := '<font size="3" style="font-size: 10pt"><span><b>(%Bezeichnung%)</b></span></font>'
            + '<br><img class="imgitem" src="(%Bild%)" style="background:none">'

            + '<br>(%Bewertung%)'
            +'<br><font size="2" style="font-size: 8pt"><span>(%Preis%)<span></span></font>';

           WebDBResponsiveGrid1.Options.ItemWidthMin := Karten_Groesse[1] div 2;

            WebDBResponsiveGrid1.Options.ItemHeight := Karten_Groesse[2] div 2;
          end
          else begin
            WebDBResponsiveGrid1.Options.ItemTemplate := '<font size="6" style="font-size: 14pt"><span><b>(%Bezeichnung%)</b></span></font>'
            + '<br><img class="imgitem" src="(%Bild%)" style="background:none">'

            + '<br>(%Bewertung%)'
            +'<br><font size="3" style="font-size: 10pt"><span>(%Preis%)<span></span></font>';

           WebDBResponsiveGrid1.Options.ItemWidthMin := Karten_Groesse[1];

            WebDBResponsiveGrid1.Options.ItemHeight := Karten_Groesse[2];

          end;
        end;

  end;





  WebDBResponsiveGrid2.Options.ItemTemplate := '<img class="imgitem" src="(%Bild%)">';
  WebDBResponsiveGrid2.Options.ItemWidthMin := 66;
  WebDBResponsiveGrid2.Options.ItemHeight := 66;

  WebDBResponsiveGrid3.Options.ItemBorderColor := clSilver;

  WebDBResponsiveGrid3.Options.ItemTemplate :=
  '<br><button id="Del_btn(%lfdno%)">Löschen</button><span>             </span>'
  +'<button id="Edit_btn(%lfdno%)">Ändern</button>'

  + '<br><font size="6" style="font-size: 12pt"><span><b>(%Bezeichnung%)</b></span></font></p>'
  + '<br><img class="imgitem" src="(%Bild%)">'

  +'<p><font size="3" style="font-size: 10pt"><span>(%WKZ1%)</span></font>'
  +'<br><font size="3" style="font-size: 10pt"><span>(%WKZ2%)<span></span></font>'
  +'<br><font size="3" style="font-size: 10pt"><span>(%WKZ3%)<span></span></font></p>';

  WebDBResponsiveGrid3.Options.ItemWidthMin := 200;
  WebDBResponsiveGrid3.Options.ItemHeight := 380;


  WebDBResponsiveGrid4.Options.ItemBorderColor := Karten_Farben[1];
  WebDBResponsiveGrid4.Options.ItemColor := Karten_Farben[2];
  WebDBResponsiveGrid4.Options.ItemSelectedColor := Karten_Farben[3];
  WebDBResponsiveGrid4.Options.ItemSelectedBorderColor := Karten_Farben[4];
  WebDBResponsiveGrid4.Options.ItemHoverColor := Karten_Farben[5];
  WebDBResponsiveGrid4.Options.ItemHoverBorderColor := Karten_Farben[6];

  WebDBResponsiveGrid4.Options.ItemTemplate := '<font size="6" style="font-size: 14pt"><span><b>(%Bezeichnung%)</b></span></font></p>'
        + '<br><img class="imgitem" src="(%Kioskbild%)">';

  WebDBResponsiveGrid4.Options.ItemWidthMin := Karten_Groesse[1];

  WebDBResponsiveGrid4.Options.ItemHeight := Karten_Groesse[2];



end;

procedure TForm2.Lese_Menus;
begin
  exit;

  Datamodule1.MenuDataSet.Close;
  Datamodule1.MenuDataSet.AfterOpen := Mache_Menu_Baum;
  Datamodule1.WebClientConnection7.Active := false;
  Datamodule1.WebClientConnection7.URI := Auth_URL + 'LoginService/MyGetMenu?P1='+IntToStr(0)+'&P2='+IntToStr(AktFirmaNo)+'&P3='+My_Cookie+'&P4='+GetBeiwert;
  Datamodule1.WebClientConnection7.Active := true;
  Datamodule1.MenuDataSet.Open;


end;



procedure TForm2.Mache_Menu_Baum(DataSet: TDataSet);
var m : PMenu_Rec;
    i,j,l : integer;
    tn : array[0 .. 10] of TTreeNode;
    b : TButton;
begin
  j := 0;
  l := 5;
  Datamodule1.MenuDataSet.First;
  while not Datamodule1.MenuDataSet.EOF do begin
    new(m);
    m^.ID := Datamodule1.MenuDataSet.FieldByname('ID').AsInteger;
    m^.PageID := Datamodule1.MenuDataSet.FieldByname('PageID').AsInteger;
    m^.Bez := Datamodule1.MenuDataSet.FieldByname('Bezeichnung').AsString;
    m^.Level := Datamodule1.MenuDataSet.FieldByname('TLevel').AsInteger;
    m^.Parent := Datamodule1.MenuDataSet.FieldByname('Parent').AsInteger;
    HMenu_Liste.Add(m);
    if m.Level = 1 then begin
      b := TButton.Create(self);
      j := j + 1;
      b.Name := 'MB_'+IntToStr(j);
      b.Parent := WebPanel36;
      b.Height := 40;
      b.Width := 150;
      b.Caption := m^.Bez;
      b.Top := 5;
      b.Left := l;
      l := l + b.width + 10;
      b.ElementClassName := 'btn btn-primary';
      b.OnMouseEnter := MB_MouseEnter;
      b.OnMouseLeave := MB_MouseLeave;
      b.Tag := m^.ID;
    end;
    Datamodule1.MenuDataSet.Next;
  end;
  for i := 0 to 10 do begin
    tn[i] := nil;
  end;
  webtreeview1.BeginUpdate;
  for i := 0 to HMenu_Liste.Count - 1 do begin
    m := PMenu_Rec(HMenu_Liste[i]);
    if m^.Level = 0 then begin
      tn[m^.Level] := webtreeview1.Items.Add(nil,'<font color="#E5413C" style="font-size:10pt">&#x25FC;</font>'+HTML_Decode(m^.Bez));
      tn[m^.Level].Tag := m^.ID;
      Menu_Root_ID := m^.ID;
      Menu_Root := tn[m^.Level];
    end
    else begin
      tn[m^.Level] := webtreeview1.Items.AddChild(tn[m^.Level-1],'<font color="#E5413C" style="font-size:10pt">&#x25FC;</font>'+HTML_Decode(m^.Bez));
      tn[m^.Level].Tag := m^.ID;
    end;
  end;
  webtreeview1.EndUpdate;
  WebTreeView1.Selected := tn[0];
  webtreeview1.Selected.Expand(false);
  WebTreeView1Click(Self);
  StartPhase := 12;
end;


procedure TForm2.MB_MouseEnter(Sender: TObject);
var i,j,ID : integer;
begin
  Akt_Menu := TButton(Sender).Tag;
  MB_Panel.Top := WebPanel36.Top + TButton(Sender).Top + TButton(Sender).Height;
  MB_Panel.Left := 0; // TWebButton(Sender).Left;
  MB_Panel.Width := Width;
  MB_Panel.Height := WebPageControl1.Height - WebPanel6.Height;
  WebTimer7.Enabled := true;

  exit;
  ID := TButton(Sender).Tag;


//
end;

procedure TForm2.MB_MouseLeave(Sender: TObject);
begin
  WebTimer6.Enabled := true;
//  MB_Panel.Visible := false;
//
end;


procedure TForm2.MB_PanelMouseEnter(Sender: TObject);
begin
  Bin_im_Menu := true;
  WebTimer6.Enabled := false;
end;

procedure TForm2.MB_PanelMouseLeave(Sender: TObject);
begin
  MB_Panel.Visible := false;
  WebTimer6.Enabled := false;
  Bin_im_Menu := false;

end;


procedure TForm2.Loesche_Menu_Labels;
var i : integer;
begin
  for i := 0 to Menu_Labels.Count - 1 do begin
    PML_Rec(Menu_Labels[i]).L.Free;
    PML_Rec(Menu_Labels[i]).L := nil;
  end;
  Menu_Labels.Clear;

end;


procedure TForm2.MB_Click(Sender: TObject);
begin
  if VB_Vorschlag <> 0 then begin
    WebPanel36.Visible := false;
    WebPanel9.Visible := false;
    WebPanel7.Visible := false;
    WebButton87.Visible := true;
    WebButton91.Visible := true;

    WebLabel93.Caption := VB_Bem;//TWebLabel(Sender).Caption;
    Akt_Auswahl_Menu := VB_Vorschlag;
//    VB_Vorschlag := 0;
    WebPanel42.Width := 600;
    WebPanel42.Visible := true;
    WebPanel43.Visible := true;
    MB_Panel.Visible := false;
    WebRichEdit9.Lines.Loadfromfile('Menutxt/Minus_'+IntToStr(abs(Akt_Auswahl_Menu))+'.html');
    Refresh_ShopArtikel(0);

  end
  else begin
    WebPanel36.Visible := false;
    WebPanel9.Visible := false;
    WebPanel7.Visible := false;
    WebButton87.Visible := true;
    WebButton91.Visible := true;

    WebLabel93.Caption := TLabel(Sender).Caption;
    Akt_Auswahl_Menu := TLabel(Sender).Tag;
    WebPanel42.Width := 600;
    WebPanel42.Visible := true;
    WebPanel43.Visible := true;
    MB_Panel.Visible := false;
    WebRichEdit9.Lines.Loadfromfile('Menutxt/Art2_'+IntToStr(Akt_Auswahl_Menu)+'.html');
    Refresh_ShopArtikel(0);
  end;
end;


procedure TForm2.Refresh_Bestell_Liste;
begin
  WebStringGrid5.RowCount := 1;
  WebStringGrid5.Cells[0,0] := 'Art-Nr';
  WebStringGrid5.Cells[1,0] := 'Bezeichnung';
  WebStringGrid5.Cells[2,0] := 'Preis';
  WebStringGrid5.Cells[3,0] := 'Preis-Einheit';

  WebStringGrid5.Cells[4,0] := 'Best-Menge(kg)';
  WebStringGrid5.Cells[5,0] := 'Best-Menge(Stück)';
  Bestell_Liste.Clear;

  DataModule1.BestlistArtDataSet.Close;
  DataModule1.BestlistArtDataSet.AfterOpen := Fuelle_Best_Liste;
  DataModule1.WebClientConnection10.Active := false;
  DataModule1.WebClientConnection10.URI := API2_URL + 'ApiService/myGetBestlistenArtikel?P1='+IntToStr(Akt_User_ID)+'&P2='+IntToStr(BestLA[WebComboBox12.ItemIndex])+'&P3='+IntToStr(WebComboBox13.ItemIndex);
  DataModule1.WebClientConnection10.Active := true;
  DataModule1.BestlistArtDataSet.Open;
end;

procedure TForm2.Fuelle_Best_Liste(DataSet: TDataSet);
begin

  DataModule1.BestlistArtDataSet.First;
  while not DataModule1.BestlistArtDataSet.EOF do begin
    WebStringGrid5.RowCount := WebStringGrid5.RowCount + 1;
    WebStringGrid5.Cells[0,WebStringGrid5.RowCount-1] := DataModule1.BestlistArtDataSet.FieldByName('a_Nummer').AsString;
    WebStringGrid5.Cells[1,WebStringGrid5.RowCount-1] := DataModule1.BestlistArtDataSet.FieldByName('a_Bezeichnung').AsString;
    WebStringGrid5.Cells[2,WebStringGrid5.RowCount-1] := RealStr(DataModule1.BestlistArtDataSet.FieldByName('a_Preis').AsInteger/100,12,2,true);
    WebStringGrid5.Cells[3,WebStringGrid5.RowCount-1] := DataModule1.BestlistArtDataSet.FieldByName('a_ME').AsString;
    Bestell_Liste.Add(DataModule1.BestlistArtDataSet.FieldByName('a_ID').AsString);

    WebStringGrid5.TopRow := WebStringGrid5.RowCount-1;
    DataModule1.BestlistArtDataSet.Next;
  end;
  if not DataModule1.XDataWebConnection2.Connected then begin
    DataModule1.XDataWebConnection2.Connected := true;
  end;
end;

procedure TForm2.Close_Konto_Menu;
var i : integer;
begin
  WebPanel51.Visible := false;
  Akt_Konto_Menu := 0;
  for i := 1 to 10 do begin
    if Konto_Buttons[i] <> nil then begin
      Konto_Buttons[i].Free;
      Konto_Buttons[i] := nil;
    end;
  end;
  WebButton53.Top := 92;
  WebButton54.Top := 152;
end;


procedure TForm2.Mache_Konto_Menu;

var i,j,k : integer;


procedure Do_It(c : String;var Nr,T : integer);
var b : TButton;
begin
  Nr := Nr + 1;
  b := TButton.Create(self);
  b.Name := 'KBtn_'+IntToStr(Nr);
  b.Parent := WebPanel51;
  b.Caption := c;
  b.Left := 10;
  b.Top := T;
  b.Tag := Nr;
  b.Height := 35;
  b.Width := 220;

  b.OnClick := KMB_Click;
  b.ElementClassName := 'btn btn-secondary';
  b.ElementFont := efCSS;
  Konto_Buttons[Nr] := b;
  T := T + b.Height + 10;
end;

begin
  i := 0;
  j := 20;

  case Akt_Konto_Menu of
    1 : begin
          Do_It('<i class="material-icons">contact_mail</i> <span class="badge badge-danger badge-xs">'+ 'Rechnungs-Adresse'+'</span>',i,j);
          Do_It('<i class="material-icons">local_shipping</i> <span class="badge badge-danger badge-xs">'+ 'Liefer-Adresse'+'</span>',i,j);
          Do_It('<i class="material-icons">alternate_email</i> <span class="badge badge-danger badge-xs">'+ 'eMail-Adresse'+'</span>',i,j);
          Do_It('<i class="material-icons">call</i> <span class="badge badge-danger badge-xs">'+ 'Telefon-Nummern'+'</span>',i,j);
          Do_It('<i class="material-icons">password</i> <span class="badge badge-danger badge-xs">'+ 'Kennwort ändern'+'</span>',i,j);
          k := 6 * (35 + 10);
          WebButton53.Top := WebButton53.Top + k;
          WebButton54.Top := WebButton54.Top + k;
          WebPanel51.Left := WebButton25.Left + 20;
          WebPanel51.Top := WebButton25.Top + WebButton25.Height + 10;
          WebPanel51.Height := k;
          WebPanel51.Width := 240;
          WebPanel51.ElementClassname := 'bg-primary';
          WebPanel51.Visible := true;
        end;
    2 : begin
          Do_It('<i class="material-icons">pending_actions</i> <span class="badge badge-danger badge-xs">'+ 'Offene Bestellungen'+'</span>',i,j);
          Do_It('<i class="material-icons">event_available</i> <span class="badge badge-danger badge-xs">'+ 'Erledigte Bestellungen'+'</span>',i,j);
          k := 3 * (35 + 10);
          WebButton54.Top := WebButton54.Top + k;
          WebPanel51.Left := WebButton25.Left + 20;
          WebPanel51.Top := WebButton53.Top + WebButton25.Height + 10;
          WebPanel51.Height := k;
          WebPanel51.Width := 240;
          WebPanel51.ElementClassname := 'bg-primary';
          WebPanel51.Visible := true;

        end;
    3 : begin
          Do_It('<i class="material-icons">pending_actions</i> <span class="badge badge-danger badge-xs">'+ 'Offene Bestellungen'+'</span>',i,j);
          Do_It('<i class="material-icons">event_available</i> <span class="badge badge-danger badge-xs">'+ 'Erledigte Bestellungen'+'</span>',i,j);
          k := 3 * (35 + 10);
          WebButton54.Top := WebButton54.Top + k;
          WebPanel51.Left := WebButton25.Left + 20;
          WebPanel51.Top := WebButton53.Top + WebButton25.Height + 10;
          WebPanel51.Height := k;
          WebPanel51.Width := 240;
          WebPanel51.ElementClassname := 'bg-primary';
          WebPanel51.Visible := true;
        end;



  end;

end;


procedure TForm2.KMB_Click(Sender : TObject);
var i : integer;
begin
  i := TButton(Sender).Tag;
  case Akt_Konto_Menu of
    1 : begin
          case i of
            1 : begin
                  Open_Standard_Query(1,'Get_RechAdr','ID;'+IntToStr(Akt_User_ID)+';',@Zeige_Rechnungs_Adresse,GetBeiwert);

{                  DataModule1.RechAdrDataSet.Close;
                  DataModule1.RechAdrDataSet.AfterOpen := Zeige_Rechnungs_Adresse;
                  DataModule1.WebClientConnection11.Active := false;
                  DataModule1.WebClientConnection11.URI := API2_URL + 'ApiService/myGetKundensatz?P1='+IntToStr(AktFirmaNo)+'&P2='+My_Cookie+'&P3='+IntToStr(Akt_User_ID)+'&P4='+GetBeiwert;
                  DataModule1.WebClientConnection11.Active := true;
                  DataModule1.RechAdrDataSet.Open;
}
                end;
            2 : begin
                  Open_Standard_Query(1,'Get_LiefAdr','KDID;'+IntToStr(Akt_User_ID)+';'
                    + 'lfdno;1;',@Zeige_Liefer_Adresse,GetBeiwert);

{                  In_Konto_Menu := true;
                  WebPanel9.Visible := false;
                  WebPageControl1.TabIndex := 5;
                  WebPageControl4.TabIndex := 1;
                  WebPageControl4Change(Self);
}
                end;
            3 : begin
                  WebEdit33.Text := AktKDName;
                  WebEdit34.Text := '';
                  WebEdit35.Text := '';
                  WebPanel9.Visible := false;
                  WebPageControl1.TabIndex := 24;
                  WebPageControl1Change(Self);
                end;
            4 : begin
                  Open_Standard_Query(1,'Get_Telefon','ID;'+IntToStr(Akt_User_ID)+';',@Zeige_Telefon,GetBeiwert);

                  WebEdit33.Text := AktKDName;
                  WebEdit37.Text := '';
                  WebEdit36.Text := '';
                  WebPanel9.Visible := false;
                  WebPageControl1.TabIndex := 25;
                  WebPageControl1Change(Self);
                end;
            5 : begin
                  WebEdit38.Text := '';
                  WebEdit39.Text := '';
                  WebEdit40.Text := '';
                  WebPanel9.Visible := false;
                  WebPageControl1.TabIndex := 26;
                  WebPageControl1Change(Self);
                end;

          end;
        end;
  end;

end;


procedure TForm2.Zeige_Rechnungs_Adresse(DataSet: TDataSet);
begin
  edName.Text := SFBN('Get_RechAdr','Name').AsString;
  edAddress1.Text := SFBN('Get_RechAdr','Name2').AsString;
  edAddress2.Text := SFBN('Get_RechAdr','Strasse').AsString;
  edPostalCode.Text := SFBN('Get_RechAdr','PLZ').AsString;
  edCity.Text := SFBN('Get_RechAdr','Ort').AsString;
  WebComboBox1.ItemIndex := SFBN('Get_RechAdr','Land').AsInteger;
  In_Konto_Menu := true;
  WebPanel9.Visible := false;
  WebPageControl1.TabIndex := 5;
  WebPageControl1Change(Self);
  WebPageControl4.TabIndex := 0;
  WebPageControl4Change(Self);

//  edName.Text := DataModule1.RechAdrDataSet.Fieldbyname('Name').AsString;
//  edName.Text := DataModule1.RechAdrDataSet.Fieldbyname('Name').AsString;

end;


procedure TForm2.Zeige_Liefer_Adresse(DataSet: TDataSet);
begin
  WebEdit1.Text := SFBN('Get_LiefAdr','Name').AsString;
  WebEdit6.Text := SFBN('Get_LiefAdr','Name2').AsString;
  WebEdit7.Text := SFBN('Get_LiefAdr','Strasse').AsString;
  WebEdit8.Text := SFBN('Get_LiefAdr','PLZ').AsString;
  WebEdit9.Text := SFBN('Get_LiefAdr','Ort').AsString;
  WebComboBox2.ItemIndex := SFBN('Get_LiefAdr','Land').AsInteger;

  In_Konto_Menu := true;
  WebPanel9.Visible := false;
  WebPageControl1.TabIndex := 5;
  WebPageControl1Change(Self);
  WebPageControl4.TabIndex := 1;
  WebPageControl4Change(Self);


end;


procedure TForm2.Zeige_Telefon(DataSet: TDataSet);
begin
  WebEdit37.Text := SFBN('Get_Telefon','Telefon').AsString;
  WebEdit36.Text := SFBN('Get_Telefon','Handy').AsString;
  WebPageControl1.TabIndex := 25;
  WebPageControl1Change(Self);


end;




procedure TForm2.Speichern_Rechnungs_Adresse(DataSet: TDataSet);
var i : integer;
begin
  i := SFBN('Put_RechAdr','Ergebnis').AsInteger;
  if i = 1 then begin
    WebPageControl1.TabIndex := 2;
    WebPageControl1Change(Self);
  end
  else begin

  end;
end;


procedure TForm2.Speichern_Liefer_Adresse(DataSet: TDataSet);
var i : integer;
begin
  i := SFBN('Put_LiefAdr','Ergebnis').AsInteger;
  if i = 1 then begin
    WebPageControl1.TabIndex := 2;
    WebPageControl1Change(Self);
  end
  else begin

  end;
end;



procedure TForm2.Speichern_EMail_Adresse(DataSet: TDataSet);
var i : integer;
begin
  i := SFBN('Put_EMailAdr','Ergebnis').AsInteger;

  if i = 1 then begin
    AktKDName := Trim(WebEdit34.Text);
    WebPageControl1.TabIndex := 2;
    WebPageControl1Change(Self);
  end
  else begin

  end;


end;


procedure TForm2.Speichern_Telefon(DataSet: TDataSet);
var i : integer;
begin
  i := SFBN('Put_Telefon','Ergebnis').AsInteger;
  if i = 1 then begin
    WebPageControl1.TabIndex := 2;
    WebPageControl1Change(Self);
  end
  else begin

  end;


end;


procedure TForm2.Speichern_Password(DataSet: TDataSet);
var i : integer;
begin
  i := SFBN('Put_Password','Ergebnis').AsInteger;
  if i = 1 then begin
    WebPageControl1.TabIndex := 2;
    WebPageControl1Change(Self);
  end
  else begin
    MessageDlg('Altes Kennwort ungültig oder keine Verbindung zum Server', mtError, [], nil);
  end;


end;


procedure TForm2.Zeige_Naehrwerte(DataSet: TDataSet);
begin
  WebEdit41.Text := Trim(RealStr(SFBN('Get_NW','KJoule').AsInteger/100,10,2,true));
  WebEdit42.Text := Trim(RealStr(SFBN('Get_NW','KCal').AsInteger/100,10,2,true));
  WebEdit43.Text := Trim(RealStr(SFBN('Get_NW','Eiweiss').AsInteger/100,10,2,true));
  WebEdit44.Text := Trim(RealStr(SFBN('Get_NW','Salz').AsInteger/100,10,2,true));
  WebEdit45.Text := Trim(RealStr(SFBN('Get_NW','Kohlenhydrate').AsInteger/100,10,2,true));
  WebEdit46.Text := Trim(RealStr(SFBN('Get_NW','Zucker').AsInteger/100,10,2,true));
  WebEdit47.Text := Trim(RealStr(SFBN('Get_NW','Fett').AsInteger/100,10,2,true));
  WebEdit48.Text := Trim(RealStr(SFBN('Get_NW','Fettgesaettigt').AsInteger/100,10,2,true));

  WebLabel121.Caption := SFBN('Get_NW','Allergene').AsString;



  WebPageControl2.TabIndex := 2;
  WebPageControl2Change(Self);

end;


procedure TForm2.Zeige_Zutaten(DataSet: TDataSet);
begin
  WebRichEdit8.Lines.Clear;
  WebRichEdit8.Lines.Add(SFBN('Get_Zutaten','ZText').AsString);

  WebPageControl2.TabIndex := 1;
  WebPageControl2Change(Self);
end;

procedure TForm2.PlayRecording(ABase: string);
var ts : TStringList;
begin

  WebMultimediaPlayer1.URL := 'data:audio/wav;base64,' + ABase;
//  WebMultimediaPlayer1.Play;
  ts := TStringList.Create;
  ts.Add('{');
  ts.Add('"config": {');
//  ts.Add('    "encoding":"FLAC",');
//  ts.Add('    "encoding":"MULAW",');
//  ts.Add('    "sampleRateHertz": 8000,');
  ts.Add('    "languageCode": "de-DE",');
  ts.Add('    "enableWordTimeOffsets": false');
  ts.Add('},');
  ts.Add('"audio": {');
//  ts.Add('    "uri":"gs://cloud-samples-tests/speech/brooklyn.flac"');
//  ts.Add('    "uri":"data:audio/wav;base64,'+ABase+'"');
  ts.Add('    "content": "'+ABase+'"');

  ts.Add('}');
  ts.Add('}');

  SpeechAPI_Url := 'https://speech.googleapis.com/v1p1beta1/speech:recognize?key=AIzaSyBLYIMaTxUtgyLynD_ejCK7E8vxZmTbFzA';
  WebHttpRequest2.URL := SpeechAPI_Url;
  WebHttpRequest2.PostData := ts.Text;
  WebHttpRequest2.Execute;

  ts.Free;
end;

procedure TForm2.Prospekte1Click(Sender: TObject);
begin
  WebButton56Click(Self);
end;

procedure TForm2.LieferartenFilter1Click(Sender: TObject);
begin
  WebButton105Click(Self);
end;

procedure TForm2.Lies_Vor(hst : String);
var ts : TStringList;
    hst2 : String;
begin
  if not LautSprecher_ein then exit;
  hst2 := Trim(hst);
  if hst2 = '' then exit;

  ts := TStringList.Create;
  ts.Add('{');

  ts.Add('  "audioConfig": {');
  ts.Add('    "audioEncoding": "MP3",');
  ts.Add('    "pitch": 0,');
  ts.Add('    "speakingRate": 1.2');
  ts.Add('  },');
  ts.Add('  "input": {');
  ts.Add('    "text": "'+hst2+'"');
  ts.Add('  },');
  ts.Add('  "voice": {');
  ts.Add('    "languageCode": "de-DE",');
  ts.Add('    "name": "de-DE-Wavenet-F"');
  ts.Add('  }');
  ts.Add('}');

  TextToSpeechAPI_Url := 'https://texttospeech.googleapis.com/v1beta1/text:synthesize?key=AIzaSyBLYIMaTxUtgyLynD_ejCK7E8vxZmTbFzA';
  WebHttpRequest3.URL := TextToSpeechAPI_Url;
  WebHttpRequest3.PostData := ts.Text;
  WebHttpRequest3.Execute;

  ts.Free;

end;

procedure TForm2.MeinKonto1Click(Sender: TObject);
begin
  WebButton4Click(Self);
end;

procedure TForm2.Meldungs_Fenster(st,st2 : String;Art : integer;Y_Proc,N_Proc,C_Proc : TNotifyEvent);
begin
  WebLabel129.Caption := st;
  WebLabel130.Caption := st2;
  case Art of
    0 : begin
          WebButton129.Caption := '<i class="material-icons">done</i> <span class="badge badge-danger badge-xs">OK</span>';
          WebButton130.Visible := false;
          WebButton131.Visible := false;

        end;
    1 : begin
          WebButton129.Caption := '<i class="material-icons">done</i> <span class="badge badge-danger badge-xs">Ja</span>';
          WebButton130.Caption := '<i class="material-icons">not_interested</i> <span class="badge badge-danger badge-xs">Nein</span>';
          WebButton131.Caption := '<i class="material-icons">close</i> <span class="badge badge-danger badge-xs">Abbrechen</span>';
          WebButton130.Visible := true;
          WebButton131.Visible := true;
        end;
  end;
  Yes_Procedure := Y_Proc;
  No_Procedure := N_Proc;
  Cancel_Procedure := C_Proc;
  WebPanel57.Visible := false;
  Save_Tab_Index2 := WebPageControl1.TabIndex;
  WebPageControl1.TabIndex := 28;
  WebPageControl1Change(Self);
  WebPanel57.Visible := false;

  Frage_Ergebnis := -1;
  WebTimer11.Enabled := true;
end;

procedure TForm2.Resize_Panel_9;
begin
  case Akt_Geraete_Art of
    GA_Desktop : begin
                 WebButton2.Left := 32;
                 WebButton2.Top := 3;
                 WebButton6.Left := 168;
                 WebButton6.Top := 3;
                 WebButton17.Left := 304;
                 WebButton17.Top := 3;
                 WebButton56.Left := 440;
                 WebButton56.Top := 3;
                 WebButton4.Left := 576;
                 WebButton4.Top := 3;
                 WebButton3.Left := 713;
                 WebButton3.Top := 3;
                 WebButton5.Left := 845;
                 WebButton5.Top := 3;
                 WebButton11.Left := 985;
                 WebButton11.Top := 3;
                 WebButton90.Left := 1121;
                 WebButton90.Top := 3;
                 WebButton106.Left := 1258;
                 WebButton106.Top := 3;

                 WebButton2.Caption := '<i class="material-icons">home</i> <span class="badge badge-danger badge-xs">' + 'Startseite' + '</span>';
                 WebButton6.Caption := '<i class="material-icons">apps</i> <span class="badge badge-danger badge-xs">' + 'Artikel' + '</span>';
                 WebButton3.Caption := '<i class="material-icons">shopping_cart</i> <span class="badge badge-danger badge-xs">' + IntToStr(WK_Artikel) + ' Artikel'+'</span>';
                 WebButton5.Caption := '<i class="material-icons">euro_symbol</i> <span class="badge badge-danger badge-xs">' + IntToStr(WK_Artikel) + ' Kasse</span>';
                 WebButton137.Visible := false;

               end;

    GA_Handy : begin
                 WebButton2.Left := 32;
                 WebButton2.Top := 3;
                 WebButton6.Left := 168;
                 WebButton6.Top := 3;
                 WebButton17.Left := 304;
                 WebButton17.Top := 3;
                 WebButton17.Visible := false;

                 WebButton56.Left := 440;
                 WebButton56.Top := 3;
                 WebButton56.Visible := false;

                 WebButton4.Left := 576;
                 WebButton4.Top := 3;
                 WebButton4.Visible := false;

                 WebButton3.Left := 713;
                 WebButton3.Top := 3;
                 WebButton3.Left := WebButton17.Left;

                 WebButton5.Left := 845;
                 WebButton5.Top := 3;
                 WebButton5.Left := WebButton56.Left;


                 WebButton11.Left := 985;
                 WebButton11.Top := 3;
                 WebButton11.Visible := false;

                 WebButton90.Left := 1121;
                 WebButton90.Top := 3;
                 WebButton90.Visible := false;

                 WebButton106.Left := 1258;
                 WebButton106.Top := 3;

                 WebButton2.Caption := '<i class="material-icons">home</i> <span class="badge badge-danger badge-xs">' + '' + '</span>';
                 WebButton2.Width := 55;
                 WebButton2.Left := 5;
                 WebButton6.Caption := '<i class="material-icons">apps</i> <span class="badge badge-danger badge-xs">' + '' + '</span>';
                 WebButton6.Width := 55;
                 WebButton6.Left := 65;
                 WebButton3.Caption := '<i class="material-icons">shopping_cart</i> <span class="badge badge-danger badge-xs">' + IntToStr(WK_Artikel) + ''+'</span>';
                 WebButton3.Left := 125;

                 WebButton5.Caption := '<i class="material-icons">euro_symbol</i> <span class="badge badge-danger badge-xs">' + IntToStr(WK_Artikel) + '</span>';
                 WebButton3.Width := 65;
                 WebButton5.Width := 65;
                 WebButton5.Left := 195;
                 WebButton137.Caption := '<i class="material-icons">menu</i> <span class="badge badge-danger badge-xs"></span>';
                 WebButton137.Top := 3;
                 WebButton137.Width := 55;
                 WebButton137.Left := 265;
                 WebButton137.Height := Webbutton2.Height;
                 WebButton137.Visible := true;
                 WebPanel36.Visible := false;

                 WebButton71.Width := 100;
                 WebButton71.Left := 5;

                 WebButton72.Width := 100;
                 WebButton72.Left := 110;

                 WebButton73.Width := 100;
                 WebButton73.Left := 215;

                 WebPanel30.Visible := false;

                 WebButton57.Width := 100;
                 WebButton57.Left := 5;

                 WebButton58.Width := 100;
                 WebButton58.Left := 110;

                 WebButton59.Width := 100;
                 WebButton59.Left := 215;

                 WebButton62.Width := 100;
                 WebButton62.Left := 5;

                 WebButton63.Width := 100;
                 WebButton63.Left := 110;

                 WebButton64.Width := 100;
                 WebButton64.Left := 215;

                 WebButton66.Width := 100;
                 WebButton66.Left := 5;

                 WebButton67.Width := 100;
                 WebButton67.Left := 110;

                 WebButton68.Width := 100;
                 WebButton68.Left := 215;

                 WebPanel7.Width := 320;
                 WebListBox1.Visible := false;
                 WebComboBox3.Width := (WebPanel7.Width Div 2) - 10;
                 WebComboBox3.Left := 5;

                 WebComboBox15.Left := WebComboBox3.Left + WebComboBox3.Width + 5;
                 WebComboBox15.Top := WebComboBox3.Top;
                 WebComboBox15.Width := WebComboBox3.Width;
                 WebComboBox15.Height := WebComboBox3.Height;

                 WebComboBox15.Visible := true;
//                 WebPanel8.Height := WebPanel8.Height + WebComboBox3.Height + 5;
                 WebPageControl7.Parent := WebPanel7;

                 WebButton94.Visible := false;
                 WebButton95.Visible := false;
                 WebButton96.Visible := false;
                 WebButton97.Visible := false;
                 WebButton98.Visible := false;
                 WebButton99.Visible := false;
                 WebComboBox16.Left := WebButton94.Left;
                 WebComboBox16.Top := WebButton94.Top;
                 WebComboBox16.Visible := true;
                 Webpanel33.Height := WebComboBox16.Top + WebComboBox16.Height + 10;

                 WebImageControl11.Left := WebImageControl11.Left + WebPanel10.Width;
                 WebLabel72.Left := WebLabel72.Left + WebPanel10.Width;
                 WebLabel31.Left := WebLabel31.Left + WebPanel10.Width;
                 WebLabel32.Left := WebLabel32.Left + WebPanel10.Width;
                 WebLabel29.Left := WebLabel29.Left + WebPanel10.Width;
                 WebLabel30.Left := WebLabel30.Left + WebPanel10.Width;
                 WebEdit5.Left := WebEdit5.Left + WebPanel10.Width;
                 WebComboBox4.Left := WebComboBox4.Left + WebPanel10.Width;
                 WebComboBox5.Left := WebComboBox5.Left + WebPanel10.Width;
                 WebComboBox6.Left := WebComboBox6.Left + WebPanel10.Width;

                 WebPanel4.Parent := WebPageControl2Sheet1;
                 WebPanel44.Align := alBottom;
                 WebPanel44.Height := 350;
                 WebPanel44.Parent := self;
               end;

 end;

end;



procedure TForm2.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebPanel6 := TPanel.Create(Self);
  WebImageControl12 := TImageControl.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebLabel7 := TLabel.Create(Self);
  WebLabel24 := TLabel.Create(Self);
  WebLabel25 := TLabel.Create(Self);
  WebPageControl8 := TPageControl.Create(Self);
  WebPageControl8Sheet1 := TTabSheet.Create(Self);
  WebPageControl1 := TPageControl.Create(Self);
  WebPageControl1Sheet1 := TTabSheet.Create(Self);
  WebPanel3 := TPanel.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebLabel98 := TLabel.Create(Self);
  WebButton108 := TButton.Create(Self);
  WebButton110 := TButton.Create(Self);
  WebDBResponsiveGrid3 := TDBResponsiveGrid.Create(Self);
  WebPageControl1Sheet2 := TTabSheet.Create(Self);
  WebPanel4 := TPanel.Create(Self);
  WebPanel10 := TPanel.Create(Self);
  WebDBResponsiveGrid2 := TDBResponsiveGrid.Create(Self);
  WebPanel13 := TPanel.Create(Self);
  WebButton8 := TButton.Create(Self);
  WebPanel14 := TPanel.Create(Self);
  WebPanel15 := TPanel.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton7 := TButton.Create(Self);
  WebButton93 := TButton.Create(Self);
  WebButton100 := TButton.Create(Self);
  WebPageControl2 := TPageControl.Create(Self);
  WebPageControl2Sheet1 := TTabSheet.Create(Self);
  WebImageControl11 := TImageControl.Create(Self);
  WebLabel29 := TLabel.Create(Self);
  WebLabel30 := TLabel.Create(Self);
  WebLabel31 := TLabel.Create(Self);
  WebLabel32 := TLabel.Create(Self);
  WebLabel72 := TLabel.Create(Self);
  WebEdit5 := TEdit.Create(Self);
  WebComboBox4 := TComboBox.Create(Self);
  WebComboBox5 := TComboBox.Create(Self);
  WebComboBox6 := TComboBox.Create(Self);
  WebPageControl2Sheet2 := TTabSheet.Create(Self);
  WebRichEdit8 := TRichEdit.Create(Self);
  WebPanel52 := TPanel.Create(Self);
  WebLabel122 := TLabel.Create(Self);
  WebPageControl2Sheet3 := TTabSheet.Create(Self);
  WebLabel111 := TLabel.Create(Self);
  WebLabel112 := TLabel.Create(Self);
  WebLabel113 := TLabel.Create(Self);
  WebLabel114 := TLabel.Create(Self);
  WebLabel115 := TLabel.Create(Self);
  WebLabel116 := TLabel.Create(Self);
  WebLabel117 := TLabel.Create(Self);
  WebLabel118 := TLabel.Create(Self);
  WebLabel119 := TLabel.Create(Self);
  WebLabel120 := TLabel.Create(Self);
  WebLabel121 := TLabel.Create(Self);
  WebEdit41 := TEdit.Create(Self);
  WebEdit42 := TEdit.Create(Self);
  WebEdit43 := TEdit.Create(Self);
  WebEdit44 := TEdit.Create(Self);
  WebEdit45 := TEdit.Create(Self);
  WebEdit46 := TEdit.Create(Self);
  WebEdit47 := TEdit.Create(Self);
  WebEdit48 := TEdit.Create(Self);
  WebPageControl2Sheet4 := TTabSheet.Create(Self);
  WebPageControl2Sheet5 := TTabSheet.Create(Self);
  WebPanel31 := TPanel.Create(Self);
  WebLabel83 := TLabel.Create(Self);
  WebComboBox11 := TComboBox.Create(Self);
  WebPanel32 := TPanel.Create(Self);
  WebDBMemo1 := TDBMemo.Create(Self);
  WebDBGrid2 := TDBGrid.Create(Self);
  WebPageControl2Sheet6 := TTabSheet.Create(Self);
  WebImageControl10 := TImageControl.Create(Self);
  WebPanel48 := TPanel.Create(Self);
  WebPanel33 := TPanel.Create(Self);
  WebLabel84 := TLabel.Create(Self);
  WebButton94 := TButton.Create(Self);
  WebButton95 := TButton.Create(Self);
  WebButton96 := TButton.Create(Self);
  WebButton97 := TButton.Create(Self);
  WebButton98 := TButton.Create(Self);
  WebButton99 := TButton.Create(Self);
  WebComboBox16 := TComboBox.Create(Self);
  WebPageControl1Sheet3 := TTabSheet.Create(Self);
  WebPageControl6 := TPageControl.Create(Self);
  WebPageControl6Sheet1 := TTabSheet.Create(Self);
  WebButton25 := TButton.Create(Self);
  WebButton53 := TButton.Create(Self);
  WebButton54 := TButton.Create(Self);
  WebPanel51 := TPanel.Create(Self);
  WebPageControl6Sheet2 := TTabSheet.Create(Self);
  WebPageControl6Sheet3 := TTabSheet.Create(Self);
  WebPageControl1Sheet4 := TTabSheet.Create(Self);
  WebPanel7 := TPanel.Create(Self);
  WebPanel8 := TPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebComboBox3 := TComboBox.Create(Self);
  WebEdit2 := TEdit.Create(Self);
  WebButton101 := TButton.Create(Self);
  WebButton102 := TButton.Create(Self);
  WebComboBox15 := TComboBox.Create(Self);
  WebListBox1 := TListBox.Create(Self);
  WebPanel30 := TPanel.Create(Self);
  WebLabel78 := TLabel.Create(Self);
  WebCheckBox3 := TCheckBox.Create(Self);
  WebCheckBox2 := TCheckBox.Create(Self);
  WebCheckBox1 := TCheckBox.Create(Self);
  WebButton103 := TButton.Create(Self);
  WebButton104 := TButton.Create(Self);
  WebButton105 := TButton.Create(Self);
  WebPanel11 := TPanel.Create(Self);
  WebPageControl7 := TPageControl.Create(Self);
  WebPageControl7Sheet1 := TTabSheet.Create(Self);
  WebPanel5 := TPanel.Create(Self);
  WebLabel77 := TLabel.Create(Self);
  WebLabel128 := TLabel.Create(Self);
  WebComboBox10 := TComboBox.Create(Self);
  WebButton87 := TButton.Create(Self);
  WebButton91 := TButton.Create(Self);
  WebDBResponsiveGrid1 := TDBResponsiveGrid.Create(Self);
  WebPanel42 := TPanel.Create(Self);
  WebRichEdit9 := TRichEdit.Create(Self);
  WebPanel47 := TPanel.Create(Self);
  WebPanel43 := TPanel.Create(Self);
  WebLabel93 := TLabel.Create(Self);
  WebPageControl7Sheet2 := TTabSheet.Create(Self);
  WebLabel80 := TLabel.Create(Self);
  WebLabel81 := TLabel.Create(Self);
  WebLabel82 := TLabel.Create(Self);
  WebButton69 := TButton.Create(Self);
  WebButton74 := TButton.Create(Self);
  WebButton75 := TButton.Create(Self);
  WebButton70 := TButton.Create(Self);
  WebButton76 := TButton.Create(Self);
  WebButton77 := TButton.Create(Self);
  WebPageControl7Sheet3 := TTabSheet.Create(Self);
  WebLabel79 := TLabel.Create(Self);
  WebProgressBar1 := TProgressBar.Create(Self);
  WebPageControl1Sheet5 := TTabSheet.Create(Self);
  WebPanel12 := TPanel.Create(Self);
  WebRichEdit1 := TRichEdit.Create(Self);
  WebPageControl1Sheet6 := TTabSheet.Create(Self);
  WebPageControl4 := TPageControl.Create(Self);
  WebPageControl4Sheet1 := TTabSheet.Create(Self);
  lbName := TLabel.Create(Self);
  lbAddress1 := TLabel.Create(Self);
  lbAddress2 := TLabel.Create(Self);
  lbCity := TLabel.Create(Self);
  lbPostalCode := TLabel.Create(Self);
  lbState := TLabel.Create(Self);
  WebLabel34 := TLabel.Create(Self);
  WebLabel36 := TLabel.Create(Self);
  WebLabel49 := TLabel.Create(Self);
  edName := TEdit.Create(Self);
  edAddress1 := TEdit.Create(Self);
  edAddress2 := TEdit.Create(Self);
  edCity := TEdit.Create(Self);
  edPostalCode := TEdit.Create(Self);
  WebComboBox1 := TComboBox.Create(Self);
  WebButton37 := TButton.Create(Self);
  WebButton38 := TButton.Create(Self);
  WebComboBox7 := TComboBox.Create(Self);
  WebPageControl4Sheet2 := TTabSheet.Create(Self);
  WebLabel33 := TLabel.Create(Self);
  WebLabel37 := TLabel.Create(Self);
  WebLabel38 := TLabel.Create(Self);
  WebLabel39 := TLabel.Create(Self);
  WebLabel40 := TLabel.Create(Self);
  WebLabel41 := TLabel.Create(Self);
  WebLabel42 := TLabel.Create(Self);
  WebLabel43 := TLabel.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebEdit6 := TEdit.Create(Self);
  WebEdit7 := TEdit.Create(Self);
  WebEdit8 := TEdit.Create(Self);
  WebEdit9 := TEdit.Create(Self);
  WebComboBox2 := TComboBox.Create(Self);
  WebButton35 := TButton.Create(Self);
  WebButton36 := TButton.Create(Self);
  WebPageControl4Sheet3 := TTabSheet.Create(Self);
  WebLabel12 := TLabel.Create(Self);
  WebLabel51 := TLabel.Create(Self);
  mePayment := TMemo.Create(Self);
  WebButton27 := TButton.Create(Self);
  WebRadioGroup1 := TRadioGroup.Create(Self);
  WebCheckBox7 := TCheckBox.Create(Self);
  WebButton40 := TButton.Create(Self);
  WebButton41 := TButton.Create(Self);
  WebButton42 := TButton.Create(Self);
  WebEdit12 := TEdit.Create(Self);
  WebEdit13 := TEdit.Create(Self);
  WebEdit14 := TEdit.Create(Self);
  WebPageControl4Sheet4 := TTabSheet.Create(Self);
  WebButton28 := TButton.Create(Self);
  WebButton29 := TButton.Create(Self);
  WebButton30 := TButton.Create(Self);
  WebButton31 := TButton.Create(Self);
  WebButton22 := TButton.Create(Self);
  WebButton23 := TButton.Create(Self);
  WebPageControl4Sheet5 := TTabSheet.Create(Self);
  WebLabel44 := TLabel.Create(Self);
  WebLabel45 := TLabel.Create(Self);
  WebLabel46 := TLabel.Create(Self);
  WebEdit10 := TEdit.Create(Self);
  WebEdit11 := TEdit.Create(Self);
  WebButton32 := TButton.Create(Self);
  WebButton33 := TButton.Create(Self);
  WebButton34 := TButton.Create(Self);
  WebButton39 := TButton.Create(Self);
  WebCheckBox5 := TCheckBox.Create(Self);
  WebPageControl4Sheet6 := TTabSheet.Create(Self);
  WebPageControl5 := TPageControl.Create(Self);
  WebPageControl5Sheet1 := TTabSheet.Create(Self);
  WebLabel52 := TLabel.Create(Self);
  WebLabel53 := TLabel.Create(Self);
  WebLabel54 := TLabel.Create(Self);
  WebLabel55 := TLabel.Create(Self);
  WebLabel56 := TLabel.Create(Self);
  WebLabel57 := TLabel.Create(Self);
  WebLabel58 := TLabel.Create(Self);
  WebLabel59 := TLabel.Create(Self);
  WebEdit15 := TEdit.Create(Self);
  WebEdit16 := TEdit.Create(Self);
  WebEdit17 := TEdit.Create(Self);
  WebEdit18 := TEdit.Create(Self);
  WebEdit19 := TEdit.Create(Self);
  WebComboBox8 := TComboBox.Create(Self);
  WebButton45 := TButton.Create(Self);
  WebButton46 := TButton.Create(Self);
  WebPageControl5Sheet2 := TTabSheet.Create(Self);
  WebLabel60 := TLabel.Create(Self);
  WebLabel61 := TLabel.Create(Self);
  WebLabel62 := TLabel.Create(Self);
  WebLabel63 := TLabel.Create(Self);
  WebLabel64 := TLabel.Create(Self);
  WebLabel65 := TLabel.Create(Self);
  WebEdit20 := TEdit.Create(Self);
  WebEdit21 := TEdit.Create(Self);
  WebEdit22 := TEdit.Create(Self);
  WebEdit23 := TEdit.Create(Self);
  WebButton47 := TButton.Create(Self);
  WebButton48 := TButton.Create(Self);
  WebButton49 := TButton.Create(Self);
  WebPageControl5Sheet3 := TTabSheet.Create(Self);
  WebLabel66 := TLabel.Create(Self);
  WebLabel67 := TLabel.Create(Self);
  WebLabel68 := TLabel.Create(Self);
  WebButton50 := TButton.Create(Self);
  WebButton51 := TButton.Create(Self);
  WebEdit24 := TEdit.Create(Self);
  WebButton52 := TButton.Create(Self);
  WebPageControl4Sheet7 := TTabSheet.Create(Self);
  WebPanel21 := TPanel.Create(Self);
  WebLabel47 := TLabel.Create(Self);
  WebLabel48 := TLabel.Create(Self);
  WebPayPal1 := TPayPal.Create(Self);
  WebButton26 := TButton.Create(Self);
  WebButton24 := TButton.Create(Self);
  WebPanel22 := TPanel.Create(Self);
  WebLabel50 := TLabel.Create(Self);
  WebDBGrid1 := TDBGrid.Create(Self);
  WebPageControl1Sheet7 := TTabSheet.Create(Self);
  WebImageSlider1 := TImageSlider.Create(Self);
  WebButton9 := TButton.Create(Self);
  WebPageControl1Sheet8 := TTabSheet.Create(Self);
  WebLabel14 := TLabel.Create(Self);
  WebLabel15 := TLabel.Create(Self);
  WebLabel16 := TLabel.Create(Self);
  WebLabel17 := TLabel.Create(Self);
  WebButton13 := TButton.Create(Self);
  WebButton14 := TButton.Create(Self);
  WebButton15 := TButton.Create(Self);
  WebPageControl1Sheet9 := TTabSheet.Create(Self);
  WebRichEdit2 := TRichEdit.Create(Self);
  WebPageControl1Sheet10 := TTabSheet.Create(Self);
  WebRichEdit3 := TRichEdit.Create(Self);
  WebPageControl1Sheet11 := TTabSheet.Create(Self);
  WebRichEdit4 := TRichEdit.Create(Self);
  WebPageControl1Sheet12 := TTabSheet.Create(Self);
  WebRichEdit5 := TRichEdit.Create(Self);
  WebPageControl1Sheet13 := TTabSheet.Create(Self);
  WebLabel9 := TLabel.Create(Self);
  WebLabel13 := TLabel.Create(Self);
  WebEdit3 := TEdit.Create(Self);
  WebEdit4 := TEdit.Create(Self);
  WebButton12 := TButton.Create(Self);
  WebButton16 := TButton.Create(Self);
  WebCheckBox4 := TCheckBox.Create(Self);
  WebPageControl1Sheet14 := TTabSheet.Create(Self);
  WebPageControl3 := TPageControl.Create(Self);
  WebPageControl3Sheet1 := TTabSheet.Create(Self);
  WebScrollBox4 := TScrollBox.Create(Self);
  WebImageControl1 := TImageControl.Create(Self);
  WebLabel28 := TLabel.Create(Self);
  WebPageControl3Sheet2 := TTabSheet.Create(Self);
  WebScrollBox3 := TScrollBox.Create(Self);
  WebImageControl2 := TImageControl.Create(Self);
  WebLabel27 := TLabel.Create(Self);
  WebPageControl3Sheet3 := TTabSheet.Create(Self);
  WebScrollBox2 := TScrollBox.Create(Self);
  WebImageControl5 := TImageControl.Create(Self);
  WebLabel26 := TLabel.Create(Self);
  WebPageControl3Sheet4 := TTabSheet.Create(Self);
  WebScrollBox1 := TScrollBox.Create(Self);
  WebImageControl4 := TImageControl.Create(Self);
  WebLabel23 := TLabel.Create(Self);
  WebPanel58 := TPanel.Create(Self);
  WebLabel22 := TLabel.Create(Self);
  WebComboBox17 := TComboBox.Create(Self);
  WebButton111 := TButton.Create(Self);
  WebButton21 := TButton.Create(Self);
  WebPageControl1Sheet15 := TTabSheet.Create(Self);
  WebButton43 := TButton.Create(Self);
  WebButton44 := TButton.Create(Self);
  WebRichEdit6 := TRichEdit.Create(Self);
  WebPageControl1Sheet16 := TTabSheet.Create(Self);
  WebRichEdit7 := TRichEdit.Create(Self);
  WebPageControl1Sheet17 := TTabSheet.Create(Self);
  WebPageControl1Sheet18 := TTabSheet.Create(Self);
  WebImageZoomControl1 := TImageZoomControl.Create(Self);
  WebImageSlider2 := TImageSlider.Create(Self);
  WebPanel20 := TPanel.Create(Self);
  WebLabel35 := TLabel.Create(Self);
  WebLabel71 := TLabel.Create(Self);
  WebComboBox9 := TComboBox.Create(Self);
  WebButton115 := TButton.Create(Self);
  WebPageControl1Sheet19 := TTabSheet.Create(Self);
  WebPanel23 := TPanel.Create(Self);
  WebButton57 := TButton.Create(Self);
  WebButton58 := TButton.Create(Self);
  WebButton59 := TButton.Create(Self);
  WebPanel24 := TPanel.Create(Self);
  WebLabel73 := TLabel.Create(Self);
  WebButton60 := TButton.Create(Self);
  WebStringGrid1 := TStringGrid.Create(Self);
  WebPageControl1Sheet20 := TTabSheet.Create(Self);
  WebPanel25 := TPanel.Create(Self);
  WebLabel74 := TLabel.Create(Self);
  WebButton61 := TButton.Create(Self);
  WebPanel26 := TPanel.Create(Self);
  WebButton62 := TButton.Create(Self);
  WebButton63 := TButton.Create(Self);
  WebButton64 := TButton.Create(Self);
  WebStringGrid2 := TStringGrid.Create(Self);
  WebPageControl1Sheet21 := TTabSheet.Create(Self);
  WebPanel27 := TPanel.Create(Self);
  WebLabel75 := TLabel.Create(Self);
  WebButton65 := TButton.Create(Self);
  WebPanel28 := TPanel.Create(Self);
  WebButton66 := TButton.Create(Self);
  WebButton67 := TButton.Create(Self);
  WebButton68 := TButton.Create(Self);
  WebStringGrid3 := TStringGrid.Create(Self);
  WebPageControl1Sheet22 := TTabSheet.Create(Self);
  WebLabel76 := TLabel.Create(Self);
  WebEditAutoComplete1 := TEditAutoComplete.Create(Self);
  WebPanel29 := TPanel.Create(Self);
  WebButton71 := TButton.Create(Self);
  WebButton72 := TButton.Create(Self);
  WebButton73 := TButton.Create(Self);
  WebPageControl1Sheet23 := TTabSheet.Create(Self);
  WebLabel87 := TLabel.Create(Self);
  WebEdit26 := TEdit.Create(Self);
  WebButton79 := TButton.Create(Self);
  WebButton80 := TButton.Create(Self);
  WebPageControl1Sheet24 := TTabSheet.Create(Self);
  WebPanel49 := TPanel.Create(Self);
  WebLabel10 := TLabel.Create(Self);
  WebLabel97 := TLabel.Create(Self);
  WebComboBox12 := TComboBox.Create(Self);
  WebComboBox13 := TComboBox.Create(Self);
  WebButton109 := TButton.Create(Self);
  WebPanel50 := TPanel.Create(Self);
  WebButton107 := TButton.Create(Self);
  WebStringGrid5 := TStringGrid.Create(Self);
  WebPageControl1Sheet25 := TTabSheet.Create(Self);
  WebLabel100 := TLabel.Create(Self);
  WebLabel101 := TLabel.Create(Self);
  WebLabel102 := TLabel.Create(Self);
  WebLabel103 := TLabel.Create(Self);
  WebEdit33 := TEdit.Create(Self);
  WebEdit34 := TEdit.Create(Self);
  WebEdit35 := TEdit.Create(Self);
  WebButton116 := TButton.Create(Self);
  WebButton117 := TButton.Create(Self);
  WebPageControl1Sheet26 := TTabSheet.Create(Self);
  WebLabel104 := TLabel.Create(Self);
  WebLabel105 := TLabel.Create(Self);
  WebLabel106 := TLabel.Create(Self);
  WebButton118 := TButton.Create(Self);
  WebButton119 := TButton.Create(Self);
  WebEdit36 := TEdit.Create(Self);
  WebEdit37 := TEdit.Create(Self);
  WebPageControl1Sheet27 := TTabSheet.Create(Self);
  WebLabel107 := TLabel.Create(Self);
  WebLabel108 := TLabel.Create(Self);
  WebLabel109 := TLabel.Create(Self);
  WebLabel110 := TLabel.Create(Self);
  WebButton120 := TButton.Create(Self);
  WebButton121 := TButton.Create(Self);
  WebEdit38 := TEdit.Create(Self);
  WebEdit39 := TEdit.Create(Self);
  WebEdit40 := TEdit.Create(Self);
  WebButton122 := TButton.Create(Self);
  WebButton124 := TButton.Create(Self);
  WebButton125 := TButton.Create(Self);
  WebPageControl1Sheet28 := TTabSheet.Create(Self);
  WebPanel54 := TPanel.Create(Self);
  WebLabel127 := TLabel.Create(Self);
  WebPanel55 := TPanel.Create(Self);
  WebPanel56 := TPanel.Create(Self);
  WebButton128 := TButton.Create(Self);
  WebButton133 := TButton.Create(Self);
  WebDBResponsiveGrid4 := TDBResponsiveGrid.Create(Self);
  WebPageControl1Sheet29 := TTabSheet.Create(Self);
  WebLabel129 := TLabel.Create(Self);
  WebLabel130 := TLabel.Create(Self);
  WebButton129 := TButton.Create(Self);
  WebButton130 := TButton.Create(Self);
  WebButton131 := TButton.Create(Self);
  WebPageControl1Sheet30 := TTabSheet.Create(Self);
  WebLabel131 := TLabel.Create(Self);
  WebLabel132 := TLabel.Create(Self);
  WebLabel133 := TLabel.Create(Self);
  WebButton134 := TButton.Create(Self);
  WebButton135 := TButton.Create(Self);
  WebButton136 := TButton.Create(Self);
  WebPageControl8Sheet2 := TTabSheet.Create(Self);
  WebPageControl9 := TPageControl.Create(Self);
  WebPageControl9Sheet1 := TTabSheet.Create(Self);
  WebResponsiveGridPanel1 := TResponsiveGridPanel.Create(Self);
  WebPanel34 := TPanel.Create(Self);
  WebLabel90 := TLabel.Create(Self);
  WebListBox2 := TListBox.Create(Self);
  WebPanel35 := TPanel.Create(Self);
  WebLabel85 := TLabel.Create(Self);
  WebLabel86 := TLabel.Create(Self);
  WebRadioGroup3 := TRadioGroup.Create(Self);
  WebEdit25 := TEdit.Create(Self);
  WebButton55 := TButton.Create(Self);
  WebButton78 := TButton.Create(Self);
  WebCheckBox6 := TCheckBox.Create(Self);
  WebPageControl9Sheet2 := TTabSheet.Create(Self);
  WebRadioGroup2 := TRadioGroup.Create(Self);
  WebPageControl9Sheet3 := TTabSheet.Create(Self);
  WebLabel69 := TLabel.Create(Self);
  WebLabel18 := TLabel.Create(Self);
  WebLabel89 := TLabel.Create(Self);
  WebEdit27 := TEdit.Create(Self);
  WebPanel38 := TPanel.Create(Self);
  WebLabel88 := TLabel.Create(Self);
  WebLabel11 := TLabel.Create(Self);
  WebListBox3 := TListBox.Create(Self);
  WebColorPicker1 := TColorPicker.Create(Self);
  WebEdit28 := TEdit.Create(Self);
  WebEdit29 := TEdit.Create(Self);
  WebPanel39 := TPanel.Create(Self);
  WebPageControl9Sheet4 := TTabSheet.Create(Self);
  WebLabel91 := TLabel.Create(Self);
  WebLabel92 := TLabel.Create(Self);
  WebPanel40 := TPanel.Create(Self);
  WebPanel41 := TPanel.Create(Self);
  WebTreeView1 := TTreeView.Create(Self);
  WebEdit30 := TEdit.Create(Self);
  WebEdit31 := TEdit.Create(Self);
  WebButton83 := TButton.Create(Self);
  WebButton84 := TButton.Create(Self);
  WebButton85 := TButton.Create(Self);
  WebButton86 := TButton.Create(Self);
  WebPanel37 := TPanel.Create(Self);
  WebButton81 := TButton.Create(Self);
  WebButton82 := TButton.Create(Self);
  WebPageControl8Sheet3 := TTabSheet.Create(Self);
  WebLabel19 := TLabel.Create(Self);
  WebProgressBar2 := TProgressBar.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebImageControl13 := TImageControl.Create(Self);
  WebLabel70 := TLabel.Create(Self);
  WebLabel8 := TLabel.Create(Self);
  WebLabel123 := TLabel.Create(Self);
  WebLabel126 := TLabel.Create(Self);
  WebMessageDlg1 := TMessageDlg.Create(Self);
  WebComboBox14 := TComboBox.Create(Self);
  WebPanel9 := TPanel.Create(Self);
  WebLabel99 := TLabel.Create(Self);
  WebButton4 := TButton.Create(Self);
  WebButton56 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton6 := TButton.Create(Self);
  WebButton3 := TButton.Create(Self);
  WebButton11 := TButton.Create(Self);
  WebButton10 := TButton.Create(Self);
  WebButton5 := TButton.Create(Self);
  WebButton17 := TButton.Create(Self);
  WebButton90 := TButton.Create(Self);
  WebButton106 := TButton.Create(Self);
  WebButton137 := TButton.Create(Self);
  WebPanel36 := TPanel.Create(Self);
  MB_Panel := TPanel.Create(Self);
  WebPanel44 := TPanel.Create(Self);
  WebPanel45 := TPanel.Create(Self);
  WebLabel94 := TLabel.Create(Self);
  WebLabel96 := TLabel.Create(Self);
  WebButton88 := TButton.Create(Self);
  WebButton92 := TButton.Create(Self);
  WebPanel46 := TPanel.Create(Self);
  WebLabel95 := TLabel.Create(Self);
  WebLabel124 := TLabel.Create(Self);
  WebEdit32 := TEdit.Create(Self);
  WebButton89 := TButton.Create(Self);
  WebButton123 := TButton.Create(Self);
  WebStringGrid4 := TStringGrid.Create(Self);
  WebMultimediaPlayer1 := TMultimediaPlayer.Create(Self);
  WebPanel53 := TPanel.Create(Self);
  WebLabel125 := TLabel.Create(Self);
  WebPanel57 := TPanel.Create(Self);
  WebButton126 := TButton.Create(Self);
  WebButton127 := TButton.Create(Self);
  WebButton132 := TButton.Create(Self);
  WebTimer1 := TTimer.Create(Self);
  WebTimer2 := TTimer.Create(Self);
  WebHttpRequest1 := THttpRequest.Create(Self);
  WebClientDataSet1 := TClientDataSet.Create(Self);
  WebTimer3 := TTimer.Create(Self);
  WebTimer4 := TTimer.Create(Self);
  WebTimer5 := TTimer.Create(Self);
  WebClientConnection1 := TClientConnection.Create(Self);
  WebTimer6 := TTimer.Create(Self);
  WebTimer7 := TTimer.Create(Self);
  WebSocketClient1 := TSocketClient.Create(Self);
  WebTimer8 := TTimer.Create(Self);
  StartTimer := TTimer.Create(Self);
  WebTimer9 := TTimer.Create(Self);
  WebMediaCapture1 := TMediaCapture.Create(Self);
  WebHttpRequest2 := THttpRequest.Create(Self);
  WebHttpRequest3 := THttpRequest.Create(Self);
  WebTimer10 := TTimer.Create(Self);
  WebTimer11 := TTimer.Create(Self);
  WebPopupMenu1 := TPopupMenu.Create(Self);
  AllergieFilter1 := TMenuItem.Create(Self);
  ErnhrungsFilter1 := TMenuItem.Create(Self);
  LieferartenFilter1 := TMenuItem.Create(Self);
  N3 := TMenuItem.Create(Self);
  Prospekte1 := TMenuItem.Create(Self);
  heken1 := TMenuItem.Create(Self);
  N1 := TMenuItem.Create(Self);
  MeinKonto1 := TMenuItem.Create(Self);
  Anmelden1 := TMenuItem.Create(Self);
  N2 := TMenuItem.Create(Self);
  Chat1 := TMenuItem.Create(Self);
  WebTimer12 := TTimer.Create(Self);

  WebPanel6.BeforeLoadDFMValues;
  WebImageControl12.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebLabel7.BeforeLoadDFMValues;
  WebLabel24.BeforeLoadDFMValues;
  WebLabel25.BeforeLoadDFMValues;
  WebPageControl8.BeforeLoadDFMValues;
  WebPageControl8Sheet1.BeforeLoadDFMValues;
  WebPageControl1.BeforeLoadDFMValues;
  WebPageControl1Sheet1.BeforeLoadDFMValues;
  WebPanel3.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebLabel98.BeforeLoadDFMValues;
  WebButton108.BeforeLoadDFMValues;
  WebButton110.BeforeLoadDFMValues;
  WebDBResponsiveGrid3.BeforeLoadDFMValues;
  WebPageControl1Sheet2.BeforeLoadDFMValues;
  WebPanel4.BeforeLoadDFMValues;
  WebPanel10.BeforeLoadDFMValues;
  WebDBResponsiveGrid2.BeforeLoadDFMValues;
  WebPanel13.BeforeLoadDFMValues;
  WebButton8.BeforeLoadDFMValues;
  WebPanel14.BeforeLoadDFMValues;
  WebPanel15.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton7.BeforeLoadDFMValues;
  WebButton93.BeforeLoadDFMValues;
  WebButton100.BeforeLoadDFMValues;
  WebPageControl2.BeforeLoadDFMValues;
  WebPageControl2Sheet1.BeforeLoadDFMValues;
  WebImageControl11.BeforeLoadDFMValues;
  WebLabel29.BeforeLoadDFMValues;
  WebLabel30.BeforeLoadDFMValues;
  WebLabel31.BeforeLoadDFMValues;
  WebLabel32.BeforeLoadDFMValues;
  WebLabel72.BeforeLoadDFMValues;
  WebEdit5.BeforeLoadDFMValues;
  WebComboBox4.BeforeLoadDFMValues;
  WebComboBox5.BeforeLoadDFMValues;
  WebComboBox6.BeforeLoadDFMValues;
  WebPageControl2Sheet2.BeforeLoadDFMValues;
  WebRichEdit8.BeforeLoadDFMValues;
  WebPanel52.BeforeLoadDFMValues;
  WebLabel122.BeforeLoadDFMValues;
  WebPageControl2Sheet3.BeforeLoadDFMValues;
  WebLabel111.BeforeLoadDFMValues;
  WebLabel112.BeforeLoadDFMValues;
  WebLabel113.BeforeLoadDFMValues;
  WebLabel114.BeforeLoadDFMValues;
  WebLabel115.BeforeLoadDFMValues;
  WebLabel116.BeforeLoadDFMValues;
  WebLabel117.BeforeLoadDFMValues;
  WebLabel118.BeforeLoadDFMValues;
  WebLabel119.BeforeLoadDFMValues;
  WebLabel120.BeforeLoadDFMValues;
  WebLabel121.BeforeLoadDFMValues;
  WebEdit41.BeforeLoadDFMValues;
  WebEdit42.BeforeLoadDFMValues;
  WebEdit43.BeforeLoadDFMValues;
  WebEdit44.BeforeLoadDFMValues;
  WebEdit45.BeforeLoadDFMValues;
  WebEdit46.BeforeLoadDFMValues;
  WebEdit47.BeforeLoadDFMValues;
  WebEdit48.BeforeLoadDFMValues;
  WebPageControl2Sheet4.BeforeLoadDFMValues;
  WebPageControl2Sheet5.BeforeLoadDFMValues;
  WebPanel31.BeforeLoadDFMValues;
  WebLabel83.BeforeLoadDFMValues;
  WebComboBox11.BeforeLoadDFMValues;
  WebPanel32.BeforeLoadDFMValues;
  WebDBMemo1.BeforeLoadDFMValues;
  WebDBGrid2.BeforeLoadDFMValues;
  WebPageControl2Sheet6.BeforeLoadDFMValues;
  WebImageControl10.BeforeLoadDFMValues;
  WebPanel48.BeforeLoadDFMValues;
  WebPanel33.BeforeLoadDFMValues;
  WebLabel84.BeforeLoadDFMValues;
  WebButton94.BeforeLoadDFMValues;
  WebButton95.BeforeLoadDFMValues;
  WebButton96.BeforeLoadDFMValues;
  WebButton97.BeforeLoadDFMValues;
  WebButton98.BeforeLoadDFMValues;
  WebButton99.BeforeLoadDFMValues;
  WebComboBox16.BeforeLoadDFMValues;
  WebPageControl1Sheet3.BeforeLoadDFMValues;
  WebPageControl6.BeforeLoadDFMValues;
  WebPageControl6Sheet1.BeforeLoadDFMValues;
  WebButton25.BeforeLoadDFMValues;
  WebButton53.BeforeLoadDFMValues;
  WebButton54.BeforeLoadDFMValues;
  WebPanel51.BeforeLoadDFMValues;
  WebPageControl6Sheet2.BeforeLoadDFMValues;
  WebPageControl6Sheet3.BeforeLoadDFMValues;
  WebPageControl1Sheet4.BeforeLoadDFMValues;
  WebPanel7.BeforeLoadDFMValues;
  WebPanel8.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebComboBox3.BeforeLoadDFMValues;
  WebEdit2.BeforeLoadDFMValues;
  WebButton101.BeforeLoadDFMValues;
  WebButton102.BeforeLoadDFMValues;
  WebComboBox15.BeforeLoadDFMValues;
  WebListBox1.BeforeLoadDFMValues;
  WebPanel30.BeforeLoadDFMValues;
  WebLabel78.BeforeLoadDFMValues;
  WebCheckBox3.BeforeLoadDFMValues;
  WebCheckBox2.BeforeLoadDFMValues;
  WebCheckBox1.BeforeLoadDFMValues;
  WebButton103.BeforeLoadDFMValues;
  WebButton104.BeforeLoadDFMValues;
  WebButton105.BeforeLoadDFMValues;
  WebPanel11.BeforeLoadDFMValues;
  WebPageControl7.BeforeLoadDFMValues;
  WebPageControl7Sheet1.BeforeLoadDFMValues;
  WebPanel5.BeforeLoadDFMValues;
  WebLabel77.BeforeLoadDFMValues;
  WebLabel128.BeforeLoadDFMValues;
  WebComboBox10.BeforeLoadDFMValues;
  WebButton87.BeforeLoadDFMValues;
  WebButton91.BeforeLoadDFMValues;
  WebDBResponsiveGrid1.BeforeLoadDFMValues;
  WebPanel42.BeforeLoadDFMValues;
  WebRichEdit9.BeforeLoadDFMValues;
  WebPanel47.BeforeLoadDFMValues;
  WebPanel43.BeforeLoadDFMValues;
  WebLabel93.BeforeLoadDFMValues;
  WebPageControl7Sheet2.BeforeLoadDFMValues;
  WebLabel80.BeforeLoadDFMValues;
  WebLabel81.BeforeLoadDFMValues;
  WebLabel82.BeforeLoadDFMValues;
  WebButton69.BeforeLoadDFMValues;
  WebButton74.BeforeLoadDFMValues;
  WebButton75.BeforeLoadDFMValues;
  WebButton70.BeforeLoadDFMValues;
  WebButton76.BeforeLoadDFMValues;
  WebButton77.BeforeLoadDFMValues;
  WebPageControl7Sheet3.BeforeLoadDFMValues;
  WebLabel79.BeforeLoadDFMValues;
  WebProgressBar1.BeforeLoadDFMValues;
  WebPageControl1Sheet5.BeforeLoadDFMValues;
  WebPanel12.BeforeLoadDFMValues;
  WebRichEdit1.BeforeLoadDFMValues;
  WebPageControl1Sheet6.BeforeLoadDFMValues;
  WebPageControl4.BeforeLoadDFMValues;
  WebPageControl4Sheet1.BeforeLoadDFMValues;
  lbName.BeforeLoadDFMValues;
  lbAddress1.BeforeLoadDFMValues;
  lbAddress2.BeforeLoadDFMValues;
  lbCity.BeforeLoadDFMValues;
  lbPostalCode.BeforeLoadDFMValues;
  lbState.BeforeLoadDFMValues;
  WebLabel34.BeforeLoadDFMValues;
  WebLabel36.BeforeLoadDFMValues;
  WebLabel49.BeforeLoadDFMValues;
  edName.BeforeLoadDFMValues;
  edAddress1.BeforeLoadDFMValues;
  edAddress2.BeforeLoadDFMValues;
  edCity.BeforeLoadDFMValues;
  edPostalCode.BeforeLoadDFMValues;
  WebComboBox1.BeforeLoadDFMValues;
  WebButton37.BeforeLoadDFMValues;
  WebButton38.BeforeLoadDFMValues;
  WebComboBox7.BeforeLoadDFMValues;
  WebPageControl4Sheet2.BeforeLoadDFMValues;
  WebLabel33.BeforeLoadDFMValues;
  WebLabel37.BeforeLoadDFMValues;
  WebLabel38.BeforeLoadDFMValues;
  WebLabel39.BeforeLoadDFMValues;
  WebLabel40.BeforeLoadDFMValues;
  WebLabel41.BeforeLoadDFMValues;
  WebLabel42.BeforeLoadDFMValues;
  WebLabel43.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebEdit6.BeforeLoadDFMValues;
  WebEdit7.BeforeLoadDFMValues;
  WebEdit8.BeforeLoadDFMValues;
  WebEdit9.BeforeLoadDFMValues;
  WebComboBox2.BeforeLoadDFMValues;
  WebButton35.BeforeLoadDFMValues;
  WebButton36.BeforeLoadDFMValues;
  WebPageControl4Sheet3.BeforeLoadDFMValues;
  WebLabel12.BeforeLoadDFMValues;
  WebLabel51.BeforeLoadDFMValues;
  mePayment.BeforeLoadDFMValues;
  WebButton27.BeforeLoadDFMValues;
  WebRadioGroup1.BeforeLoadDFMValues;
  WebCheckBox7.BeforeLoadDFMValues;
  WebButton40.BeforeLoadDFMValues;
  WebButton41.BeforeLoadDFMValues;
  WebButton42.BeforeLoadDFMValues;
  WebEdit12.BeforeLoadDFMValues;
  WebEdit13.BeforeLoadDFMValues;
  WebEdit14.BeforeLoadDFMValues;
  WebPageControl4Sheet4.BeforeLoadDFMValues;
  WebButton28.BeforeLoadDFMValues;
  WebButton29.BeforeLoadDFMValues;
  WebButton30.BeforeLoadDFMValues;
  WebButton31.BeforeLoadDFMValues;
  WebButton22.BeforeLoadDFMValues;
  WebButton23.BeforeLoadDFMValues;
  WebPageControl4Sheet5.BeforeLoadDFMValues;
  WebLabel44.BeforeLoadDFMValues;
  WebLabel45.BeforeLoadDFMValues;
  WebLabel46.BeforeLoadDFMValues;
  WebEdit10.BeforeLoadDFMValues;
  WebEdit11.BeforeLoadDFMValues;
  WebButton32.BeforeLoadDFMValues;
  WebButton33.BeforeLoadDFMValues;
  WebButton34.BeforeLoadDFMValues;
  WebButton39.BeforeLoadDFMValues;
  WebCheckBox5.BeforeLoadDFMValues;
  WebPageControl4Sheet6.BeforeLoadDFMValues;
  WebPageControl5.BeforeLoadDFMValues;
  WebPageControl5Sheet1.BeforeLoadDFMValues;
  WebLabel52.BeforeLoadDFMValues;
  WebLabel53.BeforeLoadDFMValues;
  WebLabel54.BeforeLoadDFMValues;
  WebLabel55.BeforeLoadDFMValues;
  WebLabel56.BeforeLoadDFMValues;
  WebLabel57.BeforeLoadDFMValues;
  WebLabel58.BeforeLoadDFMValues;
  WebLabel59.BeforeLoadDFMValues;
  WebEdit15.BeforeLoadDFMValues;
  WebEdit16.BeforeLoadDFMValues;
  WebEdit17.BeforeLoadDFMValues;
  WebEdit18.BeforeLoadDFMValues;
  WebEdit19.BeforeLoadDFMValues;
  WebComboBox8.BeforeLoadDFMValues;
  WebButton45.BeforeLoadDFMValues;
  WebButton46.BeforeLoadDFMValues;
  WebPageControl5Sheet2.BeforeLoadDFMValues;
  WebLabel60.BeforeLoadDFMValues;
  WebLabel61.BeforeLoadDFMValues;
  WebLabel62.BeforeLoadDFMValues;
  WebLabel63.BeforeLoadDFMValues;
  WebLabel64.BeforeLoadDFMValues;
  WebLabel65.BeforeLoadDFMValues;
  WebEdit20.BeforeLoadDFMValues;
  WebEdit21.BeforeLoadDFMValues;
  WebEdit22.BeforeLoadDFMValues;
  WebEdit23.BeforeLoadDFMValues;
  WebButton47.BeforeLoadDFMValues;
  WebButton48.BeforeLoadDFMValues;
  WebButton49.BeforeLoadDFMValues;
  WebPageControl5Sheet3.BeforeLoadDFMValues;
  WebLabel66.BeforeLoadDFMValues;
  WebLabel67.BeforeLoadDFMValues;
  WebLabel68.BeforeLoadDFMValues;
  WebButton50.BeforeLoadDFMValues;
  WebButton51.BeforeLoadDFMValues;
  WebEdit24.BeforeLoadDFMValues;
  WebButton52.BeforeLoadDFMValues;
  WebPageControl4Sheet7.BeforeLoadDFMValues;
  WebPanel21.BeforeLoadDFMValues;
  WebLabel47.BeforeLoadDFMValues;
  WebLabel48.BeforeLoadDFMValues;
  WebPayPal1.BeforeLoadDFMValues;
  WebButton26.BeforeLoadDFMValues;
  WebButton24.BeforeLoadDFMValues;
  WebPanel22.BeforeLoadDFMValues;
  WebLabel50.BeforeLoadDFMValues;
  WebDBGrid1.BeforeLoadDFMValues;
  WebPageControl1Sheet7.BeforeLoadDFMValues;
  WebImageSlider1.BeforeLoadDFMValues;
  WebButton9.BeforeLoadDFMValues;
  WebPageControl1Sheet8.BeforeLoadDFMValues;
  WebLabel14.BeforeLoadDFMValues;
  WebLabel15.BeforeLoadDFMValues;
  WebLabel16.BeforeLoadDFMValues;
  WebLabel17.BeforeLoadDFMValues;
  WebButton13.BeforeLoadDFMValues;
  WebButton14.BeforeLoadDFMValues;
  WebButton15.BeforeLoadDFMValues;
  WebPageControl1Sheet9.BeforeLoadDFMValues;
  WebRichEdit2.BeforeLoadDFMValues;
  WebPageControl1Sheet10.BeforeLoadDFMValues;
  WebRichEdit3.BeforeLoadDFMValues;
  WebPageControl1Sheet11.BeforeLoadDFMValues;
  WebRichEdit4.BeforeLoadDFMValues;
  WebPageControl1Sheet12.BeforeLoadDFMValues;
  WebRichEdit5.BeforeLoadDFMValues;
  WebPageControl1Sheet13.BeforeLoadDFMValues;
  WebLabel9.BeforeLoadDFMValues;
  WebLabel13.BeforeLoadDFMValues;
  WebEdit3.BeforeLoadDFMValues;
  WebEdit4.BeforeLoadDFMValues;
  WebButton12.BeforeLoadDFMValues;
  WebButton16.BeforeLoadDFMValues;
  WebCheckBox4.BeforeLoadDFMValues;
  WebPageControl1Sheet14.BeforeLoadDFMValues;
  WebPageControl3.BeforeLoadDFMValues;
  WebPageControl3Sheet1.BeforeLoadDFMValues;
  WebScrollBox4.BeforeLoadDFMValues;
  WebImageControl1.BeforeLoadDFMValues;
  WebLabel28.BeforeLoadDFMValues;
  WebPageControl3Sheet2.BeforeLoadDFMValues;
  WebScrollBox3.BeforeLoadDFMValues;
  WebImageControl2.BeforeLoadDFMValues;
  WebLabel27.BeforeLoadDFMValues;
  WebPageControl3Sheet3.BeforeLoadDFMValues;
  WebScrollBox2.BeforeLoadDFMValues;
  WebImageControl5.BeforeLoadDFMValues;
  WebLabel26.BeforeLoadDFMValues;
  WebPageControl3Sheet4.BeforeLoadDFMValues;
  WebScrollBox1.BeforeLoadDFMValues;
  WebImageControl4.BeforeLoadDFMValues;
  WebLabel23.BeforeLoadDFMValues;
  WebPanel58.BeforeLoadDFMValues;
  WebLabel22.BeforeLoadDFMValues;
  WebComboBox17.BeforeLoadDFMValues;
  WebButton111.BeforeLoadDFMValues;
  WebButton21.BeforeLoadDFMValues;
  WebPageControl1Sheet15.BeforeLoadDFMValues;
  WebButton43.BeforeLoadDFMValues;
  WebButton44.BeforeLoadDFMValues;
  WebRichEdit6.BeforeLoadDFMValues;
  WebPageControl1Sheet16.BeforeLoadDFMValues;
  WebRichEdit7.BeforeLoadDFMValues;
  WebPageControl1Sheet17.BeforeLoadDFMValues;
  WebPageControl1Sheet18.BeforeLoadDFMValues;
  WebImageZoomControl1.BeforeLoadDFMValues;
  WebImageSlider2.BeforeLoadDFMValues;
  WebPanel20.BeforeLoadDFMValues;
  WebLabel35.BeforeLoadDFMValues;
  WebLabel71.BeforeLoadDFMValues;
  WebComboBox9.BeforeLoadDFMValues;
  WebButton115.BeforeLoadDFMValues;
  WebPageControl1Sheet19.BeforeLoadDFMValues;
  WebPanel23.BeforeLoadDFMValues;
  WebButton57.BeforeLoadDFMValues;
  WebButton58.BeforeLoadDFMValues;
  WebButton59.BeforeLoadDFMValues;
  WebPanel24.BeforeLoadDFMValues;
  WebLabel73.BeforeLoadDFMValues;
  WebButton60.BeforeLoadDFMValues;
  WebStringGrid1.BeforeLoadDFMValues;
  WebPageControl1Sheet20.BeforeLoadDFMValues;
  WebPanel25.BeforeLoadDFMValues;
  WebLabel74.BeforeLoadDFMValues;
  WebButton61.BeforeLoadDFMValues;
  WebPanel26.BeforeLoadDFMValues;
  WebButton62.BeforeLoadDFMValues;
  WebButton63.BeforeLoadDFMValues;
  WebButton64.BeforeLoadDFMValues;
  WebStringGrid2.BeforeLoadDFMValues;
  WebPageControl1Sheet21.BeforeLoadDFMValues;
  WebPanel27.BeforeLoadDFMValues;
  WebLabel75.BeforeLoadDFMValues;
  WebButton65.BeforeLoadDFMValues;
  WebPanel28.BeforeLoadDFMValues;
  WebButton66.BeforeLoadDFMValues;
  WebButton67.BeforeLoadDFMValues;
  WebButton68.BeforeLoadDFMValues;
  WebStringGrid3.BeforeLoadDFMValues;
  WebPageControl1Sheet22.BeforeLoadDFMValues;
  WebLabel76.BeforeLoadDFMValues;
  WebEditAutoComplete1.BeforeLoadDFMValues;
  WebPanel29.BeforeLoadDFMValues;
  WebButton71.BeforeLoadDFMValues;
  WebButton72.BeforeLoadDFMValues;
  WebButton73.BeforeLoadDFMValues;
  WebPageControl1Sheet23.BeforeLoadDFMValues;
  WebLabel87.BeforeLoadDFMValues;
  WebEdit26.BeforeLoadDFMValues;
  WebButton79.BeforeLoadDFMValues;
  WebButton80.BeforeLoadDFMValues;
  WebPageControl1Sheet24.BeforeLoadDFMValues;
  WebPanel49.BeforeLoadDFMValues;
  WebLabel10.BeforeLoadDFMValues;
  WebLabel97.BeforeLoadDFMValues;
  WebComboBox12.BeforeLoadDFMValues;
  WebComboBox13.BeforeLoadDFMValues;
  WebButton109.BeforeLoadDFMValues;
  WebPanel50.BeforeLoadDFMValues;
  WebButton107.BeforeLoadDFMValues;
  WebStringGrid5.BeforeLoadDFMValues;
  WebPageControl1Sheet25.BeforeLoadDFMValues;
  WebLabel100.BeforeLoadDFMValues;
  WebLabel101.BeforeLoadDFMValues;
  WebLabel102.BeforeLoadDFMValues;
  WebLabel103.BeforeLoadDFMValues;
  WebEdit33.BeforeLoadDFMValues;
  WebEdit34.BeforeLoadDFMValues;
  WebEdit35.BeforeLoadDFMValues;
  WebButton116.BeforeLoadDFMValues;
  WebButton117.BeforeLoadDFMValues;
  WebPageControl1Sheet26.BeforeLoadDFMValues;
  WebLabel104.BeforeLoadDFMValues;
  WebLabel105.BeforeLoadDFMValues;
  WebLabel106.BeforeLoadDFMValues;
  WebButton118.BeforeLoadDFMValues;
  WebButton119.BeforeLoadDFMValues;
  WebEdit36.BeforeLoadDFMValues;
  WebEdit37.BeforeLoadDFMValues;
  WebPageControl1Sheet27.BeforeLoadDFMValues;
  WebLabel107.BeforeLoadDFMValues;
  WebLabel108.BeforeLoadDFMValues;
  WebLabel109.BeforeLoadDFMValues;
  WebLabel110.BeforeLoadDFMValues;
  WebButton120.BeforeLoadDFMValues;
  WebButton121.BeforeLoadDFMValues;
  WebEdit38.BeforeLoadDFMValues;
  WebEdit39.BeforeLoadDFMValues;
  WebEdit40.BeforeLoadDFMValues;
  WebButton122.BeforeLoadDFMValues;
  WebButton124.BeforeLoadDFMValues;
  WebButton125.BeforeLoadDFMValues;
  WebPageControl1Sheet28.BeforeLoadDFMValues;
  WebPanel54.BeforeLoadDFMValues;
  WebLabel127.BeforeLoadDFMValues;
  WebPanel55.BeforeLoadDFMValues;
  WebPanel56.BeforeLoadDFMValues;
  WebButton128.BeforeLoadDFMValues;
  WebButton133.BeforeLoadDFMValues;
  WebDBResponsiveGrid4.BeforeLoadDFMValues;
  WebPageControl1Sheet29.BeforeLoadDFMValues;
  WebLabel129.BeforeLoadDFMValues;
  WebLabel130.BeforeLoadDFMValues;
  WebButton129.BeforeLoadDFMValues;
  WebButton130.BeforeLoadDFMValues;
  WebButton131.BeforeLoadDFMValues;
  WebPageControl1Sheet30.BeforeLoadDFMValues;
  WebLabel131.BeforeLoadDFMValues;
  WebLabel132.BeforeLoadDFMValues;
  WebLabel133.BeforeLoadDFMValues;
  WebButton134.BeforeLoadDFMValues;
  WebButton135.BeforeLoadDFMValues;
  WebButton136.BeforeLoadDFMValues;
  WebPageControl8Sheet2.BeforeLoadDFMValues;
  WebPageControl9.BeforeLoadDFMValues;
  WebPageControl9Sheet1.BeforeLoadDFMValues;
  WebResponsiveGridPanel1.BeforeLoadDFMValues;
  WebPanel34.BeforeLoadDFMValues;
  WebLabel90.BeforeLoadDFMValues;
  WebListBox2.BeforeLoadDFMValues;
  WebPanel35.BeforeLoadDFMValues;
  WebLabel85.BeforeLoadDFMValues;
  WebLabel86.BeforeLoadDFMValues;
  WebRadioGroup3.BeforeLoadDFMValues;
  WebEdit25.BeforeLoadDFMValues;
  WebButton55.BeforeLoadDFMValues;
  WebButton78.BeforeLoadDFMValues;
  WebCheckBox6.BeforeLoadDFMValues;
  WebPageControl9Sheet2.BeforeLoadDFMValues;
  WebRadioGroup2.BeforeLoadDFMValues;
  WebPageControl9Sheet3.BeforeLoadDFMValues;
  WebLabel69.BeforeLoadDFMValues;
  WebLabel18.BeforeLoadDFMValues;
  WebLabel89.BeforeLoadDFMValues;
  WebEdit27.BeforeLoadDFMValues;
  WebPanel38.BeforeLoadDFMValues;
  WebLabel88.BeforeLoadDFMValues;
  WebLabel11.BeforeLoadDFMValues;
  WebListBox3.BeforeLoadDFMValues;
  WebColorPicker1.BeforeLoadDFMValues;
  WebEdit28.BeforeLoadDFMValues;
  WebEdit29.BeforeLoadDFMValues;
  WebPanel39.BeforeLoadDFMValues;
  WebPageControl9Sheet4.BeforeLoadDFMValues;
  WebLabel91.BeforeLoadDFMValues;
  WebLabel92.BeforeLoadDFMValues;
  WebPanel40.BeforeLoadDFMValues;
  WebPanel41.BeforeLoadDFMValues;
  WebTreeView1.BeforeLoadDFMValues;
  WebEdit30.BeforeLoadDFMValues;
  WebEdit31.BeforeLoadDFMValues;
  WebButton83.BeforeLoadDFMValues;
  WebButton84.BeforeLoadDFMValues;
  WebButton85.BeforeLoadDFMValues;
  WebButton86.BeforeLoadDFMValues;
  WebPanel37.BeforeLoadDFMValues;
  WebButton81.BeforeLoadDFMValues;
  WebButton82.BeforeLoadDFMValues;
  WebPageControl8Sheet3.BeforeLoadDFMValues;
  WebLabel19.BeforeLoadDFMValues;
  WebProgressBar2.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebImageControl13.BeforeLoadDFMValues;
  WebLabel70.BeforeLoadDFMValues;
  WebLabel8.BeforeLoadDFMValues;
  WebLabel123.BeforeLoadDFMValues;
  WebLabel126.BeforeLoadDFMValues;
  WebMessageDlg1.BeforeLoadDFMValues;
  WebComboBox14.BeforeLoadDFMValues;
  WebPanel9.BeforeLoadDFMValues;
  WebLabel99.BeforeLoadDFMValues;
  WebButton4.BeforeLoadDFMValues;
  WebButton56.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton6.BeforeLoadDFMValues;
  WebButton3.BeforeLoadDFMValues;
  WebButton11.BeforeLoadDFMValues;
  WebButton10.BeforeLoadDFMValues;
  WebButton5.BeforeLoadDFMValues;
  WebButton17.BeforeLoadDFMValues;
  WebButton90.BeforeLoadDFMValues;
  WebButton106.BeforeLoadDFMValues;
  WebButton137.BeforeLoadDFMValues;
  WebPanel36.BeforeLoadDFMValues;
  MB_Panel.BeforeLoadDFMValues;
  WebPanel44.BeforeLoadDFMValues;
  WebPanel45.BeforeLoadDFMValues;
  WebLabel94.BeforeLoadDFMValues;
  WebLabel96.BeforeLoadDFMValues;
  WebButton88.BeforeLoadDFMValues;
  WebButton92.BeforeLoadDFMValues;
  WebPanel46.BeforeLoadDFMValues;
  WebLabel95.BeforeLoadDFMValues;
  WebLabel124.BeforeLoadDFMValues;
  WebEdit32.BeforeLoadDFMValues;
  WebButton89.BeforeLoadDFMValues;
  WebButton123.BeforeLoadDFMValues;
  WebStringGrid4.BeforeLoadDFMValues;
  WebMultimediaPlayer1.BeforeLoadDFMValues;
  WebPanel53.BeforeLoadDFMValues;
  WebLabel125.BeforeLoadDFMValues;
  WebPanel57.BeforeLoadDFMValues;
  WebButton126.BeforeLoadDFMValues;
  WebButton127.BeforeLoadDFMValues;
  WebButton132.BeforeLoadDFMValues;
  WebTimer1.BeforeLoadDFMValues;
  WebTimer2.BeforeLoadDFMValues;
  WebHttpRequest1.BeforeLoadDFMValues;
  WebClientDataSet1.BeforeLoadDFMValues;
  WebTimer3.BeforeLoadDFMValues;
  WebTimer4.BeforeLoadDFMValues;
  WebTimer5.BeforeLoadDFMValues;
  WebClientConnection1.BeforeLoadDFMValues;
  WebTimer6.BeforeLoadDFMValues;
  WebTimer7.BeforeLoadDFMValues;
  WebSocketClient1.BeforeLoadDFMValues;
  WebTimer8.BeforeLoadDFMValues;
  StartTimer.BeforeLoadDFMValues;
  WebTimer9.BeforeLoadDFMValues;
  WebMediaCapture1.BeforeLoadDFMValues;
  WebHttpRequest2.BeforeLoadDFMValues;
  WebHttpRequest3.BeforeLoadDFMValues;
  WebTimer10.BeforeLoadDFMValues;
  WebTimer11.BeforeLoadDFMValues;
  WebPopupMenu1.BeforeLoadDFMValues;
  AllergieFilter1.BeforeLoadDFMValues;
  ErnhrungsFilter1.BeforeLoadDFMValues;
  LieferartenFilter1.BeforeLoadDFMValues;
  N3.BeforeLoadDFMValues;
  Prospekte1.BeforeLoadDFMValues;
  heken1.BeforeLoadDFMValues;
  N1.BeforeLoadDFMValues;
  MeinKonto1.BeforeLoadDFMValues;
  Anmelden1.BeforeLoadDFMValues;
  N2.BeforeLoadDFMValues;
  Chat1.BeforeLoadDFMValues;
  WebTimer12.BeforeLoadDFMValues;
  try
    Name := 'Form2';
    Width := 1400;
    Height := 800;
    Caption := 'D1';
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebPanel6.SetParentComponent(Self);
    WebPanel6.Name := 'WebPanel6';
    WebPanel6.Left := 0;
    WebPanel6.Top := 740;
    WebPanel6.Width := 1400;
    WebPanel6.Height := 60;
    WebPanel6.ElementClassName := 'bg-primary mainform';
    WebPanel6.HeightPercent := 100.000000000000000000;
    WebPanel6.WidthPercent := 100.000000000000000000;
    WebPanel6.Align := alBottom;
    WebPanel6.BorderColor := clSilver;
    WebPanel6.BorderStyle := bsNone;
    WebPanel6.ChildOrder := 2;
    WebPanel6.ElementFont := efCSS;
    WebImageControl12.SetParentComponent(WebPanel6);
    WebImageControl12.Name := 'WebImageControl12';
    WebImageControl12.Left := 6;
    WebImageControl12.Top := 19;
    WebImageControl12.Width := 123;
    WebImageControl12.Height := 38;
    WebImageControl12.HeightPercent := 100.000000000000000000;
    WebImageControl12.WidthPercent := 100.000000000000000000;
    WebImageControl12.ChildOrder := 2;
    WebImageControl12.Picture.LoadFromFile('UWSMain.WebPanel6.WebImageControl12.Picture.png');
    WebLabel3.SetParentComponent(WebPanel6);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 7;
    WebLabel3.Top := 4;
    WebLabel3.Width := 87;
    WebLabel3.Height := 14;
    WebLabel3.Caption := 'Wir akzeptieren';
    WebLabel3.ElementClassName := 'badge badge-primary';
    WebLabel3.ElementFont := efCSS;
    WebLabel3.Font.Charset := DEFAULT_CHARSET;
    WebLabel3.Font.Color := clWindowText;
    WebLabel3.Font.Height := -11;
    WebLabel3.Font.Name := 'Arial';
    WebLabel3.Font.Style := [fsBold];
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.ParentFont := False;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(WebPanel6);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 144;
    WebLabel4.Top := 6;
    WebLabel4.Width := 36;
    WebLabel4.Height := 14;
    WebLabel4.Caption := 'AGB'#180's ';
    WebLabel4.ElementClassName := 'badge badge-primary';
    WebLabel4.ElementFont := efCSS;
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.WidthPercent := 100.000000000000000000;
    SetEvent(WebLabel4, Self, 'OnClick', 'WebLabel4Click');
    WebLabel5.SetParentComponent(WebPanel6);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 210;
    WebLabel5.Top := 6;
    WebLabel5.Width := 61;
    WebLabel5.Height := 14;
    WebLabel5.Caption := 'Datenschutz';
    WebLabel5.ElementClassName := 'badge badge-primary';
    WebLabel5.ElementFont := efCSS;
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.WidthPercent := 100.000000000000000000;
    SetEvent(WebLabel5, Self, 'OnClick', 'WebLabel5Click');
    WebLabel6.SetParentComponent(WebPanel6);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 144;
    WebLabel6.Top := 38;
    WebLabel6.Width := 36;
    WebLabel6.Height := 14;
    WebLabel6.Caption := 'Kontakt';
    WebLabel6.ElementClassName := 'badge badge-primary';
    WebLabel6.ElementFont := efCSS;
    WebLabel6.HeightPercent := 100.000000000000000000;
    WebLabel6.WidthPercent := 100.000000000000000000;
    SetEvent(WebLabel6, Self, 'OnClick', 'WebLabel6Click');
    WebLabel7.SetParentComponent(WebPanel6);
    WebLabel7.Name := 'WebLabel7';
    WebLabel7.Left := 210;
    WebLabel7.Top := 38;
    WebLabel7.Width := 52;
    WebLabel7.Height := 14;
    WebLabel7.Caption := 'Impressum';
    WebLabel7.ElementClassName := 'badge badge-primary';
    WebLabel7.ElementFont := efCSS;
    WebLabel7.HeightPercent := 100.000000000000000000;
    WebLabel7.WidthPercent := 100.000000000000000000;
    SetEvent(WebLabel7, Self, 'OnClick', 'WebLabel7Click');
    WebLabel24.SetParentComponent(WebPanel6);
    WebLabel24.Name := 'WebLabel24';
    WebLabel24.Left := 624;
    WebLabel24.Top := 24;
    WebLabel24.Width := 60;
    WebLabel24.Height := 14;
    WebLabel24.Caption := 'WebLabel24';
    WebLabel24.HeightPercent := 100.000000000000000000;
    WebLabel24.Visible := False;
    WebLabel24.WidthPercent := 100.000000000000000000;
    WebLabel25.SetParentComponent(WebPanel6);
    WebLabel25.Name := 'WebLabel25';
    WebLabel25.Left := 736;
    WebLabel25.Top := 16;
    WebLabel25.Width := 60;
    WebLabel25.Height := 14;
    WebLabel25.AutoSize := False;
    WebLabel25.Caption := 'WebLabel25';
    WebLabel25.HeightPercent := 100.000000000000000000;
    WebLabel25.Visible := False;
    WebLabel25.WidthPercent := 100.000000000000000000;
    WebPageControl8.SetParentComponent(Self);
    WebPageControl8.Name := 'WebPageControl8';
    WebPageControl8.Left := 0;
    WebPageControl8.Top := 213;
    WebPageControl8.Width := 950;
    WebPageControl8.Height := 473;
    WebPageControl8.HeightPercent := 100.000000000000000000;
    WebPageControl8.WidthPercent := 100.000000000000000000;
    WebPageControl8.Align := alClient;
    WebPageControl8.ChildOrder := 3;
    WebPageControl8.TabIndex := 0;
    WebPageControl8.TabOrder := 1;
    SetEvent(WebPageControl8, Self, 'OnChange', 'WebPageControl8Change');
    WebPageControl8Sheet1.SetParentComponent(WebPageControl8);
    WebPageControl8Sheet1.Name := 'WebPageControl8Sheet1';
    WebPageControl8Sheet1.Left := 0;
    WebPageControl8Sheet1.Top := 20;
    WebPageControl8Sheet1.Width := 950;
    WebPageControl8Sheet1.Height := 453;
    WebPageControl8Sheet1.HeightPercent := 100.000000000000000000;
    WebPageControl8Sheet1.WidthPercent := 100.000000000000000000;
    WebPageControl8Sheet1.Caption := 'Klassik';
    WebPageControl8Sheet1.TabVisible := False;
    WebPageControl1.SetParentComponent(WebPageControl8Sheet1);
    WebPageControl1.Name := 'WebPageControl1';
    WebPageControl1.Left := 0;
    WebPageControl1.Top := 0;
    WebPageControl1.Width := 950;
    WebPageControl1.Height := 453;
    WebPageControl1.ElementClassName := 'bg-primary mainform';
    WebPageControl1.HeightPercent := 100.000000000000000000;
    WebPageControl1.WidthPercent := 100.000000000000000000;
    WebPageControl1.Align := alClient;
    WebPageControl1.ChildOrder := 3;
    WebPageControl1.ElementFont := efCSS;
    WebPageControl1.Font.Charset := DEFAULT_CHARSET;
    WebPageControl1.Font.Color := clWindowText;
    WebPageControl1.Font.Height := -13;
    WebPageControl1.Font.Name := 'Arial';
    WebPageControl1.Font.Style := [fsBold];
    WebPageControl1.TabIndex := 0;
    WebPageControl1.ParentFont := False;
    WebPageControl1.TabOrder := 0;
    SetEvent(WebPageControl1, Self, 'OnChange', 'WebPageControl1Change');
    WebPageControl1Sheet1.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet1.Name := 'WebPageControl1Sheet1';
    WebPageControl1Sheet1.Left := 0;
    WebPageControl1Sheet1.Top := 20;
    WebPageControl1Sheet1.Width := 950;
    WebPageControl1Sheet1.Height := 433;
    WebPageControl1Sheet1.HeightPercent := 100.000000000000000000;
    WebPageControl1Sheet1.WidthPercent := 100.000000000000000000;
    WebPageControl1Sheet1.Caption := 'Warenkorb';
    WebPageControl1Sheet1.ChildOrder := 3;
    WebPageControl1Sheet1.TabVisible := False;
    WebPageControl1Sheet1.Visible := False;
    WebPanel3.SetParentComponent(WebPageControl1Sheet1);
    WebPanel3.Name := 'WebPanel3';
    WebPanel3.Left := 0;
    WebPanel3.Top := 373;
    WebPanel3.Width := 950;
    WebPanel3.Height := 60;
    WebPanel3.HeightPercent := 100.000000000000000000;
    WebPanel3.WidthPercent := 100.000000000000000000;
    WebPanel3.Align := alBottom;
    WebPanel3.BorderColor := clSilver;
    WebPanel3.BorderStyle := bsNone;
    WebPanel3.ChildOrder := 2;
    WebPanel2.SetParentComponent(WebPageControl1Sheet1);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 0;
    WebPanel2.Width := 950;
    WebPanel2.Height := 82;
    WebPanel2.HeightPercent := 100.000000000000000000;
    WebPanel2.WidthPercent := 100.000000000000000000;
    WebPanel2.Align := alTop;
    WebPanel2.BorderColor := clSilver;
    WebPanel2.BorderStyle := bsNone;
    WebPanel2.ChildOrder := 2;
    WebLabel98.SetParentComponent(WebPanel2);
    WebLabel98.Name := 'WebLabel98';
    WebLabel98.Left := 15;
    WebLabel98.Top := 6;
    WebLabel98.Width := 70;
    WebLabel98.Height := 16;
    WebLabel98.Caption := 'Warenkorb';
    WebLabel98.HeightPercent := 100.000000000000000000;
    WebLabel98.WidthPercent := 100.000000000000000000;
    WebButton108.SetParentComponent(WebPanel2);
    WebButton108.Name := 'WebButton108';
    WebButton108.Left := 6;
    WebButton108.Top := 28;
    WebButton108.Width := 197;
    WebButton108.Height := 43;
    WebButton108.Caption := 'Alle Artikel aus Warenkorb l'#246'schen';
    WebButton108.HeightPercent := 100.000000000000000000;
    WebButton108.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton108, Self, 'OnClick', 'WebButton108Click');
    WebButton110.SetParentComponent(WebPanel2);
    WebButton110.Name := 'WebButton110';
    WebButton110.Left := 209;
    WebButton110.Top := 28;
    WebButton110.Width := 106;
    WebButton110.Height := 42;
    WebButton110.Caption := 'Zur'#252'ck';
    WebButton110.ChildOrder := 1;
    WebButton110.HeightPercent := 100.000000000000000000;
    WebButton110.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton110, Self, 'OnClick', 'WebButton110Click');
    WebDBResponsiveGrid3.SetParentComponent(WebPageControl1Sheet1);
    WebDBResponsiveGrid3.Name := 'WebDBResponsiveGrid3';
    WebDBResponsiveGrid3.Left := 0;
    WebDBResponsiveGrid3.Top := 82;
    WebDBResponsiveGrid3.Width := 950;
    WebDBResponsiveGrid3.Height := 291;
    WebDBResponsiveGrid3.HeightPercent := 100.000000000000000000;
    WebDBResponsiveGrid3.WidthPercent := 100.000000000000000000;
    WebDBResponsiveGrid3.Align := alClient;
    WebDBResponsiveGrid3.ChildOrder := 2;
    WebDBResponsiveGrid3.ItemIndex := 0;
    SetEvent(WebDBResponsiveGrid3, Self, 'OnItemCreated', 'WebDBResponsiveGrid3ItemCreated');
    SetEvent(WebDBResponsiveGrid3, Self, 'OnItemGetFieldValue', 'WebDBResponsiveGrid3ItemGetFieldValue');
    SetEvent(WebDBResponsiveGrid3, Self, 'OnItemClick', 'WebDBResponsiveGrid3ItemClick');
    WebPageControl1Sheet2.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet2.Name := 'WebPageControl1Sheet2';
    WebPageControl1Sheet2.Left := 0;
    WebPageControl1Sheet2.Top := 20;
    WebPageControl1Sheet2.Width := 950;
    WebPageControl1Sheet2.Height := 433;
    WebPageControl1Sheet2.HeightPercent := 100.000000000000000000;
    WebPageControl1Sheet2.WidthPercent := 100.000000000000000000;
    WebPageControl1Sheet2.Caption := 'Artikel-Details';
    WebPageControl1Sheet2.ChildOrder := 1;
    WebPageControl1Sheet2.TabVisible := False;
    WebPageControl1Sheet2.Visible := False;
    WebPanel4.SetParentComponent(WebPageControl1Sheet2);
    WebPanel4.Name := 'WebPanel4';
    WebPanel4.Left := 0;
    WebPanel4.Top := 0;
    WebPanel4.Width := 94;
    WebPanel4.Height := 433;
    WebPanel4.HeightPercent := 100.000000000000000000;
    WebPanel4.WidthPercent := 100.000000000000000000;
    WebPanel4.Align := alLeft;
    WebPanel4.BorderColor := clSilver;
    WebPanel4.BorderStyle := bsNone;
    WebPanel10.SetParentComponent(WebPanel4);
    WebPanel10.Name := 'WebPanel10';
    WebPanel10.Left := 0;
    WebPanel10.Top := 0;
    WebPanel10.Width := 94;
    WebPanel10.Height := 433;
    WebPanel10.HeightPercent := 100.000000000000000000;
    WebPanel10.WidthPercent := 100.000000000000000000;
    WebPanel10.Align := alClient;
    WebPanel10.BorderColor := clSilver;
    WebPanel10.BorderStyle := bsNone;
    WebDBResponsiveGrid2.SetParentComponent(WebPanel10);
    WebDBResponsiveGrid2.Name := 'WebDBResponsiveGrid2';
    WebDBResponsiveGrid2.Left := 0;
    WebDBResponsiveGrid2.Top := 49;
    WebDBResponsiveGrid2.Width := 94;
    WebDBResponsiveGrid2.Height := 384;
    WebDBResponsiveGrid2.HeightPercent := 100.000000000000000000;
    WebDBResponsiveGrid2.WidthPercent := 100.000000000000000000;
    WebDBResponsiveGrid2.Align := alClient;
    WebDBResponsiveGrid2.ItemIndex := 0;
    SetEvent(WebDBResponsiveGrid2, Self, 'OnItemGetFieldValue', 'WebDBResponsiveGrid2ItemGetFieldValue');
    SetEvent(WebDBResponsiveGrid2, Self, 'OnItemClick', 'WebDBResponsiveGrid2ItemClick');
    WebDBResponsiveGrid2.DataSource := DataModule1.ShopartikelthumbsDatasource;
    WebPanel13.SetParentComponent(WebPanel10);
    WebPanel13.Name := 'WebPanel13';
    WebPanel13.Left := 0;
    WebPanel13.Top := 0;
    WebPanel13.Width := 94;
    WebPanel13.Height := 49;
    WebPanel13.ElementClassName := 'bg-primary mainform';
    WebPanel13.HeightPercent := 100.000000000000000000;
    WebPanel13.WidthPercent := 100.000000000000000000;
    WebPanel13.Align := alTop;
    WebPanel13.BorderColor := clSilver;
    WebPanel13.BorderStyle := bsNone;
    WebPanel13.ChildOrder := 1;
    WebButton8.SetParentComponent(WebPanel13);
    WebButton8.Name := 'WebButton8';
    WebButton8.Left := 0;
    WebButton8.Top := 6;
    WebButton8.Width := 62;
    WebButton8.Height := 38;
    WebButton8.Caption := 'WebButton8';
    WebButton8.Font.Charset := DEFAULT_CHARSET;
    WebButton8.Font.Color := clWindowText;
    WebButton8.Font.Height := -11;
    WebButton8.Font.Name := 'Arial';
    WebButton8.Font.Style := [fsBold];
    WebButton8.HeightPercent := 100.000000000000000000;
    WebButton8.ParentFont := False;
    WebButton8.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton8, Self, 'OnClick', 'WebButton8Click');
    WebPanel14.SetParentComponent(WebPageControl1Sheet2);
    WebPanel14.Name := 'WebPanel14';
    WebPanel14.Left := 94;
    WebPanel14.Top := 0;
    WebPanel14.Width := 856;
    WebPanel14.Height := 433;
    WebPanel14.HeightPercent := 100.000000000000000000;
    WebPanel14.WidthPercent := 100.000000000000000000;
    WebPanel14.Align := alClient;
    WebPanel14.BorderColor := clSilver;
    WebPanel14.BorderStyle := bsNone;
    WebPanel14.ChildOrder := 1;
    WebPanel15.SetParentComponent(WebPanel14);
    WebPanel15.Name := 'WebPanel15';
    WebPanel15.Left := 0;
    WebPanel15.Top := 375;
    WebPanel15.Width := 856;
    WebPanel15.Height := 58;
    WebPanel15.ElementClassName := 'bg-primary mainform';
    WebPanel15.HeightPercent := 100.000000000000000000;
    WebPanel15.WidthPercent := 100.000000000000000000;
    WebPanel15.Align := alBottom;
    WebPanel15.BorderColor := clSilver;
    WebPanel15.BorderStyle := bsNone;
    WebPanel15.ChildOrder := 3;
    WebButton1.SetParentComponent(WebPanel15);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 1;
    WebButton1.Top := 7;
    WebButton1.Width := 133;
    WebButton1.Height := 46;
    WebButton1.Caption := 'WebButton1';
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton7.SetParentComponent(WebPanel15);
    WebButton7.Name := 'WebButton7';
    WebButton7.Left := 135;
    WebButton7.Top := 7;
    WebButton7.Width := 96;
    WebButton7.Height := 46;
    WebButton7.Caption := 'WebButton7';
    WebButton7.ChildOrder := 1;
    WebButton7.HeightPercent := 100.000000000000000000;
    WebButton7.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton7, Self, 'OnClick', 'WebButton7Click');
    WebButton93.SetParentComponent(WebPanel15);
    WebButton93.Name := 'WebButton93';
    WebButton93.Left := 233;
    WebButton93.Top := 7;
    WebButton93.Width := 78;
    WebButton93.Height := 46;
    WebButton93.Caption := 'WebButton93';
    WebButton93.ChildOrder := 2;
    WebButton93.HeightPercent := 100.000000000000000000;
    WebButton93.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton93, Self, 'OnClick', 'WebButton93Click');
    WebButton100.SetParentComponent(WebPanel15);
    WebButton100.Name := 'WebButton100';
    WebButton100.Left := 325;
    WebButton100.Top := 7;
    WebButton100.Width := 174;
    WebButton100.Height := 46;
    WebButton100.Caption := 'Live-Bild anfordern';
    WebButton100.ChildOrder := 3;
    WebButton100.HeightPercent := 100.000000000000000000;
    WebButton100.Visible := False;
    WebButton100.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton100, Self, 'OnClick', 'WebButton100Click');
    WebPageControl2.SetParentComponent(WebPanel14);
    WebPageControl2.Name := 'WebPageControl2';
    WebPageControl2.Left := 0;
    WebPageControl2.Top := 79;
    WebPageControl2.Width := 856;
    WebPageControl2.Height := 296;
    WebPageControl2.HeightPercent := 100.000000000000000000;
    WebPageControl2.WidthPercent := 100.000000000000000000;
    WebPageControl2.Align := alClient;
    WebPageControl2.ChildOrder := 1;
    WebPageControl2.TabIndex := 0;
    WebPageControl2.TabOrder := 1;
    SetEvent(WebPageControl2, Self, 'OnChange', 'WebPageControl2Change');
    WebPageControl2Sheet1.SetParentComponent(WebPageControl2);
    WebPageControl2Sheet1.Name := 'WebPageControl2Sheet1';
    WebPageControl2Sheet1.Left := 0;
    WebPageControl2Sheet1.Top := 20;
    WebPageControl2Sheet1.Width := 856;
    WebPageControl2Sheet1.Height := 276;
    WebPageControl2Sheet1.ElementClassName := 'bg-primary mainform';
    WebPageControl2Sheet1.HeightPercent := 100.000000000000000000;
    WebPageControl2Sheet1.WidthPercent := 100.000000000000000000;
    WebPageControl2Sheet1.Caption := 'Grunddaten';
    WebPageControl2Sheet1.TabVisible := False;
    WebImageControl11.SetParentComponent(WebPageControl2Sheet1);
    WebImageControl11.Name := 'WebImageControl11';
    WebImageControl11.Left := 6;
    WebImageControl11.Top := 8;
    WebImageControl11.Width := 182;
    WebImageControl11.Height := 182;
    WebImageControl11.HeightPercent := 100.000000000000000000;
    WebImageControl11.WidthPercent := 100.000000000000000000;
    WebImageControl11.ChildOrder := 2;
    WebLabel29.SetParentComponent(WebPageControl2Sheet1);
    WebLabel29.Name := 'WebLabel29';
    WebLabel29.Left := 16;
    WebLabel29.Top := 344;
    WebLabel29.Width := 66;
    WebLabel29.Height := 14;
    WebLabel29.Caption := 'WebLabel29';
    WebLabel29.Font.Charset := DEFAULT_CHARSET;
    WebLabel29.Font.Color := clWindowText;
    WebLabel29.Font.Height := -11;
    WebLabel29.Font.Name := 'Arial';
    WebLabel29.Font.Style := [fsBold];
    WebLabel29.HeightPercent := 100.000000000000000000;
    WebLabel29.ParentFont := False;
    WebLabel29.WidthPercent := 100.000000000000000000;
    WebLabel30.SetParentComponent(WebPageControl2Sheet1);
    WebLabel30.Name := 'WebLabel30';
    WebLabel30.Left := 17;
    WebLabel30.Top := 302;
    WebLabel30.Width := 66;
    WebLabel30.Height := 14;
    WebLabel30.Caption := 'WebLabel30';
    WebLabel30.Font.Charset := DEFAULT_CHARSET;
    WebLabel30.Font.Color := clWindowText;
    WebLabel30.Font.Height := -11;
    WebLabel30.Font.Name := 'Arial';
    WebLabel30.Font.Style := [fsBold];
    WebLabel30.HeightPercent := 100.000000000000000000;
    WebLabel30.ParentFont := False;
    WebLabel30.WidthPercent := 100.000000000000000000;
    WebLabel31.SetParentComponent(WebPageControl2Sheet1);
    WebLabel31.Name := 'WebLabel31';
    WebLabel31.Left := 17;
    WebLabel31.Top := 263;
    WebLabel31.Width := 38;
    WebLabel31.Height := 14;
    WebLabel31.Caption := 'Menge';
    WebLabel31.Font.Charset := DEFAULT_CHARSET;
    WebLabel31.Font.Color := clWindowText;
    WebLabel31.Font.Height := -11;
    WebLabel31.Font.Name := 'Arial';
    WebLabel31.Font.Style := [fsBold];
    WebLabel31.HeightPercent := 100.000000000000000000;
    WebLabel31.ParentFont := False;
    WebLabel31.WidthPercent := 100.000000000000000000;
    WebLabel32.SetParentComponent(WebPageControl2Sheet1);
    WebLabel32.Name := 'WebLabel32';
    WebLabel32.Left := 117;
    WebLabel32.Top := 263;
    WebLabel32.Width := 38;
    WebLabel32.Height := 14;
    WebLabel32.Caption := 'Menge';
    WebLabel32.Font.Charset := DEFAULT_CHARSET;
    WebLabel32.Font.Color := clWindowText;
    WebLabel32.Font.Height := -11;
    WebLabel32.Font.Name := 'Arial';
    WebLabel32.Font.Style := [fsBold];
    WebLabel32.HeightPercent := 100.000000000000000000;
    WebLabel32.ParentFont := False;
    WebLabel32.WidthPercent := 100.000000000000000000;
    WebLabel72.SetParentComponent(WebPageControl2Sheet1);
    WebLabel72.Name := 'WebLabel72';
    WebLabel72.Left := 16;
    WebLabel72.Top := 246;
    WebLabel72.Width := 66;
    WebLabel72.Height := 14;
    WebLabel72.Caption := 'WebLabel72';
    WebLabel72.Font.Charset := DEFAULT_CHARSET;
    WebLabel72.Font.Color := clRed;
    WebLabel72.Font.Height := -11;
    WebLabel72.Font.Name := 'Arial';
    WebLabel72.Font.Style := [fsBold];
    WebLabel72.HeightPercent := 100.000000000000000000;
    WebLabel72.ParentFont := False;
    WebLabel72.WidthPercent := 100.000000000000000000;
    WebEdit5.SetParentComponent(WebPageControl2Sheet1);
    WebEdit5.Name := 'WebEdit5';
    WebEdit5.Left := 17;
    WebEdit5.Top := 279;
    WebEdit5.Width := 86;
    WebEdit5.Height := 22;
    WebEdit5.ChildOrder := 3;
    WebEdit5.HeightPercent := 100.000000000000000000;
    WebEdit5.Text := 'WebEdit5';
    WebEdit5.WidthPercent := 100.000000000000000000;
    WebComboBox4.SetParentComponent(WebPageControl2Sheet1);
    WebComboBox4.Name := 'WebComboBox4';
    WebComboBox4.Left := 116;
    WebComboBox4.Top := 279;
    WebComboBox4.Width := 99;
    WebComboBox4.Height := 24;
    WebComboBox4.HeightPercent := 100.000000000000000000;
    WebComboBox4.Text := 'WebComboBox4';
    WebComboBox4.WidthPercent := 100.000000000000000000;
    WebComboBox4.ItemIndex := -1;
    WebComboBox5.SetParentComponent(WebPageControl2Sheet1);
    WebComboBox5.Name := 'WebComboBox5';
    WebComboBox5.Left := 16;
    WebComboBox5.Top := 318;
    WebComboBox5.Width := 296;
    WebComboBox5.Height := 24;
    WebComboBox5.HeightPercent := 100.000000000000000000;
    WebComboBox5.Text := 'WebComboBox4';
    WebComboBox5.WidthPercent := 100.000000000000000000;
    WebComboBox5.ItemIndex := -1;
    WebComboBox6.SetParentComponent(WebPageControl2Sheet1);
    WebComboBox6.Name := 'WebComboBox6';
    WebComboBox6.Left := 16;
    WebComboBox6.Top := 360;
    WebComboBox6.Width := 296;
    WebComboBox6.Height := 24;
    WebComboBox6.HeightPercent := 100.000000000000000000;
    WebComboBox6.Text := 'WebComboBox4';
    WebComboBox6.WidthPercent := 100.000000000000000000;
    WebComboBox6.ItemIndex := -1;
    WebPageControl2Sheet2.SetParentComponent(WebPageControl2);
    WebPageControl2Sheet2.Name := 'WebPageControl2Sheet2';
    WebPageControl2Sheet2.Left := 0;
    WebPageControl2Sheet2.Top := 20;
    WebPageControl2Sheet2.Width := 856;
    WebPageControl2Sheet2.Height := 276;
    WebPageControl2Sheet2.HeightPercent := 100.000000000000000000;
    WebPageControl2Sheet2.WidthPercent := 100.000000000000000000;
    WebPageControl2Sheet2.Caption := 'Zutaten/Beschreibung';
    WebPageControl2Sheet2.ChildOrder := 1;
    WebPageControl2Sheet2.TabVisible := False;
    WebRichEdit8.SetParentComponent(WebPageControl2Sheet2);
    WebRichEdit8.Name := 'WebRichEdit8';
    WebRichEdit8.Left := 0;
    WebRichEdit8.Top := 40;
    WebRichEdit8.Width := 856;
    WebRichEdit8.Height := 236;
    WebRichEdit8.HeightPercent := 100.000000000000000000;
    WebRichEdit8.WidthPercent := 100.000000000000000000;
    WebRichEdit8.Align := alClient;
    WebRichEdit8.BorderStyle := bsNone;
    WebRichEdit8.Color := clWhite;
    WebRichEdit8.ReadOnly := True;
    WebPanel52.SetParentComponent(WebPageControl2Sheet2);
    WebPanel52.Name := 'WebPanel52';
    WebPanel52.Left := 0;
    WebPanel52.Top := 0;
    WebPanel52.Width := 856;
    WebPanel52.Height := 40;
    WebPanel52.HeightPercent := 100.000000000000000000;
    WebPanel52.WidthPercent := 100.000000000000000000;
    WebPanel52.Align := alTop;
    WebPanel52.BorderColor := clSilver;
    WebPanel52.BorderStyle := bsSingle;
    WebPanel52.ChildOrder := 1;
    WebLabel122.SetParentComponent(WebPanel52);
    WebLabel122.Name := 'WebLabel122';
    WebLabel122.Left := 14;
    WebLabel122.Top := 5;
    WebLabel122.Width := 142;
    WebLabel122.Height := 16;
    WebLabel122.Caption := 'Beschreibung/Zutaten:';
    WebLabel122.HeightPercent := 100.000000000000000000;
    WebLabel122.WidthPercent := 100.000000000000000000;
    WebPageControl2Sheet3.SetParentComponent(WebPageControl2);
    WebPageControl2Sheet3.Name := 'WebPageControl2Sheet3';
    WebPageControl2Sheet3.Left := 0;
    WebPageControl2Sheet3.Top := 20;
    WebPageControl2Sheet3.Width := 856;
    WebPageControl2Sheet3.Height := 276;
    WebPageControl2Sheet3.HeightPercent := 100.000000000000000000;
    WebPageControl2Sheet3.WidthPercent := 100.000000000000000000;
    WebPageControl2Sheet3.Caption := 'N'#228'hrwerte/Allergene';
    WebPageControl2Sheet3.ChildOrder := 2;
    WebPageControl2Sheet3.TabVisible := False;
    WebLabel111.SetParentComponent(WebPageControl2Sheet3);
    WebLabel111.Name := 'WebLabel111';
    WebLabel111.Left := 11;
    WebLabel111.Top := 50;
    WebLabel111.Width := 83;
    WebLabel111.Height := 14;
    WebLabel111.Caption := 'Brennwert (KJ)';
    WebLabel111.Font.Charset := DEFAULT_CHARSET;
    WebLabel111.Font.Color := clWindowText;
    WebLabel111.Font.Height := -11;
    WebLabel111.Font.Name := 'Arial';
    WebLabel111.Font.Style := [fsBold];
    WebLabel111.HeightPercent := 100.000000000000000000;
    WebLabel111.ParentFont := False;
    WebLabel111.WidthPercent := 100.000000000000000000;
    WebLabel112.SetParentComponent(WebPageControl2Sheet3);
    WebLabel112.Name := 'WebLabel112';
    WebLabel112.Left := 120;
    WebLabel112.Top := 50;
    WebLabel112.Width := 94;
    WebLabel112.Height := 14;
    WebLabel112.Caption := 'Brennwert (KCal)';
    WebLabel112.Font.Charset := DEFAULT_CHARSET;
    WebLabel112.Font.Color := clWindowText;
    WebLabel112.Font.Height := -11;
    WebLabel112.Font.Name := 'Arial';
    WebLabel112.Font.Style := [fsBold];
    WebLabel112.HeightPercent := 100.000000000000000000;
    WebLabel112.ParentFont := False;
    WebLabel112.WidthPercent := 100.000000000000000000;
    WebLabel113.SetParentComponent(WebPageControl2Sheet3);
    WebLabel113.Name := 'WebLabel113';
    WebLabel113.Left := 11;
    WebLabel113.Top := 95;
    WebLabel113.Width := 61;
    WebLabel113.Height := 14;
    WebLabel113.Caption := 'Eiweiss (g)';
    WebLabel113.Font.Charset := DEFAULT_CHARSET;
    WebLabel113.Font.Color := clWindowText;
    WebLabel113.Font.Height := -11;
    WebLabel113.Font.Name := 'Arial';
    WebLabel113.Font.Style := [fsBold];
    WebLabel113.HeightPercent := 100.000000000000000000;
    WebLabel113.ParentFont := False;
    WebLabel113.WidthPercent := 100.000000000000000000;
    WebLabel114.SetParentComponent(WebPageControl2Sheet3);
    WebLabel114.Name := 'WebLabel114';
    WebLabel114.Left := 120;
    WebLabel114.Top := 95;
    WebLabel114.Width := 40;
    WebLabel114.Height := 14;
    WebLabel114.Caption := 'Salz (g)';
    WebLabel114.Font.Charset := DEFAULT_CHARSET;
    WebLabel114.Font.Color := clWindowText;
    WebLabel114.Font.Height := -11;
    WebLabel114.Font.Name := 'Arial';
    WebLabel114.Font.Style := [fsBold];
    WebLabel114.HeightPercent := 100.000000000000000000;
    WebLabel114.ParentFont := False;
    WebLabel114.WidthPercent := 100.000000000000000000;
    WebLabel115.SetParentComponent(WebPageControl2Sheet3);
    WebLabel115.Name := 'WebLabel115';
    WebLabel115.Left := 11;
    WebLabel115.Top := 140;
    WebLabel115.Width := 98;
    WebLabel115.Height := 14;
    WebLabel115.Caption := 'Kohlenhydrate (g)';
    WebLabel115.Font.Charset := DEFAULT_CHARSET;
    WebLabel115.Font.Color := clWindowText;
    WebLabel115.Font.Height := -11;
    WebLabel115.Font.Name := 'Arial';
    WebLabel115.Font.Style := [fsBold];
    WebLabel115.HeightPercent := 100.000000000000000000;
    WebLabel115.ParentFont := False;
    WebLabel115.WidthPercent := 100.000000000000000000;
    WebLabel116.SetParentComponent(WebPageControl2Sheet3);
    WebLabel116.Name := 'WebLabel116';
    WebLabel116.Left := 120;
    WebLabel116.Top := 140;
    WebLabel116.Width := 93;
    WebLabel116.Height := 14;
    WebLabel116.Caption := 'davon Zucker (g)';
    WebLabel116.Font.Charset := DEFAULT_CHARSET;
    WebLabel116.Font.Color := clWindowText;
    WebLabel116.Font.Height := -11;
    WebLabel116.Font.Name := 'Arial';
    WebLabel116.Font.Style := [fsBold];
    WebLabel116.HeightPercent := 100.000000000000000000;
    WebLabel116.ParentFont := False;
    WebLabel116.WidthPercent := 100.000000000000000000;
    WebLabel117.SetParentComponent(WebPageControl2Sheet3);
    WebLabel117.Name := 'WebLabel117';
    WebLabel117.Left := 11;
    WebLabel117.Top := 187;
    WebLabel117.Width := 39;
    WebLabel117.Height := 14;
    WebLabel117.Caption := 'Fett (g)';
    WebLabel117.Font.Charset := DEFAULT_CHARSET;
    WebLabel117.Font.Color := clWindowText;
    WebLabel117.Font.Height := -11;
    WebLabel117.Font.Name := 'Arial';
    WebLabel117.Font.Style := [fsBold];
    WebLabel117.HeightPercent := 100.000000000000000000;
    WebLabel117.ParentFont := False;
    WebLabel117.WidthPercent := 100.000000000000000000;
    WebLabel118.SetParentComponent(WebPageControl2Sheet3);
    WebLabel118.Name := 'WebLabel118';
    WebLabel118.Left := 120;
    WebLabel118.Top := 187;
    WebLabel118.Width := 137;
    WebLabel118.Height := 14;
    WebLabel118.Caption := 'ges'#228'ttigte Fetts'#228'uren (g)';
    WebLabel118.Font.Charset := DEFAULT_CHARSET;
    WebLabel118.Font.Color := clWindowText;
    WebLabel118.Font.Height := -11;
    WebLabel118.Font.Name := 'Arial';
    WebLabel118.Font.Style := [fsBold];
    WebLabel118.HeightPercent := 100.000000000000000000;
    WebLabel118.ParentFont := False;
    WebLabel118.WidthPercent := 100.000000000000000000;
    WebLabel119.SetParentComponent(WebPageControl2Sheet3);
    WebLabel119.Name := 'WebLabel119';
    WebLabel119.Left := 4;
    WebLabel119.Top := 13;
    WebLabel119.Width := 119;
    WebLabel119.Height := 16;
    WebLabel119.Caption := 'N'#228'hrwerte je 100 g';
    WebLabel119.HeightPercent := 100.000000000000000000;
    WebLabel119.WidthPercent := 100.000000000000000000;
    WebLabel120.SetParentComponent(WebPageControl2Sheet3);
    WebLabel120.Name := 'WebLabel120';
    WebLabel120.Left := 11;
    WebLabel120.Top := 255;
    WebLabel120.Width := 138;
    WebLabel120.Height := 16;
    WebLabel120.Caption := 'Enthaltene Allergene:';
    WebLabel120.HeightPercent := 100.000000000000000000;
    WebLabel120.WidthPercent := 100.000000000000000000;
    WebLabel121.SetParentComponent(WebPageControl2Sheet3);
    WebLabel121.Name := 'WebLabel121';
    WebLabel121.Left := 15;
    WebLabel121.Top := 291;
    WebLabel121.Width := 86;
    WebLabel121.Height := 16;
    WebLabel121.Caption := 'WebLabel121';
    WebLabel121.HeightPercent := 100.000000000000000000;
    WebLabel121.WidthPercent := 100.000000000000000000;
    WebEdit41.SetParentComponent(WebPageControl2Sheet3);
    WebEdit41.Name := 'WebEdit41';
    WebEdit41.Left := 11;
    WebEdit41.Top := 69;
    WebEdit41.Width := 83;
    WebEdit41.Height := 22;
    WebEdit41.TabStop := False;
    WebEdit41.Alignment := taRightJustify;
    WebEdit41.ChildOrder := 1;
    WebEdit41.HeightPercent := 100.000000000000000000;
    WebEdit41.ReadOnly := True;
    WebEdit41.Text := 'WebEdit41';
    WebEdit41.WidthPercent := 100.000000000000000000;
    WebEdit42.SetParentComponent(WebPageControl2Sheet3);
    WebEdit42.Name := 'WebEdit42';
    WebEdit42.Left := 120;
    WebEdit42.Top := 69;
    WebEdit42.Width := 83;
    WebEdit42.Height := 22;
    WebEdit42.TabStop := False;
    WebEdit42.Alignment := taRightJustify;
    WebEdit42.ChildOrder := 1;
    WebEdit42.HeightPercent := 100.000000000000000000;
    WebEdit42.ReadOnly := True;
    WebEdit42.Text := 'WebEdit41';
    WebEdit42.WidthPercent := 100.000000000000000000;
    WebEdit43.SetParentComponent(WebPageControl2Sheet3);
    WebEdit43.Name := 'WebEdit43';
    WebEdit43.Left := 11;
    WebEdit43.Top := 114;
    WebEdit43.Width := 83;
    WebEdit43.Height := 22;
    WebEdit43.TabStop := False;
    WebEdit43.Alignment := taRightJustify;
    WebEdit43.ChildOrder := 1;
    WebEdit43.HeightPercent := 100.000000000000000000;
    WebEdit43.ReadOnly := True;
    WebEdit43.Text := 'WebEdit41';
    WebEdit43.WidthPercent := 100.000000000000000000;
    WebEdit44.SetParentComponent(WebPageControl2Sheet3);
    WebEdit44.Name := 'WebEdit44';
    WebEdit44.Left := 120;
    WebEdit44.Top := 114;
    WebEdit44.Width := 83;
    WebEdit44.Height := 22;
    WebEdit44.TabStop := False;
    WebEdit44.Alignment := taRightJustify;
    WebEdit44.ChildOrder := 1;
    WebEdit44.HeightPercent := 100.000000000000000000;
    WebEdit44.ReadOnly := True;
    WebEdit44.Text := 'WebEdit41';
    WebEdit44.WidthPercent := 100.000000000000000000;
    WebEdit45.SetParentComponent(WebPageControl2Sheet3);
    WebEdit45.Name := 'WebEdit45';
    WebEdit45.Left := 11;
    WebEdit45.Top := 159;
    WebEdit45.Width := 83;
    WebEdit45.Height := 22;
    WebEdit45.TabStop := False;
    WebEdit45.Alignment := taRightJustify;
    WebEdit45.ChildOrder := 1;
    WebEdit45.HeightPercent := 100.000000000000000000;
    WebEdit45.ReadOnly := True;
    WebEdit45.Text := 'WebEdit41';
    WebEdit45.WidthPercent := 100.000000000000000000;
    WebEdit46.SetParentComponent(WebPageControl2Sheet3);
    WebEdit46.Name := 'WebEdit46';
    WebEdit46.Left := 120;
    WebEdit46.Top := 159;
    WebEdit46.Width := 83;
    WebEdit46.Height := 22;
    WebEdit46.TabStop := False;
    WebEdit46.Alignment := taRightJustify;
    WebEdit46.ChildOrder := 1;
    WebEdit46.HeightPercent := 100.000000000000000000;
    WebEdit46.ReadOnly := True;
    WebEdit46.Text := 'WebEdit41';
    WebEdit46.WidthPercent := 100.000000000000000000;
    WebEdit47.SetParentComponent(WebPageControl2Sheet3);
    WebEdit47.Name := 'WebEdit47';
    WebEdit47.Left := 11;
    WebEdit47.Top := 206;
    WebEdit47.Width := 83;
    WebEdit47.Height := 22;
    WebEdit47.TabStop := False;
    WebEdit47.Alignment := taRightJustify;
    WebEdit47.ChildOrder := 1;
    WebEdit47.HeightPercent := 100.000000000000000000;
    WebEdit47.ReadOnly := True;
    WebEdit47.Text := 'WebEdit41';
    WebEdit47.WidthPercent := 100.000000000000000000;
    WebEdit48.SetParentComponent(WebPageControl2Sheet3);
    WebEdit48.Name := 'WebEdit48';
    WebEdit48.Left := 120;
    WebEdit48.Top := 206;
    WebEdit48.Width := 83;
    WebEdit48.Height := 22;
    WebEdit48.TabStop := False;
    WebEdit48.Alignment := taRightJustify;
    WebEdit48.ChildOrder := 1;
    WebEdit48.HeightPercent := 100.000000000000000000;
    WebEdit48.ReadOnly := True;
    WebEdit48.Text := 'WebEdit41';
    WebEdit48.WidthPercent := 100.000000000000000000;
    WebPageControl2Sheet4.SetParentComponent(WebPageControl2);
    WebPageControl2Sheet4.Name := 'WebPageControl2Sheet4';
    WebPageControl2Sheet4.Left := 0;
    WebPageControl2Sheet4.Top := 20;
    WebPageControl2Sheet4.Width := 856;
    WebPageControl2Sheet4.Height := 276;
    WebPageControl2Sheet4.HeightPercent := 100.000000000000000000;
    WebPageControl2Sheet4.WidthPercent := 100.000000000000000000;
    WebPageControl2Sheet4.Caption := 'Herkunftsangaben';
    WebPageControl2Sheet4.ChildOrder := 3;
    WebPageControl2Sheet4.TabVisible := False;
    WebPageControl2Sheet5.SetParentComponent(WebPageControl2);
    WebPageControl2Sheet5.Name := 'WebPageControl2Sheet5';
    WebPageControl2Sheet5.Left := 0;
    WebPageControl2Sheet5.Top := 20;
    WebPageControl2Sheet5.Width := 856;
    WebPageControl2Sheet5.Height := 276;
    WebPageControl2Sheet5.HeightPercent := 100.000000000000000000;
    WebPageControl2Sheet5.WidthPercent := 100.000000000000000000;
    WebPageControl2Sheet5.Caption := 'Bewertungen';
    WebPageControl2Sheet5.ChildOrder := 4;
    WebPageControl2Sheet5.TabVisible := False;
    WebPanel31.SetParentComponent(WebPageControl2Sheet5);
    WebPanel31.Name := 'WebPanel31';
    WebPanel31.Left := 0;
    WebPanel31.Top := 0;
    WebPanel31.Width := 856;
    WebPanel31.Height := 60;
    WebPanel31.HeightPercent := 100.000000000000000000;
    WebPanel31.WidthPercent := 100.000000000000000000;
    WebPanel31.Align := alTop;
    WebPanel31.BorderColor := clSilver;
    WebPanel31.BorderStyle := bsNone;
    WebLabel83.SetParentComponent(WebPanel31);
    WebLabel83.Name := 'WebLabel83';
    WebLabel83.Left := 16;
    WebLabel83.Top := 8;
    WebLabel83.Width := 59;
    WebLabel83.Height := 14;
    WebLabel83.Caption := 'Sortierung';
    WebLabel83.Font.Charset := DEFAULT_CHARSET;
    WebLabel83.Font.Color := clWindowText;
    WebLabel83.Font.Height := -11;
    WebLabel83.Font.Name := 'Arial';
    WebLabel83.Font.Style := [fsBold];
    WebLabel83.HeightPercent := 100.000000000000000000;
    WebLabel83.ParentFont := False;
    WebLabel83.WidthPercent := 100.000000000000000000;
    WebComboBox11.SetParentComponent(WebPanel31);
    WebComboBox11.Name := 'WebComboBox11';
    WebComboBox11.Left := 16;
    WebComboBox11.Top := 26;
    WebComboBox11.Width := 145;
    WebComboBox11.Height := 24;
    WebComboBox11.HeightPercent := 100.000000000000000000;
    WebComboBox11.Text := 'WebComboBox11';
    WebComboBox11.WidthPercent := 100.000000000000000000;
    SetEvent(WebComboBox11, Self, 'OnChange', 'WebComboBox11Change');
    WebComboBox11.ItemIndex := -1;
    WebComboBox11.Items.BeginUpdate;
    try
      WebComboBox11.Items.Clear;
      WebComboBox11.Items.Add('Zeit');
      WebComboBox11.Items.Add('Beste Zuerst');
      WebComboBox11.Items.Add('Schlechte zuerst');
    finally
      WebComboBox11.Items.EndUpdate;
    end;
    WebPanel32.SetParentComponent(WebPageControl2Sheet5);
    WebPanel32.Name := 'WebPanel32';
    WebPanel32.Left := 0;
    WebPanel32.Top := 176;
    WebPanel32.Width := 856;
    WebPanel32.Height := 100;
    WebPanel32.HeightPercent := 100.000000000000000000;
    WebPanel32.WidthPercent := 100.000000000000000000;
    WebPanel32.Align := alBottom;
    WebPanel32.BorderColor := clSilver;
    WebPanel32.BorderStyle := bsNone;
    WebPanel32.ChildOrder := 1;
    WebDBMemo1.SetParentComponent(WebPanel32);
    WebDBMemo1.Name := 'WebDBMemo1';
    WebDBMemo1.Left := 0;
    WebDBMemo1.Top := 0;
    WebDBMemo1.Width := 856;
    WebDBMemo1.Height := 100;
    WebDBMemo1.Align := alClient;
    WebDBMemo1.AutoSize := False;
    WebDBMemo1.HeightPercent := 100.000000000000000000;
    WebDBMemo1.Lines.BeginUpdate;
    try
      WebDBMemo1.Lines.Clear;
      WebDBMemo1.Lines.Add('WebDBMemo1');
    finally
      WebDBMemo1.Lines.EndUpdate;
    end;
    WebDBMemo1.ReadOnly := True;
    WebDBMemo1.SelLength := 0;
    WebDBMemo1.SelStart := 0;
    WebDBMemo1.WidthPercent := 100.000000000000000000;
    WebDBMemo1.DataField := 'BEM2';
    WebDBMemo1.DataSource := DataModule1.BewertungDataSource;
    WebDBGrid2.SetParentComponent(WebPageControl2Sheet5);
    WebDBGrid2.Name := 'WebDBGrid2';
    WebDBGrid2.Left := 0;
    WebDBGrid2.Top := 60;
    WebDBGrid2.Width := 856;
    WebDBGrid2.Height := 116;
    WebDBGrid2.Align := alClient;
    WebDBGrid2.Columns.Clear;
    with WebDBGrid2.Columns.Add do
    begin
      DataField := 'PUNKTE';
      Title := 'Punkte';
    end;
    with WebDBGrid2.Columns.Add do
    begin
      DataField := 'DATUHR';
      Title := 'Datum/Uhrzeit';
      Width := 155;
    end;
    WebDBGrid2.DataSource := DataModule1.BewertungDataSource;
    WebDBGrid2.FixedCols := 1;
    WebDBGrid2.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRangeSelect,goRowSelect];
    WebDBGrid2.TabOrder := 2;
    WebDBGrid2.HeightPercent := 100.000000000000000000;
    WebDBGrid2.ShowIndicator := False;
    WebDBGrid2.WidthPercent := 100.000000000000000000;
    WebDBGrid2.ColWidths[0] := 24;
    WebDBGrid2.ColWidths[1] := 64;
    WebDBGrid2.ColWidths[2] := 155;
    WebDBGrid2.RowHeights[0] := 22;
    WebDBGrid2.RowHeights[1] := 22;
    WebDBGrid2.RowHeights[2] := 22;
    WebDBGrid2.RowHeights[3] := 22;
    WebDBGrid2.RowHeights[4] := 22;
    WebPageControl2Sheet6.SetParentComponent(WebPageControl2);
    WebPageControl2Sheet6.Name := 'WebPageControl2Sheet6';
    WebPageControl2Sheet6.Left := 0;
    WebPageControl2Sheet6.Top := 20;
    WebPageControl2Sheet6.Width := 856;
    WebPageControl2Sheet6.Height := 276;
    WebPageControl2Sheet6.HeightPercent := 100.000000000000000000;
    WebPageControl2Sheet6.WidthPercent := 100.000000000000000000;
    WebPageControl2Sheet6.Caption := 'Live-Bild';
    WebPageControl2Sheet6.ChildOrder := 5;
    WebPageControl2Sheet6.TabVisible := False;
    WebImageControl10.SetParentComponent(WebPageControl2Sheet6);
    WebImageControl10.Name := 'WebImageControl10';
    WebImageControl10.Left := 0;
    WebImageControl10.Top := 35;
    WebImageControl10.Width := 856;
    WebImageControl10.Height := 241;
    WebImageControl10.HeightPercent := 100.000000000000000000;
    WebImageControl10.WidthPercent := 100.000000000000000000;
    WebImageControl10.Align := alClient;
    WebImageControl10.AutoSize := True;
    WebPanel48.SetParentComponent(WebPageControl2Sheet6);
    WebPanel48.Name := 'WebPanel48';
    WebPanel48.Left := 0;
    WebPanel48.Top := 0;
    WebPanel48.Width := 856;
    WebPanel48.Height := 35;
    WebPanel48.HeightPercent := 100.000000000000000000;
    WebPanel48.WidthPercent := 100.000000000000000000;
    WebPanel48.Align := alTop;
    WebPanel48.BorderColor := clSilver;
    WebPanel48.BorderStyle := bsSingle;
    WebPanel48.ChildOrder := 1;
    WebPanel33.SetParentComponent(WebPanel14);
    WebPanel33.Name := 'WebPanel33';
    WebPanel33.Left := 0;
    WebPanel33.Top := 0;
    WebPanel33.Width := 856;
    WebPanel33.Height := 79;
    WebPanel33.ElementClassName := 'bg-primary mainform';
    WebPanel33.HeightPercent := 100.000000000000000000;
    WebPanel33.WidthPercent := 100.000000000000000000;
    WebPanel33.Align := alTop;
    WebPanel33.BorderColor := clSilver;
    WebPanel33.BorderStyle := bsNone;
    WebPanel33.ChildOrder := 2;
    WebLabel84.SetParentComponent(WebPanel33);
    WebLabel84.Name := 'WebLabel84';
    WebLabel84.Left := 7;
    WebLabel84.Top := 8;
    WebLabel84.Width := 113;
    WebLabel84.Height := 22;
    WebLabel84.Caption := 'WebLabel84';
    WebLabel84.ElementClassName := 'h5';
    WebLabel84.ElementLabelClassName := 'badge badge-primary';
    WebLabel84.ElementFont := efCSS;
    WebLabel84.Font.Charset := DEFAULT_CHARSET;
    WebLabel84.Font.Color := clWindowText;
    WebLabel84.Font.Height := -19;
    WebLabel84.Font.Name := 'Arial';
    WebLabel84.Font.Style := [fsBold];
    WebLabel84.HeightPercent := 100.000000000000000000;
    WebLabel84.ParentFont := False;
    WebLabel84.WidthPercent := 100.000000000000000000;
    WebButton94.SetParentComponent(WebPanel33);
    WebButton94.Name := 'WebButton94';
    WebButton94.Left := 3;
    WebButton94.Top := 33;
    WebButton94.Width := 139;
    WebButton94.Height := 37;
    WebButton94.Caption := 'Grunddaten';
    WebButton94.ChildOrder := 1;
    WebButton94.HeightPercent := 100.000000000000000000;
    WebButton94.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton94, Self, 'OnClick', 'WebButton94Click');
    WebButton95.SetParentComponent(WebPanel33);
    WebButton95.Name := 'WebButton95';
    WebButton95.Left := 147;
    WebButton95.Top := 33;
    WebButton95.Width := 139;
    WebButton95.Height := 37;
    WebButton95.Caption := 'Zutaten/Beschreibung';
    WebButton95.ChildOrder := 1;
    WebButton95.HeightPercent := 100.000000000000000000;
    WebButton95.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton95, Self, 'OnClick', 'WebButton95Click');
    WebButton96.SetParentComponent(WebPanel33);
    WebButton96.Name := 'WebButton96';
    WebButton96.Left := 292;
    WebButton96.Top := 33;
    WebButton96.Width := 139;
    WebButton96.Height := 37;
    WebButton96.Caption := 'N'#228'hrwerte/Allergen';
    WebButton96.ChildOrder := 1;
    WebButton96.HeightPercent := 100.000000000000000000;
    WebButton96.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton96, Self, 'OnClick', 'WebButton96Click');
    WebButton97.SetParentComponent(WebPanel33);
    WebButton97.Name := 'WebButton97';
    WebButton97.Left := 437;
    WebButton97.Top := 33;
    WebButton97.Width := 139;
    WebButton97.Height := 37;
    WebButton97.Caption := 'Herkunft';
    WebButton97.ChildOrder := 1;
    WebButton97.HeightPercent := 100.000000000000000000;
    WebButton97.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton97, Self, 'OnClick', 'WebButton97Click');
    WebButton98.SetParentComponent(WebPanel33);
    WebButton98.Name := 'WebButton98';
    WebButton98.Left := 582;
    WebButton98.Top := 33;
    WebButton98.Width := 139;
    WebButton98.Height := 37;
    WebButton98.Caption := 'Bewertungen';
    WebButton98.ChildOrder := 1;
    WebButton98.HeightPercent := 100.000000000000000000;
    WebButton98.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton98, Self, 'OnClick', 'WebButton98Click');
    WebButton99.SetParentComponent(WebPanel33);
    WebButton99.Name := 'WebButton99';
    WebButton99.Left := 727;
    WebButton99.Top := 33;
    WebButton99.Width := 139;
    WebButton99.Height := 37;
    WebButton99.Caption := 'Live-Bild';
    WebButton99.ChildOrder := 1;
    WebButton99.HeightPercent := 100.000000000000000000;
    WebButton99.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton99, Self, 'OnClick', 'WebButton99Click');
    WebComboBox16.SetParentComponent(WebPanel33);
    WebComboBox16.Name := 'WebComboBox16';
    WebComboBox16.Left := 134;
    WebComboBox16.Top := 43;
    WebComboBox16.Width := 213;
    WebComboBox16.Height := 24;
    WebComboBox16.HeightPercent := 100.000000000000000000;
    WebComboBox16.Text := 'Grunddaten';
    WebComboBox16.WidthPercent := 100.000000000000000000;
    SetEvent(WebComboBox16, Self, 'OnChange', 'WebComboBox16Change');
    WebComboBox16.ItemIndex := 0;
    WebComboBox16.Items.BeginUpdate;
    try
      WebComboBox16.Items.Clear;
      WebComboBox16.Items.Add('Grunddaten');
      WebComboBox16.Items.Add('Zutaten/Beschreibung');
      WebComboBox16.Items.Add('N'#228'hrwerte/Allergene');
      WebComboBox16.Items.Add('Herkunft');
      WebComboBox16.Items.Add('Bewertungen');
      WebComboBox16.Items.Add('Live-Bild');
    finally
      WebComboBox16.Items.EndUpdate;
    end;
    WebPageControl1Sheet3.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet3.Name := 'WebPageControl1Sheet3';
    WebPageControl1Sheet3.Left := 0;
    WebPageControl1Sheet3.Top := 20;
    WebPageControl1Sheet3.Width := 950;
    WebPageControl1Sheet3.Height := 433;
    WebPageControl1Sheet3.HeightPercent := 100.000000000000000000;
    WebPageControl1Sheet3.WidthPercent := 100.000000000000000000;
    WebPageControl1Sheet3.Caption := 'Mein Konto';
    WebPageControl1Sheet3.ChildOrder := 2;
    WebPageControl1Sheet3.TabVisible := False;
    WebPageControl1Sheet3.Visible := False;
    WebPageControl6.SetParentComponent(WebPageControl1Sheet3);
    WebPageControl6.Name := 'WebPageControl6';
    WebPageControl6.Left := 0;
    WebPageControl6.Top := 0;
    WebPageControl6.Width := 950;
    WebPageControl6.Height := 433;
    WebPageControl6.HeightPercent := 100.000000000000000000;
    WebPageControl6.WidthPercent := 100.000000000000000000;
    WebPageControl6.Align := alClient;
    WebPageControl6.TabIndex := 0;
    WebPageControl6.TabOrder := 0;
    WebPageControl6Sheet1.SetParentComponent(WebPageControl6);
    WebPageControl6Sheet1.Name := 'WebPageControl6Sheet1';
    WebPageControl6Sheet1.Left := 0;
    WebPageControl6Sheet1.Top := 20;
    WebPageControl6Sheet1.Width := 950;
    WebPageControl6Sheet1.Height := 413;
    WebPageControl6Sheet1.HeightPercent := 100.000000000000000000;
    WebPageControl6Sheet1.WidthPercent := 100.000000000000000000;
    WebPageControl6Sheet1.Caption := 'Menu';
    WebPageControl6Sheet1.TabVisible := False;
    WebButton25.SetParentComponent(WebPageControl6Sheet1);
    WebButton25.Name := 'WebButton25';
    WebButton25.Left := 16;
    WebButton25.Top := 32;
    WebButton25.Width := 220;
    WebButton25.Height := 49;
    WebButton25.Caption := 'Meine Daten';
    WebButton25.HeightPercent := 100.000000000000000000;
    WebButton25.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton25, Self, 'OnClick', 'WebButton25Click');
    WebButton53.SetParentComponent(WebPageControl6Sheet1);
    WebButton53.Name := 'WebButton53';
    WebButton53.Left := 16;
    WebButton53.Top := 92;
    WebButton53.Width := 220;
    WebButton53.Height := 49;
    WebButton53.Caption := 'Meine Bestellungen';
    WebButton53.ChildOrder := 1;
    WebButton53.HeightPercent := 100.000000000000000000;
    WebButton53.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton53, Self, 'OnClick', 'WebButton53Click');
    WebButton54.SetParentComponent(WebPageControl6Sheet1);
    WebButton54.Name := 'WebButton54';
    WebButton54.Left := 16;
    WebButton54.Top := 152;
    WebButton54.Width := 220;
    WebButton54.Height := 49;
    WebButton54.Caption := 'Meine Rechnungen';
    WebButton54.ChildOrder := 2;
    WebButton54.HeightPercent := 100.000000000000000000;
    WebButton54.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton54, Self, 'OnClick', 'WebButton54Click');
    WebPanel51.SetParentComponent(WebPageControl6Sheet1);
    WebPanel51.Name := 'WebPanel51';
    WebPanel51.Left := 63;
    WebPanel51.Top := 276;
    WebPanel51.Width := 258;
    WebPanel51.Height := 60;
    WebPanel51.ElementClassName := 'bg-secondary';
    WebPanel51.HeightPercent := 100.000000000000000000;
    WebPanel51.WidthPercent := 100.000000000000000000;
    WebPanel51.BorderColor := clSilver;
    WebPanel51.BorderStyle := bsSingle;
    WebPanel51.ChildOrder := 3;
    WebPanel51.ElementFont := efCSS;
    WebPanel51.Visible := False;
    WebPageControl6Sheet2.SetParentComponent(WebPageControl6);
    WebPageControl6Sheet2.Name := 'WebPageControl6Sheet2';
    WebPageControl6Sheet2.Left := 0;
    WebPageControl6Sheet2.Top := 20;
    WebPageControl6Sheet2.Width := 950;
    WebPageControl6Sheet2.Height := 413;
    WebPageControl6Sheet2.HeightPercent := 100.000000000000000000;
    WebPageControl6Sheet2.WidthPercent := 100.000000000000000000;
    WebPageControl6Sheet2.Caption := 'Meine Daten';
    WebPageControl6Sheet2.ChildOrder := 1;
    WebPageControl6Sheet2.TabVisible := False;
    WebPageControl6Sheet3.SetParentComponent(WebPageControl6);
    WebPageControl6Sheet3.Name := 'WebPageControl6Sheet3';
    WebPageControl6Sheet3.Left := 0;
    WebPageControl6Sheet3.Top := 20;
    WebPageControl6Sheet3.Width := 950;
    WebPageControl6Sheet3.Height := 413;
    WebPageControl6Sheet3.HeightPercent := 100.000000000000000000;
    WebPageControl6Sheet3.WidthPercent := 100.000000000000000000;
    WebPageControl6Sheet3.Caption := 'Meine Bestellungen';
    WebPageControl6Sheet3.ChildOrder := 2;
    WebPageControl6Sheet3.TabVisible := False;
    WebPageControl1Sheet4.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet4.Name := 'WebPageControl1Sheet4';
    WebPageControl1Sheet4.Left := 0;
    WebPageControl1Sheet4.Top := 20;
    WebPageControl1Sheet4.Width := 950;
    WebPageControl1Sheet4.Height := 433;
    WebPageControl1Sheet4.HeightPercent := 100.000000000000000000;
    WebPageControl1Sheet4.WidthPercent := 100.000000000000000000;
    WebPageControl1Sheet4.Caption := 'Artikel';
    WebPageControl1Sheet4.ChildOrder := 3;
    WebPageControl1Sheet4.TabVisible := False;
    WebPanel7.SetParentComponent(WebPageControl1Sheet4);
    WebPanel7.Name := 'WebPanel7';
    WebPanel7.Left := 0;
    WebPanel7.Top := 0;
    WebPanel7.Width := 242;
    WebPanel7.Height := 433;
    WebPanel7.ElementClassName := 'bg-primary mainform';
    WebPanel7.HeightPercent := 100.000000000000000000;
    WebPanel7.WidthPercent := 100.000000000000000000;
    WebPanel7.Align := alLeft;
    WebPanel7.BorderColor := clSilver;
    WebPanel7.BorderStyle := bsNone;
    WebPanel7.ChildOrder := 3;
    WebPanel8.SetParentComponent(WebPanel7);
    WebPanel8.Name := 'WebPanel8';
    WebPanel8.Left := 0;
    WebPanel8.Top := 0;
    WebPanel8.Width := 242;
    WebPanel8.Height := 125;
    WebPanel8.ElementClassName := 'bg-primary mainform';
    WebPanel8.HeightPercent := 100.000000000000000000;
    WebPanel8.WidthPercent := 100.000000000000000000;
    WebPanel8.Align := alTop;
    WebPanel8.BorderColor := clSilver;
    WebPanel8.BorderStyle := bsNone;
    WebPanel8.ChildOrder := 1;
    WebPanel8.ElementFont := efCSS;
    WebLabel1.SetParentComponent(WebPanel8);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 5;
    WebLabel1.Top := 62;
    WebLabel1.Width := 68;
    WebLabel1.Height := 16;
    WebLabel1.Caption := 'Suchen in:';
    WebLabel1.ElementClassName := 'badge badge-primary';
    WebLabel1.ElementFont := efCSS;
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(WebPanel8);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 3;
    WebLabel2.Top := 10;
    WebLabel2.Width := 77;
    WebLabel2.Height := 16;
    WebLabel2.Caption := 'Suchbegriff:';
    WebLabel2.ElementClassName := 'badge badge-primary';
    WebLabel2.ElementFont := efCSS;
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebComboBox3.SetParentComponent(WebPanel8);
    WebComboBox3.Name := 'WebComboBox3';
    WebComboBox3.Left := 3;
    WebComboBox3.Top := 81;
    WebComboBox3.Width := 238;
    WebComboBox3.Height := 30;
    WebComboBox3.Font.Charset := DEFAULT_CHARSET;
    WebComboBox3.Font.Color := clWindowText;
    WebComboBox3.Font.Height := -19;
    WebComboBox3.Font.Name := 'Arial';
    WebComboBox3.Font.Style := [fsBold];
    WebComboBox3.HeightPercent := 100.000000000000000000;
    WebComboBox3.ParentFont := False;
    WebComboBox3.Text := 'WebComboBox3';
    WebComboBox3.WidthPercent := 100.000000000000000000;
    SetEvent(WebComboBox3, Self, 'OnChange', 'WebComboBox3Change');
    WebComboBox3.ItemIndex := -1;
    WebEdit2.SetParentComponent(WebPanel8);
    WebEdit2.Name := 'WebEdit2';
    WebEdit2.Left := 5;
    WebEdit2.Top := 27;
    WebEdit2.Width := 149;
    WebEdit2.Height := 30;
    WebEdit2.ChildOrder := 2;
    WebEdit2.Font.Charset := DEFAULT_CHARSET;
    WebEdit2.Font.Color := clWindowText;
    WebEdit2.Font.Height := -16;
    WebEdit2.Font.Name := 'Arial';
    WebEdit2.Font.Style := [fsBold];
    WebEdit2.HeightPercent := 100.000000000000000000;
    WebEdit2.ParentFont := False;
    WebEdit2.ReadOnly := True;
    WebEdit2.WidthPercent := 100.000000000000000000;
    WebButton101.SetParentComponent(WebPanel8);
    WebButton101.Name := 'WebButton101';
    WebButton101.Left := 160;
    WebButton101.Top := 23;
    WebButton101.Width := 35;
    WebButton101.Height := 36;
    WebButton101.Caption := 'WebButton101';
    WebButton101.ChildOrder := 6;
    WebButton101.HeightPercent := 100.000000000000000000;
    WebButton101.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton101, Self, 'OnClick', 'WebButton101Click');
    WebButton102.SetParentComponent(WebPanel8);
    WebButton102.Name := 'WebButton102';
    WebButton102.Left := 201;
    WebButton102.Top := 23;
    WebButton102.Width := 35;
    WebButton102.Height := 36;
    WebButton102.Caption := 'WebButton101';
    WebButton102.ChildOrder := 6;
    WebButton102.HeightPercent := 100.000000000000000000;
    WebButton102.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton102, Self, 'OnClick', 'WebButton102Click');
    WebComboBox15.SetParentComponent(WebPanel8);
    WebComboBox15.Name := 'WebComboBox15';
    WebComboBox15.Left := 32;
    WebComboBox15.Top := 112;
    WebComboBox15.Width := 145;
    WebComboBox15.Height := 24;
    WebComboBox15.HeightPercent := 100.000000000000000000;
    WebComboBox15.Text := 'WebComboBox15';
    WebComboBox15.Visible := False;
    WebComboBox15.WidthPercent := 100.000000000000000000;
    SetEvent(WebComboBox15, Self, 'OnChange', 'WebComboBox15Change');
    WebComboBox15.ItemIndex := -1;
    WebListBox1.SetParentComponent(WebPanel7);
    WebListBox1.Name := 'WebListBox1';
    WebListBox1.Left := 0;
    WebListBox1.Top := 125;
    WebListBox1.Width := 242;
    WebListBox1.Height := 180;
    WebListBox1.Align := alClient;
    WebListBox1.Font.Charset := DEFAULT_CHARSET;
    WebListBox1.Font.Color := clWindowText;
    WebListBox1.Font.Height := -16;
    WebListBox1.Font.Name := 'Arial';
    WebListBox1.Font.Style := [fsBold];
    WebListBox1.HeightPercent := 100.000000000000000000;
    WebListBox1.ItemHeight := 19;
    WebListBox1.ItemIndex := -1;
    WebListBox1.ParentFont := False;
    WebListBox1.WidthPercent := 100.000000000000000000;
    SetEvent(WebListBox1, Self, 'OnChange', 'WebListBox1Change');
    WebPanel30.SetParentComponent(WebPanel7);
    WebPanel30.Name := 'WebPanel30';
    WebPanel30.Left := 0;
    WebPanel30.Top := 305;
    WebPanel30.Width := 242;
    WebPanel30.Height := 128;
    WebPanel30.ElementClassName := 'bg-primary mainform';
    WebPanel30.HeightPercent := 100.000000000000000000;
    WebPanel30.WidthPercent := 100.000000000000000000;
    WebPanel30.Align := alBottom;
    WebPanel30.BorderColor := clSilver;
    WebPanel30.BorderStyle := bsNone;
    WebPanel30.ChildOrder := 3;
    WebLabel78.SetParentComponent(WebPanel30);
    WebLabel78.Name := 'WebLabel78';
    WebLabel78.Left := 16;
    WebLabel78.Top := 9;
    WebLabel78.Width := 28;
    WebLabel78.Height := 14;
    WebLabel78.Caption := 'Filter';
    WebLabel78.ElementClassName := 'badge badge-primary';
    WebLabel78.ElementFont := efCSS;
    WebLabel78.Font.Charset := DEFAULT_CHARSET;
    WebLabel78.Font.Color := clWindowText;
    WebLabel78.Font.Height := -11;
    WebLabel78.Font.Name := 'Arial';
    WebLabel78.Font.Style := [fsBold];
    WebLabel78.HeightPercent := 100.000000000000000000;
    WebLabel78.ParentFont := False;
    WebLabel78.WidthPercent := 100.000000000000000000;
    WebCheckBox3.SetParentComponent(WebPanel30);
    WebCheckBox3.Name := 'WebCheckBox3';
    WebCheckBox3.Left := 13;
    WebCheckBox3.Top := 96;
    WebCheckBox3.Width := 182;
    WebCheckBox3.Height := 22;
    WebCheckBox3.Caption := 'Lieferung, Abholung etc.';
    WebCheckBox3.ChildOrder := 1;
    WebCheckBox3.Color := clNone;
    WebCheckBox3.ElementClassName := 'custom-control custom-checkbox';
    WebCheckBox3.ElementLabelClassName := 'badge badge-primary';
    WebCheckBox3.Enabled := False;
    WebCheckBox3.HeightPercent := 100.000000000000000000;
    WebCheckBox3.State := cbUnchecked;
    WebCheckBox3.WidthPercent := 100.000000000000000000;
    WebCheckBox2.SetParentComponent(WebPanel30);
    WebCheckBox2.Name := 'WebCheckBox2';
    WebCheckBox2.Left := 13;
    WebCheckBox2.Top := 63;
    WebCheckBox2.Width := 163;
    WebCheckBox2.Height := 22;
    WebCheckBox2.Caption := 'Vegetarisch, Halal etc.';
    WebCheckBox2.ChildOrder := 1;
    WebCheckBox2.Color := clNone;
    WebCheckBox2.ElementClassName := 'custom-control custom-checkbox';
    WebCheckBox2.ElementLabelClassName := 'badge badge-primary';
    WebCheckBox2.ElementFont := efCSS;
    WebCheckBox2.Enabled := False;
    WebCheckBox2.HeightPercent := 100.000000000000000000;
    WebCheckBox2.State := cbUnchecked;
    WebCheckBox2.WidthPercent := 100.000000000000000000;
    WebCheckBox1.SetParentComponent(WebPanel30);
    WebCheckBox1.Name := 'WebCheckBox1';
    WebCheckBox1.Left := 13;
    WebCheckBox1.Top := 25;
    WebCheckBox1.Width := 113;
    WebCheckBox1.Height := 22;
    WebCheckBox1.Caption := 'Allergie-Filter';
    WebCheckBox1.ChildOrder := 3;
    WebCheckBox1.Color := clNone;
    WebCheckBox1.ElementClassName := 'custom-control custom-checkbox';
    WebCheckBox1.ElementLabelClassName := 'badge badge-primary';
    WebCheckBox1.ElementFont := efCSS;
    WebCheckBox1.Enabled := False;
    WebCheckBox1.HeightPercent := 100.000000000000000000;
    WebCheckBox1.State := cbUnchecked;
    WebCheckBox1.WidthPercent := 100.000000000000000000;
    SetEvent(WebCheckBox1, Self, 'OnClick', 'WebCheckBox1Click');
    WebButton103.SetParentComponent(WebPanel30);
    WebButton103.Name := 'WebButton103';
    WebButton103.Left := 201;
    WebButton103.Top := 15;
    WebButton103.Width := 35;
    WebButton103.Height := 36;
    WebButton103.Caption := 'WebButton101';
    WebButton103.ChildOrder := 6;
    WebButton103.HeightPercent := 100.000000000000000000;
    WebButton103.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton103, Self, 'OnClick', 'WebButton103Click');
    WebButton104.SetParentComponent(WebPanel30);
    WebButton104.Name := 'WebButton104';
    WebButton104.Left := 201;
    WebButton104.Top := 54;
    WebButton104.Width := 35;
    WebButton104.Height := 36;
    WebButton104.Caption := 'WebButton101';
    WebButton104.ChildOrder := 6;
    WebButton104.HeightPercent := 100.000000000000000000;
    WebButton104.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton104, Self, 'OnClick', 'WebButton104Click');
    WebButton105.SetParentComponent(WebPanel30);
    WebButton105.Name := 'WebButton105';
    WebButton105.Left := 201;
    WebButton105.Top := 92;
    WebButton105.Width := 35;
    WebButton105.Height := 36;
    WebButton105.Caption := 'WebButton101';
    WebButton105.ChildOrder := 6;
    WebButton105.HeightPercent := 100.000000000000000000;
    WebButton105.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton105, Self, 'OnClick', 'WebButton105Click');
    WebPanel11.SetParentComponent(WebPageControl1Sheet4);
    WebPanel11.Name := 'WebPanel11';
    WebPanel11.Left := 242;
    WebPanel11.Top := 0;
    WebPanel11.Width := 708;
    WebPanel11.Height := 433;
    WebPanel11.ElementClassName := 'bg-primary mainform';
    WebPanel11.HeightPercent := 100.000000000000000000;
    WebPanel11.WidthPercent := 100.000000000000000000;
    WebPanel11.Align := alClient;
    WebPanel11.BorderColor := clSilver;
    WebPanel11.BorderStyle := bsNone;
    WebPanel11.ChildOrder := 2;
    WebPageControl7.SetParentComponent(WebPanel11);
    WebPageControl7.Name := 'WebPageControl7';
    WebPageControl7.Left := 0;
    WebPageControl7.Top := 0;
    WebPageControl7.Width := 708;
    WebPageControl7.Height := 433;
    WebPageControl7.HeightPercent := 100.000000000000000000;
    WebPageControl7.WidthPercent := 100.000000000000000000;
    WebPageControl7.Align := alClient;
    WebPageControl7.TabIndex := 0;
    WebPageControl7.TabOrder := 0;
    SetEvent(WebPageControl7, Self, 'OnChange', 'WebPageControl7Change');
    WebPageControl7Sheet1.SetParentComponent(WebPageControl7);
    WebPageControl7Sheet1.Name := 'WebPageControl7Sheet1';
    WebPageControl7Sheet1.Left := 0;
    WebPageControl7Sheet1.Top := 20;
    WebPageControl7Sheet1.Width := 708;
    WebPageControl7Sheet1.Height := 413;
    WebPageControl7Sheet1.HeightPercent := 100.000000000000000000;
    WebPageControl7Sheet1.WidthPercent := 100.000000000000000000;
    WebPageControl7Sheet1.Caption := 'Ergebnisse';
    WebPageControl7Sheet1.TabVisible := False;
    WebPanel5.SetParentComponent(WebPageControl7Sheet1);
    WebPanel5.Name := 'WebPanel5';
    WebPanel5.Left := 0;
    WebPanel5.Top := 0;
    WebPanel5.Width := 708;
    WebPanel5.Height := 53;
    WebPanel5.ElementClassName := 'bg-primary mainform';
    WebPanel5.HeightPercent := 100.000000000000000000;
    WebPanel5.WidthPercent := 100.000000000000000000;
    WebPanel5.Align := alTop;
    WebPanel5.BorderColor := clSilver;
    WebPanel5.BorderStyle := bsNone;
    WebPanel5.ChildOrder := 1;
    WebPanel5.ElementFont := efCSS;
    WebLabel77.SetParentComponent(WebPanel5);
    WebLabel77.Name := 'WebLabel77';
    WebLabel77.Left := 6;
    WebLabel77.Top := 3;
    WebLabel77.Width := 67;
    WebLabel77.Height := 16;
    WebLabel77.Caption := 'Sortierung';
    WebLabel77.ElementClassName := 'badge badge-primary';
    WebLabel77.ElementFont := efCSS;
    WebLabel77.Font.Charset := DEFAULT_CHARSET;
    WebLabel77.Font.Color := clWindowText;
    WebLabel77.Font.Height := -13;
    WebLabel77.Font.Name := 'Arial';
    WebLabel77.Font.Style := [fsBold];
    WebLabel77.HeightPercent := 100.000000000000000000;
    WebLabel77.ParentFont := False;
    WebLabel77.WidthPercent := 100.000000000000000000;
    WebLabel128.SetParentComponent(WebPanel5);
    WebLabel128.Name := 'WebLabel128';
    WebLabel128.Left := 477;
    WebLabel128.Top := 11;
    WebLabel128.Width := 86;
    WebLabel128.Height := 16;
    WebLabel128.Caption := 'WebLabel128';
    WebLabel128.HeightPercent := 100.000000000000000000;
    WebLabel128.Visible := False;
    WebLabel128.WidthPercent := 100.000000000000000000;
    WebComboBox10.SetParentComponent(WebPanel5);
    WebComboBox10.Name := 'WebComboBox10';
    WebComboBox10.Left := 8;
    WebComboBox10.Top := 21;
    WebComboBox10.Width := 145;
    WebComboBox10.Height := 24;
    WebComboBox10.HeightPercent := 100.000000000000000000;
    WebComboBox10.Text := 'WebComboBox10';
    WebComboBox10.WidthPercent := 100.000000000000000000;
    SetEvent(WebComboBox10, Self, 'OnChange', 'WebComboBox10Change');
    WebComboBox10.ItemIndex := -1;
    WebButton87.SetParentComponent(WebPanel5);
    WebButton87.Name := 'WebButton87';
    WebButton87.Left := 178;
    WebButton87.Top := 8;
    WebButton87.Width := 134;
    WebButton87.Height := 35;
    WebButton87.Caption := 'Zur'#252'ck';
    WebButton87.ChildOrder := 2;
    WebButton87.HeightPercent := 100.000000000000000000;
    WebButton87.Visible := False;
    WebButton87.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton87, Self, 'OnClick', 'WebButton87Click');
    WebButton91.SetParentComponent(WebPanel5);
    WebButton91.Name := 'WebButton91';
    WebButton91.Left := 336;
    WebButton91.Top := 9;
    WebButton91.Width := 121;
    WebButton91.Height := 34;
    WebButton91.Caption := 'Chat';
    WebButton91.ChildOrder := 3;
    WebButton91.HeightPercent := 100.000000000000000000;
    WebButton91.Visible := False;
    WebButton91.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton91, Self, 'OnClick', 'WebButton91Click');
    WebDBResponsiveGrid1.SetParentComponent(WebPageControl7Sheet1);
    WebDBResponsiveGrid1.Name := 'WebDBResponsiveGrid1';
    WebDBResponsiveGrid1.Left := 150;
    WebDBResponsiveGrid1.Top := 101;
    WebDBResponsiveGrid1.Width := 558;
    WebDBResponsiveGrid1.Height := 312;
    WebDBResponsiveGrid1.HeightPercent := 100.000000000000000000;
    WebDBResponsiveGrid1.WidthPercent := 100.000000000000000000;
    WebDBResponsiveGrid1.Align := alClient;
    WebDBResponsiveGrid1.ChildOrder := 1;
    WebDBResponsiveGrid1.ItemIndex := 0;
    SetEvent(WebDBResponsiveGrid1, Self, 'OnItemGetFieldValue', 'WebDBResponsiveGrid1ItemGetFieldValue');
    SetEvent(WebDBResponsiveGrid1, Self, 'OnItemClick', 'WebDBResponsiveGrid1ItemClick');
    WebPanel42.SetParentComponent(WebPageControl7Sheet1);
    WebPanel42.Name := 'WebPanel42';
    WebPanel42.Left := 0;
    WebPanel42.Top := 101;
    WebPanel42.Width := 150;
    WebPanel42.Height := 312;
    WebPanel42.HeightPercent := 100.000000000000000000;
    WebPanel42.WidthPercent := 100.000000000000000000;
    WebPanel42.Align := alLeft;
    WebPanel42.BorderColor := clSilver;
    WebPanel42.BorderStyle := bsSingle;
    WebPanel42.ChildOrder := 2;
    WebRichEdit9.SetParentComponent(WebPanel42);
    WebRichEdit9.Name := 'WebRichEdit9';
    WebRichEdit9.Left := 0;
    WebRichEdit9.Top := 0;
    WebRichEdit9.Width := 121;
    WebRichEdit9.Height := 312;
    WebRichEdit9.ElementClassName := 'bg-secondary';
    WebRichEdit9.HeightPercent := 100.000000000000000000;
    WebRichEdit9.WidthPercent := 100.000000000000000000;
    WebRichEdit9.Align := alClient;
    WebRichEdit9.BorderStyle := bsSingle;
    WebRichEdit9.Color := clWhite;
    WebRichEdit9.ReadOnly := True;
    WebPanel47.SetParentComponent(WebPanel42);
    WebPanel47.Name := 'WebPanel47';
    WebPanel47.Left := 121;
    WebPanel47.Top := 0;
    WebPanel47.Width := 29;
    WebPanel47.Height := 312;
    WebPanel47.HeightPercent := 100.000000000000000000;
    WebPanel47.WidthPercent := 100.000000000000000000;
    WebPanel47.Align := alRight;
    WebPanel47.BorderColor := clSilver;
    WebPanel47.BorderStyle := bsSingle;
    WebPanel47.ChildOrder := 1;
    WebPanel43.SetParentComponent(WebPageControl7Sheet1);
    WebPanel43.Name := 'WebPanel43';
    WebPanel43.Left := 0;
    WebPanel43.Top := 53;
    WebPanel43.Width := 708;
    WebPanel43.Height := 48;
    WebPanel43.HeightPercent := 100.000000000000000000;
    WebPanel43.WidthPercent := 100.000000000000000000;
    WebPanel43.Align := alTop;
    WebPanel43.BorderColor := clSilver;
    WebPanel43.BorderStyle := bsSingle;
    WebPanel43.ChildOrder := 1;
    WebPanel43.Visible := False;
    WebLabel93.SetParentComponent(WebPanel43);
    WebLabel93.Name := 'WebLabel93';
    WebLabel93.Left := 6;
    WebLabel93.Top := 9;
    WebLabel93.Width := 79;
    WebLabel93.Height := 16;
    WebLabel93.Caption := 'WebLabel93';
    WebLabel93.HeightPercent := 100.000000000000000000;
    WebLabel93.WidthPercent := 100.000000000000000000;
    WebPageControl7Sheet2.SetParentComponent(WebPageControl7);
    WebPageControl7Sheet2.Name := 'WebPageControl7Sheet2';
    WebPageControl7Sheet2.Left := 0;
    WebPageControl7Sheet2.Top := 20;
    WebPageControl7Sheet2.Width := 708;
    WebPageControl7Sheet2.Height := 413;
    WebPageControl7Sheet2.HeightPercent := 100.000000000000000000;
    WebPageControl7Sheet2.WidthPercent := 100.000000000000000000;
    WebPageControl7Sheet2.Caption := 'Leer';
    WebPageControl7Sheet2.ChildOrder := 1;
    WebPageControl7Sheet2.TabVisible := False;
    WebLabel80.SetParentComponent(WebPageControl7Sheet2);
    WebLabel80.Name := 'WebLabel80';
    WebLabel80.Left := 8;
    WebLabel80.Top := 14;
    WebLabel80.Width := 269;
    WebLabel80.Height := 16;
    WebLabel80.Caption := 'Ihre Suche hat zu keinen Treffern gef'#252'hrt. ';
    WebLabel80.HeightPercent := 100.000000000000000000;
    WebLabel80.WidthPercent := 100.000000000000000000;
    WebLabel81.SetParentComponent(WebPageControl7Sheet2);
    WebLabel81.Name := 'WebLabel81';
    WebLabel81.Left := 8;
    WebLabel81.Top := 62;
    WebLabel81.Width := 150;
    WebLabel81.Height := 16;
    WebLabel81.Caption := 'm'#246'gliche Massnahmen:';
    WebLabel81.HeightPercent := 100.000000000000000000;
    WebLabel81.WidthPercent := 100.000000000000000000;
    WebLabel82.SetParentComponent(WebPageControl7Sheet2);
    WebLabel82.Name := 'WebLabel82';
    WebLabel82.Left := 8;
    WebLabel82.Top := 46;
    WebLabel82.Width := 351;
    WebLabel82.Height := 16;
    WebLabel82.Caption := 'Versuchen Sie es in einer anderen Warengruppe, oder:';
    WebLabel82.HeightPercent := 100.000000000000000000;
    WebLabel82.WidthPercent := 100.000000000000000000;
    WebButton69.SetParentComponent(WebPageControl7Sheet2);
    WebButton69.Name := 'WebButton69';
    WebButton69.Left := 8;
    WebButton69.Top := 87;
    WebButton69.Width := 212;
    WebButton69.Height := 35;
    WebButton69.Caption := 'Suche in allen Artikeln';
    WebButton69.ChildOrder := 2;
    WebButton69.HeightPercent := 100.000000000000000000;
    WebButton69.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton69, Self, 'OnClick', 'WebButton69Click');
    WebButton74.SetParentComponent(WebPageControl7Sheet2);
    WebButton74.Name := 'WebButton74';
    WebButton74.Left := 8;
    WebButton74.Top := 208;
    WebButton74.Width := 212;
    WebButton74.Height := 35;
    WebButton74.Caption := 'Allergiefilter l'#246'schen';
    WebButton74.ChildOrder := 2;
    WebButton74.HeightPercent := 100.000000000000000000;
    WebButton74.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton74, Self, 'OnClick', 'WebButton74Click');
    WebButton75.SetParentComponent(WebPageControl7Sheet2);
    WebButton75.Name := 'WebButton75';
    WebButton75.Left := 8;
    WebButton75.Top := 168;
    WebButton75.Width := 212;
    WebButton75.Height := 35;
    WebButton75.Caption := 'Suchbegriff '#228'ndern';
    WebButton75.ChildOrder := 5;
    WebButton75.HeightPercent := 100.000000000000000000;
    WebButton75.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton75, Self, 'OnClick', 'WebButton75Click');
    WebButton70.SetParentComponent(WebPageControl7Sheet2);
    WebButton70.Name := 'WebButton70';
    WebButton70.Left := 8;
    WebButton70.Top := 127;
    WebButton70.Width := 212;
    WebButton70.Height := 35;
    WebButton70.Caption := 'Suchbegriff l'#246'schen';
    WebButton70.ChildOrder := 6;
    WebButton70.HeightPercent := 100.000000000000000000;
    WebButton70.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton70, Self, 'OnClick', 'WebButton70Click');
    WebButton76.SetParentComponent(WebPageControl7Sheet2);
    WebButton76.Name := 'WebButton76';
    WebButton76.Left := 8;
    WebButton76.Top := 249;
    WebButton76.Width := 212;
    WebButton76.Height := 35;
    WebButton76.Caption := 'Ern'#228'hrungsfilter l'#246'schen';
    WebButton76.ChildOrder := 2;
    WebButton76.HeightPercent := 100.000000000000000000;
    WebButton76.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton76, Self, 'OnClick', 'WebButton76Click');
    WebButton77.SetParentComponent(WebPageControl7Sheet2);
    WebButton77.Name := 'WebButton77';
    WebButton77.Left := 8;
    WebButton77.Top := 290;
    WebButton77.Width := 212;
    WebButton77.Height := 35;
    WebButton77.Caption := 'Lieferfilter l'#246'schen';
    WebButton77.ChildOrder := 2;
    WebButton77.HeightPercent := 100.000000000000000000;
    WebButton77.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton77, Self, 'OnClick', 'WebButton77Click');
    WebPageControl7Sheet3.SetParentComponent(WebPageControl7);
    WebPageControl7Sheet3.Name := 'WebPageControl7Sheet3';
    WebPageControl7Sheet3.Left := 0;
    WebPageControl7Sheet3.Top := 20;
    WebPageControl7Sheet3.Width := 708;
    WebPageControl7Sheet3.Height := 413;
    WebPageControl7Sheet3.HeightPercent := 100.000000000000000000;
    WebPageControl7Sheet3.WidthPercent := 100.000000000000000000;
    WebPageControl7Sheet3.Caption := 'Warten';
    WebPageControl7Sheet3.ChildOrder := 2;
    WebPageControl7Sheet3.TabVisible := False;
    WebLabel79.SetParentComponent(WebPageControl7Sheet3);
    WebLabel79.Name := 'WebLabel79';
    WebLabel79.Left := 16;
    WebLabel79.Top := 40;
    WebLabel79.Width := 92;
    WebLabel79.Height := 16;
    WebLabel79.Caption := 'Suche l'#228'uft ....';
    WebLabel79.HeightPercent := 100.000000000000000000;
    WebLabel79.WidthPercent := 100.000000000000000000;
    WebProgressBar1.SetParentComponent(WebPageControl7Sheet3);
    WebProgressBar1.Name := 'WebProgressBar1';
    WebProgressBar1.Left := 16;
    WebProgressBar1.Top := 71;
    WebProgressBar1.Width := 137;
    WebProgressBar1.Height := 24;
    WebProgressBar1.HeightPercent := 100.000000000000000000;
    WebProgressBar1.WidthPercent := 100.000000000000000000;
    WebProgressBar1.ChildOrder := 1;
    WebProgressBar1.Max := 50;
    WebPageControl1Sheet5.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet5.Name := 'WebPageControl1Sheet5';
    WebPageControl1Sheet5.Left := 0;
    WebPageControl1Sheet5.Top := 20;
    WebPageControl1Sheet5.Width := 950;
    WebPageControl1Sheet5.Height := 433;
    WebPageControl1Sheet5.HeightPercent := 100.000000000000000000;
    WebPageControl1Sheet5.WidthPercent := 100.000000000000000000;
    WebPageControl1Sheet5.Caption := 'Startseite';
    WebPageControl1Sheet5.ChildOrder := 4;
    WebPageControl1Sheet5.TabVisible := False;
    WebPanel12.SetParentComponent(WebPageControl1Sheet5);
    WebPanel12.Name := 'WebPanel12';
    WebPanel12.Left := 0;
    WebPanel12.Top := 0;
    WebPanel12.Width := 950;
    WebPanel12.Height := 433;
    WebPanel12.HeightPercent := 100.000000000000000000;
    WebPanel12.WidthPercent := 100.000000000000000000;
    WebPanel12.Align := alClient;
    WebPanel12.BorderColor := clSilver;
    WebPanel12.BorderStyle := bsNone;
    WebRichEdit1.SetParentComponent(WebPanel12);
    WebRichEdit1.Name := 'WebRichEdit1';
    WebRichEdit1.Left := 0;
    WebRichEdit1.Top := 0;
    WebRichEdit1.Width := 950;
    WebRichEdit1.Height := 433;
    WebRichEdit1.HeightPercent := 100.000000000000000000;
    WebRichEdit1.WidthPercent := 100.000000000000000000;
    WebRichEdit1.Align := alClient;
    WebRichEdit1.BorderStyle := bsNone;
    WebRichEdit1.Color := clWhite;
    WebRichEdit1.ReadOnly := True;
    WebPageControl1Sheet6.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet6.Name := 'WebPageControl1Sheet6';
    WebPageControl1Sheet6.Left := 0;
    WebPageControl1Sheet6.Top := 20;
    WebPageControl1Sheet6.Width := 950;
    WebPageControl1Sheet6.Height := 433;
    WebPageControl1Sheet6.HeightPercent := 100.000000000000000000;
    WebPageControl1Sheet6.WidthPercent := 100.000000000000000000;
    WebPageControl1Sheet6.Caption := 'Kasse';
    WebPageControl1Sheet6.ChildOrder := 5;
    WebPageControl1Sheet6.TabVisible := False;
    WebPageControl4.SetParentComponent(WebPageControl1Sheet6);
    WebPageControl4.Name := 'WebPageControl4';
    WebPageControl4.Left := 0;
    WebPageControl4.Top := 0;
    WebPageControl4.Width := 950;
    WebPageControl4.Height := 433;
    WebPageControl4.HeightPercent := 100.000000000000000000;
    WebPageControl4.WidthPercent := 100.000000000000000000;
    WebPageControl4.Align := alClient;
    WebPageControl4.ChildOrder := 1;
    WebPageControl4.TabIndex := 0;
    WebPageControl4.TabOrder := 0;
    SetEvent(WebPageControl4, Self, 'OnChange', 'WebPageControl4Change');
    WebPageControl4Sheet1.SetParentComponent(WebPageControl4);
    WebPageControl4Sheet1.Name := 'WebPageControl4Sheet1';
    WebPageControl4Sheet1.Left := 0;
    WebPageControl4Sheet1.Top := 20;
    WebPageControl4Sheet1.Width := 950;
    WebPageControl4Sheet1.Height := 413;
    WebPageControl4Sheet1.HeightPercent := 100.000000000000000000;
    WebPageControl4Sheet1.WidthPercent := 100.000000000000000000;
    WebPageControl4Sheet1.Caption := 'Rechnungsanschrift';
    WebPageControl4Sheet1.TabVisible := False;
    lbName.SetParentComponent(WebPageControl4Sheet1);
    lbName.Name := 'lbName';
    lbName.Left := 10;
    lbName.Top := 37;
    lbName.Width := 38;
    lbName.Height := 14;
    lbName.Caption := 'Name *';
    lbName.Font.Charset := DEFAULT_CHARSET;
    lbName.Font.Color := clWindowText;
    lbName.Font.Height := -11;
    lbName.Font.Name := 'Arial';
    lbName.Font.Style := [fsBold];
    lbName.HeightPercent := 100.000000000000000000;
    lbName.ParentFont := False;
    lbName.WidthPercent := 100.000000000000000000;
    lbAddress1.SetParentComponent(WebPageControl4Sheet1);
    lbAddress1.Name := 'lbAddress1';
    lbAddress1.Left := 10;
    lbAddress1.Top := 86;
    lbAddress1.Width := 95;
    lbAddress1.Height := 14;
    lbAddress1.Caption := 'Address-Zeile 1 *';
    lbAddress1.Font.Charset := DEFAULT_CHARSET;
    lbAddress1.Font.Color := clWindowText;
    lbAddress1.Font.Height := -11;
    lbAddress1.Font.Name := 'Arial';
    lbAddress1.Font.Style := [fsBold];
    lbAddress1.HeightPercent := 100.000000000000000000;
    lbAddress1.ParentFont := False;
    lbAddress1.WidthPercent := 100.000000000000000000;
    lbAddress2.SetParentComponent(WebPageControl4Sheet1);
    lbAddress2.Name := 'lbAddress2';
    lbAddress2.Left := 10;
    lbAddress2.Top := 139;
    lbAddress2.Width := 89;
    lbAddress2.Height := 14;
    lbAddress2.Caption := 'Address-Zusatz';
    lbAddress2.Font.Charset := DEFAULT_CHARSET;
    lbAddress2.Font.Color := clWindowText;
    lbAddress2.Font.Height := -11;
    lbAddress2.Font.Name := 'Arial';
    lbAddress2.Font.Style := [fsBold];
    lbAddress2.HeightPercent := 100.000000000000000000;
    lbAddress2.ParentFont := False;
    lbAddress2.WidthPercent := 100.000000000000000000;
    lbCity.SetParentComponent(WebPageControl4Sheet1);
    lbCity.Name := 'lbCity';
    lbCity.Left := 10;
    lbCity.Top := 244;
    lbCity.Width := 24;
    lbCity.Height := 14;
    lbCity.Caption := 'Ort *';
    lbCity.Font.Charset := DEFAULT_CHARSET;
    lbCity.Font.Color := clWindowText;
    lbCity.Font.Height := -11;
    lbCity.Font.Name := 'Arial';
    lbCity.Font.Style := [fsBold];
    lbCity.HeightPercent := 100.000000000000000000;
    lbCity.ParentFont := False;
    lbCity.WidthPercent := 100.000000000000000000;
    lbPostalCode.SetParentComponent(WebPageControl4Sheet1);
    lbPostalCode.Name := 'lbPostalCode';
    lbPostalCode.Left := 10;
    lbPostalCode.Top := 193;
    lbPostalCode.Width := 71;
    lbPostalCode.Height := 14;
    lbPostalCode.Caption := 'Postleitzahl *';
    lbPostalCode.Font.Charset := DEFAULT_CHARSET;
    lbPostalCode.Font.Color := clWindowText;
    lbPostalCode.Font.Height := -11;
    lbPostalCode.Font.Name := 'Arial';
    lbPostalCode.Font.Style := [fsBold];
    lbPostalCode.HeightPercent := 100.000000000000000000;
    lbPostalCode.ParentFont := False;
    lbPostalCode.WidthPercent := 100.000000000000000000;
    lbState.SetParentComponent(WebPageControl4Sheet1);
    lbState.Name := 'lbState';
    lbState.Left := 10;
    lbState.Top := 297;
    lbState.Width := 34;
    lbState.Height := 14;
    lbState.Caption := 'Land *';
    lbState.Font.Charset := DEFAULT_CHARSET;
    lbState.Font.Color := clWindowText;
    lbState.Font.Height := -11;
    lbState.Font.Name := 'Arial';
    lbState.Font.Style := [fsBold];
    lbState.HeightPercent := 100.000000000000000000;
    lbState.ParentFont := False;
    lbState.WidthPercent := 100.000000000000000000;
    WebLabel34.SetParentComponent(WebPageControl4Sheet1);
    WebLabel34.Name := 'WebLabel34';
    WebLabel34.Left := 3;
    WebLabel34.Top := 386;
    WebLabel34.Width := 62;
    WebLabel34.Height := 14;
    WebLabel34.Caption := '* Pflichtfeld';
    WebLabel34.Font.Charset := DEFAULT_CHARSET;
    WebLabel34.Font.Color := clWindowText;
    WebLabel34.Font.Height := -11;
    WebLabel34.Font.Name := 'Arial';
    WebLabel34.Font.Style := [fsBold];
    WebLabel34.HeightPercent := 100.000000000000000000;
    WebLabel34.ParentFont := False;
    WebLabel34.WidthPercent := 100.000000000000000000;
    WebLabel36.SetParentComponent(WebPageControl4Sheet1);
    WebLabel36.Name := 'WebLabel36';
    WebLabel36.Left := 11;
    WebLabel36.Top := 5;
    WebLabel36.Width := 156;
    WebLabel36.Height := 19;
    WebLabel36.Caption := 'Rechnungsanschrift';
    WebLabel36.Font.Charset := DEFAULT_CHARSET;
    WebLabel36.Font.Color := clWindowText;
    WebLabel36.Font.Height := -16;
    WebLabel36.Font.Name := 'Arial';
    WebLabel36.Font.Style := [fsBold];
    WebLabel36.HeightPercent := 100.000000000000000000;
    WebLabel36.ParentFont := False;
    WebLabel36.WidthPercent := 100.000000000000000000;
    WebLabel49.SetParentComponent(WebPageControl4Sheet1);
    WebLabel49.Name := 'WebLabel49';
    WebLabel49.Left := 10;
    WebLabel49.Top := 340;
    WebLabel49.Width := 61;
    WebLabel49.Height := 14;
    WebLabel49.Caption := 'Lieferung *';
    WebLabel49.Font.Charset := DEFAULT_CHARSET;
    WebLabel49.Font.Color := clWindowText;
    WebLabel49.Font.Height := -11;
    WebLabel49.Font.Name := 'Arial';
    WebLabel49.Font.Style := [fsBold];
    WebLabel49.HeightPercent := 100.000000000000000000;
    WebLabel49.ParentFont := False;
    WebLabel49.WidthPercent := 100.000000000000000000;
    edName.SetParentComponent(WebPageControl4Sheet1);
    edName.Name := 'edName';
    edName.Left := 8;
    edName.Top := 52;
    edName.Width := 293;
    edName.Height := 30;
    edName.ChildOrder := 1;
    edName.HeightPercent := 100.000000000000000000;
    edName.TabOrder := 9;
    edName.WidthPercent := 100.000000000000000000;
    edAddress1.SetParentComponent(WebPageControl4Sheet1);
    edAddress1.Name := 'edAddress1';
    edAddress1.Left := 8;
    edAddress1.Top := 103;
    edAddress1.Width := 293;
    edAddress1.Height := 30;
    edAddress1.ChildOrder := 3;
    edAddress1.HeightPercent := 100.000000000000000000;
    edAddress1.TabOrder := 10;
    edAddress1.WidthPercent := 100.000000000000000000;
    edAddress2.SetParentComponent(WebPageControl4Sheet1);
    edAddress2.Name := 'edAddress2';
    edAddress2.Left := 8;
    edAddress2.Top := 157;
    edAddress2.Width := 293;
    edAddress2.Height := 30;
    edAddress2.ChildOrder := 5;
    edAddress2.HeightPercent := 100.000000000000000000;
    edAddress2.TabOrder := 11;
    edAddress2.WidthPercent := 100.000000000000000000;
    edCity.SetParentComponent(WebPageControl4Sheet1);
    edCity.Name := 'edCity';
    edCity.Left := 8;
    edCity.Top := 262;
    edCity.Width := 293;
    edCity.Height := 30;
    edCity.ChildOrder := 7;
    edCity.HeightPercent := 100.000000000000000000;
    edCity.TabOrder := 12;
    edCity.WidthPercent := 100.000000000000000000;
    edPostalCode.SetParentComponent(WebPageControl4Sheet1);
    edPostalCode.Name := 'edPostalCode';
    edPostalCode.Left := 8;
    edPostalCode.Top := 209;
    edPostalCode.Width := 104;
    edPostalCode.Height := 30;
    edPostalCode.ChildOrder := 9;
    edPostalCode.HeightPercent := 100.000000000000000000;
    edPostalCode.TabOrder := 13;
    edPostalCode.WidthPercent := 100.000000000000000000;
    WebComboBox1.SetParentComponent(WebPageControl4Sheet1);
    WebComboBox1.Name := 'WebComboBox1';
    WebComboBox1.Left := 8;
    WebComboBox1.Top := 313;
    WebComboBox1.Width := 292;
    WebComboBox1.Height := 24;
    WebComboBox1.HeightPercent := 100.000000000000000000;
    WebComboBox1.Text := 'WebComboBox1';
    WebComboBox1.WidthPercent := 100.000000000000000000;
    WebComboBox1.ItemIndex := -1;
    WebButton37.SetParentComponent(WebPageControl4Sheet1);
    WebButton37.Name := 'WebButton37';
    WebButton37.Left := 3;
    WebButton37.Top := 401;
    WebButton37.Width := 135;
    WebButton37.Height := 37;
    WebButton37.Caption := 'Weiter';
    WebButton37.ChildOrder := 15;
    WebButton37.HeightPercent := 100.000000000000000000;
    WebButton37.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton37, Self, 'OnClick', 'WebButton37Click');
    WebButton38.SetParentComponent(WebPageControl4Sheet1);
    WebButton38.Name := 'WebButton38';
    WebButton38.Left := 169;
    WebButton38.Top := 402;
    WebButton38.Width := 135;
    WebButton38.Height := 37;
    WebButton38.Caption := 'Zur'#252'ck';
    WebButton38.ChildOrder := 16;
    WebButton38.HeightPercent := 100.000000000000000000;
    WebButton38.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton38, Self, 'OnClick', 'WebButton38Click');
    WebComboBox7.SetParentComponent(WebPageControl4Sheet1);
    WebComboBox7.Name := 'WebComboBox7';
    WebComboBox7.Left := 8;
    WebComboBox7.Top := 355;
    WebComboBox7.Width := 292;
    WebComboBox7.Height := 24;
    WebComboBox7.HeightPercent := 100.000000000000000000;
    WebComboBox7.Text := 'WebComboBox1';
    WebComboBox7.WidthPercent := 100.000000000000000000;
    WebComboBox7.ItemIndex := -1;
    WebPageControl4Sheet2.SetParentComponent(WebPageControl4);
    WebPageControl4Sheet2.Name := 'WebPageControl4Sheet2';
    WebPageControl4Sheet2.Left := 0;
    WebPageControl4Sheet2.Top := 20;
    WebPageControl4Sheet2.Width := 950;
    WebPageControl4Sheet2.Height := 413;
    WebPageControl4Sheet2.HeightPercent := 100.000000000000000000;
    WebPageControl4Sheet2.WidthPercent := 100.000000000000000000;
    WebPageControl4Sheet2.Caption := 'Lieferanschrift';
    WebPageControl4Sheet2.ChildOrder := 1;
    WebPageControl4Sheet2.TabVisible := False;
    WebLabel33.SetParentComponent(WebPageControl4Sheet2);
    WebLabel33.Name := 'WebLabel33';
    WebLabel33.Left := 3;
    WebLabel33.Top := 393;
    WebLabel33.Width := 62;
    WebLabel33.Height := 14;
    WebLabel33.Caption := '* Pflichtfeld';
    WebLabel33.Font.Charset := DEFAULT_CHARSET;
    WebLabel33.Font.Color := clWindowText;
    WebLabel33.Font.Height := -11;
    WebLabel33.Font.Name := 'Arial';
    WebLabel33.Font.Style := [fsBold];
    WebLabel33.HeightPercent := 100.000000000000000000;
    WebLabel33.ParentFont := False;
    WebLabel33.WidthPercent := 100.000000000000000000;
    WebLabel37.SetParentComponent(WebPageControl4Sheet2);
    WebLabel37.Name := 'WebLabel37';
    WebLabel37.Left := 3;
    WebLabel37.Top := 342;
    WebLabel37.Width := 34;
    WebLabel37.Height := 14;
    WebLabel37.Caption := 'Land *';
    WebLabel37.Font.Charset := DEFAULT_CHARSET;
    WebLabel37.Font.Color := clWindowText;
    WebLabel37.Font.Height := -11;
    WebLabel37.Font.Name := 'Arial';
    WebLabel37.Font.Style := [fsBold];
    WebLabel37.HeightPercent := 100.000000000000000000;
    WebLabel37.ParentFont := False;
    WebLabel37.Visible := False;
    WebLabel37.WidthPercent := 100.000000000000000000;
    WebLabel38.SetParentComponent(WebPageControl4Sheet2);
    WebLabel38.Name := 'WebLabel38';
    WebLabel38.Left := 3;
    WebLabel38.Top := 289;
    WebLabel38.Width := 24;
    WebLabel38.Height := 14;
    WebLabel38.Caption := 'Ort *';
    WebLabel38.Font.Charset := DEFAULT_CHARSET;
    WebLabel38.Font.Color := clWindowText;
    WebLabel38.Font.Height := -11;
    WebLabel38.Font.Name := 'Arial';
    WebLabel38.Font.Style := [fsBold];
    WebLabel38.HeightPercent := 100.000000000000000000;
    WebLabel38.ParentFont := False;
    WebLabel38.WidthPercent := 100.000000000000000000;
    WebLabel39.SetParentComponent(WebPageControl4Sheet2);
    WebLabel39.Name := 'WebLabel39';
    WebLabel39.Left := 3;
    WebLabel39.Top := 238;
    WebLabel39.Width := 71;
    WebLabel39.Height := 14;
    WebLabel39.Caption := 'Postleitzahl *';
    WebLabel39.Font.Charset := DEFAULT_CHARSET;
    WebLabel39.Font.Color := clWindowText;
    WebLabel39.Font.Height := -11;
    WebLabel39.Font.Name := 'Arial';
    WebLabel39.Font.Style := [fsBold];
    WebLabel39.HeightPercent := 100.000000000000000000;
    WebLabel39.ParentFont := False;
    WebLabel39.WidthPercent := 100.000000000000000000;
    WebLabel40.SetParentComponent(WebPageControl4Sheet2);
    WebLabel40.Name := 'WebLabel40';
    WebLabel40.Left := 3;
    WebLabel40.Top := 187;
    WebLabel40.Width := 89;
    WebLabel40.Height := 14;
    WebLabel40.Caption := 'Address-Zusatz';
    WebLabel40.Font.Charset := DEFAULT_CHARSET;
    WebLabel40.Font.Color := clWindowText;
    WebLabel40.Font.Height := -11;
    WebLabel40.Font.Name := 'Arial';
    WebLabel40.Font.Style := [fsBold];
    WebLabel40.HeightPercent := 100.000000000000000000;
    WebLabel40.ParentFont := False;
    WebLabel40.WidthPercent := 100.000000000000000000;
    WebLabel41.SetParentComponent(WebPageControl4Sheet2);
    WebLabel41.Name := 'WebLabel41';
    WebLabel41.Left := 3;
    WebLabel41.Top := 136;
    WebLabel41.Width := 95;
    WebLabel41.Height := 14;
    WebLabel41.Caption := 'Address-Zeile 1 *';
    WebLabel41.Font.Charset := DEFAULT_CHARSET;
    WebLabel41.Font.Color := clWindowText;
    WebLabel41.Font.Height := -11;
    WebLabel41.Font.Name := 'Arial';
    WebLabel41.Font.Style := [fsBold];
    WebLabel41.HeightPercent := 100.000000000000000000;
    WebLabel41.ParentFont := False;
    WebLabel41.WidthPercent := 100.000000000000000000;
    WebLabel42.SetParentComponent(WebPageControl4Sheet2);
    WebLabel42.Name := 'WebLabel42';
    WebLabel42.Left := 3;
    WebLabel42.Top := 81;
    WebLabel42.Width := 38;
    WebLabel42.Height := 14;
    WebLabel42.Caption := 'Name *';
    WebLabel42.Font.Charset := DEFAULT_CHARSET;
    WebLabel42.Font.Color := clWindowText;
    WebLabel42.Font.Height := -11;
    WebLabel42.Font.Name := 'Arial';
    WebLabel42.Font.Style := [fsBold];
    WebLabel42.HeightPercent := 100.000000000000000000;
    WebLabel42.ParentFont := False;
    WebLabel42.WidthPercent := 100.000000000000000000;
    WebLabel43.SetParentComponent(WebPageControl4Sheet2);
    WebLabel43.Name := 'WebLabel43';
    WebLabel43.Left := 5;
    WebLabel43.Top := 9;
    WebLabel43.Width := 490;
    WebLabel43.Height := 19;
    WebLabel43.Caption := 'Lieferanschrift (falls abweichend <br>von Rechnungs-Addresse)';
    WebLabel43.Font.Charset := DEFAULT_CHARSET;
    WebLabel43.Font.Color := clWindowText;
    WebLabel43.Font.Height := -16;
    WebLabel43.Font.Name := 'Arial';
    WebLabel43.Font.Style := [fsBold];
    WebLabel43.HeightPercent := 100.000000000000000000;
    WebLabel43.ParentFont := False;
    WebLabel43.WidthPercent := 100.000000000000000000;
    WebEdit1.SetParentComponent(WebPageControl4Sheet2);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 3;
    WebEdit1.Top := 101;
    WebEdit1.Width := 293;
    WebEdit1.Height := 30;
    WebEdit1.ChildOrder := 1;
    WebEdit1.HeightPercent := 100.000000000000000000;
    WebEdit1.TabOrder := 9;
    WebEdit1.WidthPercent := 100.000000000000000000;
    WebEdit6.SetParentComponent(WebPageControl4Sheet2);
    WebEdit6.Name := 'WebEdit6';
    WebEdit6.Left := 3;
    WebEdit6.Top := 153;
    WebEdit6.Width := 293;
    WebEdit6.Height := 30;
    WebEdit6.ChildOrder := 3;
    WebEdit6.HeightPercent := 100.000000000000000000;
    WebEdit6.TabOrder := 10;
    WebEdit6.WidthPercent := 100.000000000000000000;
    WebEdit7.SetParentComponent(WebPageControl4Sheet2);
    WebEdit7.Name := 'WebEdit7';
    WebEdit7.Left := 3;
    WebEdit7.Top := 205;
    WebEdit7.Width := 293;
    WebEdit7.Height := 30;
    WebEdit7.ChildOrder := 5;
    WebEdit7.HeightPercent := 100.000000000000000000;
    WebEdit7.TabOrder := 11;
    WebEdit7.WidthPercent := 100.000000000000000000;
    WebEdit8.SetParentComponent(WebPageControl4Sheet2);
    WebEdit8.Name := 'WebEdit8';
    WebEdit8.Left := 3;
    WebEdit8.Top := 256;
    WebEdit8.Width := 104;
    WebEdit8.Height := 30;
    WebEdit8.ChildOrder := 9;
    WebEdit8.HeightPercent := 100.000000000000000000;
    WebEdit8.TabOrder := 13;
    WebEdit8.WidthPercent := 100.000000000000000000;
    WebEdit9.SetParentComponent(WebPageControl4Sheet2);
    WebEdit9.Name := 'WebEdit9';
    WebEdit9.Left := 3;
    WebEdit9.Top := 306;
    WebEdit9.Width := 293;
    WebEdit9.Height := 30;
    WebEdit9.ChildOrder := 7;
    WebEdit9.HeightPercent := 100.000000000000000000;
    WebEdit9.TabOrder := 12;
    WebEdit9.WidthPercent := 100.000000000000000000;
    WebComboBox2.SetParentComponent(WebPageControl4Sheet2);
    WebComboBox2.Name := 'WebComboBox2';
    WebComboBox2.Left := 3;
    WebComboBox2.Top := 359;
    WebComboBox2.Width := 292;
    WebComboBox2.Height := 24;
    WebComboBox2.HeightPercent := 100.000000000000000000;
    WebComboBox2.Text := 'WebComboBox1';
    WebComboBox2.WidthPercent := 100.000000000000000000;
    WebComboBox2.ItemIndex := -1;
    WebButton35.SetParentComponent(WebPageControl4Sheet2);
    WebButton35.Name := 'WebButton35';
    WebButton35.Left := 3;
    WebButton35.Top := 413;
    WebButton35.Width := 135;
    WebButton35.Height := 37;
    WebButton35.Caption := 'Weiter';
    WebButton35.ChildOrder := 15;
    WebButton35.HeightPercent := 100.000000000000000000;
    WebButton35.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton35, Self, 'OnClick', 'WebButton35Click');
    WebButton36.SetParentComponent(WebPageControl4Sheet2);
    WebButton36.Name := 'WebButton36';
    WebButton36.Left := 160;
    WebButton36.Top := 413;
    WebButton36.Width := 135;
    WebButton36.Height := 37;
    WebButton36.Caption := 'Zur'#252'ck';
    WebButton36.ChildOrder := 16;
    WebButton36.HeightPercent := 100.000000000000000000;
    WebButton36.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton36, Self, 'OnClick', 'WebButton36Click');
    WebPageControl4Sheet3.SetParentComponent(WebPageControl4);
    WebPageControl4Sheet3.Name := 'WebPageControl4Sheet3';
    WebPageControl4Sheet3.Left := 0;
    WebPageControl4Sheet3.Top := 20;
    WebPageControl4Sheet3.Width := 950;
    WebPageControl4Sheet3.Height := 413;
    WebPageControl4Sheet3.HeightPercent := 100.000000000000000000;
    WebPageControl4Sheet3.WidthPercent := 100.000000000000000000;
    WebPageControl4Sheet3.Caption := 'Zahlung';
    WebPageControl4Sheet3.ChildOrder := 2;
    WebPageControl4Sheet3.TabVisible := False;
    WebLabel12.SetParentComponent(WebPageControl4Sheet3);
    WebLabel12.Name := 'WebLabel12';
    WebLabel12.Left := 12;
    WebLabel12.Top := 7;
    WebLabel12.Width := 272;
    WebLabel12.Height := 22;
    WebLabel12.Caption := 'Zahlungsmethode ausw'#228'hlen';
    WebLabel12.Font.Charset := DEFAULT_CHARSET;
    WebLabel12.Font.Color := clWindowText;
    WebLabel12.Font.Height := -19;
    WebLabel12.Font.Name := 'Arial';
    WebLabel12.Font.Style := [fsBold];
    WebLabel12.HeightPercent := 100.000000000000000000;
    WebLabel12.ParentFont := False;
    WebLabel12.WidthPercent := 100.000000000000000000;
    WebLabel51.SetParentComponent(WebPageControl4Sheet3);
    WebLabel51.Name := 'WebLabel51';
    WebLabel51.Left := 392;
    WebLabel51.Top := 128;
    WebLabel51.Width := 79;
    WebLabel51.Height := 16;
    WebLabel51.Caption := 'WebLabel51';
    WebLabel51.HeightPercent := 100.000000000000000000;
    WebLabel51.Visible := False;
    WebLabel51.WidthPercent := 100.000000000000000000;
    mePayment.SetParentComponent(WebPageControl4Sheet3);
    mePayment.Name := 'mePayment';
    mePayment.Left := 671;
    mePayment.Top := 28;
    mePayment.Width := 185;
    mePayment.Height := 89;
    mePayment.AutoSize := False;
    mePayment.HeightPercent := 100.000000000000000000;
    mePayment.Lines.BeginUpdate;
    try
      mePayment.Lines.Clear;
      mePayment.Lines.Add('mePayment');
    finally
      mePayment.Lines.EndUpdate;
    end;
    mePayment.ReadOnly := True;
    mePayment.SelLength := 0;
    mePayment.SelStart := 0;
    mePayment.Visible := False;
    mePayment.WidthPercent := 100.000000000000000000;
    WebButton27.SetParentComponent(WebPageControl4Sheet3);
    WebButton27.Name := 'WebButton27';
    WebButton27.Left := 517;
    WebButton27.Top := 82;
    WebButton27.Width := 96;
    WebButton27.Height := 25;
    WebButton27.Caption := 'WebButton27';
    WebButton27.ChildOrder := 4;
    WebButton27.HeightPercent := 100.000000000000000000;
    WebButton27.Visible := False;
    WebButton27.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton27, Self, 'OnClick', 'WebButton27Click');
    WebRadioGroup1.SetParentComponent(WebPageControl4Sheet3);
    WebRadioGroup1.Name := 'WebRadioGroup1';
    WebRadioGroup1.Left := 13;
    WebRadioGroup1.Top := 37;
    WebRadioGroup1.Width := 334;
    WebRadioGroup1.Height := 213;
    WebRadioGroup1.HeightPercent := 100.000000000000000000;
    WebRadioGroup1.WidthPercent := 100.000000000000000000;
    WebRadioGroup1.Caption := 'Auswahl Zahlungsmethode';
    WebRadioGroup1.ChildOrder := 5;
    WebRadioGroup1.Columns := 1;
    WebRadioGroup1.ItemIndex := 1;
    WebRadioGroup1.Items.BeginUpdate;
    try
      WebRadioGroup1.Items.Clear;
      WebRadioGroup1.Items.Add('Restzahlung bei Abholung in Verkaufsstelle');
      WebRadioGroup1.Items.Add('Paypal');
      WebRadioGroup1.Items.Add('Vorkasse per '#220'berweisung');
      WebRadioGroup1.Items.Add('Nachnahme (+ 4,50 '#8364' Geb'#252'hren)');
    finally
      WebRadioGroup1.Items.EndUpdate;
    end;
    WebRadioGroup1.Role := '';
    SetEvent(WebRadioGroup1, Self, 'OnChange', 'WebRadioGroup1Change');
    WebCheckBox7.SetParentComponent(WebPageControl4Sheet3);
    WebCheckBox7.Name := 'WebCheckBox7';
    WebCheckBox7.Left := 14;
    WebCheckBox7.Top := 284;
    WebCheckBox7.Width := 490;
    WebCheckBox7.Height := 41;
    WebCheckBox7.Caption := 'Zahlungsmethode als Standard-Zahlungs-<br>methode speichern';
    WebCheckBox7.Checked := True;
    WebCheckBox7.ChildOrder := 8;
    WebCheckBox7.Color := clNone;
    WebCheckBox7.HeightPercent := 100.000000000000000000;
    WebCheckBox7.State := cbChecked;
    WebCheckBox7.WidthPercent := 100.000000000000000000;
    WebButton40.SetParentComponent(WebPageControl4Sheet3);
    WebButton40.Name := 'WebButton40';
    WebButton40.Left := 10;
    WebButton40.Top := 348;
    WebButton40.Width := 135;
    WebButton40.Height := 37;
    WebButton40.Caption := 'Weiter';
    WebButton40.ChildOrder := 15;
    WebButton40.HeightPercent := 100.000000000000000000;
    WebButton40.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton40, Self, 'OnClick', 'WebButton40Click');
    WebButton41.SetParentComponent(WebPageControl4Sheet3);
    WebButton41.Name := 'WebButton41';
    WebButton41.Left := 169;
    WebButton41.Top := 348;
    WebButton41.Width := 135;
    WebButton41.Height := 37;
    WebButton41.Caption := 'Zur'#252'ck';
    WebButton41.ChildOrder := 16;
    WebButton41.HeightPercent := 100.000000000000000000;
    WebButton41.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton41, Self, 'OnClick', 'WebButton41Click');
    WebButton42.SetParentComponent(WebPageControl4Sheet3);
    WebButton42.Name := 'WebButton42';
    WebButton42.Left := 384;
    WebButton42.Top := 24;
    WebButton42.Width := 96;
    WebButton42.Height := 25;
    WebButton42.Caption := 'WebButton42';
    WebButton42.ChildOrder := 7;
    WebButton42.HeightPercent := 100.000000000000000000;
    WebButton42.Visible := False;
    WebButton42.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton42, Self, 'OnClick', 'WebButton42Click');
    WebEdit12.SetParentComponent(WebPageControl4Sheet3);
    WebEdit12.Name := 'WebEdit12';
    WebEdit12.Left := 400;
    WebEdit12.Top := 168;
    WebEdit12.Width := 121;
    WebEdit12.Height := 22;
    WebEdit12.ChildOrder := 9;
    WebEdit12.HeightPercent := 100.000000000000000000;
    WebEdit12.Text := 'WebEdit12';
    WebEdit12.Visible := False;
    WebEdit12.WidthPercent := 100.000000000000000000;
    WebEdit13.SetParentComponent(WebPageControl4Sheet3);
    WebEdit13.Name := 'WebEdit13';
    WebEdit13.Left := 552;
    WebEdit13.Top := 168;
    WebEdit13.Width := 121;
    WebEdit13.Height := 22;
    WebEdit13.ChildOrder := 10;
    WebEdit13.HeightPercent := 100.000000000000000000;
    WebEdit13.Text := 'WebEdit13';
    WebEdit13.Visible := False;
    WebEdit13.WidthPercent := 100.000000000000000000;
    WebEdit14.SetParentComponent(WebPageControl4Sheet3);
    WebEdit14.Name := 'WebEdit14';
    WebEdit14.Left := 696;
    WebEdit14.Top := 168;
    WebEdit14.Width := 121;
    WebEdit14.Height := 22;
    WebEdit14.ChildOrder := 11;
    WebEdit14.HeightPercent := 100.000000000000000000;
    WebEdit14.Text := 'WebEdit14';
    WebEdit14.Visible := False;
    WebEdit14.WidthPercent := 100.000000000000000000;
    WebPageControl4Sheet4.SetParentComponent(WebPageControl4);
    WebPageControl4Sheet4.Name := 'WebPageControl4Sheet4';
    WebPageControl4Sheet4.Left := 0;
    WebPageControl4Sheet4.Top := 20;
    WebPageControl4Sheet4.Width := 950;
    WebPageControl4Sheet4.Height := 413;
    WebPageControl4Sheet4.HeightPercent := 100.000000000000000000;
    WebPageControl4Sheet4.WidthPercent := 100.000000000000000000;
    WebPageControl4Sheet4.Caption := 'Auswahl';
    WebPageControl4Sheet4.ChildOrder := 3;
    WebPageControl4Sheet4.TabVisible := False;
    WebButton28.SetParentComponent(WebPageControl4Sheet4);
    WebButton28.Name := 'WebButton28';
    WebButton28.Left := 16;
    WebButton28.Top := 262;
    WebButton28.Width := 311;
    WebButton28.Height := 49;
    WebButton28.Caption := 'Weiter ohne Anmeldung';
    WebButton28.HeightPercent := 100.000000000000000000;
    WebButton28.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton28, Self, 'OnClick', 'WebButton28Click');
    WebButton29.SetParentComponent(WebPageControl4Sheet4);
    WebButton29.Name := 'WebButton29';
    WebButton29.Left := 16;
    WebButton29.Top := 137;
    WebButton29.Width := 311;
    WebButton29.Height := 49;
    WebButton29.Caption := 'Bereits Kunde? --> Anmelden';
    WebButton29.ChildOrder := 1;
    WebButton29.HeightPercent := 100.000000000000000000;
    WebButton29.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton29, Self, 'OnClick', 'WebButton29Click');
    WebButton30.SetParentComponent(WebPageControl4Sheet4);
    WebButton30.Name := 'WebButton30';
    WebButton30.Left := 16;
    WebButton30.Top := 199;
    WebButton30.Width := 311;
    WebButton30.Height := 49;
    WebButton30.Caption := 'Als neuer Kunde registrieren';
    WebButton30.ChildOrder := 2;
    WebButton30.HeightPercent := 100.000000000000000000;
    WebButton30.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton30, Self, 'OnClick', 'WebButton30Click');
    WebButton31.SetParentComponent(WebPageControl4Sheet4);
    WebButton31.Name := 'WebButton31';
    WebButton31.Left := 16;
    WebButton31.Top := 325;
    WebButton31.Width := 311;
    WebButton31.Height := 49;
    WebButton31.Caption := 'Zur'#252'ck zum Shop';
    WebButton31.ChildOrder := 3;
    WebButton31.HeightPercent := 100.000000000000000000;
    WebButton31.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton31, Self, 'OnClick', 'WebButton31Click');
    WebButton22.SetParentComponent(WebPageControl4Sheet4);
    WebButton22.Name := 'WebButton22';
    WebButton22.Left := 16;
    WebButton22.Top := 74;
    WebButton22.Width := 311;
    WebButton22.Height := 49;
    WebButton22.Caption := 'Bereits angemeldet --> Abmeldung';
    WebButton22.ChildOrder := 1;
    WebButton22.HeightPercent := 100.000000000000000000;
    WebButton22.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton22, Self, 'OnClick', 'WebButton22Click');
    WebButton23.SetParentComponent(WebPageControl4Sheet4);
    WebButton23.Name := 'WebButton23';
    WebButton23.Left := 16;
    WebButton23.Top := 12;
    WebButton23.Width := 311;
    WebButton23.Height := 49;
    WebButton23.Caption := 'Bereits Angemeldet --> Abschluss';
    WebButton23.ChildOrder := 1;
    WebButton23.HeightPercent := 100.000000000000000000;
    WebButton23.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton23, Self, 'OnClick', 'WebButton23Click');
    WebPageControl4Sheet5.SetParentComponent(WebPageControl4);
    WebPageControl4Sheet5.Name := 'WebPageControl4Sheet5';
    WebPageControl4Sheet5.Left := 0;
    WebPageControl4Sheet5.Top := 20;
    WebPageControl4Sheet5.Width := 950;
    WebPageControl4Sheet5.Height := 413;
    WebPageControl4Sheet5.HeightPercent := 100.000000000000000000;
    WebPageControl4Sheet5.WidthPercent := 100.000000000000000000;
    WebPageControl4Sheet5.Caption := 'Login';
    WebPageControl4Sheet5.ChildOrder := 4;
    WebPageControl4Sheet5.TabVisible := False;
    WebLabel44.SetParentComponent(WebPageControl4Sheet5);
    WebLabel44.Name := 'WebLabel44';
    WebLabel44.Left := 4;
    WebLabel44.Top := 99;
    WebLabel44.Width := 81;
    WebLabel44.Height := 14;
    WebLabel44.Caption := 'eMail-Adresse';
    WebLabel44.Font.Charset := DEFAULT_CHARSET;
    WebLabel44.Font.Color := clWindowText;
    WebLabel44.Font.Height := -11;
    WebLabel44.Font.Name := 'Arial';
    WebLabel44.Font.Style := [fsBold];
    WebLabel44.HeightPercent := 100.000000000000000000;
    WebLabel44.ParentFont := False;
    WebLabel44.WidthPercent := 100.000000000000000000;
    WebLabel45.SetParentComponent(WebPageControl4Sheet5);
    WebLabel45.Name := 'WebLabel45';
    WebLabel45.Left := 7;
    WebLabel45.Top := 163;
    WebLabel45.Width := 54;
    WebLabel45.Height := 14;
    WebLabel45.Caption := 'Kennwort';
    WebLabel45.Font.Charset := DEFAULT_CHARSET;
    WebLabel45.Font.Color := clWindowText;
    WebLabel45.Font.Height := -11;
    WebLabel45.Font.Name := 'Arial';
    WebLabel45.Font.Style := [fsBold];
    WebLabel45.HeightPercent := 100.000000000000000000;
    WebLabel45.ParentFont := False;
    WebLabel45.WidthPercent := 100.000000000000000000;
    WebLabel46.SetParentComponent(WebPageControl4Sheet5);
    WebLabel46.Name := 'WebLabel46';
    WebLabel46.Left := 4;
    WebLabel46.Top := 8;
    WebLabel46.Width := 93;
    WebLabel46.Height := 22;
    WebLabel46.Caption := 'Anmelden';
    WebLabel46.Font.Charset := DEFAULT_CHARSET;
    WebLabel46.Font.Color := clWindowText;
    WebLabel46.Font.Height := -19;
    WebLabel46.Font.Name := 'Arial';
    WebLabel46.Font.Style := [fsBold];
    WebLabel46.HeightPercent := 100.000000000000000000;
    WebLabel46.ParentFont := False;
    WebLabel46.WidthPercent := 100.000000000000000000;
    WebEdit10.SetParentComponent(WebPageControl4Sheet5);
    WebEdit10.Name := 'WebEdit10';
    WebEdit10.Left := 4;
    WebEdit10.Top := 119;
    WebEdit10.Width := 253;
    WebEdit10.Height := 30;
    WebEdit10.ChildOrder := 1;
    WebEdit10.HeightPercent := 100.000000000000000000;
    WebEdit10.TabOrder := 9;
    WebEdit10.WidthPercent := 100.000000000000000000;
    WebEdit11.SetParentComponent(WebPageControl4Sheet5);
    WebEdit11.Name := 'WebEdit11';
    WebEdit11.Left := 4;
    WebEdit11.Top := 183;
    WebEdit11.Width := 253;
    WebEdit11.Height := 30;
    WebEdit11.AutoCompletion := acCurrentPassword;
    WebEdit11.ChildOrder := 3;
    WebEdit11.HeightPercent := 100.000000000000000000;
    WebEdit11.PasswordChar := '*';
    WebEdit11.TabOrder := 10;
    WebEdit11.WidthPercent := 100.000000000000000000;
    WebButton32.SetParentComponent(WebPageControl4Sheet5);
    WebButton32.Name := 'WebButton32';
    WebButton32.Left := 3;
    WebButton32.Top := 255;
    WebButton32.Width := 117;
    WebButton32.Height := 38;
    WebButton32.Caption := 'Anmelden';
    WebButton32.ChildOrder := 5;
    WebButton32.HeightPercent := 100.000000000000000000;
    WebButton32.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton32, Self, 'OnClick', 'WebButton32Click');
    WebButton33.SetParentComponent(WebPageControl4Sheet5);
    WebButton33.Name := 'WebButton33';
    WebButton33.Left := 126;
    WebButton33.Top := 254;
    WebButton33.Width := 132;
    WebButton33.Height := 38;
    WebButton33.Caption := 'Kennwort vergessen?';
    WebButton33.ChildOrder := 6;
    WebButton33.HeightPercent := 100.000000000000000000;
    WebButton33.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton33, Self, 'OnClick', 'WebButton33Click');
    WebButton34.SetParentComponent(WebPageControl4Sheet5);
    WebButton34.Name := 'WebButton34';
    WebButton34.Left := 3;
    WebButton34.Top := 43;
    WebButton34.Width := 251;
    WebButton34.Height := 38;
    WebButton34.Caption := 'Zur'#252'ck';
    WebButton34.ChildOrder := 7;
    WebButton34.HeightPercent := 100.000000000000000000;
    WebButton34.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton34, Self, 'OnClick', 'WebButton34Click');
    WebButton39.SetParentComponent(WebPageControl4Sheet5);
    WebButton39.Name := 'WebButton39';
    WebButton39.Left := 262;
    WebButton39.Top := 182;
    WebButton39.Width := 45;
    WebButton39.Height := 31;
    WebButton39.Caption := 'WebButton39';
    WebButton39.ChildOrder := 8;
    WebButton39.HeightPercent := 100.000000000000000000;
    WebButton39.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton39, Self, 'OnClick', 'WebButton39Click');
    WebCheckBox5.SetParentComponent(WebPageControl4Sheet5);
    WebCheckBox5.Name := 'WebCheckBox5';
    WebCheckBox5.Left := 4;
    WebCheckBox5.Top := 223;
    WebCheckBox5.Width := 293;
    WebCheckBox5.Height := 22;
    WebCheckBox5.Caption := 'Anmeldedaten speichern';
    WebCheckBox5.ChildOrder := 9;
    WebCheckBox5.Color := clNone;
    WebCheckBox5.HeightPercent := 100.000000000000000000;
    WebCheckBox5.State := cbUnchecked;
    WebCheckBox5.Visible := False;
    WebCheckBox5.WidthPercent := 100.000000000000000000;
    WebPageControl4Sheet6.SetParentComponent(WebPageControl4);
    WebPageControl4Sheet6.Name := 'WebPageControl4Sheet6';
    WebPageControl4Sheet6.Left := 0;
    WebPageControl4Sheet6.Top := 20;
    WebPageControl4Sheet6.Width := 950;
    WebPageControl4Sheet6.Height := 413;
    WebPageControl4Sheet6.HeightPercent := 100.000000000000000000;
    WebPageControl4Sheet6.WidthPercent := 100.000000000000000000;
    WebPageControl4Sheet6.Caption := 'RegisterNew';
    WebPageControl4Sheet6.ChildOrder := 5;
    WebPageControl4Sheet6.TabVisible := False;
    WebPageControl5.SetParentComponent(WebPageControl4Sheet6);
    WebPageControl5.Name := 'WebPageControl5';
    WebPageControl5.Left := 0;
    WebPageControl5.Top := 0;
    WebPageControl5.Width := 950;
    WebPageControl5.Height := 413;
    WebPageControl5.HeightPercent := 100.000000000000000000;
    WebPageControl5.WidthPercent := 100.000000000000000000;
    WebPageControl5.Align := alClient;
    WebPageControl5.TabIndex := 0;
    WebPageControl5.TabOrder := 0;
    WebPageControl5Sheet1.SetParentComponent(WebPageControl5);
    WebPageControl5Sheet1.Name := 'WebPageControl5Sheet1';
    WebPageControl5Sheet1.Left := 0;
    WebPageControl5Sheet1.Top := 20;
    WebPageControl5Sheet1.Width := 950;
    WebPageControl5Sheet1.Height := 393;
    WebPageControl5Sheet1.HeightPercent := 100.000000000000000000;
    WebPageControl5Sheet1.WidthPercent := 100.000000000000000000;
    WebPageControl5Sheet1.Caption := 'Adresse';
    WebPageControl5Sheet1.TabVisible := False;
    WebLabel52.SetParentComponent(WebPageControl5Sheet1);
    WebLabel52.Name := 'WebLabel52';
    WebLabel52.Left := 14;
    WebLabel52.Top := 12;
    WebLabel52.Width := 260;
    WebLabel52.Height := 19;
    WebLabel52.Caption := 'Neuer Kunde: 1. Adresse erfassen';
    WebLabel52.Font.Charset := DEFAULT_CHARSET;
    WebLabel52.Font.Color := clWindowText;
    WebLabel52.Font.Height := -16;
    WebLabel52.Font.Name := 'Arial';
    WebLabel52.Font.Style := [fsBold];
    WebLabel52.HeightPercent := 100.000000000000000000;
    WebLabel52.ParentFont := False;
    WebLabel52.WidthPercent := 100.000000000000000000;
    WebLabel53.SetParentComponent(WebPageControl5Sheet1);
    WebLabel53.Name := 'WebLabel53';
    WebLabel53.Left := 16;
    WebLabel53.Top := 67;
    WebLabel53.Width := 46;
    WebLabel53.Height := 16;
    WebLabel53.Caption := 'Name *';
    WebLabel53.HeightPercent := 100.000000000000000000;
    WebLabel53.WidthPercent := 100.000000000000000000;
    WebLabel54.SetParentComponent(WebPageControl5Sheet1);
    WebLabel54.Name := 'WebLabel54';
    WebLabel54.Left := 16;
    WebLabel54.Top := 110;
    WebLabel54.Width := 105;
    WebLabel54.Height := 16;
    WebLabel54.Caption := 'Address-Zeile 1 *';
    WebLabel54.HeightPercent := 100.000000000000000000;
    WebLabel54.WidthPercent := 100.000000000000000000;
    WebLabel55.SetParentComponent(WebPageControl5Sheet1);
    WebLabel55.Name := 'WebLabel55';
    WebLabel55.Left := 16;
    WebLabel55.Top := 146;
    WebLabel55.Width := 96;
    WebLabel55.Height := 16;
    WebLabel55.Caption := 'Address-Zeile 2';
    WebLabel55.HeightPercent := 100.000000000000000000;
    WebLabel55.WidthPercent := 100.000000000000000000;
    WebLabel56.SetParentComponent(WebPageControl5Sheet1);
    WebLabel56.Name := 'WebLabel56';
    WebLabel56.Left := 16;
    WebLabel56.Top := 186;
    WebLabel56.Width := 83;
    WebLabel56.Height := 16;
    WebLabel56.Caption := 'Postleitzahl *';
    WebLabel56.HeightPercent := 100.000000000000000000;
    WebLabel56.WidthPercent := 100.000000000000000000;
    WebLabel57.SetParentComponent(WebPageControl5Sheet1);
    WebLabel57.Name := 'WebLabel57';
    WebLabel57.Left := 16;
    WebLabel57.Top := 235;
    WebLabel57.Width := 28;
    WebLabel57.Height := 16;
    WebLabel57.Caption := 'Ort *';
    WebLabel57.HeightPercent := 100.000000000000000000;
    WebLabel57.WidthPercent := 100.000000000000000000;
    WebLabel58.SetParentComponent(WebPageControl5Sheet1);
    WebLabel58.Name := 'WebLabel58';
    WebLabel58.Left := 16;
    WebLabel58.Top := 271;
    WebLabel58.Width := 41;
    WebLabel58.Height := 16;
    WebLabel58.Caption := 'Land *';
    WebLabel58.HeightPercent := 100.000000000000000000;
    WebLabel58.WidthPercent := 100.000000000000000000;
    WebLabel59.SetParentComponent(WebPageControl5Sheet1);
    WebLabel59.Name := 'WebLabel59';
    WebLabel59.Left := -3;
    WebLabel59.Top := 303;
    WebLabel59.Width := 73;
    WebLabel59.Height := 16;
    WebLabel59.Caption := '* Pflichtfeld';
    WebLabel59.HeightPercent := 100.000000000000000000;
    WebLabel59.WidthPercent := 100.000000000000000000;
    WebEdit15.SetParentComponent(WebPageControl5Sheet1);
    WebEdit15.Name := 'WebEdit15';
    WebEdit15.Left := 126;
    WebEdit15.Top := 71;
    WebEdit15.Width := 293;
    WebEdit15.Height := 30;
    WebEdit15.ChildOrder := 1;
    WebEdit15.HeightPercent := 100.000000000000000000;
    WebEdit15.TabOrder := 9;
    WebEdit15.WidthPercent := 100.000000000000000000;
    WebEdit16.SetParentComponent(WebPageControl5Sheet1);
    WebEdit16.Name := 'WebEdit16';
    WebEdit16.Left := 126;
    WebEdit16.Top := 108;
    WebEdit16.Width := 293;
    WebEdit16.Height := 30;
    WebEdit16.ChildOrder := 3;
    WebEdit16.HeightPercent := 100.000000000000000000;
    WebEdit16.TabOrder := 10;
    WebEdit16.WidthPercent := 100.000000000000000000;
    WebEdit17.SetParentComponent(WebPageControl5Sheet1);
    WebEdit17.Name := 'WebEdit17';
    WebEdit17.Left := 126;
    WebEdit17.Top := 144;
    WebEdit17.Width := 293;
    WebEdit17.Height := 30;
    WebEdit17.ChildOrder := 5;
    WebEdit17.HeightPercent := 100.000000000000000000;
    WebEdit17.TabOrder := 11;
    WebEdit17.WidthPercent := 100.000000000000000000;
    WebEdit18.SetParentComponent(WebPageControl5Sheet1);
    WebEdit18.Name := 'WebEdit18';
    WebEdit18.Left := 126;
    WebEdit18.Top := 184;
    WebEdit18.Width := 104;
    WebEdit18.Height := 30;
    WebEdit18.ChildOrder := 9;
    WebEdit18.HeightPercent := 100.000000000000000000;
    WebEdit18.TabOrder := 13;
    WebEdit18.WidthPercent := 100.000000000000000000;
    WebEdit19.SetParentComponent(WebPageControl5Sheet1);
    WebEdit19.Name := 'WebEdit19';
    WebEdit19.Left := 126;
    WebEdit19.Top := 223;
    WebEdit19.Width := 293;
    WebEdit19.Height := 30;
    WebEdit19.ChildOrder := 7;
    WebEdit19.HeightPercent := 100.000000000000000000;
    WebEdit19.TabOrder := 12;
    WebEdit19.WidthPercent := 100.000000000000000000;
    WebComboBox8.SetParentComponent(WebPageControl5Sheet1);
    WebComboBox8.Name := 'WebComboBox8';
    WebComboBox8.Left := 126;
    WebComboBox8.Top := 266;
    WebComboBox8.Width := 292;
    WebComboBox8.Height := 24;
    WebComboBox8.HeightPercent := 100.000000000000000000;
    WebComboBox8.Text := 'WebComboBox1';
    WebComboBox8.WidthPercent := 100.000000000000000000;
    WebComboBox8.ItemIndex := -1;
    WebButton45.SetParentComponent(WebPageControl5Sheet1);
    WebButton45.Name := 'WebButton45';
    WebButton45.Left := 109;
    WebButton45.Top := 310;
    WebButton45.Width := 135;
    WebButton45.Height := 37;
    WebButton45.Caption := 'Weiter';
    WebButton45.ChildOrder := 15;
    WebButton45.HeightPercent := 100.000000000000000000;
    WebButton45.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton45, Self, 'OnClick', 'WebButton45Click');
    WebButton46.SetParentComponent(WebPageControl5Sheet1);
    WebButton46.Name := 'WebButton46';
    WebButton46.Left := 265;
    WebButton46.Top := 312;
    WebButton46.Width := 135;
    WebButton46.Height := 37;
    WebButton46.Caption := 'Zur'#252'ck';
    WebButton46.ChildOrder := 16;
    WebButton46.HeightPercent := 100.000000000000000000;
    WebButton46.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton46, Self, 'OnClick', 'WebButton46Click');
    WebPageControl5Sheet2.SetParentComponent(WebPageControl5);
    WebPageControl5Sheet2.Name := 'WebPageControl5Sheet2';
    WebPageControl5Sheet2.Left := 0;
    WebPageControl5Sheet2.Top := 20;
    WebPageControl5Sheet2.Width := 950;
    WebPageControl5Sheet2.Height := 393;
    WebPageControl5Sheet2.HeightPercent := 100.000000000000000000;
    WebPageControl5Sheet2.WidthPercent := 100.000000000000000000;
    WebPageControl5Sheet2.Caption := 'email';
    WebPageControl5Sheet2.ChildOrder := 1;
    WebPageControl5Sheet2.TabVisible := False;
    WebLabel60.SetParentComponent(WebPageControl5Sheet2);
    WebLabel60.Name := 'WebLabel60';
    WebLabel60.Left := 10;
    WebLabel60.Top := 14;
    WebLabel60.Width := 288;
    WebLabel60.Height := 19;
    WebLabel60.Caption := 'Neuer Kunde:   2. eMail und Kennwort';
    WebLabel60.Font.Charset := DEFAULT_CHARSET;
    WebLabel60.Font.Color := clWindowText;
    WebLabel60.Font.Height := -16;
    WebLabel60.Font.Name := 'Arial';
    WebLabel60.Font.Style := [fsBold];
    WebLabel60.HeightPercent := 100.000000000000000000;
    WebLabel60.ParentFont := False;
    WebLabel60.WidthPercent := 100.000000000000000000;
    WebLabel61.SetParentComponent(WebPageControl5Sheet2);
    WebLabel61.Name := 'WebLabel61';
    WebLabel61.Left := 3;
    WebLabel61.Top := 63;
    WebLabel61.Width := 89;
    WebLabel61.Height := 16;
    WebLabel61.Caption := 'eMail-Adresse';
    WebLabel61.HeightPercent := 100.000000000000000000;
    WebLabel61.WidthPercent := 100.000000000000000000;
    WebLabel62.SetParentComponent(WebPageControl5Sheet2);
    WebLabel62.Name := 'WebLabel62';
    WebLabel62.Left := 3;
    WebLabel62.Top := 103;
    WebLabel62.Width := 212;
    WebLabel62.Height := 16;
    WebLabel62.Caption := 'Wiederholung<br>eMail-Adresse ';
    WebLabel62.HeightPercent := 100.000000000000000000;
    WebLabel62.WidthPercent := 100.000000000000000000;
    WebLabel63.SetParentComponent(WebPageControl5Sheet2);
    WebLabel63.Name := 'WebLabel63';
    WebLabel63.Left := 3;
    WebLabel63.Top := 137;
    WebLabel63.Width := 61;
    WebLabel63.Height := 16;
    WebLabel63.Caption := 'Kennwort';
    WebLabel63.HeightPercent := 100.000000000000000000;
    WebLabel63.WidthPercent := 100.000000000000000000;
    WebLabel64.SetParentComponent(WebPageControl5Sheet2);
    WebLabel64.Name := 'WebLabel64';
    WebLabel64.Left := 3;
    WebLabel64.Top := 188;
    WebLabel64.Width := 180;
    WebLabel64.Height := 16;
    WebLabel64.Caption := 'Wiederholung<br>Kennwort';
    WebLabel64.HeightPercent := 100.000000000000000000;
    WebLabel64.WidthPercent := 100.000000000000000000;
    WebLabel65.SetParentComponent(WebPageControl5Sheet2);
    WebLabel65.Name := 'WebLabel65';
    WebLabel65.Left := 3;
    WebLabel65.Top := 232;
    WebLabel65.Width := 73;
    WebLabel65.Height := 16;
    WebLabel65.Caption := '* Pflichtfeld';
    WebLabel65.HeightPercent := 100.000000000000000000;
    WebLabel65.WidthPercent := 100.000000000000000000;
    WebEdit20.SetParentComponent(WebPageControl5Sheet2);
    WebEdit20.Name := 'WebEdit20';
    WebEdit20.Left := 140;
    WebEdit20.Top := 96;
    WebEdit20.Width := 293;
    WebEdit20.Height := 30;
    WebEdit20.ChildOrder := 3;
    WebEdit20.HeightPercent := 100.000000000000000000;
    WebEdit20.TabOrder := 10;
    WebEdit20.WidthPercent := 100.000000000000000000;
    WebEdit21.SetParentComponent(WebPageControl5Sheet2);
    WebEdit21.Name := 'WebEdit21';
    WebEdit21.Left := 140;
    WebEdit21.Top := 59;
    WebEdit21.Width := 293;
    WebEdit21.Height := 30;
    WebEdit21.ChildOrder := 1;
    WebEdit21.HeightPercent := 100.000000000000000000;
    WebEdit21.TabOrder := 9;
    WebEdit21.WidthPercent := 100.000000000000000000;
    WebEdit22.SetParentComponent(WebPageControl5Sheet2);
    WebEdit22.Name := 'WebEdit22';
    WebEdit22.Left := 140;
    WebEdit22.Top := 132;
    WebEdit22.Width := 293;
    WebEdit22.Height := 30;
    WebEdit22.ChildOrder := 5;
    WebEdit22.HeightPercent := 100.000000000000000000;
    WebEdit22.PasswordChar := '*';
    WebEdit22.TabOrder := 11;
    WebEdit22.WidthPercent := 100.000000000000000000;
    WebEdit23.SetParentComponent(WebPageControl5Sheet2);
    WebEdit23.Name := 'WebEdit23';
    WebEdit23.Left := 140;
    WebEdit23.Top := 178;
    WebEdit23.Width := 293;
    WebEdit23.Height := 30;
    WebEdit23.ChildOrder := 7;
    WebEdit23.HeightPercent := 100.000000000000000000;
    WebEdit23.PasswordChar := '*';
    WebEdit23.TabOrder := 12;
    WebEdit23.WidthPercent := 100.000000000000000000;
    WebButton47.SetParentComponent(WebPageControl5Sheet2);
    WebButton47.Name := 'WebButton47';
    WebButton47.Left := 123;
    WebButton47.Top := 273;
    WebButton47.Width := 135;
    WebButton47.Height := 37;
    WebButton47.Caption := 'Weiter';
    WebButton47.ChildOrder := 15;
    WebButton47.HeightPercent := 100.000000000000000000;
    WebButton47.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton47, Self, 'OnClick', 'WebButton47Click');
    WebButton48.SetParentComponent(WebPageControl5Sheet2);
    WebButton48.Name := 'WebButton48';
    WebButton48.Left := 282;
    WebButton48.Top := 272;
    WebButton48.Width := 135;
    WebButton48.Height := 37;
    WebButton48.Caption := 'Zur'#252'ck';
    WebButton48.ChildOrder := 16;
    WebButton48.HeightPercent := 100.000000000000000000;
    WebButton48.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton48, Self, 'OnClick', 'WebButton48Click');
    WebButton49.SetParentComponent(WebPageControl5Sheet2);
    WebButton49.Name := 'WebButton49';
    WebButton49.Left := 439;
    WebButton49.Top := 130;
    WebButton49.Width := 53;
    WebButton49.Height := 31;
    WebButton49.Caption := 'WebButton39';
    WebButton49.ChildOrder := 8;
    WebButton49.HeightPercent := 100.000000000000000000;
    WebButton49.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton49, Self, 'OnClick', 'WebButton49Click');
    WebPageControl5Sheet3.SetParentComponent(WebPageControl5);
    WebPageControl5Sheet3.Name := 'WebPageControl5Sheet3';
    WebPageControl5Sheet3.Left := 0;
    WebPageControl5Sheet3.Top := 20;
    WebPageControl5Sheet3.Width := 950;
    WebPageControl5Sheet3.Height := 393;
    WebPageControl5Sheet3.HeightPercent := 100.000000000000000000;
    WebPageControl5Sheet3.WidthPercent := 100.000000000000000000;
    WebPageControl5Sheet3.Caption := 'Best'#228'tigung';
    WebPageControl5Sheet3.ChildOrder := 2;
    WebPageControl5Sheet3.TabVisible := False;
    WebLabel66.SetParentComponent(WebPageControl5Sheet3);
    WebLabel66.Name := 'WebLabel66';
    WebLabel66.Left := 11;
    WebLabel66.Top := 57;
    WebLabel66.Width := 728;
    WebLabel66.Height := 16;
    WebLabel66.Caption := 'Pr'#252'fen Sie bitte Ihren eMail-Eingang<br>(ggf. auch den Spam-Ordner)<br>und geben den zugeschickten Code ein.';
    WebLabel66.HeightPercent := 100.000000000000000000;
    WebLabel66.WidthPercent := 100.000000000000000000;
    WebLabel67.SetParentComponent(WebPageControl5Sheet3);
    WebLabel67.Name := 'WebLabel67';
    WebLabel67.Left := 14;
    WebLabel67.Top := 15;
    WebLabel67.Width := 255;
    WebLabel67.Height := 19;
    WebLabel67.Caption := 'Neuer Kunde:  3. eMail best'#228'tigen';
    WebLabel67.Font.Charset := DEFAULT_CHARSET;
    WebLabel67.Font.Color := clWindowText;
    WebLabel67.Font.Height := -16;
    WebLabel67.Font.Name := 'Arial';
    WebLabel67.Font.Style := [fsBold];
    WebLabel67.HeightPercent := 100.000000000000000000;
    WebLabel67.ParentFont := False;
    WebLabel67.WidthPercent := 100.000000000000000000;
    WebLabel68.SetParentComponent(WebPageControl5Sheet3);
    WebLabel68.Name := 'WebLabel68';
    WebLabel68.Left := 17;
    WebLabel68.Top := 118;
    WebLabel68.Width := 100;
    WebLabel68.Height := 14;
    WebLabel68.Caption := 'Best'#228'tigungscode';
    WebLabel68.Font.Charset := DEFAULT_CHARSET;
    WebLabel68.Font.Color := clWindowText;
    WebLabel68.Font.Height := -11;
    WebLabel68.Font.Name := 'Arial';
    WebLabel68.Font.Style := [fsBold];
    WebLabel68.HeightPercent := 100.000000000000000000;
    WebLabel68.ParentFont := False;
    WebLabel68.WidthPercent := 100.000000000000000000;
    WebButton50.SetParentComponent(WebPageControl5Sheet3);
    WebButton50.Name := 'WebButton50';
    WebButton50.Left := 16;
    WebButton50.Top := 178;
    WebButton50.Width := 220;
    WebButton50.Height := 45;
    WebButton50.Caption := 'Best'#228'tigungscode abschicken';
    WebButton50.ChildOrder := 3;
    WebButton50.HeightPercent := 100.000000000000000000;
    WebButton50.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton50, Self, 'OnClick', 'WebButton50Click');
    WebButton51.SetParentComponent(WebPageControl5Sheet3);
    WebButton51.Name := 'WebButton51';
    WebButton51.Left := 16;
    WebButton51.Top := 239;
    WebButton51.Width := 220;
    WebButton51.Height := 48;
    WebButton51.Caption := 'Neue eMail anfordern';
    WebButton51.ChildOrder := 1;
    WebButton51.HeightPercent := 100.000000000000000000;
    WebButton51.WidthPercent := 100.000000000000000000;
    WebEdit24.SetParentComponent(WebPageControl5Sheet3);
    WebEdit24.Name := 'WebEdit24';
    WebEdit24.Left := 17;
    WebEdit24.Top := 141;
    WebEdit24.Width := 121;
    WebEdit24.Height := 22;
    WebEdit24.ChildOrder := 4;
    WebEdit24.HeightPercent := 100.000000000000000000;
    WebEdit24.WidthPercent := 100.000000000000000000;
    WebButton52.SetParentComponent(WebPageControl5Sheet3);
    WebButton52.Name := 'WebButton52';
    WebButton52.Left := 16;
    WebButton52.Top := 296;
    WebButton52.Width := 220;
    WebButton52.Height := 48;
    WebButton52.Caption := 'Zur'#252'ck';
    WebButton52.ChildOrder := 1;
    WebButton52.HeightPercent := 100.000000000000000000;
    WebButton52.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton52, Self, 'OnClick', 'WebButton52Click');
    WebPageControl4Sheet7.SetParentComponent(WebPageControl4);
    WebPageControl4Sheet7.Name := 'WebPageControl4Sheet7';
    WebPageControl4Sheet7.Left := 0;
    WebPageControl4Sheet7.Top := 20;
    WebPageControl4Sheet7.Width := 950;
    WebPageControl4Sheet7.Height := 413;
    WebPageControl4Sheet7.HeightPercent := 100.000000000000000000;
    WebPageControl4Sheet7.WidthPercent := 100.000000000000000000;
    WebPageControl4Sheet7.Caption := 'Checkout';
    WebPageControl4Sheet7.ChildOrder := 6;
    WebPageControl4Sheet7.TabVisible := False;
    WebPanel21.SetParentComponent(WebPageControl4Sheet7);
    WebPanel21.Name := 'WebPanel21';
    WebPanel21.Left := 0;
    WebPanel21.Top := 251;
    WebPanel21.Width := 950;
    WebPanel21.Height := 162;
    WebPanel21.HeightPercent := 100.000000000000000000;
    WebPanel21.WidthPercent := 100.000000000000000000;
    WebPanel21.Align := alBottom;
    WebPanel21.BorderColor := clSilver;
    WebPanel21.BorderStyle := bsNone;
    WebPanel21.ChildOrder := 1;
    WebLabel47.SetParentComponent(WebPanel21);
    WebLabel47.Name := 'WebLabel47';
    WebLabel47.Left := 26;
    WebLabel47.Top := 5;
    WebLabel47.Width := 79;
    WebLabel47.Height := 16;
    WebLabel47.Caption := 'WebLabel47';
    WebLabel47.HeightPercent := 100.000000000000000000;
    WebLabel47.WidthPercent := 100.000000000000000000;
    WebLabel48.SetParentComponent(WebPanel21);
    WebLabel48.Name := 'WebLabel48';
    WebLabel48.Left := 26;
    WebLabel48.Top := 27;
    WebLabel48.Width := 79;
    WebLabel48.Height := 16;
    WebLabel48.Caption := 'WebLabel48';
    WebLabel48.HeightPercent := 100.000000000000000000;
    WebLabel48.WidthPercent := 100.000000000000000000;
    WebPayPal1.SetParentComponent(WebPanel21);
    WebPayPal1.Name := 'WebPayPal1';
    WebPayPal1.Left := 14;
    WebPayPal1.Top := 52;
    WebPayPal1.Width := 200;
    WebPayPal1.Height := 40;
    WebPayPal1.HeightPercent := 100.000000000000000000;
    WebPayPal1.WidthPercent := 100.000000000000000000;
    WebPayPal1.Payment.Currency := pcEUR;
    WebPayPal1.Payment.Locale := plDE_DE;
    SetEvent(WebPayPal1, Self, 'OnPaymentDone', 'WebPayPal1PaymentDone');
    SetEvent(WebPayPal1, Self, 'OnPaymentCancelled', 'WebPayPal1PaymentCancelled');
    SetEvent(WebPayPal1, Self, 'OnPaymentError', 'WebPayPal1PaymentError');
    WebButton26.SetParentComponent(WebPanel21);
    WebButton26.Name := 'WebButton26';
    WebButton26.Left := 5;
    WebButton26.Top := 109;
    WebButton26.Width := 194;
    WebButton26.Height := 40;
    WebButton26.Caption := 'Kostenpflichtig bestellen';
    WebButton26.ChildOrder := 3;
    WebButton26.HeightPercent := 100.000000000000000000;
    WebButton26.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton26, Self, 'OnClick', 'WebButton26Click');
    WebButton24.SetParentComponent(WebPanel21);
    WebButton24.Name := 'WebButton24';
    WebButton24.Left := 203;
    WebButton24.Top := 109;
    WebButton24.Width := 96;
    WebButton24.Height := 40;
    WebButton24.Caption := 'Zur'#252'ck';
    WebButton24.ChildOrder := 4;
    WebButton24.HeightPercent := 100.000000000000000000;
    WebButton24.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton24, Self, 'OnClick', 'WebButton24Click');
    WebPanel22.SetParentComponent(WebPageControl4Sheet7);
    WebPanel22.Name := 'WebPanel22';
    WebPanel22.Left := 0;
    WebPanel22.Top := 0;
    WebPanel22.Width := 950;
    WebPanel22.Height := 46;
    WebPanel22.HeightPercent := 100.000000000000000000;
    WebPanel22.WidthPercent := 100.000000000000000000;
    WebPanel22.Align := alTop;
    WebPanel22.BorderColor := clSilver;
    WebPanel22.BorderStyle := bsNone;
    WebPanel22.ChildOrder := 1;
    WebLabel50.SetParentComponent(WebPanel22);
    WebLabel50.Name := 'WebLabel50';
    WebLabel50.Left := 15;
    WebLabel50.Top := 10;
    WebLabel50.Width := 227;
    WebLabel50.Height := 22;
    WebLabel50.Caption := 'Bestellung abschliessen';
    WebLabel50.Font.Charset := DEFAULT_CHARSET;
    WebLabel50.Font.Color := clWindowText;
    WebLabel50.Font.Height := -19;
    WebLabel50.Font.Name := 'Arial';
    WebLabel50.Font.Style := [fsBold];
    WebLabel50.HeightPercent := 100.000000000000000000;
    WebLabel50.ParentFont := False;
    WebLabel50.WidthPercent := 100.000000000000000000;
    WebDBGrid1.SetParentComponent(WebPageControl4Sheet7);
    WebDBGrid1.Name := 'WebDBGrid1';
    WebDBGrid1.Left := 0;
    WebDBGrid1.Top := 46;
    WebDBGrid1.Width := 950;
    WebDBGrid1.Height := 205;
    WebDBGrid1.Align := alClient;
    WebDBGrid1.Columns.Clear;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'BEZEICHNUNG';
      Title := 'Artikel';
      Width := 211;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'MENGE';
      Title := 'Menge';
      Width := 86;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'ZSTR1';
      Title := 'Einheit';
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'PREIS';
      Title := 'E-Preis';
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'GPREIS';
      Title := 'G-Preis';
    end;
    WebDBGrid1.DataSource := DataModule1.VWarenkorbDataSource;
    WebDBGrid1.FixedCols := 0;
    WebDBGrid1.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRangeSelect,goRowSelect];
    WebDBGrid1.TabOrder := 2;
    WebDBGrid1.HeightPercent := 100.000000000000000000;
    WebDBGrid1.ShowIndicator := False;
    WebDBGrid1.WidthPercent := 100.000000000000000000;
    SetEvent(WebDBGrid1, Self, 'OnGetCellData', 'WebDBGrid1GetCellData');
    WebDBGrid1.ColWidths[0] := 211;
    WebDBGrid1.ColWidths[1] := 86;
    WebDBGrid1.ColWidths[2] := 64;
    WebDBGrid1.ColWidths[3] := 64;
    WebDBGrid1.ColWidths[4] := 64;
    WebDBGrid1.RowHeights[0] := 22;
    WebDBGrid1.RowHeights[1] := 22;
    WebDBGrid1.RowHeights[2] := 22;
    WebDBGrid1.RowHeights[3] := 22;
    WebDBGrid1.RowHeights[4] := 22;
    WebPageControl1Sheet7.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet7.Name := 'WebPageControl1Sheet7';
    WebPageControl1Sheet7.Left := 0;
    WebPageControl1Sheet7.Top := 20;
    WebPageControl1Sheet7.Width := 950;
    WebPageControl1Sheet7.Height := 433;
    WebPageControl1Sheet7.HeightPercent := 100.000000000000000000;
    WebPageControl1Sheet7.WidthPercent := 100.000000000000000000;
    WebPageControl1Sheet7.Caption := 'Bilder';
    WebPageControl1Sheet7.ChildOrder := 6;
    WebPageControl1Sheet7.TabVisible := False;
    WebImageSlider1.SetParentComponent(WebPageControl1Sheet7);
    WebImageSlider1.Name := 'WebImageSlider1';
    WebImageSlider1.Left := 0;
    WebImageSlider1.Top := 0;
    WebImageSlider1.Width := 950;
    WebImageSlider1.Height := 433;
    WebImageSlider1.HeightPercent := 100.000000000000000000;
    WebImageSlider1.WidthPercent := 100.000000000000000000;
    WebImageSlider1.Appearance.NavigationStyle := navsThumbnails;
    WebImageSlider1.Appearance.TransitionEffect := tefSlide;
    WebImageSlider1.Appearance.Bullets.Opacity := 1.000000000000000000;
    WebImageSlider1.Appearance.Thumbnails.Opacity := 0.500000000000000000;
    WebImageSlider1.Appearance.Thumbnails.OpacityActive := 1.000000000000000000;
    WebImageSlider1.Align := alClient;
    WebImageSlider1.Color := clWhite;
    WebButton9.SetParentComponent(WebPageControl1Sheet7);
    WebButton9.Name := 'WebButton9';
    WebButton9.Left := 776;
    WebButton9.Top := 16;
    WebButton9.Width := 96;
    WebButton9.Height := 25;
    WebButton9.Caption := 'WebButton9';
    WebButton9.ChildOrder := 1;
    WebButton9.HeightPercent := 100.000000000000000000;
    WebButton9.WidthPercent := 100.000000000000000000;
    WebPageControl1Sheet8.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet8.Name := 'WebPageControl1Sheet8';
    WebPageControl1Sheet8.Left := 0;
    WebPageControl1Sheet8.Top := 20;
    WebPageControl1Sheet8.Width := 950;
    WebPageControl1Sheet8.Height := 433;
    WebPageControl1Sheet8.HeightPercent := 100.000000000000000000;
    WebPageControl1Sheet8.WidthPercent := 100.000000000000000000;
    WebPageControl1Sheet8.Caption := 'NichtGefunden';
    WebPageControl1Sheet8.ChildOrder := 7;
    WebPageControl1Sheet8.TabVisible := False;
    WebLabel14.SetParentComponent(WebPageControl1Sheet8);
    WebLabel14.Name := 'WebLabel14';
    WebLabel14.Left := 15;
    WebLabel14.Top := 35;
    WebLabel14.Width := 79;
    WebLabel14.Height := 16;
    WebLabel14.Caption := 'WebLabel14';
    WebLabel14.HeightPercent := 100.000000000000000000;
    WebLabel14.WidthPercent := 100.000000000000000000;
    WebLabel15.SetParentComponent(WebPageControl1Sheet8);
    WebLabel15.Name := 'WebLabel15';
    WebLabel15.Left := 14;
    WebLabel15.Top := 9;
    WebLabel15.Width := 269;
    WebLabel15.Height := 16;
    WebLabel15.Caption := 'Ihre Suche hat zu keinen Treffern  gef'#252'hrt!';
    WebLabel15.HeightPercent := 100.000000000000000000;
    WebLabel15.WidthPercent := 100.000000000000000000;
    WebLabel16.SetParentComponent(WebPageControl1Sheet8);
    WebLabel16.Name := 'WebLabel16';
    WebLabel16.Left := 16;
    WebLabel16.Top := 59;
    WebLabel16.Width := 79;
    WebLabel16.Height := 16;
    WebLabel16.Caption := 'WebLabel16';
    WebLabel16.HeightPercent := 100.000000000000000000;
    WebLabel16.WidthPercent := 100.000000000000000000;
    WebLabel17.SetParentComponent(WebPageControl1Sheet8);
    WebLabel17.Name := 'WebLabel17';
    WebLabel17.Left := 14;
    WebLabel17.Top := 111;
    WebLabel17.Width := 265;
    WebLabel17.Height := 16;
    WebLabel17.Caption := 'Versuchen Sie es mit folgenden Aktionen:';
    WebLabel17.HeightPercent := 100.000000000000000000;
    WebLabel17.WidthPercent := 100.000000000000000000;
    WebButton13.SetParentComponent(WebPageControl1Sheet8);
    WebButton13.Name := 'WebButton13';
    WebButton13.Left := 3;
    WebButton13.Top := 146;
    WebButton13.Width := 246;
    WebButton13.Height := 46;
    WebButton13.Caption := 'Suchen in allen Artikeln';
    WebButton13.ChildOrder := 1;
    WebButton13.HeightPercent := 100.000000000000000000;
    WebButton13.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton13, Self, 'OnClick', 'WebButton13Click');
    WebButton14.SetParentComponent(WebPageControl1Sheet8);
    WebButton14.Name := 'WebButton14';
    WebButton14.Left := 3;
    WebButton14.Top := 198;
    WebButton14.Width := 246;
    WebButton14.Height := 46;
    WebButton14.Caption := 'Alle Warengruppen';
    WebButton14.ChildOrder := 3;
    WebButton14.HeightPercent := 100.000000000000000000;
    WebButton14.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton14, Self, 'OnClick', 'WebButton14Click');
    WebButton15.SetParentComponent(WebPageControl1Sheet8);
    WebButton15.Name := 'WebButton15';
    WebButton15.Left := 3;
    WebButton15.Top := 250;
    WebButton15.Width := 246;
    WebButton15.Height := 46;
    WebButton15.Caption := 'Suchbegriff '#228'ndern';
    WebButton15.ChildOrder := 4;
    WebButton15.HeightPercent := 100.000000000000000000;
    WebButton15.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton15, Self, 'OnClick', 'WebButton15Click');
    WebPageControl1Sheet9.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet9.Name := 'WebPageControl1Sheet9';
    WebPageControl1Sheet9.Left := 0;
    WebPageControl1Sheet9.Top := 20;
    WebPageControl1Sheet9.Width := 950;
    WebPageControl1Sheet9.Height := 433;
    WebPageControl1Sheet9.HeightPercent := 100.000000000000000000;
    WebPageControl1Sheet9.WidthPercent := 100.000000000000000000;
    WebPageControl1Sheet9.Caption := 'AGB';
    WebPageControl1Sheet9.ChildOrder := 8;
    WebPageControl1Sheet9.TabVisible := False;
    WebRichEdit2.SetParentComponent(WebPageControl1Sheet9);
    WebRichEdit2.Name := 'WebRichEdit2';
    WebRichEdit2.Left := 0;
    WebRichEdit2.Top := 0;
    WebRichEdit2.Width := 950;
    WebRichEdit2.Height := 433;
    WebRichEdit2.HeightPercent := 100.000000000000000000;
    WebRichEdit2.WidthPercent := 100.000000000000000000;
    WebRichEdit2.Align := alClient;
    WebRichEdit2.BorderStyle := bsNone;
    WebRichEdit2.Color := clWhite;
    WebRichEdit2.ReadOnly := True;
    WebPageControl1Sheet10.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet10.Name := 'WebPageControl1Sheet10';
    WebPageControl1Sheet10.Left := 0;
    WebPageControl1Sheet10.Top := 20;
    WebPageControl1Sheet10.Width := 950;
    WebPageControl1Sheet10.Height := 433;
    WebPageControl1Sheet10.HeightPercent := 100.000000000000000000;
    WebPageControl1Sheet10.WidthPercent := 100.000000000000000000;
    WebPageControl1Sheet10.Caption := 'Datenschutz';
    WebPageControl1Sheet10.ChildOrder := 9;
    WebPageControl1Sheet10.TabVisible := False;
    WebRichEdit3.SetParentComponent(WebPageControl1Sheet10);
    WebRichEdit3.Name := 'WebRichEdit3';
    WebRichEdit3.Left := 0;
    WebRichEdit3.Top := 0;
    WebRichEdit3.Width := 950;
    WebRichEdit3.Height := 433;
    WebRichEdit3.HeightPercent := 100.000000000000000000;
    WebRichEdit3.WidthPercent := 100.000000000000000000;
    WebRichEdit3.Align := alClient;
    WebRichEdit3.BorderStyle := bsNone;
    WebRichEdit3.Color := clWhite;
    WebRichEdit3.ReadOnly := True;
    WebPageControl1Sheet11.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet11.Name := 'WebPageControl1Sheet11';
    WebPageControl1Sheet11.Left := 0;
    WebPageControl1Sheet11.Top := 20;
    WebPageControl1Sheet11.Width := 950;
    WebPageControl1Sheet11.Height := 433;
    WebPageControl1Sheet11.HeightPercent := 100.000000000000000000;
    WebPageControl1Sheet11.WidthPercent := 100.000000000000000000;
    WebPageControl1Sheet11.Caption := 'Impressum';
    WebPageControl1Sheet11.ChildOrder := 10;
    WebPageControl1Sheet11.TabVisible := False;
    WebRichEdit4.SetParentComponent(WebPageControl1Sheet11);
    WebRichEdit4.Name := 'WebRichEdit4';
    WebRichEdit4.Left := 0;
    WebRichEdit4.Top := 0;
    WebRichEdit4.Width := 950;
    WebRichEdit4.Height := 433;
    WebRichEdit4.HeightPercent := 100.000000000000000000;
    WebRichEdit4.WidthPercent := 100.000000000000000000;
    WebRichEdit4.Align := alClient;
    WebRichEdit4.BorderStyle := bsNone;
    WebRichEdit4.Color := clWhite;
    WebRichEdit4.ReadOnly := True;
    WebPageControl1Sheet12.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet12.Name := 'WebPageControl1Sheet12';
    WebPageControl1Sheet12.Left := 0;
    WebPageControl1Sheet12.Top := 20;
    WebPageControl1Sheet12.Width := 950;
    WebPageControl1Sheet12.Height := 433;
    WebPageControl1Sheet12.HeightPercent := 100.000000000000000000;
    WebPageControl1Sheet12.WidthPercent := 100.000000000000000000;
    WebPageControl1Sheet12.Caption := 'Kontakt';
    WebPageControl1Sheet12.ChildOrder := 11;
    WebPageControl1Sheet12.TabVisible := False;
    WebRichEdit5.SetParentComponent(WebPageControl1Sheet12);
    WebRichEdit5.Name := 'WebRichEdit5';
    WebRichEdit5.Left := 0;
    WebRichEdit5.Top := 0;
    WebRichEdit5.Width := 950;
    WebRichEdit5.Height := 433;
    WebRichEdit5.HeightPercent := 100.000000000000000000;
    WebRichEdit5.WidthPercent := 100.000000000000000000;
    WebRichEdit5.Align := alClient;
    WebRichEdit5.BorderStyle := bsNone;
    WebRichEdit5.Color := clWhite;
    WebRichEdit5.ReadOnly := True;
    WebPageControl1Sheet13.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet13.Name := 'WebPageControl1Sheet13';
    WebPageControl1Sheet13.Left := 0;
    WebPageControl1Sheet13.Top := 20;
    WebPageControl1Sheet13.Width := 950;
    WebPageControl1Sheet13.Height := 433;
    WebPageControl1Sheet13.HeightPercent := 100.000000000000000000;
    WebPageControl1Sheet13.WidthPercent := 100.000000000000000000;
    WebPageControl1Sheet13.Caption := 'Login';
    WebPageControl1Sheet13.ChildOrder := 12;
    WebPageControl1Sheet13.TabVisible := False;
    WebLabel9.SetParentComponent(WebPageControl1Sheet13);
    WebLabel9.Name := 'WebLabel9';
    WebLabel9.Left := 24;
    WebLabel9.Top := 17;
    WebLabel9.Width := 81;
    WebLabel9.Height := 14;
    WebLabel9.Caption := 'eMail-Adresse';
    WebLabel9.Font.Charset := DEFAULT_CHARSET;
    WebLabel9.Font.Color := clWindowText;
    WebLabel9.Font.Height := -11;
    WebLabel9.Font.Name := 'Arial';
    WebLabel9.Font.Style := [fsBold];
    WebLabel9.HeightPercent := 100.000000000000000000;
    WebLabel9.ParentFont := False;
    WebLabel9.WidthPercent := 100.000000000000000000;
    WebLabel13.SetParentComponent(WebPageControl1Sheet13);
    WebLabel13.Name := 'WebLabel13';
    WebLabel13.Left := 24;
    WebLabel13.Top := 74;
    WebLabel13.Width := 54;
    WebLabel13.Height := 14;
    WebLabel13.Caption := 'Kennwort';
    WebLabel13.Font.Charset := DEFAULT_CHARSET;
    WebLabel13.Font.Color := clWindowText;
    WebLabel13.Font.Height := -11;
    WebLabel13.Font.Name := 'Arial';
    WebLabel13.Font.Style := [fsBold];
    WebLabel13.HeightPercent := 100.000000000000000000;
    WebLabel13.ParentFont := False;
    WebLabel13.WidthPercent := 100.000000000000000000;
    WebEdit3.SetParentComponent(WebPageControl1Sheet13);
    WebEdit3.Name := 'WebEdit3';
    WebEdit3.Left := 24;
    WebEdit3.Top := 36;
    WebEdit3.Width := 247;
    WebEdit3.Height := 22;
    WebEdit3.ChildOrder := 2;
    WebEdit3.HeightPercent := 100.000000000000000000;
    WebEdit3.Text := 'WebEdit3';
    WebEdit3.WidthPercent := 100.000000000000000000;
    WebEdit4.SetParentComponent(WebPageControl1Sheet13);
    WebEdit4.Name := 'WebEdit4';
    WebEdit4.Left := 24;
    WebEdit4.Top := 93;
    WebEdit4.Width := 247;
    WebEdit4.Height := 22;
    WebEdit4.ChildOrder := 3;
    WebEdit4.HeightPercent := 100.000000000000000000;
    WebEdit4.Text := 'WebEdit4';
    WebEdit4.WidthPercent := 100.000000000000000000;
    WebButton12.SetParentComponent(WebPageControl1Sheet13);
    WebButton12.Name := 'WebButton12';
    WebButton12.Left := 24;
    WebButton12.Top := 160;
    WebButton12.Width := 96;
    WebButton12.Height := 49;
    WebButton12.Caption := 'Anmelden';
    WebButton12.ChildOrder := 4;
    WebButton12.HeightPercent := 100.000000000000000000;
    WebButton12.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton12, Self, 'OnClick', 'WebButton12Click');
    WebButton16.SetParentComponent(WebPageControl1Sheet13);
    WebButton16.Name := 'WebButton16';
    WebButton16.Left := 126;
    WebButton16.Top := 160;
    WebButton16.Width := 146;
    WebButton16.Height := 49;
    WebButton16.Caption := 'Kennwort vergessen';
    WebButton16.ChildOrder := 5;
    WebButton16.HeightPercent := 100.000000000000000000;
    WebButton16.WidthPercent := 100.000000000000000000;
    WebCheckBox4.SetParentComponent(WebPageControl1Sheet13);
    WebCheckBox4.Name := 'WebCheckBox4';
    WebCheckBox4.Left := 24;
    WebCheckBox4.Top := 126;
    WebCheckBox4.Width := 238;
    WebCheckBox4.Height := 22;
    WebCheckBox4.Caption := 'Angemeldet bleiben';
    WebCheckBox4.ChildOrder := 6;
    WebCheckBox4.Color := clNone;
    WebCheckBox4.HeightPercent := 100.000000000000000000;
    WebCheckBox4.State := cbUnchecked;
    WebCheckBox4.Visible := False;
    WebCheckBox4.WidthPercent := 100.000000000000000000;
    WebPageControl1Sheet14.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet14.Name := 'WebPageControl1Sheet14';
    WebPageControl1Sheet14.Left := 0;
    WebPageControl1Sheet14.Top := 20;
    WebPageControl1Sheet14.Width := 950;
    WebPageControl1Sheet14.Height := 433;
    WebPageControl1Sheet14.HeightPercent := 100.000000000000000000;
    WebPageControl1Sheet14.WidthPercent := 100.000000000000000000;
    WebPageControl1Sheet14.Caption := 'Theke';
    WebPageControl1Sheet14.ChildOrder := 13;
    WebPageControl1Sheet14.TabVisible := False;
    WebPageControl3.SetParentComponent(WebPageControl1Sheet14);
    WebPageControl3.Name := 'WebPageControl3';
    WebPageControl3.Left := 0;
    WebPageControl3.Top := 93;
    WebPageControl3.Width := 950;
    WebPageControl3.Height := 340;
    WebPageControl3.HeightPercent := 100.000000000000000000;
    WebPageControl3.WidthPercent := 100.000000000000000000;
    WebPageControl3.Align := alClient;
    WebPageControl3.Font.Charset := DEFAULT_CHARSET;
    WebPageControl3.Font.Color := clWindowText;
    WebPageControl3.Font.Height := -19;
    WebPageControl3.Font.Name := 'Arial';
    WebPageControl3.Font.Style := [fsBold];
    WebPageControl3.TabIndex := 0;
    WebPageControl3.ParentFont := False;
    WebPageControl3.TabOrder := 0;
    SetEvent(WebPageControl3, Self, 'OnChange', 'WebPageControl3Change');
    WebPageControl3Sheet1.SetParentComponent(WebPageControl3);
    WebPageControl3Sheet1.Name := 'WebPageControl3Sheet1';
    WebPageControl3Sheet1.Left := 0;
    WebPageControl3Sheet1.Top := 20;
    WebPageControl3Sheet1.Width := 950;
    WebPageControl3Sheet1.Height := 320;
    WebPageControl3Sheet1.HeightPercent := 100.000000000000000000;
    WebPageControl3Sheet1.WidthPercent := 100.000000000000000000;
    WebPageControl3Sheet1.Caption := 'Wursttheke';
    WebPageControl3Sheet1.TabVisible := False;
    WebScrollBox4.SetParentComponent(WebPageControl3Sheet1);
    WebScrollBox4.Name := 'WebScrollBox4';
    WebScrollBox4.Left := 0;
    WebScrollBox4.Top := 0;
    WebScrollBox4.Width := 950;
    WebScrollBox4.Height := 320;
    WebScrollBox4.HeightPercent := 100.000000000000000000;
    WebScrollBox4.WidthPercent := 100.000000000000000000;
    WebScrollBox4.Align := alClient;
    WebScrollBox4.BorderStyle := bsNone;
    WebScrollBox4.ChildOrder := 1;
    WebImageControl1.SetParentComponent(WebScrollBox4);
    WebImageControl1.Name := 'WebImageControl1';
    WebImageControl1.Left := 32;
    WebImageControl1.Top := 70;
    WebImageControl1.Width := 471;
    WebImageControl1.Height := 161;
    WebImageControl1.HeightPercent := 100.000000000000000000;
    WebImageControl1.WidthPercent := 100.000000000000000000;
    WebImageControl1.AutoSize := True;
    WebImageControl1.ChildOrder := 1;
    SetEvent(WebImageControl1, Self, 'OnMouseMove', 'WebImageControl1MouseMove');
    SetEvent(WebImageControl1, Self, 'OnMouseDown', 'WebImageControl1MouseDown');
    WebLabel28.SetParentComponent(WebScrollBox4);
    WebLabel28.Name := 'WebLabel28';
    WebLabel28.Left := 88;
    WebLabel28.Top := 80;
    WebLabel28.Width := 113;
    WebLabel28.Height := 22;
    WebLabel28.Caption := 'WebLabel28';
    WebLabel28.HeightPercent := 100.000000000000000000;
    WebLabel28.Transparent := False;
    WebLabel28.Visible := False;
    WebLabel28.WidthPercent := 100.000000000000000000;
    WebPageControl3Sheet2.SetParentComponent(WebPageControl3);
    WebPageControl3Sheet2.Name := 'WebPageControl3Sheet2';
    WebPageControl3Sheet2.Left := 0;
    WebPageControl3Sheet2.Top := 20;
    WebPageControl3Sheet2.Width := 950;
    WebPageControl3Sheet2.Height := 320;
    WebPageControl3Sheet2.HeightPercent := 100.000000000000000000;
    WebPageControl3Sheet2.WidthPercent := 100.000000000000000000;
    WebPageControl3Sheet2.Caption := 'Fleischtheke';
    WebPageControl3Sheet2.ChildOrder := 1;
    WebPageControl3Sheet2.TabVisible := False;
    WebScrollBox3.SetParentComponent(WebPageControl3Sheet2);
    WebScrollBox3.Name := 'WebScrollBox3';
    WebScrollBox3.Left := 0;
    WebScrollBox3.Top := 0;
    WebScrollBox3.Width := 950;
    WebScrollBox3.Height := 320;
    WebScrollBox3.HeightPercent := 100.000000000000000000;
    WebScrollBox3.WidthPercent := 100.000000000000000000;
    WebScrollBox3.Align := alClient;
    WebScrollBox3.BorderStyle := bsNone;
    WebScrollBox3.ChildOrder := 1;
    WebImageControl2.SetParentComponent(WebScrollBox3);
    WebImageControl2.Name := 'WebImageControl2';
    WebImageControl2.Left := 48;
    WebImageControl2.Top := 61;
    WebImageControl2.Width := 471;
    WebImageControl2.Height := 161;
    WebImageControl2.HeightPercent := 100.000000000000000000;
    WebImageControl2.WidthPercent := 100.000000000000000000;
    WebImageControl2.AutoSize := True;
    WebImageControl2.ChildOrder := 1;
    SetEvent(WebImageControl2, Self, 'OnMouseMove', 'WebImageControl2MouseMove');
    SetEvent(WebImageControl2, Self, 'OnMouseDown', 'WebImageControl2MouseDown');
    WebLabel27.SetParentComponent(WebScrollBox3);
    WebLabel27.Name := 'WebLabel27';
    WebLabel27.Left := 104;
    WebLabel27.Top := 88;
    WebLabel27.Width := 113;
    WebLabel27.Height := 22;
    WebLabel27.Caption := 'WebLabel27';
    WebLabel27.HeightPercent := 100.000000000000000000;
    WebLabel27.Transparent := False;
    WebLabel27.Visible := False;
    WebLabel27.WidthPercent := 100.000000000000000000;
    WebPageControl3Sheet3.SetParentComponent(WebPageControl3);
    WebPageControl3Sheet3.Name := 'WebPageControl3Sheet3';
    WebPageControl3Sheet3.Left := 0;
    WebPageControl3Sheet3.Top := 20;
    WebPageControl3Sheet3.Width := 950;
    WebPageControl3Sheet3.Height := 320;
    WebPageControl3Sheet3.HeightPercent := 100.000000000000000000;
    WebPageControl3Sheet3.WidthPercent := 100.000000000000000000;
    WebPageControl3Sheet3.Caption := 'K'#228'setheke';
    WebPageControl3Sheet3.ChildOrder := 2;
    WebPageControl3Sheet3.TabVisible := False;
    WebScrollBox2.SetParentComponent(WebPageControl3Sheet3);
    WebScrollBox2.Name := 'WebScrollBox2';
    WebScrollBox2.Left := 0;
    WebScrollBox2.Top := 0;
    WebScrollBox2.Width := 950;
    WebScrollBox2.Height := 320;
    WebScrollBox2.HeightPercent := 100.000000000000000000;
    WebScrollBox2.WidthPercent := 100.000000000000000000;
    WebScrollBox2.Align := alClient;
    WebScrollBox2.BorderStyle := bsNone;
    WebScrollBox2.ChildOrder := 1;
    WebImageControl5.SetParentComponent(WebScrollBox2);
    WebImageControl5.Name := 'WebImageControl5';
    WebImageControl5.Left := 40;
    WebImageControl5.Top := 56;
    WebImageControl5.Width := 471;
    WebImageControl5.Height := 161;
    WebImageControl5.HeightPercent := 100.000000000000000000;
    WebImageControl5.WidthPercent := 100.000000000000000000;
    WebImageControl5.AutoSize := True;
    WebImageControl5.ChildOrder := 1;
    SetEvent(WebImageControl5, Self, 'OnMouseMove', 'WebImageControl5MouseMove');
    SetEvent(WebImageControl5, Self, 'OnMouseDown', 'WebImageControl5MouseDown');
    WebLabel26.SetParentComponent(WebScrollBox2);
    WebLabel26.Name := 'WebLabel26';
    WebLabel26.Left := 176;
    WebLabel26.Top := 104;
    WebLabel26.Width := 113;
    WebLabel26.Height := 22;
    WebLabel26.Caption := 'WebLabel26';
    WebLabel26.HeightPercent := 100.000000000000000000;
    WebLabel26.Transparent := False;
    WebLabel26.Visible := False;
    WebLabel26.WidthPercent := 100.000000000000000000;
    WebPageControl3Sheet4.SetParentComponent(WebPageControl3);
    WebPageControl3Sheet4.Name := 'WebPageControl3Sheet4';
    WebPageControl3Sheet4.Left := 0;
    WebPageControl3Sheet4.Top := 20;
    WebPageControl3Sheet4.Width := 950;
    WebPageControl3Sheet4.Height := 320;
    WebPageControl3Sheet4.HeightPercent := 100.000000000000000000;
    WebPageControl3Sheet4.WidthPercent := 100.000000000000000000;
    WebPageControl3Sheet4.Caption := 'Heisstheke/Imbiss';
    WebPageControl3Sheet4.ChildOrder := 3;
    WebPageControl3Sheet4.TabVisible := False;
    WebScrollBox1.SetParentComponent(WebPageControl3Sheet4);
    WebScrollBox1.Name := 'WebScrollBox1';
    WebScrollBox1.Left := 0;
    WebScrollBox1.Top := 0;
    WebScrollBox1.Width := 950;
    WebScrollBox1.Height := 320;
    WebScrollBox1.HeightPercent := 100.000000000000000000;
    WebScrollBox1.WidthPercent := 100.000000000000000000;
    WebScrollBox1.Align := alClient;
    WebScrollBox1.BorderStyle := bsNone;
    WebScrollBox1.ChildOrder := 1;
    WebImageControl4.SetParentComponent(WebScrollBox1);
    WebImageControl4.Name := 'WebImageControl4';
    WebImageControl4.Left := 0;
    WebImageControl4.Top := -270;
    WebImageControl4.Width := 958;
    WebImageControl4.Height := 319;
    WebImageControl4.HeightPercent := 100.000000000000000000;
    WebImageControl4.WidthPercent := 100.000000000000000000;
    WebImageControl4.AutoSize := True;
    WebImageControl4.ChildOrder := 1;
    SetEvent(WebImageControl4, Self, 'OnMouseMove', 'WebImageControl4MouseMove');
    SetEvent(WebImageControl4, Self, 'OnMouseDown', 'WebImageControl4MouseDown');
    WebLabel23.SetParentComponent(WebScrollBox1);
    WebLabel23.Name := 'WebLabel23';
    WebLabel23.Left := 128;
    WebLabel23.Top := 152;
    WebLabel23.Width := 113;
    WebLabel23.Height := 22;
    WebLabel23.Caption := 'WebLabel23';
    WebLabel23.HeightPercent := 100.000000000000000000;
    WebLabel23.Transparent := False;
    WebLabel23.Visible := False;
    WebLabel23.WidthPercent := 100.000000000000000000;
    WebPanel58.SetParentComponent(WebPageControl1Sheet14);
    WebPanel58.Name := 'WebPanel58';
    WebPanel58.Left := 0;
    WebPanel58.Top := 0;
    WebPanel58.Width := 950;
    WebPanel58.Height := 93;
    WebPanel58.HeightPercent := 100.000000000000000000;
    WebPanel58.WidthPercent := 100.000000000000000000;
    WebPanel58.Align := alTop;
    WebPanel58.BorderColor := clSilver;
    WebPanel58.BorderStyle := bsSingle;
    WebPanel58.ChildOrder := 1;
    WebLabel22.SetParentComponent(WebPanel58);
    WebLabel22.Name := 'WebLabel22';
    WebLabel22.Left := 9;
    WebLabel22.Top := 50;
    WebLabel22.Width := 224;
    WebLabel22.Height := 14;
    WebLabel22.Caption := 'Klicken Sie auf den<br>Artikel Ihrer Wahl';
    WebLabel22.Font.Charset := DEFAULT_CHARSET;
    WebLabel22.Font.Color := clWindowText;
    WebLabel22.Font.Height := -11;
    WebLabel22.Font.Name := 'Arial';
    WebLabel22.Font.Style := [fsBold];
    WebLabel22.HeightPercent := 100.000000000000000000;
    WebLabel22.ParentFont := False;
    WebLabel22.WidthPercent := 100.000000000000000000;
    WebComboBox17.SetParentComponent(WebPanel58);
    WebComboBox17.Name := 'WebComboBox17';
    WebComboBox17.Left := 9;
    WebComboBox17.Top := 12;
    WebComboBox17.Width := 160;
    WebComboBox17.Height := 24;
    WebComboBox17.HeightPercent := 100.000000000000000000;
    WebComboBox17.Text := 'Wursttheke';
    WebComboBox17.WidthPercent := 100.000000000000000000;
    SetEvent(WebComboBox17, Self, 'OnChange', 'WebComboBox17Change');
    WebComboBox17.ItemIndex := 0;
    WebComboBox17.Items.BeginUpdate;
    try
      WebComboBox17.Items.Clear;
      WebComboBox17.Items.Add('Wursttheke');
      WebComboBox17.Items.Add('Fleischtheke');
      WebComboBox17.Items.Add('K'#228'setheke');
      WebComboBox17.Items.Add('Heisstheke/Imbiss');
    finally
      WebComboBox17.Items.EndUpdate;
    end;
    WebButton111.SetParentComponent(WebPanel58);
    WebButton111.Name := 'WebButton111';
    WebButton111.Left := 182;
    WebButton111.Top := 7;
    WebButton111.Width := 126;
    WebButton111.Height := 39;
    WebButton111.Caption := 'Zur'#252'ck';
    WebButton111.ChildOrder := 2;
    WebButton111.HeightPercent := 100.000000000000000000;
    WebButton111.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton111, Self, 'OnClick', 'WebButton111Click');
    WebButton21.SetParentComponent(WebPanel58);
    WebButton21.Name := 'WebButton21';
    WebButton21.Left := 182;
    WebButton21.Top := 49;
    WebButton21.Width := 126;
    WebButton21.Height := 37;
    WebButton21.Caption := 'Live-Bild anfordern';
    WebButton21.ChildOrder := 1;
    WebButton21.HeightPercent := 100.000000000000000000;
    WebButton21.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton21, Self, 'OnClick', 'WebButton21Click');
    WebPageControl1Sheet15.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet15.Name := 'WebPageControl1Sheet15';
    WebPageControl1Sheet15.Left := 0;
    WebPageControl1Sheet15.Top := 20;
    WebPageControl1Sheet15.Width := 950;
    WebPageControl1Sheet15.Height := 433;
    WebPageControl1Sheet15.HeightPercent := 100.000000000000000000;
    WebPageControl1Sheet15.WidthPercent := 100.000000000000000000;
    WebPageControl1Sheet15.Caption := 'Cookies';
    WebPageControl1Sheet15.ChildOrder := 15;
    WebPageControl1Sheet15.TabVisible := False;
    WebButton43.SetParentComponent(WebPageControl1Sheet15);
    WebButton43.Name := 'WebButton43';
    WebButton43.Left := 16;
    WebButton43.Top := 170;
    WebButton43.Width := 267;
    WebButton43.Height := 80;
    WebButton43.Caption := 'Ich stimme der Verwendung<br>von Cookies zu';
    WebButton43.ChildOrder := 1;
    WebButton43.HeightPercent := 100.000000000000000000;
    WebButton43.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton43, Self, 'OnClick', 'WebButton43Click');
    WebButton44.SetParentComponent(WebPageControl1Sheet15);
    WebButton44.Name := 'WebButton44';
    WebButton44.Left := 16;
    WebButton44.Top := 269;
    WebButton44.Width := 267;
    WebButton44.Height := 80;
    WebButton44.Caption := 'Ich bin nicht einverstanden mit <br>der Verwendung von Cookies';
    WebButton44.ChildOrder := 1;
    WebButton44.HeightPercent := 100.000000000000000000;
    WebButton44.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton44, Self, 'OnClick', 'WebButton44Click');
    WebRichEdit6.SetParentComponent(WebPageControl1Sheet15);
    WebRichEdit6.Name := 'WebRichEdit6';
    WebRichEdit6.Left := 0;
    WebRichEdit6.Top := 0;
    WebRichEdit6.Width := 950;
    WebRichEdit6.Height := 149;
    WebRichEdit6.HeightPercent := 100.000000000000000000;
    WebRichEdit6.WidthPercent := 100.000000000000000000;
    WebRichEdit6.Align := alTop;
    WebRichEdit6.BorderStyle := bsNone;
    WebRichEdit6.ChildOrder := 2;
    WebRichEdit6.Color := clWhite;
    WebRichEdit6.ReadOnly := True;
    WebPageControl1Sheet16.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet16.Name := 'WebPageControl1Sheet16';
    WebPageControl1Sheet16.Left := 0;
    WebPageControl1Sheet16.Top := 20;
    WebPageControl1Sheet16.Width := 950;
    WebPageControl1Sheet16.Height := 433;
    WebPageControl1Sheet16.HeightPercent := 100.000000000000000000;
    WebPageControl1Sheet16.WidthPercent := 100.000000000000000000;
    WebPageControl1Sheet16.Caption := 'Good_Bye';
    WebPageControl1Sheet16.ChildOrder := 16;
    WebPageControl1Sheet16.TabVisible := False;
    WebRichEdit7.SetParentComponent(WebPageControl1Sheet16);
    WebRichEdit7.Name := 'WebRichEdit7';
    WebRichEdit7.Left := 0;
    WebRichEdit7.Top := 0;
    WebRichEdit7.Width := 950;
    WebRichEdit7.Height := 433;
    WebRichEdit7.HeightPercent := 100.000000000000000000;
    WebRichEdit7.WidthPercent := 100.000000000000000000;
    WebRichEdit7.Align := alClient;
    WebRichEdit7.BorderStyle := bsNone;
    WebRichEdit7.ChildOrder := 1;
    WebRichEdit7.Color := clWhite;
    WebRichEdit7.ReadOnly := True;
    WebPageControl1Sheet17.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet17.Name := 'WebPageControl1Sheet17';
    WebPageControl1Sheet17.Left := 0;
    WebPageControl1Sheet17.Top := 20;
    WebPageControl1Sheet17.Width := 950;
    WebPageControl1Sheet17.Height := 433;
    WebPageControl1Sheet17.HeightPercent := 100.000000000000000000;
    WebPageControl1Sheet17.WidthPercent := 100.000000000000000000;
    WebPageControl1Sheet17.Caption := 'WebPageControl1Sheet17';
    WebPageControl1Sheet17.ChildOrder := 17;
    WebPageControl1Sheet17.TabVisible := False;
    WebPageControl1Sheet18.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet18.Name := 'WebPageControl1Sheet18';
    WebPageControl1Sheet18.Left := 0;
    WebPageControl1Sheet18.Top := 20;
    WebPageControl1Sheet18.Width := 950;
    WebPageControl1Sheet18.Height := 433;
    WebPageControl1Sheet18.HeightPercent := 100.000000000000000000;
    WebPageControl1Sheet18.WidthPercent := 100.000000000000000000;
    WebPageControl1Sheet18.Caption := 'Prospekt';
    WebPageControl1Sheet18.ChildOrder := 17;
    WebPageControl1Sheet18.TabVisible := False;
    WebImageZoomControl1.SetParentComponent(WebPageControl1Sheet18);
    WebImageZoomControl1.Name := 'WebImageZoomControl1';
    WebImageZoomControl1.Left := 592;
    WebImageZoomControl1.Top := 168;
    WebImageZoomControl1.Width := 100;
    WebImageZoomControl1.Height := 41;
    WebImageZoomControl1.HeightPercent := 100.000000000000000000;
    WebImageZoomControl1.WidthPercent := 100.000000000000000000;
    WebImageZoomControl1.ChildOrder := 2;
    WebImageSlider2.SetParentComponent(WebPageControl1Sheet18);
    WebImageSlider2.Name := 'WebImageSlider2';
    WebImageSlider2.Left := 0;
    WebImageSlider2.Top := 74;
    WebImageSlider2.Width := 950;
    WebImageSlider2.Height := 359;
    WebImageSlider2.HeightPercent := 100.000000000000000000;
    WebImageSlider2.WidthPercent := 100.000000000000000000;
    WebImageSlider2.Appearance.NavigationStyle := navsBullets;
    WebImageSlider2.Appearance.TransitionEffect := tefFade;
    WebImageSlider2.Appearance.Bullets.Opacity := 1.000000000000000000;
    WebImageSlider2.Appearance.Thumbnails.Opacity := 0.500000000000000000;
    WebImageSlider2.Appearance.Thumbnails.OpacityActive := 1.000000000000000000;
    SetEvent(WebImageSlider2, Self, 'OnImageClick', 'WebImageSlider2ImageClick');
    WebImageSlider2.Align := alClient;
    WebImageSlider2.Color := clWhite;
    WebPanel20.SetParentComponent(WebPageControl1Sheet18);
    WebPanel20.Name := 'WebPanel20';
    WebPanel20.Left := 0;
    WebPanel20.Top := 0;
    WebPanel20.Width := 950;
    WebPanel20.Height := 74;
    WebPanel20.HeightPercent := 100.000000000000000000;
    WebPanel20.WidthPercent := 100.000000000000000000;
    WebPanel20.Align := alTop;
    WebPanel20.BorderColor := clSilver;
    WebPanel20.BorderStyle := bsNone;
    WebPanel20.ChildOrder := 1;
    WebLabel35.SetParentComponent(WebPanel20);
    WebLabel35.Name := 'WebLabel35';
    WebLabel35.Left := 9;
    WebLabel35.Top := 7;
    WebLabel35.Width := 44;
    WebLabel35.Height := 16;
    WebLabel35.Caption := 'Woche';
    WebLabel35.HeightPercent := 100.000000000000000000;
    WebLabel35.WidthPercent := 100.000000000000000000;
    WebLabel71.SetParentComponent(WebPanel20);
    WebLabel71.Name := 'WebLabel71';
    WebLabel71.Left := 9;
    WebLabel71.Top := 38;
    WebLabel71.Width := 265;
    WebLabel71.Height := 16;
    WebLabel71.Caption := 'Klicken Sie auf den Artikel<br>Ihrer Wahl';
    WebLabel71.HeightPercent := 100.000000000000000000;
    WebLabel71.WidthPercent := 100.000000000000000000;
    WebComboBox9.SetParentComponent(WebPanel20);
    WebComboBox9.Name := 'WebComboBox9';
    WebComboBox9.Left := 54;
    WebComboBox9.Top := 6;
    WebComboBox9.Width := 156;
    WebComboBox9.Height := 24;
    WebComboBox9.HeightPercent := 100.000000000000000000;
    WebComboBox9.Text := '19.4.2021 - 25.4.2021';
    WebComboBox9.WidthPercent := 100.000000000000000000;
    WebComboBox9.ItemIndex := 0;
    WebComboBox9.Items.BeginUpdate;
    try
      WebComboBox9.Items.Clear;
      WebComboBox9.Items.Add('19.4.2021 - 25.4.2021');
    finally
      WebComboBox9.Items.EndUpdate;
    end;
    WebButton115.SetParentComponent(WebPanel20);
    WebButton115.Name := 'WebButton115';
    WebButton115.Left := 214;
    WebButton115.Top := 4;
    WebButton115.Width := 103;
    WebButton115.Height := 39;
    WebButton115.Caption := 'Zur'#252'ck';
    WebButton115.ChildOrder := 2;
    WebButton115.HeightPercent := 100.000000000000000000;
    WebButton115.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton115, Self, 'OnClick', 'WebButton115Click');
    WebPageControl1Sheet19.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet19.Name := 'WebPageControl1Sheet19';
    WebPageControl1Sheet19.Left := 0;
    WebPageControl1Sheet19.Top := 20;
    WebPageControl1Sheet19.Width := 950;
    WebPageControl1Sheet19.Height := 433;
    WebPageControl1Sheet19.HeightPercent := 100.000000000000000000;
    WebPageControl1Sheet19.WidthPercent := 100.000000000000000000;
    WebPageControl1Sheet19.Caption := 'Allergie';
    WebPageControl1Sheet19.ChildOrder := 18;
    WebPageControl1Sheet19.TabVisible := False;
    WebPanel23.SetParentComponent(WebPageControl1Sheet19);
    WebPanel23.Name := 'WebPanel23';
    WebPanel23.Left := 0;
    WebPanel23.Top := 373;
    WebPanel23.Width := 950;
    WebPanel23.Height := 60;
    WebPanel23.HeightPercent := 100.000000000000000000;
    WebPanel23.WidthPercent := 100.000000000000000000;
    WebPanel23.Align := alBottom;
    WebPanel23.BorderColor := clSilver;
    WebPanel23.BorderStyle := bsNone;
    WebPanel23.ChildOrder := 2;
    WebButton57.SetParentComponent(WebPanel23);
    WebButton57.Name := 'WebButton57';
    WebButton57.Left := 13;
    WebButton57.Top := 7;
    WebButton57.Width := 125;
    WebButton57.Height := 41;
    WebButton57.Caption := 'Filter setzen';
    WebButton57.HeightPercent := 100.000000000000000000;
    WebButton57.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton57, Self, 'OnClick', 'WebButton57Click');
    WebButton58.SetParentComponent(WebPanel23);
    WebButton58.Name := 'WebButton58';
    WebButton58.Left := 144;
    WebButton58.Top := 8;
    WebButton58.Width := 135;
    WebButton58.Height := 41;
    WebButton58.Caption := 'Filter l'#246'schen';
    WebButton58.ChildOrder := 1;
    WebButton58.HeightPercent := 100.000000000000000000;
    WebButton58.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton58, Self, 'OnClick', 'WebButton58Click');
    WebButton59.SetParentComponent(WebPanel23);
    WebButton59.Name := 'WebButton59';
    WebButton59.Left := 288;
    WebButton59.Top := 8;
    WebButton59.Width := 96;
    WebButton59.Height := 41;
    WebButton59.Caption := 'Zur'#252'ck';
    WebButton59.ChildOrder := 2;
    WebButton59.HeightPercent := 100.000000000000000000;
    WebButton59.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton59, Self, 'OnClick', 'WebButton59Click');
    WebPanel24.SetParentComponent(WebPageControl1Sheet19);
    WebPanel24.Name := 'WebPanel24';
    WebPanel24.Left := 0;
    WebPanel24.Top := 0;
    WebPanel24.Width := 950;
    WebPanel24.Height := 85;
    WebPanel24.HeightPercent := 100.000000000000000000;
    WebPanel24.WidthPercent := 100.000000000000000000;
    WebPanel24.Align := alTop;
    WebPanel24.BorderColor := clSilver;
    WebPanel24.BorderStyle := bsNone;
    WebPanel24.ChildOrder := 2;
    WebLabel73.SetParentComponent(WebPanel24);
    WebLabel73.Name := 'WebLabel73';
    WebLabel73.Left := 10;
    WebLabel73.Top := 6;
    WebLabel73.Width := 229;
    WebLabel73.Height := 16;
    WebLabel73.Caption := 'Allergie-Filter setzen oder aufheben';
    WebLabel73.HeightPercent := 100.000000000000000000;
    WebLabel73.WidthPercent := 100.000000000000000000;
    WebButton60.SetParentComponent(WebPanel24);
    WebButton60.Name := 'WebButton60';
    WebButton60.Left := 3;
    WebButton60.Top := 45;
    WebButton60.Width := 287;
    WebButton60.Height := 35;
    WebButton60.Caption := 'Aktuellen Eintrag aus- oder abw'#228'hlen';
    WebButton60.ChildOrder := 1;
    WebButton60.HeightPercent := 100.000000000000000000;
    WebButton60.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton60, Self, 'OnClick', 'WebButton60Click');
    WebStringGrid1.SetParentComponent(WebPageControl1Sheet19);
    WebStringGrid1.Name := 'WebStringGrid1';
    WebStringGrid1.Left := 0;
    WebStringGrid1.Top := 85;
    WebStringGrid1.Width := 950;
    WebStringGrid1.Height := 288;
    WebStringGrid1.Align := alClient;
    WebStringGrid1.ColCount := 3;
    WebStringGrid1.RowCount := 15;
    WebStringGrid1.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRowSelect];
    WebStringGrid1.TabOrder := 2;
    SetEvent(WebStringGrid1, Self, 'OnDblClick', 'WebStringGrid1DblClick');
    WebStringGrid1.ElementClassName := 'table-secondary';
    WebStringGrid1.RangeEdit.Max := 100.000000000000000000;
    WebStringGrid1.RangeEdit.Step := 1.000000000000000000;
    WebStringGrid1.HeightPercent := 100.000000000000000000;
    WebStringGrid1.WidthPercent := 100.000000000000000000;
    WebStringGrid1.ColWidths[0] := 18;
    WebStringGrid1.ColWidths[1] := 24;
    WebStringGrid1.ColWidths[2] := 326;
    WebStringGrid1.RowHeights[0] := 24;
    WebStringGrid1.RowHeights[1] := 24;
    WebStringGrid1.RowHeights[2] := 24;
    WebStringGrid1.RowHeights[3] := 24;
    WebStringGrid1.RowHeights[4] := 24;
    WebStringGrid1.RowHeights[5] := 24;
    WebStringGrid1.RowHeights[6] := 24;
    WebStringGrid1.RowHeights[7] := 24;
    WebStringGrid1.RowHeights[8] := 24;
    WebStringGrid1.RowHeights[9] := 24;
    WebStringGrid1.RowHeights[10] := 24;
    WebStringGrid1.RowHeights[11] := 24;
    WebStringGrid1.RowHeights[12] := 24;
    WebStringGrid1.RowHeights[13] := 24;
    WebStringGrid1.RowHeights[14] := 24;
    WebPageControl1Sheet20.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet20.Name := 'WebPageControl1Sheet20';
    WebPageControl1Sheet20.Left := 0;
    WebPageControl1Sheet20.Top := 20;
    WebPageControl1Sheet20.Width := 950;
    WebPageControl1Sheet20.Height := 433;
    WebPageControl1Sheet20.HeightPercent := 100.000000000000000000;
    WebPageControl1Sheet20.WidthPercent := 100.000000000000000000;
    WebPageControl1Sheet20.Caption := 'Vegan_u_co';
    WebPageControl1Sheet20.ChildOrder := 19;
    WebPageControl1Sheet20.TabVisible := False;
    WebPanel25.SetParentComponent(WebPageControl1Sheet20);
    WebPanel25.Name := 'WebPanel25';
    WebPanel25.Left := 0;
    WebPanel25.Top := 0;
    WebPanel25.Width := 950;
    WebPanel25.Height := 85;
    WebPanel25.HeightPercent := 100.000000000000000000;
    WebPanel25.WidthPercent := 100.000000000000000000;
    WebPanel25.Align := alTop;
    WebPanel25.BorderColor := clSilver;
    WebPanel25.BorderStyle := bsNone;
    WebPanel25.ChildOrder := 2;
    WebLabel74.SetParentComponent(WebPanel25);
    WebLabel74.Name := 'WebLabel74';
    WebLabel74.Left := 10;
    WebLabel74.Top := 4;
    WebLabel74.Width := 269;
    WebLabel74.Height := 16;
    WebLabel74.Caption := 'Ern'#228'hrungsstil-Filter setzen oder aufheben';
    WebLabel74.HeightPercent := 100.000000000000000000;
    WebLabel74.WidthPercent := 100.000000000000000000;
    WebButton61.SetParentComponent(WebPanel25);
    WebButton61.Name := 'WebButton61';
    WebButton61.Left := 10;
    WebButton61.Top := 41;
    WebButton61.Width := 288;
    WebButton61.Height := 38;
    WebButton61.Caption := 'Aktuellen Eintrag aus- oder abw'#228'hlen';
    WebButton61.ChildOrder := 1;
    WebButton61.HeightPercent := 100.000000000000000000;
    WebButton61.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton61, Self, 'OnClick', 'WebButton61Click');
    WebPanel26.SetParentComponent(WebPageControl1Sheet20);
    WebPanel26.Name := 'WebPanel26';
    WebPanel26.Left := 0;
    WebPanel26.Top := 373;
    WebPanel26.Width := 950;
    WebPanel26.Height := 60;
    WebPanel26.HeightPercent := 100.000000000000000000;
    WebPanel26.WidthPercent := 100.000000000000000000;
    WebPanel26.Align := alBottom;
    WebPanel26.BorderColor := clSilver;
    WebPanel26.BorderStyle := bsNone;
    WebPanel26.ChildOrder := 2;
    WebButton62.SetParentComponent(WebPanel26);
    WebButton62.Name := 'WebButton62';
    WebButton62.Left := 13;
    WebButton62.Top := 7;
    WebButton62.Width := 125;
    WebButton62.Height := 41;
    WebButton62.Caption := 'Filter setzen';
    WebButton62.HeightPercent := 100.000000000000000000;
    WebButton62.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton62, Self, 'OnClick', 'WebButton62Click');
    WebButton63.SetParentComponent(WebPanel26);
    WebButton63.Name := 'WebButton63';
    WebButton63.Left := 144;
    WebButton63.Top := 7;
    WebButton63.Width := 135;
    WebButton63.Height := 41;
    WebButton63.Caption := 'Filter l'#246'schen';
    WebButton63.ChildOrder := 1;
    WebButton63.HeightPercent := 100.000000000000000000;
    WebButton63.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton63, Self, 'OnClick', 'WebButton63Click');
    WebButton64.SetParentComponent(WebPanel26);
    WebButton64.Name := 'WebButton64';
    WebButton64.Left := 285;
    WebButton64.Top := 7;
    WebButton64.Width := 96;
    WebButton64.Height := 41;
    WebButton64.Caption := 'Zur'#252'ck';
    WebButton64.ChildOrder := 2;
    WebButton64.HeightPercent := 100.000000000000000000;
    WebButton64.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton64, Self, 'OnClick', 'WebButton64Click');
    WebStringGrid2.SetParentComponent(WebPageControl1Sheet20);
    WebStringGrid2.Name := 'WebStringGrid2';
    WebStringGrid2.Left := 0;
    WebStringGrid2.Top := 85;
    WebStringGrid2.Width := 950;
    WebStringGrid2.Height := 288;
    WebStringGrid2.Align := alClient;
    WebStringGrid2.ColCount := 3;
    WebStringGrid2.RowCount := 15;
    WebStringGrid2.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRowSelect];
    WebStringGrid2.TabOrder := 2;
    SetEvent(WebStringGrid2, Self, 'OnDblClick', 'WebStringGrid2DblClick');
    WebStringGrid2.ElementClassName := 'table-secondary';
    WebStringGrid2.RangeEdit.Max := 100.000000000000000000;
    WebStringGrid2.RangeEdit.Step := 1.000000000000000000;
    WebStringGrid2.HeightPercent := 100.000000000000000000;
    WebStringGrid2.WidthPercent := 100.000000000000000000;
    WebStringGrid2.ColWidths[0] := 18;
    WebStringGrid2.ColWidths[1] := 24;
    WebStringGrid2.ColWidths[2] := 326;
    WebStringGrid2.RowHeights[0] := 24;
    WebStringGrid2.RowHeights[1] := 24;
    WebStringGrid2.RowHeights[2] := 24;
    WebStringGrid2.RowHeights[3] := 24;
    WebStringGrid2.RowHeights[4] := 24;
    WebStringGrid2.RowHeights[5] := 24;
    WebStringGrid2.RowHeights[6] := 24;
    WebStringGrid2.RowHeights[7] := 24;
    WebStringGrid2.RowHeights[8] := 24;
    WebStringGrid2.RowHeights[9] := 24;
    WebStringGrid2.RowHeights[10] := 24;
    WebStringGrid2.RowHeights[11] := 24;
    WebStringGrid2.RowHeights[12] := 24;
    WebStringGrid2.RowHeights[13] := 24;
    WebStringGrid2.RowHeights[14] := 24;
    WebPageControl1Sheet21.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet21.Name := 'WebPageControl1Sheet21';
    WebPageControl1Sheet21.Left := 0;
    WebPageControl1Sheet21.Top := 20;
    WebPageControl1Sheet21.Width := 950;
    WebPageControl1Sheet21.Height := 433;
    WebPageControl1Sheet21.HeightPercent := 100.000000000000000000;
    WebPageControl1Sheet21.WidthPercent := 100.000000000000000000;
    WebPageControl1Sheet21.Caption := 'Lieferfilter';
    WebPageControl1Sheet21.ChildOrder := 20;
    WebPageControl1Sheet21.TabVisible := False;
    WebPanel27.SetParentComponent(WebPageControl1Sheet21);
    WebPanel27.Name := 'WebPanel27';
    WebPanel27.Left := 0;
    WebPanel27.Top := 0;
    WebPanel27.Width := 950;
    WebPanel27.Height := 85;
    WebPanel27.HeightPercent := 100.000000000000000000;
    WebPanel27.WidthPercent := 100.000000000000000000;
    WebPanel27.Align := alTop;
    WebPanel27.BorderColor := clSilver;
    WebPanel27.BorderStyle := bsNone;
    WebPanel27.ChildOrder := 2;
    WebLabel75.SetParentComponent(WebPanel27);
    WebLabel75.Name := 'WebLabel75';
    WebLabel75.Left := 10;
    WebLabel75.Top := 5;
    WebLabel75.Width := 216;
    WebLabel75.Height := 16;
    WebLabel75.Caption := 'Liefer-Filter setzen oder aufheben';
    WebLabel75.HeightPercent := 100.000000000000000000;
    WebLabel75.WidthPercent := 100.000000000000000000;
    WebButton65.SetParentComponent(WebPanel27);
    WebButton65.Name := 'WebButton65';
    WebButton65.Left := 10;
    WebButton65.Top := 41;
    WebButton65.Width := 272;
    WebButton65.Height := 39;
    WebButton65.Caption := 'Aktuellen Eintrag aus- oder abw'#228'hlen';
    WebButton65.ChildOrder := 1;
    WebButton65.HeightPercent := 100.000000000000000000;
    WebButton65.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton65, Self, 'OnClick', 'WebButton65Click');
    WebPanel28.SetParentComponent(WebPageControl1Sheet21);
    WebPanel28.Name := 'WebPanel28';
    WebPanel28.Left := 0;
    WebPanel28.Top := 373;
    WebPanel28.Width := 950;
    WebPanel28.Height := 60;
    WebPanel28.HeightPercent := 100.000000000000000000;
    WebPanel28.WidthPercent := 100.000000000000000000;
    WebPanel28.Align := alBottom;
    WebPanel28.BorderColor := clSilver;
    WebPanel28.BorderStyle := bsNone;
    WebPanel28.ChildOrder := 2;
    WebButton66.SetParentComponent(WebPanel28);
    WebButton66.Name := 'WebButton66';
    WebButton66.Left := 13;
    WebButton66.Top := 7;
    WebButton66.Width := 125;
    WebButton66.Height := 41;
    WebButton66.Caption := 'Filter setzen';
    WebButton66.HeightPercent := 100.000000000000000000;
    WebButton66.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton66, Self, 'OnClick', 'WebButton66Click');
    WebButton67.SetParentComponent(WebPanel28);
    WebButton67.Name := 'WebButton67';
    WebButton67.Left := 144;
    WebButton67.Top := 7;
    WebButton67.Width := 135;
    WebButton67.Height := 41;
    WebButton67.Caption := 'Filter l'#246'schen';
    WebButton67.ChildOrder := 1;
    WebButton67.HeightPercent := 100.000000000000000000;
    WebButton67.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton67, Self, 'OnClick', 'WebButton67Click');
    WebButton68.SetParentComponent(WebPanel28);
    WebButton68.Name := 'WebButton68';
    WebButton68.Left := 285;
    WebButton68.Top := 7;
    WebButton68.Width := 96;
    WebButton68.Height := 41;
    WebButton68.Caption := 'Zur'#252'ck';
    WebButton68.ChildOrder := 2;
    WebButton68.HeightPercent := 100.000000000000000000;
    WebButton68.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton68, Self, 'OnClick', 'WebButton68Click');
    WebStringGrid3.SetParentComponent(WebPageControl1Sheet21);
    WebStringGrid3.Name := 'WebStringGrid3';
    WebStringGrid3.Left := 0;
    WebStringGrid3.Top := 85;
    WebStringGrid3.Width := 950;
    WebStringGrid3.Height := 288;
    WebStringGrid3.Align := alClient;
    WebStringGrid3.ColCount := 3;
    WebStringGrid3.RowCount := 15;
    WebStringGrid3.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRowSelect];
    WebStringGrid3.TabOrder := 2;
    SetEvent(WebStringGrid3, Self, 'OnDblClick', 'WebStringGrid3DblClick');
    WebStringGrid3.ElementClassName := 'table-secondary';
    WebStringGrid3.RangeEdit.Max := 100.000000000000000000;
    WebStringGrid3.RangeEdit.Step := 1.000000000000000000;
    WebStringGrid3.HeightPercent := 100.000000000000000000;
    WebStringGrid3.WidthPercent := 100.000000000000000000;
    WebStringGrid3.ColWidths[0] := 18;
    WebStringGrid3.ColWidths[1] := 24;
    WebStringGrid3.ColWidths[2] := 326;
    WebStringGrid3.RowHeights[0] := 24;
    WebStringGrid3.RowHeights[1] := 24;
    WebStringGrid3.RowHeights[2] := 24;
    WebStringGrid3.RowHeights[3] := 24;
    WebStringGrid3.RowHeights[4] := 24;
    WebStringGrid3.RowHeights[5] := 24;
    WebStringGrid3.RowHeights[6] := 24;
    WebStringGrid3.RowHeights[7] := 24;
    WebStringGrid3.RowHeights[8] := 24;
    WebStringGrid3.RowHeights[9] := 24;
    WebStringGrid3.RowHeights[10] := 24;
    WebStringGrid3.RowHeights[11] := 24;
    WebStringGrid3.RowHeights[12] := 24;
    WebStringGrid3.RowHeights[13] := 24;
    WebStringGrid3.RowHeights[14] := 24;
    WebPageControl1Sheet22.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet22.Name := 'WebPageControl1Sheet22';
    WebPageControl1Sheet22.Left := 0;
    WebPageControl1Sheet22.Top := 20;
    WebPageControl1Sheet22.Width := 950;
    WebPageControl1Sheet22.Height := 433;
    WebPageControl1Sheet22.HeightPercent := 100.000000000000000000;
    WebPageControl1Sheet22.WidthPercent := 100.000000000000000000;
    WebPageControl1Sheet22.Caption := 'TextFilter';
    WebPageControl1Sheet22.ChildOrder := 21;
    WebPageControl1Sheet22.TabVisible := False;
    WebLabel76.SetParentComponent(WebPageControl1Sheet22);
    WebLabel76.Name := 'WebLabel76';
    WebLabel76.Left := 16;
    WebLabel76.Top := 30;
    WebLabel76.Width := 206;
    WebLabel76.Height := 16;
    WebLabel76.Caption := 'Text-Filter setzen oder aufheben';
    WebLabel76.HeightPercent := 100.000000000000000000;
    WebLabel76.WidthPercent := 100.000000000000000000;
    WebEditAutoComplete1.SetParentComponent(WebPageControl1Sheet22);
    WebEditAutoComplete1.Name := 'WebEditAutoComplete1';
    WebEditAutoComplete1.Left := 17;
    WebEditAutoComplete1.Top := 81;
    WebEditAutoComplete1.Width := 176;
    WebEditAutoComplete1.Height := 36;
    WebEditAutoComplete1.HeightPercent := 100.000000000000000000;
    WebEditAutoComplete1.WidthPercent := 100.000000000000000000;
    WebEditAutoComplete1.BorderStyle := bsNone;
    WebEditAutoComplete1.Color := clWhite;
    WebEditAutoComplete1.Font.Charset := DEFAULT_CHARSET;
    WebEditAutoComplete1.Font.Color := clWindowText;
    WebEditAutoComplete1.Font.Height := -16;
    WebEditAutoComplete1.Font.Name := 'Arial';
    WebEditAutoComplete1.Font.Style := [fsBold];
    WebEditAutoComplete1.TabStop := True;
    WebEditAutoComplete1.TabOrder := 0;
    WebEditAutoComplete1.Text := '';
    WebEditAutoComplete1.LookupType := ltAnywhere;
    WebPanel29.SetParentComponent(WebPageControl1Sheet22);
    WebPanel29.Name := 'WebPanel29';
    WebPanel29.Left := 0;
    WebPanel29.Top := 373;
    WebPanel29.Width := 950;
    WebPanel29.Height := 60;
    WebPanel29.HeightPercent := 100.000000000000000000;
    WebPanel29.WidthPercent := 100.000000000000000000;
    WebPanel29.Align := alBottom;
    WebPanel29.BorderColor := clSilver;
    WebPanel29.BorderStyle := bsNone;
    WebPanel29.ChildOrder := 2;
    WebButton71.SetParentComponent(WebPanel29);
    WebButton71.Name := 'WebButton71';
    WebButton71.Left := 13;
    WebButton71.Top := 7;
    WebButton71.Width := 125;
    WebButton71.Height := 41;
    WebButton71.Caption := 'Filter setzen';
    WebButton71.HeightPercent := 100.000000000000000000;
    WebButton71.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton71, Self, 'OnClick', 'WebButton71Click');
    WebButton72.SetParentComponent(WebPanel29);
    WebButton72.Name := 'WebButton72';
    WebButton72.Left := 144;
    WebButton72.Top := 7;
    WebButton72.Width := 135;
    WebButton72.Height := 41;
    WebButton72.Caption := 'Filter l'#246'schen';
    WebButton72.ChildOrder := 1;
    WebButton72.HeightPercent := 100.000000000000000000;
    WebButton72.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton72, Self, 'OnClick', 'WebButton72Click');
    WebButton73.SetParentComponent(WebPanel29);
    WebButton73.Name := 'WebButton73';
    WebButton73.Left := 285;
    WebButton73.Top := 7;
    WebButton73.Width := 96;
    WebButton73.Height := 41;
    WebButton73.Caption := 'Zur'#252'ck';
    WebButton73.ChildOrder := 2;
    WebButton73.HeightPercent := 100.000000000000000000;
    WebButton73.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton73, Self, 'OnClick', 'WebButton73Click');
    WebPageControl1Sheet23.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet23.Name := 'WebPageControl1Sheet23';
    WebPageControl1Sheet23.Left := 0;
    WebPageControl1Sheet23.Top := 20;
    WebPageControl1Sheet23.Width := 950;
    WebPageControl1Sheet23.Height := 433;
    WebPageControl1Sheet23.HeightPercent := 100.000000000000000000;
    WebPageControl1Sheet23.WidthPercent := 100.000000000000000000;
    WebPageControl1Sheet23.Caption := 'AdminPassword';
    WebPageControl1Sheet23.ChildOrder := 22;
    WebPageControl1Sheet23.TabVisible := False;
    WebLabel87.SetParentComponent(WebPageControl1Sheet23);
    WebLabel87.Name := 'WebLabel87';
    WebLabel87.Left := 3;
    WebLabel87.Top := 20;
    WebLabel87.Width := 54;
    WebLabel87.Height := 14;
    WebLabel87.Caption := 'Kennwort';
    WebLabel87.Font.Charset := DEFAULT_CHARSET;
    WebLabel87.Font.Color := clWindowText;
    WebLabel87.Font.Height := -11;
    WebLabel87.Font.Name := 'Arial';
    WebLabel87.Font.Style := [fsBold];
    WebLabel87.HeightPercent := 100.000000000000000000;
    WebLabel87.ParentFont := False;
    WebLabel87.WidthPercent := 100.000000000000000000;
    WebEdit26.SetParentComponent(WebPageControl1Sheet23);
    WebEdit26.Name := 'WebEdit26';
    WebEdit26.Left := 3;
    WebEdit26.Top := 42;
    WebEdit26.Width := 173;
    WebEdit26.Height := 22;
    WebEdit26.ChildOrder := 1;
    WebEdit26.HeightPercent := 100.000000000000000000;
    WebEdit26.PasswordChar := '*';
    WebEdit26.WidthPercent := 100.000000000000000000;
    WebButton79.SetParentComponent(WebPageControl1Sheet23);
    WebButton79.Name := 'WebButton79';
    WebButton79.Left := 3;
    WebButton79.Top := 111;
    WebButton79.Width := 193;
    WebButton79.Height := 50;
    WebButton79.Caption := 'Weiter zum Admin-Bereich';
    WebButton79.ChildOrder := 2;
    WebButton79.HeightPercent := 100.000000000000000000;
    WebButton79.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton79, Self, 'OnClick', 'WebButton79Click');
    WebButton80.SetParentComponent(WebPageControl1Sheet23);
    WebButton80.Name := 'WebButton80';
    WebButton80.Left := 4;
    WebButton80.Top := 173;
    WebButton80.Width := 192;
    WebButton80.Height := 48;
    WebButton80.Caption := 'Zur'#252'ck';
    WebButton80.ChildOrder := 3;
    WebButton80.HeightPercent := 100.000000000000000000;
    WebButton80.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton80, Self, 'OnClick', 'WebButton80Click');
    WebPageControl1Sheet24.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet24.Name := 'WebPageControl1Sheet24';
    WebPageControl1Sheet24.Left := 0;
    WebPageControl1Sheet24.Top := 20;
    WebPageControl1Sheet24.Width := 950;
    WebPageControl1Sheet24.Height := 433;
    WebPageControl1Sheet24.HeightPercent := 100.000000000000000000;
    WebPageControl1Sheet24.WidthPercent := 100.000000000000000000;
    WebPageControl1Sheet24.Caption := 'Bestell-Listen';
    WebPageControl1Sheet24.ChildOrder := 23;
    WebPageControl1Sheet24.TabVisible := False;
    WebPanel49.SetParentComponent(WebPageControl1Sheet24);
    WebPanel49.Name := 'WebPanel49';
    WebPanel49.Left := 0;
    WebPanel49.Top := 0;
    WebPanel49.Width := 950;
    WebPanel49.Height := 81;
    WebPanel49.HeightPercent := 100.000000000000000000;
    WebPanel49.WidthPercent := 100.000000000000000000;
    WebPanel49.Align := alTop;
    WebPanel49.BorderColor := clSilver;
    WebPanel49.BorderStyle := bsSingle;
    WebPanel49.ChildOrder := 1;
    WebLabel10.SetParentComponent(WebPanel49);
    WebLabel10.Name := 'WebLabel10';
    WebLabel10.Left := 11;
    WebLabel10.Top := 14;
    WebLabel10.Width := 77;
    WebLabel10.Height := 16;
    WebLabel10.Caption := 'Bestell-Liste';
    WebLabel10.HeightPercent := 100.000000000000000000;
    WebLabel10.WidthPercent := 100.000000000000000000;
    WebLabel97.SetParentComponent(WebPanel49);
    WebLabel97.Name := 'WebLabel97';
    WebLabel97.Left := 11;
    WebLabel97.Top := 43;
    WebLabel97.Width := 67;
    WebLabel97.Height := 16;
    WebLabel97.Caption := 'Sortierung';
    WebLabel97.HeightPercent := 100.000000000000000000;
    WebLabel97.WidthPercent := 100.000000000000000000;
    WebComboBox12.SetParentComponent(WebPanel49);
    WebComboBox12.Name := 'WebComboBox12';
    WebComboBox12.Left := 137;
    WebComboBox12.Top := 14;
    WebComboBox12.Width := 145;
    WebComboBox12.Height := 24;
    WebComboBox12.HeightPercent := 100.000000000000000000;
    WebComboBox12.Text := 'WebComboBox12';
    WebComboBox12.WidthPercent := 100.000000000000000000;
    SetEvent(WebComboBox12, Self, 'OnChange', 'WebComboBox12Change');
    WebComboBox12.ItemIndex := -1;
    WebComboBox13.SetParentComponent(WebPanel49);
    WebComboBox13.Name := 'WebComboBox13';
    WebComboBox13.Left := 137;
    WebComboBox13.Top := 43;
    WebComboBox13.Width := 145;
    WebComboBox13.Height := 24;
    WebComboBox13.HeightPercent := 100.000000000000000000;
    WebComboBox13.Text := 'Artikel-Nummer';
    WebComboBox13.WidthPercent := 100.000000000000000000;
    SetEvent(WebComboBox13, Self, 'OnChange', 'WebComboBox12Change');
    WebComboBox13.ItemIndex := 0;
    WebComboBox13.Items.BeginUpdate;
    try
      WebComboBox13.Items.Clear;
      WebComboBox13.Items.Add('Artikel-Nummer');
      WebComboBox13.Items.Add('Bezeichung');
    finally
      WebComboBox13.Items.EndUpdate;
    end;
    WebButton109.SetParentComponent(WebPanel49);
    WebButton109.Name := 'WebButton109';
    WebButton109.Left := 304;
    WebButton109.Top := 11;
    WebButton109.Width := 128;
    WebButton109.Height := 43;
    WebButton109.Caption := 'Zur'#252'ck';
    WebButton109.ChildOrder := 1;
    WebButton109.HeightPercent := 100.000000000000000000;
    WebButton109.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton109, Self, 'OnClick', 'WebButton109Click');
    WebPanel50.SetParentComponent(WebPageControl1Sheet24);
    WebPanel50.Name := 'WebPanel50';
    WebPanel50.Left := 0;
    WebPanel50.Top := 373;
    WebPanel50.Width := 950;
    WebPanel50.Height := 60;
    WebPanel50.HeightPercent := 100.000000000000000000;
    WebPanel50.WidthPercent := 100.000000000000000000;
    WebPanel50.Align := alBottom;
    WebPanel50.BorderColor := clSilver;
    WebPanel50.BorderStyle := bsSingle;
    WebPanel50.ChildOrder := 1;
    WebButton107.SetParentComponent(WebPanel50);
    WebButton107.Name := 'WebButton107';
    WebButton107.Left := 16;
    WebButton107.Top := 11;
    WebButton107.Width := 255;
    WebButton107.Height := 43;
    WebButton107.Caption := 'Artikel in Warenkorb';
    WebButton107.HeightPercent := 100.000000000000000000;
    WebButton107.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton107, Self, 'OnClick', 'WebButton107Click');
    WebStringGrid5.SetParentComponent(WebPageControl1Sheet24);
    WebStringGrid5.Name := 'WebStringGrid5';
    WebStringGrid5.Left := 0;
    WebStringGrid5.Top := 81;
    WebStringGrid5.Width := 950;
    WebStringGrid5.Height := 292;
    WebStringGrid5.Align := alClient;
    WebStringGrid5.ColCount := 6;
    WebStringGrid5.FixedCols := 4;
    WebStringGrid5.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goEditing,goAlwaysShowEditor];
    WebStringGrid5.TabOrder := 2;
    SetEvent(WebStringGrid5, Self, 'OnClick', 'WebStringGrid5Click');
    WebStringGrid5.ElementClassName := 'table-secondary';
    WebStringGrid5.RangeEdit.Max := 100.000000000000000000;
    WebStringGrid5.RangeEdit.Step := 1.000000000000000000;
    WebStringGrid5.HeightPercent := 100.000000000000000000;
    WebStringGrid5.WidthPercent := 100.000000000000000000;
    WebStringGrid5.ColWidths[0] := 64;
    WebStringGrid5.ColWidths[1] := 261;
    WebStringGrid5.ColWidths[2] := 90;
    WebStringGrid5.ColWidths[3] := 112;
    WebStringGrid5.ColWidths[4] := 108;
    WebStringGrid5.ColWidths[5] := 108;
    WebStringGrid5.RowHeights[0] := 24;
    WebStringGrid5.RowHeights[1] := 24;
    WebStringGrid5.RowHeights[2] := 24;
    WebStringGrid5.RowHeights[3] := 24;
    WebStringGrid5.RowHeights[4] := 24;
    WebPageControl1Sheet25.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet25.Name := 'WebPageControl1Sheet25';
    WebPageControl1Sheet25.Left := 0;
    WebPageControl1Sheet25.Top := 20;
    WebPageControl1Sheet25.Width := 950;
    WebPageControl1Sheet25.Height := 433;
    WebPageControl1Sheet25.HeightPercent := 100.000000000000000000;
    WebPageControl1Sheet25.WidthPercent := 100.000000000000000000;
    WebPageControl1Sheet25.Caption := 'Email';
    WebPageControl1Sheet25.ChildOrder := 24;
    WebPageControl1Sheet25.TabVisible := False;
    WebLabel100.SetParentComponent(WebPageControl1Sheet25);
    WebLabel100.Name := 'WebLabel100';
    WebLabel100.Left := 8;
    WebLabel100.Top := 16;
    WebLabel100.Width := 138;
    WebLabel100.Height := 16;
    WebLabel100.Caption := 'eMail-Adresse '#228'ndern';
    WebLabel100.HeightPercent := 100.000000000000000000;
    WebLabel100.WidthPercent := 100.000000000000000000;
    WebLabel101.SetParentComponent(WebPageControl1Sheet25);
    WebLabel101.Name := 'WebLabel101';
    WebLabel101.Left := 14;
    WebLabel101.Top := 59;
    WebLabel101.Width := 106;
    WebLabel101.Height := 14;
    WebLabel101.Caption := 'Alte eMail-Adresse';
    WebLabel101.Font.Charset := DEFAULT_CHARSET;
    WebLabel101.Font.Color := clWindowText;
    WebLabel101.Font.Height := -11;
    WebLabel101.Font.Name := 'Arial';
    WebLabel101.Font.Style := [fsBold];
    WebLabel101.HeightPercent := 100.000000000000000000;
    WebLabel101.ParentFont := False;
    WebLabel101.WidthPercent := 100.000000000000000000;
    WebLabel102.SetParentComponent(WebPageControl1Sheet25);
    WebLabel102.Name := 'WebLabel102';
    WebLabel102.Left := 14;
    WebLabel102.Top := 120;
    WebLabel102.Width := 112;
    WebLabel102.Height := 14;
    WebLabel102.Caption := 'Neue eMail-Adresse';
    WebLabel102.Font.Charset := DEFAULT_CHARSET;
    WebLabel102.Font.Color := clWindowText;
    WebLabel102.Font.Height := -11;
    WebLabel102.Font.Name := 'Arial';
    WebLabel102.Font.Style := [fsBold];
    WebLabel102.HeightPercent := 100.000000000000000000;
    WebLabel102.ParentFont := False;
    WebLabel102.WidthPercent := 100.000000000000000000;
    WebLabel103.SetParentComponent(WebPageControl1Sheet25);
    WebLabel103.Name := 'WebLabel103';
    WebLabel103.Left := 14;
    WebLabel103.Top := 179;
    WebLabel103.Width := 192;
    WebLabel103.Height := 14;
    WebLabel103.Caption := 'Wiederholung neue eMail-Adresse';
    WebLabel103.Font.Charset := DEFAULT_CHARSET;
    WebLabel103.Font.Color := clWindowText;
    WebLabel103.Font.Height := -11;
    WebLabel103.Font.Name := 'Arial';
    WebLabel103.Font.Style := [fsBold];
    WebLabel103.HeightPercent := 100.000000000000000000;
    WebLabel103.ParentFont := False;
    WebLabel103.WidthPercent := 100.000000000000000000;
    WebEdit33.SetParentComponent(WebPageControl1Sheet25);
    WebEdit33.Name := 'WebEdit33';
    WebEdit33.Left := 14;
    WebEdit33.Top := 80;
    WebEdit33.Width := 274;
    WebEdit33.Height := 30;
    WebEdit33.TabStop := False;
    WebEdit33.ChildOrder := 2;
    WebEdit33.HeightPercent := 100.000000000000000000;
    WebEdit33.ReadOnly := True;
    WebEdit33.Text := 'WebEdit33';
    WebEdit33.WidthPercent := 100.000000000000000000;
    WebEdit34.SetParentComponent(WebPageControl1Sheet25);
    WebEdit34.Name := 'WebEdit34';
    WebEdit34.Left := 14;
    WebEdit34.Top := 140;
    WebEdit34.Width := 274;
    WebEdit34.Height := 30;
    WebEdit34.ChildOrder := 2;
    WebEdit34.HeightPercent := 100.000000000000000000;
    WebEdit34.Text := 'WebEdit33';
    WebEdit34.WidthPercent := 100.000000000000000000;
    WebEdit35.SetParentComponent(WebPageControl1Sheet25);
    WebEdit35.Name := 'WebEdit35';
    WebEdit35.Left := 14;
    WebEdit35.Top := 199;
    WebEdit35.Width := 274;
    WebEdit35.Height := 30;
    WebEdit35.ChildOrder := 2;
    WebEdit35.HeightPercent := 100.000000000000000000;
    WebEdit35.Text := 'WebEdit33';
    WebEdit35.WidthPercent := 100.000000000000000000;
    WebButton116.SetParentComponent(WebPageControl1Sheet25);
    WebButton116.Name := 'WebButton116';
    WebButton116.Left := 16;
    WebButton116.Top := 272;
    WebButton116.Width := 128;
    WebButton116.Height := 48;
    WebButton116.Caption := 'Speichern';
    WebButton116.ChildOrder := 7;
    WebButton116.HeightPercent := 100.000000000000000000;
    WebButton116.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton116, Self, 'OnClick', 'WebButton116Click');
    WebButton117.SetParentComponent(WebPageControl1Sheet25);
    WebButton117.Name := 'WebButton117';
    WebButton117.Left := 160;
    WebButton117.Top := 272;
    WebButton117.Width := 128;
    WebButton117.Height := 48;
    WebButton117.Caption := 'Zur'#252'ck';
    WebButton117.ChildOrder := 8;
    WebButton117.HeightPercent := 100.000000000000000000;
    WebButton117.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton117, Self, 'OnClick', 'WebButton117Click');
    WebPageControl1Sheet26.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet26.Name := 'WebPageControl1Sheet26';
    WebPageControl1Sheet26.Left := 0;
    WebPageControl1Sheet26.Top := 20;
    WebPageControl1Sheet26.Width := 950;
    WebPageControl1Sheet26.Height := 433;
    WebPageControl1Sheet26.HeightPercent := 100.000000000000000000;
    WebPageControl1Sheet26.WidthPercent := 100.000000000000000000;
    WebPageControl1Sheet26.Caption := 'WebPageControl1Sheet26';
    WebPageControl1Sheet26.ChildOrder := 25;
    WebPageControl1Sheet26.TabVisible := False;
    WebLabel104.SetParentComponent(WebPageControl1Sheet26);
    WebLabel104.Name := 'WebLabel104';
    WebLabel104.Left := 22;
    WebLabel104.Top := 128;
    WebLabel104.Width := 99;
    WebLabel104.Height := 14;
    WebLabel104.Caption := 'Telefon Mobilfunk';
    WebLabel104.Font.Charset := DEFAULT_CHARSET;
    WebLabel104.Font.Color := clWindowText;
    WebLabel104.Font.Height := -11;
    WebLabel104.Font.Name := 'Arial';
    WebLabel104.Font.Style := [fsBold];
    WebLabel104.HeightPercent := 100.000000000000000000;
    WebLabel104.ParentFont := False;
    WebLabel104.WidthPercent := 100.000000000000000000;
    WebLabel105.SetParentComponent(WebPageControl1Sheet26);
    WebLabel105.Name := 'WebLabel105';
    WebLabel105.Left := 22;
    WebLabel105.Top := 67;
    WebLabel105.Width := 92;
    WebLabel105.Height := 14;
    WebLabel105.Caption := 'Telefon Festnetz';
    WebLabel105.Font.Charset := DEFAULT_CHARSET;
    WebLabel105.Font.Color := clWindowText;
    WebLabel105.Font.Height := -11;
    WebLabel105.Font.Name := 'Arial';
    WebLabel105.Font.Style := [fsBold];
    WebLabel105.HeightPercent := 100.000000000000000000;
    WebLabel105.ParentFont := False;
    WebLabel105.WidthPercent := 100.000000000000000000;
    WebLabel106.SetParentComponent(WebPageControl1Sheet26);
    WebLabel106.Name := 'WebLabel106';
    WebLabel106.Left := 16;
    WebLabel106.Top := 24;
    WebLabel106.Width := 113;
    WebLabel106.Height := 16;
    WebLabel106.Caption := 'Telefon-Nummern';
    WebLabel106.HeightPercent := 100.000000000000000000;
    WebLabel106.WidthPercent := 100.000000000000000000;
    WebButton118.SetParentComponent(WebPageControl1Sheet26);
    WebButton118.Name := 'WebButton118';
    WebButton118.Left := 24;
    WebButton118.Top := 280;
    WebButton118.Width := 128;
    WebButton118.Height := 48;
    WebButton118.Caption := 'Speichern';
    WebButton118.ChildOrder := 7;
    WebButton118.HeightPercent := 100.000000000000000000;
    WebButton118.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton118, Self, 'OnClick', 'WebButton118Click');
    WebButton119.SetParentComponent(WebPageControl1Sheet26);
    WebButton119.Name := 'WebButton119';
    WebButton119.Left := 168;
    WebButton119.Top := 280;
    WebButton119.Width := 128;
    WebButton119.Height := 48;
    WebButton119.Caption := 'Zur'#252'ck';
    WebButton119.ChildOrder := 8;
    WebButton119.HeightPercent := 100.000000000000000000;
    WebButton119.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton119, Self, 'OnClick', 'WebButton117Click');
    WebEdit36.SetParentComponent(WebPageControl1Sheet26);
    WebEdit36.Name := 'WebEdit36';
    WebEdit36.Left := 22;
    WebEdit36.Top := 148;
    WebEdit36.Width := 274;
    WebEdit36.Height := 30;
    WebEdit36.ChildOrder := 2;
    WebEdit36.HeightPercent := 100.000000000000000000;
    WebEdit36.Text := 'WebEdit33';
    WebEdit36.WidthPercent := 100.000000000000000000;
    WebEdit37.SetParentComponent(WebPageControl1Sheet26);
    WebEdit37.Name := 'WebEdit37';
    WebEdit37.Left := 22;
    WebEdit37.Top := 88;
    WebEdit37.Width := 274;
    WebEdit37.Height := 30;
    WebEdit37.ChildOrder := 2;
    WebEdit37.HeightPercent := 100.000000000000000000;
    WebEdit37.Text := 'WebEdit33';
    WebEdit37.WidthPercent := 100.000000000000000000;
    WebPageControl1Sheet27.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet27.Name := 'WebPageControl1Sheet27';
    WebPageControl1Sheet27.Left := 0;
    WebPageControl1Sheet27.Top := 20;
    WebPageControl1Sheet27.Width := 950;
    WebPageControl1Sheet27.Height := 433;
    WebPageControl1Sheet27.HeightPercent := 100.000000000000000000;
    WebPageControl1Sheet27.WidthPercent := 100.000000000000000000;
    WebPageControl1Sheet27.Caption := 'WebPageControl1Sheet27';
    WebPageControl1Sheet27.ChildOrder := 26;
    WebPageControl1Sheet27.TabVisible := False;
    WebLabel107.SetParentComponent(WebPageControl1Sheet27);
    WebLabel107.Name := 'WebLabel107';
    WebLabel107.Left := 22;
    WebLabel107.Top := 192;
    WebLabel107.Width := 172;
    WebLabel107.Height := 14;
    WebLabel107.Caption := 'Wiederholung neues Kennwort';
    WebLabel107.Font.Charset := DEFAULT_CHARSET;
    WebLabel107.Font.Color := clWindowText;
    WebLabel107.Font.Height := -11;
    WebLabel107.Font.Name := 'Arial';
    WebLabel107.Font.Style := [fsBold];
    WebLabel107.HeightPercent := 100.000000000000000000;
    WebLabel107.ParentFont := False;
    WebLabel107.WidthPercent := 100.000000000000000000;
    WebLabel108.SetParentComponent(WebPageControl1Sheet27);
    WebLabel108.Name := 'WebLabel108';
    WebLabel108.Left := 22;
    WebLabel108.Top := 136;
    WebLabel108.Width := 92;
    WebLabel108.Height := 14;
    WebLabel108.Caption := 'Neues Kennwort';
    WebLabel108.Font.Charset := DEFAULT_CHARSET;
    WebLabel108.Font.Color := clWindowText;
    WebLabel108.Font.Height := -11;
    WebLabel108.Font.Name := 'Arial';
    WebLabel108.Font.Style := [fsBold];
    WebLabel108.HeightPercent := 100.000000000000000000;
    WebLabel108.ParentFont := False;
    WebLabel108.WidthPercent := 100.000000000000000000;
    WebLabel109.SetParentComponent(WebPageControl1Sheet27);
    WebLabel109.Name := 'WebLabel109';
    WebLabel109.Left := 22;
    WebLabel109.Top := 75;
    WebLabel109.Width := 110;
    WebLabel109.Height := 14;
    WebLabel109.Caption := 'Aktuelles Kennwort';
    WebLabel109.Font.Charset := DEFAULT_CHARSET;
    WebLabel109.Font.Color := clWindowText;
    WebLabel109.Font.Height := -11;
    WebLabel109.Font.Name := 'Arial';
    WebLabel109.Font.Style := [fsBold];
    WebLabel109.HeightPercent := 100.000000000000000000;
    WebLabel109.ParentFont := False;
    WebLabel109.WidthPercent := 100.000000000000000000;
    WebLabel110.SetParentComponent(WebPageControl1Sheet27);
    WebLabel110.Name := 'WebLabel110';
    WebLabel110.Left := 24;
    WebLabel110.Top := 32;
    WebLabel110.Width := 110;
    WebLabel110.Height := 16;
    WebLabel110.Caption := 'Kennwort '#228'ndern';
    WebLabel110.HeightPercent := 100.000000000000000000;
    WebLabel110.WidthPercent := 100.000000000000000000;
    WebButton120.SetParentComponent(WebPageControl1Sheet27);
    WebButton120.Name := 'WebButton120';
    WebButton120.Left := 32;
    WebButton120.Top := 288;
    WebButton120.Width := 128;
    WebButton120.Height := 48;
    WebButton120.Caption := 'Speichern';
    WebButton120.ChildOrder := 7;
    WebButton120.HeightPercent := 100.000000000000000000;
    WebButton120.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton120, Self, 'OnClick', 'WebButton120Click');
    WebButton121.SetParentComponent(WebPageControl1Sheet27);
    WebButton121.Name := 'WebButton121';
    WebButton121.Left := 176;
    WebButton121.Top := 288;
    WebButton121.Width := 128;
    WebButton121.Height := 48;
    WebButton121.Caption := 'Zur'#252'ck';
    WebButton121.ChildOrder := 8;
    WebButton121.HeightPercent := 100.000000000000000000;
    WebButton121.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton121, Self, 'OnClick', 'WebButton117Click');
    WebEdit38.SetParentComponent(WebPageControl1Sheet27);
    WebEdit38.Name := 'WebEdit38';
    WebEdit38.Left := 22;
    WebEdit38.Top := 212;
    WebEdit38.Width := 274;
    WebEdit38.Height := 30;
    WebEdit38.ChildOrder := 2;
    WebEdit38.HeightPercent := 100.000000000000000000;
    WebEdit38.PasswordChar := '*';
    WebEdit38.Text := 'WebEdit33';
    WebEdit38.WidthPercent := 100.000000000000000000;
    WebEdit39.SetParentComponent(WebPageControl1Sheet27);
    WebEdit39.Name := 'WebEdit39';
    WebEdit39.Left := 22;
    WebEdit39.Top := 156;
    WebEdit39.Width := 274;
    WebEdit39.Height := 30;
    WebEdit39.ChildOrder := 2;
    WebEdit39.HeightPercent := 100.000000000000000000;
    WebEdit39.PasswordChar := '*';
    WebEdit39.Text := 'WebEdit33';
    WebEdit39.WidthPercent := 100.000000000000000000;
    WebEdit40.SetParentComponent(WebPageControl1Sheet27);
    WebEdit40.Name := 'WebEdit40';
    WebEdit40.Left := 22;
    WebEdit40.Top := 96;
    WebEdit40.Width := 274;
    WebEdit40.Height := 30;
    WebEdit40.ChildOrder := 2;
    WebEdit40.HeightPercent := 100.000000000000000000;
    WebEdit40.PasswordChar := '*';
    WebEdit40.Text := 'WebEdit33';
    WebEdit40.WidthPercent := 100.000000000000000000;
    WebButton122.SetParentComponent(WebPageControl1Sheet27);
    WebButton122.Name := 'WebButton122';
    WebButton122.Left := 307;
    WebButton122.Top := 96;
    WebButton122.Width := 53;
    WebButton122.Height := 31;
    WebButton122.Caption := 'WebButton39';
    WebButton122.ChildOrder := 8;
    WebButton122.HeightPercent := 100.000000000000000000;
    WebButton122.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton122, Self, 'OnClick', 'WebButton122Click');
    WebButton124.SetParentComponent(WebPageControl1Sheet27);
    WebButton124.Name := 'WebButton124';
    WebButton124.Left := 307;
    WebButton124.Top := 212;
    WebButton124.Width := 53;
    WebButton124.Height := 31;
    WebButton124.Caption := 'WebButton39';
    WebButton124.ChildOrder := 8;
    WebButton124.HeightPercent := 100.000000000000000000;
    WebButton124.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton124, Self, 'OnClick', 'WebButton124Click');
    WebButton125.SetParentComponent(WebPageControl1Sheet27);
    WebButton125.Name := 'WebButton125';
    WebButton125.Left := 307;
    WebButton125.Top := 155;
    WebButton125.Width := 53;
    WebButton125.Height := 31;
    WebButton125.Caption := 'WebButton39';
    WebButton125.ChildOrder := 8;
    WebButton125.HeightPercent := 100.000000000000000000;
    WebButton125.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton125, Self, 'OnClick', 'WebButton125Click');
    WebPageControl1Sheet28.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet28.Name := 'WebPageControl1Sheet28';
    WebPageControl1Sheet28.Left := 0;
    WebPageControl1Sheet28.Top := 20;
    WebPageControl1Sheet28.Width := 950;
    WebPageControl1Sheet28.Height := 433;
    WebPageControl1Sheet28.HeightPercent := 100.000000000000000000;
    WebPageControl1Sheet28.WidthPercent := 100.000000000000000000;
    WebPageControl1Sheet28.Caption := 'Kiosk';
    WebPageControl1Sheet28.ChildOrder := 27;
    WebPageControl1Sheet28.TabVisible := False;
    WebPanel54.SetParentComponent(WebPageControl1Sheet28);
    WebPanel54.Name := 'WebPanel54';
    WebPanel54.Left := 0;
    WebPanel54.Top := 0;
    WebPanel54.Width := 950;
    WebPanel54.Height := 60;
    WebPanel54.HeightPercent := 100.000000000000000000;
    WebPanel54.WidthPercent := 100.000000000000000000;
    WebPanel54.Align := alTop;
    WebPanel54.BorderColor := clSilver;
    WebPanel54.BorderStyle := bsSingle;
    WebLabel127.SetParentComponent(WebPanel54);
    WebLabel127.Name := 'WebLabel127';
    WebLabel127.Left := 152;
    WebLabel127.Top := 16;
    WebLabel127.Width := 135;
    WebLabel127.Height := 16;
    WebLabel127.Caption := 'Bitte w'#228'hlen Sie aus:';
    WebLabel127.HeightPercent := 100.000000000000000000;
    WebLabel127.WidthPercent := 100.000000000000000000;
    WebPanel55.SetParentComponent(WebPageControl1Sheet28);
    WebPanel55.Name := 'WebPanel55';
    WebPanel55.Left := 0;
    WebPanel55.Top := 60;
    WebPanel55.Width := 150;
    WebPanel55.Height := 313;
    WebPanel55.HeightPercent := 100.000000000000000000;
    WebPanel55.WidthPercent := 100.000000000000000000;
    WebPanel55.Align := alLeft;
    WebPanel55.BorderColor := clSilver;
    WebPanel55.BorderStyle := bsSingle;
    WebPanel55.ChildOrder := 1;
    WebPanel55.Visible := False;
    WebPanel56.SetParentComponent(WebPageControl1Sheet28);
    WebPanel56.Name := 'WebPanel56';
    WebPanel56.Left := 0;
    WebPanel56.Top := 373;
    WebPanel56.Width := 950;
    WebPanel56.Height := 60;
    WebPanel56.HeightPercent := 100.000000000000000000;
    WebPanel56.WidthPercent := 100.000000000000000000;
    WebPanel56.Align := alBottom;
    WebPanel56.BorderColor := clSilver;
    WebPanel56.BorderStyle := bsSingle;
    WebPanel56.ChildOrder := 2;
    WebButton128.SetParentComponent(WebPanel56);
    WebButton128.Name := 'WebButton128';
    WebButton128.Left := 457;
    WebButton128.Top := 10;
    WebButton128.Width := 115;
    WebButton128.Height := 41;
    WebButton128.Anchors := [];
    WebButton128.Caption := 'Warenkorb';
    WebButton128.ChildOrder := 3;
    WebButton128.ElementClassName := 'btn btn-primary';
    WebButton128.ElementFont := efCSS;
    WebButton128.HeightPercent := 100.000000000000000000;
    WebButton128.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton128, Self, 'OnClick', 'WebButton3Click');
    WebButton133.SetParentComponent(WebPanel56);
    WebButton133.Name := 'WebButton133';
    WebButton133.Left := 592;
    WebButton133.Top := 10;
    WebButton133.Width := 193;
    WebButton133.Height := 39;
    WebButton133.Caption := 'Bestellung abbrechen';
    WebButton133.ChildOrder := 2;
    WebButton133.HeightPercent := 100.000000000000000000;
    WebButton133.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton133, Self, 'OnClick', 'WebButton132Click');
    WebDBResponsiveGrid4.SetParentComponent(WebPageControl1Sheet28);
    WebDBResponsiveGrid4.Name := 'WebDBResponsiveGrid4';
    WebDBResponsiveGrid4.Left := 150;
    WebDBResponsiveGrid4.Top := 60;
    WebDBResponsiveGrid4.Width := 800;
    WebDBResponsiveGrid4.Height := 313;
    WebDBResponsiveGrid4.HeightPercent := 100.000000000000000000;
    WebDBResponsiveGrid4.WidthPercent := 100.000000000000000000;
    WebDBResponsiveGrid4.Align := alClient;
    WebDBResponsiveGrid4.ChildOrder := 3;
    WebDBResponsiveGrid4.ItemIndex := 0;
    SetEvent(WebDBResponsiveGrid4, Self, 'OnItemGetFieldValue', 'WebDBResponsiveGrid4ItemGetFieldValue');
    SetEvent(WebDBResponsiveGrid4, Self, 'OnItemClick', 'WebDBResponsiveGrid4ItemClick');
    WebDBResponsiveGrid4.DataSource := DataModule1.ShopKatWGDatasource;
    WebPageControl1Sheet29.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet29.Name := 'WebPageControl1Sheet29';
    WebPageControl1Sheet29.Left := 0;
    WebPageControl1Sheet29.Top := 20;
    WebPageControl1Sheet29.Width := 950;
    WebPageControl1Sheet29.Height := 433;
    WebPageControl1Sheet29.HeightPercent := 100.000000000000000000;
    WebPageControl1Sheet29.WidthPercent := 100.000000000000000000;
    WebPageControl1Sheet29.Caption := 'Meldung';
    WebPageControl1Sheet29.ChildOrder := 28;
    WebPageControl1Sheet29.TabVisible := False;
    WebLabel129.SetParentComponent(WebPageControl1Sheet29);
    WebLabel129.Name := 'WebLabel129';
    WebLabel129.Left := 12;
    WebLabel129.Top := 65;
    WebLabel129.Width := 86;
    WebLabel129.Height := 16;
    WebLabel129.Caption := 'WebLabel129';
    WebLabel129.HeightPercent := 100.000000000000000000;
    WebLabel129.WidthPercent := 100.000000000000000000;
    WebLabel130.SetParentComponent(WebPageControl1Sheet29);
    WebLabel130.Name := 'WebLabel130';
    WebLabel130.Left := 15;
    WebLabel130.Top := 13;
    WebLabel130.Width := 86;
    WebLabel130.Height := 16;
    WebLabel130.Caption := 'WebLabel130';
    WebLabel130.HeightPercent := 100.000000000000000000;
    WebLabel130.WidthPercent := 100.000000000000000000;
    WebButton129.SetParentComponent(WebPageControl1Sheet29);
    WebButton129.Name := 'WebButton129';
    WebButton129.Left := 3;
    WebButton129.Top := 147;
    WebButton129.Width := 140;
    WebButton129.Height := 36;
    WebButton129.Caption := 'WebButton129';
    WebButton129.ChildOrder := 1;
    WebButton129.HeightPercent := 100.000000000000000000;
    WebButton129.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton129, Self, 'OnClick', 'WebButton129Click');
    WebButton130.SetParentComponent(WebPageControl1Sheet29);
    WebButton130.Name := 'WebButton130';
    WebButton130.Left := 150;
    WebButton130.Top := 147;
    WebButton130.Width := 140;
    WebButton130.Height := 36;
    WebButton130.Caption := 'WebButton130';
    WebButton130.ChildOrder := 2;
    WebButton130.HeightPercent := 100.000000000000000000;
    WebButton130.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton130, Self, 'OnClick', 'WebButton130Click');
    WebButton131.SetParentComponent(WebPageControl1Sheet29);
    WebButton131.Name := 'WebButton131';
    WebButton131.Left := 298;
    WebButton131.Top := 147;
    WebButton131.Width := 140;
    WebButton131.Height := 36;
    WebButton131.Caption := 'WebButton131';
    WebButton131.ChildOrder := 4;
    WebButton131.HeightPercent := 100.000000000000000000;
    WebButton131.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton131, Self, 'OnClick', 'WebButton131Click');
    WebPageControl1Sheet30.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet30.Name := 'WebPageControl1Sheet30';
    WebPageControl1Sheet30.Left := 0;
    WebPageControl1Sheet30.Top := 20;
    WebPageControl1Sheet30.Width := 950;
    WebPageControl1Sheet30.Height := 433;
    WebPageControl1Sheet30.HeightPercent := 100.000000000000000000;
    WebPageControl1Sheet30.WidthPercent := 100.000000000000000000;
    WebPageControl1Sheet30.Caption := 'KioskStart';
    WebPageControl1Sheet30.ChildOrder := 29;
    WebPageControl1Sheet30.TabVisible := False;
    WebLabel131.SetParentComponent(WebPageControl1Sheet30);
    WebLabel131.Name := 'WebLabel131';
    WebLabel131.Left := 80;
    WebLabel131.Top := 32;
    WebLabel131.Width := 410;
    WebLabel131.Height := 16;
    WebLabel131.Caption := 'Willkommen am Selbstbedienungsterminal der Metzgerei Meier!';
    WebLabel131.HeightPercent := 100.000000000000000000;
    WebLabel131.WidthPercent := 100.000000000000000000;
    WebLabel132.SetParentComponent(WebPageControl1Sheet30);
    WebLabel132.Name := 'WebLabel132';
    WebLabel132.Left := 80;
    WebLabel132.Top := 88;
    WebLabel132.Width := 427;
    WebLabel132.Height := 16;
    WebLabel132.Caption := 'Klicken Sie auf einen der Buttons, um Ihre Bestellung zu beginnen.';
    WebLabel132.HeightPercent := 100.000000000000000000;
    WebLabel132.WidthPercent := 100.000000000000000000;
    WebLabel133.SetParentComponent(WebPageControl1Sheet30);
    WebLabel133.Name := 'WebLabel133';
    WebLabel133.Left := 84;
    WebLabel133.Top := 304;
    WebLabel133.Width := 399;
    WebLabel133.Height := 16;
    WebLabel133.Caption := 'oder, falls Sie individuelle Bedienung an der Theke w'#252'nschen:';
    WebLabel133.HeightPercent := 100.000000000000000000;
    WebLabel133.WidthPercent := 100.000000000000000000;
    WebButton134.SetParentComponent(WebPageControl1Sheet30);
    WebButton134.Name := 'WebButton134';
    WebButton134.Left := 81;
    WebButton134.Top := 146;
    WebButton134.Width := 352;
    WebButton134.Height := 46;
    WebButton134.Caption := 'WebButton134';
    WebButton134.ChildOrder := 1;
    WebButton134.HeightPercent := 100.000000000000000000;
    WebButton134.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton134, Self, 'OnClick', 'WebButton134Click');
    WebButton135.SetParentComponent(WebPageControl1Sheet30);
    WebButton135.Name := 'WebButton135';
    WebButton135.Left := 80;
    WebButton135.Top := 225;
    WebButton135.Width := 352;
    WebButton135.Height := 46;
    WebButton135.Caption := 'WebButton134';
    WebButton135.ChildOrder := 1;
    WebButton135.HeightPercent := 100.000000000000000000;
    WebButton135.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton135, Self, 'OnClick', 'WebButton135Click');
    WebButton136.SetParentComponent(WebPageControl1Sheet30);
    WebButton136.Name := 'WebButton136';
    WebButton136.Left := 77;
    WebButton136.Top := 336;
    WebButton136.Width := 352;
    WebButton136.Height := 46;
    WebButton136.Caption := 'WebButton134';
    WebButton136.ChildOrder := 1;
    WebButton136.HeightPercent := 100.000000000000000000;
    WebButton136.WidthPercent := 100.000000000000000000;
    WebPageControl8Sheet2.SetParentComponent(WebPageControl8);
    WebPageControl8Sheet2.Name := 'WebPageControl8Sheet2';
    WebPageControl8Sheet2.Left := 0;
    WebPageControl8Sheet2.Top := 20;
    WebPageControl8Sheet2.Width := 950;
    WebPageControl8Sheet2.Height := 453;
    WebPageControl8Sheet2.HeightPercent := 100.000000000000000000;
    WebPageControl8Sheet2.WidthPercent := 100.000000000000000000;
    WebPageControl8Sheet2.Caption := 'Modern';
    WebPageControl8Sheet2.ChildOrder := 1;
    WebPageControl8Sheet2.TabVisible := False;
    WebPageControl9.SetParentComponent(WebPageControl8Sheet2);
    WebPageControl9.Name := 'WebPageControl9';
    WebPageControl9.Left := 0;
    WebPageControl9.Top := 0;
    WebPageControl9.Width := 950;
    WebPageControl9.Height := 393;
    WebPageControl9.HeightPercent := 100.000000000000000000;
    WebPageControl9.WidthPercent := 100.000000000000000000;
    WebPageControl9.Align := alClient;
    WebPageControl9.Font.Charset := DEFAULT_CHARSET;
    WebPageControl9.Font.Color := clWindowText;
    WebPageControl9.Font.Height := -16;
    WebPageControl9.Font.Name := 'Arial';
    WebPageControl9.Font.Style := [fsBold];
    WebPageControl9.TabIndex := 0;
    WebPageControl9.ParentFont := False;
    WebPageControl9.TabOrder := 0;
    SetEvent(WebPageControl9, Self, 'OnChange', 'WebPageControl9Change');
    WebPageControl9Sheet1.SetParentComponent(WebPageControl9);
    WebPageControl9Sheet1.Name := 'WebPageControl9Sheet1';
    WebPageControl9Sheet1.Left := 0;
    WebPageControl9Sheet1.Top := 20;
    WebPageControl9Sheet1.Width := 950;
    WebPageControl9Sheet1.Height := 373;
    WebPageControl9Sheet1.HeightPercent := 100.000000000000000000;
    WebPageControl9Sheet1.WidthPercent := 100.000000000000000000;
    WebPageControl9Sheet1.Caption := 'Themes';
    WebResponsiveGridPanel1.SetParentComponent(WebPageControl9Sheet1);
    WebResponsiveGridPanel1.Name := 'WebResponsiveGridPanel1';
    WebResponsiveGridPanel1.Left := 0;
    WebResponsiveGridPanel1.Top := 0;
    WebResponsiveGridPanel1.Width := 950;
    WebResponsiveGridPanel1.Height := 373;
    WebResponsiveGridPanel1.ElementClassName := 'bg-primary mainform';
    WebResponsiveGridPanel1.HeightStyle := ssAuto;
    WebResponsiveGridPanel1.WidthStyle := ssPercent;
    WebResponsiveGridPanel1.HeightPercent := 100.000000000000000000;
    WebResponsiveGridPanel1.WidthPercent := 95.000000000000000000;
    WebResponsiveGridPanel1.Align := alClient;
    WebResponsiveGridPanel1.ChildOrder := 7;
    WebResponsiveGridPanel1.ControlCollection.Clear;
    with WebResponsiveGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebPanel34;
    end;
    with WebResponsiveGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebPanel35;
    end;
    WebResponsiveGridPanel1.Color := clWhite;
    WebResponsiveGridPanel1.ElementFont := efCSS;
    WebResponsiveGridPanel1.Layout.Clear;
    with WebResponsiveGridPanel1.Layout.Add do
    begin
      Description := 'Smartphone';
      Style := '1fr';
      Width := 700;
    end;
    with WebResponsiveGridPanel1.Layout.Add do
    begin
      Description := 'Tablet';
      Style := '1fr 1fr';
      Width := 768;
    end;
    WebPanel34.SetParentComponent(WebResponsiveGridPanel1);
    WebPanel34.Name := 'WebPanel34';
    WebPanel34.Left := 0;
    WebPanel34.Top := 0;
    WebPanel34.Width := 345;
    WebPanel34.Height := 477;
    WebPanel34.ElementClassName := 'card shadow p-3 mb-5 bg-white rounded';
    WebPanel34.HeightPercent := 100.000000000000000000;
    WebPanel34.WidthPercent := 100.000000000000000000;
    WebPanel34.Align := alLeft;
    WebPanel34.BorderColor := clSilver;
    WebPanel34.BorderStyle := bsNone;
    WebPanel34.ChildOrder := 6;
    WebPanel34.ElementBodyClassName := 'card-body';
    WebPanel34.ElementFont := efCSS;
    WebLabel90.SetParentComponent(WebPanel34);
    WebLabel90.Name := 'WebLabel90';
    WebLabel90.Left := 20;
    WebLabel90.Top := 39;
    WebLabel90.Width := 61;
    WebLabel90.Height := 19;
    WebLabel90.Caption := 'Themes';
    WebLabel90.HeightPercent := 100.000000000000000000;
    WebLabel90.WidthPercent := 100.000000000000000000;
    WebListBox2.SetParentComponent(WebPanel34);
    WebListBox2.Name := 'WebListBox2';
    WebListBox2.Left := 16;
    WebListBox2.Top := 66;
    WebListBox2.Width := 298;
    WebListBox2.Height := 211;
    WebListBox2.Font.Charset := DEFAULT_CHARSET;
    WebListBox2.Font.Color := clWindowText;
    WebListBox2.Font.Height := -16;
    WebListBox2.Font.Name := 'Arial';
    WebListBox2.Font.Style := [fsBold];
    WebListBox2.HeightPercent := 100.000000000000000000;
    WebListBox2.ItemHeight := 19;
    WebListBox2.ItemIndex := -1;
    WebListBox2.Items.BeginUpdate;
    try
      WebListBox2.Items.Clear;
      WebListBox2.Items.Add('Default Bootstrap');
      WebListBox2.Items.Add('BlueVoltage');
      WebListBox2.Items.Add('Darkster');
      WebListBox2.Items.Add('Fresca');
      WebListBox2.Items.Add('Greyson');
      WebListBox2.Items.Add('Hootstrap');
      WebListBox2.Items.Add('Monotone');
      WebListBox2.Items.Add('Signal');
      WebListBox2.Items.Add('new-age');
    finally
      WebListBox2.Items.EndUpdate;
    end;
    WebListBox2.ParentFont := False;
    WebListBox2.WidthPercent := 100.000000000000000000;
    SetEvent(WebListBox2, Self, 'OnClick', 'WebListBox2Click');
    WebPanel35.SetParentComponent(WebResponsiveGridPanel1);
    WebPanel35.Name := 'WebPanel35';
    WebPanel35.Left := 475;
    WebPanel35.Top := 0;
    WebPanel35.Width := 345;
    WebPanel35.Height := 477;
    WebPanel35.ElementClassName := 'card shadow p-3 mb-5 bg-white rounded';
    WebPanel35.HeightPercent := 100.000000000000000000;
    WebPanel35.WidthPercent := 100.000000000000000000;
    WebPanel35.Anchors := [];
    WebPanel35.BorderColor := clSilver;
    WebPanel35.BorderStyle := bsNone;
    WebPanel35.ChildOrder := 6;
    WebPanel35.ElementBodyClassName := 'card-body';
    WebPanel35.ElementFont := efCSS;
    WebLabel85.SetParentComponent(WebPanel35);
    WebLabel85.Name := 'WebLabel85';
    WebLabel85.Left := 24;
    WebLabel85.Top := 20;
    WebLabel85.Width := 49;
    WebLabel85.Height := 19;
    WebLabel85.Caption := 'Dialog';
    WebLabel85.ElementClassName := 'badge badge-primary';
    WebLabel85.ElementFont := efCSS;
    WebLabel85.HeightPercent := 100.000000000000000000;
    WebLabel85.WidthPercent := 100.000000000000000000;
    WebLabel86.SetParentComponent(WebPanel35);
    WebLabel86.Name := 'WebLabel86';
    WebLabel86.Left := 17;
    WebLabel86.Top := 442;
    WebLabel86.Width := 301;
    WebLabel86.Height := 19;
    WebLabel86.Caption := 'Resize form to see responsive behavior';
    WebLabel86.ElementClassName := 'label';
    WebLabel86.ElementFont := efCSS;
    WebLabel86.HeightPercent := 100.000000000000000000;
    WebLabel86.WidthPercent := 100.000000000000000000;
    WebRadioGroup3.SetParentComponent(WebPanel35);
    WebRadioGroup3.Name := 'WebRadioGroup3';
    WebRadioGroup3.Left := 20;
    WebRadioGroup3.Top := 53;
    WebRadioGroup3.Width := 301;
    WebRadioGroup3.Height := 228;
    WebRadioGroup3.ElementClassName := 'custom-control custom-radio';
    WebRadioGroup3.HeightPercent := 100.000000000000000000;
    WebRadioGroup3.WidthPercent := 100.000000000000000000;
    WebRadioGroup3.Caption := '';
    WebRadioGroup3.ChildOrder := 3;
    WebRadioGroup3.Columns := 1;
    WebRadioGroup3.ElementButtonClassName := 'custom-control-input';
    WebRadioGroup3.ElementGroupClassName := 'modal-content';
    WebRadioGroup3.ElementLabelClassName := 'custom-control-label';
    WebRadioGroup3.ElementFont := efCSS;
    WebRadioGroup3.ItemIndex := 0;
    WebRadioGroup3.Items.BeginUpdate;
    try
      WebRadioGroup3.Items.Clear;
      WebRadioGroup3.Items.Add('Information');
      WebRadioGroup3.Items.Add('Warning');
      WebRadioGroup3.Items.Add('Error');
      WebRadioGroup3.Items.Add('Confirmation');
    finally
      WebRadioGroup3.Items.EndUpdate;
    end;
    WebRadioGroup3.Role := '';
    WebEdit25.SetParentComponent(WebPanel35);
    WebEdit25.Name := 'WebEdit25';
    WebEdit25.Left := 20;
    WebEdit25.Top := 295;
    WebEdit25.Width := 301;
    WebEdit25.Height := 34;
    WebEdit25.ChildOrder := 4;
    WebEdit25.ElementClassName := 'form-control';
    WebEdit25.ElementFont := efCSS;
    WebEdit25.HeightPercent := 100.000000000000000000;
    WebEdit25.Text := 'This is the text for dialogs';
    WebEdit25.WidthPercent := 100.000000000000000000;
    WebButton55.SetParentComponent(WebPanel35);
    WebButton55.Name := 'WebButton55';
    WebButton55.Left := 17;
    WebButton55.Top := 376;
    WebButton55.Width := 136;
    WebButton55.Height := 25;
    WebButton55.Caption := 'MessageDlg';
    WebButton55.ChildOrder := 2;
    WebButton55.ElementClassName := 'btn btn-primary';
    WebButton55.ElementFont := efCSS;
    WebButton55.HeightStyle := ssAuto;
    WebButton55.HeightPercent := 100.000000000000000000;
    WebButton55.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton55, Self, 'OnClick', 'WebButton1Click');
    WebButton78.SetParentComponent(WebPanel35);
    WebButton78.Name := 'WebButton78';
    WebButton78.Left := 185;
    WebButton78.Top := 376;
    WebButton78.Width := 136;
    WebButton78.Height := 25;
    WebButton78.Caption := 'ShowMessage';
    WebButton78.ChildOrder := 2;
    WebButton78.ElementClassName := 'btn btn-secondary';
    WebButton78.ElementFont := efCSS;
    WebButton78.HeightStyle := ssAuto;
    WebButton78.HeightPercent := 100.000000000000000000;
    WebButton78.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton78, Self, 'OnClick', 'WebButton2Click');
    WebCheckBox6.SetParentComponent(WebPanel35);
    WebCheckBox6.Name := 'WebCheckBox6';
    WebCheckBox6.Left := 20;
    WebCheckBox6.Top := 335;
    WebCheckBox6.Width := 197;
    WebCheckBox6.Height := 25;
    WebCheckBox6.Caption := 'Show time in message';
    WebCheckBox6.ChildOrder := 6;
    WebCheckBox6.Color := clNone;
    WebCheckBox6.ElementClassName := 'custom-control custom-checkbox';
    WebCheckBox6.ElementButtonClassName := 'custom-control-input';
    WebCheckBox6.ElementLabelClassName := 'custom-control-label';
    WebCheckBox6.ElementFont := efCSS;
    WebCheckBox6.HeightPercent := 100.000000000000000000;
    WebCheckBox6.State := cbUnchecked;
    WebCheckBox6.WidthPercent := 100.000000000000000000;
    WebPageControl9Sheet2.SetParentComponent(WebPageControl9);
    WebPageControl9Sheet2.Name := 'WebPageControl9Sheet2';
    WebPageControl9Sheet2.Left := 0;
    WebPageControl9Sheet2.Top := 20;
    WebPageControl9Sheet2.Width := 950;
    WebPageControl9Sheet2.Height := 373;
    WebPageControl9Sheet2.HeightPercent := 100.000000000000000000;
    WebPageControl9Sheet2.WidthPercent := 100.000000000000000000;
    WebPageControl9Sheet2.Caption := 'Artikel-Auswahl';
    WebPageControl9Sheet2.ChildOrder := 1;
    WebRadioGroup2.SetParentComponent(WebPageControl9Sheet2);
    WebRadioGroup2.Name := 'WebRadioGroup2';
    WebRadioGroup2.Left := 24;
    WebRadioGroup2.Top := 24;
    WebRadioGroup2.Width := 212;
    WebRadioGroup2.Height := 174;
    WebRadioGroup2.HeightPercent := 100.000000000000000000;
    WebRadioGroup2.WidthPercent := 100.000000000000000000;
    WebRadioGroup2.Caption := 'Artikel-Auswahl';
    WebRadioGroup2.Columns := 1;
    WebRadioGroup2.ItemIndex := 0;
    WebRadioGroup2.Items.BeginUpdate;
    try
      WebRadioGroup2.Items.Clear;
      WebRadioGroup2.Items.Add('Klassisch');
      WebRadioGroup2.Items.Add('Accordion');
      WebRadioGroup2.Items.Add('Tree');
    finally
      WebRadioGroup2.Items.EndUpdate;
    end;
    WebRadioGroup2.Role := '';
    SetEvent(WebRadioGroup2, Self, 'OnChange', 'WebRadioGroup2Change');
    WebPageControl9Sheet3.SetParentComponent(WebPageControl9);
    WebPageControl9Sheet3.Name := 'WebPageControl9Sheet3';
    WebPageControl9Sheet3.Left := 0;
    WebPageControl9Sheet3.Top := 20;
    WebPageControl9Sheet3.Width := 950;
    WebPageControl9Sheet3.Height := 373;
    WebPageControl9Sheet3.HeightPercent := 100.000000000000000000;
    WebPageControl9Sheet3.WidthPercent := 100.000000000000000000;
    WebPageControl9Sheet3.Caption := 'Artikel-Karte, Farben etc.';
    WebPageControl9Sheet3.ChildOrder := 2;
    WebLabel69.SetParentComponent(WebPageControl9Sheet3);
    WebLabel69.Name := 'WebLabel69';
    WebLabel69.Left := 308;
    WebLabel69.Top := 63;
    WebLabel69.Width := 78;
    WebLabel69.Height := 14;
    WebLabel69.Caption := 'Mindestbreite';
    WebLabel69.Font.Charset := DEFAULT_CHARSET;
    WebLabel69.Font.Color := clWindowText;
    WebLabel69.Font.Height := -11;
    WebLabel69.Font.Name := 'Arial';
    WebLabel69.Font.Style := [fsBold];
    WebLabel69.HeightPercent := 100.000000000000000000;
    WebLabel69.ParentFont := False;
    WebLabel69.WidthPercent := 100.000000000000000000;
    WebLabel18.SetParentComponent(WebPageControl9Sheet3);
    WebLabel18.Name := 'WebLabel18';
    WebLabel18.Left := 307;
    WebLabel18.Top := 112;
    WebLabel18.Width := 28;
    WebLabel18.Height := 14;
    WebLabel18.Caption := 'H'#246'he';
    WebLabel18.Font.Charset := DEFAULT_CHARSET;
    WebLabel18.Font.Color := clWindowText;
    WebLabel18.Font.Height := -11;
    WebLabel18.Font.Name := 'Arial';
    WebLabel18.Font.Style := [fsBold];
    WebLabel18.HeightPercent := 100.000000000000000000;
    WebLabel18.ParentFont := False;
    WebLabel18.WidthPercent := 100.000000000000000000;
    WebLabel89.SetParentComponent(WebPageControl9Sheet3);
    WebLabel89.Name := 'WebLabel89';
    WebLabel89.Left := 307;
    WebLabel89.Top := 158;
    WebLabel89.Width := 46;
    WebLabel89.Height := 14;
    WebLabel89.Caption := 'Abstand';
    WebLabel89.Font.Charset := DEFAULT_CHARSET;
    WebLabel89.Font.Color := clWindowText;
    WebLabel89.Font.Height := -11;
    WebLabel89.Font.Name := 'Arial';
    WebLabel89.Font.Style := [fsBold];
    WebLabel89.HeightPercent := 100.000000000000000000;
    WebLabel89.ParentFont := False;
    WebLabel89.WidthPercent := 100.000000000000000000;
    WebEdit27.SetParentComponent(WebPageControl9Sheet3);
    WebEdit27.Name := 'WebEdit27';
    WebEdit27.Left := 307;
    WebEdit27.Top := 80;
    WebEdit27.Width := 121;
    WebEdit27.Height := 22;
    WebEdit27.ChildOrder := 5;
    WebEdit27.HeightPercent := 100.000000000000000000;
    WebEdit27.Text := 'WebEdit27';
    WebEdit27.WidthPercent := 100.000000000000000000;
    WebPanel38.SetParentComponent(WebPageControl9Sheet3);
    WebPanel38.Name := 'WebPanel38';
    WebPanel38.Left := 0;
    WebPanel38.Top := 38;
    WebPanel38.Width := 290;
    WebPanel38.Height := 335;
    WebPanel38.HeightPercent := 100.000000000000000000;
    WebPanel38.WidthPercent := 100.000000000000000000;
    WebPanel38.Align := alLeft;
    WebPanel38.BorderColor := clSilver;
    WebPanel38.BorderStyle := bsSingle;
    WebPanel38.ChildOrder := 6;
    WebLabel88.SetParentComponent(WebPanel38);
    WebLabel88.Name := 'WebLabel88';
    WebLabel88.Left := 16;
    WebLabel88.Top := 27;
    WebLabel88.Width := 38;
    WebLabel88.Height := 14;
    WebLabel88.Caption := 'Farben';
    WebLabel88.Font.Charset := DEFAULT_CHARSET;
    WebLabel88.Font.Color := clWindowText;
    WebLabel88.Font.Height := -11;
    WebLabel88.Font.Name := 'Arial';
    WebLabel88.Font.Style := [fsBold];
    WebLabel88.HeightPercent := 100.000000000000000000;
    WebLabel88.ParentFont := False;
    WebLabel88.WidthPercent := 100.000000000000000000;
    WebLabel11.SetParentComponent(WebPanel38);
    WebLabel11.Name := 'WebLabel11';
    WebLabel11.Left := 15;
    WebLabel11.Top := 252;
    WebLabel11.Width := 180;
    WebLabel11.Height := 16;
    WebLabel11.Caption := 'Hier klicken f'#252'r Farbwahl -->';
    WebLabel11.Font.Charset := DEFAULT_CHARSET;
    WebLabel11.Font.Color := clWindowText;
    WebLabel11.Font.Height := -13;
    WebLabel11.Font.Name := 'Arial';
    WebLabel11.Font.Style := [fsBold];
    WebLabel11.HeightPercent := 100.000000000000000000;
    WebLabel11.ParentFont := False;
    WebLabel11.WidthPercent := 100.000000000000000000;
    WebListBox3.SetParentComponent(WebPanel38);
    WebListBox3.Name := 'WebListBox3';
    WebListBox3.Left := 16;
    WebListBox3.Top := 49;
    WebListBox3.Width := 258;
    WebListBox3.Height := 177;
    WebListBox3.HeightPercent := 100.000000000000000000;
    WebListBox3.ItemHeight := 19;
    WebListBox3.ItemIndex := -1;
    WebListBox3.Items.BeginUpdate;
    try
      WebListBox3.Items.Clear;
      WebListBox3.Items.Add('Rahmen nicht ausgew'#228'hlt');
      WebListBox3.Items.Add('Grundfarbe nicht ausgew'#228'hlt');
      WebListBox3.Items.Add('Rahmen ausgew'#228'hlt');
      WebListBox3.Items.Add('Grundfarbe ausgew'#228'hlt');
      WebListBox3.Items.Add('Rahmen hovered');
      WebListBox3.Items.Add('Grundfarbe hovered');
    finally
      WebListBox3.Items.EndUpdate;
    end;
    WebListBox3.WidthPercent := 100.000000000000000000;
    SetEvent(WebListBox3, Self, 'OnChange', 'WebListBox3Change');
    WebColorPicker1.SetParentComponent(WebPanel38);
    WebColorPicker1.Name := 'WebColorPicker1';
    WebColorPicker1.Left := 227;
    WebColorPicker1.Top := 239;
    WebColorPicker1.Width := 44;
    WebColorPicker1.Height := 41;
    WebColorPicker1.HeightPercent := 100.000000000000000000;
    WebColorPicker1.WidthPercent := 100.000000000000000000;
    WebColorPicker1.ChildOrder := 2;
    WebColorPicker1.Color := clBlack;
    WebColorPicker1.Role := '';
    SetEvent(WebColorPicker1, Self, 'OnSelect', 'WebColorPicker1Select');
    WebEdit28.SetParentComponent(WebPageControl9Sheet3);
    WebEdit28.Name := 'WebEdit28';
    WebEdit28.Left := 306;
    WebEdit28.Top := 129;
    WebEdit28.Width := 121;
    WebEdit28.Height := 22;
    WebEdit28.ChildOrder := 5;
    WebEdit28.HeightPercent := 100.000000000000000000;
    WebEdit28.Text := 'WebEdit27';
    WebEdit28.WidthPercent := 100.000000000000000000;
    WebEdit29.SetParentComponent(WebPageControl9Sheet3);
    WebEdit29.Name := 'WebEdit29';
    WebEdit29.Left := 306;
    WebEdit29.Top := 175;
    WebEdit29.Width := 121;
    WebEdit29.Height := 22;
    WebEdit29.ChildOrder := 5;
    WebEdit29.HeightPercent := 100.000000000000000000;
    WebEdit29.Text := 'WebEdit27';
    WebEdit29.WidthPercent := 100.000000000000000000;
    WebPanel39.SetParentComponent(WebPageControl9Sheet3);
    WebPanel39.Name := 'WebPanel39';
    WebPanel39.Left := 0;
    WebPanel39.Top := 0;
    WebPanel39.Width := 950;
    WebPanel39.Height := 38;
    WebPanel39.HeightPercent := 100.000000000000000000;
    WebPanel39.WidthPercent := 100.000000000000000000;
    WebPanel39.Align := alTop;
    WebPanel39.BorderColor := clSilver;
    WebPanel39.BorderStyle := bsSingle;
    WebPanel39.ChildOrder := 7;
    WebPageControl9Sheet4.SetParentComponent(WebPageControl9);
    WebPageControl9Sheet4.Name := 'WebPageControl9Sheet4';
    WebPageControl9Sheet4.Left := 0;
    WebPageControl9Sheet4.Top := 20;
    WebPageControl9Sheet4.Width := 950;
    WebPageControl9Sheet4.Height := 373;
    WebPageControl9Sheet4.HeightPercent := 100.000000000000000000;
    WebPageControl9Sheet4.WidthPercent := 100.000000000000000000;
    WebPageControl9Sheet4.Caption := 'Men'#252'-Baum';
    WebPageControl9Sheet4.ChildOrder := 3;
    WebLabel91.SetParentComponent(WebPageControl9Sheet4);
    WebLabel91.Name := 'WebLabel91';
    WebLabel91.Left := 439;
    WebLabel91.Top := 130;
    WebLabel91.Width := 102;
    WebLabel91.Height := 19;
    WebLabel91.Caption := 'Bezeichnung';
    WebLabel91.HeightPercent := 100.000000000000000000;
    WebLabel91.WidthPercent := 100.000000000000000000;
    WebLabel92.SetParentComponent(WebPageControl9Sheet4);
    WebLabel92.Name := 'WebLabel92';
    WebLabel92.Left := 439;
    WebLabel92.Top := 64;
    WebLabel92.Width := 80;
    WebLabel92.Height := 19;
    WebLabel92.Caption := 'Vorg'#228'nger';
    WebLabel92.HeightPercent := 100.000000000000000000;
    WebLabel92.WidthPercent := 100.000000000000000000;
    WebPanel40.SetParentComponent(WebPageControl9Sheet4);
    WebPanel40.Name := 'WebPanel40';
    WebPanel40.Left := 0;
    WebPanel40.Top := 0;
    WebPanel40.Width := 950;
    WebPanel40.Height := 60;
    WebPanel40.HeightPercent := 100.000000000000000000;
    WebPanel40.WidthPercent := 100.000000000000000000;
    WebPanel40.Align := alTop;
    WebPanel40.BorderColor := clSilver;
    WebPanel40.BorderStyle := bsSingle;
    WebPanel41.SetParentComponent(WebPageControl9Sheet4);
    WebPanel41.Name := 'WebPanel41';
    WebPanel41.Left := 0;
    WebPanel41.Top := 60;
    WebPanel41.Width := 409;
    WebPanel41.Height := 313;
    WebPanel41.HeightPercent := 100.000000000000000000;
    WebPanel41.WidthPercent := 100.000000000000000000;
    WebPanel41.Align := alLeft;
    WebPanel41.BorderColor := clSilver;
    WebPanel41.BorderStyle := bsSingle;
    WebPanel41.ChildOrder := 1;
    WebTreeView1.SetParentComponent(WebPanel41);
    WebTreeView1.Name := 'WebTreeView1';
    WebTreeView1.Left := 0;
    WebTreeView1.Top := 0;
    WebTreeView1.Width := 409;
    WebTreeView1.Height := 313;
    WebTreeView1.Align := alClient;
    WebTreeView1.HeightPercent := 100.000000000000000000;
    WebTreeView1.WidthPercent := 100.000000000000000000;
    SetEvent(WebTreeView1, Self, 'OnClick', 'WebTreeView1Click');
    WebEdit30.SetParentComponent(WebPageControl9Sheet4);
    WebEdit30.Name := 'WebEdit30';
    WebEdit30.Left := 439;
    WebEdit30.Top := 155;
    WebEdit30.Width := 256;
    WebEdit30.Height := 22;
    WebEdit30.ChildOrder := 2;
    WebEdit30.HeightPercent := 100.000000000000000000;
    WebEdit30.WidthPercent := 100.000000000000000000;
    WebEdit31.SetParentComponent(WebPageControl9Sheet4);
    WebEdit31.Name := 'WebEdit31';
    WebEdit31.Left := 439;
    WebEdit31.Top := 88;
    WebEdit31.Width := 256;
    WebEdit31.Height := 22;
    WebEdit31.ChildOrder := 2;
    WebEdit31.HeightPercent := 100.000000000000000000;
    WebEdit31.ReadOnly := True;
    WebEdit31.WidthPercent := 100.000000000000000000;
    WebButton83.SetParentComponent(WebPageControl9Sheet4);
    WebButton83.Name := 'WebButton83';
    WebButton83.Left := 439;
    WebButton83.Top := 188;
    WebButton83.Width := 257;
    WebButton83.Height := 25;
    WebButton83.Caption := 'Neuer Eintrag';
    WebButton83.ChildOrder := 6;
    WebButton83.HeightPercent := 100.000000000000000000;
    WebButton83.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton83, Self, 'OnClick', 'WebButton83Click');
    WebButton84.SetParentComponent(WebPageControl9Sheet4);
    WebButton84.Name := 'WebButton84';
    WebButton84.Left := 439;
    WebButton84.Top := 225;
    WebButton84.Width := 257;
    WebButton84.Height := 25;
    WebButton84.Caption := 'Neuer Untereintrag';
    WebButton84.ChildOrder := 7;
    WebButton84.HeightPercent := 100.000000000000000000;
    WebButton84.WidthPercent := 100.000000000000000000;
    WebButton85.SetParentComponent(WebPageControl9Sheet4);
    WebButton85.Name := 'WebButton85';
    WebButton85.Left := 440;
    WebButton85.Top := 264;
    WebButton85.Width := 255;
    WebButton85.Height := 25;
    WebButton85.Caption := #196'ndern';
    WebButton85.ChildOrder := 8;
    WebButton85.HeightPercent := 100.000000000000000000;
    WebButton85.WidthPercent := 100.000000000000000000;
    WebButton86.SetParentComponent(WebPageControl9Sheet4);
    WebButton86.Name := 'WebButton86';
    WebButton86.Left := 440;
    WebButton86.Top := 296;
    WebButton86.Width := 255;
    WebButton86.Height := 25;
    WebButton86.Caption := 'L'#246'schen';
    WebButton86.ChildOrder := 9;
    WebButton86.HeightPercent := 100.000000000000000000;
    WebButton86.WidthPercent := 100.000000000000000000;
    WebPanel37.SetParentComponent(WebPageControl8Sheet2);
    WebPanel37.Name := 'WebPanel37';
    WebPanel37.Left := 0;
    WebPanel37.Top := 393;
    WebPanel37.Width := 950;
    WebPanel37.Height := 60;
    WebPanel37.HeightPercent := 100.000000000000000000;
    WebPanel37.WidthPercent := 100.000000000000000000;
    WebPanel37.Align := alBottom;
    WebPanel37.BorderColor := clSilver;
    WebPanel37.BorderStyle := bsNone;
    WebPanel37.ChildOrder := 1;
    WebButton81.SetParentComponent(WebPanel37);
    WebButton81.Name := 'WebButton81';
    WebButton81.Left := 15;
    WebButton81.Top := 16;
    WebButton81.Width := 267;
    WebButton81.Height := 38;
    WebButton81.Caption := 'Speichern und zur'#252'ck zum Shop';
    WebButton81.Font.Charset := DEFAULT_CHARSET;
    WebButton81.Font.Color := clWindowText;
    WebButton81.Font.Height := -16;
    WebButton81.Font.Name := 'Arial';
    WebButton81.Font.Style := [fsBold];
    WebButton81.HeightPercent := 100.000000000000000000;
    WebButton81.ParentFont := False;
    WebButton81.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton81, Self, 'OnClick', 'WebButton81Click');
    WebButton82.SetParentComponent(WebPanel37);
    WebButton82.Name := 'WebButton82';
    WebButton82.Left := 289;
    WebButton82.Top := 16;
    WebButton82.Width := 184;
    WebButton82.Height := 38;
    WebButton82.Caption := 'Zur'#252'ck zum Shop';
    WebButton82.ChildOrder := 1;
    WebButton82.Font.Charset := DEFAULT_CHARSET;
    WebButton82.Font.Color := clWindowText;
    WebButton82.Font.Height := -16;
    WebButton82.Font.Name := 'Arial';
    WebButton82.Font.Style := [fsBold];
    WebButton82.HeightPercent := 100.000000000000000000;
    WebButton82.ParentFont := False;
    WebButton82.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton82, Self, 'OnClick', 'WebButton82Click');
    WebPageControl8Sheet3.SetParentComponent(WebPageControl8);
    WebPageControl8Sheet3.Name := 'WebPageControl8Sheet3';
    WebPageControl8Sheet3.Left := 0;
    WebPageControl8Sheet3.Top := 20;
    WebPageControl8Sheet3.Width := 950;
    WebPageControl8Sheet3.Height := 453;
    WebPageControl8Sheet3.HeightPercent := 100.000000000000000000;
    WebPageControl8Sheet3.WidthPercent := 100.000000000000000000;
    WebPageControl8Sheet3.Caption := 'Bitte Warten';
    WebPageControl8Sheet3.ChildOrder := 2;
    WebPageControl8Sheet3.TabVisible := False;
    WebLabel19.SetParentComponent(WebPageControl8Sheet3);
    WebLabel19.Name := 'WebLabel19';
    WebLabel19.Left := 20;
    WebLabel19.Top := 57;
    WebLabel19.Width := 188;
    WebLabel19.Height := 14;
    WebLabel19.Caption := 'Bitte Warten, Programm wird geladen...';
    WebLabel19.HeightPercent := 100.000000000000000000;
    WebLabel19.WidthPercent := 100.000000000000000000;
    WebProgressBar2.SetParentComponent(WebPageControl8Sheet3);
    WebProgressBar2.Name := 'WebProgressBar2';
    WebProgressBar2.Left := 26;
    WebProgressBar2.Top := 113;
    WebProgressBar2.Width := 148;
    WebProgressBar2.Height := 24;
    WebProgressBar2.HeightPercent := 100.000000000000000000;
    WebProgressBar2.WidthPercent := 100.000000000000000000;
    WebProgressBar2.ChildOrder := 1;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 1400;
    WebPanel1.Height := 105;
    WebPanel1.ElementClassName := 'bg-primary mainform';
    WebPanel1.HeightPercent := 100.000000000000000000;
    WebPanel1.WidthPercent := 100.000000000000000000;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsNone;
    WebPanel1.ChildOrder := 2;
    WebPanel1.ElementFont := efCSS;
    WebImageControl13.SetParentComponent(WebPanel1);
    WebImageControl13.Name := 'WebImageControl13';
    WebImageControl13.Left := 3;
    WebImageControl13.Top := 7;
    WebImageControl13.Width := 320;
    WebImageControl13.Height := 95;
    WebImageControl13.HeightPercent := 100.000000000000000000;
    WebImageControl13.WidthPercent := 100.000000000000000000;
    WebImageControl13.ChildOrder := 1;
    SetEvent(WebImageControl13, Self, 'OnDblClick', 'WebImageControl13DblClick');
    WebLabel70.SetParentComponent(WebPanel1);
    WebLabel70.Name := 'WebLabel70';
    WebLabel70.Left := 744;
    WebLabel70.Top := 7;
    WebLabel70.Width := 60;
    WebLabel70.Height := 14;
    WebLabel70.Caption := 'WebLabel70';
    WebLabel70.HeightPercent := 100.000000000000000000;
    WebLabel70.Visible := False;
    WebLabel70.WidthPercent := 100.000000000000000000;
    WebLabel8.SetParentComponent(WebPanel1);
    WebLabel8.Name := 'WebLabel8';
    WebLabel8.Left := 600;
    WebLabel8.Top := 24;
    WebLabel8.Width := 54;
    WebLabel8.Height := 14;
    WebLabel8.Caption := 'WebLabel8';
    WebLabel8.HeightPercent := 100.000000000000000000;
    WebLabel8.Visible := False;
    WebLabel8.WidthPercent := 100.000000000000000000;
    WebLabel123.SetParentComponent(WebPanel1);
    WebLabel123.Name := 'WebLabel123';
    WebLabel123.Left := 768;
    WebLabel123.Top := 80;
    WebLabel123.Width := 66;
    WebLabel123.Height := 14;
    WebLabel123.Caption := 'WebLabel123';
    WebLabel123.HeightPercent := 100.000000000000000000;
    WebLabel123.Visible := False;
    WebLabel123.WidthPercent := 100.000000000000000000;
    WebLabel126.SetParentComponent(WebPanel1);
    WebLabel126.Name := 'WebLabel126';
    WebLabel126.Left := 768;
    WebLabel126.Top := 32;
    WebLabel126.Width := 32;
    WebLabel126.Height := 14;
    WebLabel126.Caption := 'Modus';
    WebLabel126.HeightPercent := 100.000000000000000000;
    WebLabel126.Visible := False;
    WebLabel126.WidthPercent := 100.000000000000000000;
    WebMessageDlg1.SetParentComponent(WebPanel1);
    WebMessageDlg1.Name := 'WebMessageDlg1';
    WebMessageDlg1.Left := 640;
    WebMessageDlg1.Top := 24;
    WebMessageDlg1.Width := 24;
    WebMessageDlg1.Height := 24;
    WebMessageDlg1.HeightPercent := 100.000000000000000000;
    WebMessageDlg1.WidthPercent := 100.000000000000000000;
    WebMessageDlg1.Buttons := [];
    WebMessageDlg1.DialogText.BeginUpdate;
    try
      WebMessageDlg1.DialogText.Clear;
      WebMessageDlg1.DialogText.Add('Warning');
      WebMessageDlg1.DialogText.Add('Error');
      WebMessageDlg1.DialogText.Add('Information');
      WebMessageDlg1.DialogText.Add('Confirm');
      WebMessageDlg1.DialogText.Add('Custom');
      WebMessageDlg1.DialogText.Add('OK');
      WebMessageDlg1.DialogText.Add('Cancel');
      WebMessageDlg1.DialogText.Add('Yes');
      WebMessageDlg1.DialogText.Add('No');
      WebMessageDlg1.DialogText.Add('Abort');
      WebMessageDlg1.DialogText.Add('Retry');
      WebMessageDlg1.DialogText.Add('Ignore');
      WebMessageDlg1.DialogText.Add('All');
      WebMessageDlg1.DialogText.Add('Yes to All');
      WebMessageDlg1.DialogText.Add('No to All');
      WebMessageDlg1.DialogText.Add('Help');
      WebMessageDlg1.DialogText.Add('Close');
    finally
      WebMessageDlg1.DialogText.EndUpdate;
    end;
    WebMessageDlg1.Opacity := 0.200000000000000000;
    WebComboBox14.SetParentComponent(WebPanel1);
    WebComboBox14.Name := 'WebComboBox14';
    WebComboBox14.Left := 767;
    WebComboBox14.Top := 52;
    WebComboBox14.Width := 105;
    WebComboBox14.Height := 22;
    WebComboBox14.HeightPercent := 100.000000000000000000;
    WebComboBox14.Text := 'Desktop';
    WebComboBox14.Visible := False;
    WebComboBox14.WidthPercent := 100.000000000000000000;
    SetEvent(WebComboBox14, Self, 'OnChange', 'WebComboBox14Change');
    WebComboBox14.ItemIndex := 0;
    WebComboBox14.Items.BeginUpdate;
    try
      WebComboBox14.Items.Clear;
      WebComboBox14.Items.Add('Desktop');
      WebComboBox14.Items.Add('Handy');
      WebComboBox14.Items.Add('Tablet');
      WebComboBox14.Items.Add('Kiosk');
    finally
      WebComboBox14.Items.EndUpdate;
    end;
    WebPanel9.SetParentComponent(Self);
    WebPanel9.Name := 'WebPanel9';
    WebPanel9.Left := 0;
    WebPanel9.Top := 105;
    WebPanel9.Width := 1400;
    WebPanel9.Height := 48;
    WebPanel9.ElementClassName := 'bg-primary mainform';
    WebPanel9.HeightPercent := 100.000000000000000000;
    WebPanel9.WidthPercent := 100.000000000000000000;
    WebPanel9.Align := alTop;
    WebPanel9.BorderColor := clSilver;
    WebPanel9.BorderStyle := bsNone;
    WebPanel9.ChildOrder := 4;
    WebPanel9.ElementFont := efCSS;
    WebLabel99.SetParentComponent(WebPanel9);
    WebLabel99.Name := 'WebLabel99';
    WebLabel99.Left := 1240;
    WebLabel99.Top := 16;
    WebLabel99.Width := 60;
    WebLabel99.Height := 14;
    WebLabel99.Caption := 'WebLabel99';
    WebLabel99.HeightPercent := 100.000000000000000000;
    WebLabel99.WidthPercent := 100.000000000000000000;
    WebButton4.SetParentComponent(WebPanel9);
    WebButton4.Name := 'WebButton4';
    WebButton4.Left := 576;
    WebButton4.Top := 3;
    WebButton4.Width := 115;
    WebButton4.Height := 41;
    WebButton4.Anchors := [];
    WebButton4.Caption := 'Mein Konto';
    WebButton4.ChildOrder := 2;
    WebButton4.ElementClassName := 'btn btn-primary';
    WebButton4.ElementFont := efCSS;
    WebButton4.HeightPercent := 100.000000000000000000;
    WebButton4.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton4, Self, 'OnClick', 'WebButton4Click');
    WebButton56.SetParentComponent(WebPanel9);
    WebButton56.Name := 'WebButton56';
    WebButton56.Left := 440;
    WebButton56.Top := 3;
    WebButton56.Width := 115;
    WebButton56.Height := 41;
    WebButton56.Anchors := [];
    WebButton56.Caption := 'Prospekt';
    WebButton56.ChildOrder := 8;
    WebButton56.ElementClassName := 'btn btn-primary';
    WebButton56.ElementFont := efCSS;
    WebButton56.HeightPercent := 100.000000000000000000;
    WebButton56.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton56, Self, 'OnClick', 'WebButton56Click');
    WebButton2.SetParentComponent(WebPanel9);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 32;
    WebButton2.Top := 3;
    WebButton2.Width := 115;
    WebButton2.Height := 41;
    WebButton2.Anchors := [];
    WebButton2.Caption := 'Startseite';
    WebButton2.ChildOrder := 8;
    WebButton2.ElementClassName := 'btn btn-primary';
    WebButton2.ElementFont := efCSS;
    WebButton2.HeightPercent := 100.000000000000000000;
    WebButton2.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebButton6.SetParentComponent(WebPanel9);
    WebButton6.Name := 'WebButton6';
    WebButton6.Left := 168;
    WebButton6.Top := 3;
    WebButton6.Width := 115;
    WebButton6.Height := 41;
    WebButton6.Anchors := [];
    WebButton6.Caption := 'Artikelauswahl';
    WebButton6.ChildOrder := 3;
    WebButton6.ElementClassName := 'btn btn-primary';
    WebButton6.ElementFont := efCSS;
    WebButton6.HeightPercent := 100.000000000000000000;
    WebButton6.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton6, Self, 'OnClick', 'WebButton6Click');
    WebButton3.SetParentComponent(WebPanel9);
    WebButton3.Name := 'WebButton3';
    WebButton3.Left := 713;
    WebButton3.Top := 3;
    WebButton3.Width := 115;
    WebButton3.Height := 41;
    WebButton3.Anchors := [];
    WebButton3.Caption := 'Warenkorb';
    WebButton3.ChildOrder := 3;
    WebButton3.ElementClassName := 'btn btn-primary';
    WebButton3.ElementFont := efCSS;
    WebButton3.HeightPercent := 100.000000000000000000;
    WebButton3.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton3, Self, 'OnClick', 'WebButton3Click');
    WebButton11.SetParentComponent(WebPanel9);
    WebButton11.Name := 'WebButton11';
    WebButton11.Left := 985;
    WebButton11.Top := 3;
    WebButton11.Width := 115;
    WebButton11.Height := 41;
    WebButton11.Anchors := [];
    WebButton11.Caption := 'Anmelden';
    WebButton11.ChildOrder := 6;
    WebButton11.ElementClassName := 'btn btn-primary';
    WebButton11.ElementFont := efCSS;
    WebButton11.HeightPercent := 100.000000000000000000;
    WebButton11.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton11, Self, 'OnClick', 'WebButton11Click');
    WebButton10.SetParentComponent(WebPanel9);
    WebButton10.Name := 'WebButton10';
    WebButton10.Left := 1379;
    WebButton10.Top := 1;
    WebButton10.Width := 201;
    WebButton10.Height := 41;
    WebButton10.Anchors := [];
    WebButton10.Caption := 'Zur'#252'ck zu den Artikel-Details';
    WebButton10.ChildOrder := 5;
    WebButton10.ElementClassName := 'btn btn-primary';
    WebButton10.ElementFont := efCSS;
    WebButton10.HeightPercent := 100.000000000000000000;
    WebButton10.Visible := False;
    WebButton10.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton10, Self, 'OnClick', 'WebButton10Click');
    WebButton5.SetParentComponent(WebPanel9);
    WebButton5.Name := 'WebButton5';
    WebButton5.Left := 845;
    WebButton5.Top := 3;
    WebButton5.Width := 115;
    WebButton5.Height := 41;
    WebButton5.Anchors := [];
    WebButton5.Caption := 'Kasse';
    WebButton5.ChildOrder := 3;
    WebButton5.ElementClassName := 'btn btn-primary';
    WebButton5.ElementFont := efCSS;
    WebButton5.HeightPercent := 100.000000000000000000;
    WebButton5.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton5, Self, 'OnClick', 'WebButton5Click');
    WebButton17.SetParentComponent(WebPanel9);
    WebButton17.Name := 'WebButton17';
    WebButton17.Left := 304;
    WebButton17.Top := 3;
    WebButton17.Width := 115;
    WebButton17.Height := 41;
    WebButton17.Anchors := [];
    WebButton17.Caption := 'Theke';
    WebButton17.ChildOrder := 6;
    WebButton17.ElementClassName := 'btn btn-primary';
    WebButton17.ElementFont := efCSS;
    WebButton17.HeightPercent := 100.000000000000000000;
    WebButton17.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton17, Self, 'OnClick', 'WebButton17Click');
    WebButton90.SetParentComponent(WebPanel9);
    WebButton90.Name := 'WebButton90';
    WebButton90.Left := 1121;
    WebButton90.Top := 3;
    WebButton90.Width := 115;
    WebButton90.Height := 41;
    WebButton90.Caption := 'Chat';
    WebButton90.ChildOrder := 9;
    WebButton90.HeightPercent := 100.000000000000000000;
    WebButton90.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton90, Self, 'OnClick', 'WebButton90Click');
    WebButton106.SetParentComponent(WebPanel9);
    WebButton106.Name := 'WebButton106';
    WebButton106.Left := 1258;
    WebButton106.Top := 3;
    WebButton106.Width := 115;
    WebButton106.Height := 41;
    WebButton106.Caption := 'Listen';
    WebButton106.ChildOrder := 9;
    WebButton106.HeightPercent := 100.000000000000000000;
    WebButton106.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton106, Self, 'OnClick', 'WebButton106Click');
    WebButton137.SetParentComponent(WebPanel9);
    WebButton137.Name := 'WebButton137';
    WebButton137.Left := 1248;
    WebButton137.Top := 32;
    WebButton137.Width := 96;
    WebButton137.Height := 25;
    WebButton137.Caption := 'WebButton137';
    WebButton137.ChildOrder := 12;
    WebButton137.HeightPercent := 100.000000000000000000;
    WebButton137.Visible := False;
    WebButton137.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton137, Self, 'OnClick', 'WebButton137Click');
    WebPanel36.SetParentComponent(Self);
    WebPanel36.Name := 'WebPanel36';
    WebPanel36.Left := 0;
    WebPanel36.Top := 153;
    WebPanel36.Width := 1400;
    WebPanel36.Height := 60;
    WebPanel36.ElementClassName := 'bg-primary mainform';
    WebPanel36.HeightPercent := 100.000000000000000000;
    WebPanel36.WidthPercent := 100.000000000000000000;
    WebPanel36.Align := alTop;
    WebPanel36.BorderColor := clSilver;
    WebPanel36.BorderStyle := bsNone;
    WebPanel36.ChildOrder := 4;
    WebPanel36.ElementFont := efCSS;
    WebPanel36.Visible := False;
    MB_Panel.SetParentComponent(Self);
    MB_Panel.Name := 'MB_Panel';
    MB_Panel.Left := 498;
    MB_Panel.Top := 383;
    MB_Panel.Width := 405;
    MB_Panel.Height := 135;
    MB_Panel.ElementClassName := 'bg-secondary';
    MB_Panel.HeightPercent := 100.000000000000000000;
    MB_Panel.WidthPercent := 100.000000000000000000;
    MB_Panel.BorderColor := clSilver;
    MB_Panel.BorderStyle := bsSingle;
    MB_Panel.ChildOrder := 5;
    MB_Panel.ElementFont := efCSS;
    MB_Panel.Visible := False;
    SetEvent(MB_Panel, Self, 'OnMouseEnter', 'MB_PanelMouseEnter');
    SetEvent(MB_Panel, Self, 'OnMouseLeave', 'MB_PanelMouseLeave');
    WebPanel44.SetParentComponent(Self);
    WebPanel44.Name := 'WebPanel44';
    WebPanel44.Left := 950;
    WebPanel44.Top := 213;
    WebPanel44.Width := 450;
    WebPanel44.Height := 473;
    WebPanel44.HeightPercent := 100.000000000000000000;
    WebPanel44.WidthPercent := 100.000000000000000000;
    WebPanel44.Align := alRight;
    WebPanel44.BorderColor := clSilver;
    WebPanel44.BorderStyle := bsSingle;
    WebPanel44.ChildOrder := 6;
    WebPanel44.Visible := False;
    WebPanel45.SetParentComponent(WebPanel44);
    WebPanel45.Name := 'WebPanel45';
    WebPanel45.Left := 0;
    WebPanel45.Top := 0;
    WebPanel45.Width := 450;
    WebPanel45.Height := 100;
    WebPanel45.HeightPercent := 100.000000000000000000;
    WebPanel45.WidthPercent := 100.000000000000000000;
    WebPanel45.Align := alTop;
    WebPanel45.BorderColor := clSilver;
    WebPanel45.BorderStyle := bsSingle;
    WebPanel45.ChildOrder := 5;
    WebLabel94.SetParentComponent(WebPanel45);
    WebLabel94.Name := 'WebLabel94';
    WebLabel94.Left := 356;
    WebLabel94.Top := 24;
    WebLabel94.Width := 79;
    WebLabel94.Height := 14;
    WebLabel94.Caption := 'Nicht verbunden';
    WebLabel94.HeightPercent := 100.000000000000000000;
    WebLabel94.Visible := False;
    WebLabel94.WidthPercent := 100.000000000000000000;
    WebLabel96.SetParentComponent(WebPanel45);
    WebLabel96.Name := 'WebLabel96';
    WebLabel96.Left := 7;
    WebLabel96.Top := 4;
    WebLabel96.Width := 591;
    WebLabel96.Height := 14;
    WebLabel96.Caption := 'Chat, bei Fragen zu einem Artikel bitte auf den Artikel klicken.<br> F'#252'r eine Bestellung sagen Sie bitte "Bestellung aufgeben"';
    WebLabel96.HeightPercent := 100.000000000000000000;
    WebLabel96.WidthPercent := 100.000000000000000000;
    WebButton88.SetParentComponent(WebPanel45);
    WebButton88.Name := 'WebButton88';
    WebButton88.Left := 3;
    WebButton88.Top := 52;
    WebButton88.Width := 155;
    WebButton88.Height := 39;
    WebButton88.Caption := 'WebButton88';
    WebButton88.ChildOrder := 1;
    WebButton88.HeightPercent := 100.000000000000000000;
    WebButton88.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton88, Self, 'OnClick', 'WebButton88Click');
    WebButton92.SetParentComponent(WebPanel45);
    WebButton92.Name := 'WebButton92';
    WebButton92.Left := 179;
    WebButton92.Top := 52;
    WebButton92.Width := 155;
    WebButton92.Height := 39;
    WebButton92.Caption := 'Lautsprecher ein';
    WebButton92.ChildOrder := 1;
    WebButton92.HeightPercent := 100.000000000000000000;
    WebButton92.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton92, Self, 'OnClick', 'WebButton92Click');
    WebPanel46.SetParentComponent(WebPanel44);
    WebPanel46.Name := 'WebPanel46';
    WebPanel46.Left := 0;
    WebPanel46.Top := 348;
    WebPanel46.Width := 450;
    WebPanel46.Height := 125;
    WebPanel46.HeightPercent := 100.000000000000000000;
    WebPanel46.WidthPercent := 100.000000000000000000;
    WebPanel46.Align := alBottom;
    WebPanel46.BorderColor := clSilver;
    WebPanel46.BorderStyle := bsSingle;
    WebPanel46.ChildOrder := 4;
    WebLabel95.SetParentComponent(WebPanel46);
    WebLabel95.Name := 'WebLabel95';
    WebLabel95.Left := 6;
    WebLabel95.Top := 6;
    WebLabel95.Width := 70;
    WebLabel95.Height := 14;
    WebLabel95.Caption := 'Ihre Nachricht:';
    WebLabel95.HeightPercent := 100.000000000000000000;
    WebLabel95.WidthPercent := 100.000000000000000000;
    WebLabel124.SetParentComponent(WebPanel46);
    WebLabel124.Name := 'WebLabel124';
    WebLabel124.Left := 16;
    WebLabel124.Top := 104;
    WebLabel124.Width := 66;
    WebLabel124.Height := 14;
    WebLabel124.Caption := 'WebLabel124';
    WebLabel124.HeightPercent := 100.000000000000000000;
    WebLabel124.Visible := False;
    WebLabel124.WidthPercent := 100.000000000000000000;
    WebEdit32.SetParentComponent(WebPanel46);
    WebEdit32.Name := 'WebEdit32';
    WebEdit32.Left := 6;
    WebEdit32.Top := 22;
    WebEdit32.Width := 441;
    WebEdit32.Height := 32;
    WebEdit32.ChildOrder := 3;
    WebEdit32.Font.Charset := DEFAULT_CHARSET;
    WebEdit32.Font.Color := clWindowText;
    WebEdit32.Font.Height := -16;
    WebEdit32.Font.Name := 'Arial';
    WebEdit32.Font.Style := [fsBold];
    WebEdit32.HeightPercent := 100.000000000000000000;
    WebEdit32.ParentFont := False;
    WebEdit32.WidthPercent := 100.000000000000000000;
    WebButton89.SetParentComponent(WebPanel46);
    WebButton89.Name := 'WebButton89';
    WebButton89.Left := 262;
    WebButton89.Top := 60;
    WebButton89.Width := 185;
    WebButton89.Height := 36;
    WebButton89.Caption := 'WebButton89';
    WebButton89.ChildOrder := 4;
    WebButton89.HeightPercent := 100.000000000000000000;
    WebButton89.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton89, Self, 'OnClick', 'WebButton89Click');
    WebButton123.SetParentComponent(WebPanel46);
    WebButton123.Name := 'WebButton123';
    WebButton123.Left := 16;
    WebButton123.Top := 60;
    WebButton123.Width := 169;
    WebButton123.Height := 36;
    WebButton123.Caption := 'WebButton123';
    WebButton123.ChildOrder := 3;
    WebButton123.HeightPercent := 100.000000000000000000;
    WebButton123.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton123, Self, 'OnClick', 'WebButton123Click');
    SetEvent(WebButton123, Self, 'OnMouseDown', 'WebButton123MouseDown');
    SetEvent(WebButton123, Self, 'OnMouseUp', 'WebButton123MouseUp');
    WebStringGrid4.SetParentComponent(WebPanel44);
    WebStringGrid4.Name := 'WebStringGrid4';
    WebStringGrid4.Left := 0;
    WebStringGrid4.Top := 153;
    WebStringGrid4.Width := 450;
    WebStringGrid4.Height := 195;
    WebStringGrid4.Align := alClient;
    WebStringGrid4.ColCount := 1;
    WebStringGrid4.FixedCols := 0;
    WebStringGrid4.FixedRows := 0;
    WebStringGrid4.Font.Charset := DEFAULT_CHARSET;
    WebStringGrid4.Font.Color := clWindowText;
    WebStringGrid4.Font.Height := -16;
    WebStringGrid4.Font.Name := 'Arial';
    WebStringGrid4.Font.Style := [fsBold];
    WebStringGrid4.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRangeSelect,goRowSelect];
    WebStringGrid4.ParentFont := False;
    WebStringGrid4.TabOrder := 2;
    WebStringGrid4.ElementClassName := 'table-secondary';
    WebStringGrid4.ElementFont := efCSS;
    WebStringGrid4.RangeEdit.Max := 100.000000000000000000;
    WebStringGrid4.RangeEdit.Step := 1.000000000000000000;
    WebStringGrid4.HeightPercent := 100.000000000000000000;
    WebStringGrid4.WidthPercent := 100.000000000000000000;
    WebStringGrid4.ColWidths[0] := 443;
    WebStringGrid4.RowHeights[0] := 24;
    WebStringGrid4.RowHeights[1] := 24;
    WebStringGrid4.RowHeights[2] := 24;
    WebStringGrid4.RowHeights[3] := 24;
    WebStringGrid4.RowHeights[4] := 24;
    WebMultimediaPlayer1.SetParentComponent(WebPanel44);
    WebMultimediaPlayer1.Name := 'WebMultimediaPlayer1';
    WebMultimediaPlayer1.Left := 129;
    WebMultimediaPlayer1.Top := 196;
    WebMultimediaPlayer1.Width := 200;
    WebMultimediaPlayer1.Height := 41;
    WebMultimediaPlayer1.HeightPercent := 100.000000000000000000;
    WebMultimediaPlayer1.WidthPercent := 100.000000000000000000;
    WebMultimediaPlayer1.MultimediaType := mtAudio;
    WebMultimediaPlayer1.PlaybackRate := 1.000000000000000000;
    WebMultimediaPlayer1.Visible := False;
    WebPanel53.SetParentComponent(WebPanel44);
    WebPanel53.Name := 'WebPanel53';
    WebPanel53.Left := 0;
    WebPanel53.Top := 100;
    WebPanel53.Width := 450;
    WebPanel53.Height := 53;
    WebPanel53.HeightPercent := 100.000000000000000000;
    WebPanel53.WidthPercent := 100.000000000000000000;
    WebPanel53.Align := alTop;
    WebPanel53.BorderColor := clSilver;
    WebPanel53.BorderStyle := bsSingle;
    WebPanel53.ChildOrder := 4;
    WebPanel53.Visible := False;
    WebLabel125.SetParentComponent(WebPanel53);
    WebLabel125.Name := 'WebLabel125';
    WebLabel125.Left := 9;
    WebLabel125.Top := 7;
    WebLabel125.Width := 540;
    WebLabel125.Height := 14;
    WebLabel125.Caption := 'Bestell-Modus aktiv. Sagen Sie "Bestellung fertig" <br> oder "Artikel" + Bezeichnung + "Menge" + Mengenangabe';
    WebLabel125.HeightPercent := 100.000000000000000000;
    WebLabel125.WidthPercent := 100.000000000000000000;
    WebPanel57.SetParentComponent(Self);
    WebPanel57.Name := 'WebPanel57';
    WebPanel57.Left := 0;
    WebPanel57.Top := 686;
    WebPanel57.Width := 1400;
    WebPanel57.Height := 54;
    WebPanel57.HeightPercent := 100.000000000000000000;
    WebPanel57.WidthPercent := 100.000000000000000000;
    WebPanel57.Align := alBottom;
    WebPanel57.BorderColor := clSilver;
    WebPanel57.BorderStyle := bsSingle;
    WebPanel57.ChildOrder := 7;
    WebPanel57.Visible := False;
    WebButton126.SetParentComponent(WebPanel57);
    WebButton126.Name := 'WebButton126';
    WebButton126.Left := 20;
    WebButton126.Top := 6;
    WebButton126.Width := 134;
    WebButton126.Height := 35;
    WebButton126.Caption := 'Zur'#252'ck';
    WebButton126.ChildOrder := 2;
    WebButton126.HeightPercent := 100.000000000000000000;
    WebButton126.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton126, Self, 'OnClick', 'WebButton126Click');
    WebButton127.SetParentComponent(WebPanel57);
    WebButton127.Name := 'WebButton127';
    WebButton127.Left := 457;
    WebButton127.Top := 6;
    WebButton127.Width := 115;
    WebButton127.Height := 41;
    WebButton127.Anchors := [];
    WebButton127.Caption := 'Warenkorb';
    WebButton127.ChildOrder := 3;
    WebButton127.ElementClassName := 'btn btn-primary';
    WebButton127.ElementFont := efCSS;
    WebButton127.HeightPercent := 100.000000000000000000;
    WebButton127.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton127, Self, 'OnClick', 'WebButton3Click');
    WebButton132.SetParentComponent(WebPanel57);
    WebButton132.Name := 'WebButton132';
    WebButton132.Left := 592;
    WebButton132.Top := 6;
    WebButton132.Width := 193;
    WebButton132.Height := 39;
    WebButton132.Caption := 'Bestellung abbrechen';
    WebButton132.ChildOrder := 2;
    WebButton132.HeightPercent := 100.000000000000000000;
    WebButton132.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton132, Self, 'OnClick', 'WebButton132Click');
    WebTimer1.SetParentComponent(Self);
    WebTimer1.Name := 'WebTimer1';
    WebTimer1.Enabled := False;
    SetEvent(WebTimer1, Self, 'OnTimer', 'WebTimer1Timer');
    WebTimer1.Left := 536;
    WebTimer1.Top := 8;
    WebTimer2.SetParentComponent(Self);
    WebTimer2.Name := 'WebTimer2';
    WebTimer2.Enabled := False;
    WebTimer2.Interval := 50;
    SetEvent(WebTimer2, Self, 'OnTimer', 'WebTimer2Timer');
    WebTimer2.Left := 336;
    WebTimer2.Top := 32;
    WebHttpRequest1.SetParentComponent(Self);
    WebHttpRequest1.Name := 'WebHttpRequest1';
    WebHttpRequest1.Headers.BeginUpdate;
    try
      WebHttpRequest1.Headers.Clear;
      WebHttpRequest1.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
    finally
      WebHttpRequest1.Headers.EndUpdate;
    end;
    SetEvent(WebHttpRequest1, Self, 'OnResponse', 'WebHttpRequest1Response');
    WebHttpRequest1.Left := 568;
    WebHttpRequest1.Top := 56;
    WebClientDataSet1.SetParentComponent(Self);
    WebClientDataSet1.Name := 'WebClientDataSet1';
    WebClientDataSet1.Connection := WebClientConnection1;
    WebClientDataSet1.Left := 280;
    WebClientDataSet1.Top := 16;
    WebTimer3.SetParentComponent(Self);
    WebTimer3.Name := 'WebTimer3';
    WebTimer3.Enabled := False;
    WebTimer3.Interval := 300;
    SetEvent(WebTimer3, Self, 'OnTimer', 'WebTimer3Timer');
    WebTimer3.Left := 96;
    WebTimer3.Top := 24;
    WebTimer4.SetParentComponent(Self);
    WebTimer4.Name := 'WebTimer4';
    WebTimer4.Enabled := False;
    WebTimer4.Interval := 50;
    SetEvent(WebTimer4, Self, 'OnTimer', 'WebTimer4Timer');
    WebTimer4.Left := 16;
    WebTimer4.Top := 40;
    WebTimer5.SetParentComponent(Self);
    WebTimer5.Name := 'WebTimer5';
    WebTimer5.Enabled := False;
    WebTimer5.Interval := 200;
    SetEvent(WebTimer5, Self, 'OnTimer', 'WebTimer5Timer');
    WebTimer5.Left := 160;
    WebTimer5.Top := 16;
    WebClientConnection1.SetParentComponent(Self);
    WebClientConnection1.Name := 'WebClientConnection1';
    WebClientConnection1.Active := False;
    WebClientConnection1.AfterConnect := WebClientConnection1AfterConnect;
    SetEvent(WebClientConnection1, Self, 'OnConnectError', 'WebClientConnection1ConnectError');
    WebClientConnection1.Left := 224;
    WebClientConnection1.Top := 16;
    WebTimer6.SetParentComponent(Self);
    WebTimer6.Name := 'WebTimer6';
    WebTimer6.Enabled := False;
    WebTimer6.Interval := 10;
    SetEvent(WebTimer6, Self, 'OnTimer', 'WebTimer6Timer');
    WebTimer6.Left := 640;
    WebTimer6.Top := 64;
    WebTimer7.SetParentComponent(Self);
    WebTimer7.Name := 'WebTimer7';
    WebTimer7.Enabled := False;
    WebTimer7.Interval := 30;
    SetEvent(WebTimer7, Self, 'OnTimer', 'WebTimer7Timer');
    WebTimer7.Left := 488;
    WebTimer7.Top := 8;
    WebSocketClient1.SetParentComponent(Self);
    WebSocketClient1.Name := 'WebSocketClient1';
    WebSocketClient1.Port := 20010;
    WebSocketClient1.HostName := 'localhost';
    SetEvent(WebSocketClient1, Self, 'OnConnect', 'WebSocketClient1Connect');
    SetEvent(WebSocketClient1, Self, 'OnDisconnect', 'WebSocketClient1Disconnect');
    SetEvent(WebSocketClient1, Self, 'OnDataReceived', 'WebSocketClient1DataReceived');
    WebSocketClient1.Left := 864;
    WebSocketClient1.Top := 253;
    WebTimer8.SetParentComponent(Self);
    WebTimer8.Name := 'WebTimer8';
    WebTimer8.Enabled := False;
    SetEvent(WebTimer8, Self, 'OnTimer', 'WebTimer8Timer');
    WebTimer8.Left := 696;
    WebTimer8.Top := 24;
    StartTimer.SetParentComponent(Self);
    StartTimer.Name := 'StartTimer';
    StartTimer.Enabled := False;
    StartTimer.Interval := 50;
    SetEvent(StartTimer, Self, 'OnTimer', 'StartTimerTimer');
    StartTimer.Left := 432;
    StartTimer.Top := 32;
    WebTimer9.SetParentComponent(Self);
    WebTimer9.Name := 'WebTimer9';
    WebTimer9.Enabled := False;
    WebTimer9.Interval := 15000;
    SetEvent(WebTimer9, Self, 'OnTimer', 'WebTimer9Timer');
    WebTimer9.Left := 688;
    WebTimer9.Top := 72;
    WebMediaCapture1.SetParentComponent(Self);
    WebMediaCapture1.Name := 'WebMediaCapture1';
    WebMediaCapture1.Capture := mctAudio;
    WebMediaCapture1.SmoothingTimeConstant := 0.900000000000000000;
    SetEvent(WebMediaCapture1, Self, 'OnStopCapture', 'WebMediaCapture1StopCapture');
    WebMediaCapture1.Left := 168;
    WebMediaCapture1.Top := 128;
    WebHttpRequest2.SetParentComponent(Self);
    WebHttpRequest2.Name := 'WebHttpRequest2';
    WebHttpRequest2.Command := httpPOST;
    WebHttpRequest2.Headers.BeginUpdate;
    try
      WebHttpRequest2.Headers.Clear;
      WebHttpRequest2.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
    finally
      WebHttpRequest2.Headers.EndUpdate;
    end;
    SetEvent(WebHttpRequest2, Self, 'OnError', 'WebHttpRequest2Error');
    SetEvent(WebHttpRequest2, Self, 'OnResponse', 'WebHttpRequest2Response');
    SetEvent(WebHttpRequest2, Self, 'OnRequestResponse', 'WebHttpRequest2RequestResponse');
    WebHttpRequest2.Left := 1118;
    WebHttpRequest2.Top := 565;
    WebHttpRequest3.SetParentComponent(Self);
    WebHttpRequest3.Name := 'WebHttpRequest3';
    WebHttpRequest3.Command := httpPOST;
    WebHttpRequest3.Headers.BeginUpdate;
    try
      WebHttpRequest3.Headers.Clear;
      WebHttpRequest3.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
    finally
      WebHttpRequest3.Headers.EndUpdate;
    end;
    SetEvent(WebHttpRequest3, Self, 'OnResponse', 'WebHttpRequest3Response');
    WebHttpRequest3.Left := 1214;
    WebHttpRequest3.Top := 565;
    WebTimer10.SetParentComponent(Self);
    WebTimer10.Name := 'WebTimer10';
    WebTimer10.Enabled := False;
    WebTimer10.Interval := 100;
    SetEvent(WebTimer10, Self, 'OnTimer', 'WebTimer10Timer');
    WebTimer10.Left := 1286;
    WebTimer10.Top := 573;
    WebTimer11.SetParentComponent(Self);
    WebTimer11.Name := 'WebTimer11';
    WebTimer11.Enabled := False;
    WebTimer11.Interval := 50;
    SetEvent(WebTimer11, Self, 'OnTimer', 'WebTimer11Timer');
    WebTimer11.Left := 24;
    WebTimer11.Top := 165;
    WebPopupMenu1.SetParentComponent(Self);
    WebPopupMenu1.Name := 'WebPopupMenu1';
    WebPopupMenu1.Appearance.HamburgerMenu.Caption := 'Menu';
    WebPopupMenu1.Appearance.SubmenuIndicator := '&#9658;';
    WebPopupMenu1.Left := 640;
    WebPopupMenu1.Top := 277;
    AllergieFilter1.SetParentComponent(WebPopupMenu1);
    AllergieFilter1.Name := 'AllergieFilter1';
    AllergieFilter1.Caption := 'Allergie-Filter';
    SetEvent(AllergieFilter1, Self, 'OnClick', 'AllergieFilter1Click');
    ErnhrungsFilter1.SetParentComponent(WebPopupMenu1);
    ErnhrungsFilter1.Name := 'ErnhrungsFilter1';
    ErnhrungsFilter1.Caption := 'Ern'#228'hrungs-Filter';
    SetEvent(ErnhrungsFilter1, Self, 'OnClick', 'ErnhrungsFilter1Click');
    LieferartenFilter1.SetParentComponent(WebPopupMenu1);
    LieferartenFilter1.Name := 'LieferartenFilter1';
    LieferartenFilter1.Caption := 'Lieferarten-Filter';
    SetEvent(LieferartenFilter1, Self, 'OnClick', 'LieferartenFilter1Click');
    N3.SetParentComponent(WebPopupMenu1);
    N3.Name := 'N3';
    N3.Caption := '-';
    Prospekte1.SetParentComponent(WebPopupMenu1);
    Prospekte1.Name := 'Prospekte1';
    Prospekte1.Caption := 'Prospekte';
    SetEvent(Prospekte1, Self, 'OnClick', 'Prospekte1Click');
    heken1.SetParentComponent(WebPopupMenu1);
    heken1.Name := 'heken1';
    heken1.Caption := 'Theken';
    SetEvent(heken1, Self, 'OnClick', 'heken1Click');
    N1.SetParentComponent(WebPopupMenu1);
    N1.Name := 'N1';
    N1.Caption := '-';
    MeinKonto1.SetParentComponent(WebPopupMenu1);
    MeinKonto1.Name := 'MeinKonto1';
    MeinKonto1.Caption := 'Mein Konto';
    SetEvent(MeinKonto1, Self, 'OnClick', 'MeinKonto1Click');
    Anmelden1.SetParentComponent(WebPopupMenu1);
    Anmelden1.Name := 'Anmelden1';
    Anmelden1.Caption := 'Anmelden';
    SetEvent(Anmelden1, Self, 'OnClick', 'Anmelden1Click');
    N2.SetParentComponent(WebPopupMenu1);
    N2.Name := 'N2';
    N2.Caption := '-';
    Chat1.SetParentComponent(WebPopupMenu1);
    Chat1.Name := 'Chat1';
    Chat1.Caption := 'Chat';
    SetEvent(Chat1, Self, 'OnClick', 'Chat1Click');
    WebTimer12.SetParentComponent(Self);
    WebTimer12.Name := 'WebTimer12';
    WebTimer12.Enabled := True;
    WebTimer12.Interval := 200;
    SetEvent(WebTimer12, Self, 'OnTimer', 'WebTimer12Timer');
    WebTimer12.Left := 320;
    WebTimer12.Top := 321;
  finally
    WebPanel6.AfterLoadDFMValues;
    WebImageControl12.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebLabel7.AfterLoadDFMValues;
    WebLabel24.AfterLoadDFMValues;
    WebLabel25.AfterLoadDFMValues;
    WebPageControl8.AfterLoadDFMValues;
    WebPageControl8Sheet1.AfterLoadDFMValues;
    WebPageControl1.AfterLoadDFMValues;
    WebPageControl1Sheet1.AfterLoadDFMValues;
    WebPanel3.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebLabel98.AfterLoadDFMValues;
    WebButton108.AfterLoadDFMValues;
    WebButton110.AfterLoadDFMValues;
    WebDBResponsiveGrid3.AfterLoadDFMValues;
    WebPageControl1Sheet2.AfterLoadDFMValues;
    WebPanel4.AfterLoadDFMValues;
    WebPanel10.AfterLoadDFMValues;
    WebDBResponsiveGrid2.AfterLoadDFMValues;
    WebPanel13.AfterLoadDFMValues;
    WebButton8.AfterLoadDFMValues;
    WebPanel14.AfterLoadDFMValues;
    WebPanel15.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton7.AfterLoadDFMValues;
    WebButton93.AfterLoadDFMValues;
    WebButton100.AfterLoadDFMValues;
    WebPageControl2.AfterLoadDFMValues;
    WebPageControl2Sheet1.AfterLoadDFMValues;
    WebImageControl11.AfterLoadDFMValues;
    WebLabel29.AfterLoadDFMValues;
    WebLabel30.AfterLoadDFMValues;
    WebLabel31.AfterLoadDFMValues;
    WebLabel32.AfterLoadDFMValues;
    WebLabel72.AfterLoadDFMValues;
    WebEdit5.AfterLoadDFMValues;
    WebComboBox4.AfterLoadDFMValues;
    WebComboBox5.AfterLoadDFMValues;
    WebComboBox6.AfterLoadDFMValues;
    WebPageControl2Sheet2.AfterLoadDFMValues;
    WebRichEdit8.AfterLoadDFMValues;
    WebPanel52.AfterLoadDFMValues;
    WebLabel122.AfterLoadDFMValues;
    WebPageControl2Sheet3.AfterLoadDFMValues;
    WebLabel111.AfterLoadDFMValues;
    WebLabel112.AfterLoadDFMValues;
    WebLabel113.AfterLoadDFMValues;
    WebLabel114.AfterLoadDFMValues;
    WebLabel115.AfterLoadDFMValues;
    WebLabel116.AfterLoadDFMValues;
    WebLabel117.AfterLoadDFMValues;
    WebLabel118.AfterLoadDFMValues;
    WebLabel119.AfterLoadDFMValues;
    WebLabel120.AfterLoadDFMValues;
    WebLabel121.AfterLoadDFMValues;
    WebEdit41.AfterLoadDFMValues;
    WebEdit42.AfterLoadDFMValues;
    WebEdit43.AfterLoadDFMValues;
    WebEdit44.AfterLoadDFMValues;
    WebEdit45.AfterLoadDFMValues;
    WebEdit46.AfterLoadDFMValues;
    WebEdit47.AfterLoadDFMValues;
    WebEdit48.AfterLoadDFMValues;
    WebPageControl2Sheet4.AfterLoadDFMValues;
    WebPageControl2Sheet5.AfterLoadDFMValues;
    WebPanel31.AfterLoadDFMValues;
    WebLabel83.AfterLoadDFMValues;
    WebComboBox11.AfterLoadDFMValues;
    WebPanel32.AfterLoadDFMValues;
    WebDBMemo1.AfterLoadDFMValues;
    WebDBGrid2.AfterLoadDFMValues;
    WebPageControl2Sheet6.AfterLoadDFMValues;
    WebImageControl10.AfterLoadDFMValues;
    WebPanel48.AfterLoadDFMValues;
    WebPanel33.AfterLoadDFMValues;
    WebLabel84.AfterLoadDFMValues;
    WebButton94.AfterLoadDFMValues;
    WebButton95.AfterLoadDFMValues;
    WebButton96.AfterLoadDFMValues;
    WebButton97.AfterLoadDFMValues;
    WebButton98.AfterLoadDFMValues;
    WebButton99.AfterLoadDFMValues;
    WebComboBox16.AfterLoadDFMValues;
    WebPageControl1Sheet3.AfterLoadDFMValues;
    WebPageControl6.AfterLoadDFMValues;
    WebPageControl6Sheet1.AfterLoadDFMValues;
    WebButton25.AfterLoadDFMValues;
    WebButton53.AfterLoadDFMValues;
    WebButton54.AfterLoadDFMValues;
    WebPanel51.AfterLoadDFMValues;
    WebPageControl6Sheet2.AfterLoadDFMValues;
    WebPageControl6Sheet3.AfterLoadDFMValues;
    WebPageControl1Sheet4.AfterLoadDFMValues;
    WebPanel7.AfterLoadDFMValues;
    WebPanel8.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebComboBox3.AfterLoadDFMValues;
    WebEdit2.AfterLoadDFMValues;
    WebButton101.AfterLoadDFMValues;
    WebButton102.AfterLoadDFMValues;
    WebComboBox15.AfterLoadDFMValues;
    WebListBox1.AfterLoadDFMValues;
    WebPanel30.AfterLoadDFMValues;
    WebLabel78.AfterLoadDFMValues;
    WebCheckBox3.AfterLoadDFMValues;
    WebCheckBox2.AfterLoadDFMValues;
    WebCheckBox1.AfterLoadDFMValues;
    WebButton103.AfterLoadDFMValues;
    WebButton104.AfterLoadDFMValues;
    WebButton105.AfterLoadDFMValues;
    WebPanel11.AfterLoadDFMValues;
    WebPageControl7.AfterLoadDFMValues;
    WebPageControl7Sheet1.AfterLoadDFMValues;
    WebPanel5.AfterLoadDFMValues;
    WebLabel77.AfterLoadDFMValues;
    WebLabel128.AfterLoadDFMValues;
    WebComboBox10.AfterLoadDFMValues;
    WebButton87.AfterLoadDFMValues;
    WebButton91.AfterLoadDFMValues;
    WebDBResponsiveGrid1.AfterLoadDFMValues;
    WebPanel42.AfterLoadDFMValues;
    WebRichEdit9.AfterLoadDFMValues;
    WebPanel47.AfterLoadDFMValues;
    WebPanel43.AfterLoadDFMValues;
    WebLabel93.AfterLoadDFMValues;
    WebPageControl7Sheet2.AfterLoadDFMValues;
    WebLabel80.AfterLoadDFMValues;
    WebLabel81.AfterLoadDFMValues;
    WebLabel82.AfterLoadDFMValues;
    WebButton69.AfterLoadDFMValues;
    WebButton74.AfterLoadDFMValues;
    WebButton75.AfterLoadDFMValues;
    WebButton70.AfterLoadDFMValues;
    WebButton76.AfterLoadDFMValues;
    WebButton77.AfterLoadDFMValues;
    WebPageControl7Sheet3.AfterLoadDFMValues;
    WebLabel79.AfterLoadDFMValues;
    WebProgressBar1.AfterLoadDFMValues;
    WebPageControl1Sheet5.AfterLoadDFMValues;
    WebPanel12.AfterLoadDFMValues;
    WebRichEdit1.AfterLoadDFMValues;
    WebPageControl1Sheet6.AfterLoadDFMValues;
    WebPageControl4.AfterLoadDFMValues;
    WebPageControl4Sheet1.AfterLoadDFMValues;
    lbName.AfterLoadDFMValues;
    lbAddress1.AfterLoadDFMValues;
    lbAddress2.AfterLoadDFMValues;
    lbCity.AfterLoadDFMValues;
    lbPostalCode.AfterLoadDFMValues;
    lbState.AfterLoadDFMValues;
    WebLabel34.AfterLoadDFMValues;
    WebLabel36.AfterLoadDFMValues;
    WebLabel49.AfterLoadDFMValues;
    edName.AfterLoadDFMValues;
    edAddress1.AfterLoadDFMValues;
    edAddress2.AfterLoadDFMValues;
    edCity.AfterLoadDFMValues;
    edPostalCode.AfterLoadDFMValues;
    WebComboBox1.AfterLoadDFMValues;
    WebButton37.AfterLoadDFMValues;
    WebButton38.AfterLoadDFMValues;
    WebComboBox7.AfterLoadDFMValues;
    WebPageControl4Sheet2.AfterLoadDFMValues;
    WebLabel33.AfterLoadDFMValues;
    WebLabel37.AfterLoadDFMValues;
    WebLabel38.AfterLoadDFMValues;
    WebLabel39.AfterLoadDFMValues;
    WebLabel40.AfterLoadDFMValues;
    WebLabel41.AfterLoadDFMValues;
    WebLabel42.AfterLoadDFMValues;
    WebLabel43.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebEdit6.AfterLoadDFMValues;
    WebEdit7.AfterLoadDFMValues;
    WebEdit8.AfterLoadDFMValues;
    WebEdit9.AfterLoadDFMValues;
    WebComboBox2.AfterLoadDFMValues;
    WebButton35.AfterLoadDFMValues;
    WebButton36.AfterLoadDFMValues;
    WebPageControl4Sheet3.AfterLoadDFMValues;
    WebLabel12.AfterLoadDFMValues;
    WebLabel51.AfterLoadDFMValues;
    mePayment.AfterLoadDFMValues;
    WebButton27.AfterLoadDFMValues;
    WebRadioGroup1.AfterLoadDFMValues;
    WebCheckBox7.AfterLoadDFMValues;
    WebButton40.AfterLoadDFMValues;
    WebButton41.AfterLoadDFMValues;
    WebButton42.AfterLoadDFMValues;
    WebEdit12.AfterLoadDFMValues;
    WebEdit13.AfterLoadDFMValues;
    WebEdit14.AfterLoadDFMValues;
    WebPageControl4Sheet4.AfterLoadDFMValues;
    WebButton28.AfterLoadDFMValues;
    WebButton29.AfterLoadDFMValues;
    WebButton30.AfterLoadDFMValues;
    WebButton31.AfterLoadDFMValues;
    WebButton22.AfterLoadDFMValues;
    WebButton23.AfterLoadDFMValues;
    WebPageControl4Sheet5.AfterLoadDFMValues;
    WebLabel44.AfterLoadDFMValues;
    WebLabel45.AfterLoadDFMValues;
    WebLabel46.AfterLoadDFMValues;
    WebEdit10.AfterLoadDFMValues;
    WebEdit11.AfterLoadDFMValues;
    WebButton32.AfterLoadDFMValues;
    WebButton33.AfterLoadDFMValues;
    WebButton34.AfterLoadDFMValues;
    WebButton39.AfterLoadDFMValues;
    WebCheckBox5.AfterLoadDFMValues;
    WebPageControl4Sheet6.AfterLoadDFMValues;
    WebPageControl5.AfterLoadDFMValues;
    WebPageControl5Sheet1.AfterLoadDFMValues;
    WebLabel52.AfterLoadDFMValues;
    WebLabel53.AfterLoadDFMValues;
    WebLabel54.AfterLoadDFMValues;
    WebLabel55.AfterLoadDFMValues;
    WebLabel56.AfterLoadDFMValues;
    WebLabel57.AfterLoadDFMValues;
    WebLabel58.AfterLoadDFMValues;
    WebLabel59.AfterLoadDFMValues;
    WebEdit15.AfterLoadDFMValues;
    WebEdit16.AfterLoadDFMValues;
    WebEdit17.AfterLoadDFMValues;
    WebEdit18.AfterLoadDFMValues;
    WebEdit19.AfterLoadDFMValues;
    WebComboBox8.AfterLoadDFMValues;
    WebButton45.AfterLoadDFMValues;
    WebButton46.AfterLoadDFMValues;
    WebPageControl5Sheet2.AfterLoadDFMValues;
    WebLabel60.AfterLoadDFMValues;
    WebLabel61.AfterLoadDFMValues;
    WebLabel62.AfterLoadDFMValues;
    WebLabel63.AfterLoadDFMValues;
    WebLabel64.AfterLoadDFMValues;
    WebLabel65.AfterLoadDFMValues;
    WebEdit20.AfterLoadDFMValues;
    WebEdit21.AfterLoadDFMValues;
    WebEdit22.AfterLoadDFMValues;
    WebEdit23.AfterLoadDFMValues;
    WebButton47.AfterLoadDFMValues;
    WebButton48.AfterLoadDFMValues;
    WebButton49.AfterLoadDFMValues;
    WebPageControl5Sheet3.AfterLoadDFMValues;
    WebLabel66.AfterLoadDFMValues;
    WebLabel67.AfterLoadDFMValues;
    WebLabel68.AfterLoadDFMValues;
    WebButton50.AfterLoadDFMValues;
    WebButton51.AfterLoadDFMValues;
    WebEdit24.AfterLoadDFMValues;
    WebButton52.AfterLoadDFMValues;
    WebPageControl4Sheet7.AfterLoadDFMValues;
    WebPanel21.AfterLoadDFMValues;
    WebLabel47.AfterLoadDFMValues;
    WebLabel48.AfterLoadDFMValues;
    WebPayPal1.AfterLoadDFMValues;
    WebButton26.AfterLoadDFMValues;
    WebButton24.AfterLoadDFMValues;
    WebPanel22.AfterLoadDFMValues;
    WebLabel50.AfterLoadDFMValues;
    WebDBGrid1.AfterLoadDFMValues;
    WebPageControl1Sheet7.AfterLoadDFMValues;
    WebImageSlider1.AfterLoadDFMValues;
    WebButton9.AfterLoadDFMValues;
    WebPageControl1Sheet8.AfterLoadDFMValues;
    WebLabel14.AfterLoadDFMValues;
    WebLabel15.AfterLoadDFMValues;
    WebLabel16.AfterLoadDFMValues;
    WebLabel17.AfterLoadDFMValues;
    WebButton13.AfterLoadDFMValues;
    WebButton14.AfterLoadDFMValues;
    WebButton15.AfterLoadDFMValues;
    WebPageControl1Sheet9.AfterLoadDFMValues;
    WebRichEdit2.AfterLoadDFMValues;
    WebPageControl1Sheet10.AfterLoadDFMValues;
    WebRichEdit3.AfterLoadDFMValues;
    WebPageControl1Sheet11.AfterLoadDFMValues;
    WebRichEdit4.AfterLoadDFMValues;
    WebPageControl1Sheet12.AfterLoadDFMValues;
    WebRichEdit5.AfterLoadDFMValues;
    WebPageControl1Sheet13.AfterLoadDFMValues;
    WebLabel9.AfterLoadDFMValues;
    WebLabel13.AfterLoadDFMValues;
    WebEdit3.AfterLoadDFMValues;
    WebEdit4.AfterLoadDFMValues;
    WebButton12.AfterLoadDFMValues;
    WebButton16.AfterLoadDFMValues;
    WebCheckBox4.AfterLoadDFMValues;
    WebPageControl1Sheet14.AfterLoadDFMValues;
    WebPageControl3.AfterLoadDFMValues;
    WebPageControl3Sheet1.AfterLoadDFMValues;
    WebScrollBox4.AfterLoadDFMValues;
    WebImageControl1.AfterLoadDFMValues;
    WebLabel28.AfterLoadDFMValues;
    WebPageControl3Sheet2.AfterLoadDFMValues;
    WebScrollBox3.AfterLoadDFMValues;
    WebImageControl2.AfterLoadDFMValues;
    WebLabel27.AfterLoadDFMValues;
    WebPageControl3Sheet3.AfterLoadDFMValues;
    WebScrollBox2.AfterLoadDFMValues;
    WebImageControl5.AfterLoadDFMValues;
    WebLabel26.AfterLoadDFMValues;
    WebPageControl3Sheet4.AfterLoadDFMValues;
    WebScrollBox1.AfterLoadDFMValues;
    WebImageControl4.AfterLoadDFMValues;
    WebLabel23.AfterLoadDFMValues;
    WebPanel58.AfterLoadDFMValues;
    WebLabel22.AfterLoadDFMValues;
    WebComboBox17.AfterLoadDFMValues;
    WebButton111.AfterLoadDFMValues;
    WebButton21.AfterLoadDFMValues;
    WebPageControl1Sheet15.AfterLoadDFMValues;
    WebButton43.AfterLoadDFMValues;
    WebButton44.AfterLoadDFMValues;
    WebRichEdit6.AfterLoadDFMValues;
    WebPageControl1Sheet16.AfterLoadDFMValues;
    WebRichEdit7.AfterLoadDFMValues;
    WebPageControl1Sheet17.AfterLoadDFMValues;
    WebPageControl1Sheet18.AfterLoadDFMValues;
    WebImageZoomControl1.AfterLoadDFMValues;
    WebImageSlider2.AfterLoadDFMValues;
    WebPanel20.AfterLoadDFMValues;
    WebLabel35.AfterLoadDFMValues;
    WebLabel71.AfterLoadDFMValues;
    WebComboBox9.AfterLoadDFMValues;
    WebButton115.AfterLoadDFMValues;
    WebPageControl1Sheet19.AfterLoadDFMValues;
    WebPanel23.AfterLoadDFMValues;
    WebButton57.AfterLoadDFMValues;
    WebButton58.AfterLoadDFMValues;
    WebButton59.AfterLoadDFMValues;
    WebPanel24.AfterLoadDFMValues;
    WebLabel73.AfterLoadDFMValues;
    WebButton60.AfterLoadDFMValues;
    WebStringGrid1.AfterLoadDFMValues;
    WebPageControl1Sheet20.AfterLoadDFMValues;
    WebPanel25.AfterLoadDFMValues;
    WebLabel74.AfterLoadDFMValues;
    WebButton61.AfterLoadDFMValues;
    WebPanel26.AfterLoadDFMValues;
    WebButton62.AfterLoadDFMValues;
    WebButton63.AfterLoadDFMValues;
    WebButton64.AfterLoadDFMValues;
    WebStringGrid2.AfterLoadDFMValues;
    WebPageControl1Sheet21.AfterLoadDFMValues;
    WebPanel27.AfterLoadDFMValues;
    WebLabel75.AfterLoadDFMValues;
    WebButton65.AfterLoadDFMValues;
    WebPanel28.AfterLoadDFMValues;
    WebButton66.AfterLoadDFMValues;
    WebButton67.AfterLoadDFMValues;
    WebButton68.AfterLoadDFMValues;
    WebStringGrid3.AfterLoadDFMValues;
    WebPageControl1Sheet22.AfterLoadDFMValues;
    WebLabel76.AfterLoadDFMValues;
    WebEditAutoComplete1.AfterLoadDFMValues;
    WebPanel29.AfterLoadDFMValues;
    WebButton71.AfterLoadDFMValues;
    WebButton72.AfterLoadDFMValues;
    WebButton73.AfterLoadDFMValues;
    WebPageControl1Sheet23.AfterLoadDFMValues;
    WebLabel87.AfterLoadDFMValues;
    WebEdit26.AfterLoadDFMValues;
    WebButton79.AfterLoadDFMValues;
    WebButton80.AfterLoadDFMValues;
    WebPageControl1Sheet24.AfterLoadDFMValues;
    WebPanel49.AfterLoadDFMValues;
    WebLabel10.AfterLoadDFMValues;
    WebLabel97.AfterLoadDFMValues;
    WebComboBox12.AfterLoadDFMValues;
    WebComboBox13.AfterLoadDFMValues;
    WebButton109.AfterLoadDFMValues;
    WebPanel50.AfterLoadDFMValues;
    WebButton107.AfterLoadDFMValues;
    WebStringGrid5.AfterLoadDFMValues;
    WebPageControl1Sheet25.AfterLoadDFMValues;
    WebLabel100.AfterLoadDFMValues;
    WebLabel101.AfterLoadDFMValues;
    WebLabel102.AfterLoadDFMValues;
    WebLabel103.AfterLoadDFMValues;
    WebEdit33.AfterLoadDFMValues;
    WebEdit34.AfterLoadDFMValues;
    WebEdit35.AfterLoadDFMValues;
    WebButton116.AfterLoadDFMValues;
    WebButton117.AfterLoadDFMValues;
    WebPageControl1Sheet26.AfterLoadDFMValues;
    WebLabel104.AfterLoadDFMValues;
    WebLabel105.AfterLoadDFMValues;
    WebLabel106.AfterLoadDFMValues;
    WebButton118.AfterLoadDFMValues;
    WebButton119.AfterLoadDFMValues;
    WebEdit36.AfterLoadDFMValues;
    WebEdit37.AfterLoadDFMValues;
    WebPageControl1Sheet27.AfterLoadDFMValues;
    WebLabel107.AfterLoadDFMValues;
    WebLabel108.AfterLoadDFMValues;
    WebLabel109.AfterLoadDFMValues;
    WebLabel110.AfterLoadDFMValues;
    WebButton120.AfterLoadDFMValues;
    WebButton121.AfterLoadDFMValues;
    WebEdit38.AfterLoadDFMValues;
    WebEdit39.AfterLoadDFMValues;
    WebEdit40.AfterLoadDFMValues;
    WebButton122.AfterLoadDFMValues;
    WebButton124.AfterLoadDFMValues;
    WebButton125.AfterLoadDFMValues;
    WebPageControl1Sheet28.AfterLoadDFMValues;
    WebPanel54.AfterLoadDFMValues;
    WebLabel127.AfterLoadDFMValues;
    WebPanel55.AfterLoadDFMValues;
    WebPanel56.AfterLoadDFMValues;
    WebButton128.AfterLoadDFMValues;
    WebButton133.AfterLoadDFMValues;
    WebDBResponsiveGrid4.AfterLoadDFMValues;
    WebPageControl1Sheet29.AfterLoadDFMValues;
    WebLabel129.AfterLoadDFMValues;
    WebLabel130.AfterLoadDFMValues;
    WebButton129.AfterLoadDFMValues;
    WebButton130.AfterLoadDFMValues;
    WebButton131.AfterLoadDFMValues;
    WebPageControl1Sheet30.AfterLoadDFMValues;
    WebLabel131.AfterLoadDFMValues;
    WebLabel132.AfterLoadDFMValues;
    WebLabel133.AfterLoadDFMValues;
    WebButton134.AfterLoadDFMValues;
    WebButton135.AfterLoadDFMValues;
    WebButton136.AfterLoadDFMValues;
    WebPageControl8Sheet2.AfterLoadDFMValues;
    WebPageControl9.AfterLoadDFMValues;
    WebPageControl9Sheet1.AfterLoadDFMValues;
    WebResponsiveGridPanel1.AfterLoadDFMValues;
    WebPanel34.AfterLoadDFMValues;
    WebLabel90.AfterLoadDFMValues;
    WebListBox2.AfterLoadDFMValues;
    WebPanel35.AfterLoadDFMValues;
    WebLabel85.AfterLoadDFMValues;
    WebLabel86.AfterLoadDFMValues;
    WebRadioGroup3.AfterLoadDFMValues;
    WebEdit25.AfterLoadDFMValues;
    WebButton55.AfterLoadDFMValues;
    WebButton78.AfterLoadDFMValues;
    WebCheckBox6.AfterLoadDFMValues;
    WebPageControl9Sheet2.AfterLoadDFMValues;
    WebRadioGroup2.AfterLoadDFMValues;
    WebPageControl9Sheet3.AfterLoadDFMValues;
    WebLabel69.AfterLoadDFMValues;
    WebLabel18.AfterLoadDFMValues;
    WebLabel89.AfterLoadDFMValues;
    WebEdit27.AfterLoadDFMValues;
    WebPanel38.AfterLoadDFMValues;
    WebLabel88.AfterLoadDFMValues;
    WebLabel11.AfterLoadDFMValues;
    WebListBox3.AfterLoadDFMValues;
    WebColorPicker1.AfterLoadDFMValues;
    WebEdit28.AfterLoadDFMValues;
    WebEdit29.AfterLoadDFMValues;
    WebPanel39.AfterLoadDFMValues;
    WebPageControl9Sheet4.AfterLoadDFMValues;
    WebLabel91.AfterLoadDFMValues;
    WebLabel92.AfterLoadDFMValues;
    WebPanel40.AfterLoadDFMValues;
    WebPanel41.AfterLoadDFMValues;
    WebTreeView1.AfterLoadDFMValues;
    WebEdit30.AfterLoadDFMValues;
    WebEdit31.AfterLoadDFMValues;
    WebButton83.AfterLoadDFMValues;
    WebButton84.AfterLoadDFMValues;
    WebButton85.AfterLoadDFMValues;
    WebButton86.AfterLoadDFMValues;
    WebPanel37.AfterLoadDFMValues;
    WebButton81.AfterLoadDFMValues;
    WebButton82.AfterLoadDFMValues;
    WebPageControl8Sheet3.AfterLoadDFMValues;
    WebLabel19.AfterLoadDFMValues;
    WebProgressBar2.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebImageControl13.AfterLoadDFMValues;
    WebLabel70.AfterLoadDFMValues;
    WebLabel8.AfterLoadDFMValues;
    WebLabel123.AfterLoadDFMValues;
    WebLabel126.AfterLoadDFMValues;
    WebMessageDlg1.AfterLoadDFMValues;
    WebComboBox14.AfterLoadDFMValues;
    WebPanel9.AfterLoadDFMValues;
    WebLabel99.AfterLoadDFMValues;
    WebButton4.AfterLoadDFMValues;
    WebButton56.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton6.AfterLoadDFMValues;
    WebButton3.AfterLoadDFMValues;
    WebButton11.AfterLoadDFMValues;
    WebButton10.AfterLoadDFMValues;
    WebButton5.AfterLoadDFMValues;
    WebButton17.AfterLoadDFMValues;
    WebButton90.AfterLoadDFMValues;
    WebButton106.AfterLoadDFMValues;
    WebButton137.AfterLoadDFMValues;
    WebPanel36.AfterLoadDFMValues;
    MB_Panel.AfterLoadDFMValues;
    WebPanel44.AfterLoadDFMValues;
    WebPanel45.AfterLoadDFMValues;
    WebLabel94.AfterLoadDFMValues;
    WebLabel96.AfterLoadDFMValues;
    WebButton88.AfterLoadDFMValues;
    WebButton92.AfterLoadDFMValues;
    WebPanel46.AfterLoadDFMValues;
    WebLabel95.AfterLoadDFMValues;
    WebLabel124.AfterLoadDFMValues;
    WebEdit32.AfterLoadDFMValues;
    WebButton89.AfterLoadDFMValues;
    WebButton123.AfterLoadDFMValues;
    WebStringGrid4.AfterLoadDFMValues;
    WebMultimediaPlayer1.AfterLoadDFMValues;
    WebPanel53.AfterLoadDFMValues;
    WebLabel125.AfterLoadDFMValues;
    WebPanel57.AfterLoadDFMValues;
    WebButton126.AfterLoadDFMValues;
    WebButton127.AfterLoadDFMValues;
    WebButton132.AfterLoadDFMValues;
    WebTimer1.AfterLoadDFMValues;
    WebTimer2.AfterLoadDFMValues;
    WebHttpRequest1.AfterLoadDFMValues;
    WebClientDataSet1.AfterLoadDFMValues;
    WebTimer3.AfterLoadDFMValues;
    WebTimer4.AfterLoadDFMValues;
    WebTimer5.AfterLoadDFMValues;
    WebClientConnection1.AfterLoadDFMValues;
    WebTimer6.AfterLoadDFMValues;
    WebTimer7.AfterLoadDFMValues;
    WebSocketClient1.AfterLoadDFMValues;
    WebTimer8.AfterLoadDFMValues;
    StartTimer.AfterLoadDFMValues;
    WebTimer9.AfterLoadDFMValues;
    WebMediaCapture1.AfterLoadDFMValues;
    WebHttpRequest2.AfterLoadDFMValues;
    WebHttpRequest3.AfterLoadDFMValues;
    WebTimer10.AfterLoadDFMValues;
    WebTimer11.AfterLoadDFMValues;
    WebPopupMenu1.AfterLoadDFMValues;
    AllergieFilter1.AfterLoadDFMValues;
    ErnhrungsFilter1.AfterLoadDFMValues;
    LieferartenFilter1.AfterLoadDFMValues;
    N3.AfterLoadDFMValues;
    Prospekte1.AfterLoadDFMValues;
    heken1.AfterLoadDFMValues;
    N1.AfterLoadDFMValues;
    MeinKonto1.AfterLoadDFMValues;
    Anmelden1.AfterLoadDFMValues;
    N2.AfterLoadDFMValues;
    Chat1.AfterLoadDFMValues;
    WebTimer12.AfterLoadDFMValues;
  end;
end;

end.

