{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright © 2018 - 2021                                 }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

{$mode objfpc}
{$modeswitch externalclass}

unit WEBLib.WebSocketClient;

{$DEFINE NOPP}

interface

uses
  Classes, JS, web, WEBLib.Controls, SysUtils;

const
  DefaultPort = 8888;

type
  TSocketClientDataReceivedEvent = procedure(Sender: TObject; Origin: string; SocketData: TJSObjectRecord) of object;

  TDataReceivedProc = reference to procedure(ABytes: TBytes);

  TSocketClient = class(TComponent)
  private
    FPort: Integer;
    FHostName: string;
    FPathName: string;
    FWebSocket: TJSWebSocket;
    FSupport: TJSObject;
    FOnConnect: TNotifyEvent;
    FOnDisconnect: TNotifyEvent;
    FOnDataReceived: TSocketClientDataReceivedEvent;
  protected
    procedure CreateWebSockets;
    procedure DoMessage(AEvent: TJSMessageEvent);
    procedure DoOpen(AEvent: TJSEvent);
    procedure DoClose(AEvent: TJSEvent);
  public
    constructor Create(AOwner: TComponent); overload; override;
    constructor Create(AOwner: TComponent; APort: Integer); reintroduce; overload; virtual;
    constructor Create(AOwner: TComponent; AHostName: string; APort: Integer); reintroduce; overload; virtual;
    constructor Create(AOwner: TComponent; AHostName: string; APathName: string; APort: Integer); reintroduce; overload; virtual;
    function GetDefaultHostName: string; virtual;
    function GetDefaultPort: Integer; virtual;
    function GetDefaultPathName: string; virtual;
    function SupportsWebSockets: Boolean; virtual;
    function GetDefaultProtocol: string; virtual;
    function GetWebSocketPath: string; virtual;
    procedure Connect;
    procedure Send(const AMessage: string); overload;
    procedure Send(AMessage: TJSArrayBuffer); overload;
    procedure Disconnect;
    procedure GetDataAsBytes(AObject: TJSObject; AProc: TDataReceivedProc);
  published
    property Port: Integer read FPort write FPort default DefaultPort;
    property HostName: string read FHostName write FHostName;
    property PathName: string read FPathName write FPathName;
    property OnConnect: TNotifyEvent read FOnConnect write FOnConnect;
    property OnDisconnect: TNotifyEvent read FOnDisconnect write FOnDisconnect;
    property OnDataReceived: TSocketClientDataReceivedEvent read FOnDataReceived write FOnDataReceived;
  end;

  TWebSocketClient = class(TSocketClient);

implementation

{ TSocketClient }

constructor TSocketClient.Create(AOwner: TComponent);
begin
  Create(AOwner, GetDefaultHostName, GetDefaultPathName, GetDefaultPort);
end;

{$HINTS OFF}
procedure TSocketClient.Connect;
var
  w: string;
begin
  Disconnect;
  CreateWebSockets;
  w := GetWebSocketPath;
  if SupportsWebSockets then
  begin
    asm
      var me = this;
      me.FWebSocket = new window[me.FSupport](w);
      me.FWebSocket.onmessage = function(evt){
        me.DoMessage(evt);
      }

      if (me.FWebSocket.readyState==1) {
        me.DoOpen(new Event("open"));
      } else {
        me.FWebSocket.onopen = function(evt){
          me.DoOpen(evt);
        }
      }

      me.FWebSocket.onclose = function(evt){
        me.DoClose(evt);
      }
    end;
  end;
end;
{$HINTS ON}

constructor TSocketClient.Create(AOwner: TComponent; APort: Integer);
begin
  Create(AOwner, GetDefaultHostName, GetDefaultPathName, APort);
end;

constructor TSocketClient.Create(AOwner: TComponent; AHostName: string;
  APort: Integer);
begin
  Create(AOwner, AHostName, GetDefaultPathName, APort);
end;

constructor TSocketClient.Create(AOwner: TComponent; AHostName,
  APathName: string; APort: Integer);
begin
  inherited Create(AOwner);
  FPort := APort;
  FHostName := AHostName;
  FPathName := APathName;
  DoMessage(nil);
  DoOpen(nil);
  DoClose(nil);
end;

procedure TSocketClient.CreateWebSockets;
begin
  asm
    var me = this;
    me.FSupport = "MozWebSocket" in window ? 'MozWebSocket' : ("WebSocket" in window ? 'WebSocket' : null);
  end;
end;

procedure TSocketClient.Disconnect;
begin
  if Assigned(FWebSocket) and Assigned(FSupport) then
  begin
    FWebSocket.close();
    FWebSocket := nil;
    FSupport := nil;
  end;
end;

procedure TSocketClient.DoClose(AEvent: TJSEvent);
begin
  if Assigned(AEvent) and Assigned(OnDisconnect) then
    OnDisconnect(Self);
end;

procedure TSocketClient.DoMessage(AEvent: TJSMessageEvent);
var
  LObjRec: TJSObjectRecord;
  obj: TJSObject;
begin
  if Assigned(AEvent) and Assigned(OnDataReceived) then
  begin
    LObjRec.jsobject := TJSObject(AEvent.data);
    asm
      obj = AEvent.data;
    end;
    LObjRec.jsobject := obj;
    OnDataReceived(Self, AEvent.origin, LObjRec);
  end;
end;

procedure TSocketClient.DoOpen(AEvent: TJSEvent);
begin
  if Assigned(AEvent) and Assigned(OnConnect) then
    OnConnect(Self);
end;

procedure TSocketClient.GetDataAsBytes(AObject: TJSObject;
  AProc: TDataReceivedProc);
var
  reader: TJSFileReader;
begin
  reader := TJSFileReader.new;
  asm
    reader.addEventListener('loadend', function(e)
    {
      var buffer = new Uint8Array(e.target.result);  // arraybuffer object
      AProc(buffer);
    });
  end;
  reader.readAsArrayBuffer(TJSBlob(AObject));
end;

function TSocketClient.GetDefaultHostName: string;
begin
  Result := '';
  asm
    return window.location.hostname;
  end;
end;

function TSocketClient.GetDefaultPathName: string;
begin
  Result := '';
  asm
    return window.location.pathname;
  end;
end;

function TSocketClient.GetDefaultPort: Integer;
begin
  Result := DefaultPort;
  asm
    return window.location.port;
  end;
end;

function TSocketClient.GetDefaultProtocol: string;
begin
  Result := 'http:';
  asm
    return window.location.protocol;
  end;
end;

function TSocketClient.GetWebSocketPath: string;
var
  p, w: string;
begin
  p := GetDefaultProtocol;
  if p = 'https:' then
    w := 'wss:'
  else
    w := 'ws:';

  w := w + '//' + HostName + ':' + IntToStr(Port);
  w := w + PathName;

  Result := w;
end;

procedure TSocketClient.Send(const AMessage: string);
begin
  FWebSocket.send(AMessage);
end;

procedure TSocketClient.Send(AMessage: TJSArrayBuffer);
begin
  FWebSocket.send(AMessage);
end;


function TSocketClient.SupportsWebSockets: Boolean;
begin
  Result := Assigned(FSupport);
end;

end.
