unit UGlobvar;

interface

uses
  SysUtils, Classes,  WEBLib.StdCtrls, WEBLib.Controls,JS, Web, WEBLib.Graphics, 
WEBLib.Forms, WEBLib.Dialogs;

type

  PPushControl_Stack_Rec = ^TPushControl_Stack_Rec;
  TPushControl_Stack_Rec = record
                             f : TForm;
                             TS : TStringList;
                           end;



  TGlobvar = class(TForm)
  private
    { Private-Deklarationen }
  public
    { Public-Deklarationen }
  protected procedure LoadDFMValues; override; end;



const
  GA_Unknown = 0;
  GA_Handy = 1;
  GA_Tablet = 2;
  GA_DeskTop = 3;
  Admin_Kennwort = '123';


  cStorageTokenName = 'KWSpeiseplan-token';
  cStorageEmailAdresse = 'KWSpeiseplan_Email';
  cStoragePW = 'KWSpeiseplan_PW';


      maxAllergene = 14;

      Action_New = 1;
      Action_Edit = 2;
      Action_Delete = 3;
      Action_First = 4;
      Action_Last = 5;
      Action_Next = 6;
      Action_Prior = 7;
      Action_PgUp = 8;
      Action_PgDn = 9;
      Action_Scan = 10;
      Action_Home = 11;
      Action_Copy = 12;
      Action_Close = 13;
      Action_Cancel = 14;
      Action_Save = 15;
      Action_Print = 16;
      Action_Filter = 17;
      Action_Help = 18;
      Action_Tools = 19;
      Action_Menu = 20;
      Action_Refresh = 21;

      Action_Detail_Plus = 22;
      Action_Detail_Minus = 23;
      Action_Detail_Search = 24;
      Action_Detail_OK = 25;
      Action_Detail_Cancel = 26;
      Action_Search = 27;
      Action_SelectAll = 28;
      Action_UnSelectAll = 29;
      Action_DeleteAll = 30;
      Action_Detail_Menu = 31;
      Action_Einfuegen_Vor = 32;
      Action_Einfuegen_Nach = 33;
      Action_Anhaengen = 34;
      Action_Ersetzen = 35;
      Action_Kein_Bild = 36;
      Action_Ganz_Hoch = 37;
      Action_Hoch = 38;
      Action_Runter = 39;
      Action_Ganz_Runter = 40;
      Action_Verlauf = 41;
      Action_Add_Detail_Plus = 42;
      Action_Add_Detail_Minus = 43;
      Action_Add_Detail_Edit = 44;
      Action_Kalender = 45;
      Action_JahrMinus = 46;
      Action_JahrPlus = 47;
      Action_MonatPlus = 48;
      Action_MonatMinus = 49;
      Action_WocheMinus = 50;
      Action_WochePlus = 51;
      Action_KalenderEdit = 52;
      Action_KalenderLoeschen = 53;
      Action_Publish = 54;

      OS_Unknown = -1;
      OS_IOS = 1;
      OS_OSX = 2;
      OS_Android = 3;
      OS_Windows = 4;

      Unknown_Device = -1;
      IPhone_4 = 1;
      IPhone_5 = 2;
      IPhone_7_8 = 3;
      IPhone_7_8_Plus = 4;
      IPhone_X = 5;
      IPhone_X_max = 6;
      IPhone_X_R = 7;

      Android_320_512 = 101;
      Android_undef = 199;

      Windows_1600_900 = 201;
      Windows_1280_800 = 202;
      Windows_undef = 299;


var
  HauptFenster : TForm;
  MyTokenParam : String;
  Globvar: TGlobvar;
  TextToSpeechAPI_Url,
  SpeechAPI_Url,
  My_Cookie,
  LogoURL,
  AktPrgName,
  AktFirmaName,
  Akt_JWToken,
  AktKDName : String;
  Admin_Angemeldet : boolean;

  Akt_Geraete_Art,
  Akt_Item_Art,
  Akt_Theme,
  Akt_ArtikelWahl,
  Laenge_ID_No,
  AktFirmaNo,
  AktKDID,

  AktTeilnehmerID,
  AktEinrichtungID : integer;
  AktTeilnehmer : integer;
  AktTeilnehmerName : String;

  AktEinrichtung : integer;
  AktEinrichtungName : String;
  Akt_Suchbegriff,
  UK_EMail : String;
  UnterKonto_ID,
  VBKDGruppe,
  VBPreisListe : integer;

  Akt_Device_Typ : integer;
  Akt_OS : integer;

  TeilnehmerMenuAuswahl,
  Auswahl_TID,
  Auswahl_TNo : integer;
  Auswahl_TName : String;
  Password_Anzeigen,
  Mit_On_Click,
  UnterKonto_Aktiv,
  Email_Geaendert : boolean;

  Caption_List1,
  Caption_List2 : TStringList;



  AllergenBez : array[1 .. maxAllergene] of String;
  AllergenID : array[1 .. maxAllergene] of integer;
  SFA : array[1 .. 10] of String;
  Bewertung1 : array[1 .. 6] of String;
  Bewertung2 : array[1 .. 6] of String;
  Bewertung3 : array[1 .. 6] of String;



  NeuKD_Anrede,
  NeuKD_Titel,
  NeuKD_Vname,
  NeuKD_Name,
  NeuKD_PLZ,
  NeuKD_Strasse,
  NeuKD_Ort,
  NeuKD_Kennwort,
  NeuKD_Kennwort2,
  NeuKD_email,
  NeuKD_email2,
  Chat_Url,
  API_URL,
  API2_URL,
  AUTH_URL : String;
  Akt_SFrage : String;
  Device_Ist_Handy : boolean;
  Caption_Font_Size,
  Caption_Height,
  Edit_Font_Size,
  Edit_Height,
  Combo_Font_Size,
  Combo_Height     : integer;
  Dist_Cap_Edit : integer;
  Dist_Edit_Cap : integer;
  Bottom_Panel_Height,
  BP_Btn_Height,
  BP_Btn_Font_Size,

  Top_Panel1_Height,
  Top_Panel2_Height,
  Top_Panel3_Height,
  Top_Panel4_Height,
  Top_Panel5_Height,
  Firma_Font_Size,
  Kunden_Font_Size,
  Top_Panel_Zusatz_Font_Size : integer;
  Akt_User_ID,
  Akt_WK_ID,
  WK_Artikel,

  Hinweis_Font_Size : integer;
  Grid_Font_Size : integer;
  Max_Menu_Height : integer;

  Mit_Hilfe_Button,
  Mit_AGB_etc,
  Email_OK : boolean;
  PW_Request_Cnt : integer;


  PushControl_Liste : TList;

  Shop_ID_Token : String;
  Bilder_Url  : String;
  Start_Timer_Aktiv,
  Token_Ausgewertet,
  Token_Gelesen : boolean;
  Wort_Liste : String;
  Query_List : TList;


procedure Init_Globvar;


function Meldungs_Fenster(msgtxt : String;Art : TMsgDlgType) : TModalResult;


procedure Parse_Grid_Field(var AString,FName,DLabel : String;var DWidth : integer);


procedure Parse_Edit_Field(var AString,FName,DLabel : String;var DWidth : integer;var DT,RO,PF : String);

function Get_Help_Context(AName : String) : integer;

function UpcaseStr(st : String) : String;

function Equal(r1,r2 : real) : boolean;
function Gerundete_Realzahl(zahl : real;Komma : Integer): Real;


function Prozent_Von(Basis,Wert : real) : real;

function Str_to_Real(st : String; var r : real;Min,Max : real) : boolean;

function TrimAll(st : String) : String;

function RealStr(r:double;l,k : byte;Tp : boolean) : String;

function Aufbereitete_RealZahl(Zahl : double;NachKomma : integer) : String;

function GetRecordImage: string;

function GetStopImage: string;



implementation

{$R *.dfm}




function Aufbereitete_RealZahl(Zahl : double;NachKomma : integer) : String;
var hst:String;
    i,j: integer;
begin
  if abs(Zahl)<0.0001 then Zahl:=0.0;
  Str(Zahl:15:Nachkomma,hst);
  while hst[1]=' ' do System.delete(hst,1,1);
  if NachKomma <> 0 then begin
    i:=Pos('.',hst);
    hst[i]:=',';
    i:=i-1;
  end
  else i := length(hst);
  j:=0;
  while (i>0) do begin
       i:=i-1;
       j:=j+1;
       if (j=3) and (i>0) then begin
         System.Insert('.',hst,i+1);
         j:=0;
       end;
  end;
  if (hst<>'') and (hst[1]='.') then delete(hst,1,1)
  else if (hst<>'') and (Copy(hst,1,2)='-.') then delete(hst,2,1);
  Aufbereitete_RealZahl:=hst;
end;


function UpcaseStr(st : String) : String;
var i : integer;
    hst : String;
begin
  hst := St;
  if st = '' then UpcaseStr := ''
  else begin
    for i := 1 to length(hst) do begin
      case hst[i] of
        'ü' : hst[i] := 'Ü';
        'ö' : hst[i] := 'Ö';
        'ä' : hst[i] := 'Ä';
        else hst[i] := UpCase(hst[i]);
      end;
    end;
    UpCaseStr := hst;
  end;
end;

function Equal(r1,r2 : real) : boolean;
begin
  if abs(r1 - r2) < 0.0001 then equal := true
  else equal := false;
end;

function Gerundete_Realzahl(zahl : real;Komma : Integer): Real;
var hst : String;
    z : double;
    i,j : integer;
    Nicht_Kaufmaennisch_Runden : boolean;
begin
{  if Komma >= 6 then z := Zahl
  else begin
    Str(Zahl:30:Komma,hst);
    while hst[1] = ' ' do delete(hst,1,1);
    val(hst,z,i);
  end;
  Gerundete_Realzahl := z;
}
  Nicht_Kaufmaennisch_Runden := false;


  Str(Zahl:30:Komma + 4,hst);
  while hst[1] = ' ' do delete(hst,1,1);
//  i := length(hst);
  i := pos('.',hst);
  j := 0;
  while j < Komma do begin
    j := j + 1;
    i := i + 1;
  end;
  i := i + 1;
  Nicht_Kaufmaennisch_Runden := false;


  if Nicht_Kaufmaennisch_Runden then begin
    if hst[i] <= '5' then begin
      delete(hst,i,255);
      val(hst,z,i);
    end
    else begin
      delete(hst,i,255);
      val(hst,z,i);
      if (Zahl >= 0.0) then begin
        case Komma of
          1 : z := z + 0.1;
          2 : z := z + 0.01;
          3 : z := z + 0.001;
          4 : z := z + 0.0001;
          5 : z := z + 0.00001;
          6 : z := z + 0.000001;
        end;
      end
      else begin
        case Komma of
          1 : z := z - 0.1;
          2 : z := z - 0.01;
          3 : z := z - 0.001;
          4 : z := z - 0.0001;
          5 : z := z - 0.00001;
          6 : z := z - 0.000001;
        end;
      end;
    end;
  end
  else begin
    if hst[i] < '5' then begin
      delete(hst,i,255);
      val(hst,z,i);
    end
    else begin
      delete(hst,i,255);
      val(hst,z,i);
      if (Zahl >= 0.0) then begin
        case Komma of
          1 : z := z + 0.1;
          2 : z := z + 0.01;
          3 : z := z + 0.001;
          4 : z := z + 0.0001;
          5 : z := z + 0.00001;
          6 : z := z + 0.000001;
        end;
      end
      else begin
        case Komma of
          1 : z := z - 0.1;
          2 : z := z - 0.01;
          3 : z := z - 0.001;
          4 : z := z - 0.0001;
          5 : z := z - 0.00001;
          6 : z := z - 0.000001;
        end;
      end;
    end;
  end;
  Gerundete_Realzahl := z;

end;


function Prozent_Von(Basis,Wert : real) : real;
begin
  if not equal(Basis,0.0) then Prozent_Von := Wert * 100 / Basis
  else Prozent_Von := 0.0;
end;


function Str_to_Real(st : String; var r : real;Min,Max : real) : boolean;
var hst : String;
    i : integer;
    r2 : double;
begin
  hst := st;
  TrimAll(hst);
  if hst = '' then begin
    r := 0.0;
    Str_to_Real := true;
    exit;
  end;
  repeat
    i := pos('.',hst);
    if i > 0 then system.Delete(hst,i,1);
  until i = 0;
  repeat
    i := pos(',',hst);
    if i > 0 then hst[i] := '.';
  until i = 0;

  i := 0;
  r2 := 0.0;
  val(hst,r2,i);
  if i = 0 then begin
    Str_to_real := true;
    r := r2;
  end
  else Str_to_Real := false;
end;

function TrimAll(st : String) : String;
var hst : String;
    i : integer;
begin
  hst := st;
  repeat
    i := pos(' ',hst);
    if i <> 0 then delete(hst,i,1);
  until i = 0;
  TrimAll := hst;
end;


function RealStr(r:double;l,k : byte;Tp : boolean) : String;
var hst : String;
    i : integer;
begin
  r := Gerundete_RealZahl(r,k);
  if TP then begin
    hst := Aufbereitete_RealZahl(r,k);
    while length(hst) < l do hst := ' ' + hst;
  end
  else begin
    Str(r:l:k,hst);
    i := pos('.',hst);
    if i > 0 then hst[i] := ',';
  end;
  RealStr := hst;
end;




function Meldungs_Fenster(msgtxt : String;Art : TMsgDlgType) : TModalResult;

 procedure DialogProc(AValue: TModalResult);
 begin
   Meldungs_Fenster := AValue;
 end;

begin
  case Art of
    mtWarning : MessageDlg(msgtxt, mtWarning, [], @DialogProc);
    mtError : MessageDlg(msgtxt, mtError, [], @DialogProc);
    mtInformation : MessageDlg(msgtxt, mtInformation, [], @DialogProc);
    mtConfirmation: MessageDlg(msgtxt, mtConfirmation, [mbYes, mbNo, mbCancel], @DialogProc);
    mtCustom : MessageDlg(msgtxt, mtCustom, [mbAbort, mbRetry, mbClose], @DialogProc);
  end;
end;




procedure Init_Globvar;
var hst : String;

begin
  Akt_Geraete_Art := GA_Unknown;
  Akt_Geraete_Art := GA_Desktop; // GA_Handy;
  Akt_User_ID := 0;
  Akt_Theme := 0;
  Akt_ArtikelWahl := 0;
  Admin_Angemeldet := false;
  Query_List := TList.Create;


  AktPrgName := '';
  AktFirmaName := '';
  Akt_JWToken := '';
  AktKDName := '';
  AktFirmaNo := 0;
  AktKDID := 0;
  AktTeilnehmerID := 0;
  AktEinrichtungID := 0;
  AktTeilnehmer := 0;
  AktTeilnehmerName := '';
  AktEinrichtung := 0;
  AktEinrichtungName := '';
  LogoURL := '';
  VBKDGruppe := 0;
  VBPreisListe := 0;
  Laenge_ID_No := 10;

  Akt_Device_Typ := Unknown_Device;
  Device_Ist_Handy := false;

  Auswahl_TID := 0;
  Auswahl_TNo := 0;
  Auswahl_TName := '';
  Wort_Liste := '';
  Akt_Item_Art  := 1;



  AllergenBez[1] := 'Glutenhaltiges Getreide';
  AllergenBez[2] := 'Krebstiere und daraus gewonnene Erzeugnisse';
  AllergenBez[3] := 'Eier und daraus gewonnene Erzeugnisse';
  AllergenBez[4] := 'Fische und daraus gewonnene Erzeugnisse';
  AllergenBez[5] := 'Erdnüsse und daraus gewonnene Erzeugnisse';
  AllergenBez[6] := 'Sojabohnen und daraus gewonnene Erzeugnisse';
  AllergenBez[7] := 'Milch und daraus gewonnene Erzeugnisse (einschließlich Laktose)';
  AllergenBez[8] := 'Schalenfrüchte';
  AllergenBez[9] := 'Sellerie und daraus gewonnene Erzeugnisse';
  AllergenBez[10] := 'Senf und daraus gewonnene Erzeugnisse';
  AllergenBez[11] := 'Sesamsamen und daraus gewonnene Erzeugnisse';
  AllergenBez[12] := 'Schwefeldioxid und Sulfite';
  AllergenBez[13] := 'Lupinen und daraus gewonnene Erzeugnisse';
  AllergenBez[14] := 'Weichtiere und daraus gewonnene Erzeugnisse';
  AllergenID[1] := 1;
  AllergenID[2] := 2;
  AllergenID[3] := 3;
  AllergenID[4] := 4;
  AllergenID[5] := 5;
  AllergenID[6] := 6;
  AllergenID[7] := 7;
  AllergenID[8] := 8;
  AllergenID[9] := 9;
  AllergenID[10] := 10;
  AllergenID[11] := 11;
  AllergenID[12] := 12;
  AllergenID[13] := 13;
  AllergenID[14] := 14;


  SFA[1] := 'Wie war der Mädchenname Ihrer Mutter?';
  SFA[2] := 'Wie hieß Ihr erstes Haustier?';
  SFA[3] := 'Wie hieß Ihr Lieblingslehrer in der 1. Klasse?';
  SFA[4] := 'Wo verbrachten Sie Ihren ersten Urlaub?';
  SFA[5] := 'Wem wären Sie am liebsten nie begegnet?';
  SFA[6] := '';
  SFA[7] := '';
  SFA[8] := '';
  SFA[9] := '';
  SFA[10] := '';

  Bewertung1[1] := 'Hervorragend';
  Bewertung1[2] := 'Gut';
  Bewertung1[3] := 'Mittelmäßig';
  Bewertung1[4] := 'Nicht gut';
  Bewertung1[5] := 'Schlecht';
  Bewertung1[6] := '';

  Bewertung2[1] := 'Zuviel';
  Bewertung2[2] := 'Ausreichend';
  Bewertung2[3] := 'Genau richtig';
  Bewertung2[4] := 'Zu wenig';
  Bewertung2[5] := '';
  Bewertung2[6] := '';

  Bewertung3[1] := 'Ansprechend';
  Bewertung3[2] := 'In Ordnung';
  Bewertung3[3] := 'Geht so';
  Bewertung3[4] := 'Nicht gut';
  Bewertung3[5] := '';
  Bewertung3[6] := '';




  NeuKD_Anrede := '';
  NeuKD_Titel := '';
  NeuKD_Vname := '';
  NeuKD_Name := '';
  NeuKD_PLZ := '';
  NeuKD_Strasse := '';
  NeuKD_Ort := '';
  NeuKD_Kennwort := '';
  NeuKD_Kennwort2 := '';
  NeuKD_email := '';
  NeuKD_email2 := '';

  API_URL := '';
  AUTH_URL := '';
  Chat_Url := '';
  Akt_SFrage := '';
  Email_Geaendert := false;
  UnterKonto_Aktiv := false;
  UnterKonto_ID := 0;
  UK_EMail := '';
  Akt_Suchbegriff := '';


  Caption_Font_Size := 10;
  Caption_Height := 16;
  Edit_Font_Size := 12;
  Edit_Height := 22;
  Combo_Font_Size := 12;
  Combo_Height     := 22;
  Dist_Cap_Edit := 3;
  Dist_Edit_Cap := 6;

  Bottom_Panel_Height := 120;
  BP_Btn_Height := 55;
  Top_Panel1_Height := 120;
  Top_Panel2_Height := 120;
  Top_Panel3_Height := 120;
  Top_Panel4_Height := 120;
  Top_Panel5_Height := 120;
  Firma_Font_Size := 14;
  Kunden_Font_Size := 13;
  Top_Panel_Zusatz_Font_Size := 12;
  BP_Btn_Font_Size := 12;
  Hinweis_Font_Size := 16;
  Grid_Font_Size := 16;
  Max_Menu_Height := 70;

  Email_OK := false;
  SpeechAPI_Url := '';
  TextToSpeechAPI_Url := '';

  Caption_List1 := TStringList.Create;
  Caption_List2 := TStringList.Create;

  Mit_On_Click := true;
  Mit_AGB_etc := true;
  PushControl_Liste := TList.Create;
  Mit_Hilfe_Button := false;
  Password_Anzeigen := false;
  PW_Request_Cnt := 0;
end;


procedure Parse_Grid_Field(var AString,FName,DLabel : String;var DWidth : integer);
var hst : String;
    i,j : integer;
begin
  AString := Trim(AString);
  i := pos(';',AString);
  FName := Copy(AString,1,i-1);
  system.Delete(AString,1,i);
  i := pos(';',AString);
  DLabel := Copy(AString,1,i-1);
  system.Delete(AString,1,i);
  i := pos(';',AString);
  hst := Copy(AString,1,i-1);
  system.Delete(AString,1,i);
  DWidth := StrToInt(hst);
end;

procedure Parse_Edit_Field(var AString,FName,DLabel : String;var DWidth : integer;var DT,RO,PF : String);
var hst : String;
    i,j : integer;
begin
  AString := Trim(AString);
  i := pos(';',AString);
  FName := Copy(AString,1,i-1);
  system.Delete(AString,1,i);
  i := pos(';',AString);
  DLabel := Copy(AString,1,i-1);
  system.Delete(AString,1,i);
  i := pos(';',AString);
  hst := Copy(AString,1,i-1);
  system.Delete(AString,1,i);
  DWidth := StrToInt(hst);

  i := pos(';',AString);
  DT := Copy(AString,1,i-1);
  system.Delete(AString,1,i);

  i := pos(';',AString);
  RO := Copy(AString,1,i-1);
  system.Delete(AString,1,i);

  i := pos(';',AString);
  PF := Copy(AString,1,i-1);
  system.Delete(AString,1,i);


end;

function Get_Help_Context(AName : String) : integer;
var hst : String;
    i : integer;
begin
  hst := Uppercase(AName);
{
4.11.2020  08:26               619 Index.html
05.08.2020  15:51               619 PMainMenu.html
12.10.2020  20:00             1.395 PSpeiseplan_Client.html
26.07.2020  13:20               195 UanzArtBilder.html
26.07.2020  13:20               195 UAnzArtikel.html
26.07.2020  13:20               195 UanzPDF.html
25.07.2020  09:22               195 UAnzSpeiseplan.html
26.07.2020  18:17               195 uanzspeiseplantag.html
24.09.2020  08:38               195 UAnzSpeiseplanTag2.html
26.07.2020  13:59               195 UChangeEmail.html
26.07.2020  16:52               195 UConfirmEmail.html
26.07.2020  13:20               195 UEditBank.html
05.08.2020  20:24               195 UEditEinrichtung.html
21.07.2020  15:38               195 ueditkonto.html
25.07.2020  19:12               195 UEditKonto2.html
25.07.2020  19:22               195 UEditKonto3.html
25.07.2020  21:08               195 *UEditKonto4.html
26.07.2020  13:33               195 UEditPasswort.html
11.07.2020  13:15               195 ueditStrTouch.html
11.07.2020  13:15               195 ueditstrtouchpw.html
30.07.2020  16:36               195 ueditsubaccount.html
30.07.2020  16:36               195 UEditTeilnehmer.html
28.09.2020  18:50               195 UEditTelefon.html
05.08.2020  18:28               195 uessenausgabe.html
25.07.2020  10:02               195 UGetAuswahl.html
28.09.2020  18:50               195 ugetbewertung.html
25.07.2020  09:41               195 UGlobvar.html
25.07.2020  09:00               195 *UHMenu.html
05.08.2020  17:38               195 *UHMenu2.html
26.07.2020  13:04               195 *UKontoMenu.html
11.07.2020  13:15               195 ULogin.html
04.11.2020  08:26               195 UMainform.html
05.08.2020  15:51               195 UMain_Form.html
21.07.2020  15:38               195 UNeuesKonto.html
03.10.2020  11:38               195 UNeuesKonto2.html
25.07.2020  13:19               195 UResetPassword.html
11.07.2020  13:15               195 ustartseite.html
06.08.2020  16:34               195 UStd_Edit.html
07.08.2020  19:26               195 UStd_EditForm.html
08.08.2020  07:07               195 USTD_Suche.html
26.07.2020  18:17               195 USucheTeilnehmer.html
26.07.2020  18:17               195 UTeilnehmer.html
26.07.2020  13:04               195 *uteilnmenu.html
26.07.2020  13:20               195 uUebergabe_Chip.html
04.08.2020  14:18               195 UVWAllergien.html
05.08.2020  16:54               195 UVWEinrichtungen.html
05.08.2020  16:34               195 UVWVarianten.html
05.08.2020  18:28               195 UVW_Einrichtungen.html
25.07.2020  09:00               195 uWaitConnect.html
25.07.2020  09:00               195 uwaitconnect2.html
25.07.2020  09:00               195 uwaitconnect3.html
05.08.2020  09:23               195 UWochenplan.html
05.08.2020  18:28               195 uzeigeessen.html
26.07.2020  18:17               195 UZeigeSummen.html
}
  if hst = 'HMENU' then i := 1
  else if hst = 'HMENU2' then i := 2
  else if hst = 'KONTOMENU' then i := 3
  else if hst = 'TEILNMENU' then i := 4
  else if hst = 'EDITKONTO4' then i := 5

  ;
  Get_Help_Context := i;
end;


function GetRecordImage: string;
begin
  Result := 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAL0AAAC9CAYAAADm13wwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjEuNv1OCegAABG+SURBVHhe7Z0vkBXXEsYRESkqIiKCoiIQiIiIJxGIJxAIBOIJBAKBRCAQERFUrUAgEBEIB' +
  'AIRiUBEIBAIBIJ6hViBQCAQvCoEAoFIvm/p+3Y423fv3Lmn+/SZ6a/qVwm7c+f06e47e+b8PZFqo/+ePnlb/jeV6lNI4kfgJfgI/q4I78f7PpKiUik/IfHugifgDdAS1BvaQXvuiImp1G5iMoEX4AvQki4atJP25pcgNU5IltvgGfgMtKTqDdaD9cn3h9ShmBAgSnPFGtZzT6qeWpoQ/OeDZFgiL8QVqTkLgX4Memmfe0F/PBEXpeYiBPX9IMjJ' +
  'et6Ly1K9ikEsgpqMg37Ll99exGCB2gNES4V+zOSPKgZHgqQFL9mNTP5okqBowUrq8lFcnmolBIFzUrTgJLa8lBCkvASn3wfZ9dgW+v++hCRlKTj6w8DxSXs+SGhStQXnciKV5vQkBjnCW1Nw6KfCwUlMPknIUlMFJ7Ltrjk3iU229acIjntVODLpi1cSytQYwWHZnJkH2dzZJDjpXuG0ZB5kc0cTHLP0+e1z57mEOkXBIdl+Xwb7EvJlC45YylK' +
  '95CvLnrNPBxQOmRtvwWvAxdnkocCtRDgrlP9d/Wx1Da/n57T7zYVlvuCy4oUjeoXTIp4CjincApfBL+A7qeok8fNyn0uA9+X9Wc5cpmEsK/FR4Z4ni/HLyg2WboJfpUquQrn8MtwAXPPb88Pji1Rp3lIq3gN80WZz5BzY6QleW7RH7KJ9XXYISFXmKVSwpyf8O8A2d5On+VTRXsCd2Wi/Vq+IzPOJj4r18GeYNnIj1gsg1BN9iqQefEnuwfefxe' +
  'x5CBWK3ktzsAYU/Cgmz0qsF/gdRI/DPF5uUZHI/fBMAr6Q/iDmzlqo5/dS38jJ33c/PioQ9cWKX8Tr4HsxdVFivcE18YPmn9b0OXILwyPOpeEuvmzGLDLZS9EPYE/8ovmrJX2txILBEWdLcmDnrJiYGgh+Yb8//aP5rSUPxcT4UoxvCduvV8S01DGCn66KvzQ/NkFMiy0YGql7jN11s+yRsRL9Bdhtq/mzBbF7dGBglBdXfvGuiVmpCaL/xI+af' +
  '715LWbFEgyLsoibsxS7GkWNKvpR/Kn52Zt47XvFyBbwi5c9MxVFf4pfNX+7IibFEAxq/WeQc3quijkpA9G/4mfN/17EaN/DkNY7j/EL9x8xJ2Uo+Jlz+1s/4Nr33ytGecIAnBdTUg6iv0HTrdHFlDaCAS1X8bA/+V9iSspR8DsHs1r257fZJx8Ft3y5ocN/EVNSDQT//wz2JR4teCCm+AmFtnqpyYQPIsThlMRDi5M5YoaPUGCrE0DYhs8mTSAh' +
  'HmzqtGrm+g1aKYV7kC+tQcW4SHy0uJkiJtgKBbV4c2dTKrslAwvxYXdmiyavbd89CuBcdK1ga3LgqQMxTkXcvLgnJtQXbt7iKZ873nYkxquInwc2i8px4xZPeU526m4uzf9On/wBnAc3wB/gCXgF3oIv4O8B/Dd/zt/zOl7Pz/Hz3a3dZbxAi9m2d8SEesJNvZ/yfDHqYrYkkvM7cAHcAUzeYVLvCu/H+/L+XWxLgridlfhpcbWi/oCVUog14ef' +
  'DSyI+AB+AlrC1YTks74KYEFaI35Uinh7UO8ofN/MegAi7NhIJ9yNg8+MN0BLTC5ZPO8KuDEMcvdv39bYPUW5uCb9g4QKJ5GI7/TbweqqPhfbQrnDtf8SR7XvXbcel6N2EG3k/5UMt4kYysb1+HbwHWtJFgfbRzmgbzHLrci3OVuz+tFduaslTKTaEkEDnwGugJVlUaO85qUIIIa7c2lyLtwlS7DThBtz/XL2xAdxwKMS+NEia78FdSaJeof0hun' +
  'sR1zMSXy3uFjyWorcXPuw5rFzvzXsHIVHOgtrdjq1gPUI8SBBfbiCrxd2C6Vt/KzezgnsqNn8qIUEugY+SMHPhE7gkVWwmxlfirMW/OlLsdsIHPfeivC7FNhMSg91/5WjpXGC9bklVm4lxLuJuyfbn1io3sYK9Q02f8kiI3tvvY7krVW4ixlnireVBdaTYccIHPOfZ3JRimwiJcK9IjLljNyNxhBjvIv6WjH9PxMVebS/O52k2qIIEWMoTvqRZ4' +
  'jPeEnctH2rzRordLOXDVjTrsUHg2YbXEmIpNPsLy7gXeWCGFHm8cKGXQZyF12S6AQLOXpq5vrRuQ5NeHcYdePXbb36w4iIe1a59uDaPpEhXIdDsh59bt+RU6IdWB0B7bQf+TIpcL1zk9Q10nx6LAHOkdS4DT7WgP9x7zxj/Ih+s2LyqSvmQBTzY131iFIK71BfXTbi/2DL+kgdaflRFitSFC3jStPrByrj3FyOwnDymBTz5ivv2KsyDIi+sWL+U' +
  'EL/02oHYtR2JgHJ6cG+zJb3ZB65/fZkHRV5YsX6nY/zSY4LZKynOTQgm55lrgU6+5Ya4zE3MhyI/LFg/AU252ALXvnkEkiueoi8AiQJXYbl2IzMfivwwQYr7VviFV/vKdYEDgsildFqAE536W2kcI+ZDkR9WHK0XfuixuoUDUm7tRgSQi7ijrWmNDvvu3Z72zAfJCy1favJEijwUfugx3+ZowYZC8JY+1WAqrtOQmRdFnlhwdB6OcpEFrvM9ELz' +
  'W23T0yltxoYuYF0WemCDFHUq7yAC3rkoEjhsxaQFNxuE2Ys68KPLEBCnuUNpFlfkgRbkIQeNOYFowk3G4brjF/CjypTpS1KG0iyrjtr0HAsbBqHyB3Q2urXWbk8P8KPKlOlLUV+EHHjPe3LbcRrCyaVMHt6nHzI8iXyw4nNmLf3icIeXWI4BgcZdfLYjJdrhNRGN+FPliwUsp7qBAj+Vbl6U4cyFYOX24Dm5TRpgfRb5YcLidt/JLC1yOwUSgOO' +
  '1AC2AyDZc1zMyPIl9MkOLckt5lJBZB4gkeWvCSabh0XTI/inwxQYpzSXq3wQ4EKUdh6+I285J5UuRNdaQol6R3O+QWQeKZTVrwkmn8Ia41F/OkyJvqSFEuSb95cW4lIUg8rEwLXjKNv8S15mKeFHlTHSlqdkmfPTd18ezBmVXSuw1pI0g8nlILXjINt/cx5kmRN9WRomaX9LmJU12m7/W+pZgnRd5UR4pySXq33Q+UoCU7Iq41F/OkyJvqrAryW' +
  'KPoti5WC1qyG+JaczFPiryx4GsuKr+oTT7pO0Zcay7mSZE31ZGiXJI+2/QdI641F/OkyJvqSFGzS/rsvalL9t5MJPvp+8VtNJ15UuRNdaSo2SX9X0XQkt3IEdmJ5NybfnFb8cY8KfKmOlKUS9LnLMt+cdu2hXlS5E11pCiXpCde8+lzfWxdcj79DuTKqT5x2eKP+VHkiwlS3EGBHmtk3VbWI1DZg1OHfXGpuZgfRb5Y8M0a2bnthrC0A5Gt8FxA' +
  '4r4bwtz2veGRmVoQk+2Y7743lHJBbTx3OOMpgtyhSwtkMo5573BGaRdVxnsvy4eDACbb43rOL/OjyJfqSFGH0i4ywKUHh0LQsutyN9w252JeFHlighR3KO0iA1wP8kLgcvLZNN4BtxNjmBdFnpggxR0KP/Q4ieSxFOciBO63QSCT8fwmLnQR86LIEwvUk0jmeuYUz1DSApvo8AX2lLjQXMwHyQstX2qinjk119MFcwfj7XA9Mp/5UOSHFfqpicq' +
  'FFnifI5tP+/HQTz+J61zEfCjywwQp7qjwy7meGH5zENhkPa5teYr5UOSHBceeGP6iuNgKtwPXKASTx/HkSYPHQ/+4DUZRzIMiL6x4IUUeFX55p7jYCtdTqSkE9N+DACdHuSiuchPzoMgLK47PN+UDFryT4lyFwN4vAp185YG4yFXMgyIvTJDi1gsXfS4/ZITbOaUrIbick/N6EOzk9Ml94HLayFCMf5EPVnyWItcLF5kvzhVczyldCQH+FeRktK' +
  '98Bq7vVysx/kU+WLF5UwJc5NKFBDgg4bIqpxQCfXUQ+CVzVVziKsZd4q/lRW3GdZErH7TidynSXQj47SIBlsaeuMJdjHuRB2ZIkZuFiz3m4ZD3wLWbbCgEfqnbhbgt6CnFeEvctXyozdH5NuuEi/eKD1vitsVEKQSf/fcPBsmwBP4EbvOfSjHeRfwt2e6vmXIDK5o+7SkkwVKe+OyybZnwnk/58U2blfAhr9FZck2KbSYkw94gOeZIszb8SoxzE' +
  'XdL1o/CHiflRlbwHaLp055CYrBXh914WtL0CuvTpJdmKMZX4qzFvzpS7PbChz0moK1wnX25TkgQ9uNzwEZLoN5gPZr0w5dCfN16bMD0c7LwYY+FJSs4EnxWim4qJAp3Sev9BZf2u4+0akJcz0h8tbhbcHTByDZSbmiJ2zYhY4SkuQh6m51Je90njx0nxNXz4Tm9abMSbuL2ti1ckaJDCAnE+Tpcaxt9IQrto53N342GQjwvF/G15r0UvZuUG1vC' +
  'L1mT6QnHCcn0E+B2gdHm7dAe2uW64mmMEEe+vJpvvz1Eit5duJn3077JZLQxQnKdAnyicpsMLQm9YPm0w20R97ZCHD226htyuEHrrsLNvCahDWned3+ckGwczb0MHgGvpz/LYXkst9kg0xghfleKeHpQtwcQN/TYznsIZ+GF6G7bJCQg2/3cNJYju7W7O3k/3pf3D9VeXyfE7azET4urFfWe8ivhpi2e9jyDqItAD4Xk5A4M3FaQC9I59M/D37h' +
  '4Zd2Oa/w5f8/reD0/x8+He7fZJMYLeCz2LrHZugQ39n7ak2YzAlPbi/Eq4ufB5tVRU4Wbt3jak+bD6KnNYpyKuHlhu0EVCmjxtOd0CLcDAlLbC/G5KHHS4mfJJzHBVkrBHvDF6LyYkAokxIVb83m/uB4gJtgLhXmcUaXBvzJue9ynNovxAN7jOCvcDuU+EAps8aeM0ME/ixmphkIcTgG36cIlYoafUGiLt/QV+yDsSOQSRP9LHLT4eNBkgypW3P' +
  'ycoGPgEz+bOg1Ev4NmT3hQfyBqGykGecIvXb7cOgr+5ktrqzb8AWJKO8EIz7W0Guw1yO5MB8HP7JZs0kszYNra19qCIa0dwZfqHMAyFP0rftb874VPn/xYKQa2gC/X3c3ViSz6U/yq+dsVMSmOYJTXhpyb4GSnEGttexf9KP7U/OxNzDUWMIyzIjWDvWFzK9Syw95E/4kfNf96sy9mxRQMjOIoks2dLUV/id80f7bAbgZlTSmGt4RrNN2OfO9Z9' +
  'JP4S/NjE8S0+IKxUdr3Q7gNxRkxMTUQ/SL+0fzWkj/FxD4Eg58XFYgANxziTlvZ5IHoB/GH50ZMY4ndjl8nGl5UJAocQr8OFpn8rDfgZqotpxIcR5OD+KoJFWg6ZL0B2sb90kNsf2ct1JPJzvqGjomY27dQkUg9Ohqcp8+lkN0txB4j1guwGRM52UmsEdddxQoVFYwIbXwEeNxj6H1lxkjqwU6FHnw/fafhyGLFiopGhgf73gVd7L2zEu0FPInb' +
  '5WDiWoj585RW4Q7gUDybP5xWG+ovAO0Ru2hflCkDWyFVmbdQ0Z6e+CVsKrBPmy+ErQ4e5iKOG+Ax6KHpshap0jKECkfsG54CF7I8BRy6vwU4qsmk3OkvAj8v97kEeF/en+W0XK1Wk3m24TcJFe/6KTUCDulzEt4zgS+VhO8KbI7wv6ufra7h9aGmAhgwr16abQUHRO9GS+oyj374XQVHRB25TerS90hrbcEhrdfaJrb0OZfGWnAM27aaw5K+6Wu' +
  '2ZAvBSXN/wV0KfSwAiSI4LMrSw2Qa2ZyZIjgumzt9EvagvG4EJ2Zzpw+W3f9eW3Bo9u7EJsbOY3MUnNviJJRkPW03U12K4Ohs68egzXbZSxacnj08bfA9ASR1VAhCvuj6kC+qkYSA3ANzma4cDfrV9tjK1HQhOFwqly+7daAfM9l7EYLF+epzWXDhzcHuEOLKVI9CAHPO/jhyrvvchKByTWnP63MtoD+eiItScxYCHXGvTU9yBHWpQvDZ9o+6p2' +
  'NtWM89qXoq9f8vABdnz6Xrk/VgffKlNDVOSBZ2f3KiWy/vAbST9t6RKqRSuwnJxC07uNFTlOYQ7aA9meQpfyHxuPHrS1B7YIz3430fSVGpVJ9CEmc7u4lOnPgH1YNdZT+c/8wAAAAASUVORK5CYII=';
end;

function GetStopImage: string;
begin
  Result := 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAL0AAAC9CAYAAADm13wwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjEuNv1OCegAABA3SURBVHhe7Z0hsFxFFoYRiNQWAkltIRARiBUrEUjEiogIREREBBKBQCKoioiIiEAgIhARK' +
  'xARkRERERERqS1EBAIREcFWIRARCPj/l3+Yef3O9Myb6XO6+875q74KvHfndvf5z9zXt29333dSffS/f/7jW/1nKjWnkMQPwHPwG/izITwfz/tARaVScULi3QWPwM/AStBoWA/W546qmEodJyYTeAb+AFbSjQbryfrmlyC1n5As34In4A2wkmo22A62J+8fUmsxIcAo3RVv2M7banrq1ATzn24kwynyTKFILVkw+iGYpX8eBePxSCFKLUUw9fWG' +
  'ycl2XitkqVlFEwtTk/1g3PLmdxbRLND6AdGpwjhm8o8qmiOTLPOS48jkH00yxTIractvCnmql2AC56RY5iS+PJcFqSgh6N+DHHrsC+P/vSxJeQqB/nUj8El/fpU1qdZCcDmRygp6Mgb5hLelENDfiwAnY/K7LEsdKgSRfXcruMnYZF//ECFwL4pAJnPxQlam9hEClt2ZZZDdnV1CkO4VQUuWQXZ3LCEwpz6/fek8ldUpCgHJ/vtp8FKWn7YQiFN' +
  'Zqpe85bTn7DMARUCWxi/gJ8DF2eQHwa1EOCuU/65+tjqGx/Nz1vmWwmne4LLhRSBmhdMiHgM+U/gaXAcfg3fV1IPEz+s81wDPy/OznKVMwzitxEeDZ54sxi8rN1j6CvxLTQoVyuWX4UvANb8zXzz+UJOWLaPhM8AbbXZHPgFHXcFbi/VRvVi/KQcE1JRlCg2c6Qr/CrDP3eVqfqhYX8Cd2Vh/q10jsswrPho2w59h1pEbsX4GhrqiHyK1gzfJM8' +
  'T+jaq9DKFBo4/SnK0BBe+ryosS2wW+AaP7sIybWzRk5HF4JgFvSN9TdRcttPOK2jty8s89jo8GjHpjxS/iF+CKqnpSYrvBLcXBik9v5nxyi4qPOJeGu/iyG3OSyV6KcQC3FRcrXj2ZayUWKjzibEk+2LmqKqY2hLhw3J/xseLWkx9UxfFlVL4n7L/eUNVSFSFONxUvK45dUNXGFio60vAYh+sWOSLjJcYLcNjWimcPxh7RQQVHuXHlF++WqpU6Q' +
  'Iyf4mjFN5qfVK2xhIqNsoibsxSneoo6qhhHxdOKczTj9e+NSvaAX7wcmWkoxlNxteIdiqo0hlCh3n8GOafnpqqTchDjqzhb8Y9ijP49KtJ75zF+4T5XdVKOQpw5t7/3Ba7/+L1RqUhowKeqSipAjDfoujW6qtJHqEDPVTwcT/63qpIKFOLOh1k9x/P77JOPgnve3DDgH6sqqQ5C/D8EL+VHD+6rKnFCob1uajLhBxF8+EB+WD65o2rECAX2egMI' +
  '+/DZpRlI8INdnV7d3LiHVkbhEeRN66CiL/LH8s0VVcFXKKjHnTu7UjksObDgD4cze3R5fcfuUQDnolsFe5MPniYQfSp8i+KeqtBeOHmPq3zueDuR6FfhXwQ+i8px4h5XeU52yrk0E4l+gR6zbe+oCu2Ek0Zf5XljlLMlJxR8uyr/LF+9aP/AyijEm5wPP7Hg343CzwjavcofJ4t+ADHP2sjUVsHH6P59u+1DjJN7wi+Y6xK//6PfCW6CH8Ev4M8' +
  'Tgu1lu9l+1/sl+Mj+fei24yr6OOFE0Vd510XcMPpz8ApYCXFqMA6uzz/gJ7cut3z24virvXFSTx6r2OaCue+C72R2ch7GxW3vTvjKrc0tv11QsYcJJ+D+5+aJHeCGQ2770shYy/DkLd8pVM0FXz+Sv5bvHjxU0ZcXPhz5WLndnXchGMoujWV0ch63rg785Qaylu8eHL71t3EyL7inostNFYzkTWv24feDcXLxgf7KZ8v/5qjYywkfjNyL8gsV21' +
  'wwkaMUlsGJjds8J/pc+O7J5d9ba5zEC44OuQ2dwUQOz1nmJjY/KnTNRZ/lt5UHzVGx+wkfiJxn85WKdRFMPLVx+GP5RaFzEf0u/Pdk//tEHBzV9+J8HtcXIhimJjtQ6FxEv+W7lQ+t+VnF7pbxYS/cRmxWskxN6ih0bqLvRR64oSLrwoFRFeIsPPcdhS1TkzoKnZvoO4gat999YcVBfFW79eHWPFCRrrJMTeoodK6i/0U+ePFERW4XDor6Bn6mI' +
  'l1lmZrUUehcRf+LfPBi96oq40Me8MW+Ie9ptUxN6ih0rqL/ygMrP5qiIm3hAL5p2vxgY+6qSHdZpiZ1FDp3MQ+KvPBi+1JC/DJqB+KwZYCWqUkdhc5dzIMiL7zYvtMxfhkxweyFiguRZWpSR6ELEfOhyA8Ptk9AMw72wH1sflOWqUkdhS5EzIciP1xQceeFX0T1rz5RkSGyTE3qKHQhYj4U+eHFxX49fhixuoUPpEJGbVayTE3qKHQhYj4oL6x8' +
  'ackjFbkWfhgx3+Ziwc6yTE3qKHRhYl4UeeLBxXk4xkEeuM6otGSZmtRR6MLEvCjyxAUVt5Z1kAPhO5ZZpiZ1FLowMS+KPHFBxa1lHdSYX1VUqCxTkzoKXaiYH0W+NEdFrWUd1Bi37T1qskxN6ih0oWJ+FPnSHBX1VvhBxIy3LltuW6YmdRS6UDE/inzxYD2zF/8T8Q6pr1VcqCxTkzoKXaiYH0W+ePBcxZ0VGLF867qKC5VlalJHoQsV86PIFw/' +
  'W23kbv/Sgy2swLVOTOgpdqJgfRb64oOLCkj70SexKlqlJHYUuVMyPIl9cUHEhSe+6rURNlqlJHYUuXMyTIm+ao6JCkj7uJbeFLFOTOgpduJgnRd40R0WFJP3uxblOskxN6ih04WKeFHnTHBWVSZ+cR6ELF/OkyJvmqKiQpO/2DinL1KSOQhcu5kmRN81RUZn0yXkUunAxT4q8aY6KCkn6sN0PSlmmJnUUunAxT4q8ac6qoIg1iqHrYjdlmZrUUe' +
  'jCxTwp8saDt7lo/KI1eaWfCIUuXMyTIm+ao6JCkj779BOh0IWLeVLkTXNUVCZ9ch6FLlzMkyJvmqOiQpI+x+knQqELF/OkyJvmqKhM+uQ8Cl24mCdF3jRHRYUkfc69mQiFLlzMkyJvmqOiQpI+Z1lOhEIXLuZJkTfNUVEhSU9yPv0kKHShYn4U+eKCigtL+lw5NQkKXaiYH0W+uKDizgqMWCN7TcWFyjI1qaPQhYr5UeSLB+fWyOZuCMnfKHShY' +
  'n4U+eLBud0Qct+b5G8UulAxP4p88eD8Gy2NA1qTO5xNgkIXKuZHkS/NUVFrWQc1JveynASFLlTMjyJfmqOi1rIOciB8BMcyNamj0IWJeVHkiQsqbi3rIAe+VHFhskxN6ih0YWJeFHnigopbCz+MeBPJQxUXJsvUpI5CFybmRZEnHphvIsl3TiVnKHQhYj4oL6x8aYn5zql8u2ByhkIXIuZDkR9e2G8NNw70IN8jOzgKXYiYD0V+uKDiLgq/zDeG' +
  'J9FJ3/2N4c+Kg70Ie+GaZWpSR6FzF/OgyAsvnqnIi8Iv7xQHe2H3rxxkmZrUUejcxTwo8sKLer4ZH/DglYpzl2VqUkehcxfzoMgLF1TcduGgN+WHnPhMRbrKMjWpo9C5iv4X+eDFGxW5XTjIfXGuCNkWxDI1qaPQuYr+F/ngxe5NCXBQyBAS4AOJ91WsmyxTkzoKnZvou/y38qI1+w2RGx/04hsV6SbL1KSOQucm+l7kgRsqcrdwcMQ8HPIaXFG' +
  'xLrJMTeoodC6i3/LdyofWXJxvs004+HbxYU++UrEugomvSlOTKq4ja/S78N+T2yp2Pxkn8ML1ag8THxamJnXcZsLSZ/lt5UFzVOz+woeins6SWyq2uWDircLUpI6bF/S58N2T7U9hazJO5AXvIVyu9jDxCni9YWqyHcbJxQf6K58t/5ujYi8vfDhiAtoKt9mXMPLGhrHJdm4oZM0Ff8NGbMD2CWa7hA9HLCxZwSfBV1V0c8HQ+4XByXnuK1TNBV' +
  '8/kr+W7x5cXDByGRkn9MRtmxCY+i6N3TA5WcO4uK1og6+RF8/jh1xxkrC7beH2J5aCuezqZB//LYyDa7zh5/XCX29eq+jjZJzYE37JXKcnwGje3HJUh8OZpzaOz/ay3Wy/64NB+MibV/fttzdR0ccLJ4u+2nd7R1WqneBjxFZ9m6w3aD1WOFnUJLRN3MaLU/6CfzcKPyNoOwKIE0Zs570JZ+GFLStMtRN8uyr/LF+9aHeVXwkn7XG15zuIXPudq' +
  'baiXyBisXfJPVWhrXDi6Ks96bLFd+ow0a/Cvwh2r446VDh5j6s9uakqpAYWfSp8i8LnKr8SCuhxted0iC6v70ntJ/jzH/lk+efJ76qCr4yCI+CN0aeqQmogwRduzRd943qGquAvFBbxjioL/pXp8pbClC36AaKf46yIfSk3Cuzxp4wwwB+qGqmOgg8fgLDpwiWqRpxQaI+79BUvwQeqSqqDGH/5YPkTgdus0KpQsPt7girwip9dnQ5i3EG3Kzxo' +
  '/yDqMjIqFAm/dHlzGyjEmzetvfrwZ6gq/YRKRK6lteCoQQ5nBghx5rBkl1GaDQ5b+9paqEjvQPCmOh9gOYrxVZyt+EcRMya/r4wK9oA31zlXp6EYT8XVincoqtI4QqWiNuTcBSc7ua21PSUxjoqnFedoxlxjgYpxVqRV4WjY3XJdBrd0MX6KoxXfaF6qWmMKFRwlUCS7O5cU46W4WfHsgd8MypYyKt4TrtG8rqqlKmKcFC8rjl1Q1cYXKjtK/34' +
  'TbkPxkaqY2hDjovhYcevJf1XFOYQKPy0aMALccIg7bWWXB2IcFI/IjZj2Zex+/Dax4kVDRoGP0L8AJ5n8bDfgZqo9pxLUCHsRn4vQgK6PrHfAunG/9PdU3UUL7WSys71De6Lqzi00ZKQRHQvO0+dSSPd3YPUQ2wXYjRk52clYT1yPFRtUNHBEWMcHgK97dNvbMUpqBwcVZoj94TsNjyw2rGjoyPDFvnfBVHvvsL6Ab+IOeTFxK1T9Zcpq8ATwUT' +
  'y7P5xWO9RfANZH9WL9RpkycCnUlGULDZ3pil/CrgLHtHlD2OWvAMrlIo4vwUMwQ9dlK2rSaQgNHnFs+BC4kOUx4KP7rwGfajIpj/qLwM/rPNcAz8vzs5yeq9Vassw+/C6h4VNfpfaAj/Q5Ce+J4E0l4b0CuyP8d/Wz1TE8fqipAA4sa5TmskIARh9GS9qyjHH4Y4VAjPrkNmnL3E9aWwsB6b3WNvFlzrk03kJg2Le1ApbMzVyzJXsIQVr6De6pM' +
  'McCkFGEgI2y9DA5jOzOHCIELrs7c5IvyjtWCGJ2d+bgtMffWwsBzdGdsRlj57ElCsHt8SaUZDt9N1M9FSHQ2dcfgz7bZZ+yEPQc4elD7BtAUhcFE/JGN4a8UR1JMOQeWMp05dFgXH1fW5k6XDCHS+XyZrcNjGMm+yyCWZyvvpQFF9Gc7Q6hUKZmFAzMOfv7kXPdlyaYyjWlM6/P9YDxeKQQpZYsGD3iXpuR5BPUUxXMZ99/1D0dW8N23lbTU6m/' +
  'vwBcnL2UoU+2g+3Jm9LUfkKycPiTE91muQ9gPVnfO2pCKnWckEzcsoMbPY3SHWI9WJ9M8lS8kHjc+PU5aP1gjOfjeR+oqFRqTiGJs5/dRe+88xd6CEHAOx8daAAAAABJRU5ErkJggg==';
end;



procedure TGlobvar.LoadDFMValues;
begin
  inherited LoadDFMValues;


  try
    Name := 'Globvar';
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    ParentFont := False;
  finally
  end;
end;

end.