unit UAnzPDF;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, WEBLib.DBCtrls,
  
UDatamodule,
  UGlobvar, WEBLib.ExtCtrls, WEBLib.ComCtrls, WEBLib.Buttons, WEBLib.WebCtrls;

type
  TAnzPDF = class(TForm)
    WebPanel1: TPanel;
    WebPanel2: TPanel;
    WebButton1: TButton;
    WebBrowserControl1: TBrowserControl;
    procedure WebFormShow(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private-Deklarationen }
  public
    { Public-Deklarationen }
    Zeilen_Liste : TStringList;
    Zeilen_Count : integer;
    Form_Aktiv : boolean;
    Akt_Url,
    Akt_Header : String;
  protected procedure LoadDFMValues; override; end;

var
  AnzPDF: TAnzPDF;


procedure Anzeige_PDF(AktHeader,AktUrl : String);


implementation

{$R *.dfm}


procedure Anzeige_PDF(AktHeader,AktUrl : String);
var
  newform: TAnzPDF;
begin
  newform := TAnzPDF.CreateNew(procedure(AForm: TObject)
    begin
      (AForm as TAnzPDF).Caption := 'PDF anzeigen';
    end
  );
  newform.Border := fbSingle;
  newform.Akt_Url := AktUrl;
  newform.Akt_Header := AktHeader;


  newform.ShowModal(procedure(AValue: TModalResult)
  begin
  end
  );
end;




procedure TAnzPDF.WebButton1Click(Sender: TObject);
begin
  Close;

end;

procedure TAnzPDF.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  Zeilen_Liste.Clear;
  Zeilen_Liste.Free;

end;

procedure TAnzPDF.WebFormCreate(Sender: TObject);
begin
  Form_Aktiv := false;

end;

procedure TAnzPDF.WebFormResize(Sender: TObject);
begin
  if not Form_Aktiv then exit;
  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,WebPanel1,WebPanel2);

end;

procedure TAnzPDF.WebFormShow(Sender: TObject);

begin
  Mit_Hilfe_Button := false;
  Resize_Top_Panel_2(self,WebPanel1,Akt_Header);
  Mit_Hilfe_Button := true;
  Mit_AGB_etc := false;
  Resize_Bottom_Panel(self,WebPanel2);
  Mit_AGB_etc := true;
  Zeilen_Liste := TStringList.Create;
  Zeilen_Count := 0;
  Form_Aktiv := true;
  Resize_Edit_Controls(WF_Edit,self,Zeilen_Liste,WebPanel1,WebPanel2);
  WebBrowserControl1.URL := Akt_Url;
end;



procedure TAnzPDF.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebPanel1 := TPanel.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebBrowserControl1 := TBrowserControl.Create(Self);

  WebPanel1.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebBrowserControl1.BeforeLoadDFMValues;
  try
    Name := 'AnzPDF';
    Width := 640;
    Height := 439;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Arial';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 640;
    WebPanel1.Height := 129;
    WebPanel1.HeightPercent := 100.000000000000000000;
    WebPanel1.WidthPercent := 100.000000000000000000;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 379;
    WebPanel2.Width := 640;
    WebPanel2.Height := 60;
    WebPanel2.HeightPercent := 100.000000000000000000;
    WebPanel2.WidthPercent := 100.000000000000000000;
    WebPanel2.Align := alBottom;
    WebPanel2.BorderColor := clSilver;
    WebPanel2.BorderStyle := bsSingle;
    WebPanel2.ChildOrder := 2;
    WebButton1.SetParentComponent(WebPanel2);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 175;
    WebButton1.Top := 8;
    WebButton1.Width := 90;
    WebButton1.Height := 41;
    WebButton1.Caption := 'Zur'#252'ck';
    WebButton1.ChildOrder := 14;
    WebButton1.ElementClassName := 'btn-primary';
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebBrowserControl1.SetParentComponent(Self);
    WebBrowserControl1.Name := 'WebBrowserControl1';
    WebBrowserControl1.Left := 0;
    WebBrowserControl1.Top := 129;
    WebBrowserControl1.Width := 640;
    WebBrowserControl1.Height := 250;
    WebBrowserControl1.HeightPercent := 100.000000000000000000;
    WebBrowserControl1.WidthPercent := 100.000000000000000000;
    WebBrowserControl1.Align := alClient;
    WebBrowserControl1.ChildOrder := 3;
    WebBrowserControl1.Sandbox := [];
  finally
    WebPanel1.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebBrowserControl1.AfterLoadDFMValues;
  end;
end;

end.